<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="adv_search">
	<div class="advSerach_scrollbar">
	<!-- <perfect-scrollbar class="advSerach_scrollbar"> -->
		<div class="row margin-bottom-5" *ngFor="let filter of listOfFilters; let i=index;">
			<div class="col-md-4 col-sm-4 col-xs-6 advance-search-textboxdiv padding-right-7">
				<label [for]="'EnterText' + i" class="sr-only">{{ 'AdvancedSearch.enterText' | translate }}</label>
				<input [id]="'EnterText' + i" class="form-control dropdown-search rightpadding-0 input-texbox text-box filter-control-input"
				 aria-label="Enter Text" title="{{ 'AdvancedSearch.enterText' | translate }}" maxlength="100" placeholder="{{ 'AdvancedSearch.enterText' | translate }}"
				 type="text" [(ngModel)]="listOfFilters[i].fieldValue" (ngModelChange)="filterChanged(listOfFilters[i].fieldValue, i)"
				 autocomplete="off" [ngModelOptions]="{standalone:true}">
			</div>
			<div class="col-md-4 col-sm-4 col-xs-6 no-padding">
				<label [for]="'EntireDocument' + i" class="sr-only">{{ 'AdvancedSearch.entireDocument' | translate }}</label>
				<select title="{{ 'AdvancedSearch.fieldName' | translate }}" tabindex="0" [id]="'EntireDocument' + i" [(ngModel)]="listOfFilters[i].fieldName"
				 [ngModelOptions]="{standalone:true}" (ngModelChange)="onFieldChange($event,i)" class="form-control dropdown-search rightpadding-0 text-box">
					<option *ngFor="let option of fieldsDropdownOptions; let i = index;" [value]="option.itemValue">{{ 'AdvancedSearch.'+option.itemValue+'.name' | translate }}</option>
				</select>
			</div>
			<div class="col-md-3 col-sm-3 col-xs-6 advance-search-operatordiv  right-padding-0 padding-left-7" *ngIf="i != listOfFilters?.length - 1">
				<label [for]="'and' + i" class="sr-only">{{ 'AdvancedSearch.andText' | translate }}</label>
				<select title="{{'AdvancedSearch.operator'|translate}}" tabindex="0" [id]="'and' + i" [(ngModel)]="listOfFilters[i].operator"
				 [ngModelOptions]="{standalone:true}" (ngModelChange)="onOperatorChange($event,i)" class="form-control dropdown-search rightpadding-0 text-box">
					<option *ngFor="let option of operatorsDropdownOptions; let i = index;" [value]="option.itemValue">{{ 'AdvancedSearch.operators.'+option.itemName+'.name' | translate }}</option>
				</select>
			</div>
			<div class="col-md-1 col-sm-1 col-xs-6 no-padding icon-left-align">
				<a href="javascript:void(0)" title="{{ 'AdvancedSearch.addFilter' | translate }}">
					<i *ngIf="i!==7" aria-hidden="true" class="fa fa-lg fa-plus-circle add-icon" (click)="addFilter(i);$event.stopPropagation()"></i>
				</a>
				<a href="javascript:void(0)" title="{{ 'AdvancedSearch.removeFilter' | translate }}">
					<i aria-hidden="true" class="fa fa-lg fa-minus-circle delete-icon" (click)="removeFilter(i);$event.stopPropagation()" *ngIf="listOfFilters?.length > 1 && i !== 7"></i>
				</a>
			</div>
		</div>
	<!-- </perfect-scrollbar> -->
</div>
</div>
