<!-- <div class="col-md-12 col-sm-12">
<h1 class="page-heading" style="display: inline-block">
	{{ 'textDownloadContent.pageTitle' | translate }}</h1></div> -->
<div
  style="
    background-image: url('assets/config/wiley/wiley_banner.jpg');
    height: 450px;
  "
>
  <!-- <h1 class="page-heading" style="display: inline-block;padding-left: 23px;
	font-family: 'Open Sans', sans-serif !important;
	font-size: 20px;">{{ 'Common.Search' | translate }}</h1> -->
  <a
    class="back-btn wiley-primary-text-color text-right"
    style="cursor: pointer"
    (click)="backtoHome()"
    >Back To Home</a
  >

  <div class="container">
    <!-- <div class=" about-container search-align">
			<input aria-label="What are you searching for?" class="input-style" type="text" id="searchid" name="searchKeyword" #searchKeyId
			 class="form-control search-texbox archives-search-textbox" placeholder="Search Content with Document ID" autocomplete="off" [(ngModel)]="pageContent">
			<span class="input-group-btn">
				<button class="btn btn-primary search-btn g-search-btn" title="{{ 'Common.Search' | translate }}" type="submit" (click)='downloadSearch(pageContent)'>
					<i class="fa fa-search"></i>
					<span class="sr-only">{{ 'Common.Search' | translate }}</span>
				</button>
			</span>
		</div> -->

    <div class="tab-search">
      <tabset #homepageSeach>
        <tab
          tabindex="0"
          heading=""
          class="advEnabled"
          [disabled]="pageContent?.currentSelectedArchives.length <= 0"
          [customClass]="'homePageSearch'"
          tabindex="0"
        >
          <ng-template tabHeading>
            <!-- <label class="homePageSearchLabel" title="{{'HomePage.advancedSearch'|translate}} ({{controlShortcut}} + J)">{{'Download Content'|translate}}</label> -->
          </ng-template>
          <div class="input-group archives-menudiv advance-search">
            <ng-container
              *ngIf="activateAdvSearch && pageContent.downloadContentArchives"
            >
              <multiselect-dropdown
                class="multiselect"
                (isMutliDropdownOpen)="receiveMessage($event)"
                [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'"
                [options]="pageContent.downloadContentArchives"
                [text-key]="'name'"
                [value-key]="'shortName'"
                [(ngModel)]="pageContent.currentSelectedArchives"
                (ngModelChange)="onSelectedArchivesChange($event)"
              ></multiselect-dropdown>
            </ng-container>

            <ng-container *ngIf="activateAdvSearch && currentArchive === 'RGS'">
              <multiselect-dropdown
                class="multiselect"
                (isMutliDropdownOpen)="receiveMessage($event)"
                [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'"
                [options]="rgsArchives"
                [text-key]="'name'"
                [value-key]="'shortName'"
                [(ngModel)]="pageContent.currentSelectedArchives"
                (ngModelChange)="onSelectedArchivesChange($event)"
              ></multiselect-dropdown>
            </ng-container>

            <ng-container *ngIf="activateAdvSearch && currentArchive === 'RCP'">
              <multiselect-dropdown
                class="multiselect"
                (isMutliDropdownOpen)="receiveMessage($event)"
                [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'"
                [options]="rcpArchives"
                [text-key]="'name'"
                [value-key]="'shortName'"
                [(ngModel)]="pageContent.currentSelectedArchives"
                (ngModelChange)="onSelectedArchivesChange($event)"
              ></multiselect-dropdown>
            </ng-container>

            <label for="searchid" class="sr-only">{{
              "HomePage.search" | translate
            }}</label>
            <p
              *ngIf="activateAdvSearch"
              class="limiter_count search-texbox"
              [class.other_archives]="!pageContent.downloadContentArchives"
            >
              <label class="estimated-count-div">
                <span
                  [class.arabic-style]="isArabicLanguage"
                  class="text-italic estimated-count"
                  >{{ "AdvancedSearch.estimatedCount" | translate }}
                </span>

                <span
                  [class.arabic-style]="isArabicLanguage"
                  *ngIf="
                    (estimatedCount == 0 && !showLoadingTxt) ||
                    (disableSimpleSearch && !showLoadingTxt)
                  "
                  class="blink-wordlbl mb-0"
                >
                  {{ "HomePage.noResults" | translate }}
                </span>
                <span
                  [class.arabic-style]="isArabicLanguage"
                  *ngIf="
                    (estimatedCount > 0 && !showLoadingTxt) ||
                    (disableSimpleSearch && showLoadingTxt)
                  "
                  class="blink-wordlbl mb-0"
                >
                  {{ estimatedCount }} {{ "HomePage.documents" | translate }}
                </span>
                <span *ngIf="showLoadingTxt">
                  {{ "AdvancedSearch.Loading" | translate }}
                </span>
              </label>
              <span
                class="pull-right reset_link"
                title="{{ 'AdvancedSearch.CapsReset' | translate }}"
                (click)="resetClicked($event)"
                >{{ "AdvancedSearch.CapsReset" | translate }}</span
              >
            </p>
            <!-- Help icon removed -->
            <!-- <a href="javascript:void(0)" class="help-btn" title="{{ 'Common.Help' | translate }}" (click)="  ($event);">
                <i class="spriteico helpIcon"></i>
                <span class="sr-only">{{ 'HomePage.helpIcon' | translate }}</span>
              </a> -->

            <span class="input-group-btn">
              <button
                class="btn btn-primary search-btn g-search-btn"
                title="{{ 'Common.Search' | translate }}"
                [disabled]="
                  estimatedCount == 0 ||
                  (disableSimpleSearch && pageContent.showAdvancedFilter)
                "
                type="submit"
                (click)="verifyAndGotoSearch(pageContent.searchTerm, '')"
              >
                <i class="fa fa-search"></i>
                <span class="sr-only">{{ "Common.Search" | translate }}</span>
              </button>
            </span>
          </div>

          <div class="dropdown-box text-right">
            <app-search-filter
              (isAdvanceSearchOpen)="receiveAdvSelectMessage($event)"
              [page-content]="pageContent"
              [show-filter]="showFilter"
              [search-key]="pageContent.searchTerm"
              (disable-search)="disableSearch($event)"
              (show-loading-text)="isShowLoadingText($event)"
              (estimated-count)="receiveEstimatedCount($event)"
              [fromHomepage]="true"
              [resetFilter]="isResetFilter"
              [filterToggle]="isFiltertoggle"
              [showPubDateval]="showPubDate"
              [selectedArchives]="selectedArchives"
              [getFilterValue]="getFilterValue"
              (passFilterValue)="getFilterFormValue($event)"
            ></app-search-filter>
          </div>
        </tab>

        <!-- <tab tabindex="0" heading="" class="advEnabled" [disabled]="pageContent?.currentSelectedArchives.length<=0 " 
        (select)="showAdvDropdown();"[customClass]="'homePageSearch'" tabindex="0">


        
          <ng-template tabHeading>
            <label class="homePageSearchLabel" title="{{'HomePage.advancedSearch'|translate}} ({{controlShortcut}} + J)">{{'Download Content'|translate}}</label>
          </ng-template>
          <div class="input-group archives-menudiv advance-search">
            <ng-container *ngIf="activateAdvSearch && pageContent.downloadContentArchives">
              <multiselect-dropdown class="multiselect" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'" [options]="pageContent.downloadContentArchives" [text-key]="'name'" [value-key]="'shortName'"
                [(ngModel)]="pageContent.currentSelectedArchives" (keypress)="onSelectedArchivesChange($event)"></multiselect-dropdown>
            </ng-container>

            <ng-container *ngIf="activateAdvSearch && currentArchive === 'RGS'">
              <multiselect-dropdown class="multiselect" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'" [options]="rgsArchives" [text-key]="'name'" [value-key]="'shortName'"
                [(ngModel)]="pageContent.currentSelectedArchives" (keypress)="onSelectedArchivesChange($event)"></multiselect-dropdown>
            </ng-container>

            <ng-container *ngIf="activateAdvSearch && currentArchive === 'RCP'">
              <multiselect-dropdown class="multiselect" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'" [options]="rcpArchives" [text-key]="'name'" [value-key]="'shortName'"
                [(ngModel)]="pageContent.currentSelectedArchives" (keypress)="onSelectedArchivesChange($event)"></multiselect-dropdown>
            </ng-container>
            
             <label for="searchid" class="sr-only">{{ 'HomePage.search' | translate }}</label>
            <p *ngIf="activateAdvSearch" class="limiter_count search-texbox" [class.other_archives]="!pageContent.downloadContentArchives">
              <label class="estimated-count-div">
                <span [class.arabic-style]="isArabicLanguage" class="text-italic estimated-count">{{ 'AdvancedSearch.estimatedCount' | translate }} </span>

                 <span [class.arabic-style]="isArabicLanguage" *ngIf="(estimatedCount == 0 && !showLoadingTxt) || (disableSimpleSearch && !showLoadingTxt)"
                  class="blink-wordlbl mb-0">
                  {{ 'HomePage.noResults' | translate }}
                </span>
                <span [class.arabic-style]="isArabicLanguage" *ngIf="((estimatedCount > 0 && !showLoadingTxt) || (disableSimpleSearch && showLoadingTxt))"
                  class="blink-wordlbl mb-0">
                  {{ estimatedCount }} {{ 'HomePage.documents' | translate }}
                </span>
                <span *ngIf="showLoadingTxt">
                  {{'AdvancedSearch.Loading' | translate}}
                </span>
              </label>
              <span class="pull-right reset_link" title="{{ 'AdvancedSearch.CapsReset' | translate }}" (click)="resetClicked($event)">{{ 'AdvancedSearch.CapsReset' | translate }}</span>
            </p>
            <a href="javascript:void(0)" class="help-btn" title="{{ 'Common.Help' | translate }}" (click)="  ($event);">
              <i class="spriteico helpIcon"></i>
              <span class="sr-only">{{ 'HomePage.helpIcon' | translate }}</span>
            </a>

            <span class="input-group-btn ">

              <button class="btn btn-primary search-btn g-search-btn" title="{{ 'Common.Search' | translate }}" [disabled]="estimatedCount == 0 || disableSimpleSearch && pageContent.showAdvancedFilter"
                type="submit" (click)='verifyAndGotoSearch(pageContent.searchTerm, "")'>
                <i class="fa fa-search"></i>
                <span class="sr-only">{{ 'Common.Search' | translate }}</span>
              </button>
            </span> 


          </div>  

          <div class="dropdown-box text-right">
            <app-search-filter (isAdvanceSearchOpen)="receiveAdvSelectMessage($event)" [page-content]="pageContent" [show-filter]="showFilter"
              [search-key]="pageContent.searchTerm" (disable-search)="disableSearch($event)" (show-loading-text)="isShowLoadingText($event)"
              (estimated-count)="receiveEstimatedCount($event)" [fromHomepage]="true" [resetFilter]="isResetFilter" [filterToggle]="isFiltertoggle"
              [showPubDateval]="showPubDate" [selectedArchives]="selectedArchives" [getFilterValue]="getFilterValue" (passFilterValue)="getFilterFormValue($event)"></app-search-filter>
          </div>
        </tab>  -->

        <div
          [class.arabic-style]="isArabicLanguage"
          class="wol-help-container"
          id="help_box"
          [class.show-wol-help]="isWileyLibraryHelp"
          (click)="$event.stopPropagation()"
          [class.arabic-align-right]="isArabicLanguage"
        >
          <p class="wol-content">
            {{ "HomePage.wolHelpContent" | translate }}
          </p>
        </div>
      </tabset>
    </div>
  </div>

  <!-- <ng-template #downloadTableTemplate></ng-template>

	<ng-template #errorTableTemplate></ng-template> -->
  <!-- <div *ngIf="downloadDocs" class="container">
		<div class="first-screen ">
			<div>
				<div class="screen-description1">
					<h4 class="modal-title text-center">
						<img src="assets/images/icons/download@2x.png " alt="View History" class="center-block downloadPopupIcon" />
					</h4>
				</div>
				<div class="form-check">
					<input type="checkbox" class="form-check-input" id="check" (change)="handleSelected($event)">
					<label class="form-check-label" for="check">Include Page</label>
				</div>
				<div class="modal-body url_body download-body">
					<div [class.arabic-style]="isArabicLanguage" class="download-text">
						<a *ngIf="downloadPageURL" [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadPageURL}}"
						 target="_blank">{{ 'Detail.download' | translate }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div> -->

  <!-- <div *ngIf="downloadError" class="container">
			<div class="second-screen">						 
							<div class="screen-description2">
									<p>Requested document is not available. Please click "back to home".</p>
							</div>
			</div>
  </div> -->

  <!-- =======================Table================================================= -->

  <!-- <div class="table-container" *ngIf="documentList && documentList.length > 0">
    <div>
      <div class="col-6">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope=""></th>
              <th scope="col">Document</th>
              <th scope="col">Title</th>             
            </tr>
          </thead>
         <tbody>
            <tr *ngFor="let document of documentList">
              <td>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox"  class="custom-control-input" id="customCheck1" checked>
                    <label class="custom-control-label" for="customCheck1"></label>
                </div>
              </td>
              <td>{{document.id}}</td>
              <td>{{document.title}}</td>
            </tr>
          
          </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- ==================================================Endtable=================== -->
</div>
