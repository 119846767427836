<div class="row image-viewer-parent">
  <div class="col-md-12 padding-0">
    <div class="image-wrapper" #container id="thumbnailImageWrapper">
      <!-- <perfect-scrollbar [config]="config" class="image-wrapper" #container id="thumbnailImageWrapper"> -->
      <section>
        <ng-container *ngFor="let image of documentImages; let i = index">
          <div
            (click)="callImageViewer(image, i)"
            (mouseenter)="preMaridienMapsEnter(image, i)"
            (mouseleave)="preMaridienMapsLeave(image, i)"
            class="image-container filmstrip-container-div"
            id="thumb{{ i + 1 }}"
            [ngClass]="{ 'active': i + 1 == currentActiveIndex }"
          >
            <img *ngIf="image?.thumb"
              title="{{ image?.title | unicodeencodeEntity }}"
              name="Document-image"
              class="filmstrip-container center-block filmstrip-image-tag"
              alt="Document Image"
              [defaultImage]="defaultImage"
              [lazyLoad]="image?.thumb"
              [errorImage]="errorImage"
            />
            <!-- <div class="sequence-number">{{i+1}}</div> -->
            <div class="sequence-number">{{ image?.sequenceNumber }}</div>
            <div
              *ngIf="image?.preMaridenMaps"
              class="premaps-division"
              [class.even-class]="isEvenclass"
            >
              The geo-coordinates for this map pre-date the internationally
              recognised single meridian and appear in the “Notes” section. A
              TIFF image is provided if you wish to use the coordinates as
              reference points for map rectification.
            </div>
          </div>
        </ng-container>
      </section>
      <!-- </perfect-scrollbar> -->
    </div>
  </div>
</div>
