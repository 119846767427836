<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<!-- <button (click) = "callDocumentViewer()" >Click Doc viewer</button> -->
<div class="col-md-12">
  <div class="filter-result">
    <div [class.arabic-style]="isArabicLanguage" class="pageheader">
      <h1 class="search-res-header" [class.arabic-style]="isArabicLanguage"
        title="{{ 'SearchResult.SearchResults' | translate }}">{{ 'SearchResult.SearchResults' | translate }}</h1>
        <!-- <span *ngIf="showENVDocumentInfo" class="doc-count-icon">
          <i class="fa fa-info-circle doc-count-eye-icn" (click)="clickHereENV()"></i>
          <span class="more-rgs-text">{{ 'SearchResult.moreENVsContentLink' | translate }}</span>
          <span class="rgs-download-file"  (click)="clickHereENV()"> {{ 'Common.clickHeretext' | translate }}.</span>
        </span> -->

        <!--
          <span *ngIf="showDocumentInfo" (click)="showMoreRGSDocumentsOnClick()" class="doc-count-icon" (mouseenter)="moreRgsContentOnMouseEnter($event)" (mouseleave)="moreRgsContentOnMouseLeave($event)">
            <i class="fa fa-info-circle doc-count-eye-icn"></i>
            <span class="more-rgs-text">{{ 'SearchResult.moreRGSContentLink' | translate }}</span>
            <span class="rgs-download-file"  (click)="gotoRGSFullDocumentList()"> {{ 'Common.clickHeretext' | translate }}.</span>
          </span>
          -->

      <!-- <span *ngIf="showBAASDocumentInfo" (click)="showMoreBAASDocumentsOnClick()" class="doc-count-icon" 
          (mouseenter)="moreBAASContentOnMouseEnter($event)" (mouseleave)="moreBAASContentOnMouseLeave($event)">
            <i class="fa fa-info-circle doc-count-eye-icn"></i>
            <span class="more-rgs-text">{{ 'SearchResult.moreBAASContentLink' | translate }}</span>
          </span> 
           <span *ngIf="showBAASDocumentInfo" class="rgs-download-file" 
           (mouseleave)="moreBAASContentOnMouseLeave($event)"  (click)="gotoBAASFullDocumentList()"> {{ 'Common.clickHeretext' | translate }}.</span> -->
      <!--
          <ng-container *ngIf="showMoreRGSContentLink">
            <div class="reg-more-content" [class.rgs-more-text-container]="mapView || pageContentData?.advancedFilterParam.contentType.length > 0 || pageContentData?.advancedFilterParam.docSubType.length > 0 ||
                pageContentData?.advancedFilterParam.publishedDateFrom  != '' || pageContentData?.advancedFilterParam.languages.length > 0 ||
                pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
                pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
                0 || pageContentData?.advancedFilterParam.illustrationType.length > 0 ">
              <div class="reg-more-content-left" (mouseenter)="moreRgsContentOnMouseLeave($event)">
                  <span class="reg-docs-text"> {{ 'SearchResult.rgsmoreContentText' | translate }}</span>
              </div>
            </div>
          </ng-container>
-->
      <!-- <ng-container *ngIf="showMoreBAASContentLink">
            <div class="baas-more-content" [class.baas-more-text-container]="mapView || pageContentData?.advancedFilterParam.contentType.length > 0 || pageContentData?.advancedFilterParam.docSubType.length > 0 ||
                pageContentData?.advancedFilterParam.publishedDateFrom  != '' || pageContentData?.advancedFilterParam.languages.length > 0 ||
  pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
  pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
  0 || pageContentData?.advancedFilterParam.illustrationType.length > 0 ">
              <div class="reg-more-content-left" (mouseenter)="moreBAASContentOnMouseLeave($event)">
                  <span class="reg-docs-text"> {{ 'SearchResult.baasmoreContentText' | translate }}</span>               
              </div>
            </div>
          </ng-container> -->
    </div>
    <div class="small-underline" [class.arabic-style]="isArabicLanguage"></div>
    <div class="row">
      <div class="col-md-12 searchResult" *ngIf="fieldVal == true || pageContentData?.searchTerm || pageContentData?.advancedFilterParam.contentType.length > 0 ||
              pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  != '' ||
              pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
0 || pageContentData?.advancedFilterParam.illustrationType.length > 0">
        <ul class="result-tag">
          <ul [class.arabic-style]="isArabicLanguage" class="col-sm-12 col-xs-12 padding-0 searchQry search_qry_term"
            *ngIf="fieldVal == true || pageContentData?.searchTerm">
            <label [class.arabic-style]="isArabicLanguage" class="searchBy limiterLabel" [ngClass]="{'mr49': addMargin}"
              *ngIf="fieldVal == true || pageContentData?.searchTerm">{{ 'SearchResult.Query' | translate }}</label>
            <!-- <li [ngClass]="{'col-md-1': pageContentData?.searchTerm}"> -->
            <!-- <li [class.arabic-style]="isArabicLanguage" class="simpleSearchQuery" *ngIf="!searchTermwithSpaces">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl" *ngIf="pageContentData?.searchTerm ? true : false">{{
                'SearchResult.EntireDocument' | translate }} </label> -->
            <!-- <span class="badge margin-left5" *ngIf="pageContentData?.searchTerm ? true : false">
                <span [class.arabic-style]="isArabicLanguage" *ngIf="!isSearchTermContainsbibId" class="mrgn-l0 simpleSearch select-qry-single"
                  [attr.title]="pageContentData?.searchTerm">
                  {{pageContentData?.searchTerm ? pageContentData?.searchTerm: '' }}</span>

                <span [class.arabic-style]="isArabicLanguage" *ngIf="isSearchTermContainsbibId" class="mrgn-l0 simpleSearch select-qry-single"
                  [attr.title]="pageContentData?.searchTerm.substring(1, pageContentData?.searchTerm.length - 1)">
                  {{pageContentData?.searchTerm.substring(1, pageContentData?.searchTerm.length - 1) }}</span>
              </span> -->
            <!-- <span [class.arabic-style]="isArabicLanguage" class="operator op-and" *ngIf="fieldVal == true && pageContentData?.searchTerm">
                {{ 'Common.AND' | translate }}
              </span> -->
            <!-- </li> -->


            <!-- <li class="simple-query-list" >
              <span class="simple-searchterms" *ngFor="let searchTerms of searchTermDatas; let last = last">
              <ng-container *ngIf="searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT'">
                  <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                    *ngIf="pageContentData?.searchTerm ? true : false">{{
                  'SearchResult.EntireDocument' | translate }} </label>
              </ng-container>
              <ng-container *ngIf="searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT'">
                <span class="badge margin-left5" *ngIf="searchTerms !== ''">
                  <span [attr.title]="searchTerms">
                    <span class="mrgn-l0 simpleSearch select-qry-single">{{searchTerms}}</span>
                  </span>
                </span>
              </ng-container>
             <ng-container *ngIf="searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT'">
                <span [class.arabic-style]="isArabicLanguage" class="operator op-and"
                  *ngIf="searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT' && !last">
                  {{ 'Common.AND' | translate }}
                </span>
              </ng-container> 
              <ng-container *ngIf="searchTerms === 'AND' || searchTerms === 'OR' || searchTerms === 'NOT'">
                <span [class.arabic-style]="isArabicLanguage" class="boolean-operator operator op-and" *ngIf="!last">
                  {{searchTerms}}
                </span>
              </ng-container>
              </span>
            </li> -->

            <li class="simple-query-list">
              <span class="simple-searchterms" *ngFor="let searchTerms of searchTermDatas; let last = last">
                <ng-container
                  *ngIf="searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT' && searchTerms !== ''">
                  <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                    *ngIf="pageContentData?.searchTerm ? true : false">{{ 'SearchResult.EntireDocument' | translate }}
                  </label>
                </ng-container>
                <ng-container
                  *ngIf="searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT' && searchTerms !== ''">
                  <span class="badge margin-left5" *ngIf="searchTerms !== ''">
                    <span [attr.title]="searchTerms">
                      <span class="mrgn-l0 simpleSearch select-qry-single">{{searchTerms}}</span>
                    </span>
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="searchTerms === 'AND' || searchTerms === 'OR' || searchTerms === 'NOT' && searchTerms !== ''">
                  <span [class.arabic-style]="isArabicLanguage" class="boolean-operator operator op-and" *ngIf="!last">
                    {{searchTerms}}
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT' && searchTerms !== ''">
                  <span [class.arabic-style]="isArabicLanguage" class="operator op-and" *ngIf="!last">
                    {{ 'Common.OR' | translate }}
                    <!-- {{ 'Common.AND' | translate }} -->
                  </span>
                </ng-container>
              </span>
            </li>



            <li>
              <div class="searchQryDiv"
                *ngFor="let keyword of pageContentData?.advancedFilterParam?.searchKeywords; let i = index; let last = last;">
                <div class="col-sm-9" class="search-reslbls" *ngIf="keyword.fieldValue !== ''"
                  [attr.title]="keyword.fieldValue">
                  <div class="searchResultDoc">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                      [attr.title]="fieldNames[keyword.fieldName]">{{fieldNames[keyword.fieldName] | titlecase}}</label>
                    <span class="badge margin-left5">
                      <span [class.arabic-style]="isArabicLanguage" class="select-qry-single"
                        *ngIf="keyword.fieldValue !== ''">{{keyword.fieldValue}}
                      </span>
                      <i class="fa fa-times close-icon" (click)="setKeywords(i)"></i>
                    </span>
                  </div>
                </div>
                <!-- <span class="operator" *ngIf="i !== pageContentData?.advancedFilterParam?.searchKeywords - 1 && !last"> -->
                <span class="operator" *ngIf="i !== pageContentData?.advancedFilterParam?.searchKeywords - 1 && !last">
                  <span *ngIf="fieldVal == true">{{keyword.operator}}</span>
                </span>
              </div>

              <!-- <div class="searchResultDoc" *ngFor="let keyword of viewHistory?.filterParams?.searchKeywords; let i = index">
                <div class="search-reslbl" *ngIf="keyword?.fieldValue">
                  <label class="search-resultlbl">Entire Document </label>
                  <span class="badge-operator float-left">
                    <span class="operator" *ngIf="i !== viewHistory?.filterParams?.searchKeywords.length - 1">
                      {{keyword.operator}}
                    </span>
                  </span>

                  <span class="badge margin-left5" [attr.title]="keyword?.fieldValue">{{keyword?.fieldValue | titlecase}}</span>
                </div>
              </div> -->
            </li>
          </ul>
          <ul class="col-sm-12 col-xs-12 advSearch searchQry" *ngIf="pageContentData?.advancedFilterParam.contentType.length > 0 || pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  != '' || pageContentData?.advancedFilterParam.languages.length > 0 ||
pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
0 || pageContentData?.advancedFilterParam.illustrationType.length > 0">
            <label [class.arabic-style]="isArabicLanguage" *ngIf="pageContentData?.advancedFilterParam.contentType.length > 0 || pageContentData?.advancedFilterParam.docSubType.length > 0 ||
                   pageContentData?.advancedFilterParam.publishedDateFrom  != '' || pageContentData?.advancedFilterParam.languages.length > 0 ||
pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
0 || pageContentData?.advancedFilterParam.illustrationType.length > 0 " class="limitedBy limiterLabel">{{
              'SearchResult.Limiters' | translate }}</label>
            <li *ngIf="pageContentData?.advancedFilterParam.contentType.length > 0" class="contenttype-results">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl">{{ 'SearchResult.ContentTypes' |
                translate }} </label>
              <span class="result-ele-label" *ngIf="pageContentData?.advancedFilterParam.contentType.length < 2">
                <span *ngFor="let contentType of pageContentData.advancedFilterParam.contentType; let i = index"
                  class="margin-left5" style="padding: 0 !important;">
                  <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;"
                    [attr.title]="contentType">
                    <div class="searchqryAdv pull-left">{{contentType}}</div>
                  </div>
                  <div class="close_content_single pull-right" *ngIf="!isOtherTabActive">
                    <i class="fa fa-times close-icon" (click)="setContentType(contentType)" role="button"></i>
                  </div>
                </span>
              </span>
              <div *ngIf="pageContentData?.advancedFilterParam.contentType.length > 1"
                class="badge badgePop limiter-badge" [attr.title]="pageContentData.advancedFilterParam.contentType[0]"
                (click)="limiterBadgeOnclick($event)" [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true"
                [outsideClick]="true" [popover]="contentTypepopOverTemplate" [placement]="'bottom'">
                <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.contentType[0]}}</div>
                <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.contentType.length
                  -1}}
                  <i class="fa fa-caret-down"
                    [ngClass]="{'fa-caret-up':toggleviewlist ,'fa-caret-down': !toggleviewlist  }"></i>
                </div>
                <div class="close_content pull-right">
                  <i class="fa fa-times close-icon" (click)="clearContentType(contentType)"></i>
                </div>
              </div>
              <ng-template #contentTypepopOverTemplate>
                <!-- <span *ngIf="i != pageContentData?.advancedFilterParam.contentType.length - 1" class="operator"> OR </span> -->
                <ul class="popoverUl">
                  <li *ngFor="let contentType of pageContentData.advancedFilterParam.contentType; let i = index">
                    <span class="popover_inner_span">{{contentType}}</span>
                    <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setContentType(contentType)"></i>
                  </li>
                </ul>
              </ng-template>
              <!-- <span *ngIf="pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  > 0 || pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 || pageContentData?.advancedFilterParam.contentSources.length > 0  " class="operator">AND</span> -->
            </li>

            <li class="contentSubType_result" *ngIf="pageContentData?.advancedFilterParam.docSubType.length > 0">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                title="{{ 'SearchResult.ContentSubtypes' | translate }}">{{ 'SearchResult.ContentSubtypes' | translate
                }} </label>
              <label class="resultset-lbl" *ngIf="pageContentData?.advancedFilterParam.docSubType.length < 2 ">
                <span *ngFor="let contentsubType of pageContentData.advancedFilterParam.docSubType; let i = index"
                  class=" margin-left5">
                  <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;"
                    [attr.title]="contentsubType">
                    <div class="searchqryAdv pull-left">{{contentsubType}}</div>
                  </div>
                  <div class="close_content_single pull-right" *ngIf="!isOtherTabActive">
                    <i class="fa fa-times close-icon" (click)="setContentSubType(contentsubType)" role="button"></i>
                  </div>
                  <span [class.arabic-style]="isArabicLanguage"
                    *ngIf="i != pageContentData?.advancedFilterParam.docSubType.length - 1" class="operator">
                    {{ 'Common.OR' | translate }} </span>
                </span>
              </label>
              <div *ngIf="pageContentData?.advancedFilterParam.docSubType.length > 1"
                class="badge badgePop limiter-badge" [attr.title]="pageContentData.advancedFilterParam.docSubType[0]"
                (click)="limiterBadgeOnclick($event)" [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true"
                [outsideClick]="true" [popover]="contentsubTypepopOverTemplate" [placement]="'bottom'">
                <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.docSubType[0]}}</div>
                <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.docSubType.length -1}}
                  <i class="fa fa-caret-down"></i>
                </div>
                <div class="close_content pull-right">
                  <i class="fa fa-times close-icon" (click)="clearContentSubType()"></i>
                </div>
              </div>
              <ng-template #contentsubTypepopOverTemplate>
                <ul class="popoverUl">
                  <li *ngFor="let contentsubType of pageContentData.advancedFilterParam.docSubType; let i = index">
                    <span class="popover_inner_span">{{contentsubType}}</span>
                    <i class="fa fa-times icon-sizes" aria-hidden="true"
                      (click)="setContentSubType(contentsubType)"></i>
                  </li>
                </ul>
              </ng-template>
              <!-- <span *ngIf="pageContentData?.advancedFilterParam.publishedDateFrom > 0 || pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 || pageContentData?.advancedFilterParam.contentSources.length > 0  " class="operator">AND</span> -->
            </li>

            <li class="languages_result" *ngIf="pageContentData?.advancedFilterParam.languages.length > 0">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                title=" {{ 'SearchResult.Languages' | translate }}">{{ 'SearchResult.Languages' | translate }}</label>
              <label *ngIf="pageContentData?.advancedFilterParam.languages.length < 2">
                <span *ngFor="let language of pageContentData.advancedFilterParam.languages; let i = index"
                  class=" margin-left5">
                  <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="language">
                    <div class="searchqryAdv pull-left">{{language}}</div>
                  </div>
                  <div class="close_content_single pull-right" *ngIf="!isOtherTabActive">
                    <i class="fa fa-times close-icon" (click)="setLanguage(language)" role="button"></i>
                  </div>
                  <span [class.arabic-style]="isArabicLanguage"
                    *ngIf="i != pageContentData?.advancedFilterParam.languages.length - 1" class="operator">
                    {{ 'Common.OR' | translate }} </span>
                </span>
              </label>
              <!-- <span *ngIf=" pageContentData?.advancedFilterParam.contentCollection.length > 0 || pageContentData?.advancedFilterParam.contentSources.length > 0  " class="operator">AND</span> -->
              <div *ngIf="pageContentData?.advancedFilterParam.languages.length > 1"
                [attr.title]="pageContentData.advancedFilterParam.languages[0]" class="badge badgePop limiter-badge"
                (click)="limiterBadgeOnclick($event)" [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true"
                [outsideClick]="true" [popover]="languagePopOverTemplate" [placement]="'bottom'">
                <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.languages[0]}}</div>
                <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.languages.length -1}}
                  <i class="fa fa-caret-down"></i>
                </div>
                <div class="close_content pull-right">
                  <i class="fa fa-times close-icon" (click)="clearLanguage()"></i>
                </div>
              </div>
              <ng-template #languagePopOverTemplate>
                <ul class="popoverUl">
                  <li *ngFor="let language of pageContentData.advancedFilterParam.languages; let i = index">
                    <span class="popover_inner_span">{{language}}</span>
                    <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setLanguage(language)"></i>
                  </li>
                </ul>
              </ng-template>
            </li>
            <li class="contentCollection_result"
              *ngIf="pageContentData?.advancedFilterParam.contentCollection.length > 0">
              <label class="search-resultlbl" title="{{ 'SearchResult.Collections' | translate }} ">{{
                'SearchResult.Collections' | translate }} </label>
              <label [class.arabic-style]="isArabicLanguage"
                *ngIf="pageContentData?.advancedFilterParam.contentCollection.length < 2">
                <span *ngFor="let collection of pageContentData.advancedFilterParam.contentCollection; let i = index"
                  class=" margin-left5">
                  <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="collection">
                    <div class="searchqryAdv pull-left">{{collection}}</div>
                  </div>
                  <div class="close_content_single pull-right" *ngIf="!isOtherTabActive">
                    <i class="fa fa-times close-icon" (click)="clearCollection(collection)" role="button"></i>
                  </div>
                  <span [class.arabic-style]="isArabicLanguage"
                    *ngIf="i != pageContentData?.advancedFilterParam.contentCollection.length - 1" class="operator">
                    {{ 'Common.OR' | translate }} </span>
                </span>
              </label>
              <div *ngIf="pageContentData?.advancedFilterParam.contentCollection.length > 1"
                [attr.title]="pageContentData.advancedFilterParam.contentCollection[0]"
                class="badge badgePop limiter-badge" (click)="limiterBadgeOnclick($event)"
                [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true" [outsideClick]="true"
                [popover]="collectionsepopOverTemplate" [placement]="'bottom'">
                <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.contentCollection[0]}}</div>
                <div class="search-resultcount pull-left">
                  +{{pageContentData?.advancedFilterParam.contentCollection.length -1}}
                  <i class="fa fa-caret-down"></i>
                </div>
                <div class="close_content pull-right">
                  <i class="fa fa-times close-icon" (click)="clearCollection()"></i>
                </div>
              </div>
              <ng-template #collectionsepopOverTemplate>
                <ul class="popoverUl">
                  <li *ngFor="let collection of pageContentData.advancedFilterParam.contentCollection; let i = index">
                    <span class="popover_inner_span">{{collection}}</span>
                    <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setCollection(collection)"></i>
                  </li>
                </ul>
              </ng-template>
              <!-- <span *ngIf="pageContentData?.advancedFilterParam.contentSources.length > 0  " class="operator">AND</span> -->
            </li>
            <li class="contentSources_result" *ngIf="pageContentData?.advancedFilterParam.contentSources.length > 0">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                title="{{ 'SearchResult.Sources' | translate }}">{{ 'SearchResult.Sources' | translate }} </label>
              <label *ngIf="pageContentData?.advancedFilterParam.contentSources.length < 2">
                <span *ngFor="let source of pageContentData.advancedFilterParam.contentSources; let i = index"
                  class=" margin-left5">
                  <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="source">
                    <div class="searchqryAdv pull-left">{{source}}</div>
                  </div>
                  <div class="close_content_single pull-right" *ngIf="!isOtherTabActive">
                    <i class="fa fa-times close-icon" (click)="clearSource(source)" role="button"></i>
                  </div>
                  <span [class.arabic-style]="isArabicLanguage"
                    *ngIf="i != pageContentData?.advancedFilterParam.contentSources.length - 1" class="operator">
                    {{ 'Common.OR' | translate }} </span>
                </span>
              </label>
              <div *ngIf="pageContentData?.advancedFilterParam.contentSources.length > 1"
                [attr.title]="pageContentData.advancedFilterParam.contentSources[0]"
                class="badge badgePop limiter-badge" (click)="limiterBadgeOnclick($event)"
                [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true" [outsideClick]="true"
                [popover]="sourcepopOverTemplate" [placement]="'bottom'">
                <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.contentSources[0]}}</div>
                <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.contentSources.length
                  -1}}
                  <i class="fa fa-caret-down"></i>
                </div>
                <div class="close_content pull-right">
                  <i class="fa fa-times close-icon" (click)="clearSource()"></i>
                </div>
              </div>
              <ng-template #sourcepopOverTemplate>
                <ul class="popoverUl">
                  <li *ngFor="let source of pageContentData.advancedFilterParam.contentSources; let i = index">
                    <span class="popover_inner_span">{{source}}</span>
                    <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setSource(source)"></i>
                  </li>
                </ul>
              </ng-template>
            </li>

            <li class="contentSources_result" *ngIf="pageContentData?.advancedFilterParam.illustrationType.length > 0">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                title="{{ 'SearchResult.IllustrationType' | translate }}">{{ 'SearchResult.IllustrationType' | translate
                }} </label>
              <label *ngIf="pageContentData?.advancedFilterParam.illustrationType.length < 2">
                <span *ngFor="let illustration of pageContentData.advancedFilterParam.illustrationType; let i = index"
                  class=" margin-left5">
                  <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;"
                    [attr.title]="illustration">
                    <div class="searchqryAdv pull-left">{{illustration}}</div>
                  </div>
                  <div class="close_content_single pull-right" *ngIf="!isOtherTabActive">
                    <i class="fa fa-times close-icon" (click)="clearIllustration(illustration)" role="button"></i>
                  </div>
                  <span [class.arabic-style]="isArabicLanguage"
                    *ngIf="i != pageContentData?.advancedFilterParam.illustrationType.length - 1" class="operator">
                    {{ 'Common.OR' | translate }} </span>
                </span>
              </label>
              <div *ngIf="pageContentData?.advancedFilterParam.illustrationType.length > 1"
                [attr.title]="pageContentData.advancedFilterParam.illustrationType[0]"
                class="badge badgePop limiter-badge" (click)="limiterBadgeOnclick($event)"
                [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true" [outsideClick]="true"
                [popover]="illustrationpopOverTemplate" [placement]="'bottom'">
                <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.illustrationType[0]}}</div>
                <div class="search-resultcount pull-left">
                  +{{pageContentData?.advancedFilterParam.illustrationType.length -1}}
                  <i class="fa fa-caret-down"></i>
                </div>
                <div class="close_content pull-right">
                  <i class="fa fa-times close-icon" (click)="clearIllustration()"></i>
                </div>
              </div>
              <ng-template #illustrationpopOverTemplate>
                <ul class="popoverUl">
                  <li *ngFor="let illustration of pageContentData.advancedFilterParam.illustrationType; let i = index">
                    <span class="popover_inner_span">{{illustration}}</span>
                    <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setIllustration(illustration)"></i>
                  </li>
                </ul>
              </ng-template>
            </li>

            <li class="contentSources_result" *ngIf="pageContentData?.advancedFilterParam.subject.length > 0">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                title="{{ 'SearchResult.Subjects' | translate }}">{{ 'SearchResult.Subjects' | translate }} </label>
              <label *ngIf="pageContentData?.advancedFilterParam.subject.length < 2">
                <span *ngFor="let subject of pageContentData.advancedFilterParam.subject; let i = index"
                  class=" margin-left5">
                  <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="subject">
                    <div class="searchqryAdv pull-left">{{subject}}</div>
                  </div>
                  <div class="close_content_single pull-right" *ngIf="!isOtherTabActive">
                    <i class="fa fa-times close-icon" (click)="clearSubject(subject)" role="button"></i>
                  </div>
                  <span [class.arabic-style]="isArabicLanguage"
                    *ngIf="i != pageContentData?.advancedFilterParam.subject.length - 1" class="operator">
                    {{ 'Common.OR' | translate }} </span>
                </span>
              </label>
            </li>

            <li class=""
              *ngIf="pageContentData?.advancedFilterParam.publishedDateFrom || pageContentData?.advancedFilterParam.publishedDateIncludesNull"
              (click)="togglepublist = !togglepublist;$event.stopPropagation();"
              [ngClass]="{'popoverSelect':toggleviewlist, 'publishedDate_result' : (pageContentData?.advancedFilterParam.publishedDateTo || pageContentData?.advancedFilterParam.publishedDateIncludesNull) }">
              <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                title="{{ 'SearchResult.PublicationDate' | translate }}">{{ 'SearchResult.PublicationDate' | translate
                }}</label>
              <br>
              <span>
                <label *ngIf="pageContentData?.advancedFilterParam.publishedDateFrom">
                  <span class="badge margin-left5">
                    <span [class.select-pub-otherarchive]="isOtherTabActive" class="select-qry-pub">
                      <span
                        *ngIf="pageContentData?.advancedFilterParam.publishedDateFrom !== 'All'">{{pageContentData?.advancedFilterParam.publishedDatePrefix}}:</span>
                      {{pageContentData?.advancedFilterParam.publishedDateFrom}}
                      <span *ngIf="pageContentData?.advancedFilterParam.publishedDateTo">{{ 'Common.AND' | translate
                        }}</span>
                      <span
                        *ngIf="pageContentData?.advancedFilterParam.publishedDateTo.includes('-') && pageContentData?.advancedFilterParam.publishedDateTo.length > 5 && pageContentData?.advancedFilterParam.publishedDateTo.length > 8">
                        {{pageContentData?.advancedFilterParam.publishedDateTo}}
                      </span>
                      <span *ngIf="pageContentData?.advancedFilterParam.publishedDateTo.length === 5">
                        {{pageContentData?.advancedFilterParam.publishedDateTo.slice(0,4)}}
                      </span>
                      <span *ngIf="pageContentData?.advancedFilterParam.publishedDateTo.length < 5">
                        {{pageContentData?.advancedFilterParam.publishedDateTo}}
                      </span>
                      <span *ngIf="pageContentData?.advancedFilterParam.publishedDateTo.length === 8">
                        {{pageContentData?.advancedFilterParam.publishedDateTo.slice(0,7)}}
                      </span>
                    </span>
                    <span [class.arabic-style]="isArabicLanguage" class="undated"
                      *ngIf="pageContentData?.advancedFilterParam.publishedDateIncludesNull">
                      {{ 'SearchResult.ANDUNDATED' | translate }}
                    </span>

                    <!-- <i class="fa fa-times close-icon" aria-hidden="true" (click)="setPublicationDate()"></i> -->
                    <i *ngIf="!isOtherTabActive" class="fa fa-times close-icon" (click)="setPublicationDate()"></i>
                  </span>
                </label>
              </span>
              <!-- <span *ngIf="pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 || pageContentData?.advancedFilterParam.contentSources.length > 0  " class="operator">AND</span> -->
              <i *ngIf="pageContentData.advancedFilterParam.publishedDatePrefix === 'BETWEEN'" class="badge"
                aria-hidden="true" [outsideClick]="true" [popover]="pubDatepopOverTemplate" [placement]="'bottom'"></i>
              <ng-template #pubDatepopOverTemplate>
                <ul class="popoverUl">
                  <li>
                    <i class="fa fa-times icon-sizes" aria-hidden="true"
                      (click)="setPublicationDate()"></i>{{pageContentData?.advancedFilterParam.publishedDatePrefix}}:
                    <span [class.select-pub-otherarchive]="isOtherTabActive"
                      class="select-qry-pub">{{pageContentData?.advancedFilterParam.publishedDateFrom}} {{
                      'Common.AND' | translate }} {{pageContentData?.advancedFilterParam.publishedDateTo}}
                    </span>
                  </li>
                </ul>
              </ng-template>
            </li>

          </ul>
        </ul>
      </div>
      <div class="col-md-12 paddingBottom-0 marginBottom" *ngIf="(searchResultCount) && !resultLoader ">
        <div class="floatLeft">
          <div class="paddingLeft searchRecord">
            <label class="resultlbl" *ngIf="searchResultCount && !(pageContentData?.groupsToBeDisplayed.length > 0)"
              title="{{searchResultCount}} {{ 'SearchResult.DocumentsFound' | translate }}">{{searchResultCount}}
              <span [class.arabic-style]="isArabicLanguage" class="result-span">{{ 'SearchResult.DocumentsFound' |
                translate }}</span>
            </label>
            <label *ngIf="pageContentData?.groupsToBeDisplayed.length > -1">
              <span class="resultlbl" *ngFor="let group of groups; let i = index;">
                <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'SearchResult.searchContent' |
                  translate }}</span>
                <label *ngIf="group.group === pageContentData?.groupsToBeDisplayed[0]"
                  title="{{group?.totalGroupElements}} {{ 'SearchResult.DocumentsFound' | translate }}">{{group?.totalGroupElements}}
                  <span [class.arabic-style]="isArabicLanguage" class="result-span">{{ 'SearchResult.DocumentsFound' |
                    translate }}</span>
                </label>
              </span>
            </label>
          </div>


        </div>

        <div
          *ngIf="fieldVal == true || pageContentData?.searchTerm || pageContentData?.advancedFilterParam.contentType.length > 0 ||
            pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  != '' ||
            pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
            pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length > 0"
          class="floatLeft cleardiv" (click)="resetSearchFilter()" tabindex="0" (keyup.enter)="resetSearchFilter()">
          <!-- && !(pageContentData?.groupsToBeDisplayed.length > 0) -->
          <span *ngIf="searchResultCount" class="spriteico clearIcon"></span>
          <label [class.arabic-style]="isArabicLanguage" *ngIf="searchResultCount" class="bm-page"
            title="{{ 'SearchResult.ClearSearch' | translate }} ({{shortcutKeyTitle}} + Delete)">{{
            'SearchResult.ClearSearch' | translate }}</label>

        </div>



        <div class="floatRight">
          <div class="paddingRight">
            <ul class="search-resultul">
              <li>
                <span class="i-Maps-icons"></span>
              </li>
              <li class="search-resultli right-seperator">
                <span id="cursor-not-allowed-map">
                  <span tabindex="0" class="fa fa-globe" [ngClass]="{'active': _isInMapView}"
                    title="{{ 'SearchResult.ToggleMapView' | translate }} ({{shortcutKeyTitle}} + Z)" id="mapIcon"
                    aria-hidden="true" (click)="mapViewClick()" (keyup.enter)="mapViewClick()"></span>
                </span>
              </li>
              <li class="search-resultli right-seperator">
                <span id="CursorNotAllowed"
                  [ngClass]="{'cursornotallowed' : photographView || scrollDisplay === 'Photographs'}">
                  <span class="fa fa-list" title="{{ 'SearchResult.Listview' | translate }} ({{shortcutKeyTitle}} + L)"
                    id="ListIcon" [ngClass]="getStyleListDisplay()" aria-hidden="true" (click)="listViewClick('list')"
                    tabindex="0" (keyup.index)="listViewClick('list')" [ngClass]="{'disabled'
: (scrollDisplay === 'Photographs')}"></span>
                </span>
              </li>
              <li class="search-resultli vertical-line">
                <span class="fa fa-th" title="{{ 'SearchResult.Gridview' | translate }} ({{shortcutKeyTitle}} + L)"
                  id="GridIcon" [ngClass]="getStyleGridDisplay()" aria-hidden="true" (click)="gridViewClick('grid')"
                  tabindex="0" (keyup.index)="gridViewClick('grid')"></span>
              </li>
              <!-- <li class="search-resultli" (click)="bookmarkClick()" tabindex="0" (keyup.enter)="bookmarkClick()"
                title="{{ 'SearchResult.BookmarkPage' | translate }}  ({{shortcutKeyTitle}} + K)">
                <span class="spriteico addbookmarkIcon"></span>
                <label [class.arabic-style]="isArabicLanguage" class="bm-page"> {{ 'SearchResult.BookmarkPage' |
                  translate }}</label>
              </li> -->

              <li class="search-resultli dropdown internalShare" (click)="internalShareToggleList($event)">
                <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                  title="{{ 'SearchResult.ShareSearchQryTxt' | translate }} ({{shiftContrlKey}} + Y)"
                  href="javascript:void(0)">
                  <!-- <span class="spriteico shareIcon dropdown-toggle"></span> -->
                  <span class="spriteico shareIcon"></span>
                  <label [class.arabic-style]="isArabicLanguage" class="bm-page"> {{ 'SearchResult.ShareSearchQryTxt' |
                    translate }}</label>
                </a>
                <div class="dropdown-menu shareWidth" *ngIf="!isGuestUser" role="menu"
                  [ngStyle]="{'display': toggleInternalShareList ? 'flex': 'none'}">
                  <div class="shareLimitedPanel">
                    <h4>{{'Detail.shareIntenalUsers' |translate}}</h4>
                    <!-- <p>{{'Detail.wileyInternalShareContent' |translate}}</p> -->
                    <p>{{'SearchResult.ShareSearchQueryContent' |translate}}</p>
                    <!-- <a class="bookMarkInternalLinkCls" href="{{bookmarkEntireDocURL}}" (click)="$event.preventDefault();$event.stopPropagation();">{{bookmarkEntireDocURL}}</a> -->
                    <span class="bookMarkLink">{{bookmarkEntireDocURL}}</span>
                    <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation();"
                      id="shareIconsWidth">
                      <li style="display: inline-flex;">
                        <div class="mailBtn m-r-3">
                          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon"
                            (click)="shareMailClick(bookmarkEntireDocURL)"
                            title="{{ 'Detail.shareByMail' | translate }}">
                            <i class="fa fa-envelope"></i>
                            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.mailIcon' |
                              translate }}</span>
                          </button>
                        </div>
                        <div class="mailBtn m-r-3">
                          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon share-bg"
                            (click)="copytoClipBoard(bookmarkEntireDocURL,isFromShare)"
                            title="{{ 'Detail.copytoClipboard' | translate }}">
                            <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
                            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{'Common.Copy'
                              |translate}}</span>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>

        <div class="tabs" *ngIf="showOtherTab">
          <div>
            <ul class="archives-tab">
              <li class="docviewer-tabs" [ngClass]="{'active':!isOtherTabActive}"
                (click)="otherTabsetclick('selected')">Search Results</li>
              <li class="docviewer-tabs" [ngClass]="{'active':isOtherTabActive}"
                [attr.disabled]="isdisableOtherArchives" (click)="otherTabsetclick('other')">View Results from Additional Archives</li>
            </ul>
            <div *ngIf="documentFromOtherArchives" class="doc-access-link">
              <p class="bold-txt">For access to these additional archive(s) please contact your librarian.
              </p>
            </div>

          </div>

        </div>

      </div>
    </div>
    <p [class.arabic-style]="isArabicLanguage" *ngIf="resultLoader">{{'SearchResult.loadingPleaseWait' | translate}}</p>
    <p [class.arabic-style]="isArabicLanguage" *ngIf="!(searchResultCount) && !resultLoader">{{
      'SearchResult.NoResultsFound' | translate }}
      <a [class.arabic-style]="isArabicLanguage" class="backtosearch-link" (click)="returntoSearch()">{{
        'SearchResult.return' | translate }}</a> {{ 'SearchResult.toPreviousSearch' | translate }}
    </p>
    <div class="padding-0" id="searchResultDiv"
      [ngClass]="{ 'col-xs-8 col-sm-6 col-md-5 col-lg-4': _isInMapView, 'col-sm-12 defaultView': !_isInMapView, 'search-result-float-menu': _isInMapView, 'heatmap-result-leftbar': (showLeftMenu &&_isInMapView), 'collapsed-leftmenu': (!showLeftMenu &&_isInMapView) , 'isSliderOpen': isSliderPopover}">

      <!-- maps view -->
      <div class="row" [style.display]="_isInMapView ? 'block' : 'none'">
        <div class="col-sm-12 padding-0 toggle-filterdiv" [ngStyle]="{'display': showLeftMenu ? 'block': 'none'}">
          <div [class.arabic-style]="isArabicLanguage"
            class="col-sm-9 toggle-filterdivleft padding-left-0 padding-right-0">
            <label [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.publicationBrowser' | translate }}</label>
          </div>

          <div class="col-sm-3 toggle-filterrt toggle-filterright-arrow padding-left-0 padding-right-0" tabindex="0">
            <a class="sidebar-toggle" title="{{ 'SearchResult.CollapseTheListView' | translate }}" role="button"
              (click)="toggleLeftMenu()" tabindex="0" (keyup.enter)="toggleLeftMenu()">
              <i aria-hidden="true" class="fa fa-chevron-left fa-lg"></i>
            </a>
          </div>
        </div>
        <div class="toggle-filterrightdiv" [ngStyle]="{'display': showLeftMenu ? 'none': 'block'}">
          <a class="sidebar-toggle" title="{{ 'SearchResult.ExpandTheListView' | translate }}" role="button"
            (click)="toggleLeftMenu()" tabindex="0" (keyup.enter)="toggleLeftMenu()">
            <i aria-hidden="true" class="fa fa-chevron-right fa-lg"></i>
          </a>
        </div>
      </div>
      <div class="col-md-12 padding-0" scrollable (scrollPosition)="scrollHandler($event)"
        [ngClass]="{' search-result-float-menu-content' : _isInMapView}" *ngIf="!resultLoader">
        <div [class.arabic-style]="isArabicLanguage" *ngIf="groups?.length == 0 && noResultInMap"
          [style.display]="_isInMapView ? 'block' : 'none'">
          <span [class.arabic-style]="isArabicLanguage" class="noResultFound">
            {{ 'SearchResult.PleaseSelectArea' | translate }}
            <span class="graphicDot"></span> {{ 'SearchResult.IsPresent' | translate }}
          </span>
        </div>
        <accordion [isAnimated]="true" [style.display]="(showLeftMenu || !_isInMapView) ? 'block' : 'none'" *ngIf="groups?.length > 0"
          class="searchresult-accordion">
          <ng-container *ngFor="let group of groups; let i = index;">
            <!-- //[ngClass]="{'order1':group?.group == 'Monographs','order2':group?.group == 'Manuscripts','order3':group?.group == 'Photographs','order4':group?.group == 'Maps'}" -->
            <accordion-group 
              [ngClass]="{'order1':(group?.group == 'Manuscripts' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false)) || (isContentTypePhoto && group?.group == 'Photographs') || (isContentTypeMap && group?.group == 'Maps') || (contentTypeList.length>0 && group?.order== '1'),
            'order2':(group?.group == 'Monographs' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false))|| (isContentTypePhoto && group?.group == 'Manuscripts') || (isContentTypeMap && group?.group == 'Manuscripts') || (contentTypeList.length>0 && group?.order== '2'),
            'order3':(group?.group == 'Photographs' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false))  || (isContentTypePhoto && group?.group == 'Maps') || (isContentTypeMap && group?.group == 'Photographs') || (contentTypeList.length>0 && group?.order== '3'),
            'order4':(group?.group == 'Maps' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false)) || (contentTypeList.length>0 && group?.order== '4') }"
              *ngIf="pageContentData?.groupsToBeDisplayed?.length == 0 ? true : pageContentData?.groupsToBeDisplayed?.indexOf(group?.group) > -1"
              [isOpen]="true" (isOpenChange)="checkAccordionStatus(i, $event)">
              <div class="heading" accordion-heading>
                <i *ngIf="group?.group == 'Monographs'" class="acc_icon monographIcon" aria-hidden="true"></i>
                <i *ngIf="group?.group == 'Manuscripts'" class="acc_icon manuscriptsIcon" aria-hidden="true"></i>
                {{group?.group}}
                <span class="doc-count"> {{' (' + group?.totalGroupElements + ')'}} </span>
                <i *ngIf="group?.group == 'Photographs'" class="acc_icon photographIcon" aria-hidden="true"></i>
                <i *ngIf="group?.group == 'Maps'" class="fa fa-map-marker  map-icon"
                  [ngClass]="{'map-icon-collapsed': !togglesearchicon[i]  }" aria-hidden="true"></i>
                <i class="fa fa-chevron-down pull-right accordion-upicn accordionDownIcon{{i}}"
                  [ngStyle]="{'display': !togglesearchicon[i] ? 'block': 'none'}"></i>
                <i class="fa fa-chevron-up pull-right accordion-upicn  accordionDownIcon{{i}}"
                  [ngStyle]="{'display': togglesearchicon[i] ? 'block': 'none'}"></i>
              </div>

              <div #getScrollElement [attr.data-scrollgroup]="group?.group"
                    [attr.data-scrolldoclength]="group?.documents.length"
                    [attr.data-scrolltotalgroup]="group?.totalGroupElements" [attr.data-gridviewscroll]="false"
                    [ngClass]="{'search-resultsRemove': scrollDisplay, 'nosearch': !scrollDisplay}">
                    <div [class.pad-btm-10]=" _isInMapView" [ngClass]="setGridClasses(group?.group)"
                      [class.mapView-enable]="_isInMapView" [class.gridview-enable]="showGridView"
                      [class.listview-enabled]="!showGridView" [class.listview-only]="!showGridView && !_isInMapView"
                      [class.pointernone]="documentFromOtherArchives && (group?.group !== 'Photographs')"
                      [class.pointersnone]="documentFromOtherArchives && (group?.group === 'Photographs')"
                      class="col-md-12 col-xs-12 accordian-inner listViewSwitch padding-right-0"
                      *ngFor="let document of group?.documents; let j = index;">
                      <div [ngClass]="{ 'col-sm-4 col-lg-3': _isInMapView, 'col-sm-2 col-md-1': !_isInMapView }"
                        class="sub-content col-sm-4 col-xs-3 padding-left-0 img_pad {{group?.group}}class "
                        *ngIf="group?.documents.length > 0">
                        <div class="searchresult-imgdiv " [tooltip]="pageTitle" placement="auto"
                          aria-hidden="true">
                          <ng-container
                            *ngIf="((document.archive?.toUpperCase() === 'RAI') && (group?.group === 'Photographs'))">
                            <a (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                              (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}">
                              <img class="accordian-img center-block img-responsive"
                                [attr.src]="document.folderImages ? document.folderImages[0]+ '&type=small' : pageContentData.hasFolderImage &&
                                                    showFolderImage?.locations[document.archive]?.showFolderImage == 'false' &&
                                                    (group?.group === 'Manuscripts' || group?.group === 'Photographs')?
                                                    (document.imageThumbnails?.length > 1  ? document.imageThumbnails[1] + '&type=small' : document.imageThumbnails[0] + '&type=small' ) : (document.imageThumbnails ? document.imageThumbnails[0] + '&type=small' : noImageUrl )"
                                alt="{{'Alt.imageThumbnails' | translate}}" (error)="errorHandler($event)" />
                            </a>
                          </ng-container>
                          <ng-container
                            *ngIf="!((document.archive?.toUpperCase() === 'RAI') && (group?.group === 'Photographs'))">
                            <a (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                              (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}">
                              <img class="accordian-img center-block img-responsive"
                                [attr.src]="pageContentData.hasFolderImage &&
                                                    showFolderImage?.locations[document.archive]?.showFolderImage == 'false' &&
                                                    (group?.group === 'Manuscripts' || group?.group === 'Photographs')?
                                                    (document.imageThumbnails?.length > 1  ? document.imageThumbnails[1] + '&type=small' : document.imageThumbnails[0] + '&type=small' ) : (document.imageThumbnails ? document.imageThumbnails[0] + '&type=small' : noImageUrl )"
                                alt="{{'Alt.imageThumbnails' | translate}}" (error)="errorHandler($event)" />
                            </a>
                          </ng-container>
                          <ng-template #imageSliderPOpover>
                            <div class="">
                              <app-image-swipe-slider [documentIndex]="j" [document]="document" [group]="group?.group"
                                [thumbnailImages]="document.imageThumbnails"
                                (callDocViewerOutput)="callDocViewerFromOutput($event)"
                                [hasFolderImage]="pageContentData.hasFolderImage && showFolderImage?.locations[document.archive]?.showFolderImage == 'false' && (group?.group === 'Manuscripts' || group?.group === 'Photographs')">
                              </app-image-swipe-slider>
                            </div>
                          </ng-template>

                           <!-- <ng-template class="{{group?.group}}GridTooltip" #pageTitle
                          [ngClass]="getdisplayOption(group?.group)"> -->
                          <ng-template #pageTitle>
                          <div class="pagetitle-popoverdiv" style="overflow: auto;">
                            <a (contextmenu)="onRightClick(document,group?.group, $event)"
                              (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                              title="{{ document?.title}}"
                              [class.popup-doc-title-elipsis]="document?.title?.length > 70"
                              class="search-result-header-title popup-document-title"
                              [class.docVisited]="visitedDocList.indexOf(document.id)> -1"
                              appDecodeEntities>{{document.title}}</a>
                            <div [innerHtml]="document.description"></div>
                            <div class="image_count-photograph">
                              <span [class.arabic-style]="isArabicLanguage" class="img_count"> {{document.pageCount}}
                              </span>
                              {{ (document.pageCount > 1) ? ('SearchResult.ImagesInThis' | translate) :
                              ('SearchResult.ImageInThis' | translate) }} {{(group?.group?.substr(-1) ===
                              's')?group?.group?.substring(0,(group?.group?.length
                              - 1)) :group?.group }} {{'SearchResult.imageCollection' | translate}}
                            </div>
                          </div>
                          </ng-template> 
                       
                      
                        </div>
                        <ng-container *ngIf="!_isInMapView && !popoverforIpad">
                          <i class="view-icn {{group?.group}}-icon"
                            title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}"
                            (click)='imgsliderToggle($event)' tabindex="0" (keyup.enter)='imgsliderToggle($event)'
                            tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                            [popover]="imageSliderPOpover" [placement]="'left'"></i>
                          <img *ngIf="document?.processType === 'atr'"
                            tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                            class="atr-list-img-icon" [class.full-wdth-atr-style]="!leftMenuStatus"
                            [class.mapview-atr]="_isInMapView" [class.grid-atr-cls]="showGridView"
                            src="assets/images/icons/ATRN3.png">
                          <img *ngIf="document?.processType === 'drone'"
                            tooltip="This large-format map was captured by a drone." placement="right" alt="Drone Icon"
                            class="atr-list-img-icon" [class.full-wdth-atr-style]="!leftMenuStatus"
                            [class.mapview-atr]="_isInMapView" [class.grid-atr-cls]="showGridView"
                            src="assets/images/icons/Drone1.png">

                          <i *ngIf="document?.isGeoBoundaryExist" class="view-icn {{group?.group}}-icon geo-icon"
                            title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                            (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                            (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>

                          <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                            *ngIf="group?.group == 'Photographs' || showGridView"
                            title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                            {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                            ('SearchResult.image' | translate)
                            }}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="_isInMapView && !popoverforIpad">
                          <i class="view-icn {{group?.group}}-icon"
                            title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}" tabindex="0"
                            (keyup.enter)='imgsliderToggle($event)' (click)='imgsliderToggle($event)'
                            tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                            [popover]="imageSliderPOpover" [placement]="'right'"></i>


                          <img *ngIf="document?.processType === 'atr'"
                            tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                            class="atr-list-img-icon" [class.full-wdth-atr-style]="!leftMenuStatus"
                            [class.grid-atr-cls]="showGridView" [class.mapview-atr]="_isInMapView"
                            src="assets/images/icons/ATRN3.png">
                          <img *ngIf="document?.processType === 'drone'" alt="Drone Icon"
                            [class.full-wdth-atr-style]="!leftMenuStatus" [class.grid-atr-cls]="showGridView"
                            [class.mapview-atr]="_isInMapView" class="atr-list-img-icon"
                            src="assets/images/icons/Drone1.png"
                            tooltip="This large-format map was captured by a drone." placement="right">

                          <i *ngIf="document?.isGeoBoundaryExist" class="view-icn {{group?.group}}-icon geo-icon"
                            title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                            (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                            (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>

                          <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                            *ngIf="group?.group == 'Photographs' || showGridView"
                            title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                            {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                            ('SearchResult.image' | translate)
                            }}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!_isInMapView && popoverforIpad">
                          <i class="view-icn" title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}"
                            (click)='imgsliderToggle($event)' tabindex="0" (keyup.enter)='imgsliderToggle($event)'
                            tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                            [popover]="imageSliderPOpover" [placement]="'right'"></i>

                          <img *ngIf="document?.processType === 'atr'"
                            tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                            class="atr-list-ipad atr-list-img-icon" [class.grid-atr-cls]="showGridView"
                            [class.full-wdth-atr-style]="!leftMenuStatus" [class.mapview-atr]="_isInMapView"
                            src="assets/images/icons/ATRN3.png">
                          <img *ngIf="document?.processType === 'drone'" alt="Drone Icon"
                            class="atr-list-ipad atr-list-img-icon" [class.grid-atr-cls]="showGridView"
                            [class.full-wdth-atr-style]="!leftMenuStatus" [class.mapview-atr]="_isInMapView"
                            src="assets/images/icons/Drone1.png"
                            tooltip="This large-format map was captured by a drone." placement="right">

                          <i *ngIf="document?.isGeoBoundaryExist" class="view-icn {{group?.group}}-icon geo-icon"
                            title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                            (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                            (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>

                          <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                            *ngIf="group?.group == 'Photographs' || showGridView"
                            title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                            {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                            ('SearchResult.image' | translate)
                            }}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="_isInMapView && popoverforIpad">
                          <i class="view-icn" title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}" tabindex="0"
                            (click)='imgsliderToggle($event)' (keyup.enter)='imgsliderToggle($event)'
                            tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                            [popover]="imageSliderPOpover" [placement]="'right'"></i>

                          <img *ngIf="document?.processType === 'atr'"
                            tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                            class="atr-list-img-icon" [class.mapview-atr]="_isInMapView"
                            [class.grid-atr-cls]="showGridView" [class.full-wdth-atr-style]="!leftMenuStatus"
                            src="assets/images/icons/ATRN3.png">
                          <img *ngIf="document?.processType === 'drone'" alt="Drone Icon" class="atr-list-img-icon"
                            [class.grid-atr-cls]="showGridView" [class.full-wdth-atr-style]="!leftMenuStatus"
                            src="assets/images/icons/Drone1.png" [class.mapview-atr]="_isInMapView"
                            tooltip="This large-format map was captured by a drone." placement="right">

                          <i *ngIf="document?.isGeoBoundaryExist" class="view-icn {{group?.group}}-icon geo-icon"
                            title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                            (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                            (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>

                          <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                            *ngIf="group?.group == 'Photographs' || showGridView"
                            title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                            {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                            ('SearchResult.image' | translate)
                            }}
                          </div>
                        </ng-container>
                      </div>

                      <div
                        class="padding-left-0 padding-right-0 accodion-panel {{group?.group}}show getClassShow(group?.group)"
                        [ngClass]="{ 'col-md-7 col-sm-6 col-xs-7': _isInMapView ,'col-md-11 col-sm-10 col-xs-9' : !_isInMapView}">
                        <div class="col-md-12 col-sm-12 col-xs-12 gridview-panel" [ngClass]="paddingLeft(group?.group)">
                          <div class="panel-heading"
                            [class.content-fullwidth]="group?.group !== 'Manuscripts' && !_isInMapView"
                            [ngClass]="getClassHide(group?.group)" [class.panel-block]="!_isInMapView">
                            <h2 data-toggle="tooltip1" data-placement="right">
                              <div class="searchResultHeaderContainer">
                                <!-- tabindex="0"  -->
                                <label title="{{ document.title }}"
                                  [ngClass]="{'headerTitle': !_isInMapView || (_isInMapView && document?.title.length > 40) }"
                                  class="search-result-header-title"
                                  title="Right click to open link in new tab - {{document?.title}}" tabindex="0"
                                  (keyup.enter)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                                  (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                                  (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}"
                                  [class.docVisited]="visitedDocList.indexOf(document.id)> -1">{{document.title}}
                                </label>
                              </div>
                            </h2>
                            <div *ngIf="!_isInMapView">
                              <div [innerHtml]="document.description"></div>
                              <div [class.arabic-style]="isArabicLanguage"
                                *ngIf="pageContentData.currentArchive === 'WILEY'" class="source-citation">
                                <label [class.arabic-style]="isArabicLanguage" class="left-source">{{'Common.Source' |
                                  translate}} &nbsp;</label>
                                <label *ngIf="document.archive ==='BAAS'" class="right-source italic">{{document.archive
                                  ==='BAAS' ? archivefullName :
                                  document.sourceInstitution}}</label>
                                <label *ngIf="document.archive ==='ENV'" class="right-source italic">{{document.archive
                                  ==='ENV' ? ENVArchiveFullName :
                                  document.sourceInstitution}}</label>
                                <label *ngIf="document.archive !=='BAAS' && document.archive !=='ENV'"
                                  class="right-source italic">{{document.archive ==='BAAS' ? archivefullName :
                                  document.sourceInstitution}}</label>
                              </div>
                              <ng-container *ngIf="detailsRedirectParams['start']= j">
                                <span *ngIf="false">{{SearchResult.coverflow | translate}}</span>
                              </ng-container>
                              <div *ngIf="group?.group == 'Manuscripts'" class="image_count col-md-12 padding0">
                                <div class="col-xs-12 col-sm-6 col-md-6 padding0">
                                  <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                    {{document.pageCount}}
                                  </span> {{ 'SearchResult.ImagesInThis' | translate }} {{(group?.group?.substr(-1) ===
                                  's')?group?.group?.substring(0,(group?.group?.length
                                  - 1)) :group?.group }}
                                </div>
                                <div
                                  class="col-xs-12 col-sm-6 alignTextLeft col-md-6 pull-right padding0 text-right browse-collection-div">
                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="open-new-tab-link collection-icon"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                  </a>
                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="browse-collection-text"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                    {{ 'SearchResult.browseThisCollection' | translate }} </a>
                                  <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                    title="{{ 'SearchResult.openInNewTab' | translate }}"
                                    class="open-new-tab-link open-tab-icon" [routerLink]="['../detail/' + document.id,
                                  detailsRedirectParams]" [ngStyle]="{'border-left': document?.collectionNo ? '1px solid
                                  #61165e': 'none'}">
                                  </a>
                                </div>
                                <!-- <a *ngIf="document?.referenceNo && document?.referenceNo !== null" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'Detail.referenceNo' | translate }}" class="open-new-tab-link"
                                    (click)="setReferenceNumber(document?.referenceNo)">{{document?.referenceNo}}
                                  </a> -->
                              </div>
                              <div *ngIf="group?.group == 'Monographs'" class="image_count col-md-12 padding0">
                                <div class="col-xs-12 col-sm-6 col-md-6 padding0">
                                  <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                    {{document.pageCount}}
                                  </span>
                                  {{ (document.pageCount > 1) ? ('SearchResult.ImagesInThis' | translate) :
                                  ('SearchResult.ImageInThis' | translate) }} {{(group?.group?.substr(-1)===
                                  's')?group?.group?.substring(0,(group?.group?.length - 1)) :group?.group }}
                                </div>
                                <div class="col-xs-12 col-sm-6 alignTextLeft  col-md-6 pull-right padding0 text-right">
                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="open-new-tab-link collection-icon"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                  </a>
                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="browse-collection-text"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                    {{ 'SearchResult.browseThisCollection' | translate }} </a>

                                  <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                    title="{{ 'SearchResult.openInNewTab' | translate }}"
                                    class="open-new-tab-link open-tab-icon" [routerLink]="['../detail/' + document.id,
                                      detailsRedirectParams]" [ngStyle]="{'border-left': document?.collectionNo ? '1px solid
                                  #61165e': 'none'}"></a>
                                </div>
                                <!-- <a *ngIf="document?.referenceNo && document?.referenceNo !== null" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'Detail.referenceNo' | translate }}" class="open-new-tab-link"
                                    (click)="setReferenceNumber(document?.referenceNo)">{{document?.referenceNo}}
                                  </a> -->
                              </div>
                              <div *ngIf="group?.group == 'Photographs'" class="image_count col-md-12 padding0">
                                <div class="col-xs-12 col-sm-6 col-md-6  padding0">
                                  <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                    {{document.pageCount}}
                                  </span> {{ 'SearchResult.imagesInThisPhotographicCollection' | translate }}
                                </div>
                                <div class="col-xs-12 col-sm-6 alignTextLeft col-md-6 pull-right padding0 text-right">
                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="open-new-tab-link collection-icon"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                  </a>

                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="browse-collection-text"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                    {{ 'SearchResult.browseThisCollection' | translate }} </a>

                                  <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                    title="{{ 'SearchResult.openInNewTab' | translate }}"
                                    class="open-new-tab-link open-tab-icon"
                                    [routerLink]="['../detail/' + document.id, detailsRedirectParams]"></a>

                                </div>
                                <!-- <a *ngIf="document?.referenceNo && document?.referenceNo !== null" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'Detail.referenceNo' | translate }}" class="open-new-tab-link"
                                    (click)="setReferenceNumber(document?.referenceNo)">{{document?.referenceNo}}
                                  </a> -->
                              </div>
                              <div *ngIf="group?.group == 'Maps'" class="image_count col-md-12 padding0">
                                <div class="col-xs-12 col-sm-6 col-md-6 padding0">
                                  <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                    {{document.pageCount}}
                                  </span>
                                  {{ (document.pageCount > 1) ? ('SearchResult.ImagesInThis' | translate) :
                                  ('SearchResult.ImageInThis' | translate) }} {{(group?.group?.substr(-1)===
                                  's')?group?.group?.substring(0,(group?.group?.length - 1)) :group?.group }}
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 alignTextLeft  pull-right text-right padding0">
                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="open-new-tab-link collection-icon"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)"></a>

                                  <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                    class="browse-collection-text"
                                    (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                    {{ 'SearchResult.browseThisCollection' | translate }} </a>

                                  <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                    title="{{ 'SearchResult.openInNewTab' | translate }}"
                                    class="open-new-tab-link open-tab-icon" [routerLink]="['../detail/' + document.id,
                                        detailsRedirectParams]" [ngStyle]="{'border-left': document?.collectionNo ? '1px solid
                                  #61165e': 'none'}"> </a>
                                </div>
                                <!-- <a *ngIf="document?.referenceNo && document?.referenceNo !== null" [class.arabic-style]="isArabicLanguage"
                                    title="{{ 'Detail.referenceNo' | translate }}" class="open-new-tab-link"
                                    (click)="setReferenceNumber(document?.referenceNo)">{{document?.referenceNo}}
                                  </a> -->
                              </div>
                            </div>
                            <div class="mobileContentSubType"
                              *ngIf="group?.group == 'Manuscripts' || group?.group == 'Monographs'"
                              [ngClass]="getClassHide(group?.group)">
                              <a [class.arabic-style]="isArabicLanguage" href="#" class="dropdown-toggle"
                                data-toggle="dropdown">
                                <button [class.arabic-style]="isArabicLanguage" type="button"
                                  class="btn contentsubType">
                                  {{ 'SearchResult.ContentSubtypes' | translate }}
                                  <b class="caret"></b>
                                </button>
                              </a>
                              <ul class="dropdown-menu">
                                <li *ngFor="let docsubType of document?.docSubType?.split(',')"
                                  (click)="documentSubtypeList(document.id, docsubType, j)">
                                  <a>{{docsubType}}</a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="panel-rightcontent" [style.display]="_isInMapView ? 'none' : 'block'"
                            *ngIf="group?.group == 'Manuscripts'" [ngClass]="getClassHide(group?.group)">
                            <div class="tooltip-box">
                              <div class="tooltip-box-scroll"></div>
                              <!-- <perfect-scrollbar [config]="config" class="tooltip-box-scroll"> -->
                                <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                  *ngFor="let docsubType of document?.docSubType?.split(',')"
                                  (click)="documentSubtypeList(document.id, docsubType, j)"
                                  title="{{docsubType}}">{{docsubType}}</a>
                              <!-- </perfect-scrollbar> -->
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="{{group?.group}}GridIcon illustration_list" [ngClass]="{'icon-width' : _isInMapView}"
                        *ngIf="!_isInMapView">
                        <div class="listview-icon search-div-icon">
                          <ul class="search-iconsdiv photographwidth {{group?.group}}ListViewIcon"
                            [class.positions]="document?.latlon" [ngClass]="getgridviewList(group?.group)"
                            [class.gridviewBorder]="gridViewClick"
                            [ngStyle]="{'border-left': group?.group == 'Manuscripts' ? 'none' : '1px solid #d2d2da'}">
                            <li tabindex="0" (keyup.enter)="getDocIllustrations(document.id, j)"
                              (click)="getDocIllustrations(document.id, j)"
                              *ngIf="document.isIllustrationExist == true  && group?.group !== 'Photographs' && group?.group !== 'Maps'">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                href="javascript:void(0)" class="serch-icns img-icon"
                                (click)="imageClick(document.title)">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.BrowseIllustrations' | translate }}</span>
                                <span class="spriteico illustrationsIcon" [class.gridViewIcons]="showGridView"
                                  title="{{ 'SearchResult.BrowseIllustrations' | translate }}" aria-hidden="true">
                                  <span class="illustrationCount not-mapview" *ngIf="document.illustrationCount"
                                    [ngStyle]="{'left': group?.group == 'Monographs' ? '50px': '16px'}">
                                    <div [class.arabic-style]="isArabicLanguage" class="illustration-badge">
                                      {{document.illustrationCount}}</div>
                                  </span>
                                </span>

                              </a>
                            </li>
                            <li tabindex="0" [class.gridviewIllustration]="gridViewClick"
                              *ngIf="document.isIllustrationExist == false && group?.group !== 'Photographs' && group?.group !== 'Maps'">
                              <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                class="serch-icns img-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.BrowseIllustrationsNotFound' | translate }}</span>
                                <span class="spriteico illustrationsIcon img-disable"
                                  [class.gridViewIcons]="showGridView" [class.illustrationfour]="document?.latlon"
                                  title="{{ 'SearchResult.BrowseIllustrationsNotFound' | translate }}"
                                  aria-hidden="true"></span>
                              </a>
                            </li>
                            <!-- <li tabindex="0" (keyup.enter)="bookmarkClick(document.id)"
                              (click)="bookmarkClick(document.id)" [class.gridBookmark]="gridViewClick"
                              *ngIf="bookmarkedResults.indexOf(document.title) == -1">
                              <a href="javascript:void(0)" class="serch-icns bookmark-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                    'SearchResult.BookmarkDocument' | translate }}</span>
                                <span class="spriteico addbookmarkIcon" [class.gridViewIcons]="showGridView"
                                  [class.bookmarkforfour]="document?.latlon"
                                  title="{{ 'SearchResult.BookmarkDocument' | translate }}" aria-hidden="true"></span>
                              </a>
                            </li> -->
                            <li class="menu-list dropdown documentLevelShare socialShare{{j}}"
                              (click)="socialShare(document,j, group?.group);$event.stopPropagation();">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                title="{{ 'Detail.Share' | translate }}" href="javascript:void(0)">
                                <span class="spriteico shareIcon dropdown-toggle"></span>
                              </a>
                              <div *ngIf="document.enableShare">
                                <app-share-document class="shareDocumentClss" [bookMarkShare]="document.enableShare"
                                  [currentDocument]="document" [bookmarkDocumentURL]="bookmarkURL"
                                  [bookMarkExternalShareUrl]="shareUrl"></app-share-document>
                              </div>
                            </li>


                            <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                              [class.gridlist]="gridViewClick"
                              *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) == -1 : true">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                href="javascript:void(0)" class="serch-icns list-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.AddToList' | translate }} </span>
                                <span class="spriteico addtolistIcon" [class.gridViewIcons]="showGridView"
                                  [class.addtolistfour]="document?.latlon"
                                  title="{{ 'SearchResult.AddToList' | translate }}" aria-hidden="true"></span>
                              </a>
                            </li>
                            <!-- additional icons -->
                            <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                              [class.gridviewList]="gridViewClick"
                              *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) >= 0 : false">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                href="javascript:void(0)" class="serch-icns list-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.RemoveFromList' | translate }}</span>
                                <span class="spriteico removefromlistIcon" [class.gridViewIcons]="showGridView"
                                  [class.addtolistfour]="document?.latlon"
                                  title="{{ 'SearchResult.RemoveFromList' | translate }}" aria-hidden="true"></span>
                              </a>
                            </li>
                            <li tabindex="0" (keyup.enter)="pageSettingService.addToViewContent(document, group?.group)"
                            (click)="pageSettingService.addToViewContent(document, group?.group, j);getContentVal(document, group?.group, j, 'add')"
                            [class.gridlist]="gridViewClick" *ngIf="(document.showDocs && document.paidArchive) && showDCIcon">
                            <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                              class="serch-icns list-icon">
                              <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                'SearchResult.AddToContent' | translate }} </span>
                              <span class="spriteico addtocontentIcon" [class.gridViewIcons]="showGridView"
                                [class.addtolistfour]="document?.latlon"
                                title="{{ 'SearchResult.AddToContent' | translate }}" aria-hidden="true"></span>
                            </a>
                          </li>
                          <li tabindex="0" (keyup.enter)="pageSettingService.removeToViewContent(document, group?.group)"
                              (click)="pageSettingService.removeToViewContent(document, group?.group, j); getContentVal(document, group?.group, j, 'remove')"
                              [class.gridviewList]="gridViewClick" *ngIf="(!document.showDocs && document.paidArchive) && showDCIcon">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                class="serch-icns list-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.RemoveFromContent' | translate }}</span>
                                <span class="spriteico removefromcontentIcon" [class.gridViewIcons]="showGridView"
                                  [class.addtolistfour]="document?.latlon"
                                  title="{{ 'SearchResult.RemoveFromContent' | translate }}" aria-hidden="true"></span>
                              </a>
                            </li>

                            <li tabindex="0" *ngIf="group?.group === 'Photographs'" [class.gridlist]="gridViewClick">
                              <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                class="open-new-tab-link collection-icon-new"
                                (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                              </a>
                            </li>
                            <li tabindex="0" *ngIf="group?.group === 'Photographs'" [class.gridlist]="gridViewClick">
                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                class="open-new-tab-link open-tab-icon-new" [routerLink]="['../detail/' + document.id,
                            detailsRedirectParams]"></a>
                            </li>

                            <li tabindex="0" [class.arabic-style]="isArabicLanguage" *ngIf="(document?.latlon)"
                              [class.position]="document?.latlon" (contextmenu)="disableRightClick($event)"
                              (keyup.enter)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                              (click)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                              tooltipPlacement="bottom" [class.gridlist]="gridViewClick" class="serch-icns"
                              title="{{ 'SearchResult.DropPinOnMap' | translate}}">
                              <span role="button" aria-pressed="false" class="mapIcon fa fa-map-marker browsemap_icon"
                                [class.gridViewIcons]="showGridView"
                                [ngStyle]="{'color': document['highlightSelected'] ? '#068853': '#61165e'}"></span>
                              <!-- <tooltip-content #dropPinTooltip>Drop pin on Map</tooltip-content> -->
                              <ng-template #dropPinTooltip>
                                Drop pin on Map
                              </ng-template>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="mapsclass-new" [class.full-wdth-style]="!leftMenuStatus"
                        *ngIf="_isInMapView && !showGridView">
                        <div class="listview-icon search-div-icon">
                          <ul class="search-iconsdiv photographwidth {{group?.group}}ListViewIcon"
                            [class.positions]="document?.latlon" [ngClass]="getgridviewList(group?.group)"
                            [class.gridviewBorder]="gridViewClick"
                            [ngStyle]="{'border-left': group?.group == 'Manuscripts' ? 'none' : '1px solid #d2d2da'}">
                            <li tabindex="0" class="background-image"
                              (keyup.enter)="getDocIllustrations(document.id, j)"
                              (click)="getDocIllustrations(document.id, j)"
                              *ngIf="!showGridView && document.isIllustrationExist == true  && group?.group !== 'Photographs' && group?.group !== 'Maps'">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                href="javascript:void(0)" class="serch-icns img-icon img-align"
                                (click)="imageClick(document.title)">
                                <span [class.arabic-style]="isArabicLanguage" class="browse-txt">{{
                                  'SearchResult.BrowseIllustrations' | translate }}</span>
                                <span class="spriteico  spriteico-icon illustrationsIcon"
                                  [class.gridViewIcons]="showGridView"
                                  title="{{ 'SearchResult.BrowseIllustrations' | translate }}" aria-hidden="true">
                                  <span class="illustrationCount" *ngIf="document.illustrationCount"
                                    [ngStyle]="{'left': group?.group == 'Monographs' ? '50px': '16px'}">
                                    <div [class.arabic-style]="isArabicLanguage" class="illustration-badge">
                                      {{document.illustrationCount}}</div>
                                  </span>
                                </span>

                              </a>
                            </li>
                            <li tabindex="0" [class.gridviewIllustration]="gridViewClick"
                              *ngIf="!showGridView && document.isIllustrationExist == false && group?.group !== 'Photographs' && group?.group !== 'Maps'">
                              <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                class="serch-icns img-icon">
                                <!-- <span [class.arabic-style]="isArabicLanguage" class="browse-txt">{{
                                  'SearchResult.BrowseIllustrationsNotFound' | translate }}</span> -->
                                <span class="spriteico illustrationsIcon img-disable"
                                  [class.gridViewIcons]="showGridView" [class.illustrationfour]="document?.latlon"
                                  title="{{ 'SearchResult.BrowseIllustrationsNotFound' | translate }}"
                                  aria-hidden="true"></span>
                              </a>
                            </li>


                            <!-- 

                            <li class="menu-list dropdown documentLevelShare socialShare{{j}}"
                              (click)="socialShare(document,j, group?.group);$event.stopPropagation();">
                              <a [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.Share' | translate }}"
                                href="javascript:void(0)">
                                <span class="spriteico shareIcon dropdown-toggle"></span>
                              </a>
                              <div *ngIf="document.enableShare">
                                <app-share-document class="shareDocumentClss" [bookMarkShare]="document.enableShare"
                                  [currentDocument]="document" [bookmarkDocumentURL]="bookmarkURL"
                                  [bookMarkExternalShareUrl]="shareUrl"></app-share-document>
                              </div>
                            </li>


                            <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                              [class.gridlist]="gridViewClick"
                              *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) == -1 : true">
                              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                                class="serch-icns list-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.AddToList' | translate }} </span>
                                <span class="spriteico addtolistIcon" [class.gridViewIcons]="showGridView"
                                  [class.addtolistfour]="document?.latlon"
                                  title="{{ 'SearchResult.AddToList' | translate }}" aria-hidden="true"></span>
                              </a>
                            </li>

                            <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                              [class.gridviewList]="gridViewClick"
                              *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) >= 0 : false">
                              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                                class="serch-icns list-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.RemoveFromList' | translate }}</span>
                                <span class="spriteico removefromlistIcon" [class.gridViewIcons]="showGridView"
                                  [class.addtolistfour]="document?.latlon"
                                  title="{{ 'SearchResult.RemoveFromList' | translate }}" aria-hidden="true"></span>
                              </a>
                            </li>

                            <li tabindex="0" *ngIf="group?.group === 'Photographs'" [class.gridlist]="gridViewClick">
                              <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                class="open-new-tab-link collection-icon-new"
                                (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                              </a>
                            </li>

                            <li tabindex="0" *ngIf="group?.group === 'Photographs'" [class.gridlist]="gridViewClick">
                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                class="open-new-tab-link open-tab-icon-new" [routerLink]="['../detail/' + document.id,
                            detailsRedirectParams]"></a>
                            </li>

                            <li tabindex="0" [class.arabic-style]="isArabicLanguage" *ngIf="(document?.latlon)"
                              [class.position]="document?.latlon"
                              (keyup.enter)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                              (click)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                              tooltipPlacement="bottom" [class.gridlist]="gridViewClick" class="serch-icns"
                              title="{{ 'SearchResult.DropPinOnMap' | translate}}">
                              <span role="button" aria-pressed="false" class="mapIcon fa fa-map-marker browsemap_icon"
                                [class.gridViewIcons]="showGridView"
                                [ngStyle]="{'color': document['highlightSelected'] ? '#068853': '#61165e'}"></span>
                            </li> -->
                          </ul>


                        </div>
                      </div>
                      <div class="browse-grid-style" [class.browsemaps-gridview]="showGridView"
                        [class.full-wdth-style]="!leftMenuStatus" *ngIf="_isInMapView && showGridView">
                        <div class="listview-icon search-div-icon">
                          <ul class="search-iconsdiv photographwidth {{group?.group}}ListViewIcon"
                            [class.positions]="document?.latlon" [ngClass]="getgridviewList(group?.group)"
                            [class.gridviewBorder]="gridViewClick"
                            [ngStyle]="{'border-left': group?.group == 'Manuscripts' ? 'none' : '1px solid #d2d2da'}">
                            <li tabindex="0" (keyup.enter)="getDocIllustrations(document.id, j)"
                              (click)="getDocIllustrations(document.id, j)"
                              *ngIf="document.isIllustrationExist == true  && group?.group !== 'Photographs' && group?.group !== 'Maps'">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                href="javascript:void(0)" class="serch-icns img-icon"
                                (click)="imageClick(document.title)">
                                <span [class.arabic-style]="isArabicLanguage" class="browse-txt">{{
                                  'SearchResult.BrowseIllustrations' | translate }}</span>
                                <span class="spriteico illustrationsIcon" [class.gridViewIcons]="showGridView"
                                  title="{{ 'SearchResult.BrowseIllustrations' | translate }}" aria-hidden="true">
                                  <span class="illustrationCount" *ngIf="document.illustrationCount"
                                    [ngStyle]="{'left': group?.group == 'Monographs' ? '50px': '16px'}">
                                    <div [class.arabic-style]="isArabicLanguage" class="illustration-badge">
                                      {{document.illustrationCount}}</div>
                                  </span>
                                </span>

                              </a>
                            </li>
                            <li tabindex="0" [class.gridviewIllustration]="gridViewClick"
                              *ngIf="document.isIllustrationExist == false && group?.group !== 'Photographs' && group?.group !== 'Maps'">
                              <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                class="serch-icns img-icon">
                                <span class="spriteico illustrationsIcon img-disable"
                                  [class.gridViewIcons]="showGridView" [class.illustrationfour]="document?.latlon"
                                  title="{{ 'SearchResult.BrowseIllustrationsNotFound' | translate }}"
                                  aria-hidden="true"></span>
                              </a>
                            </li>

                          </ul>


                        </div>
                      </div>

                      <div class="map-group-icons" [class.full-wdth-style]="!leftMenuStatus"
                        [class.browsemaps-map-gridview]="showGridView"
                        [class.browser-photo-cls]="group?.group === 'Photographs'"
                        [class.browser-maps-cls]="group?.group === 'Maps'" *ngIf="_isInMapView">
                        <i class="fa fa-ellipsis-v triple-dot" aria-hidden="true" [outsideClick]="true"
                          [popover]="browsermapspopOverTemplate" [placement]="'bottom'"></i>
                      </div>
                      <ng-template #browsermapspopOverTemplate>
                        <div class="browser-map-popover">
                          <ul class="browse-map-ul">
                            <li class="browse-map-li menu-list dropdown documentLevelShare socialShare{{j}}"
                              (click)="socialShare(document,j, group?.group);$event.stopPropagation();">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                title="{{ 'Detail.Share' | translate }}" href="javascript:void(0)">
                                <span class="browsericn spriteico shareIcon dropdown-toggle"></span>
                                <span class="share-txt">Share</span>
                              </a>
                              <div *ngIf="document.enableShare">
                                <app-share-document class="shareDocumentClss" [bookMarkShare]="document.enableShare"
                                  [currentDocument]="document" [bookmarkDocumentURL]="bookmarkURL"
                                  [bookMarkExternalShareUrl]="shareUrl"></app-share-document>
                              </div>
                            </li>



                            <li tabindex="0" class="browse-map-li"
                              (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                              [class.gridlist]="gridViewClick"
                              *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) == -1 : true">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                href="javascript:void(0)" class="serch-icns list-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.AddToList' | translate }} </span>
                                <span class="browsericn spriteico addtolistIcon" [class.gridViewIcons]="showGridView"
                                  [class.addtolistfour]="document?.latlon"
                                  title="{{ 'SearchResult.AddToList' | translate }}" aria-hidden="true"></span>
                                <span class="share-txt">Add To List</span>
                              </a>
                            </li>



                            <li tabindex="0" class="browse-map-li"
                              (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                              [class.gridviewList]="gridViewClick"
                              *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) >= 0 : false">
                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                href="javascript:void(0)" class="serch-icns list-icon">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                  'SearchResult.RemoveFromList' | translate }}</span>
                                <span class="browsericn spriteico removefromlistIcon"
                                  [class.gridViewIcons]="showGridView" [class.addtolistfour]="document?.latlon"
                                  title="{{ 'SearchResult.RemoveFromList' | translate }}" aria-hidden="true"></span>
                                <span class="share-txt">Remove From List</span>
                              </a>
                            </li>

                            <li tabindex="0" class="browse-map-li" *ngIf="group?.group === 'Photographs'"
                              [class.gridlist]="gridViewClick">
                              <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                class="open-new-tab-link collection-icon-new"
                                (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                              </a>
                              <span class="photo-collection">Collection</span>
                            </li>

                            <li tabindex="0" class="browse-map-li" *ngIf="group?.group === 'Photographs'"
                              [class.gridlist]="gridViewClick">
                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                class="open-new-tab-link open-tab-icon-new" [routerLink]="['../detail/' + document.id,
                            detailsRedirectParams]">
                              </a>
                              <span class="photo-collection">Open New Tab</span>
                            </li>

                            <li tabindex="0" (contextmenu)="disableRightClick($event)"
                              [class.arabic-style]="isArabicLanguage" *ngIf="(document?.latlon)"
                              [class.position]="document?.latlon"
                              (keyup.enter)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                              (click)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                              tooltipPlacement="bottom" [class.gridlist]="gridViewClick"
                              class="browse-map-li serch-icns" title="{{ 'SearchResult.DropPinOnMap' | translate}}">
                              <span role="button" aria-pressed="false"
                                class="browsericn mapIcon fa fa-map-marker browsemap_icon"
                                [class.gridViewIcons]="showGridView"
                                [ngStyle]="{'color': document['highlightSelected'] ? '#068853': '#61165e'}"></span>
                              <span class="share-txt">Drop Pin on Map</span>
                            </li>

                          </ul>

                        </div>
                      </ng-template>
                    </div>
              </div>
              <div
                *ngIf="(group?.documents.length >= 6 && group?.documents.length != group?.totalGroupElements) ||( scrollDisplay && (group?.documents.length >= 3 && group?.documents.length != group?.totalGroupElements))"
                class="row text-center clearFix">
                <span *ngIf="loadMoreLoader" class="glyphicon glyphicon-repeat fast-right-spinner"></span>
                <div (click)="loadMoreResults('viewMoreFromTemplate')" class="viewMoreContent"
                  *ngIf="!loadMoreLoader && ((group?.documents.length <= 5 || showGridView && group?.documents.length <= 25) && !_isInMapView)">
                  <div class="glyphicon glyphicon-menu-down loadMoreResultsArrow"></div>
                  <div [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.ViewMore' | translate }}</div>
                </div>
              </div>
              <div [class.arabic-style]="isArabicLanguage" [class.text-left]="gridViewClick" class="text-center"
                [ngStyle]="{'display': (_isInMapView || scrollDisplay) ? 'none': 'inline-block'}">
                <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary mt-5 ted"
                  *ngIf="(group?.totalGroupPages > 1) && (group?.documents.length < group?.totalGroupElements)"
                  (click)="viewMoreResultsGroup(group?.group, group?.totalGroupElements, group?.documents.length, contentType)"
                  title="{{ 'SearchResult.ViewMore' | translate }}">{{ 'SearchResult.ViewMore' | translate }}</button>
              </div>
              <div [class.arabic-style]="isArabicLanguage" [class.text-left]="gridViewClick" class="text-center"
                [ngStyle]="{'display': (!_isInMapView) ? 'none': 'inline-block'}" *ngIf="!resultDataLoading">
                <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary mt-5"
                  *ngIf="(group?.totalGroupPages > 1) && (group?.documents.length <=5) && (group?.documents.length < group?.totalGroupElements)"
                  (click)="viewMoreResultsGroup(group?.group, group?.totalGroupElements, group?.documents.length, contentType)"
                  title="{{ 'SearchResult.ViewMore' | translate }}">{{ 'SearchResult.ViewMore' | translate }}</button>
              </div>
              <button [class.arabic-style]="isArabicLanguage"
                [ngStyle]="{'display': (!scrollDisplay || !hightScrollTop) ? 'none': 'block'}"
                title="{{ 'SearchResult.GoToTop' | translate }}" class="btn btn-primary scrollTopPage"
                (click)="scrollTopPage()">
                <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Common.scrollTopPage' | translate
                  }}</span>
                <span class="spriteico topPageIcon"></span>
              </button>
            </accordion-group>
          </ng-container>
        </accordion>
      </div>
    </div>
  </div>

  <ng-template tabindex="0" #bookmarkHtmlTemplate>
    <div class="modal-header">
      <h4 class="bookmark-header">
        {{ 'Detail.bookmarkURL' | translate }}
      </h4>
      <h4 *ngIf="!showClipBoardSuccessAlert" class="modal-title sa-icon sa-info pulseWarning">
      </h4>
      <h4 *ngIf="showClipBoardSuccessAlert" class="modal-title sa-icon sa-success pulseWarning">
        <span class="sa-line sa-tip animateSuccessTip"></span>
        <span class="sa-line sa-long animateSuccessLong"></span>
      </h4>
    </div>
    <div class="modal-body url_body">
      <div [class.arabic-style]="isArabicLanguage" *ngIf="showClipBoardSuccessAlert" class="alert alert-success">{{
        'SearchResult.URLCopiedToClipboard' | translate }}</div>
      <div class="well">
        <span id="bookmarkURLonTemplate">{{bookmarkURL}}</span>
      </div>
      <div [class.arabic-style]="isArabicLanguage" *ngIf="bookmarkURL" class="share-doc-message">
        <span [class.arabic-style]="isArabicLanguage" class="share-doc-message-span">{{
          'Detail.shareQueryBookmarkMessage' | translate }}</span>
      </div>
    </div>
    <div [class.arabic-style]="isArabicLanguage" class="modal-footer">
      <button tabindex="0" [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary"
        (click)="closeBookMarkModal()">
        {{ 'Common.Close' | translate }}
      </button>
      <button tabindex="0" [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary"
        aria-label="Close" (click)="copytoClipBoard(bookmarkURL)">
        {{ 'SearchResult.CopyToClipboard' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #bookmarkPageHtmlTemplate>
    <div class="modal-header">
      <h4 *ngIf="!showClipBoardSuccessAlert" class="modal-title sa-icon sa-success pulseWarning">
        <span class="sa-line sa-tip animateSuccessTip"></span>
        <span class="sa-line sa-long animateSuccessLong"></span>
      </h4>
    </div>
    <div class="modal-body url_body">
      <div class="well">
        <span>{{bookmarkPageURL}}</span>
      </div>
    </div>
    <div [class.arabic-style]="isArabicLanguage" class="modal-footer">
      <button [class.arabic-style]="isArabicLanguage" type="button" class="btn-default btn"
        (click)="closeBookMarkModal()">
        {{ 'Common.Close' | translate }}
      </button>
      <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" aria-label="Close"
        (click)="copytoClipBoard(bookmarkPageURL)">
        {{ 'SearchResult.CopyToClipboard' | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #illustrationDataTemplate>
    <div [class.arabic-style]="isArabicLanguage" class="modal-header">
      <h4 appModalfocus tabindex="0" [class.arabic-style]="isArabicLanguage"
        class="modal-title illustration-title pull-left removeoutline">{{ 'SearchResult.Illustrations.Illustrations' |
        translate }}</h4>
      <p [class.arabic-style]="isArabicLanguage" class="float-right illustration-helptext"> * {{
        'SearchResult.Illustrations.MouseoverTheIllustration' | translate }} </p>
      <p [class.arabic-style]="isArabicLanguage" class="float-right mobIllustration-helptext"> * {{
        'SearchResult.Illustrations.LongPressTheIllustration' | translate }} </p>
    </div>
    <div class="modal-body illustration-modal">
      <!-- <perfect-scrollbar [config]="config" class="searchresultIllusrations-scroll" #container> -->
        <tree-root id="searchresultIllusrations" [focused]="true" [nodes]="illustrationNodes"
          (initialized)="afterIllustrationTreeInit($event)" (activate)="onTableSelect($event)"
          (contextMenu)="onTableBlur($event)">

          <ng-template #treeNodeFullTemplate let-node let-templates="templates">
            <div class="tree-node">
              <tree-node-expander [node]="node"></tree-node-expander>
              <div tabindex="0"
                class="node-content-wrapper illustration-popover-content illustrationTitle removeoutline"
                (keyup.enter)="node.toggleActivated(true)" (click)="node.toggleActivated(true)">
                <a (mouseenter)="mouseEnter(node) " (mouseleave)="mouseLeave(node)"
                  containerClass="illustration-customclass" [class]="node.data.className" [attr.title]="node.data.name"
                  [class.title]="true">{{ node.data.name }}</a>
              </div>
              <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
            </div>
          </ng-template>
        </tree-root>
      <!-- </perfect-scrollbar> -->
      <div class="illustration-image-body" id="illustrationImageDiv" style="display:none">
        <img class="accordian-img center-block img-responsive" [attr.src]="illustrationDocumentImage"
          alt="{{'Alt.imageThumbnails' | translate}}" />
        <div class="illustration-image-loader" [ngStyle]="{display:illustrationsLoader ? 'block':'none'}">
          <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
        </div>
      </div>



    </div>
    <div [class.arabic-style]="isArabicLanguage" class="modal-footer">
      <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary"
        (keyup.enter)="closeIllustrationModal()" (click)="closeIllustrationModal()">
        {{ 'Common.Close' | translate }}
      </button>
    </div>
  </ng-template>
  <div class="col-md-12 padding-0" *ngIf="_isInMapView">
    <app-browser-map (spatialSearchEmitsFromBrowserMap)="onSpatialSearchEmitFromBrowserMap($event)"></app-browser-map>


  </div>


  <ng-template #shareHtmlTemplate>
    <div class="modal-popup-parentDiv">
      <div class="modal-header emailpopup-header">
        <h4 [class.arabic-style]="isArabicLanguage" class="emailpopup-heading">{{ 'Detail.sendEmail' | translate }}
        </h4>
      </div>
      <div class="modal-body shareTemplate">
        <form class="form-horizontal sendMail" #sendMailForm="ngForm">
          <div class="form-group">
            <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
              'Detail.from' | translate }}
              <span class="glyphicon glyphicon-asterisk"></span>
            </label>
            <div class="col-sm-10">
              <input type="email" #contactemailfrom="ngModel" class="form-control" id="emailFrom"
                placeholder="{{'Detail.fromPlaceholder' | translate}}" name="contactemailfrom"
                [(ngModel)]="shareMailData.from" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                required>
              <div class="" [hidden]="contactemailfrom.valid || contactemailfrom.untouched">
                <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                  *ngIf="contactemailfrom.errors && contactemailfrom.errors.required">
                  <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.emailIsRequired' | translate }}</h4>
                </div>
                <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                  *ngIf="contactemailfrom.errors && contactemailfrom.errors.pattern">
                  <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.invalidEmail' | translate }}</h4>
                </div>
              </div>
            </div>

          </div>
          <div class="form-group">
            <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
              'Detail.recipient' | translate }}
              <span class="glyphicon glyphicon-asterisk"></span>
            </label>
            <div class="col-sm-10">
              <input type="email" #contactemailto="ngModel" class="form-control" id="emailTo"
                placeholder="{{'Detail.recipientPlaceholder' | translate}}" name="contactemailto"
                [(ngModel)]="shareMailData.to" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                required>
              <div class="" [hidden]="contactemailto.valid || contactemailto.untouched">
                <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                  *ngIf="contactemailto.errors && contactemailto.errors.required">
                  <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.emailIsRequired' | translate }}</h4>
                </div>
                <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                  *ngIf="contactemailto.errors && contactemailto.errors.pattern">
                  <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.invalidEmail' | translate }}</h4>
                </div>
              </div>
            </div>

          </div>
          <div class="form-group">
            <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="subject">{{
              'Detail.subject' | translate }}
              <span class="glyphicon glyphicon-asterisk"></span>
            </label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="subject"
                placeholder="{{'Detail.subjectPlaceholder' | translate}}" name="subject"
                [(ngModel)]="shareMailData.topic" #subject="ngModel" required>
              <div class="error text-danger" [hidden]=" subject.untouched || shareMailData.topic.trim() !== ''">
                <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                  *ngIf="(subject.errors && subject.errors.required ) || shareMailData.topic.trim() === ''">
                  <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.requiredSubject' | translate }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="message">{{
              'Detail.Message' | translate }}
              <span class="glyphicon glyphicon-asterisk"></span>
            </label>
            <div class="col-sm-10">
              <textarea type="text" wrap="hard" class="form-control message-textarea" id="message" rows="10" cols="50"
                placeholder="{{'Detail.messagePlaceholder' | translate}}" name="message"
                [(ngModel)]="shareMailData.message" #message="ngModel" required></textarea>
              <div class="error text-danger" [hidden]="message.untouched || shareMailData.message.trim() !== ''">
                <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                  *ngIf="(message.errors && message.errors.required) || shareMailData.message.trim() === ''">
                  <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.requiredMessage' | translate }}</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="emailSend submitBtn">
            <div class="col-sm-12">
              <button [class.arabic-style]="isArabicLanguage" type="button"
                [disabled]="!sendMailForm.form.valid || shareMailData.message.trim() === '' || shareMailData.topic.trim() === ''"
                class="btn-primary btn" aria-label="Close" (click)="shareEmail()">{{ 'Detail.Send' | translate
                }}</button>

            </div>
          </div>
          <!-- <tooltip-content #sendMailFormTooltip
            [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
            <div class="error text-danger " [hidden]="contactemailfrom.valid">
              <div [class.arabic-style]="isArabicLanguage"
                *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
                {{ 'Detail.fromIsRequired' | translate }}
              </div>

            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
              <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailto.untouched || contactemailto.invalid">
                {{ 'Detail.recipientIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger"
              [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
              <div [class.arabic-style]="isArabicLanguage" *ngIf="subject.untouched || subject.invalid">
                {{ 'Detail.subjectIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger"
              [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
              <div [class.arabic-style]="isArabicLanguage"
                *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
                {{ 'Detail.messageIsRequired' | translate }}
              </div>
            </div>
          </tooltip-content> -->
          <!-- <ng-template #sendMailFormTooltip  [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
            <div class="error text-danger " [hidden]="contactemailfrom.valid">
              <div [class.arabic-style]="isArabicLanguage"
                *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
                {{ 'Detail.fromIsRequired' | translate }}
              </div>

            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
              <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailto.untouched || contactemailto.invalid">
                {{ 'Detail.recipientIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger"
              [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
              <div [class.arabic-style]="isArabicLanguage" *ngIf="subject.untouched || subject.invalid">
                {{ 'Detail.subjectIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger"
              [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
              <div [class.arabic-style]="isArabicLanguage"
                *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
                {{ 'Detail.messageIsRequired' | translate }}
              </div>
            </div>
          </ng-template> -->
          <div class="emailClose closeBtn">
            <button [class.arabic-style]="isArabicLanguage"  class="btn-default btn"
              (click)="closeShareModal()">Close
            </button>
          </div>
        </form>
      </div>

    </div>
  </ng-template>

  <!-- <ng-template #envDocsTemplate>
    <div class="modal-header  download-title">        
      {{ 'SearchResult.moreENVsContentLink' | translate }}
    </div>
    <div class="modal-body warning_bdy">
      <div class="more-body-text">
        {{ 'SearchResult.envmoreContentText' | translate }}
      </div>
    </div>
    <div class="modal-footer">
        <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" (click)="closeEnvModal();$event.stopPropagation();">
            {{ 'Detail.Close' | translate }}
          </button>
    </div>
  </ng-template> -->
</div>