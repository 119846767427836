/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Subject, Observable } from 'rxjs';
import { AdvancedFilterParams } from './../models/AdvancedFilterParams';
export class PageSettings {
	private _currentArchive: string;
	private _currentLocation: string;
	private _backgroundUrl: string;
	private _archiveLogo: string;
	private _customerLogo: string;
	private _returnUrl: string;
	private _pageTitle: string;
	private _description: Object;
	private _wileyArchives: Array<any>;
	private _customerArchives: Array<any>;
	private _downloadContentArchives: Array<any>;
	private _currentSelectedArchives: Array<any>;
	private _currentInstitutionName: string;
	private _availableContentTypes: Array<any>;
	private _availableContentSubTypes: Array<any>;
	private _availablePublicationTitles: Array<any>;
	private _availableCollections: Array<any>;
	private _availableSources: Array<any>;
	private _availableLanguages: Array<any>;
	private _availableFields: Array<any>;
	private _availableOperators: Array<any>;
	private _showAdvancedFilter: boolean;
	private _searchResultCount: number;
	private _searchTerm: string;
	public _selectedArchives: Observable<Array<any>>;
	private _advancedFilterParam: AdvancedFilterParams;
	private _showLeftMenu: boolean;
	private _isSearchWithIn: boolean;
	private _groupsToBeDisplayed: Array<any>;
	private _hasFolderImage: boolean;
	private _availableSubject: Array<any>;
	private _availableIllustrationTypes: Array<any>;
	private _pubDates: Object;


	constructor(obj?: any) {
		if (obj) {
			try {
				this.advancedFilterParam = new AdvancedFilterParams(obj._advancedFilterParam);
				this.archiveLogo = obj._archiveLogo;
				this.currentArchive = obj._currentArchive;
				this.currentLocation = obj._currentLocation;
				this.currentInstitutionName = obj._currentInstitutionName;
				this.backgroundUrl = obj._backgroundUrl;
				this.archiveLogo = obj._archiveLogo;
				this.customerLogo = obj._customerLogo;
				this.returnUrl = obj._returnUrl;
				this.pageTitle = obj._pageTitle;
				this.description = obj._description;
				this.wileyArchives = obj._wileyArchives;
				this.customerArchives = obj._customerArchives;
				this.downloadContentArchives = obj._downloadContentArchives;
				this.currentSelectedArchives = obj._currentSelectedArchives;
				this.availableContentTypes = obj._availableContentTypes;
				this.availableContentSubTypes = obj._availableContentSubTypes;
				this.availablePublicationTitles = obj._availablePublicationTitles;
				this.availableCollections = obj._availableCollections;
				this.availableSources = obj._availableSources;
				this.availableLanguages = obj._availableLanguages;
				this.availableFields = obj._availableFields;
				this.availableOperators = obj._availableOperators;
				this.showAdvancedFilter = obj._showAdvancedFilter;
				this.searchResultCount = obj._searchResultCount;
				this.searchTerm = obj._searchTerm;
				this.showLeftMenu = obj._showLeftMenu;
				this.isSearchWithIn = obj._isSearchWithIn;
				this.groupsToBeDisplayed = obj._groupsToBeDisplayed;
				this.hasFolderImage = obj._hasFolderImage;
				this.availableSubject = obj._availableSubject;
				this.availableIllustrationTypes = obj._availableIllustrationTypes;
				this.pubDates = obj._pubDates;

			} catch (e) {}
		} else {
			this.advancedFilterParam = new AdvancedFilterParams();
			this.currentArchive = '';
			this.currentLocation = '';
			this.backgroundUrl = '';
			this.archiveLogo = '';
			this.customerLogo = '';
			this.returnUrl = '';
			this.pageTitle = '';
			this.description = {
				title: '',
				content: ''
			};
			this.wileyArchives = [];
			this.customerArchives = [];
			this.downloadContentArchives = [];
			this.currentSelectedArchives = [];
			this.availableContentTypes = [];
			this.availableContentSubTypes = [];
			this.availablePublicationTitles = [];
			this.availableCollections = [];
			this.availableSources = [];
			this.availableLanguages = [];
			this.availableFields = [];
			this.availableOperators = [];
			this.showAdvancedFilter = false;
			this.searchResultCount = 0;
			this.searchTerm = '';
			this._selectedArchives = new Observable();
			this.showLeftMenu = true;
			this.isSearchWithIn = false;
			this.groupsToBeDisplayed = [];
			this.availableSubject = [];
			this.availableIllustrationTypes = [];
			this.pubDates = {};

		}
	}

	public set currentArchive(value: string) {
		this._currentArchive = value;
	}

	public get currentArchive() {
		return this._currentArchive;
	}

	public set currentLocation(value: string) {
		this._currentLocation = value;
	}

	public get currentLocation() {
		return this._currentLocation;
	}

	public set returnUrl(value: string) {
		this._returnUrl = value;
	}

	public get currentInstitutionName() {
		return this._currentInstitutionName;
	}

	public set currentInstitutionName(value: string) {
		this._currentInstitutionName = value;
	}

	public get returnUrl() {
		return this._returnUrl;
	}

	public set backgroundUrl(value: string) {
		this._backgroundUrl = value;
	}

	public get backgroundUrl() {
		return this._backgroundUrl;
	}

	public set archiveLogo(value: string) {
		this._archiveLogo = value;
	}

	public get archiveLogo() {
		return this._archiveLogo;
	}

	public set customerLogo(value: string) {
		this._customerLogo = value;
	}

	public get customerLogo() {
		return this._customerLogo;
	}

	public set pageTitle(value: string) {
		this._pageTitle = value;
	}

	public get pageTitle() {
		return this._pageTitle;
	}

	public set description(value: Object) {
		this._description = value;
	}

	public get description() {
		return this._description;
	}

	public set currentSelectedArchives(value: Array<any>) {
		this._currentSelectedArchives = value;
	}

	public get currentSelectedArchives() {
		return this._currentSelectedArchives;
	}

	public set customerArchives(value: Array<any>) {
		this._customerArchives = [];
		this._customerArchives = value;
	}

	public get customerArchives() {
		return this._customerArchives;
	}
	public set downloadContentArchives(value: Array<any>) {
		this._downloadContentArchives = [];
		this._downloadContentArchives = value;
	}

	public get downloadContentArchives() {
		return this._downloadContentArchives;
	}
	
	public set wileyArchives(value: Array<any>) {
		this._wileyArchives = [];
		this._wileyArchives = value;
	}

	public get wileyArchives() {
		return this._wileyArchives;
	}

	public set availableCollections(value: Array<any>) {
		this._availableCollections = value;
	}

	public get availableCollections() {
		return this._availableCollections;
	}

	public set availableSources(value: Array<any>) {
		this._availableSources = value;
	}

	public get availableSources() {
		return this._availableSources;
	}

	public set availableLanguages(value: Array<any>) {
		this._availableLanguages = value;
	}

	public get availableLanguages() {
		return this._availableLanguages;
	}

	public set availableFields(value: Array<any>) {
		this._availableFields = value;
	}

	public get availableFields() {
		return this._availableFields;
	}

	public set availableOperators(value: Array<any>) {
		this._availableOperators = value;
	}

	public get availableOperators() {
		return this._availableOperators;
	}

	public set availablePublicationTitles(value: Array<any>) {
		this._availablePublicationTitles = value;
	}

	public get availablePublicationTitles() {
		return this._availablePublicationTitles;
	}

	public set availableContentTypes(value: Array<any>) {
		this._availableContentTypes = value;
	}

	public get availableContentTypes() {
		return this._availableContentTypes;
	}

	public set availableContentSubTypes(value: Array<any>) {
		this._availableContentSubTypes = value;
	}

	public get availableContentSubTypes() {
		return this._availableContentSubTypes;
	}

	public set showAdvancedFilter(flag: boolean) {
		this._showAdvancedFilter = flag;
	}

	public get isSearchWithIn() {
		return this._isSearchWithIn;
	}

	public set hasFolderImage(flag: boolean) {
		this._hasFolderImage = flag;
	}

	public get hasFolderImage() {
		return this._hasFolderImage;
	}

	public set isSearchWithIn(flag: boolean) {
		this._isSearchWithIn = flag;
	}

	public get showAdvancedFilter() {
		return this._showAdvancedFilter;
	}

	public set showLeftMenu(flag: boolean) {
		this._showLeftMenu = flag;
	}

	public get showLeftMenu() {
		return this._showLeftMenu;
	}

	public set searchResultCount(value: number) {
		this._searchResultCount = value;
	}

	public get searchResultCount() {
		return this._searchResultCount;
	}

	public set advancedFilterParam(value: AdvancedFilterParams) {
		this._advancedFilterParam = value;
	}

	public get advancedFilterParam() {
		return this._advancedFilterParam;
	}

	public set searchTerm(value: string) {
		this._searchTerm = value;
	}

	public get searchTerm() {
		return this._searchTerm;
	}
	public set groupsToBeDisplayed(value: Array<any>) {
		this._groupsToBeDisplayed = [];
	}

	public get groupsToBeDisplayed() {
		return this._groupsToBeDisplayed;
	}

	public set availableSubject(value: Array<any>) {
		this._availableSubject = value;
	}

	public get availableSubject() {
		return this._availableSubject;
	}
	public set availableIllustrationTypes(value: Array<any>) {
		this._availableIllustrationTypes = value;
	}

	public get availableIllustrationTypes() {
		return this._availableIllustrationTypes;
	}
	public set pubDates(value: Object) {
		this._pubDates = value;
	}
	public get pubDates() {
		return this._pubDates;
	}


}
