
import { takeUntil } from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, ChangeDetectorRef, Input, forwardRef, EventEmitter, Output, HostListener, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PageSettingsService } from './../common/services/page-settings.service';
import { SearchService } from './../common/services/search.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlUtilityService } from '../common/services/url-utility.service';
import { Subject } from 'rxjs';
declare var $: any;
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'filter-control',
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterControlComponent),
      multi: true
    }
  ]
})
export class FilterControlComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  currentfieldValue: any;
  currentFieldName: any;
  currentTerms: any;

  private fieldsDropdownSettings = {
    singleSelection: true,
    text: 'Fields',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    maxHeight: 200
  };
  // tslint:disable-next-line:no-input-rename
  @Input('field-options') fieldsDropdownOptions;
  // tslint:disable-next-line:no-input-rename
  @Input('operator-options') operatorsDropdownOptions;
  private _searchTerm: any;
  private filterParams: any;
  listOfFilters: any = [];
  private advancedFilterParam: AdvancedFilterParams;

  constructor(
    private ref: ChangeDetectorRef,
    private pageSettingService: PageSettingsService,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private urlUtilityService: UrlUtilityService
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this._searchTerm = params['searchTerm'];
      this.filterParams = params['filter'];
      this.urlUtilityService.parseUrl(this.filterParams).pipe(takeUntil(this.stop$)).subscribe(data => {
        this.advancedFilterParam = new AdvancedFilterParams(data);
      });
    });
  }

  ngAfterViewInit() { }

  filterChanged(value, index) {
    this.listOfFilters[index].fieldValue = this.listOfFilters[index].fieldValue.trim();
    // if (value.trim()) {
    this.propagateChange(this.listOfFilters);
    // }
  }
  addFilter(index: number) {
    if (this.listOfFilters.length < 8) {
      let enterDocument: any = [];
      enterDocument = this.fieldsDropdownOptions.filter((f) => (f.itemName === 'Entire Document'))
      this.listOfFilters.splice(index + 1, 0, {
        fieldValue: '',
        operator: this.operatorsDropdownOptions[0].itemName,
        // fieldName: this.fieldsDropdownOptions[5].itemValue
        fieldName: enterDocument ? enterDocument[0].itemValue : this.fieldsDropdownOptions[5].itemValue
        // fieldName: this.fieldsDropdownOptions[0].itemValue
      });
      this.propagateChange(this.listOfFilters);
      console.log(this.listOfFilters)
    }
  }

  removeFilter(index: number) {
    if (this.listOfFilters.length > 1) {
      this.listOfFilters.splice(index, 1);
    }
    this.propagateChange(this.listOfFilters);
  }

  onFieldChange(value, index) {
    if (value !== 'manuscriptNoV2') {
      this.listOfFilters.forEach((obj, i) => {
        if (obj.fieldValue.indexOf('"') >= 0 && i === index) {
          obj.fieldValue = obj.fieldValue.replace(/['"]+/g, '');
        }
      });
    }
    this.propagateChange(this.listOfFilters);
  }

  onOperatorChange(value, index) {
    this.propagateChange(this.listOfFilters);
  }

  onKeywordChange(value, index) {
    this.propagateChange(this.listOfFilters);
  }

  writeValue(filters: any) {
    this.listOfFilters = filters;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched() { }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.ctrlKey && e.altKey && e.keyCode === 65) {
      this.onFieldChange('', '');
    }
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
