
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, ViewChild, ViewContainerRef, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd,  Event as NavigationEvent, ActivatedRoute, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { ModalService } from './common/services/modal.service';
import { UtilityService } from './common/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { VersionCheckService } from './common/services/version-check.service';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { AuthService } from './common/services/auth.service';
import { GlobalErrorHandler } from './global-error-handler';
import { SharedService } from './common/services/sharedService';
import { HttpErrorResponse } from '@angular/common/http';
// declare google analytics
// declare const ga: any;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [GlobalErrorHandler]
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  stop$: Subject<any> = new Subject();
  title = 'Wiley';
  viewer: any;
  dibujar = false;
  lineHeight = 1;
  // @ViewChild('popupHolder', {read: ViewContainerRef})
  @ViewChild('popupHolder', { static: false, read: ViewContainerRef }) popupHolder;

  // public popupHolder;
  private imageViewerOptions: any = {
    height: 950,
    showBrightness: true,
    showZoom: true,
    showRotate: true,
    showShare: true,
    showFullScreen: true,
    showPrint: true,
    showDownload: true,
    showExpand: true,
    showDocument: false,
  };
  userActivity;

  userInactive: Subject<any> = new Subject();
  constructor(
    private router: Router,
    private modalService: ModalService ,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private versionCheckService: VersionCheckService,
    private titleService: Title,
    private authService: AuthService,
    private globalErrorHandler: GlobalErrorHandler,
    private sharedService: SharedService
  ) {
    this.checkUserInactivity();
    this.titleService.setTitle('Wiley Digital Archives - ' + environment.version);
    /*
    * this location check is to solve the continious self redirection issue in app
    */
    if (window.location.pathname.includes('location') ||
    window.location.pathname.includes('fromBookmark') ||
    window.location.pathname.includes('search') ||
    window.location.pathname.includes('detail') ||
    window.location.pathname.includes('frequency') ||
    window.location.pathname.includes('collection') ||
    window.location.pathname.includes('about') ||
      window.location.pathname.includes('explorer') ||
    window.location.pathname.includes('analysis-hub')
  ) {
      sessionStorage.setItem('lastSessionPath', window.location.href);
    }
    this.translate.setDefaultLang('en-us');
    this.activatedRoute.params.subscribe(params => {
      let language: any;
      if (sessionStorage.getItem('lang')) {
          language = sessionStorage.getItem('lang');
      } else {
        language = params['lang'] ? params['lang'] : this.translate.getDefaultLang();
        sessionStorage.setItem('lang', language);
      }
      if (!language) {
        language = 'en-us';
      }
      this.translate.use(language);
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.router.navigated = false;
      }
    });
    this.utilityService.configValues();
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
          this.versionCheckService.checkVersion(environment.versionCheckURL);
          // this.modalService.showLoader();
      } else if (event instanceof RouteConfigLoadEnd) {
          // this.modalService.hideLoader();
      }
    });
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event: NavigationEvent) => {
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('set', 'page', event.urlAfterRedirects);
        gtag('send', 'pageview');
      }
    });
    this.sharedService.handleError.subscribe((res: Error | HttpErrorResponse) => {
      this.globalErrorHandler.handleError(res);
    });
    this.userInactive.pipe(takeUntil(this.stop$)).subscribe(() => {
      const accessType = localStorage.getItem('accessType');
      if (this.authService.isAuthenticated() && accessType !== 'guest') {
        this.authService.logout();
      }
    });
    ModalService.popupHolder = this.popupHolder;
    // const pathName = document.location.pathname;
    // if (pathName.indexOf('selectedArchives') > -1) {
    //   sessionStorage.setItem('lastSessionPath', document.location.pathname);
    // }
    /*
    * PopUp detector
    */
    // const windowRef = window.open('popup-detector', '',
    // 'directories=no,height=100,width=100,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,top=0,location=no');
    // if (windowRef == null || typeof (windowRef) === 'undefined' || (windowRef == null && windowRef.outerWidth === 0) ||
    // (windowRef != null && windowRef.outerHeight === 0) ) {
    //     alert('The popup was blocked. You must allow popups to use this site.');
    //   } else {
    //   windowRef.close();
    //   }
    setTimeout(() => {
      const lang = sessionStorage.getItem('lang');
      let language: any;
      if (lang) {
        language = lang;
       } else  {
        language = this.translate.getDefaultLang();
        sessionStorage.setItem('lang', language);
      }
      if (!language) {
        language = 'en-us';
      }
      this.translate.use(language);
    }, 2000);
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
  }

  checkUserInactivity() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 9000000);
  }
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:keypress', ['$event'])
  @HostListener('touchend', ['$event'])
  refreshUserState(event) {
    clearTimeout(this.userActivity);
    this.checkUserInactivity();
  }
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
