/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
  selector: '[appHighlightSelect]'
})
export class HighlightSelectDirective {
  constructor(private el: ElementRef) { }
  //This event listenser added for dropdown to fix the width issue on IE - #9245
  //#9245 -  Focus on the input before accessing select causes this error.
  @HostListener('mouseover') onMouseOver() {
    if(this.el.nativeElement.nodeName === "SELECT"){
      this.el.nativeElement.focus();
    }
  } 
}
