import { Injectable } from '@angular/core';
import { environment, APIURLS } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SearchService } from '../../common/services/search.service';
import { AdvancedFilterParams } from '../../common/models/AdvancedFilterParams';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class ImageData {
  pageId: String;
  docId: String;
  title: String;
  documentTitle: String;
  captions: Array<any>;
  thumb: String;
  image: String;
  coordinates: Array<any>;
  relatedImageId: String;
  hasInsetMaps: any;
  processType: any;

  constructor(
    pageId: String,
    itemTitle: String = 'Untitled',
    documentTitle: String = null,
    thumb: String,
    image: String,
    docId: String,
    captions: Array<any> = [],
    coordinates: Array<any> = [],
    relatedImageId: String,
    hasInsetMaps: any,
    processType: any
  ) {
    this.pageId = pageId;
    this.docId = docId;
    this.title = itemTitle;
    this.documentTitle = documentTitle;
    this.captions = captions;
    this.thumb = thumb;
    this.image = image;
    this.coordinates = coordinates;
    this.relatedImageId = relatedImageId;
    this.hasInsetMaps = hasInsetMaps;
    this.processType = processType;
  }
}
@Injectable()
export class ExplorerService {
  explorerPhotoCache$ = {};
  explorerMapCache$ = {};
  mapExplorerRangeYearObj$ = {};
  photoExplorerLoading: Boolean = false;
  mapExplorerLoading: Boolean = false;
  private stop$: Subject<void> = new Subject<void>();
  constructor(
    private httpClient: HttpClient,
    private searchService: SearchService
  ) { }

  getPhotoExplorer(advSearchParams, searchTerm, callback, errorCallback, spatialFilterParams?, offset: number = 0,
    isCountRequest: boolean = false): any {  
      var archivesVal;
     PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
      archivesVal = Object.assign(data);
     })
    if(advSearchParams.archive.length >0) {
      if (archivesVal.currentArchive == "WILEY"){
        let dropVal = JSON.parse(sessionStorage.getItem('explorerDropDownLimiter'));
        if(dropVal && dropVal.length >0) {
        } else{
          let accessedArchives: any = JSON.parse(localStorage.getItem('personalizationData'));
          if (advSearchParams.archive === 'RCP') {
            if(accessedArchives.bothRCPAvailable) {
              advSearchParams.archive.push('RCP1');
              advSearchParams.archive.push('RCP2')
              const index = advSearchParams.archive.indexOf("RCP");
              if (index > -1) { 
                advSearchParams.archive.splice(index, 1);
              }
            }
          } else if (advSearchParams.archive === 'RGS') {
            if(accessedArchives.bothRGSAvailable) {
              advSearchParams.archive.push('RGS1');
              advSearchParams.archive.push('RGS2')
              const index = advSearchParams.archive.indexOf("RGS");
              if (index > -1) { 
                advSearchParams.archive.splice(index, 1);
              }
            }
          } 
          // this.removeDuplicate(advSearchParams.archive);
        }
      }
    }
    const data = advSearchParams || {};
    let str = searchTerm || '';
    str = str.trim();
    if (str.includes('-')) {
      if (str.includes('"')) {
        searchTerm = str;
      } else {
        searchTerm = '"' + str + '"';
      }
    }
    data.searchTerm = searchTerm;
    if (window.location.pathname.split('/')[1] === 'rgs' || window.location.pathname.split('/')[1] === 'rcp') {
      advSearchParams['archive'] = JSON.parse((localStorage.getItem('wileySelectedArchive')));
    }
    this.searchService.buildFilterParams(new AdvancedFilterParams(advSearchParams), (param) => {
      param.filterParams.forEach((obj, index) => {
        if (obj.fieldName === 'illustrationType') {
          param.filterParams.splice(index, 1);
        }
      });
      param.searchTerm = searchTerm;
      if (spatialFilterParams) {
        spatialFilterParams.forEach(obj => {
          obj.fieldName = 'latlon';
        });
        param['spatialFilterParams'] = spatialFilterParams;
      }
      param['start'] = offset ? offset : 0;
      let undate = 0;
      param.filterParams.forEach((obj, index) => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          undate++;
          if (undate > 1) {
            param.filterParams.splice(index, 1);
          }
        }
      });
      param.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
      });
      if (isCountRequest) {
        param['resultNeeds'] = false;
      }
      if (this.explorerPhotoCache$[JSON.stringify(param)]) {
        callback(this.explorerPhotoCache$[JSON.stringify(param)]);
        return true;
      }
      if (!this.photoExplorerLoading) {
        this.photoExplorerLoading = true;
      param.filterParams.forEach(obj => {
        if (obj.fieldName === 'archive') {
          if (obj.fieldValues.indexOf('RGS') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RGS');
            obj.fieldValues.push('RGS1', 'RGS2');
          }
          if (obj.fieldValues.indexOf('RCP') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RCP');
            obj.fieldValues.push('RCP1', 'RCP2');
          }

        }
      });
      this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['getPhotoExplorer']}`, param).subscribe(res => {
        const authToken = JSON.parse(localStorage.getItem('auth_token'));
        if (res) {
          const imageUrls: any[] = [];
          if (res.results) {
            if (res.results.explorerResult) {
              if (!isCountRequest) {
                res.results.explorerResult.forEach(doc => {
                  if (doc.captions) {
                    doc.captions = doc.captions.toString().replace(',', ', ');
                  } else {
                    doc.captions = 'Not Available';
                  }
                  if (doc.relatedImageId) {
                    doc.relatedImageId = doc.relatedImageId;
                  } else {
                    doc.relatedImageId = '';
                  }
                  imageUrls.push(new ImageData(
                    doc.id,
                    doc.itemTitle,
                    doc.documentTitle,
                    // tslint:disable-next-line:max-line-length
                    `${environment.APIUrl}${APIURLS['fileUrlThumb']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                    // tslint:disable-next-line:max-line-length
                    `${environment.APIUrl}${APIURLS['fileUrlImage']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                    doc.documentId,
                    doc.captions,
                    doc.latlon,
                    doc.relatedImageId,
                    doc.hasInsetMaps,
                    doc.processType ? doc.processType : ''
                  ));
                });
                if (res.totalRecords > 0) {
                this.registerInHistoryList(searchTerm, advSearchParams, 'photo');
                }
              }
            }
          }
          this.explorerPhotoCache$[JSON.stringify(param)] = { imageUrls: imageUrls, totalRecoards: res.totalRecords };
          this.photoExplorerLoading = false;
          callback({ imageUrls: imageUrls, totalRecoards: res.totalRecords });
        }
      }, err => {
        this.photoExplorerLoading = false;
        errorCallback([]);
      });
    }
    });
  }
  /**
   * @description :get map explorer data from service based on params
   * @param advSearchParams : advanced filter param
   * @param searchTerm : search term
   * @param callback :success callback
   * @param errorCallback : error callback
   * @param spatialFilterParams : map data
   * @param mapCoOrdinatesOnly :only documents which is contains coordinates
   * @param offset : limiter to pull data
   * @param isCountRequest :whether the current request for count or not
   */
  getMapExplorer(advSearchParams, searchTerm, callback, errorCallback, spatialFilterParams?, mapCoOrdinatesOnly?,
    offset: number = 0, isCountRequest: boolean = false, ignoreFullText?: boolean): any {
      const data = advSearchParams || {};
    let str = searchTerm || '';
    str = str.trim();
    if (str.includes('-')) {
      if (str.includes('"')) {
        searchTerm = str;
      } else {
        searchTerm = '"' + str + '"';
      }
    }
      data.searchTerm = searchTerm;
      if (window.location.pathname.split('/')[1] === 'rgs' || window.location.pathname.split('/')[1] === 'rcp') {
        advSearchParams['archive'] = JSON.parse((localStorage.getItem('wileySelectedArchive')));
      }
      this.searchService.buildFilterParams(new AdvancedFilterParams(advSearchParams), (param) => {
        param.filterParams.forEach((obj, index) => {
          if (obj.fieldName === 'illustrationType') {
              param.filterParams.splice(index, 1);
          }
        });
        param.searchTerm = searchTerm;
        if (spatialFilterParams) {
          spatialFilterParams.forEach(obj => {
            obj.fieldName = 'geoBoundaries';
          });
          param['spatialFilterParams'] = spatialFilterParams;
        }
        param['start'] = offset ? offset : 0;
        if (mapCoOrdinatesOnly) {
          param['mapCoordinatesNotNull'] = true;
        }
        let undate = 0;
        param.filterParams.forEach((obj, index) => {
          if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
            undate++;
            if (undate > 1) {
              param.filterParams.splice(index, 1);
            }
          }
        });
        param.filterParams.forEach(obj => {
          if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
            obj.condition = 'All';
            obj.fieldValue = '';
          }
        });
        if (isCountRequest) {
          param['resultNeeds'] = false;
        }
        if (ignoreFullText) {
          param['ignoreFullText'] = true;
        } else {
          param['ignoreFullText'] = false;
        }
        if (this.explorerMapCache$[JSON.stringify(param)]) {
          callback(this.explorerMapCache$[JSON.stringify(param)]);
          return true;
        }
        if (!this.mapExplorerLoading) {
          this.mapExplorerLoading = true;
          param.filterParams.forEach(obj => {
            if (obj.fieldName === 'archive') {
              if (obj.fieldValues.indexOf('RGS') > -1) {
                obj.fieldValues = obj.fieldValues.filter(item => item !== 'RGS');
                obj.fieldValues.push('RGS1', 'RGS2');
              }
              if (obj.fieldValues.indexOf('RCP') > -1) {
                obj.fieldValues = obj.fieldValues.filter(item => item !== 'RCP');
                obj.fieldValues.push('RCP1', 'RCP2');
              }
            }
          });
        this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['getMapExplorer']}`, param).subscribe(res => {
          const authToken = JSON.parse(localStorage.getItem('auth_token'));
          if (res) {
            const imageUrls: any[] = [];
            if (res.results) {
              if (res.results.explorerResult) {
                if (!isCountRequest) {
                  res.results.explorerResult.forEach(doc => {
                        if (doc.captions) {
                          doc.captions = doc.captions.toString().replace(',', ', ');
                        } else {
                          doc.captions = 'Not Available';
                        }
                        imageUrls.push(new ImageData(
                          doc.id,
                          doc.itemTitle,
                          doc.documentTitle,
                          // tslint:disable-next-line:max-line-length
                          `${environment.APIUrl}${APIURLS['fileUrlThumb']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                          // tslint:disable-next-line:max-line-length
                          `${environment.APIUrl}${APIURLS['fileUrlImage']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                          doc.documentId,
                          doc.captions,
                          doc.geoBoundaries,
                          doc.relatedImageId,
                          doc.hasInsetMaps,
                          doc.processType ? doc.processType : ''
                        ));
                  });
                  if (res.totalRecords > 0) {
                   this.registerInHistoryList(searchTerm, advSearchParams, 'map');
                  }
                }
              }
            }
            this.explorerMapCache$[JSON.stringify(param)] = { imageUrls: imageUrls, totalRecoards: res.totalRecords };
            this.mapExplorerLoading = false;
            callback({ imageUrls: imageUrls, totalRecoards: res.totalRecords });
          }
        }, err => {
          this.mapExplorerLoading = false;
          errorCallback([]);
        });
      }
      });
    }

    registerInHistoryList(searchTerm: any, advParams?: any, explorerType?: any) {
      if ((advParams.publishedDateFrom === '' || advParams.publishedDateTo === '' || advParams.publishedDatePrefix === '')
      && advParams.publishedDateIncludesNull) {
        advParams.publishedDateIncludesNull = false;
      }
      setTimeout(() => {
        const urlForHistory = window.location.pathname;
        const objDate = Date.now();
        const historyItem = {
          url: urlForHistory,
          filterParams: advParams,
          searchParams: searchTerm || '',
          date: objDate,
          explorerType: explorerType
        };
        const previousHistoryObject = JSON.parse(localStorage.getItem('HistoryList'));
        if (previousHistoryObject.length > 0) {
          previousHistoryObject.push(historyItem);
          // if (advParams.publishedDateFrom !== '' || advParams.publishedDateTo !== '' || advParams.publishedDatePrefix !== '') {
            localStorage.setItem('HistoryList', JSON.stringify(previousHistoryObject));
          // }
        } else {
          const historyObject = [historyItem];
          // if (advParams.publishedDateFrom !== '' || advParams.publishedDateTo !== '' || advParams.publishedDatePrefix !== '') {
          localStorage.setItem('HistoryList', JSON.stringify(historyObject));
          // }
        }
      });
    }

    getRangeSliderMinMaxYear(advSearchParams, callback, errorCallback ) {
      const advParams: any =  new AdvancedFilterParams();
      advParams.archive = advSearchParams.archive;
      if (this.mapExplorerRangeYearObj$[JSON.stringify(advParams)]) {
        callback(this.mapExplorerRangeYearObj$[JSON.stringify(advParams)]);
        return true;
      }
      if (window.location.pathname.split('/')[1] === 'rgs' || window.location.pathname.split('/')[1] === 'rcp') {
        advParams['archive'] = JSON.parse((localStorage.getItem('wileySelectedArchive')));
      }
      this.searchService.buildFilterParams(advParams, (param) => {
        try {
          delete param.groupLimit;
          delete param.groupOffset;
        } catch (error) {}
        param.filterParams.forEach(obj => {
          if (obj.fieldName === 'archive') {
            if (obj.fieldValues.indexOf('RGS') > -1) {
              obj.fieldValues = obj.fieldValues.filter(item => item !== 'RGS');
              obj.fieldValues.push('RGS1', 'RGS2');
            }
            if (obj.fieldValues.indexOf('RCP') > -1) {
              obj.fieldValues = obj.fieldValues.filter(item => item !== 'RCP');
              obj.fieldValues.push('RCP1', 'RCP2');
            }
  
          }
        });
      this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['explorerminmaxsearch']}`, param).subscribe(res => {
                if (res) {
                  if (res.results) {
                    if (res.results.statsResults.length > 0) {
                      callback({ statsResults: res.results.statsResults[0] });
                      this.mapExplorerRangeYearObj$[JSON.stringify(advParams)] = {
                        statsResults: res.results.statsResults[0] };
                    } else { callback({ statsResults: [] }); }
                  } else { callback({ statsResults: [] }); }
                } else { callback({ statsResults: [] }); }
      }, err => {
                errorCallback([]);
      });
    });
    }

}
