/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './common/services/auth-guard.service';
import { environment } from '../environments/environment';
import { LandingPageWrapperComponent } from './landing-page-wrapper/landing-page-wrapper.component';
import { WileyBodyComponentComponent } from './wiley-body-component/wiley-body-component.component';
import { PageSettingsResolver } from './common/services/pagesettings.resolver';
import { WileySearchResultWrapperComponent } from './wiley-search-result-wrapper/wiley-search-result-wrapper.component';
import { KnownIssuesComponent } from './common/components/known-issues/known-issues.component';
import { UnauthorizedPageComponent } from './common/components/unauthorized-page/unauthorized-page.component';
import { LocationUpdateComponent } from './common/components/location-update/location-update.component';
import { DummyComponent } from './common/components/dummy/dummy.component';
import { TerminateUrlSesstionComponent } from './common/components/terminate-url-sesstion/terminate-url-sesstion.component';
import { UndermaintenanceComponent } from './undermaintenance/undermaintenance.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { FindPagesComponent } from './find-pages/find-pages.component';
import { FindpagesDownloadContentComponent } from './findpages-download-content/findpages-download-content.component';


const routes: Routes = [
  {
    path: 'check',
    loadChildren: () => import('./troubleshoot/troubleshoot.module').then(m => m.TroubleshootModule)
  },
  {
    path: 'check/:location',
    loadChildren: () => import('./troubleshoot/troubleshoot.module').then(m => m.TroubleshootModule)
  },
  /*
  * START: To manage OLD routes ':archive/location/:col'
  */
  {
    path: ':archive/location/:col',
    component: TerminateUrlSesstionComponent, canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: ':archive/location/:col',
    component: LocationUpdateComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DummyComponent, canActivate: [AuthGuard],
      }, {
        path: 'search',
        component: DummyComponent, canActivate: [AuthGuard]
      }, {
        path: 'detail/:docID',
        component: DummyComponent, canActivate: [AuthGuard]
      }, {
        path: 'about-us',
        component: DummyComponent, canActivate: [AuthGuard]
      },
      {
        path: 'collections',
        component: DummyComponent, canActivate: [AuthGuard]
      },
      {
        path: 'collection-details',
        component: DummyComponent, canActivate: [AuthGuard]
      },
      {
        path: 'term-frequency',
        component: DummyComponent, canActivate: [AuthGuard]
      },
      {
        path: 'unauthorized-page',
        component: DummyComponent
      }

    ]
  },
  /*
  * END: To manage OLD routes ':archive/location/:col'
  */
  {
    path: ':archive/auth',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'undermaintance',
    component: UndermaintenanceComponent
  },
  {
    path: ':archive',
    component: LandingPageWrapperComponent, canActivate: [AuthGuard],
     resolve: {
          'pageSettings': PageSettingsResolver
        }, 
    children: [
      {
        path: '',
        component: WileyBodyComponentComponent, canActivate: [AuthGuard]
      }, {
        path: 'search',
        component: WileySearchResultWrapperComponent, canActivate: [AuthGuard]
      }, {
        path: 'detail/:docID',
        component: DocumentViewerComponent, canActivate: [AuthGuard]
      }, {
        path: 'about-us',
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule), canActivate: [AuthGuard]
      },
      {
        path: 'collections',
        loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule), canActivate: [AuthGuard]
      },
      {
        path: 'collection-details',
        loadChildren: () => import('./collection-details/collection-details.module').then(m => m.CollectionDetailsModule), canActivate: [AuthGuard]
      },
      {
        path: 'analysis-hub',
        loadChildren: () => import('./analysis-hub/analysis-hub.module').then(m => m.AnalysisHubModule), canActivate: [AuthGuard]
      },
      {
        path: 'known-issues',
        component: KnownIssuesComponent, canActivate: [AuthGuard]
      },
      {
        path: 'unauthorized-page',
        component: UnauthorizedPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'explorer',
        loadChildren: () => import('./explorer/explorer.module').then(m => m.ExplorerModule), canActivate: [AuthGuard]
      },
      {
        path: 'downloadcontent',
        component: FindPagesComponent,
      },
      {
        path: 'downloadcontenttable',
        component:FindpagesDownloadContentComponent, canActivate: [AuthGuard],
      },

    ]
  },  
  {
    path: '',
    redirectTo: `/${environment.defaultArchive}/auth`,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: `/${environment.defaultArchive}`,
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
