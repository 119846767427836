<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="top_nav" *ngIf="pageContent">
  <div class="nav_menu">
    <nav *ngIf="!removeWithSearch">
      <div class="toggle-filterdiv" *ngIf="pageContent.showLeftMenu">
        <div class="col-md-10 col-sm-11 toggle-filterdivleft padding-left-0 padding-right-0">
          <label [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.FILTERS' | translate }}</label>
        </div>
        <div class="col-md-2 col-sm-1 toggle-filterright toggle-filterright-arrow padding-left-0 padding-right-0">
          <a title="{{ 'SearchResult.FILTERLeft' | translate }}" class="sidebar-toggle" role="button" tabindex="0"
            (click)="toggleFilters()" (keyup.enter)="toggleFilters()">
            <span class="spriteico leftToggleIcon"></span>
          </a>
        </div>
      </div>
      <div class="toggle-filterrightdiv" *ngIf="!pageContent.showLeftMenu">
        <a title="{{ 'SearchResult.FILTERRight' | translate }}" class="sidebar-toggle" tabindex="0"
          (click)="toggleFilters()" (keyup.enter)="toggleFilters()" role="button">
          <span class="spriteico rightToggleIcon"></span>
        </a>
      </div>
    </nav>
    <nav *ngIf="removeWithSearch">
      <div class="toggle-filterdiv" >
        <!-- <div class="col-md-10 col-sm-11 toggle-filterdivleft padding-left-0 padding-right-0"> -->
          <!-- <label [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.FILTERS' | translate }}</label> -->
        <label class="downTitle">Download Content</label>
        <!-- </div> -->
        <!-- <div class="col-md-2 col-sm-1 toggle-filterright toggle-filterright-arrow padding-left-0 padding-right-0">
          <a title="{{ 'SearchResult.FILTERLeft' | translate }}" class="sidebar-toggle" role="button" tabindex="0"
            (click)="toggleFilters()" (keyup.enter)="toggleFilters()">
            <span class="spriteico leftToggleIcon"></span>
          </a>
        </div> -->
      </div>
      <div class="toggle-filterrightdiv" *ngIf="!pageContent.showLeftMenu">
        <a title="{{ 'SearchResult.FILTERRight' | translate }}" class="sidebar-toggle" tabindex="0"
          (click)="toggleFilters()" (keyup.enter)="toggleFilters()" role="button">
          <span class="spriteico rightToggleIcon"></span>
        </a>
      </div>
    </nav>
    <form name="simpleSearchForm">
      <div class="filter-search">
        <div class="input-group" [class.checkFilter]="pageContent.showLeftMenu">
          <span class="input-group-addon search-checkbox" *ngIf="!removeWithSearch">
            <label [class.arabic-style]="isArabicLanguage" class="radio-inline showall-container"
              title="{{ 'SearchResult.SearchWithin' | translate }}" for="searchin"
              [ngClass]="{'active-filter': pageContent.isSearchWithIn, 'inactive-filter':!pageContent.isSearchWithIn}">
              <input tabindex="0" [class.arabic-style]="isArabicLanguage" type="radio" name="optradio" id="searchin"
                [checked]="pageContent.isSearchWithIn" [disabled]="!hasResults" [value]="true"
                (change)="searchSettingChange($event.target.value)">{{ 'SearchResult.SearchWithin' | translate }}
              <span class="showall-checkmark"></span>
            </label>
            <label [class.arabic-style]="isArabicLanguage" class="radio-inline showall-container"
              title="{{ 'SearchResult.NewSearch' | translate }}" for="newsearch"
              [ngClass]="{'active-filter': !pageContent.isSearchWithIn, 'inactive-filter':pageContent.isSearchWithIn}">
              <input tabindex="0" type="radio" name="optradio" id="newsearch" [value]="false"
                [checked]="!pageContent.isSearchWithIn" [class.arabic-style]="isArabicLanguage"
                (change)="searchSettingChange($event.target.value)">{{ 'SearchResult.NewSearch' | translate }}
              <span class="showall-checkmark"></span>
            </label>
          </span>
          <label *ngIf="!removeWithSearch" [class.arabic-style]="isArabicLanguage" class="sr-only"
            for="search_textbox">{{ 'AdvancedSearch.WhatAreYouSearchingFor' | translate }}</label>
          <input tabindex="0" *ngIf="pageContent.isSearchWithIn && !removeWithSearch" [class.arabic-style]="isArabicLanguage"
            aria-label="What are you searching for?" name="docSearch" appAutofocus
            title="{{ 'Common.Popups.searchWithInPlaceHolder' | translate }}" type="text" id="search_textbox"
            class="form-control filter-search-texbox seach-input" [placeholder]="pageContent?.showAdvancedFilter ? performingAdvSearch: searchWithinPlaceHolder"
            [value]="searchWithinKeyWord ? searchWithinKeyWord : ''" #searchTermValue autocomplete="off"
            [disabled]="pageContent?.showAdvancedFilter">
            <input tabindex="0" [class.arabic-style]="isArabicLanguage" *ngIf="!pageContent.isSearchWithIn  && !removeWithSearch "
              aria-label="What are you searching for?" name="docSearch" appAutofocus
              title="{{ 'AdvancedSearch.WhatAreYouSearchingFor' | translate }}" type="text" id="search_textbox"
              class="form-control filter-search-texbox seach-input"
              [placeholder]="pageContent?.showAdvancedFilter ? performingAdvSearch:  newSearchPlaceHolder"
              [value]="searchTerm ? searchTerm : ''" #searchTermValue autocomplete="off"
              [disabled]="pageContent?.showAdvancedFilter" (input)="onSearchChange($event.target.value)" >
              <!-- (input)="onSearchChange($event.target.value)" -->
          <!-- <input tabindex="0" [class.arabic-style]="isArabicLanguage" *ngIf="!pageContent.isSearchWithIn"
            aria-label="What are you searching for?" name="docSearch" appAutofocus
            title="{{ 'AdvancedSearch.WhatAreYouSearchingFor' | translate }}" type="text" id="search_textbox"
            class="form-control filter-search-texbox seach-input"
            [placeholder]="pageContent?.showAdvancedFilter ? performingAdvSearch:  newSearchPlaceHolder"
            [value]="searchTerm ? searchTerm : ''" #searchTermValue autocomplete="off" [disabled]="pageContent?.showAdvancedFilter"
            [(ngModel)]="pageContent.searchTerm"
            [typeahead]="suggestedSearchTerms"
            [typeaheadMinLength]="3"
            [typeaheadItemTemplate]="customItemTemplate"
            typeaheadOptionField="term"
            typeaheadWaitMs="4000" 
            isAnimated="true"
            (input)="onSearchChange($event.target.value)"
            typeaheadHideResultsOnBlur="true"
            typeaheadGroupField="payload"
            (typeaheadOnSelect)="typeaheadOnSelect($event)"> -->
          <!-- <ng-template #customItemTemplate let-model="item" let-index="index">
            <p class="suggested-term"> {{model?.term }}</p>
          </ng-template> -->
          
          <div class="dropdown-box text-right parent searchbox-revise">
            <button [class.arabic-style]="isArabicLanguage"
              title="{{ 'Common.REVISE' | translate }} ({{controlShortcut}} + J)" type="button"
              class="btn btn-secondary btn-adv-search" tabindex="0" (keyup.enter)="revise();$event.stopPropagation();"
              (click)="revise();$event.stopPropagation();" id="menu1">{{'Common.REVISE' | translate }}
            </button>
            <span class="help-btn" type="submit" tabindex="1" (click)="openHelp($event);"
              (keyup.enter)="openHelp($event);" *ngIf="!removeWithSearch">
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                title="{{ 'Common.Help' | translate }}">
                <i class="spriteico helpIcon"></i>
              </a>
            </span>

            <app-search-filter [page-content]="pageContent" [show-filter]="pageContent?.showAdvancedFilter"
              (filter-close)="advFilterClosed($event)" (disable-search)="disableSearch($event)" (estimated-count)="estimatedCount($event)"></app-search-filter>

            <div class="help-container" id="help_box" [class.show-help]="showHelp" (click)="$event.stopPropagation();">
             <div class="help-scrollbar">
              <!-- <perfect-scrollbar [config]="config" class="help-scrollbar"> -->
                <p [class.arabic-style]="isArabicLanguage">
                  <b>{{ 'AdvancedSearch.BasicSearch' | translate }}</b> -
                  {{ 'AdvancedSearch.BasicSearchContent' | translate }}
                </p>
                <p [class.arabic-style]="isArabicLanguage">{{ 'AdvancedSearch.Examples' | translate }}:</p>
                <ul [class.arabic-style]="isArabicLanguage">
                  <li [class.arabic-style]="isArabicLanguage">
                    <b>{{ 'AdvancedSearch.SimpleSearch' | translate }}</b>:
                    <span [class.arabic-style]="isArabicLanguage"
                      class="underline">{{ 'AdvancedSearch.ExecutiveCommittee' | translate }} </span> ({{ 'AdvancedSearch.ReturnsDocuments' | translate
                  }})
                  </li>
                  <li [class.arabic-style]="isArabicLanguage">
                    <b>{{ 'AdvancedSearch.SimpleSearch' | translate }}</b>
                    ({{ 'AdvancedSearch.UsingQuotes' | translate }}):
                    <span [class.arabic-style]="isArabicLanguage"
                      class="underline">"{{ 'AdvancedSearch.ExecutiveCommittee' | translate }}" </span>({{ 'AdvancedSearch.RetrievesOnlyDocuments'
                  | translate }})</li>
                  <li [class.arabic-style]="isArabicLanguage">
                    <b>{{ 'AdvancedSearch.BooleanSearch' | translate }}</b>
                    {{ 'AdvancedSearch.usingANDORNOT' | translate }}:
                    <span [class.arabic-style]="isArabicLanguage"
                      class="underline">{{ 'AdvancedSearch.ExecutiveORcommittee' | translate }}</span>
                  </li>
                  <li [class.arabic-style]="isArabicLanguage">
                    <b>{{ 'AdvancedSearch.ProximitySearch' | translate }}</b>:
                    <span [class.arabic-style]="isArabicLanguage"
                      class="underline">"{{ 'AdvancedSearch.ExecutiveCommittee' | translate }}"~3</span> ({{ 'AdvancedSearch.matchesCommitteewithin3words'
                  | translate }})</li>
                  <li [class.arabic-style]="isArabicLanguage">
                    <b>{{ 'AdvancedSearch.ExactMatch' | translate }}</b>:
                    <span [class.arabic-style]="isArabicLanguage" class="underline">"Color"</span>
                    ({{ 'AdvancedSearch.DoNotUseFuzzySearch' | translate }})</li>
                  <li [class.arabic-style]="isArabicLanguage">
                    <b>{{ 'AdvancedSearch.WildcardSearch' | translate }}</b> (* -
                    {{ 'AdvancedSearch.AnyNumber' | translate }},
                    ? - {{ 'AdvancedSearch.OneChar' | translate }}, ! - {{ 'AdvancedSearch.OneOrNoChar' | translate }}):
                    <span [class.arabic-style]="isArabicLanguage"
                      class="underline">{{ 'AdvancedSearch.MistakeColor' | translate }}</span>
                  </li>
                </ul>
                <p [class.arabic-style]="isArabicLanguage">
                  <b>{{ 'AdvancedSearch.Tips' | translate }}:</b>
                </p>
                <ul>
                  <li [class.arabic-style]="isArabicLanguage">{{ 'AdvancedSearch.YouDoNotNeedToEnter' | translate }},
                    <span class="underline">Résumé</span> {{ 'AdvancedSearch.IsSameAs' | translate }}
                    <span class="underline">Resume</span>
                  </li>
                  <li [class.arabic-style]="isArabicLanguage">{{ 'AdvancedSearch.StopWordsSuchAs' | translate }} "a",
                    "an", "the" etc. {{ 'AdvancedSearch.AreIgnored' |
                  translate }}</li>
                </ul>
              <!-- </perfect-scrollbar> -->
            </div>
            </div>
          </div>
        
          <span class="input-group-btn search-input-group-btn">
            <button [class.arabic-style]="isArabicLanguage" class="btn btn-primary filter-search-btn"
              id="simpleSearchBtn" type="submit" [disabled]="pageContent?.showAdvancedFilter && checkEstimatedCount === 0"
              (click)="simpleSearch()" tabindex="0" (keyup.enter)="simpleSearch()"
              title="{{ 'Common.Search' | translate }}">
              <i class="fa fa-search"></i>
              <span [class.arabic-style]="isArabicLanguage"
                class="search-text">&nbsp;{{ 'Common.CapsSEARCH' | translate }}</span>
            </button>
          </span>




          <!-- search suggestion code start -->
          <div class="pharse-div searchOperator" *ngIf="suggestedSearchTerms?.length > 0 || spellCheckSearchTerms?.length > 0" [class.floatLeft]="isChangeOperator !==''">
              <div *ngIf="spellCheckSearchTerms?.length > 0" class="spell-check-div">
                  <span class="text-means">Did you mean "<span class="spell-check-text" (click)="spellCheckSearchTerm(spellCheckSearchTerms)">{{spellCheckSearchTerms}}</span>"  ? </span>
              </div>
           
            <div *ngIf="spellCheckSearchTerms?.length === 0 && isChangeOperator !=='' && !typeaheadLoading && !noSuggestedTermsFound && !showAddQuotesOn">
              <span class="text-means">Did you mean "<span>{{isChangeOperator}}</span>" Operator ? </span>
              <span *ngIf="!ischangeToOperators" class="operator-text" (click)="changeToOperators()"> Yes</span>
              <span *ngIf="ischangeToOperators" class="operator-text" (click)="changeToOperators()"> No</span>
            </div>
            <div *ngIf="suggestedSearchTerms?.length > 0" [class.wholeStringBreak]="isChangeOperator !==''">
              <label class="switch">
                <input type="checkbox" (change)="toggle($event)">
                <span class="slider round">
                  <span class="slider-info-on" [class.on-btn]="showAddQuotesOn"> ON </span>
                  <span class="slider-info-off" [class.on-btn]="!showAddQuotesOn" > OFF </span>
                </span>
              </label>
              <span class="pharse-string-txt"> Search whole string</span>
            </div>
            

          </div>
          <ul class="suggested-terms-list" [style.width]="suggestedTermsListWidth"
            [class.change-operator]="isChangeOperator !=='' && !typeaheadLoading && !noSuggestedTermsFound" *ngIf="suggestedSearchTerms?.length > 0">
            <li class="suggested-list" [class.active-suggested-term]="currentIndex === i + 1" #suggestedTerms
              *ngFor="let suggestedterm of suggestedSearchTerms; let i = index;" tabindex="{{i + 1}}"
              (mouseenter)="suggestedTermsMouseEnter(suggestedterm.term, i + 1)"
              (mouseleave)="suggestedTermsMouseLeave(suggestedterm.term, i + 1)" (click)="typeaheadOnSelect(suggestedterm.term, i + 1)">
              <p class="sugg-term" title="{{ suggestedterm.term }}"
                > {{suggestedterm.term}} </p>
            </li>
          </ul>
          <div class="suggested-loading-text" *ngIf="typeaheadLoading">{{'HomePage.suggestedTermLoadingText' | translate}}</div>
          <div class="suggested-loading-text" *ngIf="noSuggestedTermsFound && !typeaheadLoading">{{'HomePage.noSuggestedTerms' | translate}}</div>
          <!-- search suggestion code end -->


        </div>
      </div>
    </form>
  </div>
  <div class="clearfix"></div>
  <div class="search-contenttype-dispaly" *ngIf="!removeWithSearch">
    <div class="row">
      <div class="col-md-9 c-type-left">
        <ul class="c-type" id="myDiv">
          <li [class.arabic-style]="isArabicLanguage" class="pull-left" *ngIf="contentTypesFacets.length > 0">{{ 'Common.ContentType' | translate }}: </li>
            <!-- <li class="child"
          [ngClass]="{'order1':(type.value == 'Manuscripts' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false)) || (isContentTypePhoto && type.value == 'Photographs') || (isContentTypeMap && type.value == 'Maps') || (contentTypeList.length>0 && type.order== '1'),
            'order2':(type.value == 'Monographs' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false))|| (isContentTypePhoto && type.value == 'Manuscripts') || (isContentTypeMap && type.value == 'Manuscripts') ||  (contentTypeList.length>0 && type.order== '2'),
            'order3':(type.value == 'Photographs' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false))  || (isContentTypePhoto && type.value == 'Maps') || (isContentTypeMap && type.value == 'Photographs' ||  (contentTypeList.length>0 && type.order== '3')),
            'order4':(type.value == 'Maps' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false)) || (contentTypeList.length>0 && type.order== '4')}"
          *ngFor="let type of contentTypesFacets; let i = index; let last = last;"
            (click)="showContentType(type)" (keyup.enter)="showContentType(type)">
            <span class="i-{{type.value}}-icons fa "></span>
            <a tabindex="0" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
              title="{{type.value | titlecase}}" class="contenttype-link">{{type.value | titlecase}}
              <span [class.arabic-style]="isArabicLanguage" class="count-badge">({{type.count}})</span>
            </a>
          </li> -->
          <li class="order{{i+1}}" *ngFor="let type of contentTypesFacets; let i = index;"
            (click)="showContentType(type)" (keyup.enter)="showContentType(type)">
            <span class="i-{{type.value}}-icons fa "></span>
            <a (contextmenu)="disableRightClick($event)" tabindex="0" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
              title="{{type.value | titlecase}}" class="contenttype-link">{{type.value | titlecase}}
              <span [class.arabic-style]="isArabicLanguage" class="count-badge">({{type.count}})</span>
            </a>
          </li>
        </ul>
        <ul class="drop-downDiv" *ngIf="contentTypesFacets.length > 0">
          <li class="mobileContentType">
            <a (contextmenu)="disableRightClick($event)" href="#" class="dropdown-toggle profile-image" data-toggle="dropdown">
              <button type="button" class="btn contentButton">
                <span [class.arabic-style]="isArabicLanguage" *ngIf="!selectedContentType">
                  {{ 'Common.ContentType' | translate }} </span>
                <span *ngIf="selectedContentType">
                  <!-- <span class="i-{{type.value}}-icons"></span> -->
                  <span [class.arabic-style]="isArabicLanguage" title="{{ 'Common.ContentType' | translate }}"
                    class="contenttype-link">{{selectedContentType}}
                  </span>
                </span>
                <b class="caret"></b>
              </button>
            </a>
            <ul class="dropdown-menu" [class.arabic-style]="isArabicLanguage">
              <li *ngFor="let type of contentTypesFacets; let i = index" (click)="showContentType(type)"
                class="{{type.value}}-1">
                <span class="i-{{type.value}}-icons fa "></span>
                <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage" title="{{ 'Common.ContentType' | translate }}"
                  class="contenttype-link">{{type.value | titlecase}}
                  <span [class.arabic-style]="isArabicLanguage" class="count-badge">({{type.count}})</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-md-3 c-type-right">
        <form class="form-inline pull-right">
          <div class="form-group" align="center">
            <label [class.arabic-style]="isArabicLanguage" class="sortBy"
              for="sel1">{{ 'Sort.SortBy' | translate }}</label>
            <span class="custom-dropdown big" [class.isFirefox]="isFirefox">
              <label [class.arabic-style]="isArabicLanguage" for="relevance_sort" class="sr-only">{{ 'Sort.Relevance' | translate }}</label>
              <select appHighlightSelect tabindex="0" [class.arabic-style]="isArabicLanguage"
                class="form-control firefoxText" title="{{ 'Sort.SortBy' | translate }} ({{shortcutKey}} + R)"
                id="relevance_sort" [ngModel]="currentSort" [ngModelOptions]="{standalone:true}"
                (change)="onSortOrderChange($event.target.value)">
                <option [class.arabic-style]="isArabicLanguage" value="">{{ 'Sort.Relevance' | translate }} </option>
                <option [class.arabic-style]="isArabicLanguage" value="asc">{{ 'Sort.OldestFirst' | translate }}
                </option>
                <option [class.arabic-style]="isArabicLanguage" value="desc">{{ 'Sort.LatestFirst' | translate }}
                </option>
              </select>
            </span>
            <i class="relevanceIcon" title="{{ 'Sort.relevanceRanking' | translate }}" tabindex="0"
              (click)="openRelevanceHelp($event);" (keyup.enter)="openRelevanceHelp($event);"></i>
             
            <div class="relevance-help-container" [class.show-showRelevanceHelp]="showRelevanceHelp"
              (click)="$event.stopPropagation();">
              <div class="help-scrollbar">
              <!-- <perfect-scrollbar [config]="config" class="help-scrollbar"> -->
              <p [class.arabic-style]="isArabicLanguage">
                <span> {{ 'Sort.relevanceContent' | translate }} </span>
                <span class="wiley-primary-text-color"> {{ 'Sort.term' | translate }} </span>
                <span> {{ 'Sort.relevanceContentcontinues' | translate }} </span>
                <span class="wiley-primary-text-color"> {{ 'Sort.term' | translate }} </span>
                <span> {{ 'Sort.relevanceContentcontinuesText' | translate }} </span>
                <span class="wiley-primary-text-color"> {{ 'Sort.relevanceContentText' | translate }} </span>
                <span> {{ 'Sort.document' | translate }}, </span>
                <span class="wiley-primary-text-color"> {{ 'Sort.overallRelevanceSearch' | translate }} </span>
                <span> {{ 'Sort.priorityOrderText' | translate }} </span>
              </p>
              <ul [class.arabic-style]="isArabicLanguage">
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.DocumentTitle' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.fullText' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'HomePage.publicationDate' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.itemCollectionTitle' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.authors' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.documentItemnotes' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.subjectsKeywords' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.contentTypeSubtype' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.Language' | translate }} </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.sourceInstitutionAndLocation' | translate }}
                  </span>
                </li>
                <li [class.arabic-style]="isArabicLanguage">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.wileyDocumentNumber' | translate }} </span>
                </li>
              </ul>
            <!-- </perfect-scrollbar> -->
              </div>
            </div>
            

          </div>
        </form>
      </div>
    </div>

  </div>
  <input type="text" id="handleFocusOnSuggestedTerms" autocomplete="off" style="position: absolute;width: 0;left: 0;opacity: 0;" />
</div>
