<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="swiper-wrapper">
    <img class="swiper-slide" alt="Swiper Slide" [src]="selectedImage ? selectedImage : noImageUrl" (click) = "gotoDocumentDetails()">
    <!-- <canvas class="swiper-slide" id="thumbnailCanvas"></canvas> -->
</div>
<div class="swiper-pagination"></div>
<div class="swiper-button-next"></div>
<div class="swiper-button-prev"></div>
<div class="swiper-buttons">
<div class="swiper-buttons-inner">
    <a  title="Previous Image" *ngIf = "selectedImageIndex > 0" (click)="loadPreviousImage();$event.stopPropagation()"><i class="fa fa-arrow-left fa-left-arrow"></i></a>
    <a title="Next Image" *ngIf = "selectedImageIndex != thumbnailImages?.length - 1"><i class="fa fa-arrow-right fa-right-arrow" (click)="loadNextImage();$event.stopPropagation()"></i></a>
</div>
<div class="image-viewer-frame-loader" [ngStyle]="{display:imageviewerframeLoader ? 'block':'none'}">
        <img alt="Loading..." class="img-icon" src="assets/images/three-dots.svg">
</div>
</div>
