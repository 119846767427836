<!--  Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="top_nav">
  <div class="padding-l-0">
    <nav>
      <div class="toggle-filterdiv" [ngStyle]="{'display': pageContentData.showLeftMenu ? 'block': 'none'}">
        <div class="col-sm-11 toggle-filterdivleft padding-left-0 padding-right-0">
          <label>{{ 'Detail.CapsDetails' | translate }}</label>
        </div>
        <div class="col-sm-1 toggle-filterright toggle-filterright-arrow padding-left-0 padding-right-0">
          <a class="sidebar-toggle" title="{{ 'Detail.leftMenu' | translate }}" role="button" (click)="toggleLeftMenu()">
            <span class="spriteico leftToggleIcon"></span>
            <!-- <i aria-hidden="true" class="fa fa-chevron-left fa-lg"></i> -->
          </a>
        </div>
      </div>
      <div class="toggle-filterrightdiv" [ngStyle]="{'display': pageContentData.showLeftMenu ? 'none': 'block'}">
        <a class="sidebar-toggle" title="{{ 'Detail.rightMenu' | translate }}" role="button" (click)="toggleLeftMenu()">
          <span class="spriteico rightToggleIcon"></span>
          <!-- <i aria-hidden="true" class="fa fa-chevron-right fa-lg"></i> -->
        </a>
      </div>
    </nav>
    <div class="col-md-12 details-page-div" [ngClass]="{'detailPageHeader': toggleHeader, 'nodetailPageHeader': !toggleHeader }"
      [ngStyle]="{'display': pageContentData.showLeftMenu ? 'block': 'none'}">
      <div class="details-page-searchcontent">
        <div class="details-page-header">
          <h3>{{ 'Detail.SearchWithinDoc' | translate }}</h3>
        </div>
        <form #keyWordForm="ngForm">
          <div class="details-page-content">
            <label for="document_search" class="sr-only">{{ 'Detail.EnterSearchKeyword' | translate }}</label>
            <!-- <input type="text" id="document_search" class="tect-control search-txt" [value]="documentSearchTerm" placeholder="Enter Search Keyword" maxlength="100" #detailsSearchTerm/> -->
            <input type="text" id="document_search" aria-label="Enter Search Keyword" [value]="documentSearchTerm"
              title="{{ 'Detail.EnterSearchKeyword' | translate }} ({{shortcutKeyTitle}} + W) " class="tect-control search-txt"
              placeholder="{{ 'Detail.EnterSearchKeyword' | translate }}" maxlength="100" #detailsSearchTerm
              autocomplete="off" autofocus/>
            <button class="btn search-icon" type="submit" (click)="searchWithin(detailsSearchTerm.value)" id="searchWithinBtn">
              <i class="fa fa-search" aria-hidden="true"></i>
              <span class="sr-only sr-color">{{ 'Detail.searchIcon' | translate }}</span>
            </button>

            <!-- for fuzzy search  *ngIf="searchTermData"-->
            <label class="wiley-admin-text check-container" *ngIf="documentSearchTerm || searchTermData">
              <input type="checkbox" class="disable-label" (click)="togglefuzzySearch()" name="active" id="searchWithinCheckbox">
              <span>{{ 'Detail.ExactMatch' | translate }}</span>
              <span class="checkmark"></span>
            </label>

          </div>
        </form>
      </div>
      <accordion>

        <div class="details-page-innerdiv">
          <!-- *ngIf="documentData?.docType == 'Manuscripts'" -->
          <div *ngIf="documentData?.docType !== 'Monographs'" class="items-treenode">
            <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggleanalysisicon')">
              <div accordion-heading>
                <a href="javascript:void(0)" title="{{ 'Detail.Items' | translate }}">
                  <label class="items-label">{{ 'Detail.Items' | translate }}</label>
                </a>
                <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !toggleanalysisicon ? 'block': 'none'}"></i>
                <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': toggleanalysisicon ? 'block': 'none'}"></i>
              </div>
              <tabset #itemsTab class="item-facet-tabset" id="itemTabset">
                <tab [customClass]="'tableofcontents-tabs bytype-tab'" heading="" id="itemTab1" title="{{ 'Detail.ByType' | translate }} ({{shortcutKeyTitle}} + Q)">
                  <ng-template tabHeading>
                    <span title="{{ 'Detail.ByType' | translate }} ({{shortcutKeyTitle}} + Q)">{{ 'Detail.ByType' |
                      translate }}</span>
                  </ng-template>
                  <div class="treenode-icons itemstabset-treenode">
                    <tree-root #byTypeTree id="manuscriptTree1" [focused]="true" [nodes]="manuscriptTypeTableofContentNodes"
                      (updateData)="expandTreeOnLoad($event)" (activate)="onNodeSelect($event)" (deactivate)="loadNodedataToItems($event)">
                      <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                        <div class="tree-node" *ngIf="node">
                          <tree-node-expander [node]="node"></tree-node-expander>
                          <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                            [class.node-content-wrapper-focused]="checkPageIdExist(node?.data?.chilldPageIds)">
                            <a href="javascript:void(0)" [class.node-link]="node?.data?.chilldPageIds" [ngClass]="addPageIdAsClass(node?.data?.chilldPageIds)"
                              class="treenode-childnode" [class.title]="true" [attr.title]="node?.data?.name">{{
                              node?.data?.name }}</a>
                          </div>
                          <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                        </div>
                      </ng-template>
                    </tree-root>
                  </div>
                </tab>
                <tab [customClass]="'tableofcontents-tabs byitem-tab'" heading="" id="itemTab2" title="{{ 'Detail.BySequence' | translate }} ({{ControlShortcutKey}} + Q)">
                  <ng-template tabHeading>
                    <span title="{{ 'Detail.BySequence' | translate }} ({{ControlShortcutKey}} + Q)">{{
                      'Detail.BySequence' | translate }}</span>
                  </ng-template>
                  <div class="treenode-icons itemstabset-treenode">
                    <tree-root id="manuscriptTree2" [focused]="true" [nodes]="manuscriptOrderTableofContentNodes"
                      (activate)="onNodeSelect($event)" (deactivate)="loadNodedataToItems($event)">
                      <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                        <div class="tree-node">
                          <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                            [class.node-content-wrapper-focused]="checkPageIdExist(node?.data?.chilldPageIds)">
                            <a href="javascript:void(0)" [class.node-link]="node?.data?.chilldPageIds" [ngClass]="addPageIdAsClass(node?.data?.chilldPageIds)"
                              class=" sequence-tree treenode-childnode m-w300" [attr.title]="node?.data?.name"
                              [class.title]="true">{{ node?.data?.name }}</a>
                          </div>
                          <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                        </div>
                      </ng-template>
                    </tree-root>
                  </div>
                </tab>
              </tabset>
            </accordion-group>
          </div>

          <div *ngIf="documentData?.docType == 'Monographs'">
            <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggleanalysisicon')">
              <div accordion-heading>
                <div class="details-page-header">
                  <label class="items-label"> {{ 'Detail.TableOfContents' | translate }}</label>
                  <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !toggleanalysisicon ? 'block': 'none'}"></i>
                  <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': toggleanalysisicon ? 'block': 'none'}"></i>
                </div>
              </div>
              <div class="col-md-12 padding-0" style="margin-top: -10px;">
                <tree-root id="monographTree" (updateData)="expandTreeOnLoad($event)" [focused]="true" [nodes]="monographTableofContentNodes"
                  (activate)="onNodeSelect($event)" (deactivate)="loadNodedataToItems($event)">
                  <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                    <div class="tree-node" *ngIf="node">
                      <tree-node-expander [node]="node"></tree-node-expander>
                      <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                        [class.node-content-wrapper-focused]="node?.data?.pageId === selectedDocPageId?.pageId">
                        <a [class.node-link]="node?.data?.chilldPageIds" [ngClass]="addPageIdAsClass(node?.data?.chilldPageIds)"
                          [class]="node.data.className" [class.title]="true" [attr.title]="node?.data?.name"
                          (mouseenter)="mouseEnter(node)" (mouseleave)="mouseLeave(node)">{{
                          node?.data?.name }}</a>
                      </div>
                      <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                    </div>
                  </ng-template>
                </tree-root>
              </div>
              <!--<div class="page-content-details">
                <a title="{{ 'Common.ViewMore' | translate }}" class="text-right viewmore-link"> {{ 'Common.ViewMore' | translate }} ... </a>
              </div> -->
            </accordion-group>
          </div>

        </div>

        <div class="details-page-innerdiv" id="illustrationTree" *ngIf="documentData?.docType != 'Monographs' && documentData?.docType != 'Photographs' && documentData?.docType != 'Maps' && showIllustrations"
          [style.border-bottom]="showIllustrations ? '1px solid #aaa' : 'none'">
          <div class="illustrations-treenode">
            <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggleDoctypeicon')">
              <div accordion-heading>
                <div class="details-page-header">
                  <label *ngIf="documentData?.docType !== 'Manuscripts'" title="{{ 'Detail.Items' | translate }}" class="items-label">
                    {{ 'Detail.Items' | translate }} </label>
                  <label *ngIf="documentData?.docType === 'Manuscripts'" title="{{ 'SearchResult.Illustrations.Illustrations' | translate }}"
                    class="items-label"> {{ 'SearchResult.Illustrations.Illustrations' | translate }} </label>
                  <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !toggleDoctypeicon ? 'block': 'none'}"></i>
                  <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': toggleDoctypeicon ? 'block': 'none'}"></i>
                </div>
              </div>

              <div class="col-md-12 padding-0" style="margin-top: -10px;">
                <tree-root id="illustrationTree" (updateData)="expandTreeOnLoad($event)" [focused]="true" [nodes]="illustrationNodes"
                  (activate)="onTableSelect($event)" (deactivate)="loadNodedataIllustrations($event)">
                  <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                    <div *ngIf="node" class="tree-node">
                      <tree-node-expander [node]="node"></tree-node-expander>
                      <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                        [class.node-content-wrapper-focused]="(node?.data?.pageId === selectedDocPageId?.pageId)">

                        <a [class.node-link]="node?.data?.pageId" [ngClass]="addPageIdAsClass(node?.data?.pageId)"
                          (mouseenter)="mouseEnter(node)" (mouseleave)="mouseLeave(node)" class="illustration-tree treenode-childnode"
                          [class]="node.data.className" [attr.title]="node?.data?.name" [class.title]="true">{{
                          node?.data?.name }}</a>
                      </div>
                      <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                    </div>
                  </ng-template>
                </tree-root>
              </div>
              <div class="illustration-image-body" id="illustrationImgDiv" style="display:none">
                <img class="accordian-img center-block img-responsive" [attr.src]="illustrationDocumentImage" alt="{{'Alt.imageThumbnails' | translate}}" />
                <div class="illustration-image-loader" [ngStyle]="{display:illustrationsLoader ? 'block':'none'}">
                  <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
                </div>
              </div>
              <!-- <div class="page-content-details">
                  <a title="View More" class="text-right viewmore-link"> View More ... </a>
              </div> -->
            </accordion-group>
          </div>
        </div>
        <div class="details-page-innerdiv" id="illustrationTree" *ngIf="documentData?.docType === 'Monographs' && showIllustrations"
          [style.border-bottom]="showIllustrations ? '1px solid #aaa' : 'none'">
          <div class="illustrations-treenode">
            <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggleDoctypeicon')">
              <div accordion-heading>
                <div class="details-page-header">
                  <label *ngIf="documentData?.docType !== 'Manuscripts'" title="{{ 'Detail.Items' | translate }}" class="items-label">
                    {{ 'SearchResult.Illustrations.Illustrations' | translate }} </label>
                  <label *ngIf="documentData?.docType === 'Manuscripts'" title="{{ 'SearchResult.Illustrations.Illustrations' | translate }}"
                    class="items-label"> {{ 'SearchResult.Illustrations.Illustrations' | translate }} </label>
                  <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !toggleDoctypeicon ? 'block': 'none'}"></i>
                  <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': toggleDoctypeicon ? 'block': 'none'}"></i>
                </div>
              </div>

              <div class="col-md-12 padding-0" style="margin-top: -10px;">
                <tree-root id="illustrationTree" (updateData)="expandTreeOnLoad($event)" [focused]="true" [nodes]="illustrationNodes"
                  (activate)="onTableSelect($event)" (deactivate)="loadNodedataIllustrations($event)">
                  <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                    <div *ngIf="node" class="tree-node">
                      <tree-node-expander [node]="node"></tree-node-expander>
                      <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                        [class.node-content-wrapper-focused]="(node?.data?.pageId === selectedDocPageId?.pageId)">

                        <a [class.node-link]="node?.data?.pageId" [ngClass]="addPageIdAsClass(node?.data?.pageId)"
                          (mouseenter)="mouseEnter(node)" (mouseleave)="mouseLeave(node)" class="illustration-tree treenode-childnode"
                          [class]="node.data.className" [attr.title]="node?.data?.name" [class.title]="true">{{
                          node?.data?.name }}</a>
                      </div>
                      <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                    </div>
                  </ng-template>
                </tree-root>
              </div>
              <div class="illustration-image-body" id="illustrationImageDiv" style="display:none">
                <img class="accordian-img center-block img-responsive" [attr.src]="illustrationDocumentImage" alt="{{'Alt.imageThumbnails' | translate}}" />
                <div class="illustration-image-loader" [ngStyle]="{display:illustrationsLoader ? 'block':'none'}">
                  <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
                </div>
              </div>
              <!-- <div class="page-content-details">
                  <a title="View More" class="text-right viewmore-link"> View More ... </a>
              </div> -->
            </accordion-group>
          </div>
        </div>
        <div *ngIf="isRelatedSubject" class="details-page-innerdiv">
          <div class="relatedsub-treenode">
            <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'togglerelatedsubicon')">
              <div accordion-heading>
                <div class="details-page-header">
                  <a title="{{ 'Detail.RelatedSubjects' | translate }}" href="javascript:void(0)">
                    <label class="items-label"> {{ 'Detail.RelatedSubjects' | translate }}</label>
                  </a>
                  <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !togglerelatedsubicon ? 'block': 'none'}"></i>
                  <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': togglerelatedsubicon ? 'block': 'none'}"></i>
                </div>
              </div>
              <div class="col-md-12 padding-0">
                <ul class="relatedsub-ul" id="relatedSubjects">
                  <li *ngFor="let relatedSubject of documentData?.relatedSubjects" class="relatedsub-list">
                    <a title="{{relatedSubject}}" href="javascript:void(0)" class="relatedsub-link" (click)="setSubject(relatedSubject)">{{relatedSubject}}</a>
                  </li>
                </ul>
              </div>
              <!-- <div class="page-content-details" *ngIf="documentData?.relatedSubjects?.length > 4">
                    <a title="View More" class="text-right viewmore-link" id="viewMore" (click) = "getRelatedSubjects()"> View More ... </a>
                </div> -->
            </accordion-group>
          </div>
        </div>
      </accordion>
    </div>
  </div>
</div>
