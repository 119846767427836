import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecodeEntitiesDirective } from '../../common/directives/decode-entities.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DecodeEntitiesDirective,
    // EncodeEntityPipe
  ],
  exports: [DecodeEntitiesDirective]
})
export class UnicodeConverterModule { }
