
import {catchError} from 'rxjs/operators';

import {throwError as observableThrowError ,  Observable } from 'rxjs';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable } from '@angular/core';
import { environment, APIURLS } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpService } from './http.service';
import { SharedService } from './sharedService';
import { Router } from '@angular/router';
import { isArray as _isArray } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable()
export class AuthService {
    host: string;
    originalPath: string;
    urlArchive: string;
    personalizationData: any;
    isGuestUser: boolean;
    currentLocationObj: any;
    currentLocation: any;
    token: string;
    helper = new JwtHelperService();
    locInfo: any;
    constructor(
        private httpClient: HttpClient,
        private httpService: HttpService,
        private sharedService: SharedService,
        private router: Router,
        private commonService: CommonService,
    ) {
        /*
        * Detect Guest login
        */
        this.host = window.location.host;
        this.originalPath = window.location.pathname;
        const urlPath = window.location.href.split(';');
        this.locInfo = localStorage.getItem('currentLocation');
        this.urlArchive = window.location.pathname.split('/')[1];
        const isAccessType = localStorage.getItem('accessType');
        if (!isAccessType || !(isAccessType === 'guest')) {
            if (urlPath.length > 0) {
                urlPath.forEach(obj => {
                    const urlParams = obj.split('=');
                    if (urlParams.length > 0) {
                        if (urlParams[0] === 'location_id') {
                            this.locInfo = urlParams[1];
                        }
                    }
                });
                urlPath.forEach(obj => {
                    const urlParams = obj.split('=');
                    if (urlParams.length > 0) {
                        if (urlParams[0] === 'guestAuthToken') {
                                this.setGuestToken(this.locInfo, urlParams[1]);
                        }

                    }
                });
            }
        } else {
            this.isGuestUser = true;
            this.sharedService.isGuestUser(this.isGuestUser);
            if (urlPath.length > 0) {
                urlPath.forEach(obj => {
                    const urlParams = obj.split('=');
                    if (urlParams.length > 0) {
                        if (urlParams[0] === 'location_id') {
                            this.locInfo = urlParams[1];
                        }
                    }
                });
                urlPath.forEach(obj => {
                    const urlParams = obj.split('=');
                    if (urlParams.length > 0) {
                        if (urlParams[0] === 'guestAuthToken') {
                            this.setGuestToken(this.locInfo, urlParams[1], true);
                        }

                    }
                });
            }
        }
    }

    public isTokenExpired(): boolean {
        const accessType = localStorage.getItem('accessType');
        let token: any;
        if (accessType === 'guest') {
            token = sessionStorage.getItem('auth_token');
        } else {
            token = localStorage.getItem('auth_token');
        }
        const isExpired = this.helper.isTokenExpired(JSON.parse(token));
        if (isExpired) {
            this.httpService.stopAllServices();
        }
        return isExpired;
    }
    public isAuthenticated(): boolean {
        const accessType = localStorage.getItem('accessType');
        if (accessType === 'guest') {
            this.token = sessionStorage.getItem('auth_token');
        } else {
            this.token = localStorage.getItem('auth_token');
        }
        if (this.token) {
            return this.token ? true : false;
        } else {
            return false;
        }
    }

    getIp(): Observable<any> {
        return this.httpClient.get<any>(environment.APIUrl + APIURLS.getIp, { responseType: 'text' as 'json' }).pipe(catchError(this.handleError));
    }

    private handleError(error: Response) {
        return observableThrowError(error.statusText);
    }

    getSessionTime(token: any) {
        return this.helper.getTokenExpirationDate(token);
    }

    setGuestToken(location, guestToken, option?) {
        const isAccessArchive = this.checkArchiveAccess(this.urlArchive);
        const tokenExpired = this.isTokenExpired();
        const userNotLoggedIn = !this.isAuthenticated();
        if (userNotLoggedIn || option) {
            localStorage.setItem('currentLocation', location);
            this.commonService.getArchives(location);
            localStorage.setItem('accessType', 'guest');
            sessionStorage.setItem('auth_token', JSON.stringify(guestToken));
            this.isGuestUser = true;
            this.sharedService.isGuestUser(this.isGuestUser);
        } else if (tokenExpired) {
            localStorage.setItem('accessType', 'guest');
            localStorage.setItem('auth_token', JSON.stringify(guestToken));
            sessionStorage.setItem('auth_token', JSON.stringify(guestToken));
            sessionStorage.setItem('isNeedToLogout', 'yes');
            this.isGuestUser = true;
            this.sharedService.isGuestUser(this.isGuestUser);
        } else if (!isAccessArchive)  {
            const path = window.location.pathname;
            const patharry = path.split('/');
            patharry.splice(0, 2);
            let url = `/${localStorage.getItem('currentArchive')}/`;
            patharry.forEach(res => {
                url += res + '/';
            });
            window.location.pathname = url;
            this.isGuestUser = false;
            this.sharedService.isGuestUser(this.isGuestUser);
        }
    }

    checkArchiveAccess(urlArchive) {
        if (urlArchive) {
            const archivesList = ['WILEY'];
            const personalizationObj = JSON.parse(localStorage.getItem('personalizationData'));
            if (personalizationObj) {
                if (_isArray(personalizationObj.archives)) {
                    personalizationObj.archives.forEach(obj => {
                        archivesList.push(obj.shortName.toUpperCase());
                    });
                }
                if (archivesList.indexOf(urlArchive.toUpperCase()) > -1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    logout(): void {
        this.httpClient.post<any>(environment.APIUrl + APIURLS.logout, null).subscribe(res => { });
        const currentArchive = localStorage.getItem('currentArchive');
        const authType = localStorage.getItem('accessType');
        // const currentCaptchaType = localStorage.getItem('isGoogleCaptchaAvailable');
        this.httpService.hideAll();
        localStorage.clear();
        sessionStorage.clear();
        if (authType === 'IP') {
            setTimeout(() => {
                this.router.navigate([`${currentArchive || environment.defaultArchive}/auth/ip`, { source: 'logout' }]);
            });
        } else if (authType === 'guest') {
            this.router.navigate([`${currentArchive || environment.defaultArchive}/auth/pass`]);
        } else {
            this.router.navigate([`${currentArchive || environment.defaultArchive}/auth/pass`]);
        }
        // if (currentCaptchaType) {
        //     localStorage.setItem('isGoogleCaptchaAvailable', currentCaptchaType);
        // }
    }
}
