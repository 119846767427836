
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { isArray } from 'util';
import {
  Component, Input, AfterContentInit, ElementRef, ViewChild, Renderer2, Inject, HostListener,
  ChangeDetectorRef, Output, EventEmitter, OnDestroy, TemplateRef
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from './../common/services/http.service';
import { environment } from '../../environments/environment';
import { SharedService } from '../common/services/sharedService';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalService } from '../common/services/modal.service';
import { Location } from '@angular/common';
import { PageSettingsService } from './../common/services/page-settings.service';
import { SearchService } from './../common/services/search.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription , Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UrlUtilityService } from '../common/services/url-utility.service';
import { UtilityService } from '../common/services/utility.service';
import { AuthService } from '../common/services/auth.service';
import { APIURLS } from '../../environments/environment.qa';
// declare let addthis: any;
declare let OpenSeadragon: any;

export enum KEY_CODE {
  ESCAPE_KEYCODE = 27,
  UP_ARROW = 34,
  DOWN_ARROW = 33

}
export const maxPrintPages = 30;
declare let $: any;
@Component({
  selector: 'app-new-image-viewer-frame',
  templateUrl: './new-image-viewer-frame.component.html',
  styleUrls: ['./new-image-viewer-frame.component.css']
})
export class NewImageViewerFrameComponent implements AfterContentInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  pdfUrl: any = '';
  pageIdFromurl: any;
  unsubscribeParams: Subscription;
  unsubFlimstripeImage: Subscription;
  showOverlayValue: any;
  private downloadPrintmodalRef: BsModalRef;
  // @ViewChild('downloadPrintTemplate') private downloadPrintTemplate: TemplateRef<any>;
	@ViewChild('downloadPrintTemplate', { static: false}) downloadPrintTemplate: TemplateRef<any>;

  private printWarningModalRef: BsModalRef;
  // @ViewChild('printWarningTemplate') private printWarningTemplate: TemplateRef<any>;
	@ViewChild('printWarningTemplate', { static: false}) printWarningTemplate: TemplateRef<any>;

  private downloadPDFmodalRef: BsModalRef;
  // @ViewChild('downloadPageasPdfTemplate') private downloadPageasPdfTemplate: TemplateRef<any>;
  @ViewChild('downloadPageasPdfTemplate', { static: false}) downloadPageasPdfTemplate: TemplateRef<any>;

  _callZoomOut: { random: number; };
  _callZoomIn: { random: number; };
  currentPageOverlay: any;
  isShowFloatIcons: Boolean = true;
  showFittoHeight: Boolean = false;
  imagesWithTitle: any[];
  unSubImageViewerHighlight: Subscription;
  hasDocumentAccess: boolean;
  isFromResultPage: boolean;
  mailSent: any;
  emailNotDelivered: any;
  showOverlay: Boolean;
  showOverlayFloat: any = 1;
  private sharemodalRef: BsModalRef;
  // @ViewChild('shareHtmlPageTemplate') private shareHtmlPageTemplate: TemplateRef<any>;
  @ViewChild('shareHtmlPageTemplate', { static: false }) private shareHtmlPageTemplate: TemplateRef<any>;

  useFollowingLinktoAccessContent: any;
  shareMailData: ShareMail = {
    'topic': '',
    'message': '',
    'to': '',
    'from': ''
  };
  shareUrl: string;
  toggleviewlist: boolean;
  viewListCount: any;
  viewList: any;
  unSubViewListObservable: Subscription;
  documentData: any;
  unSubDocumentData: Subscription;
  copytoClipboard: any;
  citationCopiedtoClipboard: any;
  modelcitaionCHICAGO: Boolean = false;
  modelcitaionAPA: Boolean = false;
  modelcitaionMLA: Boolean = false;
  citaionAPA: Boolean = false;
  citaionCHICAGO: Boolean = false;
  citaionMLA: Boolean = false;
  requestParams: any;
  private citationmodalRef: any;
  // @ViewChild('citationPageTemplate') private citationPageTemplate: TemplateRef<any>;
  @ViewChild('citationPageTemplate', { static: false }) private citationPageTemplate: TemplateRef<any>;

  citationRes: any;
  showClipBoardSuccessAlert: boolean;
  private bookMarkmodalRef: BsModalRef;
  // @ViewChild('bookmarkHtmlPageTemplate') bookmarkHtmlPageTemplate: TemplateRef<any>;
  @ViewChild('bookmarkHtmlPageTemplate', { static: false }) private bookmarkHtmlPageTemplate: TemplateRef<any>;

  bookmarkPageURL: any;
  searchTermCopy: any;
  isNeedToLogout: string;
  documentImages: any[];
  isPrintMenuOpen = false;
  isDownloadMenuOpen = false;
  isBrightnessMenuOpen = false;
  isImageviewerMenuOpen = false;
  checkHightLightChangeTypes: any;
  isSessionGuest: string;
  currentDocumentId: any;
  currentDocPageUrl: any;
  isdefaultPageIdChanged = false;
  invalidPagenumber: any;
  limitReachExcededPage: any;
  pagesText = ' Page(s)';
  downloadingText = 'Downloading...';
  downloadText = 'Download';
  printText = 'Print';
  retrievingText = 'Retrieving content...';
  clipImageToastMessage: any;
  selection = false;
  unSubThumbnailData: Subscription;
  unSubPageId: Subscription;
  translatedValues: any;
  errorWhileDownload: any;
  printingError: any;
  unblockPopup: any;
  popupBlocked: any;
  invalidPageRange: any;
  enterPageNumber: any;
  invalidRange: any;
  searchResults: string;
  searchTermNotFound: string;
  // fuzzySearch: boolean;
  releventPageResponse: any;
  brightnessValue = 0;
  controlShortcut: any;
  shortcutKeyTitle: any;
  pageDown: any = 'Page Down';
  pageUp: any = 'Page Up';
  searchTermwithSpaces: any;
  initialSearchTerm: any;
  showadvancedSearchKeywords: Boolean = false;
  advancedSearchKeywords = [];
  pageContentData: any;
  searchTermData: any;
  documentSearchTerm: any;
  contrastAdjusted: Boolean = false;
  brightnessAdjusted: Boolean = false;
  currentPageId: any;
  checkHightLightChange: any;
  // @ViewChild('imageEditorContainer', { read: ElementRef }) mainContainer: ElementRef;
  // @ViewChild('thumbsContainer', { read: ElementRef }) private thumbsContainer: ElementRef;
  // @ViewChildren('thumbImg', { read: ElementRef }) private thumbImg: any;
  // @ViewChild('editorContainer', { read: ElementRef }) private editorContainer: ElementRef;
  // @ViewChild('listViewContainer', { read: ElementRef }) private listViewContainer: ElementRef;
  // @ViewChild('imageToolbarForm', { read: NgForm }) private brightnessControl: NgForm;
  // @ViewChild('nextBtn', { read: ElementRef }) private nextBtn: ElementRef;
  // @ViewChild('prevBtn', { read: ElementRef }) private prevBtn: ElementRef;
  @ViewChild('imageEditorContainer', { static: false, read: ElementRef }) mainContainer: ElementRef;
  @ViewChild('thumbsContainer', { static: false, read: ElementRef }) thumbsContainer: ElementRef;
  @ViewChild('thumbImg', { static: false, read: ElementRef }) thumbImg: any;
  @ViewChild('editorContainer', { static: false, read: ElementRef }) editorContainer: ElementRef;
  @ViewChild('listViewContainer', { static: false, read: ElementRef }) listViewContainer: ElementRef;
  @ViewChild('imageToolbarForm', { static: false, read: NgForm }) brightnessControl: NgForm;
  @ViewChild('nextBtn', { static: false, read: ElementRef }) nextBtn: ElementRef;
  @ViewChild('prevBtn', { static: false, read: ElementRef }) prevBtn: ElementRef;

  @Input('options') options: any;
  @Input() documentTitleImages: any;
  showFloatingMenu: Boolean = false;
  hoverFloatingMenu: Boolean = false;
  urlToDownload: any;
  urlForDownload: any;
  increasedBrightness: number;
  @Input('fuzzySearch')  fuzzySearch;
  @Input('documentCurrentIndex')
  set DocumentCurrentIndex(val: any) {
    this.currentActiveImageIndex = val;
  }
  @Input('urlToDownload')
  set downloadUrl(val: any) {
    this.urlToDownload = val;
  }
  @Input('urlForDownload')
  set downloadUrlFor(val: any) {
    this.urlForDownload = val;
  }
  @Input()
  set overlayOption(val: any) {

    if (val) {
      if (val.status === false) {
        this.showOverlayFloat = 1;
        this.hoverFloatingMenu = true;
      } else {
        this.showOverlayFloat = 0;
        this.hoverFloatingMenu = false;
      }
    } else {
      this.showOverlayFloat = 1;
      this.hoverFloatingMenu = false;
    }
    setTimeout(() => {
      this.showOverlayValue = val;
    }, 3500);
  }
  @Input('showDragonViewer') showDragonViewer: [any];
  @Output('relevantStatusChanged') private relevantStatusChanged: EventEmitter<any>;
  @Output('overlayClicked') private overlayClicked: EventEmitter<any>;
  @Output('pageFullText') private pageFullText: EventEmitter<any> = new EventEmitter();
  @Output('currentActiveImageIndex') private currentActiveImageIndex: EventEmitter<any> = new EventEmitter();
  @Output('whenMainImageLoaded') private whenMainImageLoaded: EventEmitter<any> = new EventEmitter();
  @Output('newOverlayFloatingVal') private newOverlayFloatingVal: EventEmitter<any> = new EventEmitter();
  @Output('rotationValue') private rotationValue: EventEmitter<any> = new EventEmitter();
  _source: any;
  isInFullScreenMode: Boolean = false;
  isInListViewMode: Boolean = false;
  private isEditorLoaded: Boolean = false;
  currentImage: any;
  private currentImageURL: string;
  currentBrightness = 0;
  currentContrast = 0;
  currentAngle = 0;
  currentZoomLevel = 1;
  isInverted: Boolean = false;
  private isCheckedInvertCbx = '';
  currentSelectedImage: any;
  currentSelectedImageIndex: number;
  currentFocusedImageIndex: number;
  currentPageNum: number;
  isPageResized: Boolean = false;
  isEditorInitialized: Boolean = false;
  private containerHeight: number;
  private containerWidth: number;
  fitToWidth: Boolean = true;
  restoreOriginalImage: Boolean = false;
  listOfImages: any = [];
  private thumbHeight: any = 0;
  thumbScrollPosition: any = null;
  private _isShowingRelevantImages: Boolean = false;
  noOfRelevantImages = 0;
  private _defaultPage: any;
  private currentDocID: any;
  downloadImagesModel: any;
  pagesToDownload = '';
  printImageModel: any;
  pagesToPrint: any;
  showEditorMenu: Boolean = false;
  showFilmStripMenu: Boolean = false;
  private pageInfo: any = [];
  toggleHighlighter = true;
  private image_id: any;
  private page_id: any;
  private img_title: any;
  private page;
  private pageNo;
  private childNodes = [];
  private currentItemTitle;
  isDownloadingInProgress: Boolean;
  isPrintInProgress: Boolean;
  enableOverlay: any = true;
  errorMsg: any = '';
  private validPage: Boolean = false;
  private validPrintPage: Boolean = false;
  private isValidationPage: Boolean = false;
  private validPageNumber;
  private notvalidPageNumber;
  private validPageNumberforpdf;
  addFilmStrip = false;
  isInvalidPageNumber: Boolean = true;
  changeBrightness: Boolean;
  fullScreenForIpad: Boolean;
  fullWidthInIpad: Boolean;
  @Input('itemTitle') itemTitle: any;
  @Input('showRelevantImages')
  set isShowingRelevantImages(flag: Boolean) {
    this._isShowingRelevantImages = flag;
    if (this._isShowingRelevantImages) {
      // this.showRelevantImages();
    }
  }

  get isShowingRelevantImages() {
    return this._isShowingRelevantImages;
  }


  searchTermOverlay: any;
  @Input('overlayForSearchTerm')
  set _searchTermOverlay(value: any) {
    if (value) {
      this.searchTermOverlay = value;
    }
  }

  get _searchTermOverlay() {
    return this.searchTermOverlay;
  }


  @Input()
  set defaultPage(page: any) {
    this._defaultPage = page;
  }

  get defaultPage() {
    return this._defaultPage;
  }


  @Input('source')
  set source(imgs: Array<any>) {
    if (isArray(imgs)) {
      if (imgs.length > 0) {
        // console.log('input source', imgs);
        this.documentImages = imgs;
    if (imgs !== undefined) {
      this.listOfImages = [];
      this.noOfRelevantImages = 0;
      this._source = {};
      // imgs.forEach((img) => {
      //   this._source[img.imageId] = img;
      //   if (img.isRelevant) {
      //     this.noOfRelevantImages += 1;
      //   }
      //   this.listOfImages.push({ imageId: img.imageId, imgTitle: 'Untitled' });
      // });
      const firstImage = this.listOfImages[0];
      if (this.unsubscribeParams) {
        this.unsubscribeParams.unsubscribe();
      }
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
        if (params['pageId']) {
          this.loadImage(params['pageId']);
        } else {
          this.loadFirstImage();
        }
      });
      if (this.itemTitle) {
        const contents = Object.keys(this.itemTitle);
        contents.forEach((content, parentIndex) => {
          if (Array.isArray(this.itemTitle[content]) && this.itemTitle[content].length > 0) {
            this.itemTitle[content].forEach((child, childIndex) => {
              const grandChildNodes = [];
              const pages = child.pageNo.split(',');
              if (child.itemTitle == null) {
                child.itemTitle = 'Untitled';
              }
              this.childNodes.push({
                name: child.itemTitle,
                children: [],
                pageId: child.pages[0] ? child.pages[0] : '',
                chilldPageIds: child.pages
              });
            });
            this.childNodes.forEach(pages => {
              this.pageInfo.push({
                title: pages.name,
                pageid: pages.chilldPageIds
              });
              if (this.pageInfo) {
                this.pageInfo.forEach((el) => {
                  this.pageNo = el.pageid;
                  this.img_title = el.title;
                });
                this.listOfImages.forEach((imageIds) => {
                  this.image_id = imageIds.imageId;
                  this.pageNo.forEach(obj => {
                    this.page_id = obj;
                    if (this.page_id === this.image_id) {
                      this.currentItemTitle = this.img_title;
                      imageIds.imgTitle = this.currentItemTitle;
                    }
                  });
                });
              }
            });
          }
        });
      }
    }
      }
    }
  }

  get source() {
    return this._source;
  }
  @Input('image-id')
  set imageId(img: any) {
    if (img) {
      this.loadImage(img.pageId, img.pageData);
    }
  }

  get imageId() {
    return null;
  }

  @Input('pageOverlay')
  set pageOverlay(overlay: any) {
    setTimeout(() => {
      if (overlay) {
        this.loadImage(overlay.pageId, overlay.pageData);
        this.currentPageOverlay = overlay;
      }
    }, 500);
  }

  get pageOverlay() {
    return null;
  }

  @Input('currentDocumentId')
  set documentId(currentDocumentId: any) {
    if (currentDocumentId) {
      this.currentDocumentId = currentDocumentId;
    }
  }

  get documentId() {
    return this.currentDocumentId;
  }

  currentDocumentType: String;
  @Input('documentType')
  set documentType(value: String) {
    if (value) {
      this.currentDocumentType = value;
    }
  }

  get documentType() {
    return this.currentDocumentType;
  }

  _invokedFrom: any;
  @Input('invokedFrom')
  set invokedFrom(value: any) {
    if (value) {
      this._invokedFrom = value;
    }
  }
  get invokedFrom() {
    return this._invokedFrom;
  }

  accessToken: any;
  downloadasPdfText: any = 'Download as PDF';
  currentPagedocID: String;
  isSharedDocument: Boolean = false;
  isArabicLanguage: Boolean = false;
  downloadPageURL: String;
  constructor(
    @Inject('Window') private window: Window,
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private componentContainer: ElementRef,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private _data: SharedService,
    private modalService: ModalService,
    private location: Location,
    private router: Router,
    private pageSettingService: PageSettingsService,
    private sharedService: SharedService,
    private searchService: SearchService,
    private translate: TranslateService,
    private bsModalService: BsModalService,
    private urlUtilityService: UrlUtilityService,
    private utilityService: UtilityService,
    private authService: AuthService,
    @Inject(DOCUMENT) private dom: Document,
    private domSanitizer: DomSanitizer
  ) {
    this.currentSelectedImageIndex = 1;
    PageSettingsService.ViewListObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.viewList = data.viewList;
      this.viewListCount = data.count;
    });
    this.sharedService.documentData.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.hasDocumentAccess = this.authService.checkArchiveAccess(res['shortName']);
        this.documentData = res;
      }
    }, (err) => {
    });
    if (localStorage.getItem('accessType') === 'guest') {
      this.isSharedDocument = true;
    }
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.copytoClipboard = res['copytoClipboard'] || 'Copy to Clipboard';
      this.citationCopiedtoClipboard = res['citationCopiedtoClipboard'] || 'CITATION copied to clipboard';
      this.useFollowingLinktoAccessContent = res['useFollowingLinktoAccessContent'] || 'Please use the following link to access content from the Wiley Digital Archives:';
      this.mailSent = res['mailSent'] || 'Mail Sent';
      this.emailNotDelivered = res['emailNotDelivered'] || 'Email has not been Delivered';
    });
    this.translate.get('Common.pages').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.pagesText = res;
    });
    this.translate.get('Common.download').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.downloadText = res;
    });
    this.translate.get('Common.downloading').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.downloadingText = res;
    });
    this.translate.get('Common.print').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.printText = res;
    });
    this.translate.get('Common.retrievingText').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.retrievingText = res;
    });
    this.translate.get('Detail.downloadAsPDF').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.downloadasPdfText = res;
    });
    this._data.clusterDataReceived(this.currentSelectedImage);
    this.currentPageNum = 0;
    this.downloadImagesModel = 'all-pages';
    this.printImageModel = 'all-pages';
    this.relevantStatusChanged = new EventEmitter();
    this.overlayClicked = new EventEmitter();
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this.isFromResultPage = params['isFromResultsPage'];
      if (this.isFromResultPage) {
        this.isFromResultPage = true;
      } else {
        this.isFromResultPage = false;
      }
      this.searchTermCopy = params['searchTerm'];
      this.currentDocID = params['docID'];
      if (params['searchTerm'] && params['searchTerm'] !== '' && params['searchTerm'] !== null) {
        this.documentSearchTerm = params['searchTerm'];
      }
    });
    this.accessToken = this.httpService.getCurrentAccessToken();
    // this.sharedService.ipadFullScreen.subscribe((changes) => {
    // });
  }

  documentSearchTermSplitted;
  ngAfterContentInit() {
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKeyTitle = 'Shift + Alt';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKeyTitle = 'Shift + Option';
    }
    if (this.currentDocumentId) {
      if (this.currentDocumentId.docId) {
        this.currentPagedocID = this.currentDocumentId.docId;
      } else {
        this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
          this.currentPagedocID = params['docID'];
        });
      }
    }
    this.isNeedToLogout = sessionStorage.getItem('isNeedToLogout');
    this.isSessionGuest = localStorage.getItem('accessType');
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
      this.searchTermNotFound = res['searchTermNotFound'] || 'Search term not found in this document.';
      this.searchResults = res['searchResults'] || 'Search Result';
      this.invalidRange = res['invalidRange'] || 'Invalid page range';
      this.enterPageNumber = res['enterPageNumber'] || 'Please Enter Page Number';
      this.invalidPageRange = res['invalidPageRange'] || 'Invalid page range, use e.g.1,5-7,9,11-12';
      this.popupBlocked = res['popupBlocked'] || 'Popup blocked';
      this.unblockPopup = res['unblockPopup'] || 'Please unblock popups for this site.';
      this.printingError = res['printingError'] || 'Error while Printing. Please try again.';
      this.errorWhileDownload = res['errorWhileDownload'] || 'Error while downloading. Please try again.';
      this.clipImageToastMessage = res['clicktoDrawClipBox'] || 'Click and drag to draw a box to clip';
      this.limitReachExcededPage = res['limitReachExcededPage'] || 'Please enter page number between 1 and';
      this.invalidPagenumber = res['invalidPagenumber'] || 'Invalid Page Number';
    });
    this.httpService.getCurrentAccessToken();
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.pageContentData = res;
    });
    const searchKeywordLength: number = this.pageContentData.advancedFilterParam.searchKeywords.length;
    let counter = 1;
    let advancedSearchKeyword: String = '';
    this.pageContentData.advancedFilterParam.searchKeywords.forEach(response => {
      this.advancedSearchKeywords.push(response.fieldValue);
      if (response.fieldName === 'ALL') {
        if (response.fieldValue !== '' && response.fieldValue !== null) {
          if (counter === 1) {
            advancedSearchKeyword += response.fieldValue;
          } else if (counter <= searchKeywordLength) {
            advancedSearchKeyword += ',' + response.fieldValue;
          }
          counter++;
        }
      }
    });
    if (advancedSearchKeyword !== '  ') {
      this.documentSearchTerm = advancedSearchKeyword;
    }
    if (this.documentSearchTerm.includes('"')) {
      this.documentSearchTerm = this.documentSearchTerm.replace(',', '');
      this.documentSearchTermSplitted = this.documentSearchTerm.split(',');
    } else {
      this.documentSearchTermSplitted = this.documentSearchTerm.split(',');
    }
    let searchWithinValues = $('#document_search').val();
    if (searchWithinValues) {
      if (searchWithinValues.includes('"')) {
      } else {
        this.searchTermwithSpaces = (/\s/.test(searchWithinValues));
        if (this.searchTermwithSpaces) {
          searchWithinValues = searchWithinValues.replace(/ and/g, '');
          searchWithinValues = searchWithinValues.replace(/ or/g, '');
          searchWithinValues = searchWithinValues.replace(/ not/g, '');
          searchWithinValues = searchWithinValues.replace(/ AND/g, '');
          searchWithinValues = searchWithinValues.replace(/ OR/g, '');
          searchWithinValues = searchWithinValues.replace(/ NOT/g, '');
          this.documentSearchTermSplitted = [].concat.apply([], searchWithinValues.split('"').map((term, index) => {
            return index % 2 ? term : term.split(' ');
          })).filter(Boolean);
        }
      }
    }
    if (this.advancedSearchKeywords.length > 0) {
      this.showadvancedSearchKeywords = true;
    }
    this._data.pageId.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.currentPageId = res.pageId;
      this.toggleHighlighter = true;
    });
    this.document.addEventListener('webkitfullscreenchange', function (e) {
    }, false);
    $(document).on('webkitfullscreenchange', (event) => {
      if (OpenSeadragon.isFullScreen()) {
        this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '100vh');
      } else {
        this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '74.5vh');
      }
    });
    setTimeout(() => {
      this.initLayout(() => {
        this.refreshImageList(() => {
          /*
          * this is for initial load table highlight
          * */
          if (this.currentSelectedImage) {
            this.loadImage(this.currentSelectedImage.image.imageId);

          }
        });
      });
      this.toggleHighlighter = false;
    }, 2000);

    this._data.thumbnailData.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.afterThumbnailsLoad();
        setTimeout(() => {
          // console.log('this._data.thumbnailData');
          this.loadImage(res);
        }, 0);
      }
    });
    // this._data.documentSearchData.subscribe(res => {
    //   if (res === '' || res === undefined) {
    //     $('#searchtermData').hide();
    //     $('#advSearchTerm').hide();
    //     $('#simpleSearchterms').hide();
    //     $('#searchTermWithSpace').hide();
    //     $('#RelevantTermData').hide();
    //     $('#initialsearchTerm').hide();
    //   } else {
    //     $('#searchtermData').show();
    //     $('#advSearchTerm').show();
    //     $('#simpleSearchterms').show();
    //     $('#searchTermWithSpace').show();
    //     $('#RelevantTermData').show();
    //     $('#initialsearchTerm').show();
    //   }
    //   if ($('#searchWithinCheckbox').prop('checked') === true) {
    //     this.fuzzySearch = false;
    //   } else {
    //     this.fuzzySearch = true;
    //   }
    //   if (res) {
    //     if ($('#searchWithinCheckbox').prop('checked') === true) {
    //       this.fuzzySearch = false;
    //     } else {
    //       this.fuzzySearch = true;
    //     }
    //     let searchWithValues = $('#document_search').val();
    //     if (searchWithValues.includes('"')) {
    //       this.documentSearchTermSplitted = '';
    //       setTimeout(() => {
    //         this.searchTermData = this.documentSearchTerm = searchWithValues;
    //         // this.showRelevantImages();
    //         this.loadFirstImage();
    //       }, 500);
    //     } else {
    //       this.searchTermwithSpaces = (/\s/.test(searchWithValues));
    //       if (this.searchTermwithSpaces) {
    //         searchWithValues = searchWithValues.replace(/ and/g, '');
    //         searchWithValues = searchWithValues.replace(/ or/g, '');
    //         searchWithValues = searchWithValues.replace(/ not/g, '');
    //         searchWithValues = searchWithValues.replace(/ AND/g, '');
    //         searchWithValues = searchWithValues.replace(/ OR/g, '');
    //         searchWithValues = searchWithValues.replace(/ NOT/g, '');
    //         this.documentSearchTermSplitted = [].concat.apply([], searchWithValues.split('"').map((term, index) => {
    //           return index % 2 ? term : term.split(' ');
    //         })).filter(Boolean);
    //         this.searchTermData = this.documentSearchTerm = this.documentSearchTermSplitted;
    //         this.noOfRelevantImages = this.releventPageResponse;
    //       } else {
    //         this.searchTermData = this.documentSearchTerm = res;
    //         this.noOfRelevantImages = this.releventPageResponse;
    //       }
    //     }
    //   }
    // });

    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this.initialSearchTerm = params['searchTerm'];
      this.currentDocID = params['docID'];
      if (params['searchTerm'] && params['searchTerm'] !== '' && params['searchTerm'] !== null) {
        this.documentSearchTerm = params['searchTerm'];
      }
    });
    this.sharedService.ipadFullScreen.pipe(takeUntil(this.stop$)).subscribe((changes) => {
      if (changes === 'true') {
        this.fullWidthInIpad = true;
      } else {
        this.fullWidthInIpad = false;
      }
    });

    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
        this.translatedValues = res;
        this.clipImageToastMessage = res['clicktoDrawClipBox'];
      });
      this.translate.get('Detail.downloadAsPDF').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
        this.downloadasPdfText = res;
      });
    });

    this.unsubFlimstripeImage = this._data.filmstripImage.pipe(takeUntil(this.stop$)).subscribe((res) => {
      if (res) {
        if (res['imageId']) {
          // console.log('this._data.filmstripImage if');
          this.loadImage(res['imageId']);
        } else {
          // console.log('this._data.filmstripImage else');
          this.loadImage(res);
        }
      }
    });

    this._data.ImageViewerHighlight.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res['pageData']) {
        setTimeout(() => {
          this.checkHightLightChange = true;
        }, 2500);
      } else {
        this.checkHightLightChange = false;
      }
    });
  }

  scrollTo(position) {

  }

  getJSONKeys(obj) {
    if (typeof obj === 'object') {
      return Object.keys(obj);
    } else {
      return [];
    }
  }

  initLayout(callBack?) {
    const ratio = 800 / 775;
    this.containerHeight = this.options.height ? this.options.height : 775;
    this.containerWidth = this.containerHeight / ratio;
    if (this.isInListViewMode) {
      window.setTimeout(() => {
        if (this.containerHeight) {
          this.renderer.setStyle(this.listViewContainer.nativeElement, 'height', (this.containerHeight + 40) + 'px');
        }
        if (typeof callBack === 'function') {
          this.isEditorInitialized = true;
          callBack();
        }
      }, 100);
    } else {
      window.setTimeout(() => {
        if (this.containerHeight) {
          this.renderer.setStyle(this.mainContainer.nativeElement, 'height', this.containerHeight + 'px');
          this.renderer.setStyle(this.thumbsContainer.nativeElement, 'height', '74.5vh');
          this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '74.5vh');
        }
        if (typeof callBack === 'function') {
          this.isEditorInitialized = true;
          callBack();
        }
      }, 100);
    }
  }

  loadImage(imageId: any, imageData?) {
    if (this.defaultPage) {
      if (this.defaultPage.pageId) {
        imageId = this.defaultPage.pageId;
      }
    }
    if (this._source[imageId]) {
      this.currentSelectedImage = {
        image: this._source[imageId],
        imageData: imageData
      };
      if (this.isInListViewMode) {
        this.toggleListView();
      }
      const image = new Image();
      let index;
      this.listOfImages.some((entry, i) => {
        if (entry.imageId === imageId) {
          index = i;
          return true;
        }
      });

      this.currentSelectedImageIndex = index;
      this.currentPageNum = this.currentSelectedImageIndex + 1;
      image.onload = () => {
        this.currentImage = image;
      };
      if (!this.showDragonViewer) {
        image.src = this.currentSelectedImage.image.imageURL;
      }
      this.currentImageURL = this.currentSelectedImage.image.imageURL;
      this.checkHightLightChange = false;
      this._data.tableHighlight.pipe(takeUntil(this.stop$)).subscribe(res => {
        setTimeout(() => {
          if (res['className'] && res['className'] !== 'image-keyword-highlight') {
            this.checkHightLightChange = true;
          } else {
            this.checkHightLightChange = false;
          }
        }, 500);
      });
    }
    if (this.currentSelectedImage) {
      this.pageFullText.emit({
        'pageId': this.currentSelectedImage.image.imageId
      });
      this._data.pageIdChanged({
        'pageId': this.currentSelectedImage.image.imageId,

        'page': this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
          const currentParams = JSON.parse(JSON.stringify(params));
          currentParams['pageId'] = this.currentSelectedImage.image.imageId;
          const checkparamValue = currentParams['pageId'];
          const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
        })
      });
    }
    if (!this.isdefaultPageIdChanged) {
      if (this.defaultPage) {
        if (this.defaultPage.pageId) {
          this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
            const currentParams = JSON.parse(JSON.stringify(params));
            currentParams['pageId'] = this.defaultPage.pageId;
            currentParams['docID'] = this.defaultPage.docId;
            const checkparamValue = currentParams['pageId'];
            const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
            this.location.
              replaceState(this.router.createUrlTree(['/' + currentArchive + '/detail/' +
                this.defaultPage.docId, currentParams], { relativeTo: this.activatedRoute }).toString());
          });
        }
      }
    } else {
      if (this.currentDocumentId) {
        if (this.currentDocumentId.docId) {
          this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
            const currentParams = JSON.parse(JSON.stringify(params));
            currentParams['pageId'] = this.currentSelectedImage.image.imageId;
            currentParams['docID'] = this.currentDocumentId.docId;
            const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
            this.location.
              replaceState(this.router.createUrlTree(['/' + currentArchive + '/detail/' +
                this.currentDocumentId.docId, currentParams], { relativeTo: this.activatedRoute }).toString());
          });
        }
      } else {
        this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
          const currentParams = JSON.parse(JSON.stringify(params));
          currentParams['pageId'] = this.currentSelectedImage.image.imageId;
          currentParams['docID'] = this.currentDocID;
          const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
          this.location.
            replaceState(this.router.createUrlTree(['/' + currentArchive + '/detail/' +
              this.currentDocID, currentParams], { relativeTo: this.activatedRoute }).toString());
        });
      }
    }
    this.fitImageToWidth(this.showFittoHeight);
  }

  selectCurrentImageAndApplyStyles(selectedImage) {
    $('.image-thumb').removeClass('active');
    $('.thumb-' + selectedImage).addClass('active');
  }

  adjustBrightness(value) {
    this.brightnessAdjusted = true;
    if (value <= -255) {
      value = -255;
    }
    if (value > 255) {
      value = 255;
    }
    this.currentBrightness = parseFloat(value);
    $('.i-edit-image').parent().addClass('effect-active');
  }

  adjustContrast(value) {
    this.contrastAdjusted = true;
    if (value < 0) {
      value = 0;
    }
    if (value > 5) {
      value = 5;
    }
    this.currentContrast = parseFloat(value);
    $('.i-edit-image').parent().addClass('effect-active');
  }

  negativeChanged() {
    if (this.isInverted) {
      $('.i-edit-image').parent().addClass('effect-active');
    } else if (!this.contrastAdjusted && !this.brightnessAdjusted && !this.isInverted) {
      $('.i-edit-image').parent().removeClass('effect-active');
    }
  }

  rotateImage(value) {
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    this.currentAngle = (value + this.currentAngle) % 4;
  }

  invertImage() {
    if (this.isInverted) {
      this.isCheckedInvertCbx = 'checked';
    }
    this.isInverted = !this.isInverted;
    $('.i-edit-image').parent().addClass('effect-active');
  }

  zoomImage(factor) {
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    if (factor > 0) {
      if (this.currentZoomLevel < 0) {
        this.currentZoomLevel = 0;
      }
      this._callZoomIn = { random: Math.random() };
    } else {
      if (this.currentZoomLevel > 0) {
        this.currentZoomLevel = 0;
      }
      this._callZoomOut = { random: Math.random() };
    }
    this.currentZoomLevel += factor;
  }

  fitImageToWidth(flag) {
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    this.fitToWidth = flag;
    if (flag === true) {
      this.showFittoHeight = true;
    } else {
      this.showFittoHeight = false;
    }
  }

  browserFullScreen() {
    this.openImageviewerMenu();
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    if (this.isInFullScreenMode) {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      }
      this.isInFullScreenMode = false;
      if (window.innerWidth <= 1024) {
        this.fullScreenForIpad = false;
        this.sharedService.setFullScreen('false');
      }
    } else {
      const elem = this.mainContainer.nativeElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
      this.isInFullScreenMode = true;
      if (window.innerWidth <= 1024) {
        this.fullScreenForIpad = true;
        this.sharedService.setFullScreen('true');
      }
    }
  }

  refreshImageList(callBack?) {
    // if (this._isShowingRelevantImages) {
    //   $('.image-editor-film-strip li').show();
    //   $('.list-view-item').show();
    //   $('.irrelevant-img').hide();
    // } else {
    //   $('.irrelevant-img').show();
    // }
    if (typeof callBack === 'function') {
      callBack();
    }
  }

  reRenderFrame() {
    this.isEditorInitialized = false;
    setTimeout(() => {
      this.isPageResized = !this.isPageResized;
      setTimeout(() => {
        this.isPageResized = !this.isPageResized;
        setTimeout(() => {
          this.isPageResized = !this.isPageResized;
          this.isEditorInitialized = true;
        }, 100);
      }, 100);
    });
  }

  resetImage() {
    this.fitImageToWidth(false);
    this.openImageviewerMenu();
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    this.restoreOriginalImage = !this.restoreOriginalImage;
    $('.i-edit-image').parent().removeClass('effect-active');
    this.currentContrast = 0;
    this.currentBrightness = 0;
    this.isInverted = false;
    this.contrastAdjusted = false;
    this.brightnessAdjusted = false;
    this.enableOverlay = false;
    this.toggleTitleOverlay();
    this.currentAngle = 0;
    $('#invertChkbox').prop('checked', '');
    this.selection = false;
  }

  resetToolbar() {
    $('.i-edit-image').parent().removeClass('effect-active');
    this.currentContrast = 0;
    this.currentBrightness = 0;
    this.isInverted = false;
    this.contrastAdjusted = false;
    this.brightnessAdjusted = false;
    $('#invertChkbox').prop('checked', '');
  }

  toggleListView() {
    this.afterThumbnailsLoad();
    this.isInListViewMode = !this.isInListViewMode;
    this.isPageResized = !this.isPageResized;
    if (this.isInFullScreenMode) {
      if (this.isInListViewMode) {
        setTimeout(() => {
          this.renderer.setStyle(this.listViewContainer.nativeElement, 'height', this.window.innerHeight + 'px');
        }, 100);
      } else {
        setTimeout(() => {
          this.renderer.setStyle(this.mainContainer.nativeElement, 'height', this.window.innerHeight + 'px');
          this.renderer.setStyle(this.mainContainer.nativeElement, 'width', this.window.innerWidth + 'px');
          this.renderer.setStyle(this.thumbsContainer.nativeElement, 'height', '74.5vh');
          this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '74.5vh');
        }, 100);
      }
    } else {
      this.initLayout(() => {
        this.refreshImageList();
      });
    }
  }

  // showRelevantStatusChanged(event) {
  //   this.relevantStatusChanged.emit(event);
  //   this._isShowingRelevantImages = event;
  //   this.showRelevantImages();
  // }

  // showRelevantImages() {
  //   // if (this._isShowingRelevantImages) {
  //   //   $('.image-editor-film-strip li').show();
  //   //   $('.list-view-item').show();
  //   //   $('.irrelevant-img').hide();
  //   // } else {
  //   //   $('.irrelevant-img').show();
  //   // }
  //   this.loadFirstImage();
  // }

  loadFirstImage() {
    // console.log('load first image fun call');

    if (this._source) {
      let img = this._source[Object.keys(this._source)[0]];
      if (this._isShowingRelevantImages) {
        for (let imgIndex = 0; imgIndex < Object.keys(this._source).length; imgIndex++) {
          img = this._source[Object.keys(this._source)[imgIndex]];
          if (img.isRelevant) {
            this.loadImage(img.imageId);
            // console.log('loadFirstImage for isRelevant page', );
            break;
          }
        }
      } else {
        if (img) {
          // console.log('loadFirstImage for is not a Relevant page', );
          this.loadImage(img.imageId);
        }
      }
    }
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    this.restoreOriginalImage = !this.restoreOriginalImage;
    $('.i-edit-image').parent().removeClass('effect-active');
    this.currentContrast = 0;
    this.currentBrightness = 0;
    this.isInverted = false;
    this.contrastAdjusted = false;
    this.brightnessAdjusted = false;
    this.enableOverlay = false;
    this.toggleTitleOverlay();
    this.currentAngle = 0;
    $('#invertChkbox').prop('checked', '');
    this.selection = false;
  }

  onOverlayClick(imageId) {
    this.isInFullScreenMode = false;
    this.overlayClicked.emit(imageId);
  }

  afterPageLoaded(image) {
    this.addFilmStrip = true;
    setTimeout(() => {
      this.whenMainImageLoaded.emit(true);
      this.afterThumbnailsLoad();
    }, 0);
  }

  afterThumbnailsLoad() {
    if (this.thumbImg) {
      const currentThumb = this.thumbImg.toArray()[this.currentSelectedImageIndex];
      if (currentThumb) {
        this.thumbScrollPosition = $(currentThumb['nativeElement']);
      }
    }
    this.selectCurrentImageAndApplyStyles(this.currentSelectedImageIndex);
    this.currentFocusedImageIndex = this.currentSelectedImageIndex;
    setTimeout(() => {
      if (this.defaultPage) {
        if (this.currentSelectedImage) {
          this.defaultPage = this.currentSelectedImage.image.imageId;
        }
        this.isdefaultPageIdChanged = true;
      }
    }, 1500);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isPageResized = !this.isPageResized;
    if (OpenSeadragon.isFullScreen()) {
      this.isInFullScreenMode = true;
      this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '100vh');
    } else {
      this.isInFullScreenMode = false;
      this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '74.5vh');
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.UP_ARROW && $('#Document-link').hasClass('active')) {
      this.loadNextImage();
    }
    if (event.keyCode === KEY_CODE.DOWN_ARROW && $('#Document-link').hasClass('active')) {
      this.loadPreviousImage();
    }
    if (event.keyCode === KEY_CODE.ESCAPE_KEYCODE) {
      $('#fullScreenModal').hide();
      setTimeout(() => {
        if (OpenSeadragon.isFullScreen()) {
          this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '100vh');
        } else {
          this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '74.5vh');
        }
      }, 2000);
    }
  }

  downloadImageToPDF(isFromPrint?) {
    if (this.currentDocumentId) {
      if (this.currentDocumentId.docId) {
        this.currentDocID = this.currentDocumentId.docId;
      } else {
        this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
          this.currentDocID = params['docID'];
        });
      }
    }
    // this.isDownloadingInProgress = true;
    // this.downloadPDFmodalRef = this.bsModalService.show(this.downloadPDFTemplate, { backdrop: true, ignoreBackdropClick: true });
    let url: any;
    if (this.downloadImagesModel === 'all-pages') {
      this.validPage = true;
      url = environment.APIUrl + 'pagemapper/pdf/' + this.currentDocID;
    } else if (this.downloadImagesModel === 'current-page') {
      this.validPage = true;
      url = environment.APIUrl + 'pagemapper/pdf/' + this.currentDocID + '/' + (this.currentSelectedImageIndex + 1);
    } else if (this.downloadImagesModel === 'selected-page') {
      if (this.pagesToDownload) {
        const pagesForSplit = this.pagesToDownload.split(/[ , -]+/);
        pagesForSplit.forEach(obj => {
          const pageNumber = parseInt(obj, 10);
          if (!(pageNumber > this.listOfImages.length) && !this.notvalidPageNumber) {
            if (this.isValidationPage) {
              this.validPage = true;
              url = environment.APIUrl + 'pagemapper/pdf/' + this.currentDocID + '/' + this.validPageNumber;
            }
          } else {
            this.validPage = false;
            this.isDownloadingInProgress = false;
            this.downloadPDFmodalRef.hide();
          }
        });


      }
      if (this.pagesToDownload === '') {
        this.validPage = false;
        this.errorMsg = this.translatedValues.enterPageNumber;
        this.isDownloadingInProgress = false;
        this.downloadPDFmodalRef.hide();
      }
    }
    if (this.validPage === true) {
      this.downloadPageURL = url + '?access_token=' + this.accessToken;
    }
    if (!isFromPrint) {
      this.downloadPDFmodalRef = this.bsModalService.show(this.downloadPageasPdfTemplate, { backdrop: true, ignoreBackdropClick: true });
    }
    this.clearSelectPage();
  }


  closeDownloadPdfModal () {
    this.downloadPDFmodalRef.hide();
  }


  printImageFromServer() {
    if (this.currentDocumentId) {
      if (this.currentDocumentId.docId) {
        this.currentDocID = this.currentDocumentId.docId;
      } else {
        this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
          this.currentDocID = params['docID'];
        });
      }
    }
    this.isPrintInProgress = true;
    this.downloadPrintmodalRef = this.bsModalService.show(this.downloadPrintTemplate, { backdrop: true, ignoreBackdropClick: true });
    let requestParams: any;
    const pagesToPrint: any = [];
    if (this.printImageModel === 'all-pages') {
      this.validPrintPage = true;
      requestParams = this.currentDocID;
    } else if (this.printImageModel === 'current-page') {
      this.validPrintPage = true;
      requestParams = this.currentDocID + '/' + (this.currentSelectedImageIndex + 1);
    } else if (this.printImageModel === 'selected-page') {
      if (this.pagesToPrint === '') {
        this.validPrintPage = false;
        this.errorMsg = this.translatedValues.enterPageNumber;
        this.isPrintInProgress = false;
        this.downloadPrintmodalRef.hide();
      }
      if (this.pagesToPrint) {
        const pagesForSplit = this.pagesToPrint.split(/[ , -]+/);
        pagesForSplit.forEach(obj => {
          const pageNumber = parseInt(obj, 10);
          if (!(pageNumber > this.listOfImages.length) && !this.notvalidPageNumber) {
            if (this.isValidationPage) {
              this.validPrintPage = true;
            }
          } else {
            this.validPrintPage = false;
            this.isPrintInProgress = false;
            this.downloadPrintmodalRef.hide();
          }
        });
      }
      requestParams = this.currentDocID + '/' + this.validPageNumber;
    } else {
    }
    if (this.validPrintPage === true) {
      this.httpService.get(environment.APIUrl + 'pagemapper/print/' + requestParams, (resp) => {
        // console.log(resp);
        const file = new Blob([resp], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        this.isPrintInProgress = false;
        this.downloadPrintmodalRef.hide();
        // this.printWarningModalRef.hide();
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file);
          } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {  // for IPAD
          window.open('data:application/json;charset=utf-8,' + encodeURIComponent(fileURL), '_blank');
        } else {
          const printwWindow = window.open(fileURL, '_blank');
          if (!printwWindow || printwWindow.closed || typeof printwWindow.closed === 'undefined') {
            this.modalService.showNotification('ERROR', this.popupBlocked, this.unblockPopup);
          }
          printwWindow.print();
        }
        this.clearSelectPage();
      }, (err) => {
        this.modalService.showNotification('ERROR', 'Error', this.printingError);
        this.isPrintInProgress = false;
        this.downloadPrintmodalRef.hide();
        // this.modalService.hideLoader();
      });
    }


  }

  downloadImagesPDF(value) {
    this.downloadImagesModel = value;
  }

  setPrintImageModel(value) {
    this.printImageModel = value;
  }
  printWarning() {
    // this.printWarningModalRef = this.bsModalService.show(this.printWarningTemplate, { backdrop: true, ignoreBackdropClick: true });
    this.printImageFromServer();
    // this.printImage(true);
  }
  printImage(continuePrint?) {
    // if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    //   this.printImageFromServer();
    //   return;
    // }
    const ImageUrls: any = [];
    const pagesToPrint: any = [];
    if (this.printImageModel === 'all-pages') {
      if (this.listOfImages.length >= maxPrintPages) {
        this.printWarning();
        return;
      } else {
        this.listOfImages.forEach((imageId) => {
          if (this._source[imageId.imageId]) {
            ImageUrls.push(this._source[imageId.imageId].imageURL);
          }
        });
      }
    } else if (this.printImageModel === 'current-page') {
      ImageUrls.push(this.currentSelectedImage.image.imageURL);
    } else if (this.printImageModel === 'selected-page') {
      if (this.pagesToPrint) {
        // if (this.pagesToPrint.split('-')) {
        //   const range = this.pagesToPrint.split('-');
        //   for (let j = 0; j < range[1]; j++) {
        //     pagesToPrint.push(j);
        //   }
        // }
        // console.log(pagesToPrint, this.pagesToPrint);

        if (this.pagesToPrint.split(',')) {
          const range = this.pagesToPrint.split(',');
          const rangeLength = range.length;
          let j = 0;
          range.forEach(obj => {
            const hyphenRange = range[j].split('-');
            if (range[j].indexOf('-') > -1) {
              const rangeArray = range[j].split('-');
              // tslint:disable-next-line:radix
              for (let iRangeArray = parseInt(rangeArray[0]); iRangeArray <= parseInt(rangeArray[1]); iRangeArray++) {
                pagesToPrint.push(iRangeArray);
              }
            } else {
              // tslint:disable-next-line:radix
              pagesToPrint.push(parseInt(obj));

            }
            j++;

          });
        }
        if (pagesToPrint.length > maxPrintPages) {
          // console.log(pagesToPrint, maxPrintPages);
          // console.log(this.pagesToPrint);
          // this.pagesToDownload = this.pagesToPrint;
          // this.downloadImagesModel = 'selected-page';
          this.printWarning();
          return;
        } else {

          pagesToPrint.forEach((imageIndex) => {
            // tslint:disable-next-line:radix
            const sourceIndex = this.listOfImages[parseInt(imageIndex) - 1].imageId;
            ImageUrls.push(this._source[sourceIndex].imageURL);
          });
        }
      }

    }
    let imageTag: String = '';
    const urlImagesLength = ImageUrls.length;
    // let i = 1;
    ImageUrls.forEach((image) => {
      // if (urlImagesLength === i) {
      //   // tslint:disable-next-line:max-line-length
      //   imageTag += '<img  class="printImg" alt="print Image" src=' + image
      //   + '?access_token=' + JSON.parse(localStorage.getItem('auth_token')) + '>';
      // } else {
      //   // tslint:disable-next-line:max-line-length
      imageTag += '<img  class="printImg" alt="print Image" src=' + image
        + '?access_token=' + JSON.parse(localStorage.getItem('auth_token')) + '>';
      // }
      // i++;
    });
    this.printImages(imageTag, continuePrint);
  }
  printImages(images, continuePrint?) {
    this.modalService.showNotification('INFO', 'Please Wait' , 'Print will take some time. Please wait.', 2000 );
    let printContent = '<html><body onload="printDoc();">';
    printContent += images;
    printContent += '</body><script>' +
      'function printDoc(){' +
      'window.print();' +
      '}' +
      '</script>' +
      '<style> @media print { .printImg { page-break-after:always; }}' +
      'html,body{ width:100%;height:100%;margin:0;text-align:center;}' +
      // tslint:disable-next-line:max-line-length
      '.printImg{ max-width: 100%; max-height: 100%;vertical-align: middle;}' +
      '</style>' +
      '</html>';
    const newWin = window.frames['printf'];
    newWin.document.write(printContent);
    newWin.document.close();
    // if (continuePrint) {
    //   this.printWarningModalRef.hide();
    // }
    this.clearSelectPage();
  }

  // buildImagesToPrint(images) {
  //   // const ele: any  = document.getElementById('iframe');
  //   //   const my_content = ele.contentWindow.document;
  //   //   my_content.body.innerHTML = '';
  //   let printcmd: any = '';
  //   const newIframe: any = document.createElement('iframe');
  //         newIframe.width = '300';
  //         newIframe.height = '300';
  //         document.body.appendChild(newIframe);
  //   if (navigator.userAgent.indexOf('Chrome') !== -1) {
  //     printcmd = 'self.focus();self.print();';
  //   } else if (window.navigator.msSaveOrOpenBlob) {
  //     // tslint:disable-next-line:quotemark
  //     printcmd = "window.document.getElementById('iframe').contentWindow.document.execCommand('print', false, null)";
  //   }
  //   let printContent = '<html><body >';
  //   printContent += images;
  //   printContent += '</body><script>' +
  //     'function printDoc(){' + printcmd +
  //   // 'window.close();' +
  //   '}' +
  //   '</script>' +
  //   '<style>' +
  //   'html,body{ width:100%;height:100%;margin:0;text-align:center;}' +
  //   '.printImg{width: 100%;}' +
  //   '</style>' +
  //   '</html>';
  //   newIframe.contentWindow.document.write(printContent);
  //   // if (navigator.userAgent.indexOf('Firefox') || navigator.userAgent.indexOf('Chrome') !== -1) {
  //   //   ele.contentWindow.print();
  //   // } else if (window.navigator.msSaveOrOpenBlob) {
  //   //   console.log('exec command');
  //   //   ele.contentWindow.document.execCommand('print', false, null);
  //   // }
  //   setTimeout(() => {
  //     window.frames['printer_frame'].focus();
  //     window.frames['printer_frame'].print();
  //   }, 4000);

  //   // newWin.document.open();
  //   // newWin.document.write(printContent);
  //   // newIframe.id = 'printer_frame';
  //   // newIframe.name = 'printer_frame';
  //   // newIframe.width = '300';
  //   // newIframe.height = '300';
  //   // // newIframe.style.visibility = 'hidden';
  //   // // newIframe.open();
  //   // newIframe.contentWindow.document.write(printContent);
  //   // // newIframe.close();
  //   // document.body.appendChild(newIframe);
  //   // setTimeout(() => {
  //   //   newIframe.contentWindow.focus();
  //   //   // newIframe.contentWindow.print();
  //   //   // document.getElementById('printer_frame').remove();
  //   // }, 200);
  // }


  zoomReset(event) {
    setTimeout(() => {
    }, 200);
  }

  scrollToNextImage() {
    if ($('.film-strip-button').hasClass('hide-nextarrow')) {
      return false;
    }
    if (this.thumbImg) {
      const listOfThumbs = this.thumbImg.toArray();
      const nextThumb = listOfThumbs[this.currentFocusedImageIndex + 1];
      if (nextThumb) {
        if (this.currentFocusedImageIndex < (this.listOfImages.length - 1)) {
          this.thumbScrollPosition = $(nextThumb['nativeElement']);
          this.currentFocusedImageIndex += 1;
        }
      }
    }
  }

  scrollToPreviousImage() {
    if ($('.film-strip-button').hasClass('hide-previousarrow')) {
      return false;
    }
    if (this.thumbImg) {
      const listOfThumbs = this.thumbImg.toArray();
      const previousThumb = listOfThumbs[this.currentFocusedImageIndex - 1];
      if (previousThumb) {
        if (this.currentFocusedImageIndex > 0) {
          this.thumbScrollPosition = $(previousThumb['nativeElement']);
          this.currentFocusedImageIndex -= 1;
        }
      }
    }
  }
  toggleEditorMenu(event) {
    this.showEditorMenu = !this.showEditorMenu;
    this.showFilmStripMenu = false;
    event.stopPropagation();
  }
  toggleFilmStripMenu(event) {
    this.showFilmStripMenu = !this.showFilmStripMenu;
    this.showEditorMenu = false;
    event.stopPropagation();
  }
  toggleHighlight() {
    this.toggleHighlighter = !this.toggleHighlighter;
    if (this.toggleHighlighter) {
      $('.image-viewer-table').removeClass('hide-mouse-action');
    } else {
      $('.image-viewer-table').addClass('hide-mouse-action');
    }
  }
  /*
  * Emit 'enableOverlay' for show/hide image title.
  */
  toggleTitleOverlay() {
    this.sharedService.shareImageViewerTitleOverlay(this.enableOverlay);
  }
  clearSelectPage(arg?: string) {
    this.pagesToDownload = '';
    this.pagesToPrint = '';
    if (!arg) {
      this.downloadImagesModel = 'all-pages';
      this.printImageModel = 'all-pages';
    }
    this.errorMsg = '';
    this.isInvalidPageNumber = false;
  }
  clearSelectPages() {
    this.isInvalidPageNumber = true;
  }
  selectPageChanges() {
    let pageNumber;
    const pageNumArray = [];
    if (this.pagesToDownload || this.pagesToPrint) {
      const pageToValidate = (this.pagesToDownload || this.pagesToPrint);
      const pagesForSplits = pageToValidate.split(/[,]+/);
      pagesForSplits.forEach(ele => {
        const pageElement = ele;
        if (pageElement.includes('-')) {
          const pageToCompare = pageElement.split(/[-]+/);
          if (parseInt(pageToCompare[1], 10)) {
            if (parseInt(pageToCompare[0], 10) < parseInt(pageToCompare[1], 10)) {
              pageNumArray.push('false');
            } else if (parseInt(pageToCompare[0], 10) > parseInt(pageToCompare[1], 10)) {
              pageNumArray.push('true');
            } else {
              pageNumArray.push('false');
            }
          }
        }
      });
      if (pageNumArray) {
        if (pageNumArray.indexOf('true') < 0) {
          this.notvalidPageNumber = false;
        } else {
          this.notvalidPageNumber = true;
        }
      }

      if (pageNumArray.length === 0) {
        this.notvalidPageNumber = false;
      }
      const pagesForSplit = pageToValidate.split(/[ , -]+/);
      pagesForSplit.forEach(el => {
        pageNumber = parseInt(el, 10);
        if (pageNumber > this.listOfImages.length || pageNumber <= 0) {
          this.notvalidPageNumber = true;
        }
      });
      if (this.notvalidPageNumber) {
        this.isInvalidPageNumber = true;
        this.errorMsg = this.invalidRange;
      } else {
        this.isInvalidPageNumber = false;
        this.validPageNumberforpdf = true;
        this.isValidationPage = /^(\s*\d+\s*(-\s*\d+\s*)?)(,\s*\d+\s*(-\s*\d+\s*)?)*$/.test(pageToValidate);
        if (this.isValidationPage) {
          this.validPageNumber = pageToValidate.replace(/\s/g, '');
          this.errorMsg = '';
        } else {
          this.isInvalidPageNumber = true;
          this.errorMsg = this.invalidPageRange;
        }
      }
    } else {
      this.errorMsg = this.enterPageNumber;
      this.isInvalidPageNumber = true;
    }
  }

  highlighthide(event) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.isImageviewerMenuOpen = false;
    if ($('.imageviewer-menubar').hasClass('imageviewer-menubar-active')) {
      $('.imageviewer-menubar').removeClass('imageviewer-menubar-active');
    }
  }

  brightnessUpdate(value) {
    this.changeBrightness = true;
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.shiftKey && e.altKey) {
      switch (e.keyCode) {
        case 78:
          if ($('#Brightness').hasClass('effect-active')) {
            $('#invertChkbox').trigger('click');
            $('#Brightness').addClass('effect-active');
          } else {
            $('#Brightness').trigger('click');
            setTimeout(() => {
              $('#invertChkbox').trigger('click');
            }, 0);
          }
          break;
        case 190:
          this.increasedBrightness = 10;
          if (this.brightnessValue === 0) {
            this.brightnessValue = this.increasedBrightness;
          } else {
            this.brightnessValue = this.brightnessValue + this.increasedBrightness;
          }
          if (this.brightnessValue <= -255) {
            this.brightnessValue = -255;
          }
          if (this.brightnessValue >= 255) {
            this.brightnessValue = 255;
          }
          this.adjustBrightness(this.brightnessValue);
          break;
        case 188:
          this.increasedBrightness = 10;
          if (this.brightnessValue > 0) {
            this.brightnessValue = this.brightnessValue - this.increasedBrightness;
          } else {
            this.brightnessValue = this.brightnessValue - this.increasedBrightness;
          }
          if (this.brightnessValue <= -255) {
            this.brightnessValue = -255;
          }
          if (this.brightnessValue >= 255) {
            this.brightnessValue = 255;
          }
          this.adjustBrightness(this.brightnessValue);
          break;
        case 79:
          if (this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.openImageviewerMenu();
            this.printImageMenu();
          } else if (!this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.showFloatingMenu = true;
            this.openImageviewerMenu();
            this.printImageMenu();
          } else if (!this.hoverFloatingMenu && this.showFloatingMenu) {
            this.showFloatingMenu = false;
            this.openImageviewerMenu();
          }
          break;
        case 38:
          this.browserFullScreen();
          break;
        case 45:
          this.selectImage();
          break;
          case 37:
          this.rotateImage(-1);
          break;
          case 39:
          this.rotateImage(1);
          break;
      }
    } else if (e.ctrlKey && e.altKey) {
      switch (e.keyCode) {
        case 68:
          if (this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.openImageviewerMenu();
            this.downloadAsPDF();
          } else if (!this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.showFloatingMenu = true;
            this.openImageviewerMenu();
            this.downloadAsPDF();
          } else if (!this.hoverFloatingMenu && this.showFloatingMenu) {
            this.showFloatingMenu = false;
            this.openImageviewerMenu();
          }
          break;
      }
    }

    if (e.shiftKey && e.keyCode === 32) {
      this.showOverlayFloat = !this.showOverlayFloat;
      if (this.showOverlayFloat) {
        this.hoverFloatingMenu = true;
        this.showOverlay = true;
      } else {
        this.hoverFloatingMenu = false;
        this.showOverlay = false;
      }
    }

    /* if (e.shiftKey && e.altKey && e.keyCode === 78) {
       if ($('#Brightness').hasClass('effect-active')) {
         $('#invertChkbox').trigger('click');
         $('#Brightness').addClass('effect-active');
       } else {
         $('#Brightness').trigger('click');
         setTimeout(() => {
           $('#invertChkbox').trigger('click');
         }, 0);
       }
     } else if (e.shiftKey && e.altKey && e.keyCode === 190) {
       const increasedBrightness = 10;
       if (this.brightnessValue === 0) {
         this.brightnessValue = increasedBrightness;
       } else {
         this.brightnessValue = this.brightnessValue + increasedBrightness;
       }
       if (this.brightnessValue <= -255) {
         this.brightnessValue = -255;
       }
       if (this.brightnessValue >= 255) {
         this.brightnessValue = 255;
       }
       this.adjustBrightness(this.brightnessValue);
     } else if (e.shiftKey && e.altKey && e.keyCode === 188) {
       const increasedBrightness = 10;
       if (this.brightnessValue > 0) {
         this.brightnessValue = this.brightnessValue - increasedBrightness;
       } else {
         this.brightnessValue = this.brightnessValue - increasedBrightness;
       }
       if (this.brightnessValue <= -255) {
         this.brightnessValue = -255;
       }
       if (this.brightnessValue >= 255) {
         this.brightnessValue = 255;
       }
       this.adjustBrightness(this.brightnessValue);
     } else if (e.shiftKey && e.altKey && e.keyCode === 39) {
       this.rotateImage(1);
     } else if (e.shiftKey && e.altKey && e.keyCode === 37) {
       this.rotateImage(-1);
     }
     if (e.shiftKey && e.altKey && e.keyCode === 79) {
       if (this.hoverFloatingMenu && !this.showFloatingMenu) {
         this.openImageviewerMenu();
         this.printImageMenu();
       } else if (!this.hoverFloatingMenu && !this.showFloatingMenu) {
         this.showFloatingMenu = true;
         this.openImageviewerMenu();
         this.printImageMenu();
       } else if (!this.hoverFloatingMenu && this.showFloatingMenu) {
         this.showFloatingMenu = false;
         this.openImageviewerMenu();

       }

     }

     if (e.ctrlKey && e.altKey && e.keyCode === 68) {

       if (this.hoverFloatingMenu && !this.showFloatingMenu) {
         this.openImageviewerMenu();
         this.downloadAsPDF();
       } else if (!this.hoverFloatingMenu && !this.showFloatingMenu) {
         this.showFloatingMenu = true;
         this.openImageviewerMenu();
         this.downloadAsPDF();
       } else if (!this.hoverFloatingMenu && this.showFloatingMenu) {
         this.showFloatingMenu = false;
         this.openImageviewerMenu();

}

     }
     if (e.shiftKey && e.altKey && e.keyCode === 38) {

     }
     if (e.shiftKey && e.altKey && e.keyCode === 45) {

     }*/
  }
  selectImage() {
    this.isShowFloatIcons = !this.isShowFloatIcons;
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    this.selection = !this.selection;
    if (this.selection) {
      this.modalService.showNotification('INFO', 'INFO', this.clipImageToastMessage || 'Click and drag to draw a box to clip', 2000);
      $('.image-viewer-table').addClass('hide-mouse-action');
      $('.image-photo-highlight').addClass('hide-mouse-action');
      this.rotationValue.emit({ rotateValue: this.currentAngle, random: Math.random() });
    } else {
      $('.image-viewer-table').removeClass('hide-mouse-action');
      $('.image-photo-highlight').removeClass('hide-mouse-action');
    }
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
  selectionPerformed(event) {
    this.selection = false;
    this.isShowFloatIcons = true;
  }
  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  openImageviewerMenu() {
    this.isImageviewerMenuOpen = !this.isImageviewerMenuOpen;
    if ($('.imageviewer-menubar').hasClass('imageviewer-menubar-active')) {
      $('.imageviewer-menubar').removeClass('imageviewer-menubar-active');
    } else {
      $('.imageviewer-menubar').addClass('imageviewer-menubar-active');
    }
    this.isDownloadMenuOpen = false;
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;

    this.sharedService.dropdownToggleOption({
      tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument:  false
    });
  }
  openBrightnessMenu() {
    this.isDownloadMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isBrightnessMenuOpen = !this.isBrightnessMenuOpen;
  }
  downloadAsPDF() {
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = !this.isDownloadMenuOpen;
    this.isInvalidPageNumber = false;
  }
  printImageMenu() {
    this.isBrightnessMenuOpen = false;
    this.isDownloadMenuOpen = false;
    this.isPrintMenuOpen = !this.isPrintMenuOpen;
    this.isInvalidPageNumber = false;
  }
  backToSearchResults() {
    if (this.isNeedToLogout) {
      sessionStorage.clear();
      localStorage.clear();
    }
    this.searchService.backToSearchResult(this.searchTermCopy, this.pageContentData.advancedFilterParam);
  }
  bookmarkPageClick() {
    this.showClipBoardSuccessAlert = false;
    const currentUrl = window.location.href;
    const requestParams = { 'url': currentUrl + ';fromBookmark=true' };
    // this.modalService.showLoader();
    this.httpService.post(environment.APIUrl + 'util/shorturl', requestParams, (response) => {
      this.bookmarkPageURL = response.url;
      // this.modalService.hideLoader();
    }, (err) => {
    });
    this.bookMarkmodalRef = this.bsModalService.show(this.bookmarkHtmlPageTemplate, { backdrop: true, ignoreBackdropClick: true });
  }
  closeBookMarkModal() {
    this.bookMarkmodalRef.hide();
  }
  copytoClipBoard(bookmarkPageURL) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const $input = $('#bookmarkPageURLonTemplate');
      $input.val();
      const el = $input.get(0);
      const editable = el.contentEditable;
      const readOnly = el.readOnly;
      el.contentEditable = true;
      el.readOnly = false;
      const range = document.createRange();
      range.selectNodeContents(el);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;

      const successful = document.execCommand('copy');
      $input.blur();
      const msg = successful ? 'successful ' : 'un-successful ';
    } else {
      const textToBeCopied = $('#bookmarkPageURLonTemplate').text();
      const event = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', textToBeCopied);
        e.preventDefault();
        document.removeEventListener('copy', event);
      };
      document.addEventListener('copy', event);
    }
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      this.showClipBoardSuccessAlert = true;
    }
  }
  citationClick() {
    if (this.currentDocumentId) {
      this.requestParams = {
        'wileyId': this.currentDocumentId.docId,
        'citationTypes': ['modern-language-association', 'chicago-author-date', 'apa']
      };
    } else {
      this.requestParams = {
        'wileyId': this.currentDocID,
        'citationTypes': ['modern-language-association', 'chicago-author-date', 'apa']
      };
    }
    // this.modalService.showLoader();
    this.httpService.post(environment.APIUrl + 'citation/', this.requestParams, (response) => {
      this.citationRes = response;
      this.citationmodalRef = this.bsModalService.show(this.citationPageTemplate, {
        class: 'large',
        backdrop: true, ignoreBackdropClick: true
      });
      // this.modalService.hideLoader();
    }, (err) => {
      // this.modalService.hideLoader();
    });
  }
  closecitationPageTemplate() {
    this.citationmodalRef.hide();
  }

  copyCitation(elem, citation?) {

    if (citation === 'MLA') {
      this.citaionMLA = true;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'CHICAGO') {
      this.citaionMLA = false;
      this.citaionCHICAGO = true;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'APA') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = true;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'ModelMLA') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = true;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'ModelAPA') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = true;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'ModelCHICAGO') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = true;
    }
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      if (citation) {
        $(elem).addClass('welcome');
        const citationEle = elem;
        const textToBeCopied = (citationEle).replace(/<(?:.|\n)*?>/gm, '');
        const $input = $('.mycopyCitation');
        $input.val();
        const el = $input.get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;

        const successful = document.execCommand('copy');
        $input.blur();
        const msg = successful ? 'successful ' : 'un-successful ';
      }
    } else {
      const textToBeCopied = (elem).replace(/<(?:.|\n)*?>/gm, '');
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', this.copytoClipboard, this.citationCopiedtoClipboard);
    }
  }

  downloadCitation(citation) {
    const textToBeCopied = citation;
    const htmlCode = `<html><head><title>Citation</title><style> body{    font-family: 'Open Sans', sans-serif !important;}
     .csl-entry { color: #414246;font-size: 16px; font-weight: 300; line-height: 20px;  } i{font-weight : bold;}
         </style>
      </head><body ><div>`;
    const htmlContent = [htmlCode + textToBeCopied + '</div></body></html>'];
    const htmlContentipad = $(textToBeCopied).text();

    const blob = new Blob(htmlContent, { type: 'text/html' });
    const doc = document.createElement('a');
    let fileNameToSaveAs;
    if (this.currentDocumentId) {
      fileNameToSaveAs = this.currentDocumentId.docId;
    } else {
      fileNameToSaveAs = this.currentDocID;
    }
    if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
      window.navigator.msSaveBlob(blob, fileNameToSaveAs);
    } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      window.open('data:application/json;charset=utf-8,' + encodeURIComponent(htmlContentipad), '_blank');
    } else {
      doc.href = URL.createObjectURL(blob);
      doc.download = fileNameToSaveAs + '.html';
      doc.hidden = true;
      document.body.appendChild(doc);
      doc.click();
    }
  }
  exportRIS(type) {
    const typeName = $(type).attr('id');
    let requestParams;
    if (this.currentDocumentId) {
      requestParams = this.currentDocumentId.docId;
    } else {
      requestParams = this.currentDocID;
    }
    this.httpService.downloadFile(environment.APIUrl + APIURLS['getCitationRISFile'] + requestParams).takeUntil(this.stop$).subscribe((resp) => {
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        window.open('data:application/json;charset=utf-8,' + encodeURIComponent(resp._body), '_blank');
      }
      const res = new Blob([resp.blob()], { type: 'application/pdf' });
      const fileName = requestParams + '-' + typeName + '.ris';
      if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(res); // xhr.response is a blob
        a.download = fileName; // Set the file name.
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
    }, (err) => {
    });

  }
  listClick(document) {
    this.pageSettingService.addToViewList({
      id: document.wileyId,
      title: document.title,
      citation: document.description,
      imageThumbnails: document.images.map((thumb) => {
        return environment.APIUrl + 'file/thumb/' + document.wileyId + '/' + thumb + '?type=small&access_token=' + this.accessToken
        + '&WDALoc=' + localStorage.getItem('currentLocation') || environment.defaultLocation ;
      })
    }, document.docType);
  }
  openShareMenu(event) {
    // this.modalService.showLoader();
    $('.image-help-container').removeClass('show-help');
    if (this.toggleviewlist) {
      this.toggleviewlist = false;
      this.shareUrl = '';
      // addthis.layers.refresh();
      // addthis.update('share', 'url', this.shareUrl);
      // addthis.update('share', 'title', this.documentData.title +
      //   '. Please use the following link to access content from the Wiley Digital Archives:');
      // addthis.update('share', 'description', 'Please use the following link to access content from the Wiley Digital Archives:');
      // this.modalService.hideLoader();
    } else {
      this.toggleviewlist = true;
      const currentUrl = window.location.href;
      const requestParams = { 'url': currentUrl };
      const currentHost = this.urlUtilityService.getCurrentUrl();

      if (this.documentData) {
        if (this.documentData['wileyId']) {
          this.httpService.get(environment.APIUrl + 'auth/guestauth/' + this.documentData['wileyId'], (response) => {
            this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
              const currentParams = JSON.parse(JSON.stringify(params));
              currentParams['guestAuthToken'] = response.authtoken.access_token;
              currentParams['searchTerm'] = '';
              delete currentParams['isFromResultsPage'];
              currentParams['location_id'] = localStorage.getItem('currentLocation') || environment.defaultLocation;
              const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
              const urlToShort = currentHost + this.router.createUrlTree(
                [`/${currentArchive}/detail/${this.documentData.wileyId}`, currentParams]).toString();
              this.urlUtilityService.getShortUrl(urlToShort, (shortUrlResponse) => {
                this.shareUrl = shortUrlResponse.url;
                // addthis.layers.refresh();
                // addthis.update('share', 'url', shortUrlResponse.url);
                // addthis.update('share', 'title', this.documentData.title +
                //   '. Please use the following link to access content from the Wiley Digital Archives:');
                // addthis.update('share', 'description', 'Please use the following link to access content from the Wiley Digital Archives:');
                // this.modalService.hideLoader();
              }, (err) => {
                // this.modalService.hideLoader();
              });
            });
          }, (err) => {
            // this.modalService.hideLoader();
          }, 'json', true);
        }
        // this.modalService.hideLoader();
      }
    }
    event.stopPropagation();
  }
  shareMailClick() {
    // this.modalService.showLoader();
    this.shareMailData = {
      'topic': this.documentData.title,
      'message': '',
      'to': ''
    };
    const currentHost = this.urlUtilityService.getCurrentUrl();
    if (this.documentData) {
      if (this.documentData['wileyId']) {
        this.httpService.get(environment.APIUrl + 'auth/guestauth/' + this.documentData['wileyId'], (response) => {
          this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
            const currentParams = JSON.parse(JSON.stringify(params));
            currentParams['guestAuthToken'] = response.authtoken.access_token;
            currentParams['searchTerm'] = '';
            delete currentParams['isFromResultsPage'];
            currentParams['location_id'] = localStorage.getItem('currentLocation') || environment.defaultLocation;
            const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
            const urlToShort = currentHost + this.router.createUrlTree(
              [`/${currentArchive}/detail/${this.documentData.wileyId}`, currentParams]).toString();
            this.urlUtilityService.getShortUrl(urlToShort, (shortUrlResponse) => {
              this.shareUrl = shortUrlResponse.url;
              this.shareMailData.message = this.useFollowingLinktoAccessContent + ' \n' + this.shareUrl;
              this.sharemodalRef = this.bsModalService.show(this.shareHtmlPageTemplate, { backdrop: true, ignoreBackdropClick: true });
              // this.modalService.hideLoader();
            }, (err) => {
              // this.modalService.hideLoader();
            });
          });
        }, (err) => {
          // this.modalService.hideLoader();
        }, 'json', true);
      }
    }
    // this.modalService.hideLoader();
  }

  closeShareModal() {
    this.sharemodalRef.hide();
  }

  shareEmail() {
    this.utilityService.sendToMailClient(this.shareMailData.topic,
      this.shareMailData.message, this.shareMailData.to, this.shareMailData.from).then(res => {
        this.modalService.showNotification('SUCCESS', this.mailSent, '');
        this.closeShareModal();
      }, err => {
        this.modalService.showNotification('SUCCESS', this.emailNotDelivered, '');
        this.closeShareModal();
      });
  }
  backToSearchResultsPage() {
    if (this.isNeedToLogout) {
      sessionStorage.clear();
      localStorage.clear();
    }
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      delete currentParams['pageId'];
      this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
    });
    if (this.defaultPage) {
      if (this.defaultPage.isDocViewerShow) {
        this.defaultPage.isDocViewerShow = false;
        $('html, body').animate({ scrollTop: (this.defaultPage.currentOffset.top - 500) }, 100);
      } else {
        this.backToSearchResults();
      }
    } else {
      this.backToSearchResults();
    }
  }
  floatingToolbarMouseEnter() {
    // if (this.showOverlayFloat) {
      this.hoverFloatingMenu = true;
      this.isDownloadMenuOpen = false;
      this.isPrintMenuOpen = false;
      this.isBrightnessMenuOpen = false;
    // }
  }
  floatingToolbarMouseLeave() {
    this.hoverFloatingMenu = false;
    this.showFloatingMenu = false;
    this.isImageviewerMenuOpen = false;
  }
  closeBrightnessToolbar() {
    $('.brightness-icon').trigger('click');
  }

  loadNextImage() {
    if ((this.currentSelectedImageIndex + 1) < this.listOfImages.length) {
      this.loadImage(this.listOfImages[this.currentSelectedImageIndex + 1].imageId);
      this.selectCurrentImageAndApplyStyles(this.currentSelectedImageIndex);
      this.currentActiveImageIndex.emit(this.currentSelectedImageIndex);
    }
    if (this.showOverlay === false) {
      this.showOverlayFloat = 0;
      this.hoverFloatingMenu = false;
      setTimeout(() => {
        this.showOverlayValue = { status: true, random: Math.random() };
        this.newOverlayFloatingVal.emit({ status: true, random: Math.random() });
      }, 2500);
    } else {
      this.showOverlayFloat = 1;
      this.hoverFloatingMenu = true;
      setTimeout(() => {
        this.showOverlayValue = { status: false, random: Math.random() };
        this.newOverlayFloatingVal.emit({ status: false, random: Math.random() });
      }, 2500);
    }
  }

  loadPreviousImage() {
    if ((this.currentSelectedImageIndex - 1) >= 0) {
      this.loadImage(this.listOfImages[this.currentSelectedImageIndex - 1].imageId);
      this.selectCurrentImageAndApplyStyles(this.currentSelectedImageIndex);
      this.currentActiveImageIndex.emit(this.currentSelectedImageIndex);
    }
    if (this.showOverlay === false) {
      this.showOverlayFloat = 0;
      this.hoverFloatingMenu = false;
      setTimeout(() => {
        this.showOverlayValue = { status: true, random: Math.random() };
        this.newOverlayFloatingVal.emit({ status: true, random: Math.random() });
      }, 2500);
    } else {
      this.showOverlayFloat = 1;
      this.hoverFloatingMenu = true;
      setTimeout(() => {
        this.showOverlayValue = { status: false, random: Math.random() };
        this.newOverlayFloatingVal.emit({ status: false, random: Math.random() });
      }, 2500);
    }
  }
}
interface ShareMail {
  topic?: String;
  message?: String;
  to?: String;
  from?: String;
}
