<div>
  <div class="overlay-list" *ngIf="isHiglightisOn">
    <a
      [class.arabic-style]="isArabicLanguage"
      class="toggleHighlight"
      (click)="toggleHighlight()"
    >
      {{ "Detail.Highlight" | translate }}
      <span
        [class.arabic-style]="isArabicLanguage"
        *ngIf="!toggleHighlighter"
        >{{ "Detail.Off" | translate }}</span
      >
      <span [class.arabic-style]="isArabicLanguage" *ngIf="toggleHighlighter">{{
        "Detail.On" | translate
      }}</span>
    </a>
  </div>

  <div
    id="openSeaDragonCanvas"
    #editorContainer
    (mouseover)="imageViewerMouseEnter()"
    (mouseleave)="imageViewerMouseLeave()"
  >
    <div
      class="image-viewer-frame-loader"
      [ngStyle]="{ display: imageviewerframeLoader ? 'block' : 'none' }"
    >
      <img
        alt="{{ 'Alt.loaderImage' | translate }}"
        class="img-icon"
        src="assets/images/three-dots.svg"
      />
    </div>
    <ng-container
      *ngIf="
      this.currentArchives && this.currentArchives.shortName
          ? this.currentArchives && this.currentArchives.shortName.toUpperCase() != 'WPA' && this.currentArchives && this.currentArchives.shortName.toUpperCase() != 'WPA1'
          : true
      "
    >
      <div
        *ngIf="!hideTitle"
        id="tableOverlayTooltip"
        class="table-overlay-tooltip"
        style="display: none"
      >
        <p [class.arabic-style]="isArabicLanguage" class="overlay-tooltip">
          {{ "HomePage.clickToDownloadFile" | translate }}
        </p>
      </div>
    </ng-container>
    <div
      *ngIf="imageConfig?.droneMapExists && !hideDroneText"
      class="drone-content-box"
    >
      <p class="dronemap-textcontent">
        This large-format map was captured by a drone using an innovative
        technique. Some areas may be slightly unfocused.
      </p>
      <span
        class="fa fa-times hidetitle-droneclose"
        (click)="hideDronemapsText()"
      ></span>
    </div>
  </div>
  <span
    *ngIf="!hideOverlay && hideTitle"
    class="fa fa-chevron-up hidetitle-rarrow show-uparrow"
    [class.itemtitle-overlay-open]="itemNotes"
    (click)="showTitleOverlay()"
  ></span>
  <div class="overlay" *ngIf="!hideTitle">
    <span
      class="fa fa-chevron-down hidetitle-rarrow"
      [class.itemtitle-overlay-close]="itemNotes"
      (click)="hideTitleOverlay()"
    ></span>
    <div
      appDecodeEntities
      [class.arabic-style]="isArabicLanguage"
      *ngIf="
        imageConfig?.title &&
        (imageConfig !== undefined || imageConfig !== null)
      "
      class="text-white item-overlay-txt"
      #textContent
      id="read-more{{ itemNote }}"
      [class.lessText]="showCaptionTitle && imageConfig?.title?.length > 170"
      [class.overlay-mobile-view]="!showCaptionTitle"
    >
      {{ "Detail.imageItemTitle" | translate }}: {{ imageConfig.title }}
    </div>
    <div
      class="viewMoreBtn-section"
      (click)="showViewAction()"
      *ngIf="imageConfig?.title?.length > 170"
    >
      <span *ngIf="showCaptionTitle" style="display: flex"
        >{{ "HomePage.viewMore" | translate }} &nbsp;<i
          [class.arabic-style]="isArabicLanguage"
          class="fa fa-chevron-down morebtn"
          title="{{ 'HomePage.viewMore' | translate }}"
          aria-hidden="true"
        ></i
      ></span>
      <span *ngIf="!showCaptionTitle"
        >{{ "HomePage.viewLess" | translate }} &nbsp;<i
          [class.arabic-style]="isArabicLanguage"
          class="fa fa-chevron-up"
          title="{{ 'HomePage.viewLess' | translate }}"
          aria-hidden="true"
        ></i
      ></span>
    </div>
    <!-- <div *ngIf="itemNotes" class="text-white item-notes-content"> 
    <span [class.arabic-style]="isArabicLanguage" class="notes-content">{{ 'Detail.Notes' | translate }}:</span>
    <ng-container *ngFor="let notes of itemNotes">          
            <span class="notes-content">{{notes?.value}}</span>
      </ng-container>

  </div>   
    <ng-template *ngIf="itemNote">
    <div *ngIf="itemNote.length > 150" class="view-more-text">
        <a role="button" *ngIf='viewMorebtn' (click)='viewMorebtn = false'>
          <span class="less pull-right" >{{ 'HomePage.viewLess' | translate }}
            <i class="btn btn-up-arrow fa fa-angle-up" aria-hidden="true"></i>
          </span>
          </a>
          <a  role="button" >
          <span class="more pull-right" *ngIf='!viewMorebtn'  (click)='viewMorebtn = true'>{{ 'HomePage.viewMore' | translate }}
            <i class="btn btn-down-arrow fa fa-angle-down" aria-hidden="true"></i>
          </span>
        </a>
      </div>
    </ng-template> -->
  </div>

  <div class="backdrop-modal"></div>
  <div
    class="modal fade fullScreenModal-popup"
    id="fullScreenModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="static"
  >
    <div class="modal-dialog large" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="removeBackDrop()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            [class.arabic-style]="isArabicLanguage"
            class="cliped-image-details"
          >
            {{ "HomePage.describeClippedImage" | translate }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <label
                [class.arabic-style]="isArabicLanguage"
                class="pull-left custom-title"
              >
                {{ "HomePage.clipImageTitle" | translate }}:</label
              >
              <span class="glyphicon glyphicon-asterisk mandatory-icon"></span>
              <input
                type="text"
                maxlength="100"
                value="{{ 'HomePage.clipImage' | translate }} {{
                  (clippedImageList?.length || 0) + 1
                }}"
                class="form-control"
                name="customTitle"
                #customTitleRefModal
              />
            </div>
            <div class="col-md-12">
              <label
                [class.arabic-style]="isArabicLanguage"
                class="pull-left custom-title"
              >
                {{ "HomePage.clipImageNotes" | translate }}:</label
              >
              <textarea
                class="form-control custom-textarea"
                maxlength="500"
                name="customTitle"
                #customNotesRefModal
              ></textarea>
            </div>
            <div class="col-md-12">
              <br />
              <button
                type="button"
                class="btn btn-primary pull-right"
                (click)="
                  updateTitle(
                    customTitleRefModal.value.trim(),
                    customNotesRefModal.value.trim()
                  )
                "
                [disabled]="!customTitleRefModal.value.trim()"
              >
                {{ "SearchResult.AddToList" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade fullScreenModal-popup"
    id="downloadPdf"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="static"
  >
    <div class="modal-dialog large" role="document">
      <div class="modal-content">
        <!-- <ng-template #downloadPageasPdfTemplate> -->
        <div class="modal-header download-title">
          <h4 class="modal-title text-center">
            <!-- <span class="downloadPopupIcon"></span> -->
            <!-- <span class="spriteico downloadPopupIcon"></span> -->
            <img
              src="assets/images/icons/download@2x.png "
              alt="View History"
              class="center-block downloadPopupIcon"
            />
          </h4>
        </div>
        <div class="modal-body url_body download-body">
          <div [class.arabic-style]="isArabicLanguage" class="download-text">
            <a
              *ngIf="downloadPageURL"
              [class.arabic-style]="isArabicLanguage"
              class="primary-text-color"
              href="{{ downloadPageURL }}"
              target="_blank"
              >{{ "Detail.downloadPDF" | translate }}</a
            >
            <a
              *ngIf="downLoadTiffImageURL"
              [class.arabic-style]="isArabicLanguage"
              class="primary-text-color"
              href="{{ downLoadTiffImageURL }}"
              target="_blank"
            >
              <span *ngIf="!isGeoTiffEnabled">{{
                "Detail.downloadTiff" | translate
              }}</span>
              <span *ngIf="isGeoTiffEnabled">{{
                "Detail.downloadGeoTiffLinkTxt" | translate
              }}</span>
            </a>
          </div>
          <div
            class="modal-download-text download-text-cmt"
            [class.arabic-style]="isArabicLanguage"
          >
            <span
              [class.arabic-style]="isArabicLanguage"
              class="modal-download-text-span"
            >
              {{ "Detail.clicktoDownloadFileMessage" | translate }}</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            [class.arabic-style]="isArabicLanguage"
            type="button"
            class="btn btn-secondary"
            (click)="closeDownloadPdfModal(); $event.stopPropagation()"
          >
            {{ "Detail.Close" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </ng-template> -->
</div>
