<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->

<ul *ngIf="pageContent" id="showAdvFilter" class="dropdown-menu dropdown-menu-right dropdown-form" [class.homepageDropdown]="isFromHomePage"
  [class.dropdwn-minwidth]="!isFromHomePage" role="menu" [ngStyle]="{ 'display' : showFilter ? 'block' : 'none'}">
  <div class="option-list-wrapper"></div>
  <li role="presentation">
    <div class="search-filter-container">
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="filterForm" class="advance-search-menu" [class.hideDropdownContent]="isFilterToggled === false && isFromHomePage === true "
        (submit)="searchSubmit()">

        <div class="col-md-12 col-sm-12 col-xs-12">
          <span class="estimated-count-div">
            <span *ngIf="!isFromHomePage">
              <span [class.arabic-style]="isArabicLanguage" class="text-italic estimated-count" *ngIf="!isFromHomePage">{{ 'AdvancedSearch.estimatedCount' | translate }} </span>
              <span class="blink-wordlbl mb-0" #estimatedCountLabel>
                <span *ngIf="!isFromHomePage && (estimatedCount === 0 && !loading || estimatedCount > 0 && !loading)">
                  <b>{{(estimatedCount == 0) ? advancedSearchNoDocumentsText : estimatedCount + advancedSearchDocumentsText}}</b>
                </span>
                <span *ngIf="loading">
                    {{'AdvancedSearch.Loading' | translate}}
                  </span>
                <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'AdvancedSearch.estimatedCount' | translate }}</span>
              </span>
              <span class="sr-only">{{ 'AdvancedSearch.estimatedCount' | translate }}</span>
            </span>
            <span [class.arabic-style]="isArabicLanguage" class="pull-right reset_link" *ngIf="!isFromHomePage" title="{{ 'AdvancedSearch.Reset' | translate }}"
              (click)="resetSearchFilter()">{{ 'AdvancedSearch.CapsReset' | translate }}</span>
          </span>
        </div>

        <div class="row" class="fliter-content-area">
          <div class="col-lg-7 col-md-12 col-sm-12 adv_search_content" [class.search-fullWidth]="!isFromHomePage">
            <label [class.arabic-style]="isArabicLanguage" class="quick-limiter">{{ 'AdvancedSearch.fieldedSearch' | translate }}</label>
            <filter-control [formControl]="searchKeywords" [field-options]="pageContent.availableFields" [operator-options]="pageContent.availableOperators"></filter-control>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12" [class.search-fullWidth]="!isFromHomePage">
            <label [class.arabic-style]="isArabicLanguage" class="quick-limiter">{{ 'AdvancedSearch.quickLimitors' | translate }}</label>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12 advanced-searchdiv">
                <span [ngClass]="{'hidecontentTypeTooltip': !contentTypeTooltip}">
                  <!-- <tooltip-content #contentTypeTooltipText>
                    <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentTypes' | translate }}</label>
                    <br/>{{contentTypeTooltip}}</tooltip-content> -->
                    <ng-template #contentTypeTooltipText>
                      <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentTypes' | translate }}</label>
                      <br/>{{contentTypeTooltip}}
                    </ng-template>
                  <ss-multiselect-dropdown [tooltip]="contentTypeTooltipText" placement="top" [options]="pageContent.availableContentTypes" [texts]="{ checked: contentTypeTexts.checked, checkedPlural:
contentTypeTexts.checkedPlural, defaultTitle: contentTypeTexts.defaultTitle, searchPlaceholder: contentTypeTexts.searchPlaceholder
}" [settings]="contentTypeSettings" formControlName="contentType" [class.highlight-limitors]="contentTypeTooltip ? true : false"></ss-multiselect-dropdown>
                </span>
                <span>

                  <div class="pubDate-div button-group custom-tooltip">
                  <!-- <tooltip-content #publicationdateTooltip>
                    <label>{{ 'HomePage.publicationDate' | translate }}</label>
                    <br/><span class="custom-tooltip-wrap">{{filterForm.get('publishedDatePrefix').value == 'All' ? 'All' : (filterForm.get('publishedDateFrom').value || filterForm.get('publishedDateTo').value) ? (filterForm.get('publishedDatePrefix').value
                    === 'All' ? '':filterForm.get('publishedDatePrefix').value + ' : ' ) + filterForm.get('publishedDateFrom').value
                    : publicationDateLabel}} {{ filterForm.get('publishedDateTo').value ? ' & ' + filterForm.get('publishedDateTo').value
                    : ''}} {{(filterForm.get('publishedDateIncludesNull').value && filterForm.get('publishedDatePrefix').value)
                    ? ' And Undated' : '' }}</span>
                    <br/>
                  </tooltip-content> -->
                  <ng-template #publicationdateTooltip>
                    <label>{{ 'HomePage.publicationDate' | translate }}</label>
                    <br/>
                  </ng-template>
                  <button [tooltip]="publicationdateTooltip" placement="top" type="button" data-tooltip="tooltip" class="dropdown-search rightpadding-0 dropdown-element pub-date down-fa dropdown-toggle"
                  data-toggle="dropdown" id="publication-date" (click)="togglePubDate()" [class.highlightpubs-limitors]="filterForm.get('publishedDatePrefix').value == '' ? false : true">
                  {{filterForm.get('publishedDatePrefix').value == 'All' ? 'All' : (filterForm.get('publishedDateFrom').value || filterForm.get('publishedDateTo').value)
                  ? (filterForm.get('publishedDateFrom').value == 'All' ? '' : filterForm.get('publishedDatePrefix').value
                  + ' : ' ) + filterForm.get('publishedDateFrom').value : publicationDateLabel}} {{ filterForm.get('publishedDateTo').value
                  ? ' & ' + filterForm.get('publishedDateTo').value : '' }} {{(filterForm.get('publishedDateIncludesNull').value
                  && filterForm.get('publishedDatePrefix').value ) ? 'And Undated' : '' }}
                </button>

                    <!-- [ngStyle]="{'display': showPubDate ? 'block': 'none'}" -->
                    <ul class="dropdown-menu publicate-date-ul cus-date" (click)="$event.stopPropagation();">
                      <li>
                        <div class="date-pickeron" data-reference="publication-date">
                          <a href="javascript:void(0);" title="{{'HomePage.All' | translate}}" class="small" data-value="All">
                            <input value="All" id="select_all_pubdate" type="radio" [formControl]="publishedDatePrefix" />
                            <label for="select_all_pubdate">
                              <span [class.arabic-style]="isArabicLanguage">
                                {{ 'HomePage.ToolsDropdown.ALL' | translate }}
                              </span>
                            </label>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="date-pickeron" data-reference="publication-date">
                          <a href="javascript:void(0);" title="{{ 'HomePage.On' | translate }}" class="small" data-value="ON">
                            <input value="ON" id="on" type="radio" [formControl]="publishedDatePrefix" />
                            <label for="on">
                              <span [class.arabic-style]="isArabicLanguage">
                                {{ 'HomePage.On' | translate }}
                              </span>
                            </label>
                          </a>
                          <div *ngIf="filterForm.get('publishedDatePrefix').value == 'ON'">
                            <input [class.arabic-style]="isArabicLanguage" [textMask]="{mask: publishDateMask, guide:false, modelClean:true}"
                              class="date-picker" placeholder="{{'Common.Placeholder.date' | translate}}" type="text" [formControl]="publishedDateFrom"
                            />
                            <p class="date-error mb-0" *ngIf="checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)">Enter valid Date</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="date-pickeron" data-reference="publication-date">
                          <a href="javascript:void(0);" title="{{ 'HomePage.Before' | translate }}" class="small" data-value="Before">
                            <input class="test-radio" value="BEFORE" id="before" type="radio" [formControl]="publishedDatePrefix" />
                            <label for="before">
                              <span [class.arabic-style]="isArabicLanguage">
                                {{ 'HomePage.Before' | translate }}
                              </span>
                            </label>
                          </a>
                          <div *ngIf="filterForm.get('publishedDatePrefix').value == 'BEFORE'">
                            <input [class.arabic-style]="isArabicLanguage" [textMask]="{mask: publishDateMask, guide:false}"
                              class="date-picker" placeholder="{{'Common.Placeholder.date' | translate}}" type="text" [formControl]="publishedDateFrom"
                            />
                            <p class="date-error mb-0" *ngIf="checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)">Enter valid Date</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="date-pickeron" data-reference="publication-date">
                          <a href="javascript:void(0);" title="{{ 'HomePage.After' | translate }}" class="small" data-value="After" tabIndex="1">
                            <input value="AFTER" id="after" type="radio" [formControl]="publishedDatePrefix" />
                            <label for="after">
                              <span [class.arabic-style]="isArabicLanguage">
                                {{ 'HomePage.After' | translate }}
                              </span>
                            </label>
                          </a>
                          <div *ngIf="filterForm.get('publishedDatePrefix').value == 'AFTER'">
                            <input [class.arabic-style]="isArabicLanguage" [textMask]="{mask: publishDateMask, guide:false}"
                              class="date-picker" placeholder="{{'Common.Placeholder.date' | translate}}" type="text" [formControl]="publishedDateFrom"
                            />
                            <p class="date-error mb-0" *ngIf="checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)">Enter valid Date</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="date-pickeron" data-reference="publication-date">
                          <a href="javascript:void(0);" title="{{ 'HomePage.Between' | translate }}" class="small" data-value="Between" tabIndex="1">
                            <input value="BETWEEN" id="Between" type="radio" [formControl]="publishedDatePrefix" />
                            <label for="Between">
                              <span [class.arabic-style]="isArabicLanguage">
                                {{ 'HomePage.Between' | translate }}
                              </span>
                            </label>
                          </a>
                          <div *ngIf="filterForm.get('publishedDatePrefix').value == 'BETWEEN'">
                            <input [class.arabic-style]="isArabicLanguage" class="date-picker" [textMask]="{mask: publishDateMask, guide:false}" placeholder="{{'Common.Placeholder.date' | translate}}"
                              type="text" [formControl]="publishedDateFrom"/>
                            <input [class.arabic-style]="isArabicLanguage" class="date-picker" [textMask]="{mask: publishDateMask, guide:false}" placeholder="{{'Common.Placeholder.date' | translate}}"
                              type="text" [formControl]="publishedDateTo"/>
                              <p class="date-error mb-0" *ngIf="checkDate  && (publishedDateFrom.dirty || publishedDateFrom.touched || publishedDateTo.dirty || publishedDateTo.touched)">Enter valid Date</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="pubdate-checkboxdiv">
                          <label [class.arabic-style]="isArabicLanguage" class="pubdate-checkbox">{{ 'AdvancedSearch.includeUndatesDocuments' | translate }}
                            <input type="checkbox" [formControl]="publishedDateIncludesNull">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </li>
                    </ul>
                    </div>
                </span>
                  <span [ngClass]="{'hideCollectionTooltip': !collectionsTooltip}">
                    <!-- <tooltip-content #collectionsTooltipText>
                      <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.Collections' | translate }}</label>
                      <br/>{{collectionsTooltip}}</tooltip-content> -->
                      <ng-template #collectionsTooltipText>
                        <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.Collections' | translate }}</label>
                        <br/>{{collectionsTooltip}}
                      </ng-template>
                    <ss-multiselect-dropdown class="contentCollection"  [options]="pageContent.availableCollections"
                     [tooltip]="collectionsTooltipText" lacement="top"
                      [class.highlight-limitors]="collectionsTooltip ? true : false" [texts]="{ checked: collectionTexts.checked, checkedPlural:
collectionTexts.checkedPlural, defaultTitle: collectionTexts.defaultTitle, searchPlaceholder: collectionTexts.searchPlaceholder
}" [settings]="collectionsSettings" formControlName="contentCollection" (dropdownOpened) = "onDropdownOpen()"></ss-multiselect-dropdown>
                    <!-- <ng-template #collectionsTooltipTemplate>Here we go: <div [innerHtml]="html"></div></ng-template> -->
                  </span>
              <span [ngClass]="{'hideillustrationTypeTooltip': !illustrationTypeTooltip}">
              <!-- <tooltip-content #illustrationTypeTooltipText>
                  <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.illustrationType' | translate }}</label>
                  <br />{{illustrationTypeTooltip}}</tooltip-content> -->
                  <ng-template #illustrationTypeTooltipText>
                    <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.illustrationType' | translate }}</label>
                    <br />{{illustrationTypeTooltip}}
                  </ng-template>
              <ss-multiselect-dropdown class="illustrationType" [options]="pageContent.availableIllustrationTypes"
                  [tooltip]="illustrationTypeTooltipText" placement="top"
                  [texts]="{ checked: illustrationTypeText.checked, checkedPlural: illustrationTypeText.checkedPlural, defaultTitle:
              illustrationTypeText.defaultTitle, searchPlaceholder: illustrationTypeText.searchPlaceholder }"
[settings]="illustrationTypeSettings" formControlName="illustrationType" (dropdownOpened) = "onDropdownOpen()"
[class.highlight-limitors]="illustrationTypeTooltip ? true : false"></ss-multiselect-dropdown>
              </span>

              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 advanced-searchdiv">
                <span [ngClass]="{'hidecontentSubTypesTooltip': !contentSubTypesTooltip}">
                  <!-- <tooltip-content #contentSubTypeTooltipText>
                    <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSubtypes' | translate }}</label>
                    <br/>{{contentSubTypesTooltip}}</tooltip-content> -->
                    <ng-template #contentSubTypeTooltipText>
                      <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSubtypes' | translate }}</label>
                      <br/>{{contentSubTypesTooltip}}
                    </ng-template>
                  <ss-multiselect-dropdown class="docSubType" [options]="pageContent.availableContentSubTypes"
                    [tooltip]="contentSubTypeTooltipText" placement="top" [texts]="{ checked: contentSubTypeTexts.checked, checkedPlural: contentSubTypeTexts.checkedPlural, defaultTitle: contentSubTypeTexts.defaultTitle,
searchPlaceholder: contentSubTypeTexts.searchPlaceholder }" (dropdownOpened) = "onDropdownOpen()" [settings]="contentSubTypeSettings" formControlName="docSubType"
                    [class.highlight-limitors]="contentSubTypesTooltip ? true : false"></ss-multiselect-dropdown>
                </span>
                <span [ngClass]="{'hidepublicationTitleTooltip': !publicationTitleTooltip}">
                  <!-- <tooltip-content #publicationTitleTooltipText>
                    <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.languages' | translate }}</label>
                    <br/>{{publicationTitleTooltip}}</tooltip-content> -->
                    <ng-template #publicationTitleTooltipText>
                      <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.languages' | translate }}</label>
                    <br/>{{publicationTitleTooltip}}
                    </ng-template>
                  <ss-multiselect-dropdown class="languages" [options]="pageContent.availableLanguages"
                    [tooltip]="publicationTitleTooltipText" placement="top" [texts]="{ checked: languageTexts.checked, checkedPlural: languageTexts.checkedPlural, defaultTitle: languageTexts.defaultTitle,
searchPlaceholder: languageTexts.searchPlaceholder }" (dropdownOpened) = "onDropdownOpen()" [settings]="languageSettings" formControlName="languages" [class.highlight-limitors]="publicationTitleTooltip ? true : false"></ss-multiselect-dropdown>
                </span>
                <span [ngClass]="{'hidecontentSourcesTooltip': !contentSourcesTooltip}">
                  <!-- <tooltip-content #contentSourcesTooltipText class="contentsource-tooltip">
                    <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSources' | translate }}</label>
                    <br/>{{contentSourcesTooltip}}</tooltip-content> -->
                    <ng-template #contentSourcesTooltipText>
                      <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSources' | translate }}</label>
                    <br/>{{contentSourcesTooltip}}
                    </ng-template>
                  <ss-multiselect-dropdown class="contentSources" [options]="pageContent.availableSources"
                    [tooltip]="contentSourcesTooltipText" placement="top"
                    [texts]="{ checked: contentSourcesTexts.checked, checkedPlural: contentSourcesTexts.checkedPlural, defaultTitle:
contentSourcesTexts.defaultTitle, searchPlaceholder: contentSourcesTexts.searchPlaceholder }" [settings]="contentSourcesSettings" (dropdownOpened) = "onDropdownOpen()"
                    formControlName="contentSources" [class.highlight-limitors]="contentSourcesTooltip ? true : false"></ss-multiselect-dropdown>
                </span>
              </div>
            </div>

          </div>
        </div>
        <button type="submit" class="sr-only"></button>
      </form>
      <ng-template #templateConfirmPopup>
        <div class="modal-header">
          <h4 [class.arabic-style]="isArabicLanguage" class="modal-title pull-left">{{ 'AdvancedSearch.Confirmation' | translate }}</h4>
        </div>
        <div [class.arabic-style]="isArabicLanguage" class="modal-body">
          {{ 'AdvancedSearch.exceededMaximumSection' | translate }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-lg" aria-label="Close" (click)="limitorsClose()">
            <span [class.arabic-style]="isArabicLanguage" aria-hidden="true">{{ 'AdvancedSearch.OK' | translate }}</span>
          </button>
        </div>
      </ng-template>
    </div>
  </li>
  <div class="filter-toggle-icon" [class.hide-toggle]="!isFromHomePage">
    <div class="text-center">
      <a class="search-toggle-btn" (click)="toggleDropdown()">
        <i [ngClass]="{'arrowUpIcon':isFilterToggled ,'arrowDownIcon': !isFilterToggled  }" class="spriteico arrowDownIcon" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</ul>
