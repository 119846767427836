
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, Input, Output, EventEmitter, HostListener,
  ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ModalService } from './../common/services/modal.service';
import { HttpService } from './../common/services/http.service';
import { SharedService } from '../common/services/sharedService';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SearchService } from './../common/services/search.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment, APIURLS, COMMONPATHS } from '../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { isEmpty as _isEmpty } from 'lodash';
import { isArray } from 'util';
import { Overlay } from '../new-image-viewer-frame/ImageSource';
declare let OpenSeadragon: any;

declare let $: any;
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dragon-image-viewer',
  templateUrl: './dragon-image-viewer.component.html',
  styleUrls: ['./dragon-image-viewer.component.css']
})
export class DragonImageViewerComponent implements AfterViewInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  isArabicLanguage: Boolean = false;
  _isFuzzy: Boolean = true;
  tableOverlay: any[];
  imageInstance: any;
  cumulativeOverlays: any = [];
  isHiglightisOn: Boolean = false;
  toggleHighlighter: Boolean = false;
  unSubIllustrationsOverlays: Subscription;
  zoomScale: any;
  isFromResultsPage: Boolean;
  isHighlightOn: Boolean = false;
  itemNotes: any;
  hideOverlay = true;
  storageSizeExceeded: any;
  setscreenMode: boolean;
  clippedImageList = [];
  translatedValues: any;
  addedToList: any;
  translateLanguageValues: any;
  croppedimageCount: any = 0;
  documentCitation: any;
  clippedBase64Img: string;
  // @ViewChild('customTitleModal') private modalTemplateRef: TemplateRef<any>;
  @ViewChild('customTitleModal', { static: false }) private modalTemplateRef: TemplateRef<any>;

  modalRef: BsModalRef;
  additionalTitile: any;
  titleOverlayText: any;
  private childNodes = [];
  private pageInfo = [];
  itemNote: any;
  page_id: any;
  pageNo: any;
  selection: any;
  relevantPagesCount: any = 1;
  currentViewerZoomLevel: any;
  viewerOption: any;
  currentXposition: number;
  currentYposition: number;
  canvasYposition: any;
  canvasXposition: any;
  isCanvasPositionChanged: Boolean = false;
  hideTitle: Boolean = false;
  title: Boolean = false;
  viewMorebtn: Boolean = false;
  // itemTitle: any;
  isCollapsed: Boolean = true;
  private viewer: any = null;
  private imageLoader: any = null;
  private _currentImage: any;
  private currentBrightness = 1;
  private currentContrast = 1;
  private currentZoomLevel = 0.1;
  private currentAngle = 0;
  private imageFilters: any = [];
  private isInverted = false;
  private fitImageToWidth = true;
  private isResized = false;
  // tslint:disable-next-line:no-output-rename
  @Output('overlayClicked') private overlayClickEmitter: EventEmitter<any>;
  // tslint:disable-next-line:no-output-rename
  @Output('afterPageLoaded') private pageLoadedEmitter: EventEmitter<any>;
  // tslint:disable-next-line:no-output-rename
  @Output('zoomResetEmit') private zoomResetEmitter: EventEmitter<any>;
  @Output('selectionPerformed') selectionPerformed: EventEmitter<any>;
  imageviewerframeLoader: Boolean = false;
  private hideLoaderValue: Boolean = false;
  private timer: any = 10000;
  private timeout: any = 1000;
  @Input('listOfImages') listOfImages: any;
  @Input() documentImages: any;
  @Input('itemTitle') itemTitle: any;
  defaultImageToBeLoaded: any;
  downloadableUrl: any;
  downloadUrlFor: any;
  @Input() set docIdInput(data: any) {
    if (data) {
      this.defaultImageToBeLoaded = data;
      if (this.defaultImageToBeLoaded['docId']) {
        this.searchService.getDocumentDetail(this.defaultImageToBeLoaded['docId'], (response) => {
          if (response) {
            this.documentCitation = response['description'];
          }
        });
      }
    }
  }
  get docIdInput() {
    return this.defaultImageToBeLoaded;
  }

  @Input('screenMode')
  set _screenMode(value: any) {
    this.setscreenMode = value;
  }
  get _screenMode() {
    return this.setscreenMode;
  }

  currentDocumentType: String;
  currentDocumentId: String;
  @Input('currentDocumentID')
  set documentIdCurrent(value: String) {
    if (value) {
      this.currentDocumentId = value;
    }
  }

  get documentIdCurrent() {
    return this.currentDocumentId;
  }
  @Input('fuzzySearch')
  set fuzzySearch(value: Boolean) {
      this._isFuzzy = value;
  }

  get fuzzySearch() {
    return this._isFuzzy;
  }


  @Input('documentType')
  set documentType(value: String) {
    if (value) {
      this.currentDocumentType = value;
    }
  }

  get documentType() {
    return this.currentDocumentType;
  }

  _invokedFrom: any = {};
  @Input('invokedFrom')
  set invokedFrom(value: any) {
    if (value) {
      this._invokedFrom = value;
    }
  }

  get invokedFrom() {
    return this._invokedFrom;
  }

  keywordForSearchTerm: any;
  searchTermOverlay: any;
  @Input('searchTermOverlay')
  set _searchTermOverlay(value: any) {
    if (value) {
      if (value.splitedSearchTerms) {
        this.keywordForSearchTerm = value.splitedSearchTerms;
      } else if (value.initialSearchTerm && !value.keyword) {
        this.keywordForSearchTerm = value.initialSearchTerm;
      } else {
        this.keywordForSearchTerm = value.keyword;
      }
    }
  }

  get _searchTermOverlay() {
    return this.searchTermOverlay;
  }
  loadSearchTermOverlay: any = [];
  totalImageOverlays: any = [];

  constructor(
    private modalService: ModalService,
    private httpService: HttpService,
    private sharedService: SharedService,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private bsModalService: BsModalService
  ) {
    this.overlayClickEmitter = new EventEmitter();
    this.pageLoadedEmitter = new EventEmitter();
    this.zoomResetEmitter = new EventEmitter();
    this.selectionPerformed = new EventEmitter();
    this.clippedImageList = localStorage.getItem('croppedImageList') ? JSON.parse(localStorage.getItem('croppedImageList')) : [];
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(param => {
      if (param.hasOwnProperty('docID')) {
        if (param['docID']) {
          this.currentDocumentId = param['docID'];
        }
      }
    });
  }
  @Output('highlighthide') private highlighthide: EventEmitter<any> = new EventEmitter();
  private hideHighlight: Boolean = false;
  private image_zoom_in: String;
  private image_zoom_out: String;
  ngAfterViewInit() {

    this.sharedService.clearAllOverlays.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.viewer.overlays = [];
        this.tableOverlay = [];
        this.searchTermOverlay = [];
        this.openImage();
      }
    });
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });

    if (window.innerWidth <= 1024) {
      this.image_zoom_in = 'mob-image-viewer-zoom-in';
      this.image_zoom_out = 'mob-image-viewer-zoom-out';
    } else {
      this.image_zoom_in = 'image-viewer-zoom-in';
      this.image_zoom_out = 'image-viewer-zoom-out';
    }
    this.viewer = OpenSeadragon({
      id: 'openSeaDragonCanvas',
      clickToZoom: false,
      // zoomInButton: this.image_zoom_in,
      // zoomOutButton: this.image_zoom_out,
      homeButton: 'reset',
      zoomPerClick: 1.1,
      zoomPerScroll: 1.1,
      tabIndex: '',
      crossOriginPolicy: 'Anonymous',
      preserveViewport: true,
      visibilityRatio: 1,
      defaultZoomLevel: 1,
      minZoomLevel: 0.2,
      maxZoomLevel: 100,
      // sequenceMode: true,
      preserveOverlays: false,
      showRotationControl: true,
      gestureSettingsTouch: {
        pinchRotate: true
      },
      // constrainDuringPan: true
      // collectionImmediately : false,
      // showNavigator:  true,
      // navigatorPosition:   'TOP_LEFT',
      // ajaxWithCredentials: true,
      // loadTilesWithAjax : true
    });

    this.viewer.gestureSettingsMouse.clickToZoom = false;
    this.imageFilters.push(
      OpenSeadragon.Filters.BRIGHTNESS(this.currentBrightness)
    );
    this.imageFilters.push(
      OpenSeadragon.Filters.CONTRAST(this.currentContrast)
    );
    this.viewer.setFilterOptions({
      filters: {
        processors: this.imageFilters
      }
    });

    this.viewer.addHandler('open', (eventSource, source, data) => {
      this.fitImageToCanvas(this.fitImageToWidth);
      setTimeout(() => {
        this.pageLoadedEmitter.emit(Math.random());
        // $('.image-editor-film-strip').removeClass('hide-filmstrip');
        // $('.mCSB_dragger_bar').removeClass('hide-thumbnail');
        // $('.mCustomScrollBox').removeClass('hide-thumbnail');
        this.hideLoader();
      }, 1000);
    });

    this.viewer.addHandler('update-viewport', (eventSource, source, data) => {
      setTimeout(() => {
        // this.hideLoader();
      }, 2000);
    });

    this.viewer.addHandler('open-failed', (eventSource, source, data) => {
      this.viewer.open({type: 'image', url: COMMONPATHS.imageNotAvailable });
      this.pageLoadedEmitter.emit(Math.random());
      this.hideLoader();
    });
    this.canvasDragEvent();
    this.canvasClickEvent();
    this.sharedService.hideImageViewerTitleOverlay.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.hideTitle = !res;
    });
    this.currentViewerZoomLevel = this.viewer.viewport.getZoom();
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (params['docID']) {
        this.searchService.getDocumentDetail(params['docID'], (response) => {
          if (response) {
            this.documentCitation = response['description'];
          }
        });
      }
    });
    setTimeout(() => {
      this.httpService.get('../assets/json/supported-languages.json?t=' + new Date().getTime(), (response) => {
        this.translateLanguageValues = response.languages;
      },
        err => { });
      this.translate.get('Common').pipe(takeUntil(this.stop$)).subscribe((res) => {
        this.translatedValues = res['Popups'];
        this.storageSizeExceeded = res;
        this.addedToList = res['Popups']['Added To List'];
      });
    }, 1500);
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      const res = event['translations']['Common'];
      this.translatedValues = res['Popups'];
      this.storageSizeExceeded = res;
      this.addedToList = res['Popups']['Added To List'];
    });
    this.sharedService.itemNotes.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.itemNotes = res;
    });
    this.sharedService.ImageViewerHighlight.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res['pageData']) {
        setTimeout(() => {
          this.isHighlightOn = true;
        });
      }
    });

  }
  downloadSelectedImage(rect) {
    const imageObj = new Image();
    const ctx = this.viewer.drawer.canvas.getContext('2d');
    const imageData = ctx.getImageData(rect.x, rect.y, rect.width, rect.height);

    const canvas1 = document.createElement('canvas');
    canvas1.width = rect.width;
    canvas1.height = rect.height;
    const ctx1 = canvas1.getContext('2d');
    ctx1.rect(0, 0, rect.width, rect.height);
    ctx1.fillStyle = 'black';
    ctx1.fill();
    ctx1.putImageData(imageData, 0, 0);
    this.clippedBase64Img = canvas1.toDataURL('image/png');
    this.clippedImageList = localStorage.getItem('croppedImageList') ? JSON.parse(localStorage.getItem('croppedImageList')) : [];
    if (this.setscreenMode === true) {
      $('#fullScreenModal').modal('show');
      $('.modal-backdrop.fade.in').addClass('removeBackdrop');
      $('.backdrop-modal').addClass('activeModal');
    } else if (this.setscreenMode === false) {
      this.modalRef = this.bsModalService.show(this.modalTemplateRef, { class: 'modal-sm large' });
    }
  }
  updateTitle(title: string, notes: string) {
    const currentImageId = this._currentImage['image']['imageId'];
    const originalDocUrl = location.pathname;
    const croppedImageObject = {
      imageName: `${title}`, imageSource: `${this.itemNote}`, image: this.clippedBase64Img,
      originalDocUrl: originalDocUrl, imageId: currentImageId, imageNotes: notes,
      imageCitation: `${this.documentCitation ? this.documentCitation : ''}`
    };
    let croppedImageList = JSON.parse(localStorage.getItem('croppedImageList'));
    if (croppedImageList) {
      if (croppedImageList.length > 0) {
        croppedImageList.push(croppedImageObject);
      } else {
        croppedImageList = [croppedImageObject];
      }
    } else {
      croppedImageList = [croppedImageObject];
    }
    try {
      localStorage.setItem('croppedImageList', JSON.stringify(croppedImageList));
      this.sharedService.setCroppedImageList(croppedImageList);
      this.modalService.showNotification('SUCCESS', `${this.translatedValues.addtoList}`,
        `<span class="docTitle">Clipped From - ${this.itemNote}</span><br/>${this.translatedValues.useToolstoView}`, 7000);
    } catch (error) {
      // tslint:disable-next-line:max-line-length
      this.modalService.showNotification('ERROR', `${this.storageSizeExceeded.StorageSizeExceeded || 'Storage size exceeded'}`, `${this.storageSizeExceeded.clipImageStorageSizeExceeded || 'Storage size exceeded'}`);
    }

    if (this.setscreenMode === true) {
      $('#fullScreenModal').modal('hide');
      $('.modal-backdrop.fade.in').removeClass('removeBackdrop');
      $('.backdrop-modal').removeClass('activeModal');
    } else if (this.setscreenMode === false) {
      this.modalRef.hide();
      $('#fullScreenModal').modal('hide');
      $('.modal-backdrop.fade.in').removeClass('removeBackdrop');
      $('.backdrop-modal').removeClass('activeModal');
    }
    this.selectionPerformed.emit(false);
    this.selection.disable();
    $('.image-viewer-table').removeClass('hide-mouse-action');
    $('.image-photo-highlight').removeClass('hide-mouse-action');
  }
  @Input('selection')
  set _selection(value: any) {
    if (this.viewer) {
      this.setSelection(value);
    }
  }
  get _selection() {
    return this.currentBrightness;
  }

  @Input('image')
  set currentImage(image: any) {
    if (image) {
      this.hideTitle = true;
      this._currentImage = image;
      if (this.viewer) {
        if (this._currentImage) {
          this.loadImageInToCanvas(this._currentImage);
        }
      }
    }
  }
  get currentImage() {
    return this._currentImage;
  }

  @Input('currentOverlay')
  set currentOverlay(currentOverlay: any) {
    if (currentOverlay) {
      this.hideTitle = true;
      // this._currentImage = currentOverlay;
      // this.loadCurrentPageOverlytoFrame(currentOverlay);
    }
    if (this.viewer) {
    }
  }
  get currentOverlay() {
    return this._currentImage;
  }

  @Input('brightness')
  set _brightness(value: any) {
    this.currentBrightness = value === 0 ? 1 : value;
    if (this.viewer) {
      this.adjustBrightness();
    }
  }
  get _brightness() {
    return this.currentBrightness;
  }
  @Input('zoomIn')
  set _zoomIn(value: any) {
    if (this.viewer) {
      this.zoomInImage(0.8);
    }
  }
  @Input('zoomOut')
  set _zoomOut(value: any) {
    if (this.viewer) {
      this.zoomOutImage(0.8);
    }
  }
  @Input('contrast')
  set _contrast(value: any) {
    this.currentContrast = value === 0 ? 1 : value;
    if (this.viewer) {
      this.adjustContrast();
    }
  }
  get _contrast() {
    return this.currentContrast;
  }

  @Input('invert')
  set _invert(value: any) {
    this.isInverted = value;
    if (this.viewer) {
      this.invertImage();
    }
  }
  get _invert() {
    return this.isInverted;
  }

  @Input('fitToWidth')
  set _fitToWidth(flag) {
    this.fitImageToCanvas(flag);
  }
  get _fitToWidth() {
    return this.fitImageToWidth;
  }

  @Input('angle')
  set _angle(value: any) {
    if (this.viewer) {
      this.rotateImage(value);
    }
  }
  get _angle() {
    return this.currentAngle;
  }

  @Input('restoreImage')
  set resetImageFilters(flag: boolean) {
    if (this.viewer) {
      this.viewer.viewport.goHome();
      this.hideTitle = false;
      this.hideOverlay = true;
      this.fitImageToCanvas(false);
    }
  }
  get resetImageFilters() {
    return false;
  }

  @Input('resized')
  set _resize(flag: any) {
    this.isResized = flag;
    if (this.viewer) {
      setTimeout(() => {
        this.resizeCanvas();
      }, 100);
    }
  }
  get _resize() {
    return this.isResized;
  }
  @Input('downloadUrl')
  set urlToDownload(val: any) {
    this.downloadableUrl = val;
  }
  @Input('downloadUrlFor')
  set urlForDownload(val: any) {
    this.downloadUrlFor = val;
  }

  @Input()
  set showOverlayValue(val: any) {
    if (val) {
      setTimeout(() => {
        if (val.status === false) {
          this.hideTitle = false;
        } else {
          this.hideTitle = true;
        }
      }, 2000);
    }
  }

  setSelection(val?: boolean) {
    $('#openopenSeaDragonCanvas').css('cursor', 'crosshair !important');
    $('.custom-textarea').val('');
    if (!val) {
      this.selection.disable();
    } else {
      if (this.selection) {
        this.selection.toggleState();
      } else {
        this.selection = this.viewer.selection({
          startRotated: false,
          showSelectionControl: true,
          rect: null,
          keyboardShortcut: 'c',
          restrictToImage: true,
          allowRotation: false,
          onSelection: (rectCoOrdinates) => {
            const viewportRect = this.viewer.viewport.imageToViewportRectangle(rectCoOrdinates);
            const webRect = this.viewer.viewport.viewportToViewerElementRectangle(viewportRect);
            this.downloadSelectedImage(webRect);
          }
        });
        this.selection.enable();
      }
    }
  }
  loadImageInToCanvas(image: any) {
    // console.log('load image to canvas');
    this.viewer.overlays = [];
    this.imageInstance = image.image;
    const imageData = image.imageData;
    const accessToken = this.httpService.getCurrentAccessToken();
    // this.showLoader();
    if (image) {
      if (this.selection) {
        this.selectionPerformed.emit(false);
        this.selection.disable();
      }
      if (this.documentImages) {
        this.documentImages.forEach(imageObjFromList => {
          if (imageObjFromList.id === image.image.imageId) {
            this.itemNote = imageObjFromList.itemTitle || 'Untitled';
          }
        });
      }
    }
    this.sharedService.relevantPagesCountOberable.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.relevantPagesCount = res;
    });
    this.viewer.overlays = [];
    this.viewer.forceRedraw();

    this.getIllustrationsHighlight(image.image.imageId, this.keywordForSearchTerm);
    this.toggleHighlighter = false;
  }

  adjustBrightness() {
    if (this.currentBrightness > -255 && this.currentBrightness <= 255) {
      this.imageFilters[0] = OpenSeadragon.Filters.BRIGHTNESS(this.currentBrightness);
      this.viewer.setFilterOptions({
        min: -255,
        max: 255,
        filters: {
          processors: this.imageFilters
        }
      });
    }
  }

  adjustContrast() {
    if (this.currentContrast < 0) {
      this.currentContrast = this.currentContrast * -1 / 5;
      this.currentContrast = 1 - this.currentContrast;
      this.currentContrast =
        this.currentContrast === 0 ? 0.1 : this.currentContrast;
    }
    this.imageFilters[1] = OpenSeadragon.Filters.CONTRAST(this.currentContrast);
    this.viewer.setFilterOptions({
      min: 0,
      max: 5,
      filters: {
        processors: this.imageFilters
      }
    });
  }

  fitImageToCanvas(fitToWidth) {
    if (this.viewer) {
      if (fitToWidth) {
        this.viewer.viewport.fitHorizontally(true);
        this.panImageTo(0, 0);
      } else {
        this.panImageTo(0, 0);
        this.viewer.viewport.fitVertically(true);
      }
      this.fitImageToWidth = fitToWidth;
    }
  }

  panImageTo(x, y) {
    const bounds = this.viewer.viewport.getBounds(true);
    const currentRect = this.viewer.viewport.viewportToImageRectangle(bounds);
    const rect = this.viewer.viewport.imageToViewportRectangle(
      x,
      y,
      currentRect.width,
      currentRect.height
    );
    this.viewer.viewport.fitBoundsWithConstraints(rect);
  }

  rotateImage(angle: number) {
    this.viewer.viewport.setRotation(angle * 90);
  }

  invertImage() {
    this.isInverted = !this.isInverted;
    if (this.isInverted) {
      this.imageFilters.pop();
    } else {
      this.imageFilters.push(OpenSeadragon.Filters.INVERT());
    }
    this.viewer.setFilterOptions({
      filters: {
        processors: this.imageFilters
      }
    });
  }

  resizeCanvas() {
    this.fitImageToCanvas(this.fitImageToWidth);
  }
  // showLoader() {
  //   // this.imageviewerframeLoader = true;
  //   this.modalService.showLoader();
  // }
  hideLoader() {
    let highlightTimeOut: any;
    this.hideTitle = false;
    this.timer = 10000;
    // this.imageviewerframeLoader = false;
    // this.modalService.hideLoader();
    this.hideLoaderValue = true;
    this.timeout = 1000;
    highlightTimeOut = setTimeout(() => {
      this.hideHighlight = true;
      this.highlighthide.emit(this.hideHighlight);
    }, this.timeout);
  }
  closeTitle() {
    this.hideTitle = true;
  }
  canvasDragEvent() {
    this.viewer.addHandler('canvas-press', (target, info) => {
      this.canvasXposition = target.position.x;
      this.canvasYposition = target.position.y;
    });
  }
  canvasClickEvent() {
    this.viewer.addHandler('canvas-release', (target, info) => {
      this.currentXposition = target.position.x;
      this.currentYposition = target.position.y;
    });
  }
  zoomInImage(zoomIn) {
    this.currentZoomLevel = parseFloat(this.viewer.viewport.getZoom().toFixed(5));
    this.currentZoomLevel += zoomIn;
    // if (this.currentZoomLevel >= 2) {
    //   this.currentZoomLevel = 2.0;
    // }
    if (this.currentZoomLevel <= 0.2) {
      this.currentZoomLevel = 0.2;
    }
    this.viewer.viewport.zoomTo(this.currentZoomLevel);
  }
  zoomOutImage(zoomOut) {
    this.currentZoomLevel = parseFloat(this.viewer.viewport.getZoom().toFixed(5));
    if (this.currentZoomLevel > 0.2) {
      this.currentZoomLevel -= zoomOut;
    }
    // if (this.currentZoomLevel >= 2) {
    //   this.currentZoomLevel = 2.0;
    // }
    if (this.currentZoomLevel <= 0.2) {
      this.currentZoomLevel = 0.2;
    }
    this.viewer.viewport.zoomTo(this.currentZoomLevel);
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.shiftKey && e.altKey && e.keyCode === 187) {
      this.zoomInImage(0.8);
    } else if (e.shiftKey && e.altKey && e.keyCode === 189) {
      this.zoomOutImage(0.8);
    }
    if (e.shiftKey && e.keyCode === 32) {
      this.hideTitle = !this.hideTitle;
    }
  }
  removeBackDrop() {
    $('.modal-backdrop.fade.in').removeClass('removeBackdrop');
    $('.backdrop-modal').removeClass('activeModal');
  }
  imageViewerMouseEnter() {
    if ($('.image-viewer-table').length > 0) {
      if ($('.image-viewer-table').hasClass('hide-mouse-action')) {
      } else {
        $('#tableOverlayTooltip').show();
      }
    }
    // this.hideTitle = false;
  }
  imageViewerMouseLeave() {
    if ($('.image-viewer-table').length) {
      $('#tableOverlayTooltip').hide();
    }
  }
  hideTitleOverlay() {
    this.hideTitle = true;
    this.hideOverlay = false;
  }
  showTitleOverlay() {
    this.hideTitle = false;
    this.hideOverlay = !this.hideOverlay;
  }

  ngOnDestroy() {
    if (this.unSubIllustrationsOverlays) {
      this.unSubIllustrationsOverlays.unsubscribe();
    }
      this.stop$.next();
      this.stop$.complete();
  }
  toggleHighlight() {
    this.toggleHighlighter = !this.toggleHighlighter;
    if (!this.toggleHighlighter) {
      $('.image-viewer-table').removeClass('hide-mouse-action');
      $('.image-photo-highlight').removeClass('hide-mouse-action');
      $('.image-keyword-highlight').removeClass('hide-keyword-overlay');
    } else {
      $('.image-viewer-table').addClass('hide-mouse-action');
      $('.image-photo-highlight').addClass('hide-mouse-action');
      $('.image-keyword-highlight').addClass('hide-keyword-overlay');
    }
  }

  getIllustrationsHighlight(imageId, keyword?) {
    this.viewer.overlays = null;
    this.searchService.getIllustrationCoordinatesV2(this.currentDocumentId, imageId, this._invokedFrom, (res) => {
      this.tableOverlay = [];
      this.searchTermOverlay = [];
      if (res) {
        if (res.length === 0) {
          this.isHiglightisOn = false;
        }
       // this.showLoader();
        const overlays = Object.keys(res).map(key => ({ key: key, value: res[key] }));
        let newOverlay = {};
        overlays.forEach(element => {
          newOverlay = {
            id: element.value.cc,
            px: element.value.xPos,
            py: element.value.yPos,
            width: element.value.width,
            height: element.value.height,
            rotationMode: OpenSeadragon.OverlayRotationMode.EXACT,
            checkResize: false
          };
          if (element.value.type === 'table') {
            this.isHiglightisOn = true;
            newOverlay['className'] = 'image-viewer-table';
            newOverlay['id'] = element.value.tableId;
            $(document)
              .off()
              .on('click', '.image-viewer-table', event => {
                if (this.currentXposition === this.canvasXposition && this.currentYposition === this.canvasYposition) {
                  this.overlayClickEmitter.emit(event.target.id);
                } else {
                }
              });
          } else if (this._invokedFrom['caller'] === 'IllustrationTab') {
            newOverlay['className'] = 'image-photo-highlight';
            this.isHiglightisOn = true;
          } else {
            newOverlay = null;
            this.isHiglightisOn = false;
          }
          if (newOverlay) {
            this.tableOverlay.push(newOverlay);
          }
        });
        if (keyword) {
          this.getSearchTermOverlay(imageId, keyword);
        } else {
          this.openImage(imageId);
        }
      } else {
        if (keyword) {
          this.getSearchTermOverlay(imageId, keyword);
        } else {
          this.openImage(imageId);
        }
      }
    }, (error) => {
      // this.hideLoader();
      if (keyword) {
        this.getSearchTermOverlay(imageId, keyword);
      } else {
        this.openImage(imageId);
      }
    });
  }

  getSearchTermOverlay (pageNo: any, keyword: any) {
    // this.currentDocumentId = this.activatedRoute.snapshot.params['docID'];
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(param => {
      if (param.hasOwnProperty('docID')) {
        if (param['docID']) {
          this.currentDocumentId = param['docID'];
        }
      }
    });
    this.searchService.getRelevantPages(pageNo, keyword, (response) => {
      this.searchTermOverlay = [];
      if (response) {
        if (response.results) {
          if (response.results.hasOwnProperty('contents')) {
            if (response.results['contents'].length > 0) {
            // if (response.results.pages[0].hasOwnProperty('stringOrSPDetails')) {
              if (Array.isArray(response.results['contents'])) {
                response.results['contents'].forEach(imgObj => {
                  let imgObjHeight = 0;
                  // DESC: Its only for overlay negative co-ordinates height (like height is -78)
                  if (imgObj.height < -1) {
                    imgObjHeight = -(imgObj.height - 120);
                  } else {
                    imgObjHeight = imgObj.height;
                  }
                  const loadSearchTermOverlay =
                  new Overlay( imgObj.width, imgObjHeight, imgObj.hpos, imgObj.vpos, 'keyword', null, imgObj.content, imgObj.cc);
                  const searchTermOverlay = {
                    id: 'cc' + Math.floor(1000 + Math.random() * 9000),
                    px: loadSearchTermOverlay.xPos,
                    py: loadSearchTermOverlay.yPos,
                    width: loadSearchTermOverlay.width,
                    height: loadSearchTermOverlay.height,
                    rotationMode: OpenSeadragon.OverlayRotationMode.EXACT,
                    checkResize: true,
                    className: 'image-keyword-highlight'
                  };
                  // this.viewer.overlays.push(searchTermOverlay);
                  this.searchTermOverlay.push(searchTermOverlay);
                  this.isHiglightisOn = true;
                });
              }
            // }
          }
        }
        }
      }
      this.openImage(pageNo);
    }, !this._isFuzzy, this.currentDocumentId);
  }


  // overlay backup
  // getSearchTermOverlay (pageNo: any, keyword: any) {
  //   this.searchService.getRelevantPages(pageNo, keyword, (response) => {
  //     this.searchTermOverlay = [];
  //     if (response) {
  //       if (response.results) {
  //         if (response.results.hasOwnProperty('pages')) {
  //         if (response.results.pages.length > 0) {
  //           if (response.results.pages[0].hasOwnProperty('stringOrSPDetails')) {
  //             if (Array.isArray(response.results.pages[0]['stringOrSPDetails'])) {
  //               response.results.pages[0]['stringOrSPDetails'].forEach(imgObj => {
  //                 const loadSearchTermOverlay =
  //                 new Overlay( imgObj.width, imgObj.height, imgObj.hpos, imgObj.vpos, 'keyword', null, imgObj.content, imgObj.cc);
  //                 const searchTermOverlay = {
  //                   id: 'cc' + Math.floor(1000 + Math.random() * 9000),
  //                   px: loadSearchTermOverlay.xPos,
  //                   py: loadSearchTermOverlay.yPos,
  //                   width: loadSearchTermOverlay.width,
  //                   height: loadSearchTermOverlay.height,
  //                   rotationMode: OpenSeadragon.OverlayRotationMode.EXACT,
  //                   checkResize: true,
  //                   className: 'image-keyword-highlight'
  //                 };
  //                 // this.viewer.overlays.push(searchTermOverlay);
  //                 this.searchTermOverlay.push(searchTermOverlay);
  //                 this.isHiglightisOn = true;
  //               });
  //             }
  //           }
  //         }
  //       }
  //       }
  //     }
  //     this.openImage(pageNo);
  //   }, !this._isFuzzy, this.activatedRoute.snapshot.params['docID']);
  // }

  openImage(imageId?): void {
    this.viewer.overlays = [];
    this.viewer.addHandler('open', () => {
      // this.viewer.drawer.addOverlay({
      //   element: "svg1",
      //   location: viewer.viewport.imageToViewportRectangle(
      //     new OpenSeadragon.Rect(42156, 79352, 2263, 2590))
      // });
      // this.viewer.overlays = [];
      // console.log(this.viewer.overlays, this.tableOverlay, this.searchTermOverlay);
      // // setTimeout(() => {
      // this.tableOverlay.forEach(element => {
      //   this.viewer.overlays.push(element);
      // });
      // this.searchTermOverlay.forEach(element => {
      //   this.viewer.overlays.push(element);
      // });
    });
    if (isArray(this.tableOverlay)) {
      if (this.tableOverlay.length > 0) {
        this.isHiglightisOn = true;
      }
      this.tableOverlay.forEach(element => {
        this.viewer.overlays.push(element);
      });
    }
    if (isArray(this.searchTermOverlay)) {
      if (this.searchTermOverlay.length > 0) {
        this.isHiglightisOn = true;
      }
      this.searchTermOverlay.forEach(element => {
        this.viewer.overlays.push(element);
      });
    }
    if (this.searchTermOverlay.length === 0 && this.tableOverlay.length === 0) {
      this.isHiglightisOn = false;
    } else {
      this.isHiglightisOn = true;
    }
      const acToken = this.httpService.getCurrentAccessToken();


    // Starting IIIF Images
      // console.log(this.imageInstance.imageURL);

      setTimeout(() => {
        // this.showLoader();
        // this.modalService.showLom ,ader();
      });
      let location = localStorage.getItem('currentLocation') || environment.defaultLocation;
      if (location) {
        location = location.trim();
      }
      if (this.currentDocumentType === 'Maps') {
        const documentId = this.currentDocumentId || this.defaultImageToBeLoaded.docId;
        const tileSourceURL = environment.APIUrl + APIURLS['tileSourceServer'] + '/'  + this.currentDocumentId + '/' +
          this.imageInstance.imageId + '.jpg?WDALoc=' + location + '&access_token=' + acToken + '&_';
        let mapImageWidth = null;
        let mapImageHeight = null;
        let tilesourceID = null;
        if (documentId !== '') {
          this.httpService.get(environment.APIUrl + APIURLS['tileSourceServer'] + '/'
                                        + this.currentDocumentId + '/'
            + this.imageInstance.imageId + '.jpg/info.json?WDALoc=' + location + '&access_token=' + acToken,
                                        (tilesourceResponse) => {
            mapImageWidth = tilesourceResponse['width'];
            mapImageHeight = tilesourceResponse['height'];
            tilesourceID = tilesourceResponse['@id'];
            this.viewer.tileSources = [{
              '@context': 'http://iiif.io/api/image/2/context.json',
              '@id': tileSourceURL + '?access_token=' + acToken,
              // '@id': 'http://ec2-18-212-209-3.compute-1.amazonaws.com/test/tiles/' + imgPath,
              'height': mapImageHeight,
              'width': mapImageWidth,
              'profile': ['http://iiif.io/api/image/2/level2.json'],
              'protocol': 'http://iiif.io/api/image',
              'tiles': [{
                  'scaleFactors': [0.25, 0.5, 1, 2, 4, 8, 16, 32],
                  'width': 512
              }],
            }];

            this.viewer.open(this.viewer.tileSources);
          }, (error) => {
            this.hideLoader();
            this.viewer.open({type: 'image', url: this.imageInstance.imageURL + '?access_token=' + acToken + '&WDALoc=' + location });
          }, 'json', null, null, true, {'key': 'accessToken', 'value': acToken });
        }
      } else {
        this.viewer.open({type: 'image', url: this.imageInstance.imageURL + '?access_token=' + acToken + '&WDALoc=' + location });
      }
  }


}
