
import { takeUntil} from 'rxjs/operators';
import { Component, AfterViewInit, OnDestroy, TemplateRef, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionLike as ISubscription ,  Subject } from 'rxjs';
import { PageSettingsService } from './../common/services/page-settings.service';
import { SearchService } from './../common/services/search.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { UrlUtilityService } from './../common/services/url-utility.service';
import { environment, APIURLS } from '../../environments/environment';
import { HttpService } from './../common/services/http.service';
import { map as _map } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../common/services/modal.service';
import { SharedService } from '../common/services/sharedService';
import { ComponentLoaderService } from '../common/services/component-loader.service';
import { orderBy as _orderBy } from 'lodash';
declare var $: any;

@Component({
	selector: 'app-wiley-search-result-wrapper',
	templateUrl: './wiley-search-result-wrapper.component.html',
	styleUrls: ['./wiley-search-result-wrapper.component.css'],
	preserveWhitespaces: false
})
export class WileySearchResultWrapperComponent implements AfterViewInit, OnDestroy, OnInit {
	private stop$: Subject<any> = new Subject();
	searchWithInTerm: any;
	activePage: any;
	// @ViewChild('documentViewerPageTemplate') private documentViewerPageTemplate: TemplateRef<any>;
	@ViewChild('documentViewerPageTemplate', { static: false }) private documentViewerPageTemplate: TemplateRef<any>;

	private documentViewerPagemodalRef: any;
	pageLoadinFailed: string;
	docIdForDocViewer: any;
	illustrationData: any[];
	mapViewToggle: any;
	enableSingleList: any;
	isDocViewerOpen = false;
	// private navObservable: any;
	isInMapView: any;
	pageSettings;
	// private pageSettingsObservable: Subscription;
	// private paramObservable: Subscription;
	private personalizationObject: any;
	toggleHeader: Boolean = false;
	contentPhotoSubscription: ISubscription;
	contentMapSubscription: ISubscription;
	isPhotograph: Boolean = false;
	isMap: Boolean = false;
	contentTypeOrder: any[];
	// @ViewChild('detailsDynamicContainer', { read: ViewContainerRef }) entry: ViewContainerRef;
	@ViewChild('detailsDynamicContainer', { static: false, read: ViewContainerRef }) entry: ViewContainerRef;

	docViewerMode: Boolean = false;
	// _routerSub = Subscription.EMPTY;
	constructor(
		private pageSettingService: PageSettingsService,
		private searchService: SearchService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private urlUtilityService: UrlUtilityService,
		private httpService: HttpService,
		private sharedService: SharedService,
		private modalService: ModalService,
		private translate: TranslateService,
		private componentLoaderService: ComponentLoaderService
	) {
		let filterParams = null;
		let displayParams = null;
		PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
			this.pageSettings = data;
			this.pageSettings.availableFields = _orderBy(this.pageSettings.availableFields, ['itemName'], ['asc']);
		});
		this.sharedService.setSearchLoading(true);
		// this._routerSub = this.router.events.subscribe((event: NavigationEvent) => {
			this.pageSettings.currentSelectedArchives = localStorage.getItem('wileySelectedArchive')
			this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
				if (params['filter']) {
					filterParams = params['filter'];
				}
				if (params['display']) {
					displayParams = params['display'];
					this.pageSettingService.updateSetting('groupsToBeDisplayed', [params['display']]);
				}
				if (params['order']) {
					this.contentTypeOrder = params['order'];
				}
				if (filterParams) {
					this.urlUtilityService.parseUrl(filterParams).pipe(takeUntil(this.stop$)).subscribe((data) => {
						this.pageSettings.currentSelectedArchives = data['archive'];
						filterParams = new AdvancedFilterParams(data);
						this.pageSettings.searchTerm = params['searchTerm'];
						this.pageSettingService.updateSearchSettings(filterParams);
						const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
						if (personalizationV2) {
							// this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
							// PageSettingsService.pageSettings.currentLocation, (response) => {
								this.personalizationObject = personalizationV2;
								const archiveNames = [];
								this.personalizationObject.archives.forEach(element => {
									if (this.pageSettings.currentArchive === 'RGS') {
										if (element.shortName === 'RGS1') {
											archiveNames.push(element.shortName);
										}
										if (element.shortName === 'RGS2') {
											archiveNames.push(element.shortName);
										}
									} else if (this.pageSettings.currentArchive === 'RCP') {
										if (element.shortName === 'RCP1') {
											archiveNames.push(element.shortName);
										}
										if (element.shortName === 'RCP2') {
											archiveNames.push(element.shortName);
										}
									} else {
										archiveNames.push(element.shortName);
									}
								});
								let hasWrongArchive = false;
								let notSubscribedArchive = false;
								this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
									if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
										if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
											hasWrongArchive = true;
										}
									} else {
										if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
											if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
												if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {
													if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
													}
												}
											}
										}
									}
									return !hasWrongArchive && !notSubscribedArchive;
								});
								if (this.pageSettings.currentSelectedArchives.length === 0) {
									if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
										this.pageSettings.currentSelectedArchives = archiveNames;
									} else {
										if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' ||
											(this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
											this.pageSettings.currentSelectedArchives = archiveNames;
										} else {
											this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
										}
									}
									filterParams['archive'] = this.pageSettings.currentSelectedArchives;
									this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
								} else {
									if (hasWrongArchive || notSubscribedArchive) {
										filterParams['archive'] = this.pageSettings.currentSelectedArchives;
										this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
									} else {
										if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
											filterParams['archive'] = archiveNames;
										}
										this.searchService.advancedSearch(filterParams, null);
									}
								}

						// 	}, (error) => {

						// });
						} else {
							this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
							PageSettingsService.pageSettings.currentLocation, (response) => {
								this.personalizationObject = response;
								const archiveNames = [];
								localStorage.setItem('personalizationData', JSON.stringify(response));
								this.personalizationObject.archives.forEach(element => {
									if (this.pageSettings.currentArchive === 'RGS') {
										if (element.shortName === 'RGS1') {
											archiveNames.push(element.shortName);
										}
										if (element.shortName === 'RGS2') {
											archiveNames.push(element.shortName);
										}
									} else if (this.pageSettings.currentArchive === 'RCP') {
										if (element.shortName === 'RCP1') {
											archiveNames.push(element.shortName);
										}
										if (element.shortName === 'RCP2') {
											archiveNames.push(element.shortName);
										}
									} else {
										archiveNames.push(element.shortName);
									}
									// archiveNames.push(element.shortName);
								});
								let hasWrongArchive = false;
								let notSubscribedArchive = false;
								this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
									if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
										if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
											hasWrongArchive = true;
										}
									} else {
										if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
											if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
												if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {

													if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
													} else {
														// notSubscribedArchive = true;
													}
												}
											}
										}
									}
									return !hasWrongArchive && !notSubscribedArchive;
								});
								if (this.pageSettings.currentSelectedArchives.length === 0) {
									if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
										this.pageSettings.currentSelectedArchives = archiveNames;
									} else {
										if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' ||
											(this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
											this.pageSettings.currentSelectedArchives = archiveNames;
										} else {
											this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
										}
									}
									filterParams['archive'] = this.pageSettings.currentSelectedArchives;
									this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
								} else {
									if (hasWrongArchive || notSubscribedArchive) {
										filterParams['archive'] = this.pageSettings.currentSelectedArchives;
										this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
									} else {
										// filterParams['archive'] = [];
										if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
											filterParams['archive'] = archiveNames;
										}
										this.searchService.advancedSearch(filterParams, null);
									}
								}

							}, (error) => {

							});
						}
					});
				} else {
					this.pageSettings.searchTerm = params['searchTerm'];
					this.pageSettings.currentSelectedArchives = params['selectedArchives'] ? params['selectedArchives'].split(',') : [];
					const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
					if (personalizationV2) {
						// this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
						// PageSettingsService.pageSettings.currentLocation, (response) => {
							this.personalizationObject = personalizationV2;
							const archiveNames = [];
							personalizationV2.archives.forEach(element => {
								if (this.pageSettings.currentArchive === 'RGS') {
									if (element.shortName === 'RGS1') {
										archiveNames.push(element.shortName);
									}
									if (element.shortName === 'RGS2') {
										archiveNames.push(element.shortName);
									}
								} else if (this.pageSettings.currentArchive === 'RCP') {
									if (element.shortName === 'RCP1') {
										archiveNames.push(element.shortName);
									}
									if (element.shortName === 'RCP2') {
										archiveNames.push(element.shortName);
									}
								} else {
									archiveNames.push(element.shortName);
								}
							});

							let hasWrongArchive = false;
							let notSubscribedArchive = false;
							this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
									if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
										hasWrongArchive = true;
									}
								} else {
									if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
										if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
											if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {
												if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
												} else {
													// notSubscribedArchive = true;
												}
											}
										}
									}
								}
								return !hasWrongArchive && !notSubscribedArchive;
							});

							if (this.pageSettings.currentSelectedArchives.length === 0) {

								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
									this.pageSettings.currentSelectedArchives = archiveNames;
								} else {
									if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' || (this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
										this.pageSettings.currentSelectedArchives = archiveNames;
									} else {
										this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
									}
								}
								this.pageSettingService.updatePageSettings(this.pageSettings);
								this.searchService.goToSearchPage(this.pageSettings.searchTerm);
							} else {
								if (hasWrongArchive || notSubscribedArchive) {
									this.pageSettingService.updatePageSettings(this.pageSettings);
									this.searchService.goToSearchPage(this.pageSettings.searchTerm);
								} else {
									// tslint:disable-next-line:max-line-length
									this.searchService.search(this.pageSettings.searchTerm ? this.pageSettings.searchTerm : '', this.pageSettings.currentSelectedArchives, null, displayParams);
								}
							}
					// 	}, (error) => {
					// });
					} else {
						this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
						PageSettingsService.pageSettings.currentLocation, (response) => {
							this.personalizationObject = response;
							const archiveNames = [];
							localStorage.setItem('personalizationData', JSON.stringify(response));
							response.archives.forEach(element => {
								if (this.pageSettings.currentArchive === 'RGS') {
									if (element.shortName === 'RGS1') {
										archiveNames.push(element.shortName);
									}
									if (element.shortName === 'RGS2') {
										archiveNames.push(element.shortName);
									}
								} else if (this.pageSettings.currentArchive === 'RCP') {
									if (element.shortName === 'RCP1') {
										archiveNames.push(element.shortName);
									}
									if (element.shortName === 'RCP2') {
										archiveNames.push(element.shortName);
									}
								} else {
									archiveNames.push(element.shortName);
								}
							});

							let hasWrongArchive = false;
							let notSubscribedArchive = false;
							this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
									if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
										hasWrongArchive = true;
									}
								} else {
									if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
										if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
											if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {
												if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
												} else {
													// notSubscribedArchive = true;
												}
											}
										}
									}
								}
								return !hasWrongArchive && !notSubscribedArchive;
							});

							if (this.pageSettings.currentSelectedArchives.length === 0) {

								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
									this.pageSettings.currentSelectedArchives = archiveNames;
								} else {
									if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' || (this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
										this.pageSettings.currentSelectedArchives = archiveNames;
									} else {
										this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
									}
								}
								this.pageSettingService.updatePageSettings(this.pageSettings);
								this.searchService.goToSearchPage(this.pageSettings.searchTerm);
							} else {
								if (hasWrongArchive || notSubscribedArchive) {
									this.pageSettingService.updatePageSettings(this.pageSettings);
									this.searchService.goToSearchPage(this.pageSettings.searchTerm);
								} else {
									// tslint:disable-next-line:max-line-length
									this.searchService.search(this.pageSettings.searchTerm ? this.pageSettings.searchTerm : '', this.pageSettings.currentSelectedArchives, null, displayParams);
								}
							}
						}, (error) => {
						});
					}
				}
			});
    	// });

		// this._routerSub = this.router.events.pipe(takeUntil(this.stop$)).subscribe((events) => {
		// 	console.log(events)
		// 	console.log(this.activatedRoute)
		// 	this.pageSettings.currentSelectedArchives = localStorage.getItem('wileySelectedArchive')
		// 	if (events instanceof NavigationEnd) {
		// 		this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
		// 			if (params['filter']) {
		// 				filterParams = params['filter'];
		// 			}
		// 			if (params['display']) {
		// 				displayParams = params['display'];
		// 				this.pageSettingService.updateSetting('groupsToBeDisplayed', [params['display']]);
		// 			}
		// 			if (params['order']) {
		// 				this.contentTypeOrder = params['order'];
		// 			}
		// 			if (filterParams) {
		// 				this.urlUtilityService.parseUrl(filterParams).pipe(takeUntil(this.stop$)).subscribe((data) => {
		// 					this.pageSettings.currentSelectedArchives = data['archive'];
		// 					filterParams = new AdvancedFilterParams(data);
		// 					this.pageSettings.searchTerm = params['searchTerm'];
		// 					this.pageSettingService.updateSearchSettings(filterParams);
		// 					const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
		// 					if (personalizationV2) {
		// 						// this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
		// 						// PageSettingsService.pageSettings.currentLocation, (response) => {
		// 							this.personalizationObject = personalizationV2;
		// 							const archiveNames = [];
		// 							this.personalizationObject.archives.forEach(element => {
		// 								if (this.pageSettings.currentArchive === 'RGS') {
		// 									if (element.shortName === 'RGS1') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 									if (element.shortName === 'RGS2') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 								} else if (this.pageSettings.currentArchive === 'RCP') {
		// 									if (element.shortName === 'RCP1') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 									if (element.shortName === 'RCP2') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 								} else {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 							});
		// 							let hasWrongArchive = false;
		// 							let notSubscribedArchive = false;
		// 							this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
		// 								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 									if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
		// 										hasWrongArchive = true;
		// 									}
		// 								} else {
		// 									if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
		// 										if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
		// 											if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {
		// 												if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
		// 												}
		// 											}
		// 										}
		// 									}
		// 								}
		// 								return !hasWrongArchive && !notSubscribedArchive;
		// 							});
		// 							if (this.pageSettings.currentSelectedArchives.length === 0) {
		// 								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 									this.pageSettings.currentSelectedArchives = archiveNames;
		// 								} else {
		// 									if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' ||
		// 										(this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
		// 										this.pageSettings.currentSelectedArchives = archiveNames;
		// 									} else {
		// 										this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
		// 									}
		// 								}
		// 								filterParams['archive'] = this.pageSettings.currentSelectedArchives;
		// 								this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
		// 							} else {
		// 								if (hasWrongArchive || notSubscribedArchive) {
		// 									filterParams['archive'] = this.pageSettings.currentSelectedArchives;
		// 									this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
		// 								} else {
		// 									if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
		// 										filterParams['archive'] = archiveNames;
		// 									}
		// 									this.searchService.advancedSearch(filterParams, null);
		// 								}
		// 							}

		// 					// 	}, (error) => {

		// 					// });
		// 					} else {
		// 						this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
		// 						PageSettingsService.pageSettings.currentLocation, (response) => {
		// 							this.personalizationObject = response;
		// 							const archiveNames = [];
		// 							localStorage.setItem('personalizationData', JSON.stringify(response));
		// 							this.personalizationObject.archives.forEach(element => {
		// 								if (this.pageSettings.currentArchive === 'RGS') {
		// 									if (element.shortName === 'RGS1') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 									if (element.shortName === 'RGS2') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 								} else if (this.pageSettings.currentArchive === 'RCP') {
		// 									if (element.shortName === 'RCP1') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 									if (element.shortName === 'RCP2') {
		// 										archiveNames.push(element.shortName);
		// 									}
		// 								} else {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 								// archiveNames.push(element.shortName);
		// 							});
		// 							let hasWrongArchive = false;
		// 							let notSubscribedArchive = false;
		// 							this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
		// 								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 									if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
		// 										hasWrongArchive = true;
		// 									}
		// 								} else {
		// 									if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
		// 										if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
		// 											if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {

		// 												if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
		// 												} else {
		// 													// notSubscribedArchive = true;
		// 												}
		// 											}
		// 										}
		// 									}
		// 								}
		// 								return !hasWrongArchive && !notSubscribedArchive;
		// 							});
		// 							if (this.pageSettings.currentSelectedArchives.length === 0) {
		// 								if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 									this.pageSettings.currentSelectedArchives = archiveNames;
		// 								} else {
		// 									if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' ||
		// 										(this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
		// 										this.pageSettings.currentSelectedArchives = archiveNames;
		// 									} else {
		// 										this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
		// 									}
		// 								}
		// 								filterParams['archive'] = this.pageSettings.currentSelectedArchives;
		// 								this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
		// 							} else {
		// 								if (hasWrongArchive || notSubscribedArchive) {
		// 									filterParams['archive'] = this.pageSettings.currentSelectedArchives;
		// 									this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, filterParams);
		// 								} else {
		// 									// filterParams['archive'] = [];
		// 									if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
		// 										filterParams['archive'] = archiveNames;
		// 									}
		// 									this.searchService.advancedSearch(filterParams, null);
		// 								}
		// 							}

		// 						}, (error) => {

		// 						});
		// 					}
		// 				});
		// 			} else {
		// 				this.pageSettings.searchTerm = params['searchTerm'];
		// 				this.pageSettings.currentSelectedArchives = params['selectedArchives'] ? params['selectedArchives'].split(',') : [];
		// 				const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
		// 				if (personalizationV2) {
		// 					// this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
		// 					// PageSettingsService.pageSettings.currentLocation, (response) => {
		// 						this.personalizationObject = personalizationV2;
		// 						const archiveNames = [];
		// 						personalizationV2.archives.forEach(element => {
		// 							if (this.pageSettings.currentArchive === 'RGS') {
		// 								if (element.shortName === 'RGS1') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 								if (element.shortName === 'RGS2') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 							} else if (this.pageSettings.currentArchive === 'RCP') {
		// 								if (element.shortName === 'RCP1') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 								if (element.shortName === 'RCP2') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 							} else {
		// 								archiveNames.push(element.shortName);
		// 							}
		// 						});

		// 						let hasWrongArchive = false;
		// 						let notSubscribedArchive = false;
		// 						this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
		// 							if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 								if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
		// 									hasWrongArchive = true;
		// 								}
		// 							} else {
		// 								if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
		// 									if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
		// 										if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {
		// 											if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
		// 											} else {
		// 												// notSubscribedArchive = true;
		// 											}
		// 										}
		// 									}
		// 								}
		// 							}
		// 							return !hasWrongArchive && !notSubscribedArchive;
		// 						});

		// 						if (this.pageSettings.currentSelectedArchives.length === 0) {

		// 							if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 								this.pageSettings.currentSelectedArchives = archiveNames;
		// 							} else {
		// 								if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' || (this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
		// 									this.pageSettings.currentSelectedArchives = archiveNames;
		// 								} else {
		// 									this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
		// 								}
		// 							}
		// 							this.pageSettingService.updatePageSettings(this.pageSettings);
		// 							this.searchService.goToSearchPage(this.pageSettings.searchTerm);
		// 						} else {
		// 							if (hasWrongArchive || notSubscribedArchive) {
		// 								this.pageSettingService.updatePageSettings(this.pageSettings);
		// 								this.searchService.goToSearchPage(this.pageSettings.searchTerm);
		// 							} else {
		// 								// tslint:disable-next-line:max-line-length
		// 								this.searchService.search(this.pageSettings.searchTerm ? this.pageSettings.searchTerm : '', this.pageSettings.currentSelectedArchives, null, displayParams);
		// 							}
		// 						}
		// 				// 	}, (error) => {
		// 				// });
		// 				} else {
		// 					this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
		// 					PageSettingsService.pageSettings.currentLocation, (response) => {
		// 						this.personalizationObject = response;
		// 						const archiveNames = [];
		// 						localStorage.setItem('personalizationData', JSON.stringify(response));
		// 						response.archives.forEach(element => {
		// 							if (this.pageSettings.currentArchive === 'RGS') {
		// 								if (element.shortName === 'RGS1') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 								if (element.shortName === 'RGS2') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 							} else if (this.pageSettings.currentArchive === 'RCP') {
		// 								if (element.shortName === 'RCP1') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 								if (element.shortName === 'RCP2') {
		// 									archiveNames.push(element.shortName);
		// 								}
		// 							} else {
		// 								archiveNames.push(element.shortName);
		// 							}
		// 						});

		// 						let hasWrongArchive = false;
		// 						let notSubscribedArchive = false;
		// 						this.pageSettings.currentSelectedArchives = this.pageSettings.currentSelectedArchives.filter((archive) => {
		// 							if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 								if (archiveNames.indexOf(archive) === -1 && archiveNames.indexOf(archive.toUpperCase()) === -1) {
		// 									hasWrongArchive = true;
		// 								}
		// 							} else {
		// 								if (this.pageSettings.currentArchive.toUpperCase() !== 'RGS') {
		// 									if (this.pageSettings.currentArchive.toUpperCase() !== 'RCP') {
		// 										if ((archive).toUpperCase() !== (this.pageSettings.currentArchive).toUpperCase()) {
		// 											if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf(this.pageSettings.currentArchive) > -1) {
		// 											} else {
		// 												// notSubscribedArchive = true;
		// 											}
		// 										}
		// 									}
		// 								}
		// 							}
		// 							return !hasWrongArchive && !notSubscribedArchive;
		// 						});

		// 						if (this.pageSettings.currentSelectedArchives.length === 0) {

		// 							if ((this.pageSettings.currentArchive).toUpperCase() === 'WILEY') {
		// 								this.pageSettings.currentSelectedArchives = archiveNames;
		// 							} else {
		// 								if ((this.pageSettings.currentArchive).toUpperCase() === 'RGS' || (this.pageSettings.currentArchive).toUpperCase() === 'RCP') {
		// 									this.pageSettings.currentSelectedArchives = archiveNames;
		// 								} else {
		// 									this.pageSettings.currentSelectedArchives = [this.pageSettings.currentArchive];
		// 								}
		// 							}
		// 							this.pageSettingService.updatePageSettings(this.pageSettings);
		// 							this.searchService.goToSearchPage(this.pageSettings.searchTerm);
		// 						} else {
		// 							if (hasWrongArchive || notSubscribedArchive) {
		// 								this.pageSettingService.updatePageSettings(this.pageSettings);
		// 								this.searchService.goToSearchPage(this.pageSettings.searchTerm);
		// 							} else {
		// 								// tslint:disable-next-line:max-line-length
		// 								this.searchService.search(this.pageSettings.searchTerm ? this.pageSettings.searchTerm : '', this.pageSettings.currentSelectedArchives, null, displayParams);
		// 							}
		// 						}
		// 					}, (error) => {
		// 					});
		// 				}
		// 			}
		// 		});
		// 	}
		// });

		this.sharedService.isDocViewerShow.pipe(takeUntil(this.stop$)).subscribe(res => {
			if (this.docViewerMode) {
				this.docViewerMode = false;
				this.componentLoaderService.removeComponent();
			}
		});
	}
	ngOnInit() {
		const contentType = sessionStorage.getItem('selectedContentType');
		if (contentType) {
			if (contentType === 'Maps') {
				this.isMap = true;
			} else if (contentType === 'Photographs') {
				this.isPhotograph = true;
			}
		} else {
			this.sharedService.iscontentTypePhoto.pipe(takeUntil(this.stop$)).subscribe(message => this.isPhotograph = message);
			this.sharedService.iscontentTypeMap.pipe(takeUntil(this.stop$)).subscribe(message => this.isMap = message);
		}
		$('body').trigger('click');
	}
	openDocViewer(event) {
		// this.modalService.hideLoader();
		this.docIdForDocViewer = event;
		const inputObjects = {
			'docIdForDocViewer': this.docIdForDocViewer,
			'selected-page': this.activePage,
			'searchTerm': event.searchTerm,
			'illustrationData': this.illustrationData,
			'invokedFromTab': event.invokedFrom,
			'loadedFrom': 'wrapper',
			'spatialFilterParams': event.spatialFilterParams,
			'isFromResultsPage': event.isFromResultsPage,
			'start': event.start,
			'advancedSearchTerm': event.advancedSearchKeyword
		};
		this.componentLoaderService.addDynamicComponent(inputObjects, this.entry);
		this.isDocViewerOpen = true;
	}

	openDocViewerV1_old(event) {
		this.isDocViewerOpen = true;
		this.docIdForDocViewer = event;
		const loadDoc = (illustrations) => {
			if (this.docIdForDocViewer['docId']) {
				this.searchService.getDocumentDetail(this.docIdForDocViewer['docId'], (response) => {
					if (response) {
						const inputObjects = {
							'docIdForDocViewer': this.docIdForDocViewer,
							'selected-page': this.activePage,
							'searchTerm': event.searchTerm,
							'illustrationData': illustrations,
							'documentData': response,
							'invokedFromTab': event.invokedFrom,
							'loadedFrom': 'wrapper',
							'spatialFilterParams': event.spatialFilterParams,
							'isFromResultsPage': event.isFromResultsPage
						};
						this.isDocViewerOpen = true;
						this.docIdForDocViewer = event;
						this.componentLoaderService.addDynamicComponent(inputObjects, this.entry);
						setTimeout(() => {
							/*
							* @DESC: for doc daa:  load data after loading the dynamic conponent
							* @Input: document Data
							TODO : need to check purpose of this shared service
							*/
							response['start'] = event.start;
							response['spatialFilterParams'] = event.spatialFilterParams;
							response['isFromResultsPage'] = event.isFromResultsPage;
							this.sharedService.shareDocumentDataToSearchDetails(response);
						}, 5000);
					} else {
						this.modalService.showAlertPopup('Alert', this.pageLoadinFailed, null);
					}
				});
			}

			$('html, body').animate({ scrollTop: 0 }, 'slow');
			if (this.componentLoaderService.coverFlowDocumentId) {
				this.componentLoaderService.coverFlowDocumentId.subscribe((res) => {
					if (res) {
						this.loadDocFromCoverFlow(res);
					}
				});
			}
		};
		// this.searchService.getDocumentItembyType(this.docIdForDocViewer['docId'], response => {
		this.searchService.getIllustraion(this.docIdForDocViewer['docId'], res => {
			const tableOfContent = res;
			const parentNodes = [];
			if (tableOfContent) {
				const contents = Object.keys(tableOfContent);
				contents.forEach((content, parentIndex) => {
					const childNodes = [];
					if (
						Array.isArray(tableOfContent[content]) &&
						tableOfContent[content].length > 0
					) {
						tableOfContent[content].forEach((child, childIndex) => {
							const grandChildNodes = [];
							if (child.caption === null) {
								child.caption = 'Untitled';
							}
							childNodes.push({
								name: child.caption,
								children: grandChildNodes,
								tableId: child.tableId ? child.tableId : '',
								pageId: child.pageId ? child.pageId : '',
								docId: this.docIdForDocViewer['docId'] ? this.docIdForDocViewer['docId'] : '',
								width: child.width ? child.width : '',
								height: child.height ? child.height : '',
								hpos: child.hpos ? child.hpos : '',
								vpos: child.vpos ? child.vpos : ''
							});
						});
					}
					parentNodes.push({
						name: content,
						children: childNodes
					});
					this.illustrationData = parentNodes;
				});
			}
			loadDoc(this.illustrationData);
		}, err => {
			loadDoc(null);
		});
		// });
	}

	openDocViewerV1(event) {
		this.docIdForDocViewer = event;
		const inputObjects = event;
		this.componentLoaderService.addDynamicComponent(inputObjects, this.entry);
		this.docViewerMode = true;
		this.isDocViewerOpen = true;
		// this.modalService.hideLoader();
	}
	closedocumentViewerPageTemplate() {
		this.documentViewerPagemodalRef.hide();
	}

	mapRedir(event) {
		this.isInMapView = event;
	}
	mapToggle(event) {
		this.mapViewToggle = event;
	}
	singleListModeEnabled(event) {
		this.enableSingleList = event;
	}
	ngAfterViewInit() {
		this.translate.get('Common.Popups.pageLoadinFailed').subscribe((res: string) => {
			this.pageLoadinFailed = res;
		});
		if (!sessionStorage.getItem('LefttMenuStatus')) {
			sessionStorage.setItem('LefttMenuStatus', 'true');
		}
		setTimeout(() => {
			this.checkArchiveAccess();
		}, 500);
		this.sharedService.headerMenu.pipe(takeUntil(this.stop$)).subscribe((changes) => {
			if (changes) {
				if (changes === 'true') {
					this.toggleHeader = true;
				} else {
					this.toggleHeader = false;
				}
			} else {
				this.toggleHeader = sessionStorage.getItem('wileyShowTopBar') === 'true' ? true : false;
			}
		});
	}

	// checkArchiveAccess() {
	// 		let archiveFound = false;
	// 		if (this.personalizationObject) {
	// 			const customerSubscribedArchives = _map(this.personalizationObject.archives, 'shortName');
	// 			if (customerSubscribedArchives.indexOf(this.pageSettings.currentArchive.toUpperCase()) !== -1) {
	// 				archiveFound = true;
	// 			} else {
	// 				archiveFound = false;
	// 			}
	// 			// this.personalizationObject.archives.forEach(archive => {
	// 			// if (archive.shortName === this.pageSettings.currentArchive.toUpperCase()) {
	// 			// 	archiveFound = true;
	// 			// }else {
	// 			// 	archiveFound = false;
	// 			// }
	// 			// });
	// 		} else {
	// 			archiveFound = true;
	// 		}

	// 		if (this.pageSettings.currentArchive.toUpperCase() !== 'WILEY') {
	// 			if (!archiveFound) {
	// 				let homePageUrl = '/';
	// 				homePageUrl += PageSettingsService.pageSettings.currentArchive;
	// 				homePageUrl += '/location/';
	// 				homePageUrl += PageSettingsService.pageSettings.currentLocation;
	// 				this.router.navigate([homePageUrl + '/unauthorized-page']);
	// 			}
	// 		}
	// 	}
	checkArchiveAccess() {
		let archiveFound = false;
		const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
		if (personalizationV2) {
			// this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
			// PageSettingsService.pageSettings.currentLocation, (response) => {
				const customerSubscribedArchives = _map(personalizationV2.archives, 'shortName');
				if (customerSubscribedArchives.indexOf(PageSettingsService.pageSettings.currentArchive.toUpperCase()) !== -1) {
					archiveFound = true;
				} else {
					archiveFound = false;
				}
				if (PageSettingsService.pageSettings.currentArchive.toUpperCase() !== 'WILEY' &&
					window.location.pathname.split('/')[1].toUpperCase() !== 'RGS') {
					if (window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
						if (!archiveFound) {
							let homePageUrl = '/';
							homePageUrl += PageSettingsService.pageSettings.currentArchive;
							this.router.navigate([homePageUrl + '/unauthorized-page']);
						}
					}
				}
		// }, error => { });
		} else {
			this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] +
			PageSettingsService.pageSettings.currentLocation, (response) => {
				localStorage.setItem('personalizationData', JSON.stringify(response));
				const customerSubscribedArchives = _map(response.archives, 'shortName');
				if (customerSubscribedArchives.indexOf(PageSettingsService.pageSettings.currentArchive.toUpperCase()) !== -1) {
					archiveFound = true;
				} else {
					archiveFound = false;
				}

				if (PageSettingsService.pageSettings.currentArchive.toUpperCase() !== 'WILEY' &&
					window.location.pathname.split('/')[1].toUpperCase() !== 'RGS') {
					if (window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
						if (!archiveFound) {
							let homePageUrl = '/';
							homePageUrl += PageSettingsService.pageSettings.currentArchive;
							// homePageUrl += '/location/';
							// homePageUrl += PageSettingsService.pageSettings.currentLocation;
							this.router.navigate([homePageUrl + '/unauthorized-page']);
						}
					}
				}
			}, error => { });
		}
	}
	activePageChanged(page) {
		this.activePage = page;
		// this.sharedService.shareImageViewerHighlight(page);
	}
	onSearchTermChange(event) {
		this.searchWithInTerm = event;
	}
	afterIllustrationTreeInit(event) { }
	docViewerClosed(event: any) {
		this.isDocViewerOpen = event.value;
		this.docIdForDocViewer.isDocViewerOpen = event.value;
		this.componentLoaderService.removeComponent();
	}

	loadDocFromCoverFlow(documentId) {
		this.openDocViewer({
			'docId': documentId, 'random': Math.random(),
			'isDocViewerShow': true, 'pageId': '', 'currentOffset': '',
			'invokedFrom': 'wrapper', 'isFromResultsPage': true
		});
	}
	ngOnDestroy() {
		this.stop$.next();
		this.stop$.complete();
		// this._routerSub.unsubscribe();
	}
}
