
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription ,  Subject } from 'rxjs';
import { SearchService } from './../common/services/search.service';
import { PageSettingsService } from './../common/services/page-settings.service';
import { ModalService } from '../common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../common/services/sharedService';
declare let $: any;
// declare let Chart: any;
@Component({
  selector: 'app-drill-down-chart',
  templateUrl: './drill-down-chart.component.html',
  styleUrls: ['./drill-down-chart.component.css']
})
export class DrillDownChartComponent implements AfterViewInit, OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  isArabicLanguage: Boolean = false;
  searchResult: string;
  noDocumentsFound: string;
  pageContentData: any;
  facets: any;
  private facetResultSubscription: Subscription;
  private isUpdate: Boolean = false;
  results: any;
  resultSet: any;
  myChart: any;
  tooltipText = 'Document';
  chartDataYear: any = [];
  chartDataCount: any = [];
  current: any;
  facetRangeGap: any;
  facetYearRange: any = [];
  dateRange: any;
  year;
  yearFrom: any;
  yearTo: any;
  rangeSliderMinRange: number;
  rangeSliderMaxRange: number;

  lineChartData: Array<any> = [];
  xAxisLabel: Array<any> = [];
  lineChartOptions: any = {
    title: {
      display: false,
      text: ''
    },
    legend: { display: false },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          const l = tooltipItem.length;
          for (let i = 0; i < l; i++) {
            this.current = tooltipItem[i]['index'];
          }
          this.year = data['labels'][tooltipItem[0]['index']];
          const c = parseInt(this.year, 10) + (this.facetRangeGap ? parseInt(this.facetRangeGap, 10) : 0);
          const sum = this.facetRangeGap ? this.year + '-' + ((parseInt(this.facetRangeGap, 10) + parseInt(this.year, 10) - 1)) : this.year;
          return this.facetRangeGap ? this.year + '-' + ((parseInt(this.facetRangeGap, 10) + parseInt(this.year, 10) - 1)) : this.year;
        },
        label: (tooltipItem, data) => {
          return data['datasets'][0]['data'][tooltipItem['index']] + ' ' + 'Document(s)';
        }
      }
    },
  };
  lineChartColors: Array<any> = [];
  lineChartLegend: Boolean = true;
  lineChartType: String = 'bar';

  // tslint:disable-next-line:no-output-rename
  @Output('rangeSubmit') private onRangeSubmitEmiter: EventEmitter<any> = new EventEmitter();
    constructor(
    private searchService: SearchService,
    private popupService: ModalService,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {
      PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.pageContentData = data;
    });

      SearchService.facetObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
      for (const obj of data) {
        if (obj.facetField === 'pubYear') {
          this.facetRangeGap = obj.facetRangeGap;
          this.facets = obj.facetContent.sort(function(a, b){return a.value - b.value; });
          const facetLength = this.facets.length;
          if (facetLength > 1) {
            this.chartDataYear = [];
            this.chartDataCount = [];
            let i: any;
            for (const facetPubdateObj of obj.facetContent) {
              this.chartDataYear.push(facetPubdateObj.value);
              this.chartDataCount.push(facetPubdateObj.count);
              i++;
            }
          }
          if (this.facets) {
            if (this.facets.length > 0) {
            this.yearFrom = this.facets[0].value;
            this.yearTo = parseInt(this.facets[facetLength - 1].value, 10);
            this.dateRange = [this.yearFrom, this.yearTo];
            this.rangeSliderMinRange = this.facets[0].value;
            this.rangeSliderMaxRange = this.facets[facetLength - 1].value;
            }
          }
          // tslint:disable-next-line:max-line-length
          if (this.pageContentData.advancedFilterParam.publishedDatePrefix !== 'BEFORE' && this.pageContentData.advancedFilterParam.publishedDatePrefix !== 'AFTER') {
            if (this.pageContentData.advancedFilterParam.publishedDateFrom &&
              this.pageContentData.advancedFilterParam.publishedDateFrom !== 'All') {
              this.yearFrom = this.pageContentData.advancedFilterParam.publishedDateFrom;
              // tslint:disable-next-line:max-line-length
              if (this.pageContentData.advancedFilterParam.publishedDateTo.includes('-') &&  this.pageContentData.advancedFilterParam.publishedDateTo.length > 5) {
                this.yearTo = this.pageContentData.advancedFilterParam.publishedDateTo;
              } else if (this.pageContentData.advancedFilterParam.publishedDateTo.length === 5) {
                this.yearTo = this.pageContentData.advancedFilterParam.publishedDateTo.slice(0, 4);
              } else {
                this.yearTo = this.pageContentData.advancedFilterParam.publishedDateTo;
              }
            }
          }
        }
      }
    });
  }

  ngOnInit() { }

  dateRangeChanged(event) {
    this.yearFrom = event[0][0];
    this.yearTo = event[0][1];
  }

  ngAfterViewInit() {
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
      setTimeout(() => {
        this.loadChartData();
      }, 1000);
    this.translate.get('Common.Popups.searchResult').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.searchResult = res;
      });
    this.translate.get('Common.Popups.noDocumentsFound').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.noDocumentsFound = res;
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
  chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if ( activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // const year = chart.data.datasets[0].data[activePoints[0]._index];
        this.yearFrom = label;
        this.yearTo = chart.data.labels[clickedElementIndex + 1] - 1;
        if (this.facetRangeGap) {
          this.setCollection(label, parseInt(label, 10) + (parseInt(this.facetRangeGap, 10) - 1), 'BETWEEN');
       }else {
          this.setCollection(label, parseInt(label, 10), 'BETWEEN');
       }
      }
    }
  }
  loadChartData() {
    setTimeout(() => {
      this.xAxisLabel = this.chartDataYear;
      this.lineChartData.push({
        data:  this.chartDataCount,
        label: ''
      });
      this.lineChartColors = [{
        backgroundColor: '#006D76',
        fill: false
      }];
    }, 1000);
  }
  onRangeSubmitClick() {
    // console.log(this.yearFrom, '-', this.yearTo);
    this.onRangeSubmitEmiter.emit({
      'yearFrom': this.yearFrom,
      'yearTo': this.yearTo + '-'
    });
    this.rangeSliderMinRange = this.yearFrom;
    this.rangeSliderMaxRange = this.yearTo;
  }

  setCollection(from, to, prefix) {
      const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
      currentSearchSettings.publishedDateFrom = from;
      currentSearchSettings.publishedDateTo = to + '-';
      currentSearchSettings.publishedDatePrefix = prefix;
      this.searchService.estimateResultCount(currentSearchSettings, (count) => {
        if (count > 0) {
          this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
          setTimeout(() => {
            if (document.getElementById('update-Btn')) {
              document.getElementById('update-Btn').click();
            }
          }, 1500);
        } else {
        this.popupService.showNotification('WARNING', this.searchResult || 'Search Result', this.noDocumentsFound || 'No documents found');
        }
      });
  }
}
