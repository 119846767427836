/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, Input, HostListener, AfterContentInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { SearchService } from './../common/services/search.service';
import { PageSettingsService } from './../common/services/page-settings.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ITreeOptions } from '@circlon/angular-tree-component';
import { UrlUtilityService } from './../common/services/url-utility.service';
import { SharedService } from '../common/services/sharedService';
import { environment } from '../../environments/environment';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { HttpService } from './../common/services/http.service';
import { TranslateService } from '@ngx-translate/core';

declare let $: any;
@Component({
  selector: 'app-search-result-details-leftmenu',
  templateUrl: './search-result-details-leftmenu.component.html',
  styleUrls: ['./search-result-details-leftmenu.component.css']
})
export class SearchResultDetailsLeftmenuComponent implements AfterContentInit, OnDestroy {
  unSubHeaderMenu: Subscription;
  unSubIpadFullscreen: Subscription;
  unSubSearchData: Subscription;
  unSubDocData: Subscription;
  unSubPageSetting: Subscription;
  unSubPageId: Subscription;
  accessToken: any;
  // @ViewChild('itemsTab') private docDetailsTab: TabsetComponent;
  @ViewChild('itemsTab', { static: false }) private docDetailsTab: TabsetComponent;

  private illustrationsLoader: Boolean = false;
  illustrationPageID: any;
  illustrationDocID: any;
  illustrationDocumentImage: string;
  toggleSearch: Boolean = true;
  searchTermData: any;
  documentSearchTerm = '';
  illustrationSelectedPageId: any;
  childPageId: any;
  getpageIdfromDocClick: string;
  selectedDocPageId = { pageId: '' };
  // @ViewChild('treeNoderef') selectedNode;
  @ViewChild('treeNoderef', { static: false }) selectedNode;

  photographTableofContentNodes: any[];
  private togglerelatedsubicon: boolean;
  toggleDoctypeicon: boolean;
  toggleanalysisicon: boolean;
  togglesourceicon: boolean;
  documentData: any;
  private showLeftMenu: Boolean = true;
  pageContentData: any;
  private showledtDetailsmenu: boolean;
  private documentItem: any;
  private pageSettingsObservable: Subscription;
  private paramObservable: Subscription;
  manuscriptTypeTableofContentNodes = [];
  manuscriptOrderTableofContentNodes = [];
  monographTableofContentNodes = [];
  private TypeTableofContentNodes = [];
  private pageLength: any;
  private parentItemTile: any;
  private documentIllustration: any;
  showIllustrations = false;
  hideOnIpad: any;
  toggleMainHeader: any;
  toggleHeader: any;
  @Output('searchTermChanged') private searchTermChanged: EventEmitter<any>;
  @Output('pagechanged') private pagechanged: EventEmitter<any> = new EventEmitter();
  ControlShortcutKey: any;
  shortcutKeyTitle: any;
  @Input('items-type')
  set itemsMode(data: any) {
    if (data) {
      this.itemsMode = data;
    }
  }
  get itemsMode() {
    return this.itemsMode;
  }
  illustrationNodes = [];
  private itemTitleSubstring: any;
  private itemFullTitle: any;
  private sequenceFullTitle: any;
  private relatedSubjectLength: any;
  isRelatedSubject;
  @Input() set illustrationData(data: any) {
    if (data) {
      if (data.length > 0) {
        this.illustrationNodes = data;
        this.showIllustrations = true;
      }
    } else {
      this.showIllustrations = false;
    }
  }
  get illustrationData() {
    return this.illustrationNodes;
  }


  _docIdInput: any;
  @Input() set docIdInput(data: any) {
    if (data) {
      this._docIdInput = data;
      if (this._docIdInput) {
        this.searchService.getDocumentItembyType(this._docIdInput.docId, (response) => {
          this.documentItem = response;
        });

      }
      this.itemsByType(data.docId);
      this.itemsInOrder(data.docId);
      this.itemsInToc(data.docId);
      this.illustraionTypes(data.docId);
      this.itemsInPhotograph(data.docId);
    }
  }
  get docIdInput() {
    return this._docIdInput;
  }


  @Output('itemsTitle') private itemsTitle: EventEmitter<any> = new EventEmitter();

  manuscriptTableofContentOptions: ITreeOptions = {
    idField: 'uuid',
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    isExpandedField: 'expanded'
  };
  options1: ITreeOptions = {
    getChildren: () => new Promise((resolve, reject) => { })
  };

  options0: ITreeOptions = {
    displayField: 'title',
    nodeClass: (node) => `${node.data.title}Class`
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageSettingService: PageSettingsService,
    private searchService: SearchService,
    private urlUtilityService: UrlUtilityService,
    private sharedService: SharedService,
    private httpService: HttpService,
    private translate: TranslateService
  ) {
    this.accessToken = this.httpService.getCurrentAccessToken();
    // this.paramObservable = this.activatedRoute.params.subscribe(params => {
    this.paramObservable = this.activatedRoute.params.subscribe(params => {
      this.itemsByType(params['docID']);
      this.itemsInOrder(params['docID']);
      this.itemsInToc(params['docID']);
      this.illustraionTypes(params['docID']);
      this.itemsInPhotograph(params['docID']);
    });

    this.searchTermChanged = new EventEmitter();
    this.unSubPageSetting = PageSettingsService.pageSettingsObservable.subscribe((data) => {
      this.pageContentData = data;
      if (window.innerWidth <= 1024) {
        this.pageContentData.showLeftMenu = false;
      } else {
        this.pageContentData.showLeftMenu = true;
      }
    });
    let filterParams = null;
    this.paramObservable = this.activatedRoute.params.subscribe(param => {
      if (param.searchTerm) {
        this.documentSearchTerm = param.searchTerm;
      }
      if (param['filterParam']) {
        filterParams = param['filterParam'];
      }
      this.urlUtilityService.parseUrl(filterParams).subscribe((data) => {
        filterParams = new AdvancedFilterParams(data);
        this.pageSettingService.updateSearchSettings(filterParams);
      });
    });
  }
  onNodeSelect($event) {
    if ($event.node.hasChildren === false) {
      if ($event.node.data['pageId']) {
        this.pagechanged.emit({
          pageId: $event.node.data['pageId'],
          pageData: null
        });
      }
    }
  }

  onTableSelect($event) {
    if ($event.node.hasChildren === false) {
      const tableID = $event.node.data['tableId'];
      if ($event.node.data['pageId']) {
        const nodeData = $event.node.data;
        this.pagechanged.emit({
          pageId: nodeData['pageId'],
          pageData: {
            tableId: nodeData.tableId,
            width: nodeData.width ? nodeData.width : '',
            height: nodeData.height ? nodeData.height : '',
            hpos: nodeData.hpos ? nodeData.hpos : '',
            vpos: nodeData.vpos ? nodeData.vpos : '',
            highLight: true
          }
        });
      }
    }
  }

  toggleLeftMenu() {
    // this.showLeftMenu = !this.showLeftMenu;
    // this.pageSettingService.updateSetting('showLeftMenu', this.showLeftMenu);
    this.pageContentData.showLeftMenu = !this.pageContentData.showLeftMenu;
    sessionStorage.setItem('LefttMenuStatus', this.pageContentData.showLeftMenu.toString());
  }

  ngAfterContentInit() {
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
    (navigator.userAgent.indexOf('Linux') !== -1)) {
    this.ControlShortcutKey = 'Ctrl + Alt';
    this.shortcutKeyTitle = 'Shift + Alt';
  } else if (navigator.userAgent.indexOf('Mac') !== -1) {
    this.ControlShortcutKey = 'Cmd + Option';
    this.shortcutKeyTitle = 'Shift + Option';
  }
    // this.shortcutKeyTitle = localStorage.getItem('ShortcutKey');
   // this.ControlShortcutKey = localStorage.getItem('ControlShortcutKey');
    this.unSubPageId = this.sharedService.pageId.subscribe(res => {
      this.selectedDocPageId = res;
      // const curPos = window.pageYOffset || document.documentElement.scrollTop;
      // const targetElement = document.getElementById('nodeToScrollTo');
      // targetElement.scrollIntoView();
      // document.body.scrollTop = document.documentElement.scrollTop = curPos;
      const $parent = $('#itemTab1');
      const $sequenceparent = $('#itemTab2');
      const $illustraionParent = $('#illustrationTree .angular-tree-component');
      const $treenode = $('.' + this.selectedDocPageId.pageId);
      const $Illustrationtreenode = $('.illustration-tree' + '.' + this.selectedDocPageId.pageId);
      const $sequencetreenode = $('.sequence-tree' + '.' + this.selectedDocPageId.pageId);
      const $treenode2 = $('.' + this.selectedDocPageId.pageId);
      if ($treenode[0]) {
        if (!$treenode.parent().hasClass('node-content-wrapper-focused')) {
          $parent.animate({
            scrollTop: 0
          }, 50);
          setTimeout(() => {
            $parent.animate({
              scrollTop: $treenode.offset().top - $parent.offset().top - 5
            }, 1000);
          }, 500);
        }
      }
      if ($Illustrationtreenode[0]) {
        if (!$Illustrationtreenode.parent().hasClass('node-content-wrapper-focused')) {
          $illustraionParent.animate({
            scrollTop: 0
          }, 50);
          setTimeout(() => {
            $illustraionParent.animate({
              scrollTop: $Illustrationtreenode.offset().top - $illustraionParent.offset().top - 5
            }, 1000);
          }, 1000);
        }
      }

      if ($sequencetreenode[0]) {
        if (!$sequencetreenode.parent().hasClass('node-content-wrapper-focused')) {
          $sequenceparent.animate({
            scrollTop: 0
          }, 50);
          setTimeout(() => {
            $sequenceparent.animate({
              scrollTop: $sequencetreenode.offset().top - $sequenceparent.offset().top - 5
            }, 1000);
          }, 1000);
        }
      }

    });
    // this.paramObservable = this.activatedRoute.params.subscribe(params => {
    // this.searchService.getDocumentDetail(params['docID'], (response) => {
    this.unSubDocData = this.sharedService.documentData.subscribe(response => {
      if (response) {
        this.documentData = response;
        if (this.documentData.relatedSubjects) {
          this.isRelatedSubject = true;
        }
      }
    });
    // });
    // setTimeout(() => {
    //   if (this.documentSearchTerm) {
    //     // $('#searchWithinBtn').trigger('click');
    //   }
    // }, 1500);

    this.unSubSearchData = this.sharedService.documentSearchData.subscribe(res => {
      this.searchTermData = res;
    });
    // tslint:disable-next-line:one-line

    this.unSubIpadFullscreen = this.sharedService.ipadFullScreen.subscribe((changes) => {
      if (changes === 'true') {
        this.hideOnIpad = false;
      }else {
        this.hideOnIpad = true;
      }
    });
    this.unSubHeaderMenu = this.sharedService.headerMenu.subscribe((changes) => {
      if (changes) {
        if (changes === 'true') {
          this.toggleHeader = true;
        }else {
          this.toggleHeader = false;
        }
      }else {
        this.toggleHeader = sessionStorage.getItem('wileyShowTopBar') === 'true' ? true : false;
      }
    });
    setTimeout(() => {
      const currentPageLanguage = sessionStorage.getItem('lang');
      if (currentPageLanguage === 'ta') {
        $('.byitem-tab').addClass('items-tabset');
        $('.bytype-tab').addClass('types-tabset');
      } else {
        $('.byitem-tab').removeClass('items-tabset');
        $('.bytype-tab').removeClass('types-tabset');
      }
    }, 2500);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // if (event.target.innerWidth <= 1024) {
    //   this.pageContentData.showLeftMenu = false;
    // } else {
    //   this.pageContentData.showLeftMenu = true;
    // }
  }

  itemsByType(params) {
    if (params) {
    this.searchService.getDocumentItembyType(params, (response) => {
      const tableOfContent = response;
      this.itemsTitle.emit(tableOfContent);
      const parentNodes = [];
      if (tableOfContent) {
        const contents = Object.keys(tableOfContent);
        contents.forEach((content, parentIndex) => {
          const childNodes = [];
          if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {
            tableOfContent[content].forEach((child, childIndex) => {
              const grandChildNodes = [];
              const pages = child.pageNo.split(',');
              if (child.itemTitle == null) {
                child.itemTitle = 'Untitled';
              }
              this.pageLength = pages.length + ' Page(s)';
              childNodes.push({
                name: child.itemTitle + ', ' + this.pageLength,
                tooltipContent: this.itemFullTitle + ', ' + this.pageLength,
                children: [],
                pageId: child.pages[0] ? child.pages[0] : '',
                chilldPageIds: child.pages
              });
            });
          }
          parentNodes.push({
            name: content,
            children: childNodes
          });
        });
        this.manuscriptTypeTableofContentNodes = parentNodes;
      }
    });
  }
  }

  itemsInOrder(params) {
    if (params) {
    this.searchService.getDocumentByItem(params, (response) => {
      const tableOfContent = response;
      const parentNodes = [];

      tableOfContent.forEach((parent, childIndex) => {
        const childNodes = [];
        const pages = parent.pageNo.split(',');

        if (parent.itemTitle == null) {
          parent.itemTitle = 'Untitled';
        }
        this.pageLength = pages.length + 'Page(s)';
        this.parentItemTile = parent.subType + ', ';
        parentNodes.push({
          name: this.parentItemTile + ' ' + parent.itemTitle + ', ' + pages.length + ' Page(s)',
          tooltipContent: this.parentItemTile + ',' + this.sequenceFullTitle + ', ' + pages.length + ' Page(s)',
          children: childNodes,
          pageId: parent.pages[0] ? parent.pages[0] : '',
          chilldPageIds: parent.pages
        });
        this.manuscriptOrderTableofContentNodes = parentNodes;
      });

    });
  }
  }

  itemsInToc(params) {
    if (params) {
    this.searchService.getDocumentItembyTOC(params, (response) => {
      if (response) {
        const toc = Object.keys(response['tableOfContent']);
        const parentNodes = [];
        toc.forEach((key, value) => {
          const childNodes = [];
          response['tableOfContent'][key].forEach((childkey, childValue) => {
            const grandChildNodes = [];
            childNodes.push({
              name: childkey.title,
              children: grandChildNodes,
              pageId: childkey.pages[0] ? childkey.pages[0] : ''
            });
          });
          parentNodes.push({
            name: key,
            children: childNodes
          });
          this.monographTableofContentNodes = parentNodes;
        });
      }
    });
  }
  }
  checkPageIdExist(childNodePageIds) {
    if (childNodePageIds) {
      return childNodePageIds.includes(this.selectedDocPageId.pageId);
    } else {
      return false;
    }
  }

  illustraionTypes(params) {
    this.searchService.getIllustraion(params, (response) => {
      const tableOfContent = response;
      const parentNodes = [];
      if (tableOfContent) {

        const contents = Object.keys(tableOfContent);
        if (Object.keys(tableOfContent).length > 0) {

          this.showIllustrations = true;
        }
        contents.forEach((content, parentIndex) => {
          const childNodes = [];
          if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {

            tableOfContent[content].forEach((child, childIndex) => {
              if (child.caption === null) {
                child.caption = 'Untitled';
              }
              const grandChildNodes = [];
              childNodes.push({
                name: child.caption,
                children: grandChildNodes,
                tableId: child.tableId ? child.tableId : '',
                pageId: child.pageId ? child.pageId : '',
                width: child.width ? child.width : '',
                height: child.height ? child.height : '',
                hpos: child.hpos ? child.hpos : '',
                vpos: child.vpos ? child.vpos : '',
                chilldPageIds: child.pageId
              });
            });
          }
          parentNodes.push({
            name: content,
            children: childNodes
          });
        });
        this.illustrationNodes = parentNodes;

      }
    });

  }




  itemsInPhotograph(params) {
    if (params) {
    this.searchService.getDocumentByItem(params, (response) => {
      const tableOfContent = response;
      const parentNodes = [];
      tableOfContent.forEach((parent, childIndex) => {
        const childNodes = [];
        const pages = parent.pageNo.split(',');
        parentNodes.push({
          name: parent.itemTitle + ', ' + pages.length + ' Page(s)',
          tooltipContent: parent.itemTitle + ', ' + pages.length + ' Page(s)',
          children: childNodes,
          pageId: parent.pages[0] ? parent.pages[0] : ''
        });
        this.photographTableofContentNodes = parentNodes;
      });

    });
  }
  }

  searchWithin(keyword) {
    this.sharedService.documentSearchDataReceived(keyword);
    this.searchTermChanged.emit({ keyword: keyword, fuzzy: this.toggleSearch, 'random': Math.random() });
  }

  // afterItemsByTypeInit(event) {
  //   // setTimeout(() => {
  //       event.treeModel.expandAll();

  //       // }, 3000);
  //     }

  //     afterMonographTreeInit(event) {
  //       event.treeModel.expandAll();
  //     }

  //     afterIllustrationTreeInit(event) {
  //       setTimeout( () => {
  //         event.treeModel.expandAll();
  //     }, 3000);
  // }
  afterIllustrationTreeInit(event) {
    setTimeout(() => {
      event.treeModel.expandAll();
    }, 500);
  }
  checkAccordionStatus(event: Event, accordionGroup) {
    switch (accordionGroup) {
      case 'toggleanalysisicon':
        this.toggleanalysisicon = !this.toggleanalysisicon;
        break;
      case 'toggleDoctypeicon':
        this.toggleDoctypeicon = !this.toggleDoctypeicon;
        break;
      case 'togglerelatedsubicon':
        this.togglerelatedsubicon = !this.togglerelatedsubicon;
        break;
      default:
        break;
    }

  }

  setSubject(subject) {
    const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
    currentSearchSettings.subject = [subject];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(this.pageContentData.searchTerm, currentSearchSettings);
  }
  loadNodedataIllustrations($event) {
    if ($event.node.hasChildren === false) {
      const tableID = $event.node.data['tableId'];
      if ($event.node.data['pageId']) {
        const nodeData = $event.node.data;
        this.pagechanged.emit({
          pageId: nodeData['pageId'],
          pageData: {
            tableId: nodeData.tableId,
            width: nodeData.width ? nodeData.width : '',
            height: nodeData.height ? nodeData.height : '',
            hpos: nodeData.hpos ? nodeData.hpos : '',
            vpos: nodeData.vpos ? nodeData.vpos : ''
          }
        });
      }
    }
  }
  loadNodedataToItems($event) {
    if ($event.node.hasChildren === false) {
      // this.gotoScroolTop();
      if ($event.node.data['pageId']) {
        this.pagechanged.emit({
          pageId: $event.node.data['pageId'],
          pageData: null
        });
      }
    }
  }

  expandTreeOnLoad(event) {
    event.treeModel.expandAll();
  }

  addPageIdAsClass(pageIds) {
    return pageIds;
  }
  togglefuzzySearch() {
    this.toggleSearch = !this.toggleSearch;
    $('#searchWithinBtn').trigger('click');
  }
  mouseEnter(treeNode) {
    if (treeNode.hasChildren === false) {
      this.illustrationDocumentImage = '';
      if (treeNode.data.pageId) {
        this.illustrationDocID = treeNode.data.docId;
        this.illustrationPageID = treeNode.data.pageId;
        this.showLoader();
        setTimeout(() => {
          this.illustrationDocumentImage =
            // tslint:disable-next-line:max-line-length
            `${environment.APIUrl}file/thumb/${this.illustrationDocID}/${this.illustrationPageID}?access_token=${this.accessToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation }`;
          setTimeout(() => {
            this.hideLoader();
          }, 500);
        }, 0);
        $('#illustrationImgDiv').addClass('show-illustrationimage');
      }
    }

  }
  mouseLeave(treeNode) {
    $('#illustrationImgDiv').removeClass('show-illustrationimage');
  }
  showLoader() {
    this.illustrationsLoader = true;
  }
  hideLoader() {
    this.illustrationsLoader = false;
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
      if (e.shiftKey && e.altKey && e.keyCode === 87) {
        $('#document_search').focus();
        e.preventDefault();
      } else if (e.ctrlKey && e.altKey && e.keyCode === 81) {
        this.docDetailsTab.tabs[1].active = true;
      } else if (e.shiftKey && e.altKey && e.keyCode === 81) {
        this.docDetailsTab.tabs[0].active = true;
      }
  }

  ngOnDestroy(): void {
    if (this.unSubPageId) {
      this.unSubPageId.unsubscribe();
    }
    if (this.unSubDocData) {
      this.unSubDocData.unsubscribe();
    }
    if (this.unSubHeaderMenu) {
      this.unSubHeaderMenu.unsubscribe();
    }
    if (this.unSubIpadFullscreen) {
      this.unSubIpadFullscreen.unsubscribe();
    }
    if (this.unSubPageSetting) {
      this.unSubPageSetting.unsubscribe();
    }
    if (this.unSubSearchData) {
      this.unSubSearchData.unsubscribe();
    }
  }
}
