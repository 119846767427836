<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="known-issues-container">
  <div class="row">
    <div class="col-md-12">
      <h2 class="title-heading">{{ 'Common.inProgress' | translate }}</h2>
      <div *ngFor="let obj of knownIssuesList; let i = index;" >
        <ul class="knownIssuesList about-border-btm">
         <li>
           <a role="button"> {{ 'Common.title' | translate }} {{obj.titleName}}</a>
           <label>{{ 'Common.message' | translate }} {{obj.message}}</label>
         </li>
       </ul>
   </div>
    </div>
  </div>
</div>

