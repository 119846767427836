import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PhotoExplorerComponent } from './photo-explorer/photo-explorer.component';
import { MapExplorerComponent } from './map-explorer/map-explorer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

const routes: Routes = [
    { path: '', redirectTo: 'photo', pathMatch: 'full' },
    { path: 'photo', component: PhotoExplorerComponent },
    { path: 'map', component: MapExplorerComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes), BsDropdownModule],
    exports: [RouterModule, BsDropdownModule]
})
export class ExplorerRoutingModule { }
