
import { takeUntil } from 'rxjs/operators';


import {
  Component, OnInit, AfterViewInit, Input, Renderer2, ViewChild, ElementRef, OnChanges,
  EventEmitter, Output, TemplateRef, HostListener, OnDestroy
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalService } from '../common/services/modal.service';
import { SharedService } from '../common/services/sharedService';
import { HttpService } from '../common/services/http.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
declare let OpenSeadragon: any;
declare let $: any;

@Component({
  selector: 'app-osd-image-viewer',
  templateUrl: './osd-image-viewer.component.html',
  styleUrls: ['./osd-image-viewer.component.css']
})
export class OsdImageViewerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  hideDroneText = false;
  showCaptionTitle: boolean;
  isDownLoadPdf: Boolean;
  downloadPageURL: String = '';
  downLoadTiffImageURL: String;
  // @ViewChild('downloadPageasPdfTemplate') private downloadPageasPdfTemplate: TemplateRef<any>;
  @ViewChild('downloadPageasPdfTemplate', { static: false }) private downloadPageasPdfTemplate: TemplateRef<any>;

  @Output('overlayClicked') private overlayClicked: EventEmitter<any>;
  // @Output('headWordsOverlayClicked') private headWordsOverlayClicked: EventEmitter<any>;
  currentXposition: number;
  currentYposition: number;
  canvasYposition: any;
  canvasXposition: any;
  @Output('selectionPerformed') selectionPerformed: EventEmitter<any>;
  addedToList: any;
  storageSizeExceeded: any;
  translatedValues: any;
  translateLanguageValues: any;
  modalRef: BsModalRef;
  clippedImageList: any;
  clippedBase64Img: string;
  selection: any;
  toggleHighlighter: Boolean = false;
  isHiglightisOn: Boolean = false;
  fitImageToWidth: any;
  viewer: any;
  imageConfig: any = [];
  overlays: any;
  hideTitle: Boolean = false;
  title: Boolean = false;
  hideOverlay: Boolean = true;
  private currentBrightness = 1;
  private currentContrast = 1;
  private currentZoomLevel = 0.1;
  private currentViewPortValue = 0.4;
  private currentAngle = 0;
  private imageFilters: any = [];
  private isInverted = false;
  private isInFullScreenMode: boolean;
  isArabicLanguage: Boolean;
  imageviewerframeLoader: Boolean;
  private downloadPDFmodalRef: BsModalRef;

  @Output('imageloaded') imageloaded: EventEmitter<any> = new EventEmitter();
  // @ViewChild('editorContainer') editorContainer: ElementRef;
  @ViewChild('editorContainer', { static: false }) editorContainer: ElementRef;

  @Input('loadImage')
  set loadImage(val) {
    this.isHiglightisOn = false;
    if (val !== undefined) {
      this.imageConfig = val;
    }
    this.loadImageOnOSD();
  }

  @Input('overlays')
  set overlay(value) {
    this.overlays = value;
    setTimeout(() => {
      if (this.viewer) {
        if (this.viewer.isOpen() && this.viewer.isVisible()) {
          this.populateOverlays();
        }
      }
    }, 200);

  }

  @Input('brightness')
  set _brightness(value: any) {
    this.currentBrightness = value === 0 ? 1 : value;
    if (this.viewer) {
      this.adjustBrightness();
    }
  }
  get _brightness() {
    return this.currentBrightness;
  }
  @Input('zoomIn')
  set _zoomIn(value: any) {
    if (this.viewer) {
      this.zoomInImage(0.4);
    }
  }
  @Input('zoomOut')
  set _zoomOut(value: any) {
    if (this.viewer) {
      this.zoomOutImage(0.4);
    }
  }
  @Input('contrast')
  set _contrast(value: any) {
    this.currentContrast = value === 0 ? 1 : value;
    if (this.viewer) {
      this.adjustContrast();
    }
  }
  get _contrast() {
    return this.currentContrast;
  }

  @Input('invert')
  set _invert(value: any) {
    this.isInverted = value;
    if (this.viewer) {
      this.invertImage();
    }
  }
  get _invert() {
    return this.isInverted;
  }

  @Input('fitToWidth')
  set _fitToWidth(flag) {
    this.fitImageToCanvas(flag);
  }
  get _fitToWidth() {
    return this.fitImageToWidth;
  }
  @Input('restoreImage')
  set resetImageFilters(flag: boolean) {
    if (this.viewer) {
      this.viewer.viewport.goHome();
      this.hideTitle = false;
      this.hideOverlay = true;
      this.fitImageToCanvas(false);
      this.rotateImage(0);
      this.fitImageToCanvas(false);
    }
  }
  get resetImageFilters() {
    return false;
  }

  @Input('angle')
  set _angle(value: any) {
    if (this.viewer) {
      this.rotateImage(value);
    }
  }
  get _angle() {
    return this.currentAngle;
  }

  @Input('isInFullScreenMode')
  set _isInFullScreenMode(value: any) {
    this.isInFullScreenMode = value;
  }
  get _isInFullScreenMode() {
    return this.isInFullScreenMode;
  }
  @Input('selection')
  set _selection(value: any) {
    if (this.viewer) {
      this.setSelection(value);
    }
  }
  @Input('downloadPageURL')
  set _downloadPageURL(value: any) {
    if (value) {
      this.downloadPageURL = value.url;
      this.downLoadTiffImageURL = '';
      $('#downloadPdf').modal('show');
      $('.modal-backdrop.fade.in').addClass('removeBackdrop');
      $('.backdrop-modal').addClass('activeModal');
    }
  }
  get _downloadPageURL() {
    return this.downloadPageURL;
  }
  @Input('downLoadTiffImageURL')
  set _downLoadTiffImageURL(value: any) {
    if (value) {
      this.downloadPageURL = '';
      this.downLoadTiffImageURL = value.url;
      $('#downloadPdf').modal('show');
      $('.modal-backdrop.fade.in').addClass('removeBackdrop');
      $('.backdrop-modal').addClass('activeModal');
    }
  }
  get _downLoadTiffImageURL() {
    return this.downLoadTiffImageURL;
  }
  @Input('isDownLoadPdf')
  set _isDownLoadPdf(value: any) {
    this.isDownLoadPdf = value;
    if (this.isDownLoadPdf) {
      //  this.downloadPDFmodalRef = this.bsModalService.show(this.downloadPageasPdfTemplate, { backdrop: true, ignoreBackdropClick: true });
      // $('#downloadPdf').modal('show');
      // $('.modal-backdrop.fade.in').addClass('removeBackdrop');
      // $('.backdrop-modal').addClass('activeModal');
    }
  }
  get _isDownLoadPdf() {
    return this.isDownLoadPdf;
  }
  @Input('isGeoTiffEnabled')
  set _isGeoTiffEnabled(value: any) {
    this.isGeoTiffEnabled = value;
  }
  get _isGeoTiffEnabled() {
    return this.isGeoTiffEnabled;
  }
  private stop$: Subject<void> = new Subject<void>();
  isGeoTiffEnabled: Boolean = false;
  @Input('currentArchiveName')
  set _currentArchive(value: any) {
    this.currentArchives = value;
  }
  get _currentArchive() {
    return this.currentArchives;
  }
  currentArchives: any;

  constructor(private renderer: Renderer2,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private sharedService: SharedService,
    private httpService: HttpService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService) {
    this.selectionPerformed = new EventEmitter();
    this.overlayClicked = new EventEmitter();
    // this.headWordsOverlayClicked = new EventEmitter();
    setTimeout(() => {
      this.httpService.get('../assets/json/supported-languages.json?t=' + new Date().getTime(), (response) => {
        this.translateLanguageValues = response.languages;
      },
        err => { });
      this.translate.get('Common').pipe(takeUntil(this.stop$)).subscribe((res) => {
        this.translatedValues = res['Popups'];
        this.storageSizeExceeded = res;
        this.addedToList = res['Popups']['Added To List'];
      });
    }, 1500);
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      const res = event['translations']['Common'];
      this.translatedValues = res['Popups'];
      this.storageSizeExceeded = res;
      this.addedToList = res['Popups']['Added To List'];
    });
  }
  // public OnResetParent(data) {
  //   this.isHiglightisOn = false;
  //   if (data !== undefined) {
  //     this.imageConfig = data;
  //   }
  //   this.loadImageOnOSD();
  // }

  ngOnChanges() {
    const storeVal = localStorage.getItem("fullScreen");
    if (storeVal === 'true') {
      if (this.editorContainer){
        this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '100vh');
      }
    } else {
      if (this.editorContainer){
        this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '75vh');
      }
    }
    // if (this.deviceService.getDeviceInfo().os === 'Mac') {
    //   setTimeout(() => {
    //     if (this.isInFullScreenMode) {
    //       if (this.editorContainer){
    //         this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '100vh');
    //       }
    //     } else {
    //       if (this.editorContainer){
    //         this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '75vh');
    //       }
    //     }
    //   }, 1500);
    // } else {
    //     if (this.isInFullScreenMode) {
    //       if (this.editorContainer){
    //         this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '100vh');
    //       }
    //     } else {
    //       if (this.editorContainer){
    //         this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '75vh');
    //       }
    //     }
    // } 
  }

  ngOnInit() {
    this.viewer = OpenSeadragon({
      id: 'openSeaDragonCanvas',
      clickToZoom: false,
      autoResize: true,
      preserveImageSizeOnResize: true,
      // zoomInButton: this.image_zoom_in,
      // zoomOutButton: this.image_zoom_out,
      homeButton: 'reset',
      zoomPerClick: 1,
      zoomPerScroll: 1.1,
      tabIndex: '',
      preserveViewport: true,
      visibilityRatio: 1,
      defaultZoomLevel: 1,
      minZoomLevel: 0.2,
      maxZoomLevel: 100,
      // sequenceMode: true,
      preserveOverlays: false,
      showRotationControl: true,
      gestureSettingsTouch: {
        pinchRotate: true
      },
      crossOriginPolicy: 'Anonymous',
      // ajaxWithCredentials: false,
      loadTilesWithAjax: true,
      ajaxHeaders: {},
    });

  }

  ngAfterViewInit() {
    //  this.renderer.setStyle(this.editorContainer.nativeElement, 'height', '75vh');
    this.viewer.addHandler('open', () => {
      if (this.fitImageToWidth !== undefined) {
        this.fitImageToCanvas(this.fitImageToWidth);
      } else {
        this.fitImageToCanvas(false);
      }
      this.imageloaded.emit(this.imageConfig);
    });
    this.viewer.addHandler('add-overlay', () => { });
    this.canvasDragEvent();
    this.canvasClickEvent();
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
    this.showCaptionTitle = true;
  }

  hideTitleOverlay() {
    this.hideTitle = true;
    this.hideOverlay = false;
  }
  showTitleOverlay() {
    this.hideTitle = false;
    this.hideOverlay = !this.hideOverlay;
  }
  loadImageOnOSD() {
    this.imageloaded.emit(this.imageConfig);
    this.hideDroneText = false;
    if (this.viewer) {
      this.viewer.close();
      if (this.imageConfig.type === 'image') {
        this.viewer.open(this.imageConfig);
      } else if (this.imageConfig.type === 'tiles') {
        this.viewer.open(this.imageConfig.tileSource);
      }
    }
  }
  zoomInImage(zoomIn) {
    this.currentZoomLevel = parseFloat(this.viewer.viewport.getZoom().toFixed(5));
    this.currentZoomLevel += zoomIn;
    if (this.currentZoomLevel <= 0.2) {
      this.currentZoomLevel = 0.2;
    }

    if (+(this.currentZoomLevel).toPrecision(2) === 0.6 && this.currentViewPortValue !== 0.2) {
      this.currentZoomLevel = this.currentViewPortValue;
    }
    this.viewer.viewport.zoomTo(this.currentZoomLevel);
  }
  zoomOutImage(zoomOut) {
    this.currentZoomLevel = parseFloat(this.viewer.viewport.getZoom().toFixed(5));
    if (this.currentZoomLevel !== 0.2) {
      this.currentViewPortValue = this.currentZoomLevel;
    }
    if (this.currentZoomLevel > 0.2) {
      this.currentZoomLevel -= zoomOut;
    }
    if (this.currentZoomLevel <= 0.2) {
      this.currentZoomLevel = 0.2;
    }
    this.viewer.viewport.zoomTo(this.currentZoomLevel);
  }
  adjustBrightness() {
    if (this.currentBrightness > -255 && this.currentBrightness <= 255) {
      this.imageFilters[0] = OpenSeadragon.Filters.BRIGHTNESS(this.currentBrightness);
      this.viewer.setFilterOptions({
        min: -255,
        max: 255,
        filters: {
          processors: this.imageFilters
        }
      });
    }
  }

  adjustContrast() {
    if (this.currentContrast < 0) {
      this.currentContrast = this.currentContrast * -1 / 5;
      this.currentContrast = 1 - this.currentContrast;
      this.currentContrast =
        this.currentContrast === 0 ? 0.1 : this.currentContrast;
    }
    // this.imageFilters[1] = OpenSeadragon.Filters.CONTRAST(this.currentContrast);
    this.viewer.setFilterOptions({
      min: 0,
      max: 5,
      filters: {
        processors: OpenSeadragon.Filters.CONTRAST(this.currentContrast)
      }
    });
  }
  rotateImage(angle: number) {
    this.viewer.viewport.setRotation(angle * 90);
  }
  invertImage() {
    this.isInverted = !this.isInverted;
    if (this.isInverted) {
      this.imageFilters.pop();
    } else {
      this.imageFilters.push(OpenSeadragon.Filters.INVERT());
    }
    this.viewer.setFilterOptions({
      filters: {
        processors: this.imageFilters
      }
    });
  }

  populateOverlays() {
    if (this.overlays) {
      if (this.overlays.length > 0) {
        let animateOverlay = false;
        if (this.overlays.length > 1) {
          animateOverlay = true;
        }
        this.overlays.forEach((overlay, index) => {
          setTimeout(() => {
            if (overlay.type === 'table' || overlay.type === 'keyword') {
              this.addOverlay(overlay, animateOverlay);
            } else {
              if (overlay.invokedFrom === 'ILLUSTRATIONS') {
                this.addOverlay(overlay);
              }
            }
          }, 0);
        });
        this.overlays = null;
      }
    }
  }

  addOverlay(overlay, animateOverlay?) {
    this.toggleHighlighter = false;
    this.isHiglightisOn = true;
    const elt = document.createElement('a');
    elt.id = 'cc' + Math.floor(1000 + Math.random() * 9000);
    if (overlay.type === 'table') {
      elt.id = overlay.tableId;
      if (overlay.invokedTableId === overlay.tableId) {
        if (animateOverlay) {
          elt.className = 'image-viewer-table animate-flicker';
        } else {
          elt.className = 'image-viewer-table';
        }
      } else {
        elt.className = 'image-viewer-table';
      }
      // elt.className = 'image-viewer-table';
      $(document)
        .off()
        .on('click', '.image-viewer-table', event => {
          if (this.currentXposition === this.canvasXposition && this.currentYposition === this.canvasYposition) {
            this.overlayClicked.emit(event.target.id);
          }
        });
    } else if (overlay.type === 'keyword') {
      elt.className = 'image-keyword-highlight';
    } else {
      elt.className = 'image-photo-highlight';
    }

    if (overlay.xPos === 0) {
      overlay.xPos = 10;
    }

    this.viewer.addOverlay({
      element: elt,
      px: overlay.xPos ? overlay.xPos : overlay.hPos,
      py: overlay.yPos ? overlay.yPos : overlay.vPos,
      width: overlay.width,
      height: overlay.height,
      checkResize: true,
      rotationMode: OpenSeadragon.OverlayRotationMode.EXACT
    });
    var tracker = new OpenSeadragon.MouseTracker({
      element: elt,
      clickHandler: function(event) {
          // console.log(event.originalTarget.id)
      }
   });  
  }
  imageViewerMouseEnter() {
    if ($('.image-viewer-table').length > 0) {
      if ($('.image-viewer-table').hasClass('hide-mouse-action')) {
      } else {
        $('#tableOverlayTooltip').show();
      }
    }
  }
  imageViewerMouseLeave() {
    if ($('.image-viewer-table').length) {
      $('#tableOverlayTooltip').hide();
    }
  }

  fitImageToCanvas(fitToWidth) {
    if (this.viewer) {
      setTimeout(() => {
        if (fitToWidth) {
          this.viewer.viewport.fitHorizontally(true);
        } else {
          this.viewer.viewport.fitVertically(true);
        }
      }, 0);
      this.panImageTo(0, 0);
      this.fitImageToWidth = fitToWidth;
    }
  }

  panImageTo(x, y) {
    const bounds = this.viewer.viewport.getBounds(true);
    const currentRect = this.viewer.viewport.viewportToImageRectangle(bounds);
    const rect = this.viewer.viewport.imageToViewportRectangle(
      x,
      y,
      currentRect.width,
      currentRect.height
    );
    this.viewer.viewport.fitBoundsWithConstraints(rect);
  }

  toggleHighlight() {
    this.toggleHighlighter = !this.toggleHighlighter;
    if (this.toggleHighlighter) {
      $('.image-viewer-table').addClass('hide-mouse-action');
      $('.image-photo-highlight').addClass('hide-mouse-action');
      $('.image-keyword-highlight').addClass('hide-keyword-overlay');
      // $('.image-headword-highlight').addClass('hide-headword-overlay');
    } else {
      $('.image-viewer-table').removeClass('hide-mouse-action');
      $('.image-photo-highlight').removeClass('hide-mouse-action');
      $('.image-keyword-highlight').removeClass('hide-keyword-overlay');
      // $('.image-headword-highlight').removeClass('hide-headword-overlay');
    }
  }

  setSelection(val?: boolean) {
    $('#openopenSeaDragonCanvas').css('cursor', 'crosshair !important');
    $('.custom-textarea').val('');
    if (!val) {
      this.selection.disable();
    } else {
      if (this.selection) {
        this.selection.toggleState();
      } else {
        this.selection = this.viewer.selection({

          startRotated: false,
          showSelectionControl: true,
          rect: null,
          keyboardShortcut: 'c',
          restrictToImage: true,
          allowRotation: false,
          onSelection: (rectCoOrdinates) => {
            var viewportRect = this.viewer.viewport.imageToViewportRectangle(rectCoOrdinates);
            var webRect = this.viewer.viewport.viewportToViewerElementRectangle(viewportRect);
            const { x, y, width, height } = webRect;
            const { canvas } = this.viewer.drawer;
            let source = canvas.toDataURL();

            const img = new Image();
            img.onload = () => {
              let croppedCanvas = document.createElement('canvas');
              let ctx = croppedCanvas.getContext('2d');
              croppedCanvas.width = width;
              croppedCanvas.height = height;

              const pixelDens = OpenSeadragon.pixelDensityRatio;
              ctx.drawImage(img, x * pixelDens, y * pixelDens, width * pixelDens, height * pixelDens, 0, 0, width, height);
              let croppedSrc = croppedCanvas.toDataURL();
              fetch(croppedSrc)
                .then(res => res.blob())
                .then(blob => {
                  const file = new File([blob], "File name", { type: "image/png" })
                  // you can get selected cropped image file here
                })
              $('#fullScreenModal').modal('show');
              $('.modal-backdrop.fade.in').addClass('removeBackdrop');
              $('.backdrop-modal').addClass('activeModal');
              // ctx.fillStyle = 'black';
              // ctx.fill();
              this.clippedBase64Img = croppedCanvas.toDataURL('image/png');
              this.clippedImageList = localStorage.getItem('croppedImageList') ? JSON.parse(localStorage.getItem('croppedImageList')) : [];
            }
            img.src = source;
          }
        });
        this.selection.enable();
      }
    }
  }
  downloadSelectedImage(rect) {
    $('#fullScreenModal').modal('show');
    $('.modal-backdrop.fade.in').addClass('removeBackdrop');
    $('.backdrop-modal').addClass('activeModal');
    const imageObj = new Image();
    const ctx = this.viewer.drawer.canvas.getContext('2d');
    const imageData = ctx.getImageData(rect.x, rect.y, rect.width, rect.height);
    const canvas1 = document.createElement('canvas');
    canvas1.width = rect.width;
    canvas1.height = rect.height;
    const ctx1 = canvas1.getContext('2d');
    ctx1.rect(0, 0, rect.width, rect.height);
    ctx1.fillStyle = 'black';
    ctx1.fill();
    ctx1.putImageData(imageData, 0, 0);
    this.clippedBase64Img = canvas1.toDataURL('image/png');
    this.clippedImageList = localStorage.getItem('croppedImageList') ? JSON.parse(localStorage.getItem('croppedImageList')) : [];
  }

  updateTitle(title: string, notes: string) {
    const currentImageId = this.imageConfig.imageId;
    const originalDocUrl = location.pathname;
    const documentCitation = '';
    const croppedImageObject = {
      imageName: `${title}`, imageSource: `${this.imageConfig.title}`, image: this.clippedBase64Img,
      originalDocUrl: originalDocUrl, imageId: currentImageId, imageNotes: notes,
      imageCitation: `${documentCitation}`
    };
    let croppedImageList = JSON.parse(localStorage.getItem('croppedImageList'));
    if (croppedImageList) {
      if (croppedImageList.length > 0) {
        croppedImageList.push(croppedImageObject);
      } else {
        croppedImageList = [croppedImageObject];
      }
    } else {
      croppedImageList = [croppedImageObject];
    }
    try {
      localStorage.setItem('croppedImageList', JSON.stringify(croppedImageList));
      this.sharedService.setCroppedImageList(croppedImageList);
      this.modalService.showNotification('SUCCESS', `${this.translatedValues.addtoList}`,
        `<span class="docTitle">Clipped From - ${this.imageConfig.title}</span><br/>${this.translatedValues.useToolstoView}`, 7000);
    } catch (error) {
      // tslint:disable-next-line:max-line-length
      this.modalService.showNotification('ERROR', `${this.storageSizeExceeded.StorageSizeExceeded}`, `${this.storageSizeExceeded.clipImageStorageSizeExceeded}`);
      throw new Error(error);
    }

    $('#fullScreenModal').modal('hide');
    $('.modal-backdrop.fade.in').removeClass('removeBackdrop');
    $('.backdrop-modal').removeClass('activeModal');

    this.selectionPerformed.emit(false);
    this.selection.disable();
    $('.image-viewer-table').removeClass('hide-mouse-action');
    $('.image-photo-highlight').removeClass('hide-mouse-action');
  }
  removeBackDrop() {
    $('.modal-backdrop.fade.in').removeClass('removeBackdrop');
    $('.backdrop-modal').removeClass('activeModal');
  }
  canvasDragEvent() {
    this.viewer.addHandler('canvas-press', (target, info) => {
      this.canvasXposition = target.position.x;
      this.canvasYposition = target.position.y;
    });
  }
  canvasClickEvent() {
    this.viewer.addHandler('canvas-release', (target, info) => {
      this.currentXposition = target.position.x;
      this.currentYposition = target.position.y;
    });
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if ((e.shiftKey && e.altKey && e.keyCode === 187) || (e.shiftKey && e.altKey && e.keyCode === 61)) {
      this.zoomInImage(0.4);
    } else if ((e.shiftKey && e.altKey && e.keyCode === 189) || (e.shiftKey && e.altKey && e.keyCode === 173)) {
      this.zoomOutImage(0.4);
    }
    if (e.shiftKey && e.keyCode === 32) {
      this.hideTitle = !this.hideTitle;
    }
  }

  closeDownloadPdfModal() {
    $('#downloadPdf').modal('hide');
    $('.modal-backdrop.fade.in').removeClass('removeBackdrop');
    $('.backdrop-modal').removeClass('activeModal');
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
  showViewAction() {
    this.showCaptionTitle = !this.showCaptionTitle;
  }
  hideDronemapsText() {
    this.hideDroneText = true;
  }
}
