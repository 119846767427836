<!-- <div style="background-image: url('assets/config/wiley/wiley_banner.jpg'); height: 450px;"> -->
<!-- <h1 class="page-heading" style="display: inline-block;padding-left: 23px;
	font-family: 'Open Sans', sans-serif !important;
  font-size: 20px;">{{ 'Common.Search' | translate }}</h1> -->

<div class="col-md-12">
  <wiley-searchresult-content-component
    [contentTypeOrder]="contentTypeOrder"
    [isContentTypePhoto]="isPhotograph"
    [isContentTypeMap]="isMap"
    (enableScroller)="singleListModeEnabled($event)"
    [removeWithSearch]="true"
    [fromDownloadContent]="true"
  ></wiley-searchresult-content-component>
</div>

<div class="col-md-6" style="margin: 10px 0px">
  <div class="wiley-primary-text-color">
    <b>Note: You can select up to 10 documents at a time.</b>
  </div>
</div>
<div class="col-md-6" style="margin: 10px 0px">
  <div style="float: right">
    <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
    <a
      [class.arabic-style]="isArabicLanguage"
      class="backto-homelink wiley-primary-text-color"
      (click)="backtoHome()"
      >Back to SearchContent</a
    >
  </div>
</div>
<div class="col-md-8">
  <div [class.arabic-style]="isArabicLanguage" class="pageheader">
    <div class="searchContain">
      <h1
        class="search-res-header"
        [class.arabic-style]="isArabicLanguage"
        title="{{ 'SearchResult.SearchResults' | translate }}"
      >
        {{ "SearchResult.SearchResults" | translate }}
      </h1>
      <label class="resultlbl" *ngIf="searchTotalCount > 0"
        >{{ searchTotalCount }}
        <span [class.arabic-style]="isArabicLanguage" class="result-span">{{
          "SearchResult.DocumentsFound" | translate
        }}</span>
      </label>
    </div>
    <div class="searchWord">
      <ul>
        <li *ngFor="let val of this.searchItems" class="queryList">
          {{ val.fieldValue }}
          <i
            class="fa fa-times"
            aria-hidden="true"
            (click)="clearSearch(val)"
          ></i>
        </li>
      </ul>
    </div>
  </div>

  <!-- <button style="float:right">Download</button> -->
</div>
<div class="col-md-4">
  <button
    style="float: right"
    [disabled]="count == 0"
    [class.arabic-style]="isArabicLanguage"
    class="btn btn-primary btn-align"
    (click)="goDownloadeContent()"
  >
    Download
  </button>

  <div class="dropdown contentDrop">
    <button
      class="btn btn-primary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Content Type
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <ng-container *ngFor="let content of contentTypesParam">
        <a class="dropdown-item" (click)="clickContent(content)"
          >{{ content.value }} ({{ content.count }})</a
        >
      </ng-container>
      <!-- <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a> -->
    </div>
  </div>
</div>
<!-- <div class="filter-result">
    <div [class.arabic-style]="isArabicLanguage" class="pageheader">
      <h1 class="search-res-header" [class.arabic-style]="isArabicLanguage" title="{{ 'SearchResult.SearchResults' | translate }}">{{ 'SearchResult.SearchResults' | translate }}</h1>
    </div>
    <div class="small-underline" [class.arabic-style]="isArabicLanguage"></div>
    <div style="padding: 10px">
      <button  tooltip="Select files between 1-10nos"[tooltipDisabled]="false" [tooltipAnimation]="true" tooltipPlacement="top" [disabled]="count>=11 || count == 0 " 
      [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary btn-align"
       (click)="goDownloadeContent()">Download</button>
    </div>
  </div> -->

<!-- <a class="back-btn wiley-primary-text-color text-right" (click)="backtoHome()">Back To DownloadContent</a> -->

<!-- ===========Tablr========= -->
<!-- <div class="table-container" *ngIf="documentList && documentList.length > 0">
  <div>
    <div class="col-6">
      <table class="table table-bordered">
        <thead>
          <tr class="header-style">
            <th scope="" style="width: 0%"></th>
            <th scope="col" style="width: 11%">Document</th>
            <th scope="col" style="width:11%">Title</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let document of documentList">
            <td>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="downloadData(document ,$event.target)">
                <label class="custom-control-label" for="customCheck1"></label>
              </div>
            </td>
            <td>{{document.id}}</td>
            <td>{{document.title}}</td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</div> -->
<div class="col-md-12">
  <accordion *ngIf="groups?.length > 0" class="searchresult-accordion">
    <ng-container *ngFor="let group of groups; let i = index">
      <!-- //[ngClass]="{'order1':group?.group == 'Monographs','order2':group?.group == 'Manuscripts','order3':group?.group == 'Photographs','order4':group?.group == 'Maps'}" -->
      <accordion-group
        [ngClass]="{
          order1:
            (group?.group == 'Manuscripts' &&
              !(contentTypeList.length > 0) &&
              isContentTypePhoto === false && isContentTypeMap === false) ||
            (isContentTypePhoto && group?.group == 'Photographs') ||
            (isContentTypeMap && group?.group == 'Maps') ||
            (contentTypeList.length > 0 && group?.order == '1'),
          order2:
            (group?.group == 'Monographs' &&
              !(contentTypeList.length > 0) &&
              isContentTypePhoto === false && isContentTypeMap === false) ||
            (isContentTypePhoto && group?.group == 'Manuscripts') ||
            (isContentTypeMap && group?.group == 'Manuscripts') ||
            (contentTypeList.length > 0 && group?.order == '2'),
          order3:
            (group?.group == 'Photographs' &&
              !(contentTypeList.length > 0) &&
              isContentTypePhoto === false && isContentTypeMap === false) ||
            (isContentTypePhoto && group?.group == 'Maps') ||
            (isContentTypeMap && group?.group == 'Photographs') ||
            (contentTypeList.length > 0 && group?.order == '3'),
          order4:
            (group?.group == 'Maps' &&
              !(contentTypeList.length > 0) &&
              isContentTypePhoto === false && isContentTypeMap === false) ||
            (contentTypeList.length > 0 && group?.order == '4')
        }"
        *ngIf="
          pageContentData?.groupsToBeDisplayed?.length == 0
            ? true
            : pageContentData?.groupsToBeDisplayed?.indexOf(group?.group) > -1
        "
        [isOpen]="true"
        (isOpenChange)="checkAccordionStatus(i, $event)"
      >
        <div class="heading" accordion-heading>
          <i
            *ngIf="group?.group == 'Monographs'"
            class="acc_icon monographIcon"
            aria-hidden="true"
          ></i>
          <i
            *ngIf="group?.group == 'Manuscripts'"
            class="acc_icon manuscriptsIcon"
            aria-hidden="true"
          ></i>
          {{ group?.group }}
          <span class="doc-count">
            {{ " (" + group?.totalGroupElements + ")" }}
          </span>
          <i
            *ngIf="group?.group == 'Photographs'"
            class="acc_icon photographIcon"
            aria-hidden="true"
          ></i>
          <i
            *ngIf="group?.group == 'Maps'"
            class="fa fa-map-marker map-icon"
            [ngClass]="{ 'map-icon-collapsed': !togglesearchicon[i] }"
            aria-hidden="true"
          ></i>
          <i
            class="fa fa-chevron-down pull-right accordion-upicn accordionDownIcon{{
              i
            }}"
            [ngStyle]="{ display: !togglesearchicon[i] ? 'block' : 'none' }"
          ></i>
          <i
            class="fa fa-chevron-up pull-right accordion-upicn  accordionDownIcon{{
              i
            }}"
            [ngStyle]="{ display: togglesearchicon[i] ? 'block' : 'none' }"
          ></i>
        </div>

        <div>
          <div>
            <div
              #getScrollElement
              [attr.data-scrollgroup]="group?.group"
              [attr.data-scrolldoclength]="group?.documents.length"
              [attr.data-scrolltotalgroup]="group?.totalGroupElements"
              [attr.data-gridviewscroll]="false"
              [ngClass]="{
                'search-resultsRemove': scrollDisplay,
                nosearch: !scrollDisplay
              }"
            >
              <div
                [class.pad-btm-10]="_isInMapView"
                [class.mapView-enable]="_isInMapView"
                [class.gridview-enable]="showGridView"
                [class.listview-enabled]="!showGridView"
                [class.listview-only]="!showGridView && !_isInMapView"
                [class.pointernone]="
                  documentFromOtherArchives && group?.group !== 'Photographs'
                "
                [class.pointersnone]="
                  documentFromOtherArchives && group?.group === 'Photographs'
                "
                class="col-md-12 col-xs-12 accordian-inner listViewSwitch padding-right-0"
                *ngFor="let document of group?.documents; let j = index"
              >
                <div *ngIf="group?.documents.length > 0">
                  <!-- <div class="searchresult-imgdiv " [tooltip]="pageTitle" tooltipPlacement="auto" aria-hidden="true">

                  <ng-container *ngIf="!((document.archive?.toUpperCase() === 'RAI') && (group?.group === 'Photographs'))">

                  </ng-container>

                  <tooltip-content class="{{group?.group}}GridTooltip" #pageTitle >
                    <div class="pagetitle-popoverdiv" style="overflow: auto;">                      
                      <a (contextmenu)="onRightClick(document,group?.group, $event)" (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                        title="{{ document?.title}}" [class.popup-doc-title-elipsis]="document?.title?.length > 70" class="search-result-header-title popup-document-title"
                        [class.docVisited]="visitedDocList.indexOf(document.id)> -1" appDecodeEntities>{{document.title}}</a>
                      <div [innerHtml]="document.description"></div>
                      <div class="image_count-photograph">
                        <span [class.arabic-style]="isArabicLanguage" class="img_count"> {{document.pageCount}}
                        </span>
                        {{ (document.pageCount > 1) ? ('SearchResult.ImagesInThis' | translate) : ('SearchResult.ImageInThis' | translate) }} {{(group?.group?.substr(-1)
                        === 's')?group?.group?.substring(0,(group?.group?.length - 1)) :group?.group }} {{'SearchResult.imageCollection'
                        | translate}}
                      </div>
                    </div>
                  </tooltip-content>
                </div> -->
                </div>

                <div
                  class="padding-left-0 padding-right-0 accodion-panel {{
                    group?.group
                  }}show getClassShow(group?.group)"
                  [ngClass]="{
                    'col-md-12': _isInMapView,
                    'col-md-12': !_isInMapView
                  }"
                >
                  <div class="col-md-12 col-sm-12 col-xs-12 gridview-panel">
                    <div
                      class=""
                      [class.content-fullwidth]="
                        group?.group !== 'Manuscripts' && !_isInMapView
                      "
                    >
                      <div class="col-md-12" style="right: 25px">
                        <div
                          class="custom-control custom-checkbox col-md-1"
                          style="margin-top: 25px; padding-left: 0px"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input check-container"
                            id="customCheck1"
                            (change)="downloadData(document, $event.target)"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          ></label>
                        </div>
                        <div class="col-md-2">
                          <div class="searchresult-imgdiv">
                            <ng-container
                              *ngIf="
                                document.archive?.toUpperCase() === 'RAI' &&
                                group?.group === 'Photographs'
                              "
                            >
                              <a
                                (click)="
                                  callDocumentViewer(
                                    document?.id,
                                    '',
                                    '',
                                    j,
                                    '',
                                    group?.group
                                  )
                                "
                                (contextmenu)="
                                  onRightClick(document, group?.group, $event)
                                "
                                id="{{ document?.id }}"
                              >
                                <img
                                  class="accordian-img center-block img-responsive"
                                  [attr.src]="
                                    document.folderImages
                                      ? document.folderImages[0] + '&type=small'
                                      : pageContentData.hasFolderImage &&
                                        showFolderImage?.locations[
                                          document.archive
                                        ]?.showFolderImage == 'false' &&
                                        (group?.group === 'Manuscripts' ||
                                          group?.group === 'Photographs')
                                      ? document.imageThumbnails?.length > 1
                                        ? document.imageThumbnails[1] +
                                          '&type=small'
                                        : document.imageThumbnails[0] +
                                          '&type=small'
                                      : document.imageThumbnails
                                      ? document.imageThumbnails[0] +
                                        '&type=small'
                                      : noImageUrl
                                  "
                                  alt="{{ 'Alt.imageThumbnails' | translate }}"
                                  (error)="errorHandler($event)"
                                />
                              </a>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                !(
                                  document.archive?.toUpperCase() === 'RAI' &&
                                  group?.group === 'Photographs'
                                )
                              "
                            >
                              <a
                                (click)="
                                  callDocumentViewer(
                                    document?.id,
                                    '',
                                    '',
                                    j,
                                    '',
                                    group?.group
                                  )
                                "
                                (contextmenu)="
                                  onRightClick(document, group?.group, $event)
                                "
                                id="{{ document?.id }}"
                              >
                                <img
                                  class="accordian-img center-block img-responsive"
                                  [attr.src]="
                                    pageContentData.hasFolderImage &&
                                    showFolderImage?.locations[document.archive]
                                      ?.showFolderImage == 'false' &&
                                    (group?.group === 'Manuscripts' ||
                                      group?.group === 'Photographs')
                                      ? document.imageThumbnails?.length > 1
                                        ? document.imageThumbnails[1] +
                                          '&type=small'
                                        : document.imageThumbnails[0] +
                                          '&type=small'
                                      : document.imageThumbnails
                                      ? document.imageThumbnails[0] +
                                        '&type=small'
                                      : noImageUrl
                                  "
                                  alt="{{ 'Alt.imageThumbnails' | translate }}"
                                  (error)="errorHandler($event)"
                                />
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-9 thumbPart">
                          <!-- <div class="searchresult-imgdiv ">
                        <ng-container
                          *ngIf="((document.archive?.toUpperCase() === 'RAI') && (group?.group === 'Photographs'))">
                          <a (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                            (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}">
                            <img class="accordian-img center-block img-responsive"
                              [attr.src]="document.folderImages ? document.folderImages[0]+ '&type=small' : pageContentData.hasFolderImage &&
                                                  showFolderImage?.locations[document.archive]?.showFolderImage == 'false' &&
                                                  (group?.group === 'Manuscripts' || group?.group === 'Photographs')?
                                                  (document.imageThumbnails?.length > 1  ? document.imageThumbnails[1] + '&type=small' : document.imageThumbnails[0] + '&type=small' ) : (document.imageThumbnails ? document.imageThumbnails[0] + '&type=small' : noImageUrl )"
                              alt="{{'Alt.imageThumbnails' | translate}}" (error)="errorHandler($event)" />
                          </a>
                        </ng-container>
                        <ng-container
                          *ngIf="!((document.archive?.toUpperCase() === 'RAI') && (group?.group === 'Photographs'))">
                          <a (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                            (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}">
                            <img class="accordian-img center-block img-responsive"
                              [attr.src]="pageContentData.hasFolderImage &&
                                                  showFolderImage?.locations[document.archive]?.showFolderImage == 'false' &&
                                                  (group?.group === 'Manuscripts' || group?.group === 'Photographs')?
                                                  (document.imageThumbnails?.length > 1  ? document.imageThumbnails[1] + '&type=small' : document.imageThumbnails[0] + '&type=small' ) : (document.imageThumbnails ? document.imageThumbnails[0] + '&type=small' : noImageUrl )"
                              alt="{{'Alt.imageThumbnails' | translate}}" (error)="errorHandler($event)" />
                          </a>
                        </ng-container> 
                      </div> -->
                          <div class="searchdetails">
                            <h4 data-toggle="tooltip1" data-placement="right">
                              <label
                                title="{{ document.title }}"
                                [ngClass]="{
                                  headerTitle:
                                    !_isInMapView ||
                                    (_isInMapView &&
                                      document?.title.length > 40)
                                }"
                                title="Right click to open link in new tab - {{
                                  document?.title
                                }}"
                                tabindex="0"
                                id="{{ document?.id }}"
                                class="wiley-primary-text-color"
                                >{{ document.title }}
                              </label>
                            </h4>
                            <div
                              style="margin-bottom: 10px"
                              [innerHtml]="document.citation"
                            ></div>
                            <p><b>Source:</b></p>
                            <p style="font-style: italic">
                              <b>{{ document.archivetitle }}</b>
                            </p>
                          </div>
                        </div>
                        <!-- <hr> -->
                      </div>
                    </div>
                    <!-- <div *ngIf="(group?.group === 'Photographs')">
                    <h2 data-toggle="tooltip1" data-placement="right">
                      <div class="searchResultHeaderContainer">
                        <label title="{{ document.title }}" [ngClass]="{'headerTitle': !_isInMapView || (_isInMapView && document?.title.length > 40) }"
                          class="search-result-header-title" title="Right click to open link in new tab - {{document?.title}}"
                          tabindex="0" 
                           id="{{document?.id}}">{{document.title}}
                        </label>
                        <label>{{group?.group}}</label>
                      </div>
                    </h2>
                  </div>   -->

                    <div
                      class="panel-rightcontent"
                      [style.display]="_isInMapView ? 'none' : 'block'"
                      *ngIf="group?.group == 'Manuscripts'"
                    >
                      <div class="tooltip-box">
                        <!-- <perfect-scrollbar [config]="config" class="tooltip-box-scroll">                      
                      </perfect-scrollbar> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="mapsclass-new"
                  [class.full-wdth-style]="!leftMenuStatus"
                  *ngIf="_isInMapView && !showGridView"
                >
                  <div class="listview-icon search-div-icon">
                    <ul
                      class="search-iconsdiv photographwidth {{
                        group?.group
                      }}ListViewIcon"
                      [class.positions]="document?.latlon"
                      [class.gridviewBorder]="gridViewClick"
                      [ngStyle]="{
                        'border-left':
                          group?.group == 'Manuscripts'
                            ? 'none'
                            : '1px solid #d2d2da'
                      }"
                    >
                      <li
                        tabindex="0"
                        class="background-image"
                        (keyup.enter)="getDocIllustrations(document.id, j)"
                        (click)="getDocIllustrations(document.id, j)"
                        *ngIf="
                          !showGridView &&
                          document.isIllustrationExist == true &&
                          group?.group !== 'Photographs' &&
                          group?.group !== 'Maps'
                        "
                      >
                        <a
                          [class.arabic-style]="isArabicLanguage"
                          href="javascript:void(0)"
                          class="serch-icns img-icon img-align"
                        >
                          <span
                            [class.arabic-style]="isArabicLanguage"
                            class="browse-txt"
                            >{{
                              "SearchResult.BrowseIllustrations" | translate
                            }}</span
                          >
                          <span
                            class="spriteico spriteico-icon illustrationsIcon"
                            [class.gridViewIcons]="showGridView"
                            title="{{
                              'SearchResult.BrowseIllustrations' | translate
                            }}"
                            aria-hidden="true"
                          >
                            <span
                              class="illustrationCount"
                              *ngIf="document.illustrationCount"
                              [ngStyle]="{
                                left:
                                  group?.group == 'Monographs' ? '50px' : '16px'
                              }"
                            >
                              <div
                                [class.arabic-style]="isArabicLanguage"
                                class="illustration-badge"
                              >
                                {{ document.illustrationCount }}
                              </div>
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        tabindex="0"
                        [class.gridviewIllustration]="gridViewClick"
                        *ngIf="
                          !showGridView &&
                          document.isIllustrationExist == false &&
                          group?.group !== 'Photographs' &&
                          group?.group !== 'Maps'
                        "
                      >
                        <a
                          (contextmenu)="disableRightClick($event)"
                          href="javascript:void(0)"
                          class="serch-icns img-icon"
                        >
                          <span
                            class="spriteico illustrationsIcon img-disable"
                            [class.gridViewIcons]="showGridView"
                            [class.illustrationfour]="document?.latlon"
                            title="{{
                              'SearchResult.BrowseIllustrationsNotFound'
                                | translate
                            }}"
                            aria-hidden="true"
                          ></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="browse-grid-style"
                  [class.browsemaps-gridview]="showGridView"
                  [class.full-wdth-style]="!leftMenuStatus"
                  *ngIf="_isInMapView && showGridView"
                >
                  <div class="listview-icon search-div-icon">
                    <ul
                      class="search-iconsdiv photographwidth {{
                        group?.group
                      }}ListViewIcon"
                      [class.positions]="document?.latlon"
                      [ngClass]="getgridviewList(group?.group)"
                      [class.gridviewBorder]="gridViewClick"
                      [ngStyle]="{
                        'border-left':
                          group?.group == 'Manuscripts'
                            ? 'none'
                            : '1px solid #d2d2da'
                      }"
                    >
                      <li
                        tabindex="0"
                        (keyup.enter)="getDocIllustrations(document.id, j)"
                        (click)="getDocIllustrations(document.id, j)"
                        *ngIf="
                          document.isIllustrationExist == true &&
                          group?.group !== 'Photographs' &&
                          group?.group !== 'Maps'
                        "
                      >
                        <a
                          [class.arabic-style]="isArabicLanguage"
                          href="javascript:void(0)"
                          class="serch-icns img-icon"
                        >
                          <span
                            [class.arabic-style]="isArabicLanguage"
                            class="browse-txt"
                            >{{
                              "SearchResult.BrowseIllustrations" | translate
                            }}</span
                          >
                          <span
                            class="spriteico illustrationsIcon"
                            [class.gridViewIcons]="showGridView"
                            title="{{
                              'SearchResult.BrowseIllustrations' | translate
                            }}"
                            aria-hidden="true"
                          >
                            <span
                              class="illustrationCount"
                              *ngIf="document.illustrationCount"
                              [ngStyle]="{
                                left:
                                  group?.group == 'Monographs' ? '50px' : '16px'
                              }"
                            >
                              <div
                                [class.arabic-style]="isArabicLanguage"
                                class="illustration-badge"
                              >
                                {{ document.illustrationCount }}
                              </div>
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        tabindex="0"
                        [class.gridviewIllustration]="gridViewClick"
                        *ngIf="
                          document.isIllustrationExist == false &&
                          group?.group !== 'Photographs' &&
                          group?.group !== 'Maps'
                        "
                      >
                        <a
                          href="javascript:void(0)"
                          class="serch-icns img-icon"
                        >
                          <span
                            class="spriteico illustrationsIcon img-disable"
                            [class.gridViewIcons]="showGridView"
                            [class.illustrationfour]="document?.latlon"
                            title="{{
                              'SearchResult.BrowseIllustrationsNotFound'
                                | translate
                            }}"
                            aria-hidden="true"
                          ></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="
            (group?.documents.length >= 6 &&
              group?.documents.length != group?.totalGroupElements) ||
            (scrollDisplay &&
              group?.documents.length >= 3 &&
                group?.documents.length != group?.totalGroupElements)
          "
          class="row text-center clearFix"
        >
          <span
            *ngIf="loadMoreLoader"
            class="glyphicon glyphicon-repeat fast-right-spinner"
          ></span>
          <div
            (click)="loadMoreResults('viewMoreFromTemplate')"
            class="viewMoreContent"
            *ngIf="
              !loadMoreLoader &&
              (group?.documents.length <= 5 ||
                (showGridView && group?.documents.length <= 25)) &&
                !_isInMapView
            "
          >
            <div
              class="glyphicon glyphicon-menu-down loadMoreResultsArrow"
            ></div>
            <div [class.arabic-style]="isArabicLanguage">
              {{ "SearchResult.ViewMore" | translate }}
            </div>
          </div>
        </div>

        <div
          [class.arabic-style]="isArabicLanguage"
          [class.text-left]="gridViewClick"
          class="text-center"
          [ngStyle]="{
            display: _isInMapView || scrollDisplay ? 'none' : 'block'
          }"
        >
          <button
            [class.arabic-style]="isArabicLanguage"
            type="button"
            class="btn btn-primary ted"
            *ngIf="
              group?.totalGroupPages > 1 &&
              group?.documents.length < group?.totalGroupElements
            "
            (click)="
              viewMoreResultsGroup(
                group?.group,
                group?.totalGroupElements,
                group?.documents.length,
                contentType
              )
            "
            title="{{ 'SearchResult.ViewMore' | translate }}"
          >
            {{ "SearchResult.ViewMore" | translate }}
          </button>
        </div>

        <div
          [class.arabic-style]="isArabicLanguage"
          [class.text-left]="gridViewClick"
          class="text-center"
          [ngStyle]="{ display: !_isInMapView ? 'none' : 'block' }"
          *ngIf="!resultDataLoading"
        >
          <button
            [class.arabic-style]="isArabicLanguage"
            type="button"
            class="btn btn-primary ted"
            *ngIf="
              group?.totalGroupPages > 1 &&
              group?.documents.length <= 5 &&
              group?.documents.length < group?.totalGroupElements
            "
            (click)="
              viewMoreResultsGroup(
                group?.group,
                group?.totalGroupElements,
                group?.documents.length,
                contentType
              )
            "
            title="{{ 'SearchResult.ViewMore' | translate }}"
          >
            {{ "SearchResult.ViewMore" | translate }}
          </button>
        </div>

        <button
          [class.arabic-style]="isArabicLanguage"
          [ngStyle]="{
            display: !scrollDisplay || !hightScrollTop ? 'none' : 'block'
          }"
          title="{{ 'SearchResult.GoToTop' | translate }}"
          class="btn btn-primary scrollTopPage"
          (click)="scrollTopPage()"
        >
          <span [class.arabic-style]="isArabicLanguage" class="sr-only"
            >{{ "Common.scrollTopPage" | translate }}
          </span>
          <span class="spriteico topPageIcon"></span>
        </button>
      </accordion-group>
    </ng-container>
  </accordion>
</div>

<!-- ==============DL-pop-up=============== -->

<!-- <ng-template #downloadDocsTemplatess  class="modal" >
    
  <div class="modal-body url_body download-body" >
   
            <div class="about-container">
            <div *ngFor="let docs of downloadDocs">
                            
                <div class="column">
                   <label>{{docs.docId}}</label> &nbsp;&nbsp;
                    <label><a target="_blank" style="cursor: pointer;" href="{{docs.url}}">{{docs.url}}</a></label>
                </div>               
             
            </div> 
     </div>
     <p>sample</p>
     <p>sample</p>
     <p>sample</p>
     <p>sample</p>
     <p>sample</p>
     <p>sample</p>
     <p>sample</p>
    </div>
    <div class="modal-footer">
      <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary" (click)="closeDownloadModal();$event.stopPropagation();">
        {{ 'Detail.Close' | translate }}
      </button>
    </div>
    
  </ng-template> -->

<ng-template #downloadDocsTemplate>
  <div class="modal-header download-title">
    <h4 class="modal-title text-center">
      <!-- <img src="assets/images/icons/download@2x.png " alt="No Image" class="center-block downloadPopupIcon" /> -->
      <b>Download Content</b>
    </h4>
  </div>
  <div class="modal-body url_body download-body">
    <div style="text-align: left; padding-left: 6px">
      <div class="noteText">
        <span><b>Note:</b></span> Please select "Always allow pop-ups" option in
        your browser to download multiple files from {{ weburl }}
      </div>
    </div>
    <div class="terms-scroll">
    <!-- <perfect-scrollbar class="terms-scroll">                      -->
    <div class="row downPopup">
      <div class="col-md-12 docs" *ngFor="let docs of downloadDocs">
        <a
          class="linkTag"
          *ngIf="docs.signedUrl"
          target="_blank"
          href="{{ docs.signedUrl }}"
          download
          >{{ docs.title }}
        </a>
        <a
          class="linkTag"
          *ngIf="!docs.signedUrl"
          target="_blank"
          href=""
          download
        >
          -
        </a>
      </div>
      <!-- <div class="col-md-6">
              <label>{{docs.docId}}</label>
            </div>
            <div class="col-md-6">
              <a *ngIf="docs.url" target='_blank' href="{{docs.signedUrl}}" download>{{docs.url}} </a>
              <span *ngIf="!docs.url"> - </span>
            </div> -->
      <!-- <div class="column">
                  <label>{{docs.docId}}</label> &nbsp;&nbsp;
                    <label><a target="_blank" style="cursor: pointer;" href="{{docs.url}}">{{docs.url}} </a></label>
                     <a target="_blank" href="{{docs.url}}" download>{{docs.url}} </a>
                    <a href="{{docs.url}}" download>{{docs.url}} </a>
                    <label><a style="cursor: pointer;" (click)="openNewWindow(docs.url)">{{docs.url}} </a></label>

                  </div> -->
    </div>
    <!-- </perfect-scrollbar> -->
  </div>
  </div>
  <div class="modal-footer">
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-primary"
      (click)="DownloadALlModal(downloadDocs)"
    >
      Download All
    </button>
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-primary"
      (click)="clearAllDC()"
    >
      {{ "HomePage.ToolsDropdown.clearAll" | translate }}
    </button>
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-secondary"
      (click)="closeDownloadModal(); $event.stopPropagation()"
    >
      {{ "Detail.Close" | translate }}
    </button>
  </div>
</ng-template>
