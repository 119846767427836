<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<div class="col-md-12 contenttype-chart">
  <div class="barChartContainer" id="chartContainer" >
    <div id="tooltipForContentType">
        <div  id = 'tooltipContentDivision' [innerHtml]="tooltipContent">
        </div>
    </div>
    <!-- <canvas id="barchart" #byContentChart>
    </canvas> -->
    <canvas *ngIf="lineChartData.length > 0" baseChart width="400" height="400"
            [datasets]="lineChartData"
            [labels]="xAxisLabel"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [colors]="lineChartColors"
            [chartType]="lineChartType"
            (chartClick)="chartClicked($event)">
        </canvas>
  </div>
</div>
<div class="col-md-12 slider-control term-frequency_slider" *ngIf="byContentTypeYearFrom && byContentTypeYearTo">
  <app-range-slider [(ngModel)]="byContentTypeRangeArray" [value]="byContentTypeRangeArray" [minValue]="byContentTypeYearFromChart"
    (RangeChanged)="byContentTypeSliderRangeChanged($event)" [maxValue]="byContentTypeYearToChart" [stepValue]="1" [range]="true"
    name="rangeArrayN"></app-range-slider>
   <span *ngIf="byContentTypeYearFrom && byContentTypeYearTo">{{byContentTypeYearFrom}} - {{byContentTypeYearTo}}</span>
</div>

