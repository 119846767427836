
import {share, refCount, publishReplay, map, retry, takeUntil} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { APIURLS } from '../../../environments/environment';
import { HttpService } from './http.service';
import { ModalService } from './modal.service';

@Injectable()

export class CommonService {

    data;
    observable;
    language = 'en-us';
    private limiterCache: any = {};
    private stop$: Subject<void> = new Subject<void>();

    constructor(
        private httpClient: HttpClient,
        private httpService: HttpService,
        private notificationService: ModalService
    ) {
        this.getLocationConfig();
    }

    /*
    * Get Location information once per language and get data from server, store it as in cache
    */
    getLocationConfig(lan?: any): Observable<any> {
        if (this.data) {
            return of(this.data);
        } else if (this.observable) {
        return this.observable;
        } else {
            this.observable = this.httpClient.get(`../assets/config/locations-config.json`, {
                observe: 'response'
            }).pipe(
                retry(3),
                map(response => {
                    this.observable = null;
                    if (response.status === 400) {
                        return 'Request failed.';
                    } else if (response.status === 200) {
                        this.data = response.body;
                        return this.data;
                    }
                }),publishReplay(1),
                refCount(),
                share(),);
            return this.observable;
        }
    }
    /**
     * @deprecated : While revamping analysis hub its deprecated
     * @param imgDataUrl : Downalod path
     * @param imageName : Downlaod file name show as particular file name
     */
    downloadImage(imgDataUrl, imageName?) {

        imageName = imageName || 'image.png';
        const res = new Blob([this.dataURItoBlob(imgDataUrl)], { type: 'image/png' });
        if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
            window.navigator.msSaveBlob(res, imageName);
        } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {  // for IPAD

            const fileURL = URL.createObjectURL(res);
            window.location.href = fileURL;
            // window.open('data:application/json;charset=utf-8,' + encodeURIComponent(fileURL), '_blank');
        } else {
            const fileURL = window.URL.createObjectURL(res);
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = imageName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(fileURL);
            }, 2000);
        }
    }
    private dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        const ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const blob = new Blob([ab], { type: mimeString });
        return blob;

    }

    /**
     * @description : get limiter values based on archive
     * @param data : archives array
     * @param callback :success callback as processed data
     * @param isFromHomePage : wether call from homepage or not, also set in local storage
     */
    // tslint:disable-next-line: member-ordering
    private loading = false;
    getDropdownLimiters(data: Array<string>, callback?, isFromHomePage?) {
        if (this.limiterCache[JSON.stringify(data)]) {
            if (isFromHomePage) {
                localStorage.setItem('commonLimiters', JSON.stringify(this.limiterCache[JSON.stringify(data)]));
            }
            this.parseLimiterData(this.limiterCache[JSON.stringify(data)], res => {
                callback(res);
                return;
            });
            return;
        }
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.httpService.post(environment.APIUrl + APIURLS['limiters'], data, (response) => {
            this.loading = false;
            if (isFromHomePage) {
                localStorage.setItem('commonLimiters', JSON.stringify(response));
            }
            this.limiterCache[JSON.stringify(data)] = response;
            this.parseLimiterData(response, res => {
                callback(res);
            });
        }, err => {
            this.loading = false;
            // this.notificationService.hideLoader();
            callback(null);
        });

    }

    parseLimiterData(response, callback) {
        const queryData = {
            contentTypes: null,
            contentSubTypes: null,
            availableContentTypes: null,
            availableContentSubTypes: null,
            availablePublicationTitles: null,
            availableCollections: null,
            availableSources: null,
            availableLanguages: null,
            pubDates: {
                startDate: null,
                endDate: null
            },
            availableSubject: null,
            availableIllustrationTypes: null
        };
        if (response.contentTypes) {
            queryData.availableContentTypes = response.contentTypes.map(function (contentTypeElement, contentTypeIndex) {
                return {
                    id: contentTypeElement.value,
                    name: contentTypeElement.name
                };
            });
        }
        if (response.contentSubTypes) {
            queryData.availableContentSubTypes = response.contentSubTypes.map(function
                (contentSubTypeElement, contentSubTypeIndex) {
                return {
                    id: contentSubTypeElement.value,
                    name: contentSubTypeElement.name
                };
            });
        }
        if (response.pubTitles) {
            queryData.availablePublicationTitles = response.pubTitles.map(function (pubDateElement, pubDateIndex) {
                return {
                    id: pubDateElement.value,
                    name: pubDateElement.name
                };
            });
        }
        if (response.collections) {
            queryData.availableCollections = response.collections.map(function (collectionElement, collectionIndex) {
                return {
                    id: collectionElement.value,
                    name: collectionElement.name
                };
            });
        }
        if (response.sources) {
            queryData.availableSources = response.sources.map(function (sourcesElement, sourcesIndex) {
                return {
                    id: sourcesElement.value,
                    name: sourcesElement.name
                };
            });
        }
        if (response.language) {
            queryData.availableLanguages = response.language.map(function (languageElement, languageIndex) {
                return {
                    id: languageElement.value,
                    name: languageElement.name
                };
            });
        }
        if (response.pubEndDate && response.pubStartDate) {
            queryData.pubDates = {
                startDate: response.pubStartDate,
                endDate: response.pubEndDate
            };
        }
        if (response.subject) {
            queryData.availableSubject = response.subject.map(function (subjectElement, subjectIndex) {
                return {
                    id: subjectElement.value,
                    name: subjectElement.name
                };
            });
        }
        if (response.illustrationTypes) {
            queryData.availableIllustrationTypes = response.illustrationTypes
                .map(function (illustrationTypesElement, illustrationTypesIndex) {
                    illustrationTypesElement.name = illustrationTypesElement.name.replace('_', ' ');
                    illustrationTypesElement.name = illustrationTypesElement.name.replace('-', ' - ');
                    illustrationTypesElement.name = illustrationTypesElement.name.replace('nonEnglish', 'Non-English');
                    return {
                        id: illustrationTypesElement.value,
                        name: illustrationTypesElement.name
                    };
                });
        }
        callback(queryData);
    }

    /*
    * DESC: get limiter values
    * @Input: archives array
    */
    getPagesettingsDropdownLimiters(data: Array<string>, callback?, errorCallback?) {
        if (this.limiterCache[JSON.stringify(data)]) {
            callback(this.limiterCache[JSON.stringify(data)]);
            return;
        }
        this.httpService.post(environment.APIUrl + APIURLS['limiters'], data, (response) => {
            this.limiterCache[JSON.stringify(data)] = response;
            callback(response);
        }, err => {
            // this.notificationService.hideLoader();
            callback(null);
        });

    }

    /**
     * @description : Log all application errors either from service or UI
     * @param errorType : either from SERVICE / UI
     * @param errorCode :http codes / UI - CNERR
     * @param moduleName : url
     * @param message : error message from service / ui
     */
    logErrors(errorType, errorCode, moduleName, message): void {
        const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
        try {
            const formData = {
                errorType: errorType,
                errorCode: errorCode,
                moduleName: moduleName,
                message: JSON.stringify(message),
                locname: personalizationData.shortName,
                instname: personalizationData.instId,
                application: 'WDA'
            };
            this.httpClient.post<any>(environment.APIUrl + APIURLS.errorLogging + '?t=' + new Date(), formData).subscribe(res => { });
        } catch (error) { }
    }
    getShareAudit(type, docID) {
        this.httpClient.get(environment.APIUrl + APIURLS['shareAudit'] + '?eventType=' + type + '&docId=' + docID).subscribe((res) => {});
    }
    getArchives(currentLocation) {
        this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + currentLocation).pipe(takeUntil(this.stop$)).subscribe((res) => {
            sessionStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
            localStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
        }, err => { });	
    }   
}
