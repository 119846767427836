import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../common/services/http.service';

@Component({
  selector: 'app-terminate-url-sesstion',
  templateUrl: './terminate-url-sesstion.component.html',
  styleUrls: ['./terminate-url-sesstion.component.css']
})
export class TerminateUrlSesstionComponent implements OnInit {

  constructor(private route: Router, private httpService: HttpService) {
    const archive: any = window.location.pathname.split('/')[1];
    this.httpService.stopAllServices();
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('currentArchive', archive);
    this.route.navigate([`/${archive || environment.defaultArchive}/auth`]);
   }

  ngOnInit() {
  }

}
