<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->



<div *ngIf="dropDownOptions?.length > 0" class="input-group-btn archive-btnopen">
	<button [class.arabic-style]="isArabicLanguage" type="button" title="{{'AdvancedSearch.archives' | translate}}" class="btn dropdown-toggle" (click)="toggleOptionsList();showArchives = !showArchives; $event.stopPropagation()"  >
		{{'Titles.Archives' | translate}}
		<span [ngClass]="{ 'fa-chevron-up' :showArchives, 'fa-chevron-down' :!showArchives} " class="fa dropdown-fa"></span>
		<p [class.arabic-style]="isArabicLanguage" *ngIf="selectedOptions?.length > 0" class="archives-count"> {{selectedOptions?.length}} <span class="text_lowercase"> {{ 'Detail.OF' | translate }} </span> {{dropDownOptions?.length}} {{ 'AdvancedSearch.selectedArchivesCount' | translate }} </p>
	</button>
	<ul #optionsList class="dropdown-menu option-list" (click)="$event.stopPropagation()"  [ngStyle]="{'display': showArchives ? 'block': 'none'}">
		<li #selectAll *ngIf="dropDownOptions?.length > 0">
			<a [class.arabic-style]="isArabicLanguage" class="select_all" 
			title="{{isAllSelected ? 'Click to unselect archive option(s)' : translateAdvancedSearch.selectAll }}"
			 (click)="toggleSelectAll()">
				{{isAllSelected ? 'Unselect All' : 'Select All' }}
			</a>
		</li>
		<li *ngFor="let option of dropDownOptions; let i = index;">
			<a [class.arabic-style]="isArabicLanguage" *ngIf="!optionHTML" title="{{'AdvancedSearch.selectOption' | translate}}" [attr.data-item]="i" class="small small-select" tabIndex="-1" [ngClass]="{'selected-option': selectedOptions.indexOf(option[valueKey]) > -1}" (click)="onOptionClick(option,templateConfirmPopup)">
				{{option[textKey]}}
			</a>
		</li>
	</ul>
</div>