import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appModalfocus]'
})
export class ModalfocusDirective {
  constructor(
    private el: ElementRef
  ) { }
  ngAfterViewInit() {
    setTimeout(() => {    
      this.el.nativeElement.focus();  
    })
  }   
}
