import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextAnalysisLimiterComponent } from './text-analysis-limiter/text-analysis-limiter.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
// import { MultiselectDropdownModule } from '../../../../assets/plugin/angular-2-dropdown-multiselect';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TextMaskModule } from 'angular2-text-mask';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MultiselectDropdownModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    TextMaskModule,
    PopoverModule
  ],
  declarations: [
    TextAnalysisLimiterComponent
  ],
  exports: [TextAnalysisLimiterComponent]

})
export class TextAnalysisLimiterModule { }
