<div class="search-results-parent-div coverflowstyle dynamic-search-results-parent-div" *ngIf="_toggleCoverFlow?.show">
    <!-- [class.dynamic-search-results-parent-div]="loadedFrom === 'wrapper'"  -->
  <span class="pull-right coverflow-close-icon" (click)="closeCoverFlow();">
    <i class="fa fa-close"></i>
  </span>
  <h4 class="search-results-parent-heading"> {{ 'SearchResult.SearchResults' | translate }}
    <span [class.arabic-style]="isArabicLanguage" class="resultset-span">
      <span *ngIf="(_albums?.length < totalRecoards)" class="coverflow-count"> -
        <b>{{previousOffset + 1}} to {{ previousOffset + _albums?.length}}</b> {{'explorer.of' | translate}} <b>{{ totalRecoards }}</b>
      </span>

      <span *ngIf="(_albums?.length >= totalRecoards)" class="coverflow-count"> -
        <b>{{_albums?.length}}</b> {{'explorer.of' | translate}}
        <b>{{ totalRecoards }}</b>
      </span>

      <span *ngIf="isFromPhotoExplorer"> {{'explorer.photo' | translate}}</span>
      <span *ngIf="isFromMapExplorer"> {{'explorer.map' | translate}}</span>
      <span *ngIf="isFromResultsPage"> {{'SearchResult.coverflowDocumentsCount' | translate}}</span>
    </span>

  </h4>

  <div class="search-results-left-arrow">
<a class="coverflow-image-leftarrow" [class.disabled]="isHidePrevious || _albums?.length === 0 || previousOffset === 0 || _albums?.length === totalRecoards"
      (click)="loadPrevious()" data-slide="prev">‹</a>
  </div>
  <div class="search-coverflow-body search-results-bdy">
    <div class="search-results-image">
      <div class="coverflow-innerdiv" *ngFor="let image of _albums;let i = index;" id="coverFlow{{i}}"
        [class.active-coverflow-image]="image?.pageId === currentSelectedpageId || ((image?.docId === currentSelecteddocId) && isFromResultsPage)">
        <div class="search-results-inner-div">
          <div class="coverflow-images-div">
            <div class="coverflow-thumb-image">
              <img (click)="gotoDocumentDetails(image.docId, image.pageId, i)" [src]="image.thumb" alt="Image"
                class="center-block coverflow-img">
            </div>
          </div>
          <div class="coverflow-title-div">
            <ul class="coverflow-title-list">
              <li class="document-image-titlelbl" (click)="gotoDocumentDetails(image.docId, image.pageId, i)"
                title="{{image?.title}}" appDecodeEntities>{{image?.title}}</li>
              <li class="document-image-citationlbl" *ngIf="image?.documentTitle" appDecodeEntities>
                {{'Detail.from' | translate}}
                {{image?.documentTitle}}</li>
              <li class="document-image-imagecount" *ngIf="image?.captions?.length > 0">
                <span class="caption-title"> {{'explorer.captions' | translate}} </span>
                <div class="caption-body">
                  <span *ngFor="let cap of image?.captions; let last = last" title="{{image?.captions}}"> {{cap}}
                    <span *ngIf="!last">, &nbsp;</span>
                  </span>
                </div>
              </li>
              <li class="document-image-citationlbl" *ngIf="image?.citation">
                {{image?.citation}}
              </li>
              <li *ngIf="image?.source" class="document-image-source-lbl" [attr.title]="image?.source">
                <span class="source-lbl">{{ 'Detail.Source' | translate }}: </span>
                <span class="source-lbl-value">{{image?.source}}</span>
              </li>
              <li *ngIf="image?.imageCount" class="document-image-imagecount" title="{{image?.imageCount}} {{ 'SearchResult.images' | translate }}">
                <span> {{image?.imageCount}} {{ 'SearchResult.ImagesInThis' | translate }}
                  {{_currentContentType.slice(0, -1)}}
                </span>
              </li>


            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="coverflow-loader" [ngStyle]="{display:coverflowLoader ? 'block':'none'}">
      <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
    </div> -->


  </div>
  <div class="search-results-right-arrow">
    <a class="coverflow-image-rightarrow" [class.disabled]="isHideNext || _albums?.length === totalRecoards" (click)="loadNext()">›</a>
  </div>
</div>
