/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

@Injectable()

export class SharedService {
  private dropdownConfig = {
    tools: false,
    otherArchives: false,
    archiveSelected: false,
    advanceSearch: false,
    help: false,
    language: false,
    shareDocument:  false,
    togglewileyarchivemenu : false,
    showSearchQuery: false,
    bookmarkDocument: false,
    shareURLPopup: false
  };
 
  private shareDropdownConfig = {
    showSearchQuery: false
  };

  private ShareDropdownDataObj = new BehaviorSubject<any>(this.shareDropdownConfig);
  SharedropdownToggle = this.ShareDropdownDataObj.asObservable();

  private pageIdObj = new BehaviorSubject<any>({});
  pageId = this.pageIdObj.asObservable();

  private clusterDetailsDataObj = new BehaviorSubject<any>([]);
  clusterDetailsData = this.clusterDetailsDataObj.asObservable();

  private DropdownDataObj = new BehaviorSubject<any>(this.dropdownConfig);
  dropdownToggle = this.DropdownDataObj.asObservable();

  private thumbnailDataObj = new BehaviorSubject<any>('');
  thumbnailData = this.thumbnailDataObj.asObservable();

  private textAnalysisTabSelection = new BehaviorSubject<any>({tabId: 0, advancedParam: {}});
  textAnalysisTab = this.textAnalysisTabSelection.asObservable();

  private mapRequestParam = new Subject<any>();
  mapParam = this.mapRequestParam.asObservable();

  private guestUserData = new BehaviorSubject<any>(false);
  isGuest = this.guestUserData.asObservable();

  private scrollSpyReset = new Subject();
  isResetScrollSpy = this.scrollSpyReset.asObservable();

  private documentSearchTermData = new Subject();
  documentSearchData = this.documentSearchTermData.asObservable();

  private documentDataObj = new Subject();
  documentData = this.documentDataObj.asObservable();
/* For image viewer frame title overlay start */
  private hideImageViewerTitle = new Subject();
  hideImageViewerTitleOverlay = this.hideImageViewerTitle.asObservable();

  // private updateTitleOverlay = new Subject();
  // updateImageViewerTitleOverlay = this.updateTitleOverlay.asObservable();
/* For image viewer frame title overlay end */

 // For multiple dropdown opens start
  private dropdownChange = new BehaviorSubject<string>('');
  currentMessage = this.dropdownChange.asObservable();

  private advDropdownChange = new BehaviorSubject<string>('');
  currentValue = this.advDropdownChange.asObservable();

  private archiveDropdownChange = new BehaviorSubject<string>('');
  archiveValue = this.archiveDropdownChange.asObservable();

  private toolsDropdownChange = new BehaviorSubject<string>('');
  toolsValue = this.toolsDropdownChange.asObservable();

  private otherArchiveDropdownChange = new BehaviorSubject<string>('');
  otherArchiveValue = this.otherArchiveDropdownChange.asObservable();
 // For multiple dropdown opens end

  private popupChange = new BehaviorSubject<string>('');
  headerPopup = this.popupChange.asObservable();

  /* heat map zoom to particular location */
  private zoomToView = new Subject();
  heatMapZoomView = this.zoomToView.asObservable();
  /* Update multiple changes to search term*/
  private multipleSerchTerm = new Subject();
  multipleSerchTermOberable = this.multipleSerchTerm.asObservable();

  private fullscreenChange = new BehaviorSubject<string>('');
  ipadFullScreen = this.fullscreenChange.asObservable();

  private HeaderChange = new BehaviorSubject<string>('');
  headerMenu = this.HeaderChange.asObservable();

  private relevantPagesCount = new Subject();
  relevantPagesCountOberable = this.relevantPagesCount.asObservable();

  private listChange = new Subject();
  toolsList = this.listChange.asObservable();

  private searchLoading = new BehaviorSubject<boolean>(true);
  searchLoader = this.searchLoading.asObservable();

  private TextAnalysisTabIndex = new Subject();
  TextAnalysisTabIndexObser = this.TextAnalysisTabIndex.asObservable();

  private shareTerm = new Subject();
  shareTermObjs = this.shareTerm.asObservable();

  private CroppedImageList = new BehaviorSubject<any>([]);
  croppedImageListOberable = this.CroppedImageList.asObservable();

  private advSearchArchiveSelection = new Subject<any>();
  advSearchArchiveSelectionOberable = this.advSearchArchiveSelection.asObservable();
  private invokeAdvSearch = new Subject();
  invokeAdvSearchOberable = this.invokeAdvSearch.asObservable();

  filmstripImage = new Subject();
  bindFilmstripImageOberable = this.filmstripImage.asObservable();

  private showImageViewerHighlight = new Subject();
  ImageViewerHighlight = this.showImageViewerHighlight.asObservable();
  private showitemNotes = new Subject();
  itemNotes = this.showitemNotes.asObservable();

  private tableHighltghtObj = new Subject();
  tableHighlight = this.tableHighltghtObj.asObservable();

  private isFromResultsPageObj = new Subject();
  isFromResultsPage = this.isFromResultsPageObj.asObservable();

  private illustrationOverlaysObj = new Subject();
  illustrationOverlays = this.illustrationOverlaysObj.asObservable();

  private languageObj = new Subject();
  language = this.languageObj.asObservable();

  private backtoDetailsPageFlag = new Subject();
  isDocViewerShow = this.backtoDetailsPageFlag.asObservable();

  private clearOverlays = new Subject();
  clearAllOverlays = this.clearOverlays.asObservable();

  private contentTypePhoto = new BehaviorSubject<any>(false);
  iscontentTypePhoto = this.contentTypePhoto.asObservable();

  private contentTypeMap = new BehaviorSubject<any>(false);
  iscontentTypeMap = this.contentTypeMap.asObservable();


  private errorData = new Subject();
  handleError = this.errorData.asObservable();

  private browseMap = new BehaviorSubject<any>(false);
  browseMapSatus = this.browseMap.asObservable();

  private imageObj = new Subject();
  imageObject = this.imageObj.asObservable();

  private disablefacetbasedonotherdocs = new BehaviorSubject<any>(false);
  disablefacets = this.disablefacetbasedonotherdocs.asObservable();

  private leftMenuStatus = new BehaviorSubject<any>(true);
  isleftMenuActive = this.leftMenuStatus.asObservable();

  constructor() { }
  clearOverlay(obj) {
    this.clearOverlays.next(obj);
  }

  pageIdChanged(pageId) {
    this.pageIdObj.next(pageId);
  }

  clusterDataReceived(clusterDetailsData) {
    this.clusterDetailsDataObj.next(clusterDetailsData);
  }
  dropdownToggleOption(dropdownToggle) {
    this.DropdownDataObj.next(dropdownToggle);
  }
  
  ShareDropdownToggleOption(SharedropdownToggle) {
    this.ShareDropdownDataObj.next(SharedropdownToggle);
  }

  thumbnailDataReceived(thumbnailData) {
    this.thumbnailDataObj.next(thumbnailData);
  }

  setTextAnalysis(textAnalysisTab) {
    this.textAnalysisTabSelection.next(textAnalysisTab);
  }

  mapRequestParams(mapParam) {
    this.mapRequestParam.next(mapParam);
  }

  isGuestUser(isGuest) {
    this.guestUserData.next(isGuest);
  }
  checkContentTypePhoto(iscontentTypePhoto){
    this.contentTypePhoto.next(iscontentTypePhoto);
  }
  
  checkContentTypeMap(iscontentTypeMap){
    this.contentTypeMap.next(iscontentTypeMap);
  }
  isResetScrollSpyDisable(isResetScrollSpy: any) {
    this.scrollSpyReset.next(isResetScrollSpy);
  }
  documentSearchDataReceived(documentSearchData) {
    this.documentSearchTermData.next(documentSearchData);
  }

  shareDocumentDataToSearchDetails(documentData: any) {
    this.documentDataObj.next(documentData);
  }

  // For multiple dropdown opens start
  changeheaderDropdown(message: string) {
    this.dropdownChange.next(message);
  }

  changeadvValue(message: string) {
    this.advDropdownChange.next(message);
  }

  changearchiveValue(message: string) {
    this.archiveDropdownChange.next(message);
  }

  changetoolsValue(message: string) {
    this.toolsDropdownChange.next(message);
  }

  changeotherArchivesValue(message: string) {
    this.otherArchiveDropdownChange.next(message);
  }
   // For multiple dropdown opens end
  /* For image viewer frame title overlay start */
  shareImageViewerTitleOverlay(hideImageViewerTitleOverlay: boolean) {
    this.hideImageViewerTitle.next(hideImageViewerTitleOverlay);
  }

  // updateTitleOverlayCheckbox(updateImageViewerTitleOverlay: any) {
  //   this.updateTitleOverlay.next(updateImageViewerTitleOverlay);
  // }
  /* For image viewer frame title overlay end */

  popupChanges(msg: any) {
    this.popupChange.next(msg);

  }

  setZoomLocation(heatMapZoomView: boolean) {
    this.zoomToView.next(heatMapZoomView);
  }

  setSearchTermToDetails(multipleSerchTermOberable: any) {
    this.multipleSerchTerm.next(multipleSerchTermOberable);
  }

  setFullScreen(ipadFullScreen: any) {
    this.fullscreenChange.next(ipadFullScreen);
  }

  setHeader(headerMenu: any) {
    this.HeaderChange.next(headerMenu);
  }

  setRelavantPagesDetails(relevantPagesCountOberable: any) {
    this.relevantPagesCount.next(relevantPagesCountOberable);
  }

  setList(toolsList: any) {
    this.listChange.next(toolsList);
  }

  setSearchLoading(data: boolean) {
    this.searchLoading.next(data);
  }
  setTextAnalysisTabIndex(index: any) {
    this.TextAnalysisTabIndex.next(index);
  }

  setShredTerms(term: any) {
    this.shareTerm.next(term);
  }
  setCroppedImageList(list: any) {
    this.CroppedImageList.next(list);
  }

  setAdvSearchArchiveSelection(data: any) {
    this.advSearchArchiveSelection.next(data);
  }
  invokeAdvSearchCall(data: any) {
    this.invokeAdvSearch.next(data);
  }
  filmstripImageList(image: any) {
    this.filmstripImage.next(image);
  }

  shareImageViewerHighlight(ImageViewerHighlight: boolean) {
    this.showImageViewerHighlight.next(ImageViewerHighlight);
  }
  shareitemNotes(itemNotes: boolean) {
    this.showitemNotes.next(itemNotes);
  }
  tableHighlightChanged(tableHighlight) {
    this.tableHighltghtObj.next(tableHighlight);
  }
  checkIsFromResultsPage(isFromResultsPage) {
    this.isFromResultsPageObj.next(isFromResultsPage);
  }
  shareIllustrationOverlays(illustrationOverlays) {
    this.illustrationOverlaysObj.next(illustrationOverlays);
  }
  shareCurrentLanguage(language) {
    this.languageObj.next(language);
  }
  sharebacktoDetailsPageFlag(isDocViewerShow) {
    this.backtoDetailsPageFlag.next(isDocViewerShow);
  }

  sendErrorToService(data: any) {
    this.errorData.next(data);
  }
  updatedBrowseMapSatus(browseMapSatus) {
    this.browseMap.next(browseMapSatus);
  }
  shareimageObject(imageObject) {
    this.imageObj.next(imageObject);
  }
  updateFacetFlag(disablefacets) {
    this.disablefacetbasedonotherdocs.next(disablefacets);
  }

  updateLeftMenuStatus(isleftMenuActive) {
    this.leftMenuStatus.next(isleftMenuActive);
  }
}

