<!--  Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->
<div class="overlay-list" *ngIf="isHiglightisOn">
  <a [class.arabic-style]="isArabicLanguage" class="toggleHighlight" (click)="toggleHighlight()">
    {{ 'Detail.Highlight' | translate }}
    <span [class.arabic-style]="isArabicLanguage" *ngIf="!toggleHighlighter">{{ 'Detail.Off' | translate }}</span>
    <span [class.arabic-style]="isArabicLanguage" *ngIf="toggleHighlighter">{{ 'Detail.On' | translate }}</span>
  </a>
</div>
<span *ngIf="!hideOverlay && hideTitle" class="fa fa-chevron-up hidetitle-rarrow show-uparrow" [class.itemtitle-overlay-open]="itemNotes" (click)="showTitleOverlay()"></span>
<div class="overlay" *ngIf="!hideTitle">
    <span class="fa fa-chevron-down hidetitle-rarrow" [class.itemtitle-overlay-close]="itemNotes" (click)="hideTitleOverlay()"></span> 
    <div [class.arabic-style]="isArabicLanguage" class="text-white item-overlay-txt" #textContent id="read-more{{itemNote}}">
        {{ 'Detail.imageItemTitle' | translate }}: {{itemNote}}
    </div>
    <div *ngIf="itemNotes" class="text-white item-notes-content"> 
      <span [class.arabic-style]="isArabicLanguage" class="notes-content">{{ 'Detail.Notes' | translate }}:</span>
      <ng-container *ngFor="let notes of itemNotes">          
              <span class="notes-content">{{notes?.value}}</span>
        </ng-container>

    </div>
      <!-- <div  class="view-more-text">
        <a role="button" readMore [readMore-length]="200" [readMore-element]="textContent">
          <span [class.arabic-style]="isArabicLanguage" class="less pull-right">{{ 'HomePage.viewLess' | translate }}
            <i class="btn btn-up-arrow fa fa-angle-up" aria-hidden="true"></i>
          </span>
          <span [class.arabic-style]="isArabicLanguage" class="more pull-right">{{ 'HomePage.viewMore' | translate }}
            <i class="btn btn-down-arrow fa fa-angle-down" aria-hidden="true"></i>
          </span>
        </a>
      </div> -->
      <ng-template *ngIf="itemNote">
      <div *ngIf="itemNote.length > 150" class="view-more-text">
          <a role="button" *ngIf='viewMorebtn' (click)='viewMorebtn = false'>
            <span class="less pull-right" >{{ 'HomePage.viewLess' | translate }}
              <i class="btn btn-up-arrow fa fa-angle-up" aria-hidden="true"></i>
            </span>
            </a>
            <a  role="button" >
            <span class="more pull-right" *ngIf='!viewMorebtn'  (click)='viewMorebtn = true'>{{ 'HomePage.viewMore' | translate }}
              <i class="btn btn-down-arrow fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </a>
        </div>
      </ng-template>
</div>

<div id="openSeaDragonCanvas" (mouseover)="imageViewerMouseEnter()" (mouseleave) ="imageViewerMouseLeave()">
  <div class="image-viewer-frame-loader" [ngStyle]="{display:imageviewerframeLoader ? 'block':'none'}">
    <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
  </div>
  <div *ngIf="!hideTitle" id="tableOverlayTooltip" class="table-overlay-tooltip" style="display: none;">
    <p [class.arabic-style]="isArabicLanguage" class="overlay-tooltip">   
      {{ 'HomePage.clickToDownloadFile' | translate}}
    </p>
  </div>
</div>
<div class="backdrop-modal"></div>
<div class="modal fade fullScreenModal-popup" id="fullScreenModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop ="static" >
    <div class="modal-dialog large" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)="removeBackDrop()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 [class.arabic-style]="isArabicLanguage" class="cliped-image-details">
              {{ 'HomePage.describeClippedImage' | translate}}
            </h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                  <label [class.arabic-style]="isArabicLanguage" class="pull-left custom-title"> {{ 'HomePage.clipImageTitle' | translate}}:</label>
                  <span class="glyphicon glyphicon-asterisk mandatory-icon"></span>
                  <input type="text" maxlength="100" value="{{ 'HomePage.clipImage' | translate}} {{(clippedImageList?.length || 0) + 1}}" class="form-control" name="customTitle" #customTitleRefModal>
                </div>
                <div class="col-md-12">
                    <label [class.arabic-style]="isArabicLanguage" class="pull-left custom-title"> {{ 'HomePage.clipImageNotes' | translate}}:</label>
                    <textarea class="form-control custom-textarea" maxlength="500" name="customTitle" #customNotesRefModal></textarea>
                  </div>
                <div class="col-md-12">
                  <br/>
                  <button type="button" class="btn btn-primary pull-right" (click)="updateTitle(customTitleRefModal.value.trim(), customNotesRefModal.value.trim())" [disabled]="!customTitleRefModal.value.trim()"> {{ 'SearchResult.AddToList' | translate}} </button>
                </div>
              </div>
        </div>
        
      </div>
    </div>
  </div>

<button [class.arabic-style]="isArabicLanguage" id="zoomImage" (click)="zoomInImage(0.1)">{{'Common.Zoom' | translate}}</button>
<!-- <button id="zoomImage" (click)="zoomInImage(-1)">Zoom</button> -->
<ng-template #customTitleModal>
  <div class="modal-header custom-modal-header">
    <h4 [class.arabic-style]="isArabicLanguage" class="cliped-image-details">
      {{ 'HomePage.describeClippedImage' | translate}}
    </h4>
    <button type="button" class="close pull-right clipimage-close-btn" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label [class.arabic-style]="isArabicLanguage" class="pull-left custom-title"> {{ 'HomePage.clipImageTitle' | translate}}:</label>
        <span class="glyphicon glyphicon-asterisk mandatory-icon"></span>
        <input type="text" maxlength="100" value="{{ 'HomePage.clipImage' | translate}} {{(clippedImageList?.length || 0) + 1}}" class="form-control" name="customTitle" #customTitleRef>
      </div>
      <div class="col-md-12">
          <label [class.arabic-style]="isArabicLanguage" class="pull-left custom-title"> {{ 'HomePage.clipImageNotes' | translate}}:</label>
          <textarea class="form-control custom-textarea" maxlength="500" name="customTitle" #customNotesRef></textarea>
        </div>
      <div class="col-md-12">
        <br/>
        <button type="button" class="btn btn-primary pull-right" (click)="updateTitle(customTitleRef.value.trim(), customNotesRef.value.trim())" [disabled]="!customTitleRef.value.trim()"> {{ 'SearchResult.AddToList' | translate}} </button>
      </div>
    </div>
  </div>
</ng-template>


<div class="backdrop-modal"></div>
<div class="modal fade fullScreenDownload-popup" id="fullScreenDownloadPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  data-backdrop="static">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <button type="button" class="close" data-dismiss="modal" (click)="removeBackDrop()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
        <h4  class="modal-title sa-icon sa-info pulseWarning">
        </h4>        
      </div>
      <div class="modal-body url_body">
        <div class="well">
          <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadableUrl}}" target="_blank" *ngIf="downloadUrlFor === 'table'">{{ 'Detail.downloadSpreadsheetFile' | translate }}</a>
          <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadableUrl}}" target="_blank" *ngIf="downloadUrlFor === 'pdf'">{{ 'Detail.downloadTextDoc' | translate }}</a>
          <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadableUrl}}" target="_blank" *ngIf="downloadUrlFor === 'fulltext'">{{ 'Detail.downloadFulltext' | translate }}</a>
        </div>
        <div class="modal-download-text">
          <span [class.arabic-style]="isArabicLanguage" class="modal-download-text-span"> {{ 'Detail.clicktoDownloadFileMessage' | translate }}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary" data-dismiss="modal" (click)="removeBackDrop()">
          {{ 'Detail.Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>