/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {
  Component,
  AfterContentInit,
  Input,
  TemplateRef,
  ElementRef,
  HostListener,
  ViewChild,
  Inject,
  EventEmitter,
  Output,
  ChangeDetectorRef
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchService } from './../common/services/search.service';
import { PageSettingsService } from './../common/services/page-settings.service';
import { UrlUtilityService } from '../common/services/url-utility.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from './../common/services/modal.service';
import { HttpService } from './../common/services/http.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Overlay } from './../new-image-viewer-frame/ImageSource';
import { environment, APIURLS, rtlLanguages, stopWords } from '../../environments/environment';
import { UtilityService } from '../common/services/utility.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NumberSymbol } from '@angular/common';
import { SharedService } from '../common/services/sharedService';
import { OnInit, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map as _map, uniq as _uniq, uniqBy as _uniqBy } from 'lodash';
import { AuthService } from '../common/services/auth.service';
import { ITreeOptions } from '@circlon/angular-tree-component';
import { CommonService } from '../common/services/common.service';
import { SearchResultDetailService } from './search-result-detail.service';
// import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { isObject } from 'util';
declare let $: any;
// declare let addthis: any;
const GROUPLIMIT: NumberSymbol = 5;
declare let OpenSeadragon: any;


@Component({
  selector: 'app-search-result-details',
  templateUrl: './search-result-details.component.html',
  styleUrls: ['./search-result-details.component.css'],
  providers: [SearchResultDetailService]
})
export class SearchResultDetailsComponent implements AfterContentInit, OnInit, OnDestroy {
  isFromFrequencyDistribution: Boolean = false;
  isFromConcordance: Boolean = false;
  guestAuthToken: Boolean = false;
  isFromCollection: Boolean = false;
  spatialFilterParams: any;
  toggleCoverFlow: any;
  hideCoverFlow: any;
  startValueFromParams: any;
  copyAdvancedFilterParam: any = {};
  advSearchTerms: any;
  showExplorerCoverFlowData: Boolean = false;
  isBookmarkPopupOpen: Boolean = false;
  locationConfigData: any;
  isFromMapExplorerPage: Boolean = false;
  isFromPhotoExplorerPage: Boolean = false;
  showWebsites: boolean;
  supportedLanguagesList: any = [];
  selectLanguageNotofication: any;
  isRefershPage = false;
  currentAngleValue = 0;
  tabs: any = [
    // { id: 'itemsTab', customClass: 'itemsIcon-tabs' },
    // { id: 'tocTabs', customClass: 'tocIcon-tabs' },
    // { id: 'notesTab', customClass: 'notesIcon-tabs' },
    // { id: 'illustrationTab', customClass: 'illustrationIcon-tabs'},
    // { id: 'relatedSubjectsTab', customClass: 'relatedsubIcon-tabs'},
    // { id: 'volumesTab', customClass: 'relatedsubIcon-tabs'}
  ];
  isFromTermGroupsPage: Boolean = false;
  showOverlayValue: any;
  getoverlayOption: any;
  passNewOverlayValue: any;
  clipBoardValue: any;
  public type: String = 'component';
  @Output('coverFlowDocumentID') coverFlowDocumentID: EventEmitter<any> = new EventEmitter();
  isSearchResultsShow: Boolean = false;
  // public config: PerfectScrollbarConfigInterface = {};

  // public coverflowConfig: PerfectScrollbarConfigInterface = {};
  // @ViewChild(PerfectScrollbarComponent) perfectScrollbarCompRef?: PerfectScrollbarComponent;
  // @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  // @ViewChild('tree') tree;
  // @ViewChild('PerfectScrollbarComponent', { static: false }) perfectScrollbarCompRef?: PerfectScrollbarComponent;
  // @ViewChild('PerfectScrollbarDirective', { static: false }) directiveRef?: PerfectScrollbarDirective;
  @ViewChild('tree', { static: false }) tree;

  isArabicLanguage: Boolean = false;
  loader = { relatedVolume: false, relatedSubjects: false };
  relatedVolumes: any[] = [];
  listViewThumbActive: number;
  gridViewThumbActive: number;
  isGridViewEnable: Boolean = true;
  overlaysForTermSearch: any;
  collectionLoading: boolean;
  collectionNotes: { type: string; value: any; }[];
  collectionResponse: any;
  toggleCollectionNotesIcon: never;
  toggleNotesIcon: never;
  fuzzyChecked: Boolean = false;
  showItemNotes: Boolean = false;
  selecrPage: Boolean = false;
  toggledisclaimerShow: Boolean = false;
  searchWithInLoading: boolean;
  illustrationsOverlays: any = [];
  matchedItemTitles: any = [];
  showFillterByRelevantPage: Boolean = false;
  documentPageImages: any = [];
  pageFullTextPageIds: any;
  unSubPageId: Subscription;
  isSearchTermIncludesPhrase: boolean;
  isNoRecodesFound: Boolean = false;
  currentDocID: any;
  splitedcheckedTerms: any;
  documentSearchTermSplitted: any = [];
  documentsplittedterms = [];
  splitedSearchTerms: any;
  isTermChecked: Boolean = true;
  toggleMetadataIcon: Boolean = false;
  tooglePagesIcon: Boolean = false;
  toggleFulltextIcon: Boolean = false;
  itemNotesLength: any;
  pageFullTextObj = [];
  fullTextObj: any;
  // @ViewChild('leftMenuTabset') private leftMenuTabset: TabsetComponent;
  // @ViewChild('itemsTabset') private itemsTabset: TabsetComponent;
  // @ViewChild('documentSearch') documentSearchField: ElementRef;
  @ViewChild('leftMenuTabset', { static: false }) private leftMenuTabset: TabsetComponent;
  @ViewChild('itemsTabset', { static: false }) private itemsTabset: TabsetComponent;
  @ViewChild('documentSearch', { static: false }) documentSearchField: ElementRef;

  currentSearchTerm: any;
  isFillterByRelevantPage: Boolean = false;
  detailsRedirectParams: any;
  pageId: any;
  isItemsNoteAvailable: Boolean = false;
  itemTitleShow: Boolean = false;
  isTitleAvailable: Boolean = false;
  itemTabEnable: Boolean = false;
  docutypeDetails: any;
  documentNotesFromNotes = [];
  documentImagesWithTitles: any;
  itemsTypeResultLoader = true;
  manuscriptResultLoader = true;
  monographResultLoader = true;
  illustrationresultLoader = true;
  resultLoader = true;
  imageList: any;
  checkModel: any = { document: true, fullText: false };
  searchtermWithSpaceTerms: any;
  searchTermwithSpaces: any;
  initialSearchTerm: any;
  documentSingleTerm: any;
  documentSimpleSearchTerm: any;
  documentSearchTerm: any;
  private itemNotesmodalRef: BsModalRef;
  // @ViewChild('itemNotesTemplate') private itemNotesTemplate: TemplateRef<any>;
  @ViewChild('itemNotesTemplate', { static: false }) private itemNotesTemplate: TemplateRef<any>;

  itemNotes: any;
  isItmesTitleIcon: Boolean = false;
  unSubFilmstripImage: Subscription;
  currentDocNumberFromThumbnail: any;
  currentDocumentNumber: any;
  isFullTextActive: Boolean = false;
  relavantPageData: any;
  enableFuzzySearch: Boolean = false;
  keyword: any;
  toggleSearch: Boolean = false;
  documentFulltextModel: any = {
    onColor: 'primary',
    offColor: 'primary',
    onText: 'Document',
    offText: 'Fulltext',
    disabled: false,
    size: 'large',
    value: true
  };
  currentPageId: any;
  private sourceCitationmodalRef: BsModalRef;
  // @ViewChild('sourceCitationTemplate') private sourceCitationTemplate: TemplateRef<any>;
  @ViewChild('sourceCitationTemplate', { static: false }) private sourceCitationTemplate: TemplateRef<any>;

  isItemByTypeSequence = false;
  isItemByTypeOpen = true;
  documentItemOptions = ['Items By Type', 'Items By Sequence'];
  isDocumentFullTextOpen = false;
  isCitationTabOpen = false;
  isDocumentTabOpen = true;
  documentDropdownOptions = ['Document', 'Citation', 'FullText'];
  illustrationPageID: any;
  illustrationDocID: any;
  illustrationDocumentImage: any;
  illustrationsLoader = false;
  showIllustrations = false;
  illustrationTreeNodes: any[];
  monographTableofContentNodes: any[];
  manuscriptOrderTableofContentNodes: any[];
  sequenceFullTitle: any;
  parentItemTile: any;
  selectedDocPageId = { pageId: '' };
  @Output('pagechanged') private pagechanged: EventEmitter<any> = new EventEmitter();
  @Output('searchTermChanged') private searchTermChanged: EventEmitter<any> = new EventEmitter();
  itemFullTitle: string;
  pageLength: string;
  @Output('itemsTitle') private itemsTitle: EventEmitter<any> = new EventEmitter();
  manuscriptTableofContentOptions: ITreeOptions = {
    idField: 'uuid',
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    isExpandedField: 'expanded'
  };
  options1: ITreeOptions = {
    getChildren: () => new Promise((resolve, reject) => { })
  };

  options0: ITreeOptions = {
    displayField: 'title',
    nodeClass: (node) => `${node.data.title}Class`
  };
  manuscriptTypeTableofContentNodes = [];
  isRelatedSubject = false;
  shareMailLoader: Boolean = false;
  downloadUrlFor: string;
  downloadableUrl: any;
  downloadTablemodalRef: BsModalRef;
  translatedValuesOfDetail: any;
  translatedValuesOfMetadata: any;
  matchedRecordsMetaData: any;
  titleName: any;
  warningObj: any = [];
  warningObj1: any = [];
  private warningssmodalRef: BsModalRef;
  // @ViewChild('warningsTemplate') private warningsTemplate: TemplateRef<any>;
  @ViewChild('warningsTemplate', { static: false }) private warningsTemplate: TemplateRef<any>;

  pageContent: any;
  isNeedToLogout: string;
  unSubActivatedRoute: Subscription;
  invalidPagenumber: any;
  limitReachExceded: any;
  limitReachExcededPage: any;
  hasDocumentAccess: boolean;
  documentCurrentPageId: any;
  documentCurrentIndex: any;
  downloadDocumentlanguage: any;
  readyToDownloadLanguages = [];
  downloadDocumentlanguageCode: any;
  sessionLanguageKeys = [];
  currentLanguageCode: any;
  currentDocumentId: any;
  downloadDocumentId: any;
  sessionDownloadDocuments: any;
  entireDocumentData: any = [];
  translatedDocument: any;
  errorTranslatedDocument: any;
  // @ViewChild('languagePageTemplate') private languagePageTemplate: TemplateRef<any>;
  @ViewChild('languagePageTemplate', { static: false }) private languagePageTemplate: TemplateRef<any>;

  docNotes = [];
  doctypeDetails;
  isCollapsed: Boolean = true;
  currentSelectedLanguageValue: any;
  toggleTranslate = true;
  toggleMenu = false;
  currentLanguageForTranslate: any;
  currentLanguage: any;
  unSubPageSettingsObservable: Subscription;
  unSubViewListObservable: Subscription;
  unSubDocumentData: Subscription;
  unSubIsGuest: Subscription;
  unSubHeaderPopup: Subscription;
  unSubIpadFullScreen: Subscription;
  unSubMultipleSerchTermOberable: Subscription;
  translatedValues: any;
  textCopiedtoClipboard: any;
  citationCopiedtoClipboard: any;
  copytoClipboard: any;
  useFollowingLinktoAccessContent: any;
  emailNotDelivered: any;
  mailSent: any;
  pageLoadinFailed: string;
  searchResults: string;
  searchTermNotFound: string;
  currentDate: any;
  _isFuzzySearch = true;
  isGuestUser: boolean;
  searchTermCopy: any;
  citationDescription: any;
  citaionMLA: Boolean = false;
  citaionAPA: Boolean = false;
  citaionCHICAGO: Boolean = false;
  modelcitaionMLA: Boolean = false;
  modelcitaionAPA: Boolean = false;
  modelcitaionCHICAGO: Boolean = false;
  isInIpadMode: Boolean = false;
  translateLanguageValues: any;
  selectedPageId: any = '';
  shareUrl: any;
  private collectionId: any;
  private sourceArchive: any;
  message: any;
  private currentImageIndex: any;
  coverflowImagesCount: any = 1;
  private carousalImageCount: Number = 5;
  contentType: any;
  pageContentData: any;
  private advancedFilterParam: AdvancedFilterParams;
  private showledtDetailsmenu: boolean;
  documentData: any;
  private _searchTerm: string;
  private pageSettingsObservable: Subscription;
  private paramObservable: Subscription;
  documentFulltext: any;
  documentImages = [];
  showRelevantImages = false;
  bookmarkPageURL: any;
  private bookDetailsMarkmodalRef: BsModalRef;
  // @ViewChild('translatepop') translate_pop: PopoverDirective;
  // @ViewChild('bookmarkHtmlPageTemplate') private bookmarkHtmlPageTemplate: TemplateRef<any>;
  // @ViewChild('downloadTableTemplate') private downloadTableTemplate: TemplateRef<any>;
  // @ViewChild('translateFulltextpopup') translateFulltextpopup: PopoverDirective;
  @ViewChild('translatepop', { static: false }) translate_pop: PopoverDirective;
  @ViewChild('bookmarkHtmlPageTemplate', { static: false }) private bookmarkHtmlPageTemplate: TemplateRef<any>;
  @ViewChild('downloadTableTemplate', { static: false }) private downloadTableTemplate: TemplateRef<any>;
  @ViewChild('translateFulltextpopup', { static: false }) translateFulltextpopup: PopoverDirective;

  private sharemodalRef: BsModalRef;
  // @ViewChild('shareHtmlPageTemplate') private shareHtmlPageTemplate: TemplateRef<any>;
  @ViewChild('shareHtmlPageTemplate', { static: false }) private shareHtmlPageTemplate: TemplateRef<any>;

  private coveflowSlidermodalRef: BsModalRef;
  // @ViewChild('coveflowSliderTemplate') private coveflowSliderTemplate: TemplateRef<any>;
  @ViewChild('coveflowSliderTemplate', { static: false }) private coveflowSliderTemplate: TemplateRef<any>;

  showClipBoardSuccessAlert: boolean;
  // @ViewChild('bookmarkPageURLonTemplate', { read: ElementRef })
  // @ViewChild('bookmarkPageHtmlTemplate') private bookmarkPageHtmlTemplate: TemplateRef<any>;
  // @ViewChild('citationPageTemplate') private citationPageTemplate: TemplateRef<any>;
  // @ViewChild('carousel') private carouselContainer: ElementRef;
  // @ViewChild('documentDetailsTab') private docDetailsTab: TabsetComponent;
  @ViewChild('bookmarkPageURLonTemplate', { static: false, read: ElementRef }) bookmarkPageURLonTemplate: ElementRef;
  @ViewChild('bookmarkPageHtmlTemplate', { static: false }) private bookmarkPageHtmlTemplate: TemplateRef<any>;
  @ViewChild('citationPageTemplate', { static: false }) private citationPageTemplate: TemplateRef<any>;
  @ViewChild('carousel', { static: false }) private carouselContainer: ElementRef;
  @ViewChild('documentDetailsTab', { static: false }) private docDetailsTab: TabsetComponent;

  private citationPagemodalRef: BsModalRef;
  // private bookmarkPageURLonTemplate: ElementRef;
  private resultPageURL: any;
  viewList: any;
  private viewListCount: number;
  defaultImageToBeLoaded: any;
  private downloadImages: any;
  private toggleviewlist: Boolean = false;
  emptyCitation: Boolean = false;
  imageViewerOptions: any = {
    height: 950,
    showBrightness: true,
    showZoom: true,
    showRotate: true,
    showShare: true,
    showFullScreen: true,
    showPrint: true,
    showDownload: true,
    showExpand: true,
    showDocument: false,
  };
  coverFlowImages: any = [];
  homePageUrl: any;
  shareMailData: ShareMail = {
    'topic': '',
    'message': '',
    'to': '',
    'from': ''
  };
  private citationmodalRef: any;
  private languagemodalRef: any;
  private currentSelectedPagId: any;
  citationRes: any;
  isSessionGuest: any;
  // @ViewChild('disclaimerContents', { read: ElementRef }) private disclaimerContents: ElementRef;
  // @ViewChild('citation_content', { read: ElementRef }) private citation_content: ElementRef;
  @ViewChild('disclaimerContents', { static: false, read: ElementRef }) private disclaimerContents: ElementRef;
  @ViewChild('citation_content', { static: false, read: ElementRef }) private citation_content: ElementRef;

  @Input('itemTitle') itemTitle: any;
  hideOnIpad: Boolean;
  toggleArrow = true;
  documentViewer = true;
  showDocSwitch = false;
  pageFulltextMatchedData = [];
  showHelp: Boolean = false;
  fulltextDisable: Boolean = true;
  scrollTwo: Boolean = false;
  scrollFour: Boolean = false;
  scrollSix: Boolean = false;
  scrollFix: Boolean = false;
  disclaimerShow: Boolean = true;
  currentTabId: any;
  controlShortcut: any;
  shortcutKeyTitle: any;
  fullTextLoader: Boolean = false;
  shiftContrlKey: any;
  dropdownValue: any;
  // @Input('documentData')
  // set _documentData(value: any) {
  //   if (value) {
  //     this.isRefershPage = false;
  //     this.documentData = value;
  //     // DESC: Before pussing tab items inside the tab, empty (clear) the tab array
  //     this.tabs = [];
  //     (this.documentData.docType === 'Monographs') ? this.hideItemsTab() : this.hideTocTab();
  //     if (this.documentData.docType === 'Monographs') {
  //       this.tabs.push({ id: 'tocTabs', customClass: 'tocIcon-tabs' });
  //     } else {
  //       this.tabs.push({ id: 'itemsTab', customClass: 'itemsIcon-tabs' });
  //     }
  //     if (this.documentData.docNoteExist || this.documentData.collectionNoteExist) {
  //       this.tabs.push({ id: 'notesTab', customClass: 'notesIcon-tabs' });
  //     }
  //     if (this.documentData.illustrationExist) {
  //       this.tabs.push({ id: 'illustrationTab', customClass: 'illustrationIcon-tabs' });
  //     }
  //     if (this.documentData.subjectExist) {
  //       this.tabs.push({ id: 'relatedSubjectsTab', customClass: 'relatedsubIcon-tabs' });
  //     }
  //     if (this.documentData.hasVolumes) {
  //       this.tabs.push({ id: 'volumesTab', customClass: 'relatedsubIcon-tabs' });
  //     }
  //     if (this.documentData['spatialFilterParams']) {
  //       this.spatialFilterParams = this.documentData['spatialFilterParams'];
  //     }
  //   }
  //   if (this.documentData) {
  //     this.checkShowWebsite();
  //   }
  // }

  // get _documentData() {
  //   return this.documentData;
  // }
  @Input('searchTerm')
  set searchTerm(value: string) {
    if (value) {
      this._searchTerm = value['keyword'];
      this._isFuzzySearch = value['fuzzy'];
      if (this.documentData) {
        if (this.documentData['wileyId']) {
        }
      }
    }
  }
  get searchTerm() {
    return this._searchTerm;
  }

  _selectedPage;
  @Input('selected-page')
  set selectedPage(page: any) {
    if (this.docDetailsTab) {
      this.docDetailsTab.tabs[0].active = true;
    }
    if (page) {
      this._selectedPage = page;
      this.callGetPageLevelFullText(this._selectedPage.pageId);
      this.selectedPageId = this._selectedPage.pageId;
    }
  }
  get selectedPage() {
    return this._selectedPage;
  }

  _selectedPageOverlay;
  @Input('selected-pageoverlay')
  set selectedPageOverlay(overlay: any) {
    if (overlay) {
      this.selectedPageOverlay = overlay;
      this.callGetPageLevelFullText(this._selectedPageOverlay.pageId);
      this.selectedPageId = this._selectedPageOverlay.pageId;
    }
  }
  get selectedPageOverlay() {
    return this._selectedPageOverlay;
  }

  currentSlideNumber = 0;
  private illustrationNodes = [];
  private slideConfig = { 'slidesToShow': 4, 'slidesToScroll': 4 };
  isFromResultPage = false;
  private accessToken: any;
  @Input() set illustrationData(data: any) {
    if (data) {
      this.illustrationNodes = data;
    }
  }
  get illustrationData() {
    return this.illustrationNodes;
  }

  @Input() set docIdInput(data: any) {
    if (data) {
      this.getDocumentDetails(data.docId);
      if (data.isFromResultsPage) {
        this.isFromResultPage = data.isFromResultsPage;
      }
      if (data.spatialFilterParams) {
        this.spatialFilterParams = data.spatialFilterParams;
      }
      if (data.start) {
        this.startValueFromParams = data.start ? data.start : 0;
      }
      this.defaultImageToBeLoaded = data;
      if (this.defaultImageToBeLoaded['docId']) {
        this.itemsByType(this.defaultImageToBeLoaded['docId']);
        this.searchService.getIllustraion(this.defaultImageToBeLoaded['docId'], (response) => {
          const parentNodes = [];
          if (response) {
            const contents = Object.keys(response);
            if (Object.keys(response).length > 0) {
              this.showIllustrations = true;
            } else {
              this.hideIllustrations();
            }
          }
        });
      }
    }
  }
  get docIdInput() {
    return this.defaultImageToBeLoaded;
  }

  docTab: Boolean = false;
  relatedSubjectsData: any;
  documentIDFromRouteParams: any;
  @Output('docViewerClosed') docViewerClosed: EventEmitter<any> = new EventEmitter();
  invokedFrom: any;

  matchedMetadata: any;
  currentDocumentID: any;
  isMainImageLoaded: Boolean = false;
  // _invokedFrom: any;
  @Input('invokedFromTab')
  set invokedFromTab(value: any) {
    if (value) {
      this.invokedFrom = value;
    }
  }
  get invokedFromTab() {
    return this.invokedFrom;
  }

  loadedFrom: String;
  @Input('loadedFrom')
  set _loadedFrom(value: any) {
    if (value) {
      this.loadedFrom = value;
    }
  }
  get _loadedFrom() {
    return this.loadedFrom;
  }

  scrollToPosition: Object;
  coverflowIndex: any = 0;
  locationsConfigDetails: Object;
  illustrationPreviewPosition: any;
  // @ViewChild('illustrationsTab') illustrationsTabElement: ElementRef;
  // @ViewChild('illustrationPreviewWindow') illustrationPreviewElement: ElementRef;
  @ViewChild('illustrationsTab', { static: false }) illustrationsTabElement: ElementRef;
  @ViewChild('illustrationPreviewWindow', { static: false }) illustrationPreviewElement: ElementRef;

  advancedFilterParamForCoverFlow: any;
  searchTermFromExplorer: String;
  constructor(
    private modalService: ModalService,
    private pageSettingService: PageSettingsService,
    @Inject(DOCUMENT) private dom: Document,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private bsModalService: BsModalService,
    private renderer: Renderer2,
    private urlUtilityService: UrlUtilityService,
    private utilityService: UtilityService,
    private location: Location,
    private sharedService: SharedService,
    private translate: TranslateService,
    private popupService: ModalService,
    private http: HttpClient,
    private commonService: CommonService,
    private authService: AuthService,
    private searchResultDetailService: SearchResultDetailService,
    private _changeRef: ChangeDetectorRef
  ) {
    this.enableFuzzySearch = false;
    this.docTab = false;
    this.isSessionGuest = localStorage.getItem('accessType');
    this.documentCurrentIndex = 1;
    this.accessToken = this.httpService.getCurrentAccessToken();
    this.currentDate = new Date();
    this.isGuestUser = false;
    this.unSubPageSettingsObservable = PageSettingsService.pageSettingsObservable.subscribe((data) => {
      this.pageContentData = data;
    });
    this.unSubViewListObservable = PageSettingsService.ViewListObservable.subscribe((data) => {
      this.viewList = data.viewList;
      this.viewListCount = data.count;
    });

    this.resultPageURL = sessionStorage.getItem('currentUrl');
    this.paramObservable = this.activatedRoute.params.subscribe(params => {
      if (params['guestAuthToken']) {
        this.guestAuthToken = true;
      }
      if (params['isFromPhotoExplorer']) {
        this.isFromPhotoExplorerPage = true;
      }
      if (params['isFromMapExplorer']) {
        this.isFromMapExplorerPage = true;
      }
      if (params['isFromTermGroupsPage']) {
        this.isFromTermGroupsPage = true;
      }
      if (params['isFromCollectionDetailsPage']) {
        this.isFromCollection = true;
      }
      if (params['isFromFD']) {
        this.isFromFrequencyDistribution = true;
      }
      if (params['isFromConcordance']) {
        this.isFromConcordance = true;
      }
      if (params['start']) {
        this.startValueFromParams = params['start'];
      }
      this.documentIDFromRouteParams = params['docID'];

      if (params['docID']) {
        this.getDocumentDetails(params['docID']);
      }


      this.itemsByType(params['docID']);
      this.searchService.getIllustraion(this.documentIDFromRouteParams, (response) => {
        const parentNodes = [];
        if (response) {
          const contents = Object.keys(response);
          if (Object.keys(response).length > 0) {
            this.showIllustrations = true;
          } else {
            this.hideIllustrations();
          }
        }
      });
      this._searchTerm = params['searchTerm'];
      this.searchTermFromExplorer = params['searchTerm'];

      if (params['searchTerm']) {
        if (params['searchTerm'].includes('"')) {
          this.isSearchTermIncludesPhrase = true;
          const splitedWord = params['searchTerm'].match(/\w+|"[^"]+"/g);
          splitedWord.forEach(obj => {
            if (obj !== '') {
              this.documentSearchTermSplitted.push({ value: obj, checked: true });
            }
          });
          setTimeout(() => {
            this.searchWithin(this.documentSearchTermSplitted, true);
          }, 1500);
        } else if (params['searchTerm']) {
          this.documentSearchTermSplitted = [{ value: params['searchTerm'], checked: true }];
        }
      }
      this.searchTermCopy = params['searchTerm'];
      if (params['isFromResultsPage']) {
        this.isFromResultPage = params['isFromResultsPage'];
      }
      this.defaultImageToBeLoaded = this.pageId = params['pageId'] ? params['pageId'] : '';
      const filterParams = params['filterParam'] || params['filter'];
      this.urlUtilityService.parseUrl(filterParams).subscribe((data) => {
        this.advancedFilterParam = new AdvancedFilterParams(data);
        this.advancedFilterParamForCoverFlow = this.advancedFilterParam;
      });

      const searchKeywordLength: Number = this.advancedFilterParam.searchKeywords.length;
      let counter = 1;
      let advancedSearchKeyword = '';
      this.advancedFilterParam.searchKeywords.forEach(element => {
        if (element.fieldName === 'ALL') {
          if (counter < searchKeywordLength) {
            advancedSearchKeyword += element.fieldValue + ' ';
          } else {
            advancedSearchKeyword += element.fieldValue;
          }
          counter++;
        }
      });
      if (advancedSearchKeyword !== '  ') {
        if (params['searchTerm'].includes('"') && advancedSearchKeyword.includes('"')) {
          this.isSearchTermIncludesPhrase = true;
          const searchKeyword = params['searchTerm'].concat(advancedSearchKeyword);
          const splitedWord = searchKeyword.match(/\w+|"[^"]+"/g);
          splitedWord.forEach(obj => {
            if (obj !== '') {
              this.documentSearchTermSplitted.push({ value: obj, checked: true });
            }
          });
          setTimeout(() => {
            this.searchWithin(this.documentSearchTermSplitted, true);
          }, 1500);
        } else if (advancedSearchKeyword.includes('"')) {
          const splitedWord = advancedSearchKeyword.match(/\w+|"[^"]+"/g);
          splitedWord.forEach(obj => {
            if (obj !== '') {
              this.documentSearchTermSplitted.push({ value: obj, checked: true });
            }
          });
          setTimeout(() => {
            this.searchWithin(advancedSearchKeyword, true);
          }, 1500);
        } else {
          this.advSearchTerms = advancedSearchKeyword;
          this.searchTermwithSpaces = (/\s/.test(this.advSearchTerms));
          if (this.searchTermwithSpaces) {
            let splitWords = [];
            let splitWord;
            splitWords = this.advSearchTerms.split(' ');
            if (stopWords.indexOf(this.advSearchTerms.toLowerCase())) {
              splitWords = splitWords.filter((el) => !stopWords.includes(el.toLowerCase()));
              splitWord = splitWords.toString();
              this.advSearchTerms = splitWord.split(',');
            }
            const documentSearchTermSplitted = [].concat.apply([], this.advSearchTerms.map((term, index) => {
              return index % 2 ? term : term.split(' ');
            })).filter(Boolean);
            documentSearchTermSplitted.forEach(obj => {
              this.documentSearchTermSplitted.push({ value: obj, checked: true });
            });
            this.documentSearchTermSplitted = _uniqBy(this.documentSearchTermSplitted, 'value');
            setTimeout(() => {
              this.searchWithin(this.advSearchTerms, true);
            }, 1500);
          }
        }
      }
      const advParam = JSON.parse(sessionStorage.getItem('explorerLimiter'));
      if (advParam) {
        this.copyAdvancedFilterParam = new AdvancedFilterParams(advParam);
      }

    });


    this.unSubFilmstripImage = this.sharedService.filmstripImage.subscribe((res) => {
      setTimeout(() => {
        this.pageId = res;
        if (res) {
          if ($('.slim-strip-container').hasClass('gridview-images')) {
            this.isGridViewEnable = true;
            $('.slim-strip-container').removeClass('gridview-images');
            $('.docviewer-imageframe').removeClass('gridview-images');
          }
          if (!res.hasOwnProperty('imageId')) {
            const documentImageIds = _map(this.documentImages, function (obj) { return obj.imageId; });
            this.documentCurrentIndex = documentImageIds.indexOf(res) + 1;
            if (this.documentCurrentIndex === 0 || !this.documentCurrentIndex) {
              this.documentCurrentIndex = 1;
            }
          } else {
            this.currentDocNumberFromThumbnail = $('.pagenum-input').val();
            this.gotoCurrentDocumentFulltext(this.documentCurrentIndex, true);
          }
        }
        const searchTerm = [];
        searchTerm.push(this.pageContentData.searchTerm);
        const data = {};
        data['searchTerm'] = searchTerm;
        this.searchService.buildFilterParams(this.pageContentData.advancedFilterParam, (param) => {
          this.searchService.buildURL(param, (urlData) => {
            data['filterParam'] = urlData;
            data['searchTerm'] = this.searchTermCopy ? this.searchTermCopy : '';
            data['start'] = this.startValueFromParams;
            data['spatialFilterParams'] = this.spatialFilterParams;
            data['isFromResultsPage'] = this.isFromResultPage;
            this.detailsRedirectParams = data;
          });
        });
        if (this.pageId) {
          if (isObject(this.pageId)) {
            this.detailsRedirectParams.pageId = this.pageId.imageId;
          } else {
            this.detailsRedirectParams.pageId = this.pageId;
          }
        } else {
          this.detailsRedirectParams.pageId = '';
        }
        const docId = this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams;
        this.detailsRedirectParams.docID = docId;
        if (sessionStorage.getItem('display')) {
          // this.detailsRedirectParams.display = sessionStorage.getItem('display');
        }
        let url = this.detailsRedirectParams;
        const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
        const currentLocation = localStorage.getItem('currentLocation') || environment.defaultLocation;
        switch (true) {
          case this.isFromResultPage:
            this.detailsRedirectParams['isFromResultsPage'] = this.isFromResultPage;
            url = this.router.createUrlTree(['/' + currentArchive + '/detail/' + docId, this.detailsRedirectParams]);
            this.location.replaceState(url);
            break;
          case this.isFromPhotoExplorerPage:
            this.detailsRedirectParams['isFromPhotoExplorer'] = this.isFromPhotoExplorerPage;
            url = this.router.createUrlTree(['/' + currentArchive + '/detail/' + docId, this.detailsRedirectParams]);
            this.location.replaceState(url);
            break;
          case this.isFromMapExplorerPage:
            this.detailsRedirectParams['isFromMapExplorer'] = this.isFromMapExplorerPage;
            url = this.router.createUrlTree(['/' + currentArchive + '/detail/' + docId, this.detailsRedirectParams]);
            this.location.replaceState(url);
            break;
          case this.isFromTermGroupsPage:
            this.detailsRedirectParams['isFromTermGroupsPage'] = this.isFromTermGroupsPage;
            url = this.router.createUrlTree(['/' + currentArchive + '/detail/' + docId, this.detailsRedirectParams]);
            this.location.replaceState(url);
            break;
          default:
            url = this.router.createUrlTree(['/' + currentArchive + '/detail/' + docId, this.detailsRedirectParams]);
            this.location.replaceState(url);
            break;
        }
      }, 1000);
    });
    this.toggledisclaimerShow = localStorage.getItem('disclaimerToggle') === 'true' ? true : false;

  }

  getLabelValues(metadata) {
    metadata = metadata instanceof Array ? metadata : [metadata];
    const val = [];
    metadata.forEach(res => {
      val.push(this.translatedValuesOfDetail[res]);
    });
    return val;
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (!this.isFromMapExplorerPage && !this.isFromPhotoExplorerPage) {
      this.backToSearchResultsPage(true);
    }
  }
  ngOnInit() {
    setTimeout(() => {
      this.isMainImageLoaded = true;
    }, 2000);
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKeyTitle = 'Shift + Alt';
      this.shiftContrlKey = 'Ctrl + Shift';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKeyTitle = 'Shift + Option';
      this.shiftContrlKey = 'Cmd + Shift';
    }
    this.accessToken = this.httpService.getCurrentAccessToken();
    this.unSubPageSettingsObservable = PageSettingsService.pageSettingsObservable.subscribe((data) => {
      this.pageContent = data;
    });
    this.unSubIsGuest = this.sharedService.isGuest.subscribe(res => {
      this.isGuestUser = res ? true : false;
    });
    this.unSubHeaderPopup = this.sharedService.headerPopup.subscribe((popupValue) => {
      if (popupValue === 'true') {
        this.docTab = true;
      } else {
        this.docTab = false;
      }
    });
    this.sharedService.toolsList.subscribe((changes) => {
      /*
      * require list from localstorage
      */
      setTimeout(() => {
        this.viewList = JSON.parse(localStorage.getItem('documentList'));
        const viewListArry = [];
        this.getJSONKeys(this.viewList).forEach((obj: any) => {
          this.getJSONKeys(this.viewList[obj]).forEach(obj1 => {
            viewListArry.push(obj1);
          });
        });
        this.viewListCount = viewListArry.length;
      }, 1000);
    });
    if (window.innerWidth <= 1024) {
      this.toggleMenu = true;
      this.isGridViewEnable = true;
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false
    });
    this.shareUrl = '';
    this.toggleTranslate = true;
    this.getRotationDirection();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 1024) {
      this.toggleMenu = true;
      this.isGridViewEnable = true;
    } else {
      this.toggleMenu = false;
    }
  }

  ngAfterContentInit() {
    if (this.urlUtilityService.getContentSubType()) {
      setTimeout(() => {
        this.activateLeftMenuTabset('itemsTab');
      }, 5000);
    }
    this.sharedService.dropdownToggle.subscribe(res => {
      this.dropdownValue = res;
      if (this.dropdownValue.help === true) {
        this.showHelp = true;
      } else {
        this.showHelp = false;
      }
      if (this.dropdownValue.shareDocument === true) {
        this.toggleviewlist = true;
      } else {
        this.toggleviewlist = false;
      }
      if (res.tools || res.otherArchives || res.archiveSelected || res.advancedSearch ||
        res.help || res.language || res.shareDocument || res.togglewileyarchivemenu) {
          this.hideCoverFlow = { show: false, random: Math.random() };
      }
    });
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });


    setTimeout(() => {
      $('.container-fluid').trigger('click');
    }, 2000);
    this.unSubPageId = this.sharedService.pageId.subscribe(res => {
      this.selectedDocPageId = res;
    });

    this.sharedService.pageId.subscribe(res => {
      this.selectedDocPageId = res;
    });
    this.isNeedToLogout = sessionStorage.getItem('isNeedToLogout');
    this.httpService.get(APIURLS.supportedLanguages, (response) => {
      let isSourceLanguageExist = false;
      this.translateLanguageValues = response.languages;
      this.translateLanguageValues.forEach((obj, index) => {
        setTimeout(() => {
          if (this.documentData) {
            if (this.documentData.languages.length === 1) {
              if (this.documentData.languages.indexOf(obj['key']) > -1) {
                this.translateLanguageValues.unshift(this.translateLanguageValues.splice(index, 1)[0]);
                isSourceLanguageExist = true;
              } else {
                this.currentLanguageForTranslate = this.documentData.languages[0];
              }
            } else {
              this.currentLanguageForTranslate = 'SELECT';
            }
          }
        }, 2500);
        this.supportedLanguagesList.push(obj['key']);
      });
      setTimeout(() => {
        if (!isSourceLanguageExist) {
          if (this.documentData) {
            this.documentData.languages.forEach(element => {
              if (this.supportedLanguagesList.indexOf(element) > -1) {
                // this.currentLanguageForTranslate = this.documentData.languages[0];
              } else {
                this.translateLanguageValues.unshift({ 'value': '', 'key': element });
              }
            });
            if (this.documentData.languages.length > 1) {
              this.currentLanguageForTranslate = 'SELECT';
            }
          }
        }
      }, 3000);
    }, err => { });
    this.translate.get('Common.Popups').subscribe((res: string) => {
      this.translatedValues = res;
      this.searchTermNotFound = res['searchTermNotFound'];
      this.pageLoadinFailed = res['pageLoadinFailed'];
      this.searchResults = res['searchResults'];
      this.mailSent = res['mailSent'];
      this.emailNotDelivered = res['emailNotDelivered'];
      this.useFollowingLinktoAccessContent = res['useFollowingLinktoAccessContent'];
      this.copytoClipboard = res['copytoClipboard'];
      this.citationCopiedtoClipboard = res['citationCopiedtoClipboard'];
      this.textCopiedtoClipboard = res['textCopiedtoClipboard'];
      this.translatedDocument = res['translatedDocument'];
      this.errorTranslatedDocument = res['errorTranslatedDocument'];
      this.limitReachExceded = res['limitReachExceded'];
      this.limitReachExcededPage = res['limitReachExcededPage'];
      this.invalidPagenumber = res['invalidPagenumber'];
      this.selectLanguageNotofication = res['selectLanguageNotofication'];
    });
    this.translate.get('SearchResult').subscribe((res: string) => {
      this.clipBoardValue = res;
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translatedValuesOfDetail = event.translations['Detail'];
      this.translatedValuesOfMetadata = event.translations['Detail']['matchingMetadata'];
      this.translatedValues = event.translations['Common']['Popups'];
      this.searchTermNotFound = this.translatedValues['searchTermNotFound'];
      this.pageLoadinFailed = this.translatedValues['pageLoadinFailed'];
      this.searchResults = this.translatedValues['searchResults'];
      this.mailSent = this.translatedValues['mailSent'];
      this.emailNotDelivered = this.translatedValues['emailNotDelivered'];
      this.useFollowingLinktoAccessContent = this.translatedValues['useFollowingLinktoAccessContent'];
      this.copytoClipboard = this.translatedValues['copytoClipboard'];
      this.citationCopiedtoClipboard = this.translatedValues['citationCopiedtoClipboard'];
      this.textCopiedtoClipboard = this.translatedValues['textCopiedtoClipboard'];
      this.translatedDocument = this.translatedValues['translatedDocument'];
      this.errorTranslatedDocument = this.translatedValues['errorTranslatedDocument'];
      this.limitReachExceded = this.translatedValues['limitReachExceded'];
      this.limitReachExcededPage = this.translatedValues['limitReachExcededPage'];
      this.invalidPagenumber = this.translatedValues['invalidPagenumber'];
      this.selectLanguageNotofication = this.translatedValues['selectLanguageNotofication'];
      this.clipBoardValue = event.translations['SearchResult'];
    });
    this.currentDocumentId = this.documentIDFromRouteParams;
    setTimeout(() => {
      this.homePageUrl = '/';
      this.homePageUrl += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
    });
    const keys = {};
    window.addEventListener('keydown',
      (e) => {
        keys[e.keyCode] = true;
        switch (e.keyCode) {
          case 33: case 34: // Arrow keys
            e.preventDefault(); break; // Space
          default: break; // do not block other keys
        }
      }, false);
    window.addEventListener('keyup',
      (e) => {
        keys[e.keyCode] = false;
      }, false);

    this.unSubIpadFullScreen = this.sharedService.ipadFullScreen.subscribe((changes) => {
      if (changes === 'true') {
        this.hideOnIpad = false;
      } else {
        this.hideOnIpad = true;
      }
    });
    if (window.innerWidth <= 768) {
      $('html, body').animate({ scrollTop: 195 }, 'fast');
    } else if (window.innerWidth <= 1024 && window.innerWidth >= 768) {
      $('html, body').animate({ scrollTop: 170 }, 'fast');
    }
    if (window.innerWidth <= 1024) {
      this.isInIpadMode = true;
    } else {
      this.isInIpadMode = false;
    }
    this.sessionDownloadDocuments = JSON.parse(sessionStorage.getItem('checkDownloadStatus'));

    const failedTranslations = [];
    if (this.sessionDownloadDocuments) {
      this.sessionDownloadDocuments.forEach(obj => {
        this.downloadDocumentId = obj.id;
        this.downloadDocumentlanguageCode = obj.key;
        if (this.downloadDocumentlanguageCode) {
          this.httpService.get(environment.APIUrl + APIURLS['getTranslatefulltextstatus'] + '/' +
            this.currentDocumentId + '/' + obj.key, res => {
              if (res.status.toUpperCase() === 'SUCCESS') {
                const downloadableLang =
                  sessionStorage.getItem('checkDownloadStatus') ? JSON.parse(sessionStorage.getItem('checkDownloadStatus')) : [];
                for (let index = 0; index < downloadableLang.length; index++) {
                  if (downloadableLang[index]['key'] === obj['key']) {
                    downloadableLang.splice(index, 1);
                  }
                }
                sessionStorage.setItem('checkDownloadStatus', JSON.stringify(downloadableLang));
                setTimeout(() => {
                  this.modalService.showNotification('SUCCESS',
                  this.translatedDocument, this.translatedValues.belowLanguage || 'Below Language - ' + obj.language);
                }, 500);
              }
            }, error => { });
        }
      });
    }

    this.activatedRoute.params.subscribe(param => {
      if (param.searchTerm) {
        if (param.searchTerm.includes('"')) {
          this.isSearchTermIncludesPhrase = true;
          this.initialSearchTerm = param['searchTerm'];
        } else {
          this.searchTermwithSpaces = (/\s/.test(param.searchTerm));
          this.searchtermWithSpaceTerms = param.searchTerm;
          if (this.searchTermwithSpaces) {
            this.isSearchTermIncludesPhrase = false;
            let splitWords = [];
            let splitWord;
            splitWords = this.searchtermWithSpaceTerms.split(' ');

            if (stopWords.indexOf(this.searchtermWithSpaceTerms.toLowerCase())) {
              splitWords = splitWords.filter((el) => !stopWords.includes(el.toLowerCase()));
              splitWord = splitWords.toString();
              this.searchtermWithSpaceTerms = splitWord.split(',');
            }
            const documentSearchTermSplitted = [].concat.apply([], this.searchtermWithSpaceTerms.map((term, index) => {
              return index % 2 ? term : term.split(' ');
            })).filter(Boolean);
            this.documentSearchTermSplitted = [];
            documentSearchTermSplitted.forEach(obj => {
              this.documentSearchTermSplitted.push({ value: obj, checked: true });
            });
            this.documentSearchTermSplitted = _uniqBy(this.documentSearchTermSplitted, 'value');
            setTimeout(() => {
              if (this.documentSearchTermSplitted.length === 1 || this.documentSearchTermSplitted.length === 2) {
                this.scrollFix = false;
                this.scrollSix = false;
                this.scrollFour = false;
                this.scrollTwo = true;
              } else if (this.documentSearchTermSplitted.length === 3 || this.documentSearchTermSplitted.length === 4) {
                this.scrollFix = false;
                this.scrollSix = false;
                this.scrollFour = true;
                this.scrollTwo = false;
              } else if (this.documentSearchTermSplitted.length === 5 || this.documentSearchTermSplitted.length === 6) {
                this.scrollFix = false;
                this.scrollSix = true;
                this.scrollFour = false;
                this.scrollTwo = false;
              } else if (this.documentSearchTermSplitted.length > 6) {
                this.scrollFix = true;
                this.scrollSix = false;
                this.scrollFour = false;
                this.scrollTwo = false;
              }
            }, 1500);
          } else {
            this.isSearchTermIncludesPhrase = true;
            this.initialSearchTerm = param['searchTerm'];
            if (stopWords.indexOf(this.searchtermWithSpaceTerms.toLowerCase()) > -1) {
              this.initialSearchTerm = '';
            }
          }
        }
      }
      let searchTerm: any;
      if (param['searchTerm']) {
        if (stopWords.indexOf(param['searchTerm'].toLowerCase()) > -1) {
          searchTerm = '';
        } else {
          searchTerm = param['searchTerm'];
        }
      }
      if (searchTerm !== '') {
        this.callgetMetaDatasFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, searchTerm);
        this.callgetPageFulltextFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, searchTerm, true);
        if (this.documentSearchTermSplitted) {
          this.splitedSearchTerms =
            _map(this.documentSearchTermSplitted.filter(res => (res.checked === true)), 'value');
            this.splitedSearchTerms = this.splitedSearchTerms.toString().replace(/,/g, ' ');
        }
        this.overlaysForTermSearch = {
          initialSearchTerm: param['searchTerm'],
          splitedSearchTerms: this.splitedSearchTerms ? this.splitedSearchTerms : null,
          keyword: null,
          pageNo: null
        };
      }

    });
    /*
    * @DESC: check if the current document id not exist than use 'defaultImageToBeLoaded['docId']'
    */
    let _documentId: any;
    this.currentDocumentId ? _documentId = this.currentDocumentId : _documentId = this.defaultImageToBeLoaded['docId'];
    this.callGetDocumentImages(_documentId);


    /*
    * @DESC: get document notes from 'getDocumentNotes' url to display,
    * receive notes object and convert into (key, value) pair array
    * @Reutrn: response as 'notes' object
    */
    this.httpService.get(environment.APIUrl + APIURLS['getDocumentNotes'] + '/' + _documentId, res => {
      if (res) {
        this.showItemNotes = true;
        this.docutypeDetails = res['notes'];
        const documentttype = Object.keys(this.docutypeDetails).map(key => ({ type: key, value: this.docutypeDetails[key] }));
        this.documentNotesFromNotes = documentttype;
      } else {
        this.showItemNotes = false;
        /*
        * @DESC: remove notes tab if there is no content to display
        */
        for (let index = 0; index < this.tabs.length; index++) {
          if (this.tabs[index].id === 'notesTab') {
            this.tabs.splice(index, 1);
          }
        }
      }
    }, error => { });
    // this.getRelatedSubjects();
  }
  showWarning(archive) {
    if (window.location.pathname.includes('guestAuthToken=')) {
      const sessionWarning = sessionStorage.getItem('warning-shared');
      let archives;
      if (sessionWarning) {
        archives = sessionWarning.split(',');
      } else { archives = []; }

      this.warningObj = [];
      this.commonService.getLocationConfig(sessionStorage.getItem('lang')).subscribe(res => {
        /*
        * 1.get data from location config json, if the flag show is true then show the dialog
        * 2.display dialog only once per session
        * 3.if mutiple arhives has show flag as true then display all messages in same dialog
        * user gets the dialog archive 1 for first time then the settings were changed next time
        * it must show the last update archive warning with step1,2 and 3
        */
        if (res.warnings.archives[archive]) {
          if (res.warnings.archives[archive].showShared) {
            if (sessionWarning) {
              if (sessionWarning.indexOf(archive) > -1) {
                if (!(archives.indexOf(archive) > -1)) {
                  archives.push(archive);
                }
              } else {
                /*
                * If the session warning object available and not exist in session warning array,
                * push data in warningObj and keep copy of the object in archives array
                */
                this.warningObj.push({
                  title: res.warnings.archives[archive].titleName,
                  message: res.warnings.archives[archive].message
                });
                archives.push(archive);
              }
            } else {
              /*
              * If warning not available in session, push data in warningObj and keep copy of the object in archives array
              */
              this.warningObj.push({
                title: res.warnings.archives[archive].titleName,
                message: res.warnings.archives[archive].message
              });
              archives.push(archive);
            }
          }
        }
        /*
        * Finalize the display data and the warning object has more then one object then show the Dialog
        * And set those archives in session as well as.
        */
        if (this.warningObj.length > 0) {
          setTimeout(() => {
            this.warningssmodalRef = this.bsModalService.show(
              this.warningsTemplate, Object.assign({}, { backdrop: true, ignoreBackdropClick: true }, { class: 'gray modal-lg' }));
          }, 3000);
        }
        sessionStorage.setItem('warning-shared', archives.toString());
      }, err => { });
    }
  }
  closeWarningPopup() {
    if (this.warningssmodalRef) {
      this.warningssmodalRef.hide();
    }
  }

  loadTableOverlayFromNode(nodeData, pageList, callBack) {
    const pageIDs = [];
    const pageObjects = [];
    pageList.forEach(page => {
      pageIDs.push(page.imageId);
      pageObjects.push(page);
    });
    nodeData.forEach((element) => {
      if (this.documentData) {
        if (this.documentData.docType) {
          setTimeout(() => {
            if (this.documentData.docType === 'Photographs' || this.documentData.docType === 'Maps') {
              if (element.name === 'Illustrations') {
                this.getphotosIllustration(element, pageIDs, pageObjects, 'photo');
              }
            } else {
              if (element.name === 'Tables') {
                this.getphotosIllustration(element, pageIDs, pageObjects, 'table');
              } else if (element.name === 'keyword') {
                this.getphotosIllustration(element, pageIDs, pageObjects, 'photo');
              } else {
                this.getphotosIllustration(element, pageIDs, pageObjects, 'photo');
              }
            }
          }, 1500);
        }
      }

    });
    callBack(pageObjects);
    if (this.documentData) {
      if (this.documentData.citations) {
        if (Object.keys(this.documentData.citations).length === 0) {
          this.emptyCitation = true;
        }
      }
    }
  }

  getphotosIllustration(element, pageIDs, pageObjects, type) {
    element.children.forEach(node => {
      const indexOfNodeInPageList = pageIDs.indexOf(node.pageId);
      if (indexOfNodeInPageList > -1) {
        let overlays = pageObjects[indexOfNodeInPageList].overlays;
        if (Array.isArray(overlays)) {
          overlays.push(new Overlay(node.width, node.height, node.hpos, node.vpos, type, node.tableId));
        } else {
          overlays = [];
          overlays.push(new Overlay(node.width, node.height, node.hpos, node.vpos, type, node.tableId));
        }
      }
    });
  }
  getPageFullText(pageId) { }

  listClick(document) {
    this.pageSettingService.addToViewList({
      id: document.wileyId,
      title: document.title,
      citation: document.description,
      imageThumbnails: this.documentImagesWithTitles.map((thumb) => {
        return environment.APIUrl + 'file/thumb/' + document.wileyId + '/' + thumb.id + '?type=small&access_token=' + this.accessToken
          + '&WDALoc=' + localStorage.getItem('currentLocation') || environment.defaultLocation;
      })
    }, document.docType);
  }

  getJSONKeys(obj) {
    if (typeof obj === 'object') {
      return Object.keys(obj);
    } else {
      return [];
    }
  }

  onRelevantStatusChange(status) { }

  bookmarkPageClick() {
    if (!this.isBookmarkPopupOpen) {
      this.docTab = true;
      this.showClipBoardSuccessAlert = false;
      const currentUrl = window.location.href;
      const requestParams = { 'url': currentUrl + ';fromBookmark=true' };
      // this.modalService.showLoader();
      this.isBookmarkPopupOpen = true;
      this.httpService.post(environment.APIUrl + APIURLS.bookmarkShortUrl, requestParams, (response) => {
        this.bookmarkPageURL = response.url;
        // this.modalService.hideLoader();
        // if (!$('.modal').hasClass('in')) {
        this.bookDetailsMarkmodalRef = this.bsModalService.show(this.bookmarkHtmlPageTemplate,
          { backdrop: true, ignoreBackdropClick: true });
        // }
      }, (error) => {
        this.isBookmarkPopupOpen = false;
        // this.modalService.hideLoader();
      });
    }
  }
  citationClick() {
    this.docTab = true;
    const requestParams = {
      'wileyId': this.documentData['wileyId'],
      'citationTypes': ['modern-language-association', 'chicago-author-date', 'apa']
    };
    // this.modalService.showLoader();
    this.httpService.post(environment.APIUrl + 'citation/', requestParams, (response) => {
      this.citationRes = response;
      this.citationmodalRef = this.bsModalService.show(this.citationPageTemplate, {
        class: 'large',
        backdrop: true, ignoreBackdropClick: true
      });
      // this.modalService.hideLoader();
    }, (error) => {
      // this.modalService.hideLoader();
    });
  }
  closecitationPageTemplate() {
    this.docTab = false;
    this.citationmodalRef.hide();
  }

  exportRIS(type) {
    const typeName = $(type).attr('id');
    const requestParams = this.documentData['wileyId'];
    this.httpService.downloadFile(environment.APIUrl + APIURLS['getCitationRISFile'] + requestParams).subscribe((resp) => {
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        window.open('data:application/json;charset=utf-8,' + encodeURIComponent(resp), '_blank');
      }
      const res = new Blob([resp], { type: 'application/pdf' });
      const fileName = requestParams + '-' + typeName + '.ris';
      if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(res); // xhr.response is a blob
        a.download = fileName; // Set the file name.
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
    }, (err) => { });
  }
  closeBookMarkModal() {
    this.docTab = false;
    this.bookDetailsMarkmodalRef.hide();
    this.isBookmarkPopupOpen = false;
  }
  copyShareUrl(sheredUrl) {
    // const textToBeCopied = (sheredUrl).replace(/<(?:.|\n)*?>/gm, '');
    const textarea = this.dom.createElement('textarea');
    textarea.style.height = '0px';
    textarea.style.left = '-100px';
    textarea.style.opacity = '0';
    textarea.style.position = 'fixed';
    textarea.style.top = '-100px';
    textarea.style.width = '0px';
    this.dom.body.appendChild(textarea);
    textarea.value = sheredUrl;
    textarea.select();
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', this.clipBoardValue.URLCopiedToClipboard, '');
    }
  }
  copytoClipBoard(bookmarkPageURL) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      if (bookmarkPageURL) {
        $(bookmarkPageURL).addClass('welcome');
        const citationEle = bookmarkPageURL;
        const textToBeCopied = (citationEle).replace(/<(?:.|\n)*?>/gm, '');
        const $input = $('#bookmarkURLonTemplate');
        $input.val();
        const el = $input.get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;

        const successful = document.execCommand('copy');
        $input.blur();
        const msg = successful ? 'successful ' : 'un-successful ';
      }
    } else {
      const textToBeCopied = (bookmarkPageURL).replace(/<(?:.|\n)*?>/gm, '');
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      this.showClipBoardSuccessAlert = true;
    }
  }
  backToSearchResults() {
    if (this.isNeedToLogout) {
      sessionStorage.clear();
      localStorage.clear();
    }
    this.sharedService.sharebacktoDetailsPageFlag(false);
    // this.searchService.backToSearchResult(this.searchTermCopy, this.pageContentData.advancedFilterParam);
    this.searchService.backToSearchResult(this.searchTermCopy, this.advancedFilterParamForCoverFlow);
  }

  fulltextCopy(fullText) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const $input = $('#disclaimerContents').text();
      $input.val();
      const el = $input.get(0);
      const editable = el.contentEditable;
      const readOnly = el.readOnly;
      el.contentEditable = true;
      el.readOnly = false;
      const range = document.createRange();
      range.selectNodeContents(el);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;

      const successful = document.execCommand('copy');
      $input.blur();
      const msg = successful ? 'successful ' : 'un-successful ';
    } else {
      const textToBeCopied = $('#disclaimerContents').text();
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', this.copytoClipboard, this.textCopiedtoClipboard);
    }
  }

  saveTextAsFile() {
    // this.popupService.showLoader();
    this.searchResultDetailService.getTextUrl(this.documentData.wileyId, this.selectedPageId,
      ((this.currentLanguageForTranslate.toUpperCase() === 'SELECT') ? '' : this.currentLanguageCode), res => {
        this.downloadableUrl = res;
        this.downloadUrlFor = 'pdf';
        this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
        // this.popupService.hideLoader();
      }, err => {
        // this.popupService.hideLoader();
      });
  }
  translateTrigger() {
    if (this.toggleArrow) {
      this.toggleArrow = false;
    } else if (!this.toggleArrow) {
      this.toggleArrow = true;
    }
  }

  translateDoc(destinationLanguage, langKey) {
    this.toggleTranslate = true;
    this.toggleArrow = true;
    this.currentLanguage = langKey;
    this.currentLanguageCode = destinationLanguage;
    if (this.documentData.languages.indexOf(langKey) > -1) {
      // this.popupService.showLoader();
      this.callGetPageLevelFullText(this.selectedPageId);
      if (this.translate_pop) {
        this.translate_pop.hide();
      }
      setTimeout(() => {
        // this.popupService.hideLoader();
        if (rtlLanguages.indexOf(langKey.toUpperCase()) > -1) {
          $('#rtl-language').addClass('rtl-languages');
        } else {
          $('#rtl-language').removeClass('rtl-languages');
        }
      }, 500);
      this.currentLanguageForTranslate = langKey;
      this.currentSelectedLanguageValue = destinationLanguage;
    } else {
      this.searchService.translateDoc(this.documentData.wileyId, this.selectedPageId, destinationLanguage, (response) => {
        if (response.status === 'FAILURE') {
          this.modalService.showNotification('ERROR', this.errorTranslatedDocument, '');
        } else {
          this.documentFulltext = response.data;
          // this.popupService.hideLoader();
          if (rtlLanguages.indexOf(langKey.toUpperCase()) > -1) {
            $('#rtl-language').addClass('rtl-languages');
          } else {
            $('#rtl-language').removeClass('rtl-languages');
          }
          this.currentLanguageForTranslate = langKey;
        }
        if (this.translate_pop) {
          this.translate_pop.hide();
        }
        setTimeout(() => {
          $('.disclaimer-div').trigger('click');
        }, 0);
      }, err => { });
    }
  }
  translateEntireDoc(destinationLanguage) {
    this.searchService.translateEntireDoc(this.documentData.wileyId, this.selectedPageId, destinationLanguage, (response) => {
      const textToSave = response._body;
      const textToSaveAsBlob = new Blob([textToSave], { type: 'text/plain' });
      const fileNameToSaveAs = this.documentData['wileyId'] + '_full_text';
      if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
        window.navigator.msSaveBlob(textToSaveAsBlob, fileNameToSaveAs);
      } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        window.open('data:application/json;charset=utf-8,' + encodeURIComponent(textToSave), '_blank');
      } else {
        const textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
        const downloadLink = document.createElement('a');
        downloadLink.download = fileNameToSaveAs;
        downloadLink.innerHTML = 'Download File';
        downloadLink.href = textToSaveAsURL;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    }, err => {
    });
  }

  copyCitation(element, citation?) {
    if (citation === 'MLA') {
      this.citaionMLA = true;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'CHICAGO') {
      this.citaionMLA = false;
      this.citaionCHICAGO = true;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'APA') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = true;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'ModelMLA') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = true;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'ModelAPA') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = true;
      this.modelcitaionCHICAGO = false;
    } else if (citation === 'ModelCHICAGO') {
      this.citaionMLA = false;
      this.citaionCHICAGO = false;
      this.citaionAPA = false;
      this.modelcitaionMLA = false;
      this.modelcitaionAPA = false;
      this.modelcitaionCHICAGO = true;
    }
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      if (citation) {
        $(element).addClass('welcome');
        const citationEle = element;
        const textToBeCopied = (citationEle).replace(/<(?:.|\n)*?>/gm, '');
        const $input = $('.mycopyCitation');
        $input.val();
        const el = $input.get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;

        const successful = document.execCommand('copy');
        $input.blur();
        const msg = successful ? 'successful ' : 'un-successful ';
      }
    } else {
      const textToBeCopied = (element).replace(/<(?:.|\n)*?>/gm, '');
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', this.copytoClipboard, this.citationCopiedtoClipboard);
    }
  }

  downloadCitation(citation) {
    const textToBeCopied = citation;
    const htmlCode = `<html><head><title>Citation</title><style> body{    font-family: 'Open Sans', sans-serif !important;}
     .csl-entry { color: #414246;font-size: 16px; font-weight: 300; line-height: 20px;  } i{font-weight : bold;}
         </style>
      </head><body ><div>`;
    const htmlContent = [htmlCode + textToBeCopied + '</div></body></html>'];
    const htmlContentipad = $(textToBeCopied).text();

    const blob = new Blob(htmlContent, { type: 'text/html' });
    const doc = document.createElement('a');
    const fileNameToSaveAs = this.documentData['wileyId'];
    if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
      window.navigator.msSaveBlob(blob, fileNameToSaveAs);
    } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      window.open('data:application/json;charset=utf-8,' + encodeURIComponent(htmlContentipad), '_blank');
    } else {
      doc.href = URL.createObjectURL(blob);
      doc.download = fileNameToSaveAs + '.html';
      doc.hidden = true;
      document.body.appendChild(doc);
      doc.click();
    }
  }

  destroyClickedElement(event) {
    document.body.removeChild(event.target);
  }
  onOverlayClick(tableId) {
    if (isObject(tableId)) {
      tableId = tableId['tabId'];
    }
    this.accessToken = this.httpService.getCurrentAccessToken();
    if (tableId && (this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams)) {
      // this.popupService.showLoader();
      this.searchResultDetailService.getDownloadTableUrl(this.defaultImageToBeLoaded.docId
        || this.documentIDFromRouteParams, tableId, res => {
          this.downloadableUrl = res;
          this.downloadUrlFor = 'table';
          // this.popupService.hideLoader();
          if (!OpenSeadragon.isFullScreen()) {
            this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate,
              { backdrop: true, ignoreBackdropClick: true });
          } else {
            $('#fullScreenDownloadPopup').modal('show');
            $('.modal-backdrop.fade.in').addClass('removeBackdrop');
            $('.backdrop-modal').addClass('activeModal');
          }
        }, err => {
          // this.popupService.hideLoader();
          let tableDownloadErrorToast;
          this.translate.get('Detail.illustrationTableDownloadError').subscribe((res: string) => {
            tableDownloadErrorToast = res;
          });
          this.modalService.showNotification('ERROR', this.translatedValues.noFileFound || 'No file found', tableDownloadErrorToast);
        });
    }
  }

  updatePageFullText(event) {
    this.currentSelectedPagId = event.pageId;
    this.callGetPageLevelFullText(event.pageId);
    this.selectedPageId = event.pageId;
  }


  gotoCoverflowImagData(pageID) {
    if (this.coveflowSlidermodalRef) {
      this.coveflowSlidermodalRef.hide();
    }
    const currentUrl = sessionStorage.getItem('currentUrl');
    const data = {};
    this.searchService.buildFilterParams(this.advancedFilterParamForCoverFlow, (param) => {
      this.searchService.buildURL(param, (url) => {
        data['filterParam'] = url;
        if (pageID) {
          data['viewPage'] = pageID;
        }
      });
    });
    this.router.navigate([this.homePageUrl + '/detail/' + pageID + ';searchTerm=' + ';filterParam' + data['filterParam']]);
  }


  shareMailClick() {
    if (!$('.modal').hasClass('in') && !this.shareMailLoader) {
      this.shareMailLoader = true;
      // this.modalService.showLoader();
      this.docTab = true;
      this.shareMailData = {
        'topic': this.documentData.title,
        'message': '',
        'to': ''
      };
      if (this.shareUrl) {
        this.shareMailData.message = this.useFollowingLinktoAccessContent + ' \n' + this.shareUrl;
        if (!$('.modal').hasClass('in')) {
          this.sharemodalRef = this.bsModalService.show(this.shareHtmlPageTemplate, { backdrop: true, ignoreBackdropClick: true });
          this.shareMailLoader = false;
        }
      }
      this.shareMailLoader = false;
      // this.modalService.hideLoader();
    }
  }

  closeShareModal() {
    this.docTab = false;
    this.sharemodalRef.hide();
    this.shareMailLoader = false;
  }

  shareEmail() {
    this.utilityService.sendToMailClient(this.shareMailData.topic,
      this.shareMailData.message, this.shareMailData.to, this.shareMailData.from).then(res => {
        this.modalService.showNotification('SUCCESS', this.mailSent || 'Mail Sent', '');
        this.closeShareModal();
      }, err => {
        this.modalService.showNotification('WARNING', this.emailNotDelivered || 'Email has not been Delivered', '');
        this.closeShareModal();
      });
  }

  gotoCollectionDetails(collectionId) {
    this.router.navigate([this.homePageUrl + '/collection-details'],
      { queryParams: { archive: this.documentData.shortName, id: collectionId, scrollTo: this.documentData.wileyId } });
  }
  socialShareToggleList(event) {
    // addthis.layers.refresh();
    this.hideCoverFlow = { show: false, random: Math.random() };
    // this.modalService.showLoader();
    // this.showHelp = false;
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false
    });
    if (this.toggleviewlist) {
      // this.toggleviewlist = false;
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false
      });
      this.shareUrl = '';
      // addthis.layers.refresh();
      // addthis.update('share', 'url', this.shareUrl);
      // addthis.update('share', 'title', this.documentData.title + this.translatedValues.useFollowingLinktoAccessContent ||
      //   '. Please use the following link to access content from the Wiley Digital Archives:');
      // addthis.update('share', 'description', this.translatedValues.useFollowingLinktoAccessContent ||
      // 'Please use the following link to access content from the Wiley Digital Archives:');
      // this.modalService.hideLoader();
    } else {
      //  this.toggleviewlist = true;
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: true
      });
      const currentUrl = window.location.href;
      const requestParams = { 'url': currentUrl };
      const currentHost = this.urlUtilityService.getCurrentUrl();

      if (this.documentData) {
        if (this.documentData['wileyId']) {
          this.httpService.get(environment.APIUrl + APIURLS.getGuestToken + this.documentData['wileyId'], (response) => {
          if (response) {
            this.activatedRoute.params.subscribe((params) => {
              const currentParams = JSON.parse(JSON.stringify(params));
              currentParams['guestAuthToken'] = response.authtoken.access_token;
              currentParams['searchTerm'] = '';
              delete currentParams['isFromResultsPage'];
              currentParams['location_id'] = localStorage.getItem('currentLocation') || environment.defaultLocation;
              const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
              const urlToShort = currentHost + this.router.createUrlTree([`/${currentArchive}/detail/${this.documentData.wileyId}`,
                currentParams]).toString();
              if (urlToShort) {
                localStorage.setItem('guestTokenLog', urlToShort);
                this.urlUtilityService.getShortUrl(urlToShort, (shortUrlResponse) => {
                  this.shareUrl = shortUrlResponse.url;
                  // addthis.layers.refresh();
                  // addthis.update(this.translatedValues.share || 'share', this.translatedValues.url || 'url', shortUrlResponse.url);
                  // addthis.update(this.translatedValues.share || 'share', this.translatedValues.title || 'title',
                  // this.documentData.title + this.translatedValues.useFollowingLinktoAccessContent ||
                  //   '. Please use the following link to access content from the Wiley Digital Archives:');
                  // // tslint:disable-next-line:max-line-length
                  // addthis.update('share', 'description', this.translatedValues.useFollowingLinktoAccessContent || 'Please use the following link to access content from the Wiley Digital Archives:');
                  // this.modalService.hideLoader();
                }, (error) => {
                  // this.modalService.hideLoader();
                });
              } else {
                this.modalService.showNotification('WARNING', '', this.translatedValues.unableToGenerateUrl ||
                'Unable to generate share url, Please try again later..');
              }
            });
          } else {
            // this.modalService.hideLoader();
            this.modalService.showNotification('WARNING', '', this.translatedValues.unableToGenerateUrl ||
            'Unable to generate share url, Please try again later..');
          }
          }, (error) => {
            // this.modalService.hideLoader();
              this.modalService.showNotification('WARNING', '', this.translatedValues.unableToGenerateUrl ||
              'Unable to generate share url, Please try again later..');
          }, 'json', true);
        }
        // this.modalService.hideLoader();
      }
    }
    event.stopPropagation();
    this.hideCoverFlow = { show: false, random: Math.random() };
  }

  downloadFullText(docID) {
    if (this.documentData.languages.indexOf(this.currentLanguageForTranslate) > -1) {
      // this.popupService.showLoader();
      const destLanguage = '';
      this.searchResultDetailService.getDownloadfulltextUrl(docID, destLanguage, res => {
        this.downloadableUrl = res;
        this.downloadUrlFor = 'fulltext';
        // this.popupService.hideLoader();
        this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
      }, err => {
        // this.popupService.hideLoader();
      });
    } else {

      if (this.currentLanguageForTranslate.toUpperCase() === 'SELECT') {
        this.modalService.showNotification('WARNING', this.selectLanguageNotofication, '');
      } else {
        this.httpService.get(environment.APIUrl + APIURLS['getTranslatefulltextstatus'] + '/' +
          this.documentData['wileyId'] + '/' + this.currentLanguageCode, res => {
            if (res.status.toUpperCase() === 'PENDING') {
              this.entireDocumentData.push({
                'id': this.documentData['wileyId'], 'language': this.currentLanguageForTranslate,
                'key': this.currentLanguageCode
              });
              this.languagemodalRef = this.bsModalService.show(this.languagePageTemplate, {
                class: 'download-document',
                backdrop: true, ignoreBackdropClick: true
              });
              sessionStorage.setItem('checkDownloadStatus', JSON.stringify(this.entireDocumentData));
            }
            if (res.status.toUpperCase() === 'SUCCESS') {
              this.downloadEntireDocumentFromService();
            }
            if (res.status.toUpperCase() === 'FAILURE') {
              this.modalService.showNotification('ERROR', this.errorTranslatedDocument, '');
            }
          }, error => { });
      }
    }
  }

  downloadEntireDocumentFromService() {
    // this.popupService.showLoader();
    this.searchResultDetailService.getDownloadfulltextUrl(this.documentData['wileyId'], this.currentLanguageCode, res => {
      this.downloadableUrl = res;
      this.downloadUrlFor = 'fulltext';
      // this.popupService.hideLoader();
      this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
    }, err => {
      // this.popupService.hideLoader();
    });

    // this.httpService.get(environment.APIUrl + APIURLS['downloadFullText'] + '/' +
    //   this.documentData['wileyId'] + '?destLanguage=' + this.currentLanguageCode, resp => {
    //     const textToSave = resp._body;
    //     const textToSaveAsBlob = new Blob([textToSave], { type: 'text/plain' });
    //     const fileNameToSaveAs = this.documentData['wileyId'] + this.currentLanguageCode + '_full_text';
    //     if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
    //       window.navigator.msSaveBlob(textToSaveAsBlob, fileNameToSaveAs);
    //     } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    //       window.open('data:application/json;charset=utf-8,' + encodeURIComponent(textToSave), '_blank');
    //     } else {
    //       const textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
    //       const downloadLink = document.createElement('a');
    //       downloadLink.download = fileNameToSaveAs;
    //       downloadLink.innerHTML = 'Download File';
    //       downloadLink.href = textToSaveAsURL;
    //       downloadLink.style.display = 'none';
    //       document.body.appendChild(downloadLink);
    //       downloadLink.click();
    //       document.body.removeChild(downloadLink);
    //     }
    //     this.sessionDownloadDocuments.forEach(obj => {
    //       delete this.sessionDownloadDocuments[obj.key];
    //     });
    //     this.popupService.hideLoader();
    //   }, error => {
    //     this.popupService.hideLoader();
    //   }, 'text');
  }

/**
 * @description :Register in document history
 * @param docId : Document id
 * @param title : Document Title
 */
  setDetailsHistory(docId, title) {
    const detailsHistory: any = localStorage.getItem('detailsHistory');
    let detailsHistoryArray = [];
    const dateViewed = Date.now();
    if (detailsHistory) {
      if (detailsHistory.indexOf(docId) <= -1) {
        detailsHistoryArray = JSON.parse(detailsHistory);
        detailsHistoryArray.push({ 'id': docId, 'date': dateViewed, 'title': title, 'path': window.location.pathname });
        localStorage.setItem('detailsHistory', JSON.stringify(detailsHistoryArray));
      }
    } else {
      detailsHistoryArray.push({ 'id': docId, 'date': dateViewed, 'title': title, 'path': window.location.pathname });
      localStorage.setItem('detailsHistory', JSON.stringify(detailsHistoryArray));
    }
  }

  gotoAuthorDetail(author) {
    if (this.pageContentData.advancedFilterParam.searchKeywords) {
      // this.pageContentData.advancedFilterParam.searchKeywords.push({ fieldName: 'authors', fieldValue: author, operator: 'AND' });
      this.pageContentData.advancedFilterParam.searchKeywords = [{ fieldName: 'authors', fieldValue: author, operator: 'AND' }];
    } else {
      this.pageContentData.advancedFilterParam.searchKeywords = [{ fieldName: 'authors', fieldValue: author, operator: 'AND' }];
    }
    this._searchTerm = '';
    this.searchService.backToSearchResult(this._searchTerm, this.pageContentData.advancedFilterParam);
  }
  selectCurrentThumbnail() {
    this.sharedService.thumbnailDataReceived(this.selectedPageId);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event) {
    setTimeout(() => {
      this.viewList = JSON.parse(localStorage.getItem('documentList'));
      const viewListArry = [];
      this.getJSONKeys(this.viewList).forEach((obj: any) => {
        this.getJSONKeys(this.viewList[obj]).forEach(obj1 => {
          viewListArry.push(obj1);
        });
      });
      this.viewListCount = viewListArry.length;
    }, 0);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.keyCode === 34) {
      if (this.documentImages.length !== this.documentCurrentIndex) {
        this.gotoNextDocumentFullText();
        e.preventDefault();
      }
    } else if (e.keyCode === 33) {
      if (this.documentCurrentIndex > 1 || !this.documentCurrentIndex) {
        this.gotoPreviouesDocumentFullText();
        e.preventDefault();
      }
    }

    if (e.shiftKey && e.altKey) {
      switch (e.keyCode) {
        case 89:
          this.activateLeftMenuTabset('itemsTab');
          break;
        case 75:
          if (!this.isBookmarkPopupOpen) {
            this.bookmarkPageClick();
          } else {
          }
          break;
        case 87:
          this.leftMenuTabset.tabs[2].active = true;
          this.documentSearchField.nativeElement.focus();
          break;
        case 90:
          this.toggleMenu = false;
          break;
        case 40:
          this.getSearchResultsBasedonParams(this.documentData.docType);
          break;

        case 191:
          this.activateLeftMenuTabset('resultViewTab');
          break;
        case 74:
          this.activateLeftMenuTabset('citationTab');
          break;
        case 83:
          this.activateLeftMenuTabset('SearchWithinDocTab');
          break;

        case 66:
          this.activateLeftMenuTabset('illustrationTab');
          break;
        case 77:
          this.activateLeftMenuTabset('notesTab');
          break;
        case 81:
          this.itemsTabset.tabs[1].active = true;
          break;
        case 80:
          this.activateLeftMenuTabset('relatedSubjectsTab');
          break;
        case 85:
          this.activateLeftMenuTabset('volumesTab');
          break;
        case 71:
          if (!this.toggleMenu) {
            if (this.isGridViewEnable) {
              this.girdViewThumBImages();
            } else if (!this.isGridViewEnable) {
              this.listViewThumBImages();
            }
          } else if (this.toggleMenu) {
            this.toggleMenu = false;
            if (this.isGridViewEnable) {
              this.girdViewThumBImages();
            } else if (!this.isGridViewEnable) {
              this.listViewThumBImages();
            }
          }
          break;
      }
    } else if (e.ctrlKey && e.altKey) {
      switch (e.keyCode) {
        case 76:
          this.listClick(this.documentData);
          break;
        case 79:
          if (!$('.modal').hasClass('in')) {
            this.citationClick();
          }
          break;
        case 90:
          if (this.defaultImageToBeLoaded) {
            if (this.defaultImageToBeLoaded.isDocViewerShow) {
              this.backToSearchResultsPage();
            } else {
              this.backToSearchResults();
            }
          }
          break;
        case 75:
          this.changetoFullText(this.checkModel);
          break;
        case 80:
          if (!this.fulltextDisable) {
            this.doThisOnSelect(this.checkModel);
          }
          break;
        case 78:
          this.leftMenuTabset.tabs[1].active = true;
          break;
        case 81:
          this.itemsTabset.tabs[0].active = true;
          break;
        case 69:
          this.toggleMenu = true;
          this.isGridViewEnable = true;
          break;
      }
    } else if (e.ctrlKey && e.shiftKey) {
      switch (e.keyCode) {
        case 89:
          this.socialShareToggleList(e);
          break;
        case 220:
          for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
            this.currentTabId = this.leftMenuTabset.tabs[i].id;
            if (this.currentTabId === 'tocTabs') {
              this.leftMenuTabset.tabs[i].active = true;
            }
          }
          break;
      }
    }
  }

  ngOnDestroy() {
    this.isRefershPage = true;
    if (this.unSubIsGuest) {
      this.unSubIsGuest.unsubscribe();
    }
    if (this.paramObservable) {
      this.paramObservable.unsubscribe();
    }
    if (this.unSubHeaderPopup) {
      this.unSubHeaderPopup.unsubscribe();
    }
    if (this.unSubDocumentData) {
      this.unSubDocumentData.unsubscribe();
    }
    if (this.unSubIpadFullScreen) {
      this.unSubIpadFullScreen.unsubscribe();
    }
    if (this.unSubViewListObservable) {
      this.unSubViewListObservable.unsubscribe();
    }
    if (this.unSubPageSettingsObservable) {
      this.unSubPageSettingsObservable.unsubscribe();
    }
    if (this.unSubFilmstripImage) {
      this.unSubFilmstripImage.unsubscribe();
    }
    if (this.unSubPageId) {
      this.unSubPageId.unsubscribe();
    }
  }
  doThisOnSelect(event) {
    this.isFullTextActive = true;
    // if (event) {
    event.document = false;
    event.fullText = true;
    // }
    this.documentViewer = false;

    this.translateLanguageValues.forEach(obj => {
      if (this.currentLanguageForTranslate === obj.key) {
        // this.currentSelectedLanguageValue = obj.value;
      }
    });
    if (this.currentSelectedLanguageValue) {
      this.translateDoc(this.currentSelectedLanguageValue, this.currentLanguageForTranslate);
    }
    /*
    * set navigation index on click of tab
    */
    const documentImageIds = _map(this.documentImages, function (obj) { return obj.imageId; });
    this.documentCurrentIndex = (documentImageIds.indexOf(this.selectedPageId) + 1);
  }

  closelanguagePageTemplate() {
    this.docTab = false;
    this.languagemodalRef.hide();
  }
  gotoPreviouesDocumentFullText() {
    this.documentCurrentIndex = JSON.parse(JSON.stringify(parseInt(this.documentCurrentIndex, 10)));
    if (this.documentCurrentIndex === 0 || !this.documentCurrentIndex || this.documentCurrentIndex === '0') {
      this.documentCurrentIndex = 1;
    }
    const documentImageIds = _map(this.documentImages, function (obj) { return obj.imageId; });
    if (this.documentCurrentIndex >= documentImageIds.length) {
      this.documentCurrentIndex = documentImageIds.length;
    }
    let preIndex = documentImageIds.indexOf(this.selectedPageId);
    preIndex -= 1;
    this.selectedPageId = documentImageIds[preIndex];
    if (this.isFullTextActive) {
      // this.popupService.showLoader();
      this.searchService.translateDoc(this.documentData.wileyId, this.selectedPageId, this.currentLanguageCode, (response) => {
        if (response.status === 'FAILURE') {
          this.modalService.showNotification('ERROR', this.errorTranslatedDocument, '');
          preIndex += 1;
          this.selectedPageId = documentImageIds[preIndex];
          // this.popupService.hideLoader();
        } else {
          this.documentFulltext = response.data;
          // this.popupService.hideLoader();
          if (rtlLanguages.indexOf(this.currentLanguageForTranslate.toUpperCase()) > -1) {
            $('#rtl-language').addClass('rtl-languages');
          } else {
            $('#rtl-language').removeClass('rtl-languages');
          }
          // this.popupService.hideLoader();
        }
      }, (error) => {
        // this.popupService.hideLoader();
        preIndex += 1;
        this.selectedPageId = documentImageIds[preIndex];
      });
    }
    this.documentCurrentIndex = JSON.parse(JSON.stringify(parseInt(this.documentCurrentIndex, 10)));
    this.documentCurrentIndex -= 1;
    this.selectedPageId = documentImageIds[preIndex];
    if (document.getElementById('thumb' + this.documentCurrentIndex)) {
      const topPos = document.getElementById('thumb' + this.documentCurrentIndex).offsetTop;
      // this.searchResultDetailService.scrollTo(document.getElementById('thumbnailImageWrapper'), topPos - 10, 600);
      this.scrollToPosition = { val: topPos, random: Math.random() };
    }
    if (this.selectedPageId) {
      this.sharedService.filmstripImageList(this.selectedPageId);
      this.invokedFrom = { caller: 'Filmstrip', random: Math.random() };
    }
  }

  gotoNextDocumentFullText() {
    this.documentCurrentIndex = JSON.parse(JSON.stringify(parseInt(this.documentCurrentIndex, 10)));
    if (this.documentCurrentIndex === 0 || !this.documentCurrentIndex || this.documentCurrentIndex === '0') {
      this.documentCurrentIndex = 1;
    }
    const documentImageIds = _map(this.documentImages, function (obj) { return obj.imageId; });
    if (this.documentCurrentIndex >= documentImageIds.length) {
      this.documentCurrentIndex = documentImageIds.length;
    }
    let preIndex = documentImageIds.indexOf(this.selectedPageId);
    preIndex += 1;
    this.selectedPageId = documentImageIds[preIndex];
    if (this.isFullTextActive) {
      // this.popupService.showLoader();
      this.searchService.translateDoc(this.documentData.wileyId, this.selectedPageId, this.currentLanguageCode, (response) => {
        if (response.status === 'FAILURE') {
          this.modalService.showNotification('ERROR', this.errorTranslatedDocument, '');
          preIndex -= 1;
          this.selectedPageId = documentImageIds[preIndex];
        } else {
          this.documentFulltext = response.data;
          // this.popupService.hideLoader();
          if (rtlLanguages.indexOf(this.currentLanguageForTranslate.toUpperCase()) > -1) {
            $('#rtl-language').addClass('rtl-languages');
          } else {
            $('#rtl-language').removeClass('rtl-languages');
          }
          // this.popupService.hideLoader();
        }
      }, (error) => {
        // this.popupService.hideLoader();
        preIndex -= 1;
        this.selectedPageId = documentImageIds[preIndex];
      });
    }
    this.documentCurrentIndex += 1;
    this.selectedPageId = documentImageIds[preIndex];
    if (document.getElementById('thumb' + this.documentCurrentIndex)) {
      const topPos = document.getElementById('thumb' + this.documentCurrentIndex).offsetTop;
      // this.searchResultDetailService.scrollTo(document.getElementById('thumbnailImageWrapper'), topPos - 10, 600);
      this.scrollToPosition = { val: topPos, random: Math.random() };
    }
    if (this.selectedPageId) {
      this.sharedService.filmstripImageList(this.selectedPageId);
      this.invokedFrom = { caller: 'Filmstrip', random: Math.random() };
    }
  }

  gotoCurrentDocumentFulltext(event, doNotScrollOnclick?) {
    if (event.target) {
      if (event.target.value !== '' && event.key === 'Enter') {
        this.currentDocumentNumber = event.target.value;
      }
    } else {
      this.currentDocumentNumber = event;
    }
    const documentImageIds = _map(this.documentImages, function (obj) { return obj.imageId; });
    this.currentDocumentNumber = JSON.parse(JSON.stringify(parseInt(this.currentDocumentNumber, 10)));
    if (this.currentDocumentNumber === 0 || !this.currentDocumentNumber || this.currentDocumentNumber === '0') {
      this.currentDocumentNumber = this.documentCurrentIndex = 1;
      this.documentCurrentIndex = $('.full-text-pagination-page').val();
    }
    if (this.currentDocumentNumber > documentImageIds.length) {
      this.modalService.showNotification('WARNING', this.invalidPagenumber, this.limitReachExcededPage + documentImageIds.length);
      this.currentDocumentNumber = this.documentCurrentIndex = documentImageIds.length;
    }
    this.selectedPageId = documentImageIds[this.currentDocumentNumber - 1];
    if (this.currentDocumentNumber <= documentImageIds.length && this.currentDocumentNumber !== 0) {
      if (this.isFullTextActive) {
        // this.popupService.showLoader();
        if (this.documentData.languages.indexOf(this.currentLanguageCode) > -1) {
          this.searchService.translateDoc(this.documentData.wileyId, this.selectedPageId, this.currentLanguageCode, (response) => {
            if (response.status === 'FAILURE') {
              this.modalService.showNotification('ERROR', this.errorTranslatedDocument, '');
              this.documentCurrentIndex = this.currentDocumentNumber;
            } else {
              this.documentFulltext = response.data;
              // this.popupService.hideLoader();
              if (rtlLanguages.indexOf(this.currentLanguageForTranslate.toUpperCase()) > -1) {
                $('#rtl-language').addClass('rtl-languages');
              } else {
                $('#rtl-language').removeClass('rtl-languages');
              }
              // this.popupService.hideLoader();
            }
          }, (error) => {
            // this.popupService.hideLoader();
            this.documentCurrentIndex = this.currentDocumentNumber;
          });
        } else {
          // this.popupService.hideLoader();
        }
      } else {
        this.documentCurrentIndex = this.currentDocumentNumber;
      }
    }
    this.documentCurrentIndex = this.currentDocumentNumber;
    if (!doNotScrollOnclick) {
      if (document.getElementById('thumb' + this.documentCurrentIndex)) {
        const topPos = document.getElementById('thumb' + this.documentCurrentIndex).offsetTop;
        // this.searchResultDetailService.scrollTo(document.getElementById('thumbnailImageWrapper'), topPos - 10, 600);
        this.scrollToPosition = { val: topPos, random: Math.random() };
      }
      if (this.selectedPageId) {
        this.sharedService.filmstripImageList(this.selectedPageId);
      }
    }
    // if (this.selectedPageId) {
    //   this.sharedService.filmstripImageList(this.selectedPageId);
    // }
  }
  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  backToSearchResultsPage(isBrowserBack?) {
    if (this.loadedFrom === 'details') {
      this.backToSearchResults();
    }
    if (this.isNeedToLogout) {
      sessionStorage.clear();
      localStorage.clear();
    }
    this.unSubActivatedRoute = this.activatedRoute.params.subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      delete currentParams['pageId'];
      if (sessionStorage.getItem('display')) {
        // currentParams['display'] = sessionStorage.getItem('display');
      }
      this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
    });
    if (isBrowserBack) {
      if (this.defaultImageToBeLoaded.isDocViewerShow) {
        this.defaultImageToBeLoaded.isDocViewerShow = false;
      }
      this.docViewerClosed.emit(false);
      this.sharedService.sharebacktoDetailsPageFlag(false);
      if (this.defaultImageToBeLoaded.currentOffset) {
        $('html, body').animate({ scrollTop: (this.defaultImageToBeLoaded.currentOffset.top - 500) }, 100);
      }
    } else {
      this.docViewerClosed.emit(false);
      if (this.defaultImageToBeLoaded) {
        if (this.defaultImageToBeLoaded.isDocViewerShow) {
          this.defaultImageToBeLoaded.isDocViewerShow = false;
          if (this.defaultImageToBeLoaded.currentOffset) {
            $('html, body').animate({ scrollTop: (this.defaultImageToBeLoaded.currentOffset.top - 500) }, 100);
          }
        } else {
          this.backToSearchResults();
        }
      } else {
        this.backToSearchResults();
      }
    }
    // this.backToSearchResults();
    this.urlUtilityService.setContentSubType(false);
  }
  gotoHome() {
    if (this.isNeedToLogout) {
      sessionStorage.clear();
      localStorage.clear();
    }
    this.router.navigate([this.homePageUrl]);
  }
  setSubject(subject) {
    const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
    currentSearchSettings.subject = [subject];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(this.pageContentData.searchTerm, currentSearchSettings);
  }

  itemsByType(params) {
    this.searchService.getDocumentItembyType(params, (response) => {
      const tableOfContent = response;
      this.itemsTitle.emit(tableOfContent);
      const parentNodes = [];
      if (tableOfContent) {
        const contents = Object.keys(tableOfContent);
        contents.forEach((content, parentIndex) => {
          const childNodes = [];
          if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {
            tableOfContent[content].forEach((child, childIndex) => {
              const grandChildNodes = [];
              const pages = child.pageNo.split(',');
              if (child.itemTitle == null) {
                child.itemTitle = 'Untitled';
              }
              this.pageLength = pages.length + ' Page(s)';
              childNodes.push({
                name: child.itemTitle + ', ' + this.pageLength,
                tooltipContent: this.itemFullTitle + ', ' + this.pageLength,
                children: [],
                pageId: child.pages[0] ? child.pages[0] : '',
                chilldPageIds: child.pages,
                label: child.label,
                notesAvailable: child.notesAvailable
              });
            });
          }
          parentNodes.push({
            name: content,
            children: childNodes
          });
        });
        this.manuscriptTypeTableofContentNodes = parentNodes;
        this.itemsTypeResultLoader = false;
      }
    });
    const $treenode = document.getElementsByClassName(this.selectedDocPageId.pageId);
    if ($treenode.length > 0) {
      if ($treenode[0]) {
        setTimeout(() => {
        }, 2000);
      }
    }
    if ($('.slim-strip-container').hasClass('gridview-images')) {
      this.isGridViewEnable = true;
      $('.slim-strip-container').removeClass('gridview-images');
      $('.docviewer-imageframe').removeClass('gridview-images');
    }

  }
  onNodeSelect($event, pageID?) {
    const pageDocId = this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams;
    this.invokedFrom = { caller: 'IllustrationTab', random: Math.random() };
    if (pageID) {
      this.sharedService.filmstripImageList(pageID);
      this.changetoFullText(this.checkModel);
    } else {
      if (!this.isTitleAvailable) {
        this.itemTitleShow = true;
        if ($event.node.hasChildren === false) {
          if ($event.node.data['pageId']) {
            const item = $event.node.data.label;
            this.sharedService.filmstripImageList($event.node.data['pageId']);
            this.changetoFullText(this.checkModel);
            if ($event.node.data.notesAvailable) {
              this.isItemsNoteAvailable = true;
              this.httpService.get(environment.APIUrl + APIURLS['getDocumentNotes'] + '/' + this.documentData.wileyId + '-' + item, res => {
                const itemDetails = res['notes'];
                const documentttype = Object.keys(itemDetails).map(key => ({ type: key, value: itemDetails[key] }));
                this.itemNotes = documentttype;
                this.sharedService.shareitemNotes(this.itemNotes);
                setTimeout(() => {
                  if (document.getElementById('thumb' + this.documentCurrentIndex)) {
                    document.getElementById('thumb' + this.documentCurrentIndex).scrollIntoView({ behavior: 'smooth' });
                  }
                }, 1500);
              }, (error) => {
              });
            }
          }
        }
      }
    }
  }
  expandTreeOnLoad(event) {
    event.treeModel.expandAll();
  }
  addPageIdAsClass(pageIds) {
    return pageIds;
  }
  checkPageIdExist(childNodePageIds) {
    if (childNodePageIds) {
      return childNodePageIds.includes(this.selectedDocPageId.pageId);
    } else {
      return false;
    }
  }
  loadNodedataToItems($event) {
    if (!this.isTitleAvailable) {
      if ($event.node.hasChildren === false) {
        if ($event.node.data['pageId']) {
          this.sharedService.filmstripImageList($event.node.data['pageId']);
        }
      }
    }
  }
  itemsInOrder(params) {
    this.searchService.getDocumentByItem(params, (response) => {
      $('.angular-tree-component').trigger('click');
      const tableOfContent = response;
      const parentNodes = [];

      tableOfContent.forEach((parent, childIndex) => {
        const childNodes = [];
        const pages = parent.pageNo.split(',');

        if (parent.itemTitle == null) {
          parent.itemTitle = 'Untitled';
        }
        this.pageLength = pages.length + 'Page(s)';
        this.parentItemTile = parent.subType + ', ';
        parentNodes.push({
          name: this.parentItemTile + ' ' + parent.itemTitle + ', ' + pages.length + ' Page(s)',
          tooltipContent: this.parentItemTile + ',' + this.sequenceFullTitle + ', ' + pages.length + ' Page(s)',
          children: childNodes,
          pageId: parent.pages[0] ? parent.pages[0] : '',
          chilldPageIds: parent.pages,
          label: parent.label,
          notesAvailable: parent.notesAvailable
        });
        this.manuscriptOrderTableofContentNodes = parentNodes;
        this.manuscriptResultLoader = false;
      });

    });
    const $treenode = document.getElementsByClassName(this.selectedDocPageId.pageId);
    if ($treenode[1]) {
      setTimeout(() => {
        $treenode[1].scrollIntoView({ behavior: 'smooth' });
      }, 1500);
    }
  }
  itemsInToc(params) {
    this.searchService.getDocumentItembyTOC(params, (response) => {
      $('.angular-tree-component').trigger('click');
      if (response) {
        const toc = Object.keys(response['tableOfContent']);
        const parentNodes = [];
        toc.forEach((key, value) => {
          const childNodes = [];
          if (response['tableOfContent'][key].length > 1) {
            response['tableOfContent'][key].forEach((childkey, childValue) => {
              const grandChildNodes = [];
              childNodes.push({
                name: childkey.title,
                children: grandChildNodes,
                pageId: childkey.pages[0] ? childkey.pages[0] : ''
              });
            });
            parentNodes.push({
              name: key,
              children: childNodes
            });
          } else {
            if (response['tableOfContent'][key].length === 1) {
              parentNodes.push({
                name: key,
                pageId: response['tableOfContent'][key][0].pages[0],
                children: []
              });
            }
          }
          this.monographTableofContentNodes = parentNodes;
          this.monographResultLoader = false;
        });
      }
    });
    if ($('.slim-strip-container').hasClass('gridview-images')) {
      this.isGridViewEnable = true;
      $('.slim-strip-container').removeClass('gridview-images');
      $('.docviewer-imageframe').removeClass('gridview-images');
    }
  }
  illustraionTypes(params) {
    this.searchService.getIllustraion(params, (response) => {
      $('.angular-tree-component').trigger('click');
      const tableOfContent = response;
      const parentNodes = [];
      if (tableOfContent) {
        const contents = Object.keys(tableOfContent);
        if (Object.keys(tableOfContent).length > 0) {
          this.showIllustrations = true;
        } else {
          this.hideIllustrations();
        }
        contents.forEach((content, parentIndex) => {
          const childNodes = [];
          if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {

            tableOfContent[content].forEach((child, childIndex) => {
              if (child.caption === null) {
                child.caption = 'Untitled';
              }
              const grandChildNodes = [];
              childNodes.push({
                name: child.caption,
                children: grandChildNodes,
                tableId: child.tableId ? child.tableId : '',
                pageId: child.pageId ? child.pageId : '',
                width: child.width ? child.width : '',
                height: child.height ? child.height : '',
                hpos: child.hpos ? child.hpos : '',
                vpos: child.vpos ? child.vpos : '',
                chilldPageIds: child.pageId
              });
            });
          }
          parentNodes.push({
            name: content,
            children: childNodes
          });
        });
        this.illustrationNodes = parentNodes;
        this.illustrationresultLoader = false;
      }
    });
    const $treenode = document.getElementsByClassName(this.selectedDocPageId.pageId);
    if ($treenode[0]) {
      setTimeout(() => {
        // $treenode[0].scrollIntoView({ behavior: 'smooth' });
      }, 2000);
    }
    if ($('.slim-strip-container').hasClass('gridview-images')) {
      this.isGridViewEnable = true;
      $('.slim-strip-container').removeClass('gridview-images');
      $('.docviewer-imageframe').removeClass('gridview-images');
    }
  }


  mouseEnter(treeNode) {
    if (treeNode.hasChildren === false) {
      this.illustrationDocumentImage = '';
      if (treeNode.data.pageId) {
        this.illustrationDocID = treeNode.data.docId;
        this.illustrationPageID = treeNode.data.pageId;

        this.showLoader();
        setTimeout(() => {
          // tslint:disable-next-line:max-line-length
          this.illustrationDocumentImage = `${environment.APIUrl}file/thumb/${this.documentData.wileyId}/${this.illustrationPageID}?access_token=${this.accessToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`;
          setTimeout(() => {
            this.hideLoader();
          }, 500);
        }, 0);
        $('#illustrationImageDiv').addClass('show-illustrationimage');
        if (treeNode.position > (this.illustrationsTabElement.nativeElement.offsetHeight -
          (this.illustrationPreviewElement.nativeElement.offsetHeight + 65))) {
          this.illustrationPreviewPosition = (this.illustrationsTabElement.nativeElement.offsetHeight
            - this.illustrationPreviewElement.nativeElement.offsetHeight);
        } else {
          this.illustrationPreviewPosition = (treeNode.position);
        }
      }
    }

  }
  mouseLeave(treeNode) {
    $('#illustrationImageDiv').removeClass('show-illustrationimage');
  }
  showLoader() {
    this.illustrationsLoader = true;
  }
  hideLoader() {
    this.illustrationsLoader = false;
  }
  onItemSelectionChange(index, docId) {
    if (index === 0) {
      this.isItemByTypeOpen = true;
      this.isItemByTypeSequence = false;
      this.itemsByType(docId);
    }
    if (index === 1) {
      this.isItemByTypeOpen = false;
      this.isItemByTypeSequence = true;
      this.itemsInOrder(docId);
    }

  }

  onTableSelect($event) {
    if ($event.node.hasChildren === false) {
      const tableID = $event.node.data['tableId'];
      if ($event.node.data['pageId']) {
        this.invokedFrom = { caller: 'IllustrationTab', random: Math.random() };
        const nodeData = $event.node.data;
        this.pagechanged.emit({
          pageId: nodeData['pageId'],
          pageData: {
            tableId: nodeData.tableId,
            width: nodeData.width ? nodeData.width : '',
            height: nodeData.height ? nodeData.height : '',
            hpos: nodeData.hpos ? nodeData.hpos : '',
            vpos: nodeData.vpos ? nodeData.vpos : '',
            highLight: true
          }
        });
        this.changetoFullText(this.checkModel);
        this.sharedService.filmstripImageList($event.node.data['pageId']);
        const pageDocId = this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams;
      }
    }
  }
  downIcon(val) {
    this.onOverlayClick(val);
  }
  openSoureceCitation() {
    this.sourceCitationmodalRef = this.bsModalService.show(this.sourceCitationTemplate,
      { class: 'large', backdrop: true, ignoreBackdropClick: true });
  }
  closeCitationModal() {
    this.sourceCitationmodalRef.hide();
  }


  setCurrentActiveImageIndex(event) {
    this.documentCurrentIndex = event + 1;
    this.invokedFrom = { caller: 'Filmstrip', random: Math.random() };
  }

  togglefuzzySearch() {
    this.fuzzyChecked = true;
    this.toggleSearch = !this.toggleSearch;
    let keywords = this.documentSearchTermSplitted.filter(res => {
      if (res.checked) { return res.value; }
    });
    keywords = keywords.map(res => {
      return res.value;
    });
    keywords = keywords.toString().replace(/,/g, ' ');
    this.searchWithin(keywords, true);
    this.invokedFrom = { caller: 'SearchWithIn', random: Math.random() };
  }
  searchWithin(keyword?, fuzzySearch?) {
    if (!keyword) {
      // this.documentSearchTermSplitted = [];
      // this.initialSearchTerm = '';
      return;
    }
    this.searchWithInLoading = true;
    this.pageFullTextPageIds = [];
    this.matchedMetadata = null;
    this.pageFullTextObj = [];
    if (this.keyword) {
      this.keyword = this.keyword.trim();
    }
    if (keyword && !fuzzySearch) {
      this.selecrPage = true;
      this.enableFuzzySearch = true;
      this.sharedService.documentSearchDataReceived(this.keyword);
      this.searchTermChanged.emit({ keyword: this.keyword, fuzzy: this.toggleSearch });
      if (this.keyword.includes('"')) {
        this.isSearchTermIncludesPhrase = true;
        this.initialSearchTerm = this.keyword;

        this.documentSearchTermSplitted = [];
          const splitedWord = this.keyword.match(/\w+|"[^"]+"/g);
          splitedWord.forEach(obj => {
            if (obj !== '') {
              this.documentSearchTermSplitted.push({ value: obj, checked: true });
            }
          });
          setTimeout(() => {
            this.searchWithin(this.documentSearchTermSplitted, true);
          }, 1500);
        // if (this.keyword) {
        //   this.documentSearchTermSplitted = [{ value: this.keyword, checked: true }];
        // }
      } else {
        this.searchTermwithSpaces = (/\s/.test(this.keyword));
        this.keyword = this.keyword.replace(/[&\/\\#,+()$~%.`:+=;*?<>{}]/g, '');
        this.searchtermWithSpaceTerms = this.keyword;
        if (this.searchTermwithSpaces) {
          this.isSearchTermIncludesPhrase = false;
          let splitWords = [];
          let splitWord;
          splitWords = this.searchtermWithSpaceTerms.split(' ');

          if (stopWords.indexOf(this.searchtermWithSpaceTerms.toLowerCase())) {
            splitWords = splitWords.filter((el) => !stopWords.includes(el.toLowerCase()));
            splitWord = splitWords.toString();
            this.searchtermWithSpaceTerms = splitWord.split(',');
          }
          if (!fuzzySearch) {
            const documentSearchTermSplitted = [].concat.apply([], this.searchtermWithSpaceTerms.map((term, index) => {
              return index % 2 ? term : term.split(' ');
            })).filter(Boolean);
            this.documentSearchTermSplitted = [];
            documentSearchTermSplitted.forEach(obj => {
              this.documentSearchTermSplitted.push({ value: obj, checked: true });
            });
            this.documentSearchTermSplitted = _uniqBy(this.documentSearchTermSplitted, 'value');
          }
          if (this.documentSearchTermSplitted.length === 1 || this.documentSearchTermSplitted.length === 2) {
            this.scrollFix = false;
            this.scrollSix = false;
            this.scrollFour = false;
            this.scrollTwo = true;
          } else if (this.documentSearchTermSplitted.length === 3 || this.documentSearchTermSplitted.length === 4) {
            this.scrollFix = false;
            this.scrollSix = false;
            this.scrollFour = true;
            this.scrollTwo = false;
          } else if (this.documentSearchTermSplitted.length === 5 || this.documentSearchTermSplitted.length === 6) {
            this.scrollFix = false;
            this.scrollSix = true;
            this.scrollFour = false;
            this.scrollTwo = false;
          } else if (this.documentSearchTermSplitted.length > 6) {
            this.scrollFix = true;
            this.scrollSix = false;
            this.scrollFour = false;
            this.scrollTwo = false;

          }

        } else {
          if (stopWords.indexOf(this.keyword.toLowerCase()) > -1) {
            this.keyword = '';
            this.searchWithInLoading = false;
          } else {
            this.keyword = this.keyword;
          }
          if (this.keyword) {
            this.documentSearchTermSplitted = [{ value: this.keyword, checked: true }];
          }
          // this.initialSearchTerm = this.keyword;
          this.isSearchTermIncludesPhrase = false;
        }
      }
      if (this.keyword) {
        this.callgetMetaDatasFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, this.keyword);
        this.callgetPageFulltextFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, this.keyword,
          true);
      }
    } else {
      this.searchWithInLoading = true;
      this.documentSearchTermSplitted = _uniqBy(this.documentSearchTermSplitted, 'value');
      let keywords = this.documentSearchTermSplitted.filter(res => {
        if (res.checked) { return res.value; }
      }).map(res => {
        return res.value;
      });
      keywords = keywords.toString();
      keywords = keywords.replace(/,/g, ' ');
      // tslint:disable-next-line:max-line-length
      this.callgetMetaDatasFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, keywords);
      // tslint:disable-next-line:max-line-length
      this.callgetPageFulltextFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, keywords, true);
      if (this.documentPageImages.length > 0) {
        this.loadRelaventPage(this.documentPageImages[0].imageId);
      } else {
        this.searchWithInLoading = false;
      }
    }
  }

  onDocumentFulltextChange(event) { }

  /*Menu Toggle */
  menuToggle() {
    if (this.toggleMenu) {
      this.toggleMenu = false;
      // this.isGridViewEnable = false;
    } else if (!this.toggleMenu) {
      this.toggleMenu = true;
      this.isGridViewEnable = true;
    }
  }

  loadRelaventPage(pageNo: any, event?, splitedSearchTerms?) {
    this.isNoRecodesFound = false;
    this.invokedFrom = { caller: 'SearchWithIn', random: Math.random() };
    let splitedSearchTerm: any;
    if (splitedSearchTerms) {
      if (splitedSearchTerms.length > 0) {
        splitedSearchTerm = _map(splitedSearchTerms.filter(res => (res.checked === true)), 'value');
        splitedSearchTerm = splitedSearchTerm.toString().replace(/,/g, ' ');
      }
    }
    if (this.documentSearchTermSplitted.length > 0) {
      this.splitedSearchTerms =
        _map(this.documentSearchTermSplitted.filter(res => (res.checked === true)), 'value');
      this.splitedSearchTerms = this.splitedSearchTerms.toString().replace(/,/g, ' ');
    }
    this.overlaysForTermSearch = {
      initialSearchTerm: this.initialSearchTerm,
      splitedSearchTerms: this.splitedSearchTerms || splitedSearchTerm,
      keyword: this.keyword,
      pageNo: pageNo,
      random: Math.random()
    };
    this.sharedService.filmstripImageList(pageNo);
    this.changetoFullText(this.checkModel);
    this.callGetPageLevelFullText(pageNo);


  }
  getItemNotes(item) {
    this.isTitleAvailable = true;
    this.isItemsNoteAvailable = false;
    this.httpService.get(environment.APIUrl + APIURLS['getDocumentNotes'] + '/' + this.documentData.wileyId + '-' + item, res => {
      const itemDetails = res['notes'];
      const documentttype = Object.keys(itemDetails).map(key => ({ type: key, value: itemDetails[key] }));
      this.itemNotes = documentttype;
      this.itemNotesmodalRef = this.bsModalService.show(this.itemNotesTemplate, { backdrop: true, ignoreBackdropClick: true });
      $('.notes-content').hide();
    }, (error) => {
    });
  }
  loadNodedataIllustrations($event) {
    if ($event.node.hasChildren === false) {
      const tableID = $event.node.data['tableId'];
      if ($event.node.data['pageId']) {
        const nodeData = $event.node.data;
        this.pagechanged.emit({
          pageId: nodeData['pageId'],
          pageData: {
            tableId: nodeData.tableId,
            width: nodeData.width ? nodeData.width : '',
            height: nodeData.height ? nodeData.height : '',
            hpos: nodeData.hpos ? nodeData.hpos : '',
            vpos: nodeData.vpos ? nodeData.vpos : '',
            highLight: true
          }
        });
        this.changetoFullText(this.checkModel);
        this.sharedService.filmstripImageList($event.node.data['pageId']);
        const pageDocId = this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams;
        this.getIllustrationsbypage(pageDocId, $event.node.data['pageId']);
      }
    }
  }
  showItemNotesPopup() {
    this.itemNotesmodalRef = this.bsModalService.show(this.itemNotesTemplate, { backdrop: true, ignoreBackdropClick: true });
  }
  closeItemsNotePopup() {
    this.itemNotesmodalRef.hide();
    this.isTitleAvailable = false;
  }
  getRelevantPagesBySearchTerm(event) {
    this.searchWithInLoading = true;
    this.pageFullTextObj = [];
    this.matchedMetadata = [];
    this.splitedSearchTerms = [];
    if (this.initialSearchTerm) {
      this.splitedSearchTerms = this.initialSearchTerm;
    }
    this.documentSearchTermSplitted = _uniqBy(this.documentSearchTermSplitted, 'value');
    if (this.documentSearchTermSplitted.length > 0) {
      this.splitedcheckedTerms = this.splitedSearchTerms =
        _map(this.documentSearchTermSplitted.filter(res => (res.checked === true)), 'value');
      this.splitedSearchTerms = this.splitedSearchTerms.toString().replace(/,/g, ' ');
      this.overlaysForTermSearch = {
        initialSearchTerm: this.initialSearchTerm,
        splitedSearchTerms: this.splitedSearchTerms,
        keyword: this.keyword,
        pageNo: null,
        random: Math.random()
      };
      if (this.splitedcheckedTerms.length === 0) {
        this.isNoRecodesFound = true;
        this.searchWithInLoading = false;
        this.pageFullTextObj = [];
        this.pageFullTextPageIds = [];
        // this.sharedService.clearOverlay({ random: Math.random() });
      }
      this.sharedService.clearOverlay({ random: Math.random() });
    }
    this.pageFulltextMatchedData = [];
    this.currentSearchTerm = this.splitedSearchTerms;
    if (this.splitedSearchTerms) {
      // this.modalService.showLoader();
      this.enableFuzzySearch = true;
      this.callgetMetaDatasFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, this.splitedSearchTerms);
      this.callgetPageFulltextFromKeyword(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, this.splitedSearchTerms);
    }
    this.invokedFrom = { caller: 'SearchWithIn', random: Math.random() };
  }

  getRelatedSubjects() {
    this.loader.relatedSubjects = true;
    this.searchResultDetailService.getRelatedSubjects(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, (response) => {
      if (response) {
        if (response.length > 0) {
          this.relatedSubjectsData = response;
        } else {
          this.removeRelatedSubjectTab();
        }
      } else {
        this.removeRelatedSubjectTab();
      }
      this.loader.relatedSubjects = false;
    }, (error) => {
      this.removeRelatedSubjectTab();
      this.loader.relatedSubjects = false;
    });
  }

  removeRelatedSubjectTab() {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].id === 'relatedSubjectsTab') {
        this.tabs.splice(this.tabs.indexOf(this.tabs[i]), 1);
      }
    }
  }
  changetoFullText(event) {
    // if (event) {
    event.document = true;
    event.fullText = false;
    // }
    this.documentViewer = true;
  }
  changetoDocument(event) {
    // if (event) {
    event.document = false;
    event.fullText = true;
    // }
    this.translateLanguageValues.forEach(obj => {
      if (this.currentLanguageForTranslate === obj.key) {
        // this.currentSelectedLanguageValue = obj.value;
      }
    });
  }
  callGetRelevanePages(wileyId, searchterm) {
    // this.modalService.showLoader();
    // const imageList = [];
    // this.documentImagesWithTitles.forEach(imgID => {
    //   imageList.push(imgID);
    // });
    this.searchService.getImageURLs(wileyId, this.documentImagesWithTitles, (imageURLs) => {
      if (searchterm) {
        // this.modalService.hideLoader();
        if (this.illustrationNodes) {
          this.loadTableOverlayFromNode(this.illustrationNodes, imageURLs, (imgList) => {
            this.documentImages = imgList;
            if (this.pageId) {
              const documentImageIds = _map(this.documentImages, function (obj) { return obj.imageId; });
              this.documentCurrentIndex = documentImageIds.indexOf(this.pageId) + 1;
              if (this.documentCurrentIndex === 0 || !this.documentCurrentIndex) {
                this.documentCurrentIndex = 1;
              }
            }
          });
        } else {
          this.documentImages = imageURLs;
        }
      } else {
        // this.modalService.hideLoader();
        if (this.illustrationNodes) {
          this.loadTableOverlayFromNode(this.illustrationNodes, imageURLs, (imgList) => {
            this.documentImages = imgList;
            if (this.pageId) {
              const documentImageIds = _map(imageURLs, function (obj) { return obj.imageId; });
              this.documentCurrentIndex = documentImageIds.indexOf(this.pageId) + 1;
              if (this.documentCurrentIndex === 0 || !this.documentCurrentIndex) {
                this.documentCurrentIndex = 1;
              }
            }
          });
        } else {
          this.documentImages = imageURLs;
        }
      }
    });
  }
  callgetMetaDatasFromKeyword(docId, searchterm) {
    if (searchterm) {
      if (!searchterm.includes('-') && !searchterm.includes('"')) {
        const arr = searchterm.split(' ');
        searchterm = arr.filter(function (value, index, self) {
          return self.indexOf(value) === index;
        }).join(' ');
      }
      this.searchService.getMetaDatasFromKeyword(docId, searchterm, (response) => {
        $('.no-padding').trigger('click');
        const matchedDataList = [];
        if (response) {
          this.isNoRecodesFound = false;
          this.currentDocID = this.documentIDFromRouteParams || this.defaultImageToBeLoaded['docId'];
          matchedDataList.push(response.results.segregatedFields);
          matchedDataList.forEach(obj => {
            this.matchedMetadata = obj;
          });
          if (this.matchedMetadata) {
            if (this.matchedMetadata[this.currentDocID]) {
              if (this.matchedMetadata[this.currentDocID].length > 0) {
                this.matchedMetadata[this.currentDocID].forEach((obj, index) => {
                  const key: any = Object.keys(obj);
                  if (key[0].toUpperCase() === 'ITEMS') {
                    this.matchedItemTitles = [];
                    this.fullTextObj = obj;
                    const matchedItems = Object.keys(this.fullTextObj).map(prop => ({ type: key, value: this.fullTextObj[key] }));
                    matchedItems.forEach(element => {
                      element.value.forEach(obj2 => {
                        obj2.snipplets.forEach(elem => {
                          this.matchedItemTitles.push(elem);
                        });
                      });
                    });
                  }
                });
              }
            }
          }
        } else {
          this.isNoRecodesFound = true;
        }
        this.searchWithInLoading = false;
      }, !this.toggleSearch);
    } else {
      this.searchWithInLoading = false;
    }
  }

  callgetPageFulltextFromKeyword(docId, searchterm, selectPage?) {
    if (searchterm) {
      if (!searchterm.includes('-') && !searchterm.includes('"')) {
        const arr = searchterm.split(' ');
        searchterm = arr.filter(function (value, index, self) {
          return self.indexOf(value) === index;
        }).join(' ');
      }
      this.searchService.getPageFulltextFromKeyword(docId, searchterm, (response) => {
        $('.no-padding').trigger('click');
        if (response) {
          this.isNoRecodesFound = false;
          this.pageFullTextObj = [];
          this.pageFullTextPageIds = [];
          this.pageFulltextMatchedData = response.results.segregatedFields;
          for (const prop in this.pageFulltextMatchedData) {
            if (prop) {
              if (this.pageFulltextMatchedData[prop]) {
                if (this.pageFulltextMatchedData[prop].length > 0) {
                  this.pageFulltextMatchedData[prop].forEach(obj => {
                    const obj1 = new Object(obj);
                    obj1['pageId'] = prop;
                    this.pageFullTextObj.push(obj1);
                  });
                } else {
                  // this.pageFullTextObj.push({ pageId: prop });
                }
              }
            }
          }
          this.pageFullTextObj.forEach(obj => {
            this.pageFullTextPageIds.push({ id: obj['pageId'] });
          });
          if (this.pageFullTextPageIds.length > 0) {
            this.isFillterByRelevantPage = true;
            setTimeout(() => {
              this.searchService.getImageURLs(docId, this.pageFullTextPageIds, (imageURLs) => {
                $('.no-padding').trigger('click');
                this.documentPageImages = imageURLs;
                this.documentPageImages = this.documentPageImages.sort((a, b) => a.pageNumber - b.pageNumber);
                if (this.documentPageImages.length > 0) {
                  if (!this.isFillterByRelevantPage || this.leftMenuTabset.tabs[2].active === true) {
                    this._selectedPage = { pageId: this.documentPageImages[0].imageId };
                    this.loadRelaventPage(this.documentPageImages[0].imageId);
                  }
                }
              });
            }, 100);
          } else {
            this.isFillterByRelevantPage = false;
          }
          setTimeout(() => {
          }, 1000);
        }
        this.searchWithInLoading = false;
      }, !this.toggleSearch);
    } else {
      this.searchWithInLoading = false;
    }
  }
  toggleFilter() {
    this.showFillterByRelevantPage = !this.showFillterByRelevantPage;
    this.isFillterByRelevantPage = !this.isFillterByRelevantPage;
    if (!this.isFillterByRelevantPage) {
      if (this.documentPageImages.length > 0) {
        if (this.documentSearchTermSplitted.length > 0) {
          this.splitedSearchTerms =
            _map(this.documentSearchTermSplitted.filter(res => (res.checked === true)), 'value');
          this.splitedSearchTerms = this.splitedSearchTerms.toString().replace(/,/g, ' ');
        }
        this._selectedPage = { pageId: this.documentPageImages[0].imageId };
        this.loadRelaventPage(this.documentPageImages[0].imageId);
      }
    }
  }

  afterMainImageLoaded(event) {
    this.isMainImageLoaded = event;
  }

  gotoCorrespondingTab(metadata) {
    const metadataKey = Object.keys(metadata);
    if (metadataKey.toString() === 'CITATION') {
      this.leftMenuTabset.tabs[1].active = true;
    } else if (metadataKey.toString() === 'NOTES') {
      for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
        this.currentTabId = this.leftMenuTabset.tabs[i].id;
        if (this.currentTabId === 'notesTab') {
          this.leftMenuTabset.tabs[i].active = true;
        }
      }
    } else if (metadataKey.toString() === 'SUBJECT') {
      for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
        this.currentTabId = this.leftMenuTabset.tabs[i].id;
        if (this.currentTabId === 'relatedSubjectsTab') {
          this.leftMenuTabset.tabs[i].active = true;
        }
      }
    }
  }
  reletadSubjectsMouseEnter(subject, event) {
    if (subject) {
      if ($('.relatedsub-link').hasClass('active-sub')) {
        $('.relatedsub-link').removeClass('active-sub');
      }
      event.target.classList.add('active-sub');
    }

  }
  reletadSubjectsMouseLeave(subject, event) {
    if (subject) {
      event.target.classList.remove('active-sub');
    }
  }
  gotoPageFullText(id) {
    this.selectedPageId = id;
    this.sharedService.filmstripImageList(id);
    this.doThisOnSelect(this.checkModel);
  }
  checkAccordionStatus(event: Event, accordionGroup) {
    if (typeof (event) === 'boolean') {
      switch (accordionGroup) {
        case 'toggleMetadataIcon':
          this.toggleMetadataIcon = event;
          break;
        case 'tooglePagesIcon':
          this.tooglePagesIcon = event;
          break;
        case 'toggleFulltextIcon':
          this.toggleFulltextIcon = event;
          break;
        case 'toggleNotesIcon':
          this.toggleNotesIcon = event;
          break;
        case 'toggleCollectionNotesIcon':
          this.toggleCollectionNotesIcon = event;
          break;
        default:
          break;

      }
    }
  }
  closeDownloadTableModal() {
    if (this.downloadTablemodalRef) {
      this.downloadTablemodalRef.hide();
    }
  }
  openImageHelp(event) {
    if (!this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: true,
        language: false,
        shareDocument: false
      });
    } else if (this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false
      });
    }
    event.stopPropagation();
  }
  selectPagesTab() {
    setTimeout(() => {
      if (document.getElementById('thumb' + this.documentCurrentIndex)) {
        document.getElementById('thumb' + this.documentCurrentIndex).scrollIntoView({ behavior: 'smooth' });
      }
    }, 1500);
  }
  gotoCurrentItemTab(event, item) {
    if (this.manuscriptTypeTableofContentNodes) {
      this.manuscriptTypeTableofContentNodes.forEach(obj => {
        obj['children'].forEach(prop => {
          if (prop['label'] === item) {
            this.onNodeSelect(event, prop['pageId']);
          }
        });
      });
      this.leftMenuTabset.tabs[3].active = true;
    }
  }
  downloadCurrentFulltext(docID) {

    this.popupService.showLoader();
    this.httpService.getDataObservable(this.downloadableUrl).subscribe(
      (response) => {
        const textToSave = response['_body'];
        const textToSaveAsBlob = new Blob([textToSave], { type: 'text/plain' });
        const fileNameToSaveAs = docID + '_entire_document';
        if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
          window.navigator.msSaveBlob(textToSaveAsBlob, fileNameToSaveAs);
        } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
          window.open('data:application/json;charset=utf-8,' + encodeURIComponent(textToSave), '_blank');
        } else {
          const textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
          const downloadLink = document.createElement('a');
          downloadLink.download = fileNameToSaveAs;
          downloadLink.innerHTML = 'Download File';
          downloadLink.href = textToSaveAsURL;
          downloadLink.style.display = 'block';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
        // this.popupService.hideLoader();
      });
  }
  downloadTextAsFile() {
    this.httpService.getDataObservable(this.downloadableUrl).subscribe(
      (response) => {
        const textToSave = response['_body'];
        const textToSaveAsBlob = new Blob([textToSave], { type: 'text/plain' });
        const fileNameToSaveAs = this.documentData['wileyId'] + this.selectedPageId + this.currentLanguageCode + '_full_text';
        if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
          window.navigator.msSaveBlob(textToSaveAsBlob, fileNameToSaveAs);
        } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
          window.open('data:application/json;charset=utf-8,' + encodeURIComponent(textToSave), '_blank');
        } else {
          const textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
          const downloadLink = document.createElement('a');
          downloadLink.download = fileNameToSaveAs;
          downloadLink.innerHTML = 'Download File';
          downloadLink.href = textToSaveAsURL;
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      }, err => {
        this.closeDownloadTableModal();
      });
  }
  getIllustrationsbypage(docId, pageId) {
    this.httpService.get(environment.APIUrl + APIURLS['getIllustrationsbypage'] + docId + '/' + pageId, res => {
      if (res) {
        const illustrations = Object.keys(res).map(key => ({ type: key, value: res[key] }));
        this.illustrationsOverlays = [];
        illustrations.forEach(element => {
          element.value.forEach(overlayValue => {
            this.illustrationsOverlays.push(new Overlay(overlayValue.width, overlayValue.height, overlayValue.hpos,
              overlayValue.vpos, overlayValue.type));
          });
        });
        this.sharedService.shareIllustrationOverlays(this.illustrationsOverlays);
        this.illustrationsOverlays = [];
      }
    }, (error) => {
    });
  }

  disclaimerToggle() {
    this.toggledisclaimerShow = !this.toggledisclaimerShow;
    if (this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    } else if (!this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    }
  }

  getWebsiteLinkTags(docValue: any): any {
    return docValue.split(';').toString().replace(/,/g, '').replace(/"/g, '');
  }
  girdViewThumBImages() {
    this.isGridViewEnable = false;
    this.gridViewThumbActive = Math.random();
  }
  listViewThumBImages() {
    this.isGridViewEnable = true;
    this.listViewThumbActive = Math.random();
  }
  selectListView() {
    if ($('.slim-strip-container').hasClass('gridview-images')) {
      this.isGridViewEnable = true;
      $('.slim-strip-container').removeClass('gridview-images');
      $('.docviewer-imageframe').removeClass('gridview-images');
    }
  }

  getVolumeDetails(): void {
    this.loader.relatedVolume = true;
    this.searchResultDetailService.getVolumeDetails(this.defaultImageToBeLoaded.docId || this.documentIDFromRouteParams, res => {
      this.loader.relatedVolume = false;
      if (res) {
        if (res.hasOwnProperty('volumes')) {
          if (res['volumes']) {
            this.relatedVolumes = res['volumes'];
          }
        }
      }
    }, err => {
      this.loader.relatedVolume = false;
      this.modalService.showNotification('ERROR', this.translatedValues.sorry || 'Sorry!',
        this.translatedValuesOfDetail.unableToFetchVolumes || 'Unable to fetch Volumes');
    });
  }

  gotoDocumentById(pageId) {
    const data = {};
    this.searchService.buildFilterParams(this.pageContentData.advancedFilterParam, (param) => {
      this.searchService.buildURL(param, (url) => {
        data['filterParam'] = url;
        if (pageId) {
          data['viewPage'] = pageId;
        }
      });
    });
    this.router.navigate([this.homePageUrl + '/detail/' + pageId + ';searchTerm=' + ';filterParam' + data['filterParam']]);
  }
  getCollectionLevelMetadata(): void {
    this.collectionNotes = [];
    this.searchResultDetailService.getCollectionLevelMetadata(this.documentData.wileyId, this.documentData.collectionNumber, res => {
      this.collectionLoading = true;
      if (res) {
        this.collectionLoading = false;
        this.collectionResponse = res['notes'];
        const keys = Object.keys(this.collectionResponse).map(key => ({ type: key, value: this.collectionResponse[key] }));
        this.collectionNotes = keys;
      }
    }, err => {
      this.collectionLoading = false;
      this.modalService.showNotification('ERROR', this.translatedValues.sorry + '!' || 'Sorry!',
      this.translatedValues.unableToGetCollectionMetadata ||
      'Unable to get Collection level metadata.');
    });
  }

  getSearchResultsBasedonParams(documentType) {
    this.showExplorerCoverFlowData = !this.showExplorerCoverFlowData;
    this.toggleCoverFlow = { show: true, random: Math.random() };
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false
    });
  }

  getSearchResultDocuments(offset?) {
    if (this.coverflowImagesCount !== this.coverFlowImages.length) {
      // this.modalService.showLoader();
      this.searchResultDetailService.advancedSearch(this.searchTermCopy, this.advancedFilterParamForCoverFlow, (res) => {
        if (res.count > 0) {
          this.coverflowImagesCount = res.count;
          if (res.data.length > 0) {
            this.coverFlowImages = res.data;
          }
          // this.modalService.hideLoader();
        } else {
          // this.modalService.hideLoader();
        }
        this.coverFlowScrollToRight();
      }, err => {
        // this.modalService.hideLoader();
      }, (this.coverFlowImages.length), this.documentData.docType);
    } else {
      this.coverFlowScrollToRight();
      // this.modalService.hideLoader();
    }
  }

  coverFlowScrollToRight() {
    const scrollPosition = $('.search-results-image').scrollLeft();
    const coverFlowImageParantDivWidth = $('#coverFlow0').width();
    if ((window.innerWidth <= 1320) && (window.innerWidth >= 768)) {
      $('.search-results-image').animate({ scrollLeft: scrollPosition + (coverFlowImageParantDivWidth * 2) }, 'linear');
    } else if ((window.innerWidth <= 768)) {
      $('.search-results-image').animate({ scrollLeft: scrollPosition + (coverFlowImageParantDivWidth * 1) }, 'linear');
    } else {
      $('.search-results-image').animate({ scrollLeft: scrollPosition + (coverFlowImageParantDivWidth * 3) }, 'linear');
    }
  }

  ViewMoreClick(group?) {
    this.getSearchResultDocuments(this.coverflowIndex);
  }
  ViewLessClick() {
    const scrollPosition = $('.search-results-image').scrollLeft();
    const coverFlowImageParantDivWidth = $('#coverFlow0').width();
    if ((window.innerWidth <= 1320) && (window.innerWidth >= 768)) {
      $('.search-results-image').animate({ scrollLeft: scrollPosition - (coverFlowImageParantDivWidth * 2) }, 'linear');
    } else if ((window.innerWidth <= 768)) {
      $('.search-results-image').animate({ scrollLeft: scrollPosition - (coverFlowImageParantDivWidth * 1) }, 'linear');
    } else {
      $('.search-results-image').animate({ scrollLeft: scrollPosition - (coverFlowImageParantDivWidth * 3) }, 'linear');
    }
  }
  coverflowTrackBy(index, document) {
    return document.id;
  }
  loadDetailsPagebyCoverflowDocId(documentId) {
    this.isRefershPage = true;
    if (this.loadedFrom === 'details') {
      const data = { searchTerm: this.searchTerm || '' };
      this.searchService.buildFilterParams(this.advancedFilterParamForCoverFlow, (param) => {
        this.searchService.buildURL(param, (url) => {
          data['filterParam'] = url;
          if (documentId) {
            data['viewPage'] = documentId;
          }
          this.router.navigate([`${this.homePageUrl}/detail/${documentId}`, data]);
        });
      });
    } else {
      this.coverFlowDocumentID.emit(documentId);
    }
  }
  backToCollectionDetails() {
    const collectionPageURL = sessionStorage.getItem('collectionPageURL');
    this.router.navigateByUrl(collectionPageURL);
  }
  backToTermGroups() {
    const termGroupsPageURL = sessionStorage.getItem('termGroupsPageURL');
    this.router.navigateByUrl(termGroupsPageURL);
  }
  onScrollEvent(event: any) {
  }
  onScrollXReachEvent(event: any) {
  }

  /*
  * @dec: Manage tab selection and perform operation
  * @input: tab unique identifier
  */
  onDocViewerTabSelect(tabId): void {
    switch (tabId) {
      case 'notesTab':
        if (this.documentData.collectionNoteExist) {
          this.getCollectionLevelMetadata();
        }
        break;
      case 'relatedSubjectsTab':
        this.getRelatedSubjects();
        this.selectListView();
        this.toggleMenu = false;
        this.itemTabEnable = false;
        break;
      case 'volumesTab':
        this.getVolumeDetails();
        break;
      case 'illustrationTab':
        this.illustraionTypes(this.documentData.wileyId);
        this.toggleMenu = false;
        this.itemTabEnable = false;
        break;
      case 'itemsTab':
        this.itemsByType(this.documentData.wileyId);
        this.toggleMenu = false;
        this.itemTabEnable = true;
        break;
      case 'tocTabs':
        this.itemsInToc(this.documentData.wileyId);
        this.toggleMenu = false;
        this.itemTabEnable = false;
        break;
    }
  }

  /*
  * @DESC: hide illustration tree tab
  * */
  hideIllustrations(): void {
    /*
    * @DESC: remove illustration tab if there is no content to display
    */
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].id === 'illustrationTab') {
        this.tabs.splice(index, 1);
      }
    }
  }

  /*
  * @DESC: hide items tab
  * */
  hideItemsTab(): void {
    /*
    * @DESC: remove items tab if document type is Monographs
    */
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].id === 'itemsTab') {
        this.tabs.splice(index, 1);
      }
    }
  }

  /*
  * @DESC: hide toc tab if document type is not a Monographs
  * * @DESC: hide tabs if flag is flase in docinfo
  * */
  hideTocTab(): void {
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].id === 'tocTabs') {
        this.tabs.splice(index, 1);
      }
    }
  }
  /*
  * @DESC: activate tabs by tab id
  * @INPUT: tabId: string
  * */
  activateLeftMenuTabset(tabId: string) {
    for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
      if (this.leftMenuTabset.tabs[i].id === tabId) {
        this.leftMenuTabset.tabs[i].active = true;
      }
    }
  }

  /*
   * @DESC: hide tab based on Flags
   * @DESC: hide tabs if flag is flase in docinfo
   * */
  hideTabBasedOnFlags(): void {
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].id === 'notesTab' && !(this.documentData.docNoteExist || this.documentData.collectionNoteExist)) {
        this.tabs.splice(index, 1);
      }
      if (this.tabs[index].id === 'relatedSubjectsTab' && !(this.documentData.subjectExist)) {
        this.tabs.splice(index, 1);
      }
      if (this.tabs[index].id === 'illustrationTab' && !(this.documentData.illustrationExist)) {
        this.tabs.splice(index, 1);
      }
      if (this.tabs[index].id === 'volumesTab' && !(this.documentData.hasVolumes)) {
        this.tabs.splice(index, 1);
      }
    }
  }
  /*
  * DESC: This function will trigger only if the source and destination languages are same
  * or initial load(without destination language)
  * @INPUT: page Id
  * @result: fulltext from content (cdn url) data
  */
  callGetPageLevelFullText(pageId): void {
    if (pageId && this.documentData) {
      if (this.documentData.hasOwnProperty('wileyId')) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'text/plain',
          })
        };
        if (this.currentLanguageCode) {
          this.httpService.get(environment.APIUrl + APIURLS['fileTranslateText'] + '/' + this.documentData.wileyId + '/'
            + pageId + '?destLanguage=' + this.currentLanguageCode, response => {
              this.documentFulltext = response.data;
              // this.popupService.hideLoader();
              if (this.documentFulltext) {
                if (this.documentFulltext.trim() === '' || this.documentFulltext.trim() === null ||
                this.documentFulltext.trim() === ' <br>') {
                  this.fulltextDisable = true;
                } else {
                  this.fulltextDisable = false;
                }
              }
              if (this.currentLanguageForTranslate) {
                if (rtlLanguages.indexOf(this.currentLanguageForTranslate.toUpperCase()) > -1) {
                  $('#rtl-language').addClass('rtl-languages');
                } else {
                  $('#rtl-language').removeClass('rtl-languages');
                }
              }
            }, err => { });
        } else {
            // tslint:disable-next-line:max-line-length
          this.http.get<any>(`${environment.APIUrl}${APIURLS.downloadTextV2}${this.documentData.wileyId}/${pageId}?WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`).subscribe(res => {
              this.http.get<any>(res, httpOptions).subscribe((response) => {
                this.documentFulltext = response;
                // this.popupService.hideLoader();
                if (this.documentFulltext) {
                  // tslint:disable-next-line:max-line-length
                  if (this.documentFulltext.trim() === '' || this.documentFulltext.trim() === null || this.documentFulltext.trim() === ' <br>') {
                    this.fulltextDisable = true;
                  } else {
                    this.fulltextDisable = false;
                  }
                }
                if (this.currentLanguageForTranslate) {
                  if (rtlLanguages.indexOf(this.currentLanguageForTranslate.toUpperCase()) > -1) {
                    $('#rtl-language').addClass('rtl-languages');
                  } else {
                    $('#rtl-language').removeClass('rtl-languages');
                  }
                }
              });
            }, err => { });
        }
      }
    }
  }
  backToPhotoExplorer(spatialFilterParams) {
    const data = this.copyAdvancedFilterParam;
    data['searchTerm'] = this.searchTermFromExplorer ? this.searchTermFromExplorer : '';
    this.activatedRoute.params.subscribe(params => {
      if (params['spatialFilterParams'] && params['spatialFilterParams'] !== 'undefined') {
        data['spatialFilterParams'] = (params['spatialFilterParams']);
      }
      this.router.navigate([`${this.homePageUrl}/explorer/photo`, data]);
    });
  }
  backToMapExplorer(spatialFilterParams) {
    const data = this.copyAdvancedFilterParam;
    data['searchTerm'] = this.searchTermFromExplorer ? this.searchTermFromExplorer : '';
    this.activatedRoute.params.subscribe(params => {
      if (params['spatialFilterParams'] && params['spatialFilterParams'] !== 'undefined') {
        data['spatialFilterParams'] = (params['spatialFilterParams']);
      }
      this.router.navigate([`${this.homePageUrl}/explorer/map`, data]);
    });
  }
  checkShowWebsite() {
    this.commonService.getLocationConfig(sessionStorage.getItem('lang')).subscribe(res => {
      if (res) {
        this.locationConfigData = res['locations'];
        if (this.documentData.shortName) {
          this.showWebsites = this.locationConfigData[this.documentData.shortName].websites;
        }
      }
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onEscapeKeydownHandler(event: KeyboardEvent) {
    this.isBookmarkPopupOpen = false;
  }
  getSearchResultsFromPhotoExplorer() {
    this.showExplorerCoverFlowData = !this.showExplorerCoverFlowData;
    this.toggleCoverFlow = { show: true, random: Math.random() };
  }
  getSearchResultsFromMapExplorer() {
    this.showExplorerCoverFlowData = !this.showExplorerCoverFlowData;
    this.toggleCoverFlow = { show: true, random: Math.random() };
  }

  closeCoverflow(event) {
    this.showExplorerCoverFlowData = false;
  }
  backToCollection() {
    const termGroupsPageURL = sessionStorage.getItem('collectionPageURL');
    if (termGroupsPageURL) {
      this.router.navigateByUrl(termGroupsPageURL);
    }
  }

  getShiftOptionValue(event) {
    if (event) {
      if (event.status === true) {
        this.getoverlayOption = {status : true , random: Math.random()};
      }  else {
        this.getoverlayOption = {status : false , random: Math.random()};
      }
    }
  }
  newOverlayFloatingVal(event) {
    this.passNewOverlayValue = event;
  }
  expandAllTree() {
    setTimeout(() => {
       this.tree.treeModel.expandAll();
    }, 500);
  }

  callGetDocumentImages(docId) {
    /*
    * @DESC: get document id image ids from service
    * @reutrn: Array of image ids
    */
   this.httpService.get(environment.APIUrl + APIURLS['getDocumentImagesById'] + '/' + docId, (res) => {
    this.documentImagesWithTitles = res;
    if (res) {
      if (this.defaultImageToBeLoaded.docId) {
        this.callGetRelevanePages(this.defaultImageToBeLoaded.docId, this._searchTerm);
      } else {
        this.callGetRelevanePages(this.documentIDFromRouteParams || this.documentData['wileyId'],
          this._searchTerm);
      }
    }
  }, (error) => {
  });
  }

  getDocumentDetails (documentId) {
    this.searchService.getDocumentDetail(documentId, (response) => {
      if (response) {
        this.contentType = response.docType;
        this.documentData = response;
        this.isRefershPage = false;
        this.tabs = [];
        (this.documentData.docType === 'Monographs') ? this.hideItemsTab() : this.hideTocTab();
        if (this.documentData.docType === 'Monographs') {
          this.tabs.push({ id: 'tocTabs', customClass: 'tocIcon-tabs' });
        } else {
          this.tabs.push({ id: 'itemsTab', customClass: 'itemsIcon-tabs' });
        }
        if (this.documentData.docNoteExist || this.documentData.collectionNoteExist) {
          this.tabs.push({ id: 'notesTab', customClass: 'notesIcon-tabs' });
        }
        if (this.documentData.illustrationExist) {
          this.tabs.push({ id: 'illustrationTab', customClass: 'illustrationIcon-tabs' });
        }
        if (this.documentData.subjectExist) {
          this.tabs.push({ id: 'relatedSubjectsTab', customClass: 'relatedsubIcon-tabs' });
        }
        if (this.documentData.hasVolumes) {
          this.tabs.push({ id: 'volumesTab', customClass: 'relatedsubIcon-tabs' });
        }
        if (this.documentData['spatialFilterParams']) {
          this.spatialFilterParams = this.documentData['spatialFilterParams'];
        }
      }
      if (this.documentData) {
        /**
         * register in document history
         */
        this.setDetailsHistory(this.documentData['wileyId'], this.documentData['title']);
        this.checkShowWebsite();
      }
    });
  }
  getRotationValue(value) {
    this.currentAngleValue = value.rotateValue;
  }
  getRotationDirection() {
    $('#openSeaDragonCanvas').removeClass();
    if (this.currentAngleValue === 1 || this.currentAngleValue === -3) {
      $('#openSeaDragonCanvas').addClass('rotateFirst');
    } else if (this.currentAngleValue === 2 || this.currentAngleValue === -2) {
      $('#openSeaDragonCanvas').addClass('rotateSecond');
    } else if (this.currentAngleValue === 3  || this.currentAngleValue === -1) {
      $('#openSeaDragonCanvas').addClass('rotateThird');
    }  else if (this.currentAngleValue === -0 || this.currentAngleValue === 0) {
      $('#openSeaDragonCanvas').addClass('rotatezero');
    }
  }

  backToFrequencyDistribution() {
    const url = sessionStorage.getItem('fDPageURL');
    this.router.navigateByUrl(url);
  }
  backToConcordance() {
    const url = sessionStorage.getItem('concordancePageURL');
    this.router.navigateByUrl(url);
  }
}
interface ShareMail {
  topic?: String;
  message?: String;
  to?: String;
  from?: String;
}
