
import { takeUntil } from 'rxjs/operators';
import {
  Component, Input, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked, OnDestroy
} from '@angular/core';
import { HttpService } from '../common/services/http.service';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
// import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
declare let $: any;
@Component({
  selector: 'app-flim-strip',
  templateUrl: './flim-strip.component.html',
  styleUrls: ['./flim-strip.component.css']
})
export class FlimStripComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  isEvenclass = false;
  showMapsContent = false;
  currentActiveIndex: any;
  // @ViewChild('container', { read: ElementRef }) private flimStripContainer: ElementRef;
  @ViewChild('container', { static: false, read: ElementRef }) private flimStripContainer: ElementRef;

  // newVal = {status: false, random: Math.random()};
  newVal;
  // public config: PerfectScrollbarConfigInterface = {};

  // @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  // @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  // @ViewChild('PerfectScrollbarComponent', { static: false }) private componentRef?: PerfectScrollbarComponent;
  // @ViewChild('PerfectScrollbarDirective', { static: false }) private directiveRef?: PerfectScrollbarDirective;

  illustrationsOverlays: any = [];
  // scrollTarget: HTMLElement;
  documentImages: any = [];
  defaultImage = '/assets/images/loader.gif';
  errorImage = '/assets/images/noimage.jpg';
  images: any = [];
  documentImagesIds = [];
  imageTitle: any;
  accessToken: any;
  @Input()
  set passNewOverlayValue(val: any) {
    this.newVal = val;
  }

  @Input('documentTitleImages') documentTitleImages: any;
  @Input('documentImages')
  public set documentimage(val: any) {
    if (val) {
      this.documentImages = val;
      this.currentActiveIndex = val.sequenceNumber;
    }
  }

  public get documentimage(): any {
    return this.documentImages;
  }


  @Input('gridViewThumbActive')
  set gridViewThumbActive(val: string) {
    if (val) {
      // this.renderer.setStyle(this.flimStripContainer.nativeElement, 'max-height', '100vh');
      // setTimeout(() => {
      //   this.scrollTarget = document.getElementById('thumbnailImageWrapper');
      // }, 1000);
    }
  }

  @Input('listViewThumbActive')
  set listViewThumbActive(val: string) {
    if (val) {
      // this.renderer.setStyle(this.flimStripContainer.nativeElement, 'max-height', '60vh');
      // setTimeout(() => {
      //   this.scrollTarget = document.getElementById('thumbnailImageWrapper');
      // }, 1500);
    }
  }

  @Input('scrollTo')
  set scrollToPosition(scrollTo: any) {
    if (scrollTo) {
      // this.componentRef.directiveRef.scrollTo(0, scrollTo.val, 500);
    }
  }
  @Input('currentActiveIndex')
  set _currentActiveIndex(val) {
    this.currentActiveIndex = val;
    if (document.getElementById('thumb' + this.currentActiveIndex)) {
      setTimeout(() => {
        if (document.getElementById('thumb' + this.currentActiveIndex)) {
          const topPos = document.getElementById('thumb' + this.currentActiveIndex).offsetTop;
          // this.componentRef.directiveRef.scrollTo(0, topPos, 1000);
        }
      }, 1000);
    }
  }

  @Output('currentActiveImageIndex') currentActiveImageIndex: EventEmitter<any> = new EventEmitter();
  @Output('checkShiftOption') checkShiftOption: EventEmitter<any> = new EventEmitter();
  constructor(
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngAfterViewInit() {
    // this.scrollTarget = document.getElementById('thumbnailImageWrapper');
    this.accessToken = this.httpService.getCurrentAccessToken();
    // this.processImages();
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(param => {
      if (param['pageId']) {
        setTimeout(() => {
          if (document.getElementById('thumb' + this.currentActiveIndex)) {
            const topPos = document.getElementById('thumb' + this.currentActiveIndex).offsetTop;
            document.getElementById('thumb' + this.currentActiveIndex).scrollIntoView();
            document.getElementById('thumbnailImageWrapper').scrollTop = topPos - 10;
            // this.searchResultDetailService.scrollTo(document.getElementById('thumbnailImageWrapper'), topPos - 10, 600);
            // this.componentRef.directiveRef.scrollTo(0, topPos, 1000);
          }
        }, 2000);
      }

    });

  }

  ngAfterViewChecked() {
    // const perfectScrollbar = new PerfectScrollbar('#container', {
    //   wheelSpeed: 2,
    //   wheelPropagation: true,
    //   minScrollbarLength: 20
    // });
  }
  callImageViewer(image, index) {
    // this.sharedService.filmstripImageList(image);
    this.currentActiveImageIndex.emit(image);
    this.currentActiveIndex = image.sequenceNumber;
    // this.checkShiftOption.emit(this.newVal);
    // this.searchService.getIllustrationCoordinates(image.imageURL.split('/')[5], image.imageId, 'Filmstrip');
  }
  preMaridienMapsEnter(map, index) {
    if (map.preMapExists) {
      if (index % 2 === 0) {
        this.isEvenclass = false;
      } else {
        this.isEvenclass = true;
      }
      $('#thumbnailImageWrapper').addClass('premariden-class');
      this.documentImages.forEach((element, i) => {
        if (index === i) {
          element.preMaridenMaps = true;
        }
      });
    }
  }
  preMaridienMapsLeave(map, index) {
    if (map.preMapExists) {
      if (index % 2 === 0) {
        this.isEvenclass = false;
      } else {
        this.isEvenclass = true;
      }
      $('#thumbnailImageWrapper').removeClass('premariden-class');
      this.documentImages.forEach((element, i) => {
        if (index === i) {
          element.preMaridenMaps = false;
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.documentImages = [];
    this.stop$.next();
    this.stop$.complete();
  }
}
