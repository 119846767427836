<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->
<div class="col-xs-12 detailspage-parent">
  <div class="pageheader doc-pageheader row">
    <div class="col-md-12 padding-l-0">
      <div class="float-left pagetitle-left  col-xs-12">
        <h1 *ngIf="documentData?.title" title="{{documentData?.title}}">{{documentData?.title}}</h1>
      </div>
    </div>
  </div>
  <div class="pageheader doc-pageheader row">
    <div class="col-md-5 col-sm-6 docs-des">
      <p *ngIf="documentData?.description" class=" small result-details-p" [innerHtml]="documentData?.description"></p>
    </div>
    <div class="page-content-div col-md-7 col-sm-6" [class.image-viewer-nav-menu]="!isFullTextActive">
      <div class="page-content-right text-right pull-right" *ngIf="this.documentData?.wileyId">
        <ul class="page-contentul padding-0">

          <li (click)="openImageHelp($event);" class="helpnemu-list dropdown">
            <span [class.arabic-style]="isArabicLanguage" class="help-btn" title="{{ 'HomePage.help' | translate }}">
              <i class="spriteico helpIcon"></i>
            </span>

            <div class="image-help-container dropdown-menu show-help" id="help_box" role="menu" [ngClass]="{'fullScreenHelpText': isInFullScreenMode}"
              (click)="$event.stopPropagation();" [class.display-help]="showHelp">
              <label [class.arabic-style]="isArabicLanguage" class="help-title">
                {{ 'Detail.useMouseWheel' | translate }}
              </label>
              <ul [class.arabic-style]="isArabicLanguage">
                <li [class.arabic-style]="isArabicLanguage">{{ 'Detail.WithintheThumbnailStrip' | translate }}</li>
                <li [class.arabic-style]="isArabicLanguage">{{ 'Detail.wihtintheDocument' | translate }}</li>
                <li [class.arabic-style]="isArabicLanguage">{{ 'Detail.withintheGrayArea' | translate }}</li>
              </ul>
              <label [class.arabic-style]="isArabicLanguage" class="help-title">
                {{ 'Detail.keyboardShortcuts' | translate }}
              </label>
              <ul [class.arabic-style]="isArabicLanguage">
                <li [class.arabic-style]="isArabicLanguage">{{ 'Detail.keyboardPageupWindows' | translate }}</li>
                <li [class.arabic-style]="isArabicLanguage">{{ 'Detail.keyboardPageupMac' | translate }}</li>
              </ul>
            </div>
          </li>
          <li class="menu-list dropdown" (click)="socialShareToggleList($event)">
            <a [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.Share' | translate }} ({{shiftContrlKey}} + Y)"
              href="javascript:void(0)">
              <span class="spriteico shareIcon dropdown-toggle"></span>
            </a>
            <ul [class.arabic-style]="isArabicLanguage" *ngIf="isSessionGuest != 'guest' && shareUrl" class="dropdown-menu shareWidth"
              role="menu" [ngStyle]="{'display': toggleviewlist ? 'block': 'none'}" (click)="$event.stopPropagation();"
              id="shareIconsWidth">
              <li style="display: inline-flex;">
                <div class="mailBtn m-r-4">
                  <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon" (click)="shareMailClick()"
                    title="{{ 'Detail.shareByMail' | translate }}">
                    <i class="fa fa-envelope"></i>
                    <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.mailIcon' | translate }}</span>
                  </button>
                </div>
                <div class="mailBtn m-r-2">
                  <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon share-bg" (click)="copyShareUrl(shareUrl)"
                    title="{{ 'Detail.copytoClipboard' | translate }}">
                    <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
                    <span [class.arabic-style]="isArabicLanguage" class="sr-only">Copy</span>
                  </button>
                </div>
                <div class="addthis_inline_share_toolbox"></div>
              </li>
            </ul>

            <ul [class.arabic-style]="isArabicLanguage" *ngIf="isGuestUser && shareUrl" class="dropdown-menu shareWidth" role="menu"
              [ngStyle]="{'display': toggleviewlist ? 'block': 'none'}" (click)="$event.stopPropagation();">
              <li style="display: inline-flex;">
                <div [class.arabic-style]="isArabicLanguage" class="mailBtn m-r-4">
                  <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon" (click)="shareMailClick()"
                    title="{{ 'Detail.shareByMail' | translate }}">
                    <i class="fa fa-envelope"></i>
                    <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.mailIcon' | translate }}</span>
                  </button>
                </div>
                <div class="mailBtn m-r-2">
                  <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon share-bg" (click)="copyShareUrl(shareUrl)"
                    title="{{ 'Detail.copytoClipboard' | translate }}">
                    <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
                    <span [class.arabic-style]="isArabicLanguage" class="sr-only"> {{'Common.Copy' | translate}} </span>
                  </button>
                </div>
                <div class="addthis_inline_share_toolbox"></div>
              </li>
            </ul>
          </li>
          <li (click)="citationClick()">
            <a title="{{ 'Detail.citationTools' | translate }} ({{controlShortcut}} + O)" href="javascript:void(0)">
              <span class="spriteico citationIcon"></span>
            </a>
          </li>
          <li *ngIf="isSessionGuest != 'guest'" (click)="bookmarkPageClick()">
            <a title="{{ 'SearchResult.Bookmark' | translate }} ({{shortcutKeyTitle}} + K)" href="javascript:void(0)">
              <span class="spriteico addbookmarkIcon"></span>
            </a>
          </li>

          <ng-container *ngIf="isSessionGuest != 'guest'">
            <li *ngIf="viewList[documentData?.docType] ? getJSONKeys(viewList[documentData?.docType])?.indexOf(documentData?.wileyId) > -1 : false"
              (click)="listClick(documentData)">
              <a title="{{ 'SearchResult.RemoveFromList' | translate }}" href="javascript:void(0)">
                <span class="spriteico removelistIcon"></span>
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="isSessionGuest != 'guest'">
            <li *ngIf="viewList[documentData?.docType] ? getJSONKeys(viewList[documentData?.docType])?.indexOf(documentData?.wileyId) == -1 : true"
              (click)="listClick(documentData)">
              <a title="{{ 'SearchResult.AddToList' | translate }} ({{controlShortcut}} + L)" href="javascript:void(0)">
                <span class="spriteico addlistIcon"></span>
              </a>
            </li>
          </ng-container>
          <li class="backToSearchResults">
<ng-container *ngIf="!isGuestUser && !isFromResultPage && isMainImageLoaded  && !isFromTermGroupsPage && !isFromMapExplorerPage && !isFromPhotoExplorerPage && !isFromCollection && !isFromFrequencyDistribution && !isFromConcordance">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a href="javascript:void(0)" *ngIf="!hasDocumentAccess" class="backto-homelink" (click)="gotoHome()"
                title="{{ 'Detail.backToSearchPage' | translate }}">{{ 'Detail.backToSearchPage' | translate }}
              </a>
              <!-- <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" *ngIf="hasDocumentAccess && isFromResultPage" class="backto-homelink"
                (click)="backToSearchResultsPage(!isRefershPage)" title="{{ 'Common.BackToSearchResults' |
              translate }} ({{controlShortcut}} + Z)">
                {{ 'Common.BackToSearchResults' | translate }}
              </a> -->
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" *ngIf="hasDocumentAccess && !isFromResultPage"
                class="backto-homelink" (click)="backToSearchResults()" title="{{ 'Common.BackToSearchResults' | translate }} ({{controlShortcut}} + Z)">
                {{ 'Common.BackToSearchResults' | translate }}
              </a>
            </ng-container>

<ng-container *ngIf="!isGuestUser && isFromResultPage && isMainImageLoaded  && !isFromTermGroupsPage && !isFromMapExplorerPage && !isFromPhotoExplorerPage && !isFromCollection && !isFromFrequencyDistribution">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink" (click)="backToSearchResultsPage(!isRefershPage)"
                title="{{ 'Common.BackToSearchResults' |
                translate }} ({{controlShortcut}} + Z)">
                {{ 'Common.BackToSearchResults' | translate }}
              </a>
            </ng-container>
            <ng-container *ngIf="isFromTermGroupsPage && !isGuestUser && !isFromMapExplorerPage && !isFromPhotoExplorerPage && !isFromCollection">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink" (click)="backToTermGroups()"
                title="{{ 'SearchResult.backtoTermGroups' | translate }}">{{ 'SearchResult.backtoTermGroups' |
                translate }}
              </a>
            </ng-container>
            <ng-container *ngIf="!isFromTermGroupsPage && !isGuestUser && !isFromMapExplorerPage && isFromPhotoExplorerPage && !isFromCollection">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink" (click)="backToPhotoExplorer(spatialFilterParams)"
                title="{{ 'SearchResult.backToPhotoExplorer' | translate }}">{{ 'SearchResult.backToPhotoExplorer' |
                translate }}
              </a>
            </ng-container>
            <ng-container *ngIf="!isFromCollection && !isFromTermGroupsPage && !isGuestUser && isFromMapExplorerPage && !isFromPhotoExplorerPage">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink" (click)="backToMapExplorer(spatialFilterParams)"
                title="{{ 'SearchResult.backToMapExplorer' | translate }}">{{ 'SearchResult.backToMapExplorer' |
                translate }}
              </a>
            </ng-container>

            <ng-container *ngIf="isFromCollection && !isFromTermGroupsPage && !isGuestUser && !isFromMapExplorerPage && !isFromPhotoExplorerPage">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                (click)="backToCollection()"
                title="{{ 'SearchResult.backtoCollections' | translate }}">{{ 'SearchResult.backtoCollections' |
                translate }}
              </a>
            </ng-container>
            <ng-container *ngIf="isFromFrequencyDistribution && !isGuestUser">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink" (click)="backToFrequencyDistribution()"
                title="{{ 'SearchResult.backToFrequencyDistribution' | translate }}">{{
                'SearchResult.backToFrequencyDistribution' | translate }}
              </a>
            </ng-container>
            <ng-container *ngIf="isFromConcordance && !isGuestUser">
              <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink" (click)="backToConcordance()"
                title="{{ 'SearchResult.backToConcordance' | translate }}">{{
                'SearchResult.backToConcordance' | translate }}
              </a>
            </ng-container>
          </li>
          <ng-container *ngIf="!isGuestUser && isMainImageLoaded && (startValueFromParams || loadedFrom === 'wrapper') && !guestAuthToken">
            <li *ngIf="isFromResultPage && !isFromCollection && !isFromTermGroupsPage  && !isFromMapExplorerPage && !isFromPhotoExplorerPage">
              <span (click)="getSearchResultsBasedonParams(documentData?.docType);$event.stopPropagation();">
                <span class="spriteico backtoSearchIcon" title="{{ 'SearchResult.SearchResults' | translate }} ({{shortcutKeyTitle}} + Down Arrow)"></span>
              </span>
            </li>
            <li *ngIf="!isFromResultPage  && !isFromTermGroupsPage && !isFromMapExplorerPage && !isFromPhotoExplorerPage && !isFromCollection">
              <span (click)="getSearchResultsBasedonParams(documentData?.docType);$event.stopPropagation();">
                <span class="spriteico backtoSearchIcon" title="{{ 'SearchResult.SearchResults' | translate }} ({{shortcutKeyTitle}} + Down Arrow)"></span>
              </span>
            </li>
            <li *ngIf="isFromPhotoExplorerPage && !isFromResultPage && !isFromTermGroupsPage  && !isFromMapExplorerPage && !isFromCollection">
              <span (click)="getSearchResultsFromPhotoExplorer();$event.stopPropagation();">
                <span class="spriteico backtoSearchIcon" title="{{ 'SearchResult.SearchResults' | translate }}"></span>
              </span>
            </li>
            <li *ngIf="isFromMapExplorerPage && !isFromResultPage && !isFromTermGroupsPage  && !isFromPhotoExplorerPage && !isFromCollection">
              <span (click)="getSearchResultsFromMapExplorer();$event.stopPropagation();">
                <span class="spriteico backtoSearchIcon" title="{{ 'SearchResult.SearchResults' | translate }}"></span>
              </span>
            </li>
          </ng-container>

        </ul>
      </div>
    </div>
  </div>


  <div class="doc-viewer-container">
    <div class="slim-strip-container" [class.toggled]="toggleMenu" [class.gridview-images]="!isGridViewEnable">

      <div class="col-xs-12 padding-r0 toggle-left-menu">
        <span *ngIf="toggleMenu" title="{{ 'Detail.expandLeftMenu' | translate }} ({{shortcutKeyTitle}} + Z)" class="toggle-icon"
          [class.toggled]="toggleMenu" (click)="menuToggle();">
          <i class="fa-2x fa fa-angle-right" aria-hidden="true"></i>
        </span>

        <span *ngIf="!toggleMenu" title="{{ 'Detail.collapseLeftMenu' | translate }} ({{controlShortcut}} + E)" class="toggle-icon"
          [class.toggled]="toggleMenu" (click)="menuToggle();">
          <i class="fa-2x fa fa-angle-left" aria-hidden="true"></i>
        </span>
        <tabset #leftMenuTabset id="leftMenuTab" [vertical]="true" type="pills" [class.toggled-menu-list]="toggleMenu">

          <tab (selectTab)="selectPagesTab();toggleMenu = false; itemTabEnable = false;" [customClass]="'doc-tabs'"
            heading="" *ngIf="true" id="resultViewTab">
            <!-- (select)="selectCurrentThumbnail()" -->

            <ng-template tabHeading>
              <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.resultsView' | translate }} ({{shortcutKeyTitle}} + '/')">
                <span class="spriteico documentIcon"></span>
                <p class="tabicon-title"> {{ 'Detail.resultsView' | translate }} </p>
              </label>
              <!-- <span class="spriteico documentIcon" title="{{ 'Detail.resultsView' | translate }} ({{shortcutKeyTitle}} + '/')"></span>
              <p class="tabicon-title"> {{ 'Detail.resultsView' | translate }} </p> -->
            </ng-template>
            <div class="tab-pane active" [class.toggled]="toggleMenu">
              <div class="col-md-12 document-tab-view toggle-container result-view">
                <div class="document-view document-parent">
                  <h4 [class.arabic-style]="isArabicLanguage" class="pages-title socure-citation-linkbtn ellpisis-for-title"
                    title="{{ 'Detail.resultsView' | translate }}">{{ 'Detail.resultsView' | translate }}</h4>
                  <div class="doc-right">
                    <div class="btn-group" *ngIf="!showDocSwitch">
                      <label [class.arabic-style]="isArabicLanguage" class="btn doc-fultext-btn ellpisis-for-label"
                        title="{{ 'Detail.showImageTooltip' | translate }} ({{controlShortcut}} + K)" [class.activeTab]="documentViewer"
                        btnCheckbox tabindex="0" (click)="changetoFullText(checkModel)" role="button">{{
                        'Detail.showImage' | translate }}</label>
                      <label [class.arabic-style]="isArabicLanguage" *ngIf="!fulltextDisable" class="btn doc-fultext-btn doc-fulltext"
                        title="{{ 'Detail.showFullTextTooltip' | translate }} ({{controlShortcut}} + P)"
                        [class.activeTab]="!documentViewer" btnCheckbox tabindex="1" (click)="doThisOnSelect(checkModel)"
                        role="button">{{ 'Detail.shortFullText' | translate }}</label>

                      <label [class.arabic-style]="isArabicLanguage" *ngIf="fulltextDisable" class="btn doc-fultext-btn doc-fulltext text-disable ellpisis-for-label"
                        title="{{ 'Detail.noFullText' | translate }} ({{controlShortcut}} + P)" [class.activeTab]="!documentViewer"
                        btnCheckbox tabindex="1" role="button">{{ 'Detail.shortFullText' | translate }}</label>
                    </div>
                    <div class="btn-group" *ngIf="showDocSwitch">
                      <label [class.arabic-style]="isArabicLanguage" class="btn doc-fultext-btn ellpisis-for-label"
                        [class.activeTab]="documentViewer" btnCheckbox tabindex="0" (click)="changetoFullText(checkModel)"
                        role="button">{{ 'Detail.shortDocument' | translate }}</label>
                      <label [class.arabic-style]="isArabicLanguage" class="btn doc-fultext-btn doc-fulltext ellpisis-for-label"
                        [class.activeTab]="!documentViewer" btnCheckbox tabindex="1" (click)="doThisOnSelect(checkModel)"
                        role="button">{{ 'Detail.shortFullText' | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 document-tab-view">
                <div class="document-view-pagination">
                  <ul class="document-view-pagination-list">
                    <li title="{{ 'Detail.perviousPage' | translate }}">
                      <span (click)="gotoPreviouesDocumentFullText(selectedPageId)" *ngIf="!documentCurrentIndex || documentCurrentIndex > 1">
                        <i class="fa fa-chevron-left document-larrow"></i>
                      </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage" class="page-section" title="{{ 'Detail.page' | translate }}">
                      {{ 'Detail.page' | translate }}</li>
                    <li>
                      <input type="text" class="pagenum-input" (keypress)="keyPress($event)" [ngModel]="documentCurrentIndex"
                        (keyup.enter)="gotoCurrentDocumentFulltext($event)" />
                    </li>

                    <li [class.arabic-style]="isArabicLanguage" class="page-section page-of" title="{{ 'Detail.OF' | translate }}">
                      {{ 'Detail.OF' | translate }} </li>
                    <li [class.arabic-style]="isArabicLanguage" class="page-section total-pages" title="{{ documentImages.length }}">
                      {{documentImages.length}}</li>
                    <li *ngIf="documentImages.length != documentCurrentIndex" (click)="gotoNextDocumentFullText(selectedPageId)"
                      title="{{ 'Detail.nextPage' | translate }}">
                      <i class="fa fa-chevron-right document-rarrow"></i>
                    </li>
                  </ul>
                  <span class="gridview-thumbnails" (click)="girdViewThumBImages()">
                    <span *ngIf="isGridViewEnable && documentImages.length > 15" title="{{ 'Detail.expandToRight' | translate }} ({{shortcutKeyTitle}} + G)"
                      class="spriteico expandToRightIcon grid-thumbnails">
                    </span>
                    <!-- <i *ngIf="isGridViewEnable" title="{{ 'Detail.expandToRight' | translate }}" class="fa fa-th grid-thumbnails" aria-hidden="true"></i> -->
                  </span>
                  <span class="gridview-thumbnails" (click)="listViewThumBImages()">
                    <span *ngIf="!isGridViewEnable" title="{{ 'Detail.contractToLeft' | translate }} ({{shortcutKeyTitle}} + G)"
                      class="spriteico contractToRightIcon grid-thumbnails">
                    </span>
                    <!-- <i *ngIf="!isGridViewEnable" title="{{ 'Detail.contractToLeft' | translate }}" class="fa fa-list grid-thumbnails" aria-hidden="true"></i> -->
                  </span>
                </div>
              </div>

              <div [class.arabic-style]="isArabicLanguage" *ngIf="pageFullTextPageIds?.length > 0" class="filter-by-text">
                <a href="javascript:void(0)" class="toggle-filter" (click)="toggleFilter()">
                  <span [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.showAllPages' | translate }}" *ngIf="showFillterByRelevantPage">
                    {{ 'Detail.showAllPages' | translate }}
                  </span>
                  <span [class.arabic-style]="isArabicLanguage" title=" {{ 'Detail.relevantPages' | translate }}" *ngIf="!showFillterByRelevantPage">
                    {{ 'Detail.relevantPages' | translate }}
                  </span>
                </a>
              </div>
              <div *ngIf="showFillterByRelevantPage" class="filter-relevantpages-div">
                <div class="relevant-pages-div margin-0">
                <!-- <perfect-scrollbar [config]="config" class="relevant-pages-div margin-0"> -->
                  <ng-container *ngFor="let image of documentPageImages; let i = index;">
                    <div *ngIf="image" (click)="loadRelaventPage(image.imageId, $event)" class="image-container relevant-image-container-div"
                      [ngClass]="{'active': documentCurrentIndex === documentPageImages[i]?.pageNumber}">
                      <ng-container>
                        <img name='Document-image' [title]="image?.title" class="relevant-image-container center-block"
                          alt="Document Image" src="{{image?.thumbURL + '?type=small&access_token=' + accessToken}}">
                      </ng-container>
                      <div [class.arabic-style]="isArabicLanguage" class="relevant-sequence-number">{{documentPageImages[i]?.pageNumber}}</div>
                    </div>
                  </ng-container>
                <!-- </perfect-scrollbar> -->
              </div>
              </div>

              <div *ngIf="!showFillterByRelevantPage" class="relevantpages-div">
                <div [class.arabic-style]="isArabicLanguage" *ngIf="!isMainImageLoaded">
                  <p [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.loadingPleaseWait' | translate }}</p>
                </div>
                <!-- <app-flim-strip [documentImages]="documentImages" [currentDocIndex]="documentCurrentIndex"
                  [documentTitleImages]="documentImagesWithTitles" (currentActiveImageIndex)="setCurrentActiveImageIndex($event)"
                  [gridViewThumbActive]="gridViewThumbActive" [listViewThumbActive]="listViewThumbActive" [scrollTo]="scrollToPosition"
                  (checkShiftOption)="getShiftOptionValue($event)" [passNewOverlayValue]="passNewOverlayValue"></app-flim-strip> -->
              </div>
            </div>
          </tab>
          <tab (selectTab)="selectListView(); toggleMenu = false; itemTabEnable = false;" [customClass]="'citation-tabs'"
            heading="" *ngIf="true" id="citationTab">
            <ng-template tabHeading>
              <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.Citation' | translate }} ({{shortcutKeyTitle}} + J)">
                <span class="spriteico citationIcon"></span>
                <p [class.arabic-style]="isArabicLanguage" class="tabicon-title"> {{ 'Detail.Citation' | translate }}
                </p>
              </label>
            </ng-template>

            <div class="tab-pane" [class.toggled]="toggleMenu">
              <div>
                <div href="javascript:void(0)">
                  <h4 title="{{ 'Detail.Citation' | translate }}" [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading pull-left socure-citation-linkbtn">{{
                    'Detail.Citation' | translate }}</h4>
                  <button title="{{ 'Detail.sourceCitation' | translate }}" [class.arabic-style]="isArabicLanguage"
                    class="btn btn-primary source-citation-btn pull-right" (click)="openSoureceCitation()">
                    {{ 'Detail.sourceCitation' | translate }} </button>
                </div>

                <div class="col-xs-12 pad-r-0">
                  <div class="document-tab-view" #container>
                  <!-- <perfect-scrollbar [config]="config" class="document-tab-view" #container> -->
                    <div class="col-md-12 col-sm-12 padding-left-0 padding-top15">
                      <div class="">
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            'Detail.documentTitle' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.title}}</h4>
                        </div>
                        <div *ngIf="(documentData?.authors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            'Detail.Authors' | translate }}</label>
                          <div class="author-containor">
                            <ng-container *ngFor="let author of documentData?.authors; let last = last;">
                              <ng-container *ngIf="isSessionGuest != 'guest'">
                                <a class="citation-content author-link font_18" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                                  <span class="author-comma" *ngIf="!last">, </span>
                                </a>
                              </ng-container>
                              <ng-container *ngIf="isSessionGuest === 'guest'">
                                <span title="{{author}}" class="citation-content m-r-5 font_18">{{author}}
                                  <span class="author-comma" *ngIf="!last">, </span>
                                </span>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.pubDate !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            'HomePage.publicationDate' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.pubDate}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            'Detail.Language' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.languages}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.collection !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            'Detail.Collection' | translate }} </label>
                          <a class="citation-content collection-link font_18" href="javascript:void(0)" (click)="gotoCollectionDetails(documentData?.collectionNumber)">
                            {{documentData?.collection}}
                          </a>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                          'Common.ContentType' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.docType}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.barcode">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                          'Detail.barcode' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.barcode}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.manuscriptNo">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                          'Detail.referenceNo' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.manuscriptNo}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Source'
                            | translate }}</label>
                          <h4 class="citation-content">{{documentData?.source}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.findingAidNumber !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl"> {{
                            'Detail.findingAidNumber' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.findingAidNumber}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.findingAidNumber !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            'Detail.wileyDocumentNumber' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.wileyId}}</h4>
                        </div>
                        <ng-container *ngIf="documentData?.placeOfWriting">
                          <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.placeOfWriting?.length > 0">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                              'Detail.placeOfWriting' | translate }}</label>
                            <h4 class="citation-content">{{documentData?.placeOfWriting?.toString()?.replace(',', ',
                              ')}}</h4>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  <!-- </perfect-scrollbar> -->
                </div>
                </div>

              </div>
            </div>

          </tab>

          <tab *ngIf="true" (selectTab)="selectListView(); toggleMenu = false; itemTabEnable = false;" [customClass]="'searchWithinIcon-tabs'"
            heading="" id="SearchWithinDocTab">
            <ng-template tabHeading>
              <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.SearchWithinDoc' | translate }} ({{shortcutKeyTitle}} + S)">
                <span class="spriteico searchWithinIcon"></span>
                <p [class.arabic-style]="isArabicLanguage" class="tabicon-title"> {{ 'Detail.searchHeading' | translate
                  }} </p>
              </label>
            </ng-template>
            <div class="tab-pane" [class.toggled]="toggleMenu">
              <div>
                <div href="javascript:void(0)" title="{{ 'Detail.SearchWithinDoc' | translate }}">
                  <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading">{{
                    'Detail.SearchWithinDoc' | translate }}</h4>
                </div>
                <div class="col-md-12 doc-tab-view">
                  <div class="col-lg-12 padding-0">
                    <div class="input-group">
                      <label [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.EnterSearchKeyword' |
                        translate }}</label>
                      <input [class.arabic-style]="isArabicLanguage" #documentSearch type="text" class="form-control"
                        id="document_search" [(ngModel)]="keyword" title="{{ 'Detail.EnterSearchKeyword' | translate }} ({{shortcutKeyTitle}} + W) "
                        autofocus placeholder="{{ 'Detail.EnterSearchKeyword' | translate }}" maxlength="100"
                        autocomplete="off" (keyup.enter)="searchWithin(keyword)">
                      <span class="input-group-btn">
                        <button class="btn btn-primary" [class.disabled]="!keyword" type="button" (click)="searchWithin(keyword)">
                          <i class="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 col-xs-12 no-padding">
                    <div>
                      <!-- *ngIf="enableFuzzySearch" -->
                      <label class="wiley-admin-text check-container" *ngIf="isSearchTermIncludesPhrase || (documentSearchTermSplitted.length > 0) || initialSearchTerm">
                        <input type="checkbox" class="disable-label" [ngModel]="toggleSearch" (change)="togglefuzzySearch()"
                          name="active">
                        <span [class.arabic-style]="isArabicLanguage" class="fuzzy-text">{{ 'Detail.ExactMatch' |
                          translate }}</span>
                        <span class="checkmark"></span>
                      </label>
                      <div class="document-search-terms">
                        <label [class.arabic-style]="isArabicLanguage" *ngIf="initialSearchTerm || searchTermwithSpaces || documentSearchTermSplitted?.length > 0"
                          class="search-term-lbl">
                          {{ 'Detail.searchTerm' | translate }}

                        </label>
                        <div class="search-term">
                          <div class="search-term-txt" #container>
                          <!-- <perfect-scrollbar [config]="config" class="search-term-txt" #container> -->
                            <ng-container *ngIf="isSearchTermIncludesPhrase && splitedSearchTerms === '' && initialSearchTerm && documentSearchTermSplitted?.length === 0">
                              <span class="doc-search-term-label">
                                <label class="wiley-admin-text check-container disabled" title="{{initialSearchTerm?.replace(',','')}}">
                                  <input type="checkbox" class="disable-label" disabled [ngModel]="isTermChecked"
                                    (change)="getRelevantPagesBySearchTerm($event)" name="relevantterm" checked>
                                  <span [class.arabic-style]="isArabicLanguage" class="fuzzy-text doc-search-term">{{initialSearchTerm?.replace(',','')}}</span>
                                  <span class="checkmark"></span>
                                </label>
                              </span>
                            </ng-container>
                            <ng-container *ngIf="isSearchTermIncludesPhrase && documentSearchTermSplitted?.length > 0">
                              <span class="doc-search-term-label" *ngFor="let term of documentSearchTermSplitted">
                                <label class="wiley-admin-text check-container" title="{{term?.value?.replace(',','')}}">
                                  <input type="checkbox" class="disable-label" [(ngModel)]="term.checked" (change)="getRelevantPagesBySearchTerm($event)"
                                    name="relevantterm" checked>
                                  <span [class.arabic-style]="isArabicLanguage" class="fuzzy-text doc-search-term">{{term?.value?.replace(',','')}}</span>
                                  <span class="checkmark"></span>
                                </label>
                              </span>
                            </ng-container>

                            <ng-container *ngIf="!isSearchTermIncludesPhrase">
                              <span class="doc-search-term-label" *ngFor="let term of documentSearchTermSplitted">
                                <label class="wiley-admin-text check-container" title="{{term?.value?.replace(',','')}}">
                                  <input type="checkbox" class="disable-label" [(ngModel)]="term.checked" (change)="getRelevantPagesBySearchTerm($event)"
                                    name="relevantterm" checked>
                                  <span [class.arabic-style]="isArabicLanguage" class="fuzzy-text doc-search-term">{{term?.value?.replace(',','')}}</span>
                                  <span class="checkmark"></span>
                                </label>
                              </span>
                            </ng-container>
                          <!-- </perfect-scrollbar> -->
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="document-view-relavantItems">
                    <div class="col-xs-12 documents-tab-view document-view-parent docu-tab-view" [class.scrollFix]="scrollFix"
                      [class.scrollSix]="scrollSix" [class.scrollFour]="scrollFour" [class.scrollTwo]="scrollTwo">
                    <div class="search-doc" #container>
                      <!-- <perfect-scrollbar [config]="config" class="search-doc" #container> -->
                        <div [class.arabic-style]="isArabicLanguage" *ngIf="isNoRecodesFound && !searchWithInLoading">
                          <p [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.NoResultsFound' | translate }}</p>
                        </div>
                        <div [class.arabic-style]="isArabicLanguage" *ngIf="!isNoRecodesFound && searchWithInLoading">
                          <p [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.loading' | translate }}</p>
                        </div>

                        <accordion *ngIf="pageFullTextPageIds?.length > 0">
                          <accordion-group class="details-accordion-header" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'tooglePagesIcon')">
                            <div [class.arabic-style]="isArabicLanguage" accordion-heading class="details-page-acc-heading">
                              <span>
                                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="header-items-label"
                                  title="{{ 'Detail.Pages' | translate }}">
                                  {{ 'Detail.Pages' | translate }} </a>
                              </span>
                              <i class="fa fa-chevron-down pull-right header-accordion-upicn" [ngStyle]="{'display': !tooglePagesIcon ? 'block': 'none'}"></i>
                              <i class="fa fa-chevron-up pull-right header-accordion-upicn" [ngStyle]="{'display': tooglePagesIcon ? 'block': 'none'}"></i>
                            </div>
                            <div class="col-md-12 col-xs-12 header-accordian-inner">
                              <div class="padding-left-0 header-accodion-panel header-metadats">
                                <div class="relevant-pages-page-div">
                                  <ng-container *ngFor="let image of documentPageImages; let i = index;">
                                    <div *ngIf="image" (click)="loadRelaventPage(image.imageId, $event, documentSearchTermSplitted)"
                                      class="image-container relevant-image-container-div" [ngClass]="{'active': documentCurrentIndex === documentPageImages[i]?.pageNumber}">
                                      <ng-container>
                                        <img name='Document-image' [title]="image?.title" class="relevant-image-container center-block"
                                          alt="Document Image" src="{{image?.thumbURL + '?type=small&access_token=' + accessToken}}">
                                      </ng-container>
                                      <div class="relevant-sequence-number">{{documentPageImages[i]?.pageNumber}}</div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </accordion-group>
                        </accordion>
                        <accordion *ngIf="matchedMetadata">
                          <accordion-group *ngIf="matchedMetadata[currentDocID]?.length > 0" class="details-accordion-header"
                            [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggleMetadataIcon')">
                            <div [class.arabic-style]="isArabicLanguage" accordion-heading class="details-page-acc-heading">
                              <span>
                                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="header-items-label"
                                  title="{{ 'Detail.metaData' | translate }}">
                                  {{ 'Detail.metaData' | translate }} </a>
                              </span>
                              <i class="fa fa-chevron-down pull-right header-accordion-upicn" [ngStyle]="{'display': !toggleMetadataIcon ? 'block': 'none'}"></i>
                              <i class="fa fa-chevron-up pull-right header-accordion-upicn" [ngStyle]="{'display': toggleMetadataIcon ? 'block': 'none'}"></i>
                            </div>
                            <div class="col-md-12 col-xs-12 header-accordian-inner">
                              <div class="padding-left-0 header-accodion-panel header-metadats">
                                <div class="accordian-panel-heading header-panel-Content" *ngIf="matchedMetadata">
                                  <ul class="metadata-list">
                                    <ng-container *ngFor="let matchedData of matchedMetadata[currentDocID]">
                                      <li class="pad-5" *ngIf="matchedData[matchedData | keys]?.length > 0">
                                        <ul>
                                          <li class="pad-5" *ngFor="let val of matchedData[ matchedData | keys]">
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'title'"
                                              title="{{ 'Detail.documentTitle' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)"> {{ 'Detail.documentTitle' |
                                              translate }}</span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'collectionV2'"
                                              title="{{ 'Detail.Collection' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.Collection' |
                                              translate}}
                                            </span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'id'"
                                              title="{{ 'Detail.wileyDocumentNumber' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{
                                              'Detail.wileyDocumentNumber' | translate }}</span>
                                            <!-- <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'contentSubTypeV2'" title="{{ 'Detail.Collection' | translate }}"
                                              class="matcheddata-filedname" (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.Collection' | translate}}
                                            </span> -->
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'docLangV2'"
                                              title="{{ 'Detail.Language' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.Language' |
                                              translate }}</span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'sourceInstitutionV2' || val.field.toString() == 'archive'"
                                              title="{{ 'Detail.Source' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.Source' |
                                              translate }}</span>
                                            <!-- <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'findingAidNo'"
                                              title="{{ 'Detail.findingAidNumber' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.findingAidNumber'
                                              | translate }}</span> -->
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'authors'"
                                              title="{{ 'Detail.Authors' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.Authors' |
                                              translate }}</span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'pubDate'"
                                              title="{{ 'HomePage.publicationDate' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'HomePage.publicationDate'
                                              | translate }}</span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'docNotes'"
                                              title="{{ 'Detail.documentNotes' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.documentNotes' |
                                              translate}}
                                            </span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'itemNotesV2'"
                                              title="{{ 'Detail.itemNotes' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.itemNotes' |
                                              translate}}
                                            </span>
                                            <span *ngIf="val.field.toString() == 'itemTitles'" title="{{ 'Detail.itemTitle' | translate }}"
                                              class="matcheddata-filedname disable-cursor" (click)="gotoCorrespondingTab(matchedData)">{{
                                              'Detail.itemTitle' | translate}}</span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'subjectV2'"
                                              title="{{ 'SearchResult.Subjects' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{ 'SearchResult.Subjects' |
                                              translate }}</span>
                                            <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'docType'"
                                              title="{{ 'Detail.physicalDescription' | translate }}" class="matcheddata-filedname"
                                              (click)="gotoCorrespondingTab(matchedData)">{{
                                              'Detail.physicalDescription' | translate }}</span>
                                            <ul *ngFor="let citationSnippet of val.snipplets" class="matched-data-list">
                                              <li *ngIf="val.field.toString() !== 'itemTitles' && val.field.toString() !== 'contentSubTypeV2'"
                                                [innerHtml]="citationSnippet"></li>
                                            </ul>
                                            <ng-container *ngIf="val.field.toString() == 'itemTitles'">
                                              <ul *ngFor="let itemTitle of matchedItemTitles" class="matched-data-list">
                                                <li class="primary-text-color item-page" (click)="gotoCurrentItemTab($event, itemTitle.split(':')[0])"
                                                  [innerHtml]="itemTitle.split(':').slice(1).join(':')">
                                                </li>
                                              </ul>
                                            </ng-container>
                                          </li>
                                        </ul>
                                      </li>

                                    </ng-container>

                                  </ul>
                                </div>
                              </div>
                            </div>
                          </accordion-group>
                        </accordion>

                        <accordion *ngIf="pageFullTextObj?.length > 0">
                          <accordion-group class="details-accordion-header" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggleFulltextIcon')">
                            <div [class.arabic-style]="isArabicLanguage" accordion-heading class="details-page-acc-heading">
                              <span>
                                <a href="javascript:void(0)" [class.arabic-style]="isArabicLanguage" class="header-items-label"
                                  title="{{ 'Detail.fullText' | translate }}">
                                  {{ 'Detail.fullText' | translate }} </a>
                              </span>
                              <i class="fa fa-chevron-down pull-right header-accordion-upicn" [ngStyle]="{'display': !toggleFulltextIcon ? 'block': 'none'}"></i>
                              <i class="fa fa-chevron-up pull-right header-accordion-upicn" [ngStyle]="{'display': toggleFulltextIcon ? 'block': 'none'}"></i>
                            </div>
                            <div class="col-md-12 col-xs-12 header-accordian-inner">
                              <div class="padding-left-0 header-accodion-panel header-metadats">
                                <div class="accordian-panel-heading header-panel-Content" *ngIf="pageFullTextObj.length > 0">
                                  <ul class="metadata-list pagefulltext-list">
                                    <li class="pad-5" *ngFor="let pageFulltext of pageFullTextObj">
                                      <ul>
                                        <li class="pading-5" *ngFor="let val of pageFulltext.FULLTEXT">
                                          <ul *ngFor="let citationSnippet of val.snipplets" class="matched-data-list">
                                            <i class="fa fa-chevron-right pagefulltext-left-icn"></i>
                                            <li (click)="gotoPageFullText(pageFulltext.pageId)" [innerHtml]="'...'+citationSnippet+'...'"></li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </accordion-group>
                        </accordion>
                      <!-- </perfect-scrollbar> -->
                    </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </tab>
          <tab *ngFor="let tabz of tabs" [heading]="" [active]="tabz.active" (selectTab)="tabz.active = true;onDocViewerTabSelect(tabz.id);"
            (deselect)="tabz.active = false" [disabled]="tabz.disabled" [removable]="tabz.removable" [customClass]="tabz.customClass"
            id="{{tabz.id}}">
            <ng-container *ngIf="(tabz?.id === 'tocTabs')">
              <ng-template tabHeading>
                <label (click)="expandAllTree()" [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.TableOfContents' | translate }} ({{shiftContrlKey}} + '\')">
                  <span class="spriteico tocIcon"></span>
                  <p [class.arabic-style]="isArabicLanguage" class="tabicon-title"> {{ 'Detail.tocHeading' | translate
                    }} </p>

                </label>
              </ng-template>

              <div class="tab-pane" [class.toggled]="toggleMenu">
                <div *ngIf="documentData?.docType == 'Monographs'">
                  <div [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'Detail.TableOfContents' | translate }}">
                    <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading"> {{
                      'Detail.TableOfContents' | translate }} </h4>
                  </div>
                  <div class="toc-tree-scroll" #container>
                  <!-- <perfect-scrollbar [config]="config" class="toc-tree-scroll" #container> -->
                    <div class="col-md-12 padding-0">
                      <p [class.arabic-style]="isArabicLanguage" *ngIf="monographResultLoader">{{'SearchResult.loadingPleaseWait'
                        | translate}}
                      </p>
                      <tree-root #tree id="monographTree" (updateData)="expandTreeOnLoad($event)" [focused]="true"
                        [nodes]="monographTableofContentNodes" [options]="manuscripttableofContentOptions" (activate)="onNodeSelect($event)"
                        (deactivate)="loadNodedataToItems($event)">
                        <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                          <div class="tree-node" *ngIf="node">
                            <tree-node-expander [node]="node"></tree-node-expander>
                            <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                              [class.node-content-wrapper-focused]="node?.data?.pageId === selectedDocPageId?.pageId">
                              <a [class.node-link]="node?.data?.chilldPageIds" [ngClass]="addPageIdAsClass(node?.data?.chilldPageIds)"
                                [class]="node.data.className" [class.title]="true" [attr.title]="node?.data?.name">{{
                                node?.data?.name }}</a>
                            </div>
                            <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                          </div>
                        </ng-template>
                      </tree-root>

                    </div>
                  <!-- </perfect-scrollbar> -->
                </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="(tabz?.id === 'itemsTab')">
              <ng-template tabHeading>
                <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.itemsTitle' | translate }}  ({{shortcutKeyTitle}} + Y)">
                  <span class="spriteico itemsIcon"></span>
                  <p [class.arabic-style]="isArabicLanguage" class="tabicon-title"> {{ 'Detail.Items' | translate }}
                  </p>
                </label>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">
                <div [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'Detail.itemsTitle' | translate }}">
                  <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading"> {{ 'Detail.itemsTitle'
                    | translate }}
                  </h4>
                </div>
                <tabset #itemsTabset id="itemsTabset">
                  <tab [customClass]="'items-by-type-tabs'" id="itemTab1" heading="" (selectTab)="itemsByType(documentData?.wileyId)">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span" title="{{ 'Detail.ByType' | translate }} ({{controlShortcut}} + Q)">{{
                        'Detail.ByType' | translate }}</span>
                    </ng-template>
                    <div class="manuscriptTree2-tree-scroll" #container>
                    <!-- <perfect-scrollbar [config]="config" class="manuscriptTree2-tree-scroll" #container> -->
                      <div class="items-treenode">
                        <div class="treenode-icons itemstabset-treenode">
                          <p [class.arabic-style]="isArabicLanguage" *ngIf="itemsTypeResultLoader">{{'SearchResult.loadingPleaseWait'
                            | translate}}</p>
                          <tree-root #byTypeTree id="manuscriptTree1" [focused]="true" [nodes]="manuscriptTypeTableofContentNodes"
                            (updateData)="expandTreeOnLoad($event)" [options]="manuscripttableofContentOptions"
                            (activate)="onNodeSelect($event)" (deactivate)="loadNodedataToItems($event)">
                            <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                              <div class="tree-node" *ngIf="node">
                                <tree-node-expander [node]="node"></tree-node-expander>
                                <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                                  [class.node-content-wrapper-focused]="checkPageIdExist(node?.data?.chilldPageIds)">
                                  <a href="javascript:void(0)" [class.node-link]="node?.data?.chilldPageIds" [ngClass]="addPageIdAsClass(node?.data?.chilldPageIds)"
                                    class="treenode-childnode" [class.title]="true" [attr.title]="node?.data?.name">{{
                                    node?.data?.name }}</a>
                                  <a *ngIf="node?.data?.notesAvailable" (click)="getItemNotes(node?.data?.label)" class="item-notes-parent"
                                    href="javascript:void(0)">
                                    <i class="fa fa-sticky-note notes-icon" aria-hidden="true"></i>
                                  </a>
                                </div>
                                <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                              </div>
                            </ng-template>
                          </tree-root>
                        </div>
                      </div>
                    <!-- </perfect-scrollbar> -->
                  </div>
                  </tab>
                  <tab [customClass]="'items-by-type-tabs'" id="itemTab2" heading="" (selectTab)="itemsInOrder(documentData?.wileyId)">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span" title="{{ 'Detail.BySequence' | translate }} ({{shortcutKeyTitle}} + Q)">{{
                        'Detail.BySequence' | translate }}</span>
                    </ng-template>
                    <div class="manuscriptTree2-tree-scroll" #container>
                    <!-- <perfect-scrollbar [config]="config" class="manuscriptTree2-tree-scroll" #container> -->
                      <div class="items-treenode">
                        <div class="treenode-icons itemstabset-treenode">
                          <p [class.arabic-style]="isArabicLanguage" *ngIf="manuscriptResultLoader">{{'SearchResult.loadingPleaseWait'
                            | translate}}</p>
                          <tree-root id="manuscriptTree2" [focused]="true" [nodes]="manuscriptOrderTableofContentNodes"
                            [options]="manuscripttableofContentOptions" (activate)="onNodeSelect($event)" (deactivate)="loadNodedataToItems($event)">

                            <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                              <div class="tree-node">
                                <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                                  [class.node-content-wrapper-focused]="checkPageIdExist(node?.data?.chilldPageIds)">
                                  <a href="javascript:void(0)" [class.node-link]="node?.data?.chilldPageIds" [ngClass]="addPageIdAsClass(node?.data?.chilldPageIds)"
                                    class=" sequence-tree treenode-childnode m-w300" [attr.title]="node?.data?.name"
                                    [class.title]="true">{{ node?.data?.name }}</a>
                                  <a *ngIf="node?.data?.notesAvailable" (click)="getItemNotes(node?.data?.label)" class="item-notes-parent"
                                    href="javascript:void(0)">
                                    <i class="fa fa-sticky-note notes-icon" aria-hidden="true"></i>
                                  </a>
                                </div>
                                <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                              </div>
                            </ng-template>
                          </tree-root>
                        </div>
                      </div>
                    <!-- </perfect-scrollbar> -->
                  </div>
                  </tab>
                </tabset>





              </div>
            </ng-container>
            <ng-container *ngIf="(tabz?.id === 'notesTab')">
              <ng-template tabHeading>
                <span [class.arabic-style]="isArabicLanguage" class="spriteico notesIcon" title="{{ 'Detail.Notes' | translate}} ({{shortcutKeyTitle}} + M)"></span>
                <p class="tabicon-title" [class.arabic-style]="isArabicLanguage"> {{ 'Detail.Notes' | translate }} </p>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">

                <tabset #itemsTabset id="itemsTabset">
                  <tab *ngIf="documentData?.docNoteExist" [customClass]="'items-by-type-tabs'" id="itemTab1" heading="">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span" title="{{ 'Detail.documentNotes' | translate}}">{{
                        'Detail.documentNotes' | translate}}</span>
                    </ng-template>
                    <div class="">
                      <div class="col-xs-12 pad-r-0">
                        <div class="document-notes-tab" #container>
                        <!-- <perfect-scrollbar [config]="config" class="document-notes-tab" #container> -->
                          <div class="padding-right-5">
                            <ng-container *ngIf="documentData?.shortName === 'RCP'">
                               <div class="documentType">
                                 <label class="citation-contentlbl"> {{ 'Detail.dimensionFree' | translate }}</label>
                                 <ng-container *ngIf="docutypeDetails['Dimension Free'] || docutypeDetails['Dimensions']?.length > 0; else Dimension">
                                 <ul *ngFor="let docValue of docutypeDetails['Dimension Free'] || docutypeDetails['Dimensions']" class="document-type-list">
                                   <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}</li>
                                 </ul>
                                 </ng-container>
                                 <ng-template #Dimension>
                                    <ul class="document-type-list">
                                      <li> {{ 'Detail.infoNotProvided' | translate }} </li>
                                    </ul>
                                 </ng-template>                                 
                               </div>

                               <div class="documentType">
                                  <label class="citation-contentlbl">{{ 'Detail.objectHistory' | translate }}</label>
                                  <ng-container *ngIf="docutypeDetails['Object History Note'] || docutypeDetails['object_history_note']?.length > 0; else ObjectHistory">
                                  <ul *ngFor="let docValue of docutypeDetails['Object History Note'] || docutypeDetails['object_history_note']" class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}</li>
                                  </ul>
                                  </ng-container>
                                  <ng-template #ObjectHistory>
                                     <ul class="document-type-list">
                                       <li> {{ 'Detail.infoNotProvided' | translate }} </li>
                                     </ul>
                                  </ng-template>                                 
                                </div>
                                
                                <div class="documentType">
                                  <label class="citation-contentlbl">{{ 'Detail.contentDesc' | translate }}</label>
                                  <ng-container *ngIf="docutypeDetails['Content description']?.length > 0; else contectDesc">
                                  <ul *ngFor="let docValue of docutypeDetails['Content description']" class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}</li>
                                  </ul>
                                  </ng-container>
                                  <ng-template #contectDesc>
                                      <ul class="document-type-list">
                                        <li>  {{ 'Detail.infoNotProvided' | translate }} </li>
                                      </ul>
                                  </ng-template>                                 
                                </div>

                                <div class="documentType">
                                  <label class="citation-contentlbl">{{ 'Detail.partofReference' | translate }}</label>
                                  <ng-container *ngIf="docutypeDetails['Part of Reference']?.length > 0; else referencePart">
                                  <ul *ngFor="let docValue of docutypeDetails['Part of Reference']" class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}</li>
                                  </ul>
                                  </ng-container>
                                  <ng-template #referencePart>
                                      <ul class="document-type-list">
                                        <li >  {{ 'Detail.infoNotProvided' | translate }} </li>
                                      </ul>
                                  </ng-template>                                 
                                </div>
                                <div class="documentType">
                                  <label class="citation-contentlbl">{{ 'Detail.Notes' | translate }}</label>
                                  <ng-container *ngIf="docutypeDetails['General Note']?.length > 0; else generalNotes">
                                  <ul *ngFor="let docValue of docutypeDetails['General Note']" class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}</li>
                                  </ul>
                                  </ng-container>
                                  <ng-template #generalNotes>
                                      <ul class="document-type-list">
                                        <li >  {{ 'Detail.infoNotProvided' | translate }} </li>
                                      </ul>
                                  </ng-template>                                 
                                </div>
                                <div class="documentType">
                                  <label class="citation-contentlbl">{{ 'Detail.physicalDesc' | translate }}</label>
                                  <ng-container *ngIf="docutypeDetails['Diplomatic Notes']?.length > 0; else diplomaticNotes">
                                  <ul *ngFor="let docValue of docutypeDetails['Diplomatic Notes']" class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}</li>
                                  </ul>
                                  </ng-container>
                                  <ng-template #diplomaticNotes>
                                      <ul class="document-type-list">
                                        <li > {{ 'Detail.infoNotProvided' | translate }}</li>
                                      </ul>
                                  </ng-template>                                 
                                </div>
                            </ng-container>
                            <ng-container *ngIf="documentData?.shortName !== 'RCP'">
                            <ng-container *ngFor="let docuNotes of documentNotesFromNotes">
                              <div class="documentType">
                                <label *ngIf="docuNotes?.type !== 'Websites'" class="citation-contentlbl">{{docuNotes?.type}}</label>
                                <label *ngIf="docuNotes?.type === 'Websites' && showWebsites" class="citation-contentlbl">{{docuNotes?.type}}</label>
                                <ul *ngFor="let docValue of docuNotes?.value" class="document-type-list">
                                  <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}</li>
                                  <li *ngIf="docuNotes?.type === 'Websites' && showWebsites" [innerHtml]="getWebsiteLinkTags(docValue)"
                                    class="display-block-text primary-text-color">
                                    <!-- <li *ngIf="docuNotes?.type === 'Websites'" [innerHtml]="getWebsiteLinkTags(docValue)"
                                    class="display-block-text primary-text-color"> -->
                                  </li>
                                </ul>
                              </div>
                            </ng-container>
                            </ng-container>
                          </div>
                        <!-- </perfect-scrollbar> -->
                      </div>
                      </div>
                    </div>
                  </tab>
                  <tab *ngIf="documentData?.collectionNoteExist" [customClass]="'items-by-type-tabs'" id="itemTab2"
                    heading="" (selectTab)="getCollectionLevelMetadata();">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span" title="{{ 'Detail.collectionNotes' | translate}}">{{
                        'Detail.collectionNotes' | translate}}</span>
                    </ng-template>
                    <div class="items-treenode">
                      <div class="col-xs-12 pad-r-0">
                        <div class="document-notes-tab" #container>
                        <!-- <perfect-scrollbar [config]="config" class="document-notes-tab" #container> -->
                          <div *ngIf="collectionNotes?.length === 0 && !collectionLoading">
                            {{ 'SearchResult.NoResultsFound' | translate }}
                          </div>
                          <div *ngIf="collectionNotes?.length === 0 && collectionLoading ">
                            {{'SearchResult.loadingPleaseWait' | translate }}
                          </div>
                          <ng-container *ngFor="let collectionNotes of collectionNotes">
                            <div class="padding-right-15">
                              <div class="documentType">
                                <label class="citation-contentlbl">{{collectionNotes?.type}}</label>
                                <ul *ngFor="let collectionValue of collectionNotes?.value" class="document-type-list">
                                  <li class="citation-content">{{collectionValue}}</li>
                                </ul>
                              </div>
                            </div>
                          </ng-container>
                        <!-- </perfect-scrollbar> -->
                      </div>
                      </div>
                    </div>

                  </tab>
                </tabset>
              </div>
            </ng-container>
            <ng-container *ngIf="(tabz?.id === 'relatedSubjectsTab')">
              <ng-template tabHeading>
                <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.RelatedSubjects' | translate }} ({{shortcutKeyTitle}} + P)">
                  <span class="spriteico relatedsubIcon"> </span>
                  <p [class.arabic-style]="isArabicLanguage" class="tabicon-title"> {{ 'SearchResult.Subjects' |
                    translate }} </p>
                </label>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">
                <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading"> {{
                  'Detail.RelatedSubjects' | translate }} </h4>
                  <div class="document-tab-view" #container>
                <!-- <perfect-scrollbar [config]="config" class="document-tab-view" #container> -->
                  <div class="col-md-12">
                    <ng-container *ngIf="loader?.relatedSubjects">

                      <span [class.arabic-style]="isArabicLanguage">{{'SearchResult.loadingPleaseWait' | translate}}</span>
                    </ng-container>
                    <ng-container *ngIf="!loader?.relatedSubjects && relatedSubjectsData?.length === 0">
                      <span [class.arabic-style]="isArabicLanguage">{{'Common.Noresultsfound' | translate}}</span>
                    </ng-container>
                    <ng-container *ngIf="!loader?.relatedSubjects">
                      <ul class="relatedsub-ul" id="relatedSubjects">
                        <li *ngFor="let relatedSubject of relatedSubjectsData" class="relatedsub-list" [ngStyle]="{ 'cursor': isSessionGuest === 'guest' ? 'default' : 'pointer'}">
                          <ng-container *ngIf="isSessionGuest != 'guest'">
                            <a title="{{ 'Detail.searchBySubject' | translate }}  {{relatedSubject}}" href="javascript:void(0)"
                              class="relatedsub-link" (click)="setSubject(relatedSubject)" (mouseenter)="reletadSubjectsMouseEnter(relatedSubject, $event)"
                              (mouseleave)="reletadSubjectsMouseLeave(relatedSubject, $event)">
                              {{relatedSubject}}
                              <i class="fa fa-lg fa-search related-sub-icon sub-icon" style="visibility: hidden;"></i>
                            </a>
                          </ng-container>
                          <ng-container *ngIf="isSessionGuest === 'guest'">
                            <a title="{{ 'Detail.searchBySubject' | translate }}  {{relatedSubject}}" href="javascript:void(0)"
                              class="relatedsub-link" [ngStyle]="{ 'cursor': isSessionGuest === 'guest' ? 'default' : 'pointer'}">
                              {{relatedSubject}}
                              <i class="fa fa-lg fa-search related-sub-icon sub-icon" style="visibility: hidden;"></i>
                            </a>
                          </ng-container>
                        </li>
                      </ul>
                    </ng-container>
                  </div>
                <!-- </perfect-scrollbar> -->
              </div>
              </div>
            </ng-container>
            <ng-container *ngIf="(tabz?.id === 'illustrationTab')">
              <ng-template tabHeading>
                <label [class.arabic-style]="isArabicLanguage" title="{{ 'SearchResult.Illustrations.Illustrations' | translate }} ({{shortcutKeyTitle}} + B)">
                  <span class="spriteico illustrationIcon"></span>
                  <p class="tabicon-title" [class.arabic-style]="isArabicLanguage"> {{
                    'SearchResult.Illustrations.Illustrations' | translate }} </p>
                </label>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu" #illustrationsTab>
                <div id="illustrationTree">
                  <div [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'SearchResult.Illustrations.Illustrations' | translate  }}">
                    <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading"> {{
                      'SearchResult.Illustrations.Illustrations' | translate }} </h4>
                  </div>
                  <!-- <div class="col-md-12 padding-right-0"> -->
                    <div class="illustration-tree-scroll" #container>
                  <!-- <perfect-scrollbar [config]="config" class="illustration-tree-scroll" #container> -->
                    <p [class.arabic-style]="isArabicLanguage" *ngIf="illustrationresultLoader">{{'SearchResult.loadingPleaseWait'
                      | translate}}</p>

                    <tree-root id="illustrationTree" (updateData)="expandTreeOnLoad($event)" [focused]="true" [nodes]="illustrationNodes"
                      [options]="manuscripttableofContentOptions" (activate)="onTableSelect($event)" (deactivate)="loadNodedataIllustrations($event)">


                      <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                        <div *ngIf="node" class="tree-node">
                          <tree-node-expander [node]="node"></tree-node-expander>
                          <div class="node-content-wrapper child-node" [class.node-content-wrapper-focused]="(node?.data?.pageId === selectedDocPageId?.pageId)">

                            <a [class.node-link]="node?.data?.pageId" [ngClass]="addPageIdAsClass(node?.data?.pageId)"
                              (mouseenter)="mouseEnter(node)" (mouseleave)="mouseLeave(node)" class="illustration-tree treenode-childnode"
                              [class]="node.data.className" [attr.title]="node?.data?.name" [class.title]="true"
                              (click)="node.mouseAction('click', $event)">{{ node?.data?.name }}</a>
                            <span *ngIf="node?.data?.tableId" class="download-illustration" (click)="downIcon({tabId: node.data['tableId'], ScreenMode: false}) ">
                              <i class="fa fa-download"></i>
                            </span>
                          </div>
                          <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                        </div>

                      </ng-template>

                    </tree-root>

                  <!-- </perfect-scrollbar> -->
                </div>
                </div>
                <div class="illustration-image-body" #illustrationPreviewWindow id="illustrationImageDiv" [ngStyle]="{'top.px': illustrationPreviewPosition}"
                  style="display:none">
                  <img class="accordian-img center-block img-responsive" (click)="gotoIlustrationDetailspage()"
                    [attr.src]="illustrationDocumentImage" alt="{{'Alt.imageThumbnails' | translate}}" />
                  <div class="illustration-image-loader" [ngStyle]="{display:illustrationsLoader ? 'block':'none'}">
                    <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="(tabz?.id === 'volumesTab')">
              <ng-template tabHeading>
                <span [class.arabic-style]="isArabicLanguage" class="spriteico volumeIcon" title="{{ 'Detail.RelatedVolumes' | translate }} ({{shortcutKeyTitle}} + U)">
                </span>
                <p [class.arabic-style]="isArabicLanguage" class="tabicon-title"> {{ 'Detail.RelatedVolumes' |
                  translate }} </p>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">

                <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading"> {{
                  'Detail.RelatedVolumes' | translate }} </h4>
                  <div class="document-tab-view" #container>
                <!-- <perfect-scrollbar [config]="config" class="document-tab-view" #container> -->
                  <ng-container *ngIf="loader?.relatedVolume">

                    <span [class.arabic-style]="isArabicLanguage">{{'SearchResult.loadingPleaseWait' | translate}}</span>
                  </ng-container>
                  <ng-container *ngIf="!loader?.relatedVolume && relatedVolumes?.length === 0">
                    <span [class.arabic-style]="isArabicLanguage">{{'Common.Noresultsfound' | translate}}</span>
                  </ng-container>
                  <ng-container *ngIf="!loader?.relatedVolume">

                    <div class="col-md-12  pad-r-0">
                      <ul class="relatedsub-ul" id="volumes-ul">
                        <li *ngFor="let rv of relatedVolumes" class="relatedsub-list1" title="{{rv.title}}">
                          <a href="javascript:void(0)" class="relatedsub-link" (click)="gotoDocumentById(rv?.id)" *ngIf="rv.title; else volumeLabel">
                            {{rv?.volumeNo}} - {{rv.title}}
                          </a>
                          <ng-template #volumeLabel>
                            <p class="relatedsub-link-disabled relatedsub-link"> {{rv?.volumeNo}}</p>
                          </ng-template>
                        </li>
                      </ul>
                    </div>

                  </ng-container>
                <!-- </perfect-scrollbar> -->
              </div>
              </div>
            </ng-container>
          </tab>
        </tabset>
      </div>
    </div>
    <div class="col-md-10 docviewer-imageframe" [class.toggled]="toggleMenu" [class.gridview-images]="!isGridViewEnable">
      <div class=" filter-result padding-0" *ngIf="pageContentData">
        <div class="docu-viewer-div">
          <div [class.hideImage]="!checkModel.document">
            <!-- [defaultPage]="selectedPageId"  -->
            <app-new-image-viewer-frame [options]="imageViewerOptions" (pageFullText)="updatePageFullText($event)"
              (whenMainImageLoaded)="afterMainImageLoaded($event)" [source]="documentImages" [currentDocumentId]="defaultImageToBeLoaded"
              [showDragonViewer]="true" [image-id]="_selectedPage" [showRelevantImages]="showRelevantImages"
              (relevantStatusChanged)="onRelevantStatusChange($event)" (overlayClicked)="onOverlayClick($event)"
              [itemTitle]="itemTitle" (currentActiveImageIndex)="setCurrentActiveImageIndex($event)" [invokedFrom]="invokedFrom"
              [documentTitleImages]="documentImagesWithTitles" [pageOverlay]="_selectedPageOverlay" [documentType]="documentData?.docType"
              [overlayForSearchTerm]="overlaysForTermSearch" [urlToDownload]="downloadableUrl" [urlForDownload]="downloadUrlFor"
              [fuzzySearch]="toggleSearch" [overlayOption]="getoverlayOption" (newOverlayFloatingVal)="newOverlayFloatingVal($event)"
              (rotationValue)="getRotationValue($event)"></app-new-image-viewer-frame>
          </div>
          <div id="fullTextTab" [class.hideImage]="!checkModel.fullText">
            <ng-template>
              <span [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.fullText' | translate }} ({{controlShortcut}} + P)">{{
                'Detail.fullText' | translate }}
              </span>
            </ng-template>
            <div [class.arabic-style]="isArabicLanguage" class="col-md-12 col-sm-12 padding-top15 disclaimer-div"
              [class.toggledDisclaimer]="toggledisclaimerShow">
              <b [class.arabic-style]="isArabicLanguage">{{ 'Detail.Disclaimer' | translate }}</b>{{
              'Detail.fullTextContent' | translate }} {{ 'Detail.translateDisclaimerContent'
              | translate }}
              <div [class.arabic-style]="isArabicLanguage" class="disclaimer-toggle" (click)="disclaimerToggle()">
                <i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-down" title="Expand Disclaimer" *ngIf="toggledisclaimerShow"
                  aria-hidden="true"></i>
                <i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-up" title="Collapse Disclaimer" *ngIf="!toggledisclaimerShow"
                  aria-hidden="true"></i>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 padding-top15">
              <div class="fulltext-image-loader" [ngStyle]="{display:fullTextLoader ? 'block':'none'}">
                <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
              </div>
              <div class="full-text-wrapper language-popover">
                <ul class="full-text-clearMarginPadding" style="display: none;">
                  <li *ngIf="!documentCurrentIndex || documentCurrentIndex > 1" class="full-text-left_arrow">
                    <span class="full-text-navigation-arrows-viewer i-full-text-leftArrow" (click)="gotoPreviouesDocumentFullText()">
                    </span>
                  </li>
                  <li>
                    <label [class.arabic-style]="isArabicLanguage" class="full-text-pagination-label">{{ 'Detail.page'
                      | translate }}
                      <input [class.arabic-style]="isArabicLanguage" type="text" #pageNo (keypress)="keyPress($event)"
                        title="{{ 'Detail.pageNumber' | translate }} {{documentCurrentIndex}}" class="full-text-pagination-page"
                        [ngModel]="documentCurrentIndex" (keyup.enter)="gotoCurrentDocumentFulltext($event, false)" />
                      {{ 'Detail.OF' | translate }} {{documentImages?.length}}
                    </label>
                  </li>
                  <li *ngIf="documentImages.length != documentCurrentIndex" class="full-text-right_arrow">
                    <span class="full-text-navigation-arrows-viewer i-full-text-rightArrow" (click)="gotoNextDocumentFullText()">
                    </span>
                  </li>
                </ul>

                <button [class.arabic-style]="isArabicLanguage" *ngIf="documentFulltext != null && documentFulltext !='' && documentFulltext !=' <br>' "
                  class="btn-default btn btn-link copy-btn btn-xs full full-text-btn-height" title="{{currentLanguageForTranslate}}"
                  type="button" [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true" [outsideClick]="true"
                  [popover]="translateDocTemplate" #translatepop="bs-popover" triggers="click:click" [placement]="'bottom'"
                  (click)="translateTrigger();toggleTranslate = !toggleTranslate;">
                  <span class="fa fa-language"></span>&nbsp; {{currentLanguageForTranslate}}
                  <span class="fa translate-downicn" [class.fa-chevron-down]="toggleArrow" [class.fa-chevron-up]="!toggleArrow"></span>
                </button>
                <ng-template #translateDocTemplate>
                  <ul class="popoverUl">
                    <div class="language-popover-scroll">
                    <!-- <perfect-scrollbar [config]="config" class="language-popover-scroll"> -->
                      <li *ngFor="let language of translateLanguageValues">
                        <span class="popover_inner_span" [ngClass]="{'primary-text-color':  language.key === currentLanguageForTranslate}"
                          (click)="translateDoc(language.value, language.key);">{{language.key}}</span>
                      </li>
                    <!-- </perfect-scrollbar> -->
                    </div>
                  </ul>
                </ng-template>
              </div>
              <div class="full_text float-right" *ngIf="documentFulltext">
                <button [class.arabic-style]="isArabicLanguage" *ngIf="documentFulltext != null && documentFulltext !='' && documentFulltext !=' <br>' "
                  class="btn-default btn btn-link copy-btn btn-xs full full-text-btn-height" type="button" (click)="fulltextCopy(documentFulltext)"
                  title="{{ 'Detail.copytoClipboard' | translate }}">
                  <i class="fa fa-copy"></i>&nbsp;{{ 'Detail.copytoClipboard' | translate }}
                </button>
                <button [class.arabic-style]="isArabicLanguage" *ngIf="documentFulltext != null && documentFulltext !='' && documentFulltext !=' <br>' "
                  class="btn-default btn btn-link copy-btn btn-xs full-text-btn-height" [ngClass]="{'popoverSelect':toggleviewlist }"
                  type="button" (click)="saveTextAsFile()" title="{{ 'Detail.dpwnloadThisPage' | translate }}">
                  <span class="glyphicon glyphicon-download-alt"></span>&nbsp;{{ 'Detail.dpwnloadThisPage' | translate
                  }}
                </button>
                <button [class.arabic-style]="isArabicLanguage" class="btn-default btn btn-link copy-btn btn-xs full-text-btn-height"
                  type="button" (click)="downloadFullText(documentData?.wileyId)" title="{{ 'Detail.downloadEntire' | translate }}">
                  <span class="glyphicon glyphicon-download-alt"></span>&nbsp;{{ 'Detail.downloadEntire' | translate }}
                </button>
              </div>
              <br clear="all" />
              <div class="full-text-body">
              </div>
              <div id="disclaimerContents" class="disclaimer-contents disclaimer-content-div"
                [class.disclaimer-toggle-content]="toggledisclaimerShow" *ngIf="documentFulltext != null && documentFulltext !='' && documentFulltext !=' <br>' ">
                <div class="disclaimer-content-scroll">
                <!-- <perfect-scrollbar [config]="config" class="disclaimer-content-scroll"> -->
                  <span id="disclaimerContents" class="disclaimer-contents fulltext-parent margin-bottom0">
                    <pre id="rtl-language">{{documentFulltext}}</pre>
                  </span>
                <!-- </perfect-scrollbar> -->
              </div>
              </div>
              <p class="disclaimer-contents" *ngIf="documentFulltext == null || documentFulltext =='' || documentFulltext ==' <br>' ">{{
                'Detail.noFullTextAvailable' | translate }}</p>
            </div>

          </div>

        </div>
        <ng-template #shareHtmlPageTemplate>
          <div class="modal-popup-parentDiv">
            <div class="modal-header emailpopup-header">
              <h4 [class.arabic-style]="isArabicLanguage" class="emailpopup-heading">{{ 'Detail.sendEmail' | translate
                }} </h4>
            </div>
            <div class="modal-body">
              <form class="form-horizontal sendMail" #sendMailForm="ngForm">
                <div class="form-group">
                  <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
                    'Detail.from' | translate }}
                    <span class="glyphicon glyphicon-asterisk"></span>
                  </label>
                  <div class="col-sm-10">
                    <input type="email" #contactemailfrom="ngModel" class="form-control" id="emailFrom" placeholder="{{'Detail.fromPlaceholder' | translate}}"
                      name="contactemailfrom" [(ngModel)]="shareMailData.from" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      required>
                    <div class="" [hidden]="contactemailfrom.valid || contactemailfrom.untouched">
                      <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="contactemailfrom.errors && contactemailfrom.errors.required">
                        <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.emailIsRequired' | translate }}</h4>
                      </div>
                      <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="contactemailfrom.errors && contactemailfrom.errors.pattern">
                        <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.invalidEmail' | translate }}</h4>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group">
                  <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
                    'Detail.recipient' | translate }}
                    <span class="glyphicon glyphicon-asterisk"></span>
                  </label>
                  <div class="col-sm-10">
                    <input type="email" #contactemailto="ngModel" class="form-control" id="emailTo" placeholder="{{'Detail.recipientPlaceholder' | translate}}"
                      name="contactemailto" [(ngModel)]="shareMailData.to" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      required>
                    <div class="" [hidden]="contactemailto.valid || contactemailto.untouched">
                      <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="contactemailto.errors && contactemailto.errors.required">
                        <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.emailIsRequired' | translate }}</h4>
                      </div>
                      <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="contactemailto.errors && contactemailto.errors.pattern">
                        <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.invalidEmail' | translate }}</h4>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group">
                  <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="subject">{{
                    'Detail.subject' | translate }}
                    <span class="glyphicon glyphicon-asterisk"></span>
                  </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="subject" placeholder="{{'Detail.subjectPlaceholder' | translate}}"
                      name="subject" [(ngModel)]="shareMailData.topic" #subject="ngModel" required>
                    <div class="error text-danger" [hidden]="subject.valid || subject.untouched ">
                      <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="subject.errors && subject.errors.required">
                        <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.requiredSubject' | translate }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="message">{{
                    'Detail.Message' | translate }}
                    <span class="glyphicon glyphicon-asterisk"></span>
                  </label>
                  <div class="col-sm-10">
                    <textarea type="text" wrap="hard" class="form-control message-textarea" id="message" rows="10" cols="50"
                      placeholder="{{'Detail.messagePlaceholder' | translate}}" name="message" [(ngModel)]="shareMailData.message"
                      #message="ngModel" required></textarea>
                    <div class="error text-danger" [hidden]="message.untouched || shareMailData.message.trim() !== ''">
                      <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="(message.errors && message.errors.required) || shareMailData.message.trim() === ''">
                        <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.requiredMessage' | translate }}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="emailSend submitBtn">
                  <div class="col-sm-12">
                    <button [class.arabic-style]="isArabicLanguage" type="button" [disabled]="!sendMailForm.form.valid || shareMailData.message.trim() === ''"
                      class="btn-primary btn" aria-label="Close" (click)="shareEmail()">{{ 'Detail.Send' | translate }}</button>

                  </div>
                </div>
                <!-- <tooltip-content #sendMailFormTooltip [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
                  <div class="error text-danger " [hidden]="contactemailfrom.valid">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
                      {{ 'Detail.fromIsRequired' | translate }}
                    </div>

                  </div>
                  <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailto.untouched || contactemailto.invalid">
                      {{ 'Detail.recipientIsRequired' | translate }}
                    </div>
                  </div>
                  <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="subject.untouched || subject.invalid">
                      {{ 'Detail.subjectIsRequired' | translate }}
                    </div>
                  </div>
                  <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
                      {{ 'Detail.messageIsRequired' | translate }}
                    </div>
                  </div>
                </tooltip-content> -->
                <!-- <ng-template #sendMailFormTooltip [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
                  <div class="error text-danger " [hidden]="contactemailfrom.valid">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
                      {{ 'Detail.fromIsRequired' | translate }}
                    </div>

                  </div>
                  <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailto.untouched || contactemailto.invalid">
                      {{ 'Detail.recipientIsRequired' | translate }}
                    </div>
                  </div>
                  <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="subject.untouched || subject.invalid">
                      {{ 'Detail.subjectIsRequired' | translate }}
                    </div>
                  </div>
                  <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
                    <div [class.arabic-style]="isArabicLanguage" *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
                      {{ 'Detail.messageIsRequired' | translate }}
                    </div>
                  </div>
                </ng-template> -->
                <div class="emailClose closeBtn">
                  <button [class.arabic-style]="isArabicLanguage" type="button" class="btn-default btn" (click)="closeShareModal()">
                    Close
                  </button>
                </div>
              </form>
            </div>

          </div>
        </ng-template>

      </div>
      <ng-template #bookmarkHtmlPageTemplate>
        <div class="modal-header">
          <h4 class="bookmark-header">
            {{ 'Detail.bookmarkURL' | translate }}
          </h4>

          <h4 *ngIf="!showClipBoardSuccessAlert" class="modal-title sa-icon sa-info pulseWarning">
          </h4>
          <h4 *ngIf="showClipBoardSuccessAlert" class="modal-title sa-icon sa-success pulseWarning">
            <span class="sa-line sa-tip animateSuccessTip"></span>
            <span class="sa-line sa-long animateSuccessLong"></span>
          </h4>
        </div>
        <div class="modal-body url_body">
          <div [class.arabic-style]="isArabicLanguage" *ngIf="showClipBoardSuccessAlert" class="alert alert-success">{{
            'Detail.urlCopied' | translate }}</div>
          <div class="well">
            <span id="bookmarkPageURLonTemplate">{{bookmarkPageURL}}</span>
          </div>
          <div [class.arabic-style]="isArabicLanguage" *ngIf="bookmarkPageURL" class="share-doc-message">
            <span [class.arabic-style]="isArabicLanguage" class="share-doc-message-span">{{
              'Detail.shareDocumentBookmarkMessage' | translate }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary" (click)="closeBookMarkModal()">
            {{ 'Detail.Close' | translate }}
          </button>
          <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" aria-label="Close"
            (click)="copytoClipBoard(bookmarkPageURL)">
            {{ 'Detail.copyClipboard' | translate }}
          </button>
        </div>
      </ng-template>

      <ng-template #coveflowSliderTemplate>
        <div [class.arabic-style]="isArabicLanguage" class="modal-header coverfloe-header">
          <label [class.arabic-style]="isArabicLanguage" class="coverflow-headerlbl">{{ 'Detail.messageIsRequired' |
            translate }}  {{ 'SearchResult.browseSearchResult' | translate }}  {{this.contentType}} </label>
        </div>
        <div class="modal-body url_body cover_flow">
          <div class="carousel">
            <ng-container *ngFor="let image of coverFlowImages">
              <div>
                <label class="document-titlelbl" [attr.title]="image.imageTitle">{{image.imageTitle}}</label>
                <img (click)="gotoCoverflowImagData(image.imageId)" [src]="image.url" alt="{{'Alt.coverFlowImages' | translate}}"
                  width="100%" [tooltip]="coverflowImageTooltip" placement="right">
                <!-- <tooltip-content #coverflowImageTooltip>{{image.imageCitation}}</tooltip-content> -->
                <ng-template ##coverflowImageTooltip>
                  {{image.imageCitation}}
                </ng-template>
              </div>
            </ng-container>
          </div>
          <br clear="all" />
          <div class="modal-footer coverflow-footer">
            <div class="text-center">
              <label class="coverflowimage-countlabel">{{currentSlideNumber + 1}} of {{coverflowImagesCount}}</label>
            </div>
            <button [class.arabic-style]="isArabicLanguage" type="button" class="btn-default btn coverflow-btn-close"
              (click)="closecoveflowSlider()">
              {{ 'Detail.Close' | translate }}
            </button>

          </div>
        </div>
      </ng-template>
      <ng-template #citationPageTemplate>
        <div class="modal-header citationHeader">
          <h4 [class.arabic-style]="isArabicLanguage" class="modal-title illustration-title pull-left">{{
            'Detail.copyCitation' | translate }}</h4>
          <button type="button" class="fa fa-close citation-closebtn" (click)="closecitationPageTemplate()">
            <span class="sr-only">close button</span>
          </button>
        </div>
        <div class="modal-body citationBody">
          <div class="citationInnerHtml">
            <ul>
              <li>
                <label class="citation_type_header">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.MLA' | translate }} </span>
                  <span class="float-right" [ngClass]="{'diasbledBtn' : emptyCitation} ">
                    <span *ngIf="!isInIpadMode">
                      <i class="copyCitation fa fa-copy" tooltip="{{'Detail.copyClipboard' | translate}}" aria-hidden="true"
                        (click)="copyCitation(citationRes?.MLA, 'ModelMLA')"></i>
                      <i class="fa fa-download download-Citation" download tooltip="{{'Detail.clickToDownload' | translate}}"
                        aria-hidden="true" (click)="downloadCitation(citationRes?.MLA)"></i>
                    </span>
                    <span *ngIf="isInIpadMode">
                      <i class="copyCitation fa fa-copy" (click)="copyCitation(citationRes?.MLA, 'ModelMLA')"></i>
                      <i class="fa fa-download download-Citation" download (click)="downloadCitation(citationRes?.MLA)"></i>
                    </span>
                  </span>
                </label>
                <label id="citationMla" class="cslEntry" [ngClass]="{'mycopyCitation' : modelcitaionMLA}" #citationMla
                  [innerHtml]="citationRes?.MLA" *ngIf="!emptyCitation"></label>
                <label *ngIf="emptyCitation"> N/A</label>
              </li>
              <li>
                <label class="citation_type_header">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.CHICAGO' | translate }} </span>
                  <span class="float-right" [ngClass]="{'diasbledBtn' : emptyCitation}">
                    <span *ngIf="!isInIpadMode">
                      <i tooltip="Copy to Clipboard " class="copyCitation  fa fa-copy" aria-hidden="true" (click)="copyCitation(citationRes?.CHICAGO, 'ModelCHICAGO' )"></i>
                      <i class="fa fa-download download-Citation" tooltip="Click to download" aria-hidden="true"
                        (click)="downloadCitation(citationRes?.CHICAGO)"></i>
                    </span>
                    <span *ngIf="isInIpadMode">
                      <i class="copyCitation  fa fa-copy" (click)="copyCitation(citationRes?.CHICAGO, 'ModelCHICAGO' )"></i>
                      <i class="fa fa-download download-Citation" (click)="downloadCitation(citationRes?.CHICAGO)"></i>
                    </span>
                  </span>
                </label>
                <label id="citationChicago" #citationChicago [ngClass]="{'mycopyCitation' : modelcitaionCHICAGO}" class="cslEntry"
                  [innerHtml]="citationRes?.CHICAGO" *ngIf="!emptyCitation"></label>
                <label *ngIf="emptyCitation"> N/A</label>
              </li>
              <li>
                <label class="citation_type_header">
                  <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.APA' | translate }} </span>
                  <span class="float-right" [ngClass]="{'diasbledBtn' : emptyCitation}">
                    <span *ngIf="!isInIpadMode">
                      <i placement="top" class="copyCitation fa fa-copy" tooltip="Copy to Clipboard" aria-hidden="true"
                        (click)="copyCitation(citationRes?.APA, 'ModelAPA')"></i>
                      <i class="fa fa-download download-Citation" tooltip="Click to download" aria-hidden="true"
                        (click)="downloadCitation(citationRes?.APA)"></i>
                    </span>
                    <span *ngIf="isInIpadMode">
                      <i placement="top" class="copyCitation fa fa-copy" (click)="copyCitation(citationRes?.APA, 'ModelAPA')"></i>
                      <i class="fa fa-download download-Citation" (click)="downloadCitation(citationRes?.APA)"></i>
                    </span>
                  </span>
                </label>
                <label id="citationApa" #citationApa class="cslEntry" [ngClass]="{'mycopyCitation' : modelcitaionAPA}"
                  [innerHtml]="citationRes?.APA" *ngIf="!emptyCitation"></label>
                <label *ngIf="emptyCitation"> N/A</label>
              </li>
            </ul>
          </div>
          <div>
            <h4 [class.arabic-style]="isArabicLanguage" class="modal-title illustration-title">{{
              'Detail.exportCitation' | translate }}</h4>
            <div>
              <ul class="exportUl" [ngClass]="{'diasbledBtn' : emptyCitation}">
                <li class="export" (click)="exportRIS(EndNote)">
                  <a class="exportCitation" id="EndNote" #EndNote>
                    <span [class.arabic-style]="isArabicLanguage" class="btn-link">{{ 'Detail.exporttoEndNote' |
                      translate }}</span>
                  </a>
                </li>
                <li class="export" (click)="exportRIS(ProCite)">
                  <a [class.arabic-style]="isArabicLanguage" class="exportCitation" id="ProCite" #ProCite>
                    <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.exportProCite' | translate }}</span>
                  </a>
                </li>
                <li class="export" (click)="exportRIS(ReferenceManager)">
                  <a [class.arabic-style]="isArabicLanguage" class="exportCitation" id="ReferenceManager"
                    #ReferenceManager>
                    <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.exportReferenceManager' | translate }}</span>
                  </a>
                </li>
                <li class="export" (click)="exportRIS(Zotero)">
                  <a [class.arabic-style]="isArabicLanguage" class="exportCitation" id="Zotero" #Zotero>
                    <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.exportZotero' | translate }}</span>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <div [class.arabic-style]="isArabicLanguage" class="float-left text_left">
            {{ 'Detail.exportNote' | translate }}
          </div>
        </div>

      </ng-template>

      <ng-template #languagePageTemplate>

        <div>
          <div class="modal-header">
            <h4 class="modal-title sa-icon sa-warning pulseWarning">
              <span class="sa-body pulseWarningIns"></span>
              <span class="sa-dot pulseWarningIns"></span>
            </h4>

          </div>
          <div class="modal-body search_body">
            <div [class.arabic-style]="isArabicLanguage" class="alert_warning download-status-text">{{
              'Detail.downloadStatus' | translate }}
            </div>
          </div>
          <div class="modal-footer">
            <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" aria-label="Close"
              (click)="closelanguagePageTemplate()">
              {{ 'Detail.closeLanguagePopup' | translate }}
            </button>
          </div>
        </div>



      </ng-template>


      <ng-template #sourceCitationTemplate>
        <div class="modal-header">
          <div class="citation-header padding-0">
            <h4 [class.arabic-style]="isArabicLanguage" class="citation-pageheader"> {{ 'Detail.sourceCitation' |
              translate }}</h4>
          </div>
        </div>
        <div class="modal-body url_body">
          <div class="col-md-12 citation_type">

            <ul>
              <li>
                <label [class.arabic-style]="isArabicLanguage" class="citation_type_header">{{ 'Detail.MLA' | translate
                  }}
                  <span [ngClass]="{'diasbledBtn' : emptyCitation}">
                    <span *ngIf="!isInIpadMode">
                      <i tooltip="{{'Detail.copyClipboard' | translate}}" class="fa fa-files-o copyCitation"
                        aria-hidden="true" (click)="copyCitation(documentData?.citations?.MLA, 'MLA')"></i>
                    </span>
                    <span *ngIf="isInIpadMode">
                      <i class="fa fa-files-o copyCitation" (click)="copyCitation(documentData?.citations?.MLA, 'MLA')"></i>
                    </span>
                  </span>
                </label>
                <label [ngClass]="{'mycopyCitation' : citaionMLA}" id="citationMla" [innerHtml]="documentData?.citations?.MLA"
                  *ngIf="!emptyCitation"></label>
                <label *ngIf="emptyCitation"> N/A</label>
              </li>
              <li>
                <label [class.arabic-style]="isArabicLanguage" class="citation_type_header">{{ 'Detail.CHICAGO' |
                  translate }}
                  <span [ngClass]="{'diasbledBtn' : emptyCitation}">
                    <span *ngIf="!isInIpadMode">
                      <i tooltip="{{'Detail.copyClipboard' | translate}}" class="fa fa-files-o copyCitation"
                        aria-hidden="true" (click)="copyCitation(documentData?.citations?.CHICAGO, 'CHICAGO')"></i>
                    </span>
                    <span *ngIf="isInIpadMode">
                      <i class="fa fa-files-o copyCitation" (click)="copyCitation(documentData?.citations?.CHICAGO, 'CHICAGO')"></i>
                    </span>
                  </span>
                </label>
                <label #citationChicago [ngClass]="{'mycopyCitation' : citaionCHICAGO}" [innerHtml]="documentData?.citations?.CHICAGO"
                  *ngIf="!emptyCitation"></label>
                <label *ngIf="emptyCitation"> N/A</label>
              </li>
              <li>
                <label [class.arabic-style]="isArabicLanguage" class="citation_type_header">{{ 'Detail.APA' | translate
                  }}
                  <span [ngClass]="{'diasbledBtn' : emptyCitation}">
                    <span *ngIf="!isInIpadMode">
                      <i tooltip="{{'Detail.copyClipboard' | translate}}" placement="top" class="fa fa-files-o copyCitation"
                        aria-hidden="true" (click)="copyCitation(documentData?.citations?.APA, 'APA')"></i>
                    </span>
                    <span *ngIf="isInIpadMode">
                      <i placement="top" class="fa fa-files-o copyCitation" (click)="copyCitation(documentData?.citations?.APA, 'APA')"></i>
                    </span>
                  </span>
                </label>
                <label #citationApa [ngClass]="{'mycopyCitation' : citaionAPA}" [innerHtml]="documentData?.citations?.APA"
                  *ngIf="!emptyCitation"></label>
                <label *ngIf="emptyCitation"> N/A</label>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" (click)="closeCitationModal()">
            {{ 'Detail.Close' | translate }}
          </button>
        </div>
      </ng-template>
    </div>
    <ng-container *ngFor="let notes of itemNotes">
      <div *ngIf="notes?.value.toString().length > 100 && isItemsNoteAvailable" class="itemnotes-overlay-parent">
        <button [class.arabic-style]="isArabicLanguage" class="btn btn-primary itemoverlay-btn" (click)="showItemNotesPopup()">
          {{ 'Detail.itemNotes' | translate }} </button>
      </div>
    </ng-container>
  </div>

  <ng-template #warningsTemplate>
    <div class="modal-header warning-modal-header">
      <h4 [class.arabic-style]="isArabicLanguage" class="warnings-headerlbl no-margin"> {{ 'HomePage.warning' |
        translate }} </h4>
    </div>
    <div class="modal-body warning_bdy">
      <ng-container *ngFor="let wo of warningObj;let last = last">
        <h4 class="text-left" translate>{{wo.title}}</h4>
        <div class="warning-message" translate>{{wo.message}}</div>
        <hr *ngIf="!last" class="divider">
      </ng-container>
    </div>

    <div class="modal-footer warnings-footer">
      <button [class.arabic-style]="isArabicLanguage" type="button" appAutofocus class="btn btn-primary" (click)="closeWarningPopup();$event.stopPropagation();">
        {{ 'HomePage.closeBtn' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #itemNotesTemplate>
    <div class="modal-header warning-modal-header">
      <h4 [class.arabic-style]="isArabicLanguage" class="warnings-headerlbl no-margin"> {{ 'Detail.itemNotes' |
        translate }} </h4>
    </div>
    <div class="modal-body itemnote-content">
      <ng-container *ngFor="let notes of itemNotes">
        <div class="documentType">
          <label class="citation-contentlbl">{{notes?.type}}</label>
          <ul *ngFor="let notesValue of notes?.value">
            <li class="citation-content">{{notesValue}}</li>
          </ul>
        </div>
      </ng-container>
    </div>

    <div class="modal-footer warnings-footer">
      <button [class.arabic-style]="isArabicLanguage" [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary"
        (click)="closeItemsNotePopup();$event.stopPropagation();">
        {{ 'HomePage.closeBtn' | translate }}
      </button>
    </div>
  </ng-template>
</div>
<ng-template #downloadTableTemplate>
  <div class="modal-header  download-title">
    <h4 class="modal-title text-center">
      <!-- <span class="spriteico downloadPopupIcon"></span> -->
      <img src="assets/images/icons/download@2x.png " alt="View History" class="center-block downloadPopupIcon" />
    </h4>

  </div>
  <div class="modal-body url_body download-body">
    <div [class.arabic-style]="isArabicLanguage" class="download-text">
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadableUrl}}" target="_blank"
        *ngIf="downloadUrlFor === 'table'">{{ 'Detail.downloadSpreadsheetFile' | translate }}</a>
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadableUrl}}" target="_blank"
        *ngIf="downloadUrlFor === 'pdf'">{{ 'Detail.downloadTextDoc' | translate }}</a>
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadableUrl}}" target="_blank"
        *ngIf="downloadUrlFor === 'fulltext'">{{ 'Detail.downloadFulltext' | translate }}</a>
    </div>
    <div class="modal-download-text download-text-cmt" [class.arabic-style]="isArabicLanguage">
      <span [class.arabic-style]="isArabicLanguage" class="modal-download-text-span"> {{
        'Detail.clicktoDownloadFileMessage' | translate }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary" (click)="closeDownloadTableModal();$event.stopPropagation();">
      {{ 'Detail.Close' | translate }}
    </button>
  </div>
</ng-template>



<!-- explorer coverflow data  -->
<!-- <app-explorer-coverflow [contentType]="contentType" [start]="startValueFromParams" [advancedFilterParamForCoverFlow]="advancedFilterParamForCoverFlow"
  [loadedFrom]="loadedFrom" [documentData]="documentData" [toggleCoverFlow]="toggleCoverFlow" [spatialFilterParams]="spatialFilterParams"
  [hideCoverFlow]="hideCoverFlow"></app-explorer-coverflow> -->
