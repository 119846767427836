import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appDecodeEntities]'
})
export class DecodeEntitiesDirective implements AfterViewInit {

  constructor(
    private el: ElementRef
  ) { }
  ngAfterViewInit() {
    this.el.nativeElement.textContent = this.decodeEntities(this.el.nativeElement.textContent);
    if (this.el.nativeElement.getAttribute('title')) {
      this.el.nativeElement.setAttribute('title', this.decodeEntities(this.el.nativeElement.textContent));
    }
  }
  decodeEntities(str) {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');
    if (str && typeof str === 'string') {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }
      return str;
  }
}
