/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */

import { NgModule } from '@angular/core';
import { OrderPipe } from './ngx-order.pipe';

@NgModule({
  declarations: [OrderPipe],
  exports: [OrderPipe]
})
export class OrderModule {}
