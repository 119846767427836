/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable} from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable ,  Observer } from 'rxjs';
import { PageSettingsService } from './../services/page-settings.service';

@Injectable()
export class PageSettingsResolver implements Resolve<any> {

    constructor(private pageSettingService: PageSettingsService) {}

    resolve(): Observable<any> {
        return new Observable((observer) => {
            observer.next(this.pageSettingService.getPageSettings());
            observer.complete();
		});
    }
}
