import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-update',
  templateUrl: './location-update.component.html',
  styleUrls: ['./location-update.component.css']
})
export class LocationUpdateComponent implements OnInit {

  newUrl: string;
  constructor(private router: Router) {
    const url = window.location.pathname;
    const newUrlAry  = url.split('location');
    const remainUrl = newUrlAry[1].split('/');
    remainUrl.splice(0, 2);
    this.newUrl = newUrlAry[0];
    for (let index = 0; index < remainUrl.length; index++) {
      if (index + 1 !== remainUrl.length) {
        this.newUrl += `${remainUrl[index]}/`;
      } else {
        this.newUrl += `${remainUrl[index]}`;
      }
    }
   }

  ngOnInit() {
    this.router.navigateByUrl(this.newUrl);
  }

}
