import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    AfterViewInit,
    SimpleChanges,
    OnChanges,
    EventEmitter,
    Output,
    HostListener,
    OnDestroy
} from '@angular/core';

declare let WordCloud: any;
declare let $: any;



@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[WordCloud]'
})
export class WordCloudDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() wordData: WordCloudData[];
    @Input() updateWordCloud: any;
    @Input() chartId: string;
    @Input() maxWordCount: number;
    @Output() selectedTerm: EventEmitter<any> = new EventEmitter();
    @Output() isWordCloudLoaded: EventEmitter<any> = new EventEmitter();

    private element: ElementRef;

    public constructor(element: ElementRef) {
        this.element = element;
    }

    ngOnInit() {}

    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.updateWordCloud) {
            if (changes.updateWordCloud.currentValue) {
                // setTimeout(() => {
                    this.update();
                // }, 100);
            }
        }
    }

    update() {
        const classObj = this;
        if (!this.wordData) {
            return;
        }
        if (this.wordData.length === 0) {
            return;
        }
        if ($('#' + this.chartId)) {
            $('#' + this.chartId).empty();
        }
        $('#' + this.chartId).jQWCloud({
            words: this.wordData,
            // cloud_color: 'yellow',
            minFont: 10,
            maxFont: 80,
            // fontOffset: 5,
            cloud_font_family: 'Open Sans, sans-serif',
            verticalEnabled: true,
            padding_left: 1,
            // showSpaceDIV: true,
            // spaceDIVColor: 'black',
            word_common_classes: 'WordClass',
            word_mouseEnter: function () {
                $(this).css('text-decoration', 'underline');
                $(this).css('cursor', 'pointer');
                $(this).attr('title', $(this).text());
            },
            word_mouseOut: function () {
                $(this).css('text-decoration', 'none');
            },
            word_click: function () {
                classObj.searchTermClicked($(this).text());
            },
            beforeCloudRender: function () {
                // date1 = new Date();
            },
            afterCloudRender: function () {
                classObj.isWordCloudLoaded.emit({isLoaded: true, archive: classObj.chartId, random: Math.random()});
                // const  date2 = new Date();
                // console.log('Cloud Completed in ' + (date2.getTime() - date1.getTime()) + ' milliseconds');
            }
        });

    }
    searchTermClicked(term) {
        this.selectedTerm.emit({ term: term, random: Math.random() });
    }

    @HostListener('window:resize', ['$event'])
    onresize(event) {
        this.update();
    }

    ngOnDestroy(): void {
        $('.wordcloud-parentdiv').empty();
    }
}


// export interface WordCloudOptions {
//     settings?: {
//         minFontSize?: number,
//         maxFontSize?: number,
//         fontFace?: string,
//         fontWeight?: string,
//         spiral?: string,
//     };
//     margin?: {
//         top: number,
//         right: number,
//         bottom: number,
//         left: number
//     };
//     labels?: boolean;
// }

export interface WordCloudData {
    word: string;
    weight: number;
    color?: string;
    // constructor(word: string, count: any, maxWordCount: number, color?: string) {
    //     this.word = word;
    //     this.weight = 18;
    //     this.color = color;
    // }
}

