<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<!-- <pre>{{lineChartData | json}}</pre> -->
<div id="chartBySubjectContainer" class="col-md-12" *ngIf="lineChartData.length > 0">
  <span class="chart-label"> {{ 'Common.noOfDocuments' | translate }}</span>
  <!-- <canvas id="searchResultBySubjectChart" #bySubjectChart></canvas> -->
  <canvas baseChart width="400" height="400"
            [datasets]="lineChartData"
            [labels]="xAxisLabel"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [colors]="lineChartColors"
            [chartType]="lineChartType">
        </canvas>
</div>
<div *ngIf="lineChartData.length === 0" class="subject_container">
<p [class.arabic-style]="isArabicLanguage">{{'Common.Noresultsfound' | translate}}</p>
</div>
<div class="clearfix"></div>
<div class="col-md-12 slider-control term-frequency_slider" *ngIf="yearFrom && yearTo">
  <app-range-slider [(ngModel)]="rangeArray" [value]="rangeArray" [minValue]="minYearValue" (RangeChanged)="sliderRangeChanged($event)"
    [maxValue]="maxYearValue" [stepValue]="1" [range]="true" name="rangeArrayN"></app-range-slider>
  <span *ngIf="yearFrom && yearTo">{{yearFrom}} - {{yearTo}}</span>
</div>
