import {  ComponentFactoryResolver,
  Injectable,
  Inject,
  ViewChild,
  ViewContainerRef } from '@angular/core';
  import { SharedService } from './sharedService';
  import { Subject } from 'rxjs';
import { BasicImageViewerComponent } from '../components/basic-image-viewer/basic-image-viewer.component';
@Injectable()
export class BasicImageViewerService {

  componentRef: any;
  rootViewContainer: any;
  factoryResolver: any;
  dynamicComponent: any;
  domContainer: any;
  coverFlowDocumentId = new Subject();
  @ViewChild('detailsDynamicContainer', { static: false, read: ViewContainerRef }) entry: ViewContainerRef;
  constructor(@Inject(ComponentFactoryResolver) factoryResolver, private resolver: ComponentFactoryResolver, 
  private sharedService: SharedService) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  testFunction() {
    return 'Test Test';
  }
  addDynamicComponent(inputObjects, entry) {
    this.domContainer = entry;
    const factory = this.factoryResolver.resolveComponentFactory(BasicImageViewerComponent);
    if (this.domContainer) {
      this.removeComponent();
    }
    this.dynamicComponent = this.domContainer.createComponent(factory);
    // this.dynamicComponent.instance.docIdForDocViewer = inputObjects.docIdForDocViewer;
    // this.dynamicComponent.instance['selected-page'] = inputObjects.activePage;
    // this.dynamicComponent.instance.searchTerm = inputObjects.searchWithInTerm;
    // this.dynamicComponent.instance.illustrationData = inputObjects.illustrationData;
    // this.dynamicComponent.instance.documentData = inputObjects.documentData;
    // this.dynamicComponent.instance.docIdInput = inputObjects.docIdForDocViewer;
    // this.dynamicComponent.instance.invokedFromTab = inputObjects.invokedFromTab;
    // this.dynamicComponent.instance.loadedFrom = inputObjects.loadedFrom;
    // this.dynamicComponent.instance.docViewerClosed.subscribe((res) => {
    //   if (res === false) {
    //     this.removeComponent();
    //   }
    // });
    // this.dynamicComponent.instance.coverFlowDocumentID.subscribe((res) => {
    //  this.removeComponent();
    //  this.coverFlowDocumentId.next(res);
    // });

    // this.dynamicComponent.instance.pagechanged.subscribe((res) => {
    //   this.sharedService.shareImageViewerHighlight(res);
    // });

  }

  isComponentAlreadyExists () {
    if (this.domContainer) {
      return true;
    }
  }


  removeComponent() {
    this.domContainer.clear();
  }

 
}
