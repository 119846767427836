/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {Directive, ElementRef, HostListener, Input, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[viewMore]'
})
export class ViewMoreDirective implements OnInit {
  @Input() inputLength: number;
  div = this.renderer.createElement('div');
  text;
  constructor(
    public elRef: ElementRef,
    private renderer: Renderer2,
  ) {}
  ngOnInit() {
    this. ViewMore();
  }
  @HostListener('click')
  onClick() {
   this. ViewMore();
  }

  viewLess() {
    const parent = this.renderer.parentNode(this.elRef.nativeElement);
    const myCurrentContent: string = parent.innerHTML;
    const length = 200;
    const trimmedString = myCurrentContent.substring(0, length);
    const text = this.renderer.createText(trimmedString);
    this.renderer.appendChild(this.div, text);

    const c =  this.renderer.appendChild(parent, this.div);

  }
  ViewMore() {
    const parent = this.renderer.parentNode(this.elRef.nativeElement);
    const myCurrentContent: string = parent.innerHTML;
    const length = 200;
    const trimmedString = myCurrentContent.substring(0, length);
    const text = this.renderer.createText(trimmedString);
    this.renderer.appendChild(this.div, text);

    const c =  this.renderer.appendChild(parent, this.div);
}

}
