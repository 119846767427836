import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-undermaintenance',
  templateUrl: './undermaintenance.component.html',
  styleUrls: ['./undermaintenance.component.css']
})
export class UndermaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
