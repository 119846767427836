
import {takeUntil} from 'rxjs/operators';
import {
  Component, OnInit, Input, TemplateRef, ViewChild, AfterViewInit,
  ChangeDetectorRef, HostListener, ViewContainerRef, OnDestroy} from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpService } from '../../common/services/http.service';
import { AdvancedFilterParams } from '../../common/models/AdvancedFilterParams';
import { ExplorerService } from '../services/explorer.service';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { SearchService } from '../../common/services/search.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../common/services/modal.service';
// import { PerfectScrollbarDirective, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { environment, APIURLS } from '../../../environments/environment';
import { Subject ,  BehaviorSubject, Subscription } from 'rxjs';
import { SharedService } from '../../common/services/sharedService';
import { BasicImageViewerService } from '../../common/services/basic-image-viewer.service';
// import { BasicImageViewerComponent } from '../../common/components/basic-image-viewer/basic-image-viewer.component';
import { Location } from '@angular/common';
import { UrlUtilityService } from '../../common/services/url-utility.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '../../common/services/google-analytics.service';
import { map as _map } from 'lodash';

declare let $: any;

@Component({
  selector: 'app-photo-explorer',
  templateUrl: './photo-explorer.component.html',
  styleUrls: ['./photo-explorer.component.css'],
  providers: [BasicImageViewerService]
})
export class PhotoExplorerComponent implements OnInit, AfterViewInit, OnDestroy {
  translateValues: any;
  activePage: Boolean = true;
  currentImageId: any;
  hasRelatedImages: Boolean = false;
  private stop$: Subject<void> = new Subject<void>();
  showLimiters: Boolean;
  citationCache: any = {};
  unSubPageSettingsObservable: Subscription;
  pageContentData: any;
  isCitationLoading: Boolean = false;
  documentCitation: any;
  citationContainer: Boolean = false;
  disableNavigations: any;
  showNavigation: any;
  _coordinatesToZoom = { coordinates: [], random: this.getRandomNumber() };
  isNavigationEnabled: Boolean = true;
  loadMoreLoader: Boolean = false;
  isSearchBtnEnable: boolean;
  // @ViewChild('popupRef') popupReference: PopoverDirective;
  @ViewChild('popupRef', { static: false}) popupReference: PopoverDirective;

  pageContent: any;
  resetMapData = { value: true, random: Math.random() };
  readmoreId: Subject<any> = new BehaviorSubject(Math.random());
  totalRecoards: any = 0;
  // @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  // @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  // @ViewChild('PerfectScrollbarComponent', { static: false}) componentRef?: PerfectScrollbarComponent;
  // @ViewChild('PerfectScrollbarDirective', { static: false}) directiveRef?: PerfectScrollbarDirective;

  hightScrollTop: string;
  spatialFilterParams: any;
  // scrollTarget: HTMLElement;
  defaultImage = '/assets/images/loading.gif';
  errorImage = '/assets/images/noimage.jpg';
  loading: boolean;
  searchTerm: string;
  homePageUrl: string;
  isFromPhotoExplorer = { value: true, random: Math.random() };
  advancedParams: any;
  isMapsExplorerExapnd: Boolean = false;
  accessToken: any;
  showLoadingTxt: Boolean = false;
  private photoExplorerTemplatemodalRef: BsModalRef;
  // @ViewChild('photoExplorerTemplate') private photoExplorerTemplate: TemplateRef<any>;
  // @ViewChild('detailsDynamicContainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  @ViewChild('photoExplorerTemplate', { static: false }) private photoExplorerTemplate: TemplateRef<any>;
  @ViewChild('detailsDynamicContainer', { static: false,  read: ViewContainerRef }) private entry: ViewContainerRef;
  private photoLimitTemplatemodalRef: BsModalRef;

  @ViewChild('filterTemplate', { static: false }) private filterTemplate: TemplateRef<any>;
  activeSlideIndex: number;
  currentSlide: any;
  _albums = [];
  copyAdvancedFilterParam: any = {};
  previewImageObj: String;
  private currentZoomLevel = 0.1;
  private viewer: any = null;
  _callZoomOut: { random: number; };
  _callZoomIn: { random: number; };
  toggledisclaimerShow: Boolean = true;
  @Input('limitLabel') limitLabel: any;
  isArabicLanguage: boolean;
  estimatedCount: number;
  limitArchivrDropdown: any;
  enableWiley: boolean;
  set _limitLabel(value) {
    this.limitLabel = value;
  }

  get _limitLabel() {
    return this.limitLabel;
  }

  tilesource: any;
  isFromPage: any;
  mapVisitedCount: any = 0 ;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private bsModalService: BsModalService,
    private httpService: HttpService,
    private explorerService: ExplorerService,
    private searchService: SearchService,
    private modlService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private basicImageViewerService: BasicImageViewerService,
    private location: Location,
    private urlUtilityService: UrlUtilityService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.loading = true;
    setTimeout(() => {
      this.homePageUrl = `/${((PageSettingsService.pageSettings.currentArchive + '').toLowerCase()) || environment.defaultArchive}`;
    });
    const advParam = JSON.parse(sessionStorage.getItem('explorerLimiter'));
    this.limitArchivrDropdown = JSON.parse(sessionStorage.getItem('explorerDropDownLimiter'));
    if (advParam) {
      this.copyAdvancedFilterParam = new AdvancedFilterParams(advParam);
    } else {
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
        const filterParams = params['filter'];
        this.urlUtilityService.parseUrl(filterParams).pipe(takeUntil(this.stop$)).subscribe((url) => {
          if (localStorage.getItem('archivesList')) {
            this.copyAdvancedFilterParam['archive'] = JSON.parse(localStorage.getItem('archivesList'));
          }
          this.copyAdvancedFilterParam = new AdvancedFilterParams(url);
        });
      });
    }
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.pageContentData = data;
    });
    this.translate.get('photoExplorer').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.translateValues = res;
     });
  }

  ngOnInit() {
    // this.scrollTarget = document.getElementById('thumbnailImageWrapper2');
    this.accessToken = this.httpService.getCurrentAccessToken();
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'] || '';
      }
      if (params['spatialFilterParams'] && params['spatialFilterParams'] !== 'undefined') {
        this.spatialFilterParams = JSON.parse(params['spatialFilterParams']);
        setTimeout(() => {
          this.isMapsExplorerExapnd = true;
        }, 2000);
      }
    });
    this.showLimiters = false;
  }
  ngAfterViewInit(): void {
    this.isFromPage = 'photos';
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res['language'] === true) {
        this.popupReference.hide();
      }
      if (res['otherArchives'] === true) {
        this.popupReference.hide();
      }
      if (res['tools'] === true) {
        this.popupReference.hide();
      }
    });
    this.cdRef.detectChanges();
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
      let archives: any = [];
      this.pageContent = Object.assign(data);
      if (this.pageContent.currentArchive.toLowerCase() === 'wiley') {
        archives =  JSON.parse(localStorage.getItem('archivesList'));
      } else {
        archives.push(this.pageContent.currentArchive);
      }
      if(this.pageContent.currentArchive == 'WILEY') {
        this.enableWiley = true;
        var filterArchives;
        let accessedArchives: any = JSON.parse(localStorage.getItem('personalizationData'));
        if(!this.limitArchivrDropdown) {
          filterArchives = accessedArchives.archives;
          for(let k=0;k<filterArchives.length;k++) {
            if (filterArchives[k].shortName == 'wiley') {
              filterArchives.splice(k,1)
            }
          }
          if (accessedArchives.bothRCPAvailable) {
            const rcpObject = {
              'shortName': 'RCP',
              'name': 'The Royal College of Physicians',
              'logo': 'assets/config/rcp/rcp_logo.png',
              'pageTitle': "The Royal College of Physicians",
            };
            filterArchives = filterArchives.filter(item => item.shortName !== 'RCP1');
            filterArchives = filterArchives.filter(item => item.shortName !== 'RCP2');
            filterArchives.push(rcpObject);
          }
          if (accessedArchives.bothRGSAvailable) {
            const rgsObject = {
              'shortName': 'RGS',
              'name': 'The Royal Geographical Society (with IBG)',
              'logo': 'assets/config/rgs/rgs-logo-other.png',
              'pageTitle': "The Royal Geographical Society (with IBG)",
            };
            filterArchives = filterArchives.filter(item => item.shortName !== 'RGS1');
            filterArchives = filterArchives.filter(item => item.shortName !== 'RGS2');
            filterArchives.push(rgsObject)
          }
        } else {
          filterArchives = this.limitArchivrDropdown;
        }
      } else {
        this.enableWiley = false;
        if (this.pageContent.currentArchive === 'RGS') {
          const rgsObject = {
            'shortName': 'RGS',
            'name': 'The Royal Geographical Society (with IBG)',
            'logo': 'assets/config/rgs/rgs-logo-other.png',
            'trialArchive': false
          };
          filterArchives = [rgsObject];
        } else if (this.pageContent.currentArchive === 'RCP') {
          const rcpObject = {
            'shortName': 'RCP',
            'name': 'The Royal College of Physicians',
            'logo': 'assets/config/rcp/rcp_logo.png',
            'trialArchive': false
          };
          filterArchives = [rcpObject];
        } else {
          for(let k=0;k<this.pageContent.wileyArchives.length;k++) {
            if(this.pageContent.wileyArchives[k].shortName === this.pageContent.currentArchive) {
              filterArchives = [this.pageContent.wileyArchives[k]];
            }
          }
        }
      }
      setTimeout(() => {
        this.limitArchivrDropdown = this.removeDuplicate(filterArchives);
      }, 100); 
      if (!sessionStorage.getItem('explorerLimiter')) {
        this.copyAdvancedFilterParam['archive'] = archives;
      }
      // setTimeout(() => {
        this.getPhotoExplorerData(this.searchTerm, '', 'fromInit');
      // }, 500);
    });
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.


    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    // setTimeout(() => {
    //   this.getPhotoExplorerData(this.searchTerm);
    // }, 2000);
    // this.elementRef.nativeElement.select('#thumbnailImageWrapper2')
    //   .addEventListener('scroll', this.onScroll.bind(this));

    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
    this.cdRef.detectChanges();
  }

  removeDuplicate(data) {
		const ids = data.map(o => o.shortName)
		const filtered = data.filter(({shortName}, index) => !ids.includes(shortName, index + 1))
		data = filtered;
		return data;
	}
  receiveAdvancedParam(event) {
    this.copyAdvancedFilterParam = event;
    const searchHistory = {
      searchTerm: this.searchTerm ? this.searchTerm : ''
    };
    this.searchService.buildFilterParams(this.copyAdvancedFilterParam, res => {
      this.searchService.buildURL(res, filterData => {
        searchHistory['filter'] = filterData;
        this.location.replaceState(this.router.createUrlTree([searchHistory], { relativeTo: this.activatedRoute }).toString());
      });
    });
    this.getPhotoExplorerData(this.searchTerm);
    if (this.isMapsExplorerExapnd) {
      // this.componentRef.directiveRef.scrollToY(0, 300);
    } else {
      this.scrollTopPage();
    }
  }
  // removeContentSubType(contentSubType) {
  //   if (this.copyAdvancedFilterParam.docSubType.indexOf(contentSubType) > -1) {
  //     this.copyAdvancedFilterParam.docSubType.splice(this.copyAdvancedFilterParam.docSubType.indexOf(contentSubType), 1);
  //   }
  //   this.copyAdvancedFilterParam.docSubType = this.copyAdvancedFilterParam.docSubType;
  //   sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
  //   this.getPhotoExplorerData(this.searchTerm);

  // }
  // removeContentType(contentType) {
  //   if (this.copyAdvancedFilterParam.contentType.indexOf(contentType) > -1) {
  //     this.copyAdvancedFilterParam.contentType.splice(this.copyAdvancedFilterParam.contentType.indexOf(contentType), 1);
  //   }
  //   this.copyAdvancedFilterParam.contentType = this.copyAdvancedFilterParam.contentType;
  //   sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
  //   this.getPhotoExplorerData(this.searchTerm);
  // }
  // removeLanguages(language) {
  //   const currentSearchSettings = this.copyAdvancedFilterParam;
  //   const currentSelectedContentSubtypes = this.copyAdvancedFilterParam.languages;
  //   if (this.copyAdvancedFilterParam.languages.indexOf(language) > -1) {
  //     this.copyAdvancedFilterParam.languages.splice(this.copyAdvancedFilterParam.languages.indexOf(language), 1);
  //   }
  //   this.copyAdvancedFilterParam.languages = this.copyAdvancedFilterParam.languages;
  //   sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
  //   this.getPhotoExplorerData(this.searchTerm);
  // }
  removeContentSources(source) {
    if (this.copyAdvancedFilterParam.contentSources.indexOf(source) > -1) {
      this.copyAdvancedFilterParam.contentSources.splice(this.copyAdvancedFilterParam.contentSources.indexOf(source), 1);
    }
    this.copyAdvancedFilterParam.contentSources = this.copyAdvancedFilterParam.contentSources;
    if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
      this.copyAdvancedFilterParam.publishedDateFrom = '';
    }
    sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    this.getPhotoExplorerData(this.searchTerm);
    // this.componentRef.directiveRef.scrollToY(0, 300);
  }
  removeContentCollection(collection) {
    if (this.copyAdvancedFilterParam.contentCollection.indexOf(collection) > -1) {
      this.copyAdvancedFilterParam.contentCollection.splice(this.copyAdvancedFilterParam.contentCollection.indexOf(collection), 1);
    }
    this.copyAdvancedFilterParam.contentCollection = this.copyAdvancedFilterParam.contentCollection;
    if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
      this.copyAdvancedFilterParam.publishedDateFrom = '';
    }
    sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    this.getPhotoExplorerData(this.searchTerm);
    // this.componentRef.directiveRef.scrollToY(0, 300);
  }
  removeArchive(archive) {
    // if (this.copyAdvancedFilterParam.archive.indexOf(archive) > -1) {
    //   this.copyAdvancedFilterParam.archive.splice(this.copyAdvancedFilterParam.archive.indexOf(archive), 1);
    // }
    // this.copyAdvancedFilterParam.archive = this.copyAdvancedFilterParam.archive;
    // if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
    //   this.copyAdvancedFilterParam.publishedDateFrom = '';
    // }
    // sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    // this.getPhotoExplorerData(this.searchTerm ? this.searchTerm : '');
    
    if (this.limitArchivrDropdown.indexOf(archive) > -1) {
      this.limitArchivrDropdown.splice(this.limitArchivrDropdown.indexOf(archive), 1);
    }
    let availableArchives = [];
    let accessedArchives: any = JSON.parse(localStorage.getItem('personalizationData'));
    for(let k=0;k< this.limitArchivrDropdown.length; k++) {
      if(this.copyAdvancedFilterParam.archive.includes(this.limitArchivrDropdown[k].shortName)) {
        availableArchives.push(this.limitArchivrDropdown[k].shortName)
      } else if(this.limitArchivrDropdown[k].shortName === 'RCP') {
          if(accessedArchives.bothRCPAvailable) {
            availableArchives.push('RCP1','RCP2')
          }
      } else if(this.limitArchivrDropdown[k].shortName === 'RGS') {
        if(accessedArchives.bothRGSAvailable) {
          availableArchives.push('RGS1','RGS2')
        }
      }
    }
    this.copyAdvancedFilterParam.archive = availableArchives;
    if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
      this.copyAdvancedFilterParam.publishedDateFrom = '';
    }
    sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    sessionStorage.setItem('explorerDropDownLimiter', JSON.stringify(this.limitArchivrDropdown));
    this.getPhotoExplorerData(this.searchTerm ? this.searchTerm : '');
  }


  removeLimiterParams(params) {
    // if (params === 'contentType') {
    //   this.copyAdvancedFilterParam.contentType = [];
    //   sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    //   this.getPhotoExplorerData(this.searchTerm);
    // } else if (params === 'docSubType') {
    //   this.copyAdvancedFilterParam.docSubType = [];
    //   sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    //   this.getPhotoExplorerData(this.searchTerm);
    // } else if (params === 'languages') {
    //   this.copyAdvancedFilterParam.languages = [];
    //   sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    //   this.getPhotoExplorerData(this.searchTerm);
    // } else
    if (params === 'contentSources') {
      this.copyAdvancedFilterParam.contentSources = [];
      if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
        this.copyAdvancedFilterParam.publishedDateFrom = '';
      }
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getPhotoExplorerData(this.searchTerm);
    } else if (params === 'contentCollection') {
      this.copyAdvancedFilterParam.contentCollection = [];
      if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
        this.copyAdvancedFilterParam.publishedDateFrom = '';
      }
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getPhotoExplorerData(this.searchTerm);
    } else if (params === 'publishedDate') {
      this.copyAdvancedFilterParam.publishedDateTo = '';
      this.copyAdvancedFilterParam.publishedDateFrom = '';
      this.copyAdvancedFilterParam.publishedDatePrefix = '';
      this.copyAdvancedFilterParam.publishedDateIncludesNull = false;
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getPhotoExplorerData(this.searchTerm);
    } else if (params === 'archive') {
      this.copyAdvancedFilterParam.archive = [];
      if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
        this.copyAdvancedFilterParam.publishedDateFrom = '';
      }
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getPhotoExplorerData(this.searchTerm);
    }
    // this.componentRef.directiveRef.scrollToY(0, 300);
  }


  openClipedImage(index, image): void {
    if (index === 0) {
      this.disableNavigations = { isDisableNext: false, isDisablePrevious: true, random: Math.random() };
    }
    if (index === this._albums.length - 1) {
      this.disableNavigations = { isDisableNext: true, isDisablePrevious: false, random: Math.random() };
    }
    if (image.relatedImageId !== '') {
      this.hasRelatedImages = true;
      this.disableNavigations = { isDisableNext: true, isDisablePrevious: true, random: Math.random() };
      this.currentImageId = image.pageId;
    } else {
      this.hasRelatedImages = false;
    }
    this.previewImageObj = image;
    this.isNavigationEnabled = false;
    this.activeSlideIndex = index;
    this.readmoreId.next(Math.random());
    this.photoExplorerTemplatemodalRef = this.bsModalService.show(this.photoExplorerTemplate,
      { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large photo-explorer-large' });
    setTimeout(() => {
      this.activeSlideIndex = index;
    }, 300);
    /**
     * DESC: If I go to Photo Explorer from RAI or NYAS specific interface and if I click on the preview button,
     * it clicks through the image and loads up the document and then floats the image viewer on top of it.
     * It seems very random and I think is happening in Wiley interface as well. After this back to explorer fails.
     */
    setTimeout(() => {
      this.isNavigationEnabled = true;
    }, 1000);
    this.toggledisclaimerShow = true;
  }


  // gotoPreviousImage() {
  //   if (this.activeSlideIndex > 0) {
  //     this.activeSlideIndex = (this.activeSlideIndex - 1);
  //     this.previewImageObj = this._albums[this.activeSlideIndex];
  //   }
  // }

  // gotoNextImage() {
  //   if (this.activeSlideIndex < this._albums.length) {
  //     this.activeSlideIndex = (this.activeSlideIndex + 1);
  //     this.previewImageObj = this._albums[this.activeSlideIndex];
  //   }
  // }



  closePopup(): void {
    this.photoExplorerTemplatemodalRef.hide();
    if (this.citationContainer) {
      this.citationContainer = false;
    }
    if (this.hasRelatedImages) {
      this.loadCoverImages(this.previewImageObj, this.currentImageId, 'f');
    }
    this.disableNavigations = { isDisableNext: false, isDisablePrevious: false, random: Math.random() };
  }
  slideChangeEvent(event: number): void {
    this.readmoreId.next(Math.random());
    this.currentSlide = event;
    // setTimeout(() => {
    //   if (this.currentSlide === 0) {
    //     $('.carousel-control-prev-icon').hide();
    //     $('.carousel-control-next-icon').show();
    //   } else {
    //     $('.carousel-control-prev-icon').show();
    //     if (this.currentSlide === this._albums.length - 1) {
    //       $('.carousel-control-next-icon').hide();
    //       $('.carousel-control-prev-icon').show();
    //     } else {
    //       $('.carousel-control-next-icon').show();
    //     }
    //   }
    // }, 0);
  }
  expandMapExplorer() {
    this.isMapsExplorerExapnd = !this.isMapsExplorerExapnd;
    if (!this.isMapsExplorerExapnd) {
      this._albums.forEach(obj => { obj['highlightSelected'] = false; });
      this.spatialFilterParams = '';
    } else {
      if (this.mapVisitedCount === 0) {
        // tslint:disable-next-line:max-line-length
        this.modlService.showNotification('INFO', 'INFO', this.translateValues.drawShapeRectOrCircle || 'Use the <img src="assets/images/icons/map-rect.png" width="25" /> or the <img src="assets/images/icons/map-circle.png" width="25" /> to draw an area to limit the search', 6000);
        this.mapVisitedCount++;
      }
    }
  }
  getPhotoExplorerData(searchTerm, spatialFilterParams?, str?) {
    if(str) {
      const advParam = JSON.parse(sessionStorage.getItem('explorerLimiter'));
      if (advParam) {
        this.copyAdvancedFilterParam = new AdvancedFilterParams(advParam);
      }
    }
    setTimeout(() => {
      this._coordinatesToZoom = { coordinates: [], random: this.getRandomNumber() };
    }, 1500);
    if (this.copyAdvancedFilterParam.archive.length === 0) {
      return true;
    }
    if (this.spatialFilterParams) {
      spatialFilterParams = this.spatialFilterParams;
    }
    this.readmoreId.next(Math.random());
    this.loading = true;
    // this.modlService.showLoader();
    if (searchTerm) {
      searchTerm = searchTerm.replace(/\s\s+/g, '');
      const currentParams: any = [];
      const explorerLimiter = sessionStorage.getItem('explorerLimiter');
      currentParams['searchTerm'] = searchTerm;
      if (explorerLimiter) {
        currentParams['filter'] = explorerLimiter;
      }
      const url = this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString();
      this.location.replaceState(url);
    }
    this.explorerService.getPhotoExplorer(this.copyAdvancedFilterParam, searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        this._albums = res.imageUrls;
        if (this.isMapsExplorerExapnd) {
          // this.componentRef.directiveRef.scrollTo(0, 0, 300);
        } else {
          this.scrollTopPage();
        }
      }
      this.loading = false;
      // this.modlService.hideLoader();
    }, (error) => {
      this.loading = false;
      // this.modlService.hideLoader();
    }, spatialFilterParams);
    // this.componentRef.directiveRef.scrollToY(0, 300);
  }

  onShown() {
    this.photoLimitTemplatemodalRef = this.bsModalService.show(this.filterTemplate,
      { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large photo-explorer-large photo-inset-popup' });
    this.isSearchBtnEnable = true;
    this.isFromPhotoExplorer = { value: true, random: Math.random() };
  }
  closeLimitPopup(fromVal?) {
    if (this.limitArchivrDropdown.length >0 && (fromVal && fromVal.val.archive.length >0)) {
      let latestArchives = [];
      // for(let k=0; k< fromVal.val.archive.length;k++) {
      //   for(let l=0; l< this.limitArchivrDropdown.length;l++) {
      //       if(fromVal.val.archive[k] === this.limitArchivrDropdown[l].shortName) {
      //         latestArchives.push(this.limitArchivrDropdown[l])
      //       }
      //   }
      // }
      const archiveObj = JSON.parse(localStorage.getItem('limitorArchives'));
      archiveObj.forEach((data)=>{
       const checkVal = fromVal.val.archive.includes(data.shortName);
       if(checkVal) {
        delete data['logo'];
        latestArchives.push(data)
       }
      })
      this.limitArchivrDropdown = latestArchives;
      sessionStorage.setItem('explorerDropDownLimiter', JSON.stringify(this.limitArchivrDropdown));
    }
    this.isSearchBtnEnable = false;
    this.photoLimitTemplatemodalRef.hide();
  }

  /* DESC: navigate to document details page
  * Input: document id
  * */
  gotoDocumentDetails(docId: String, pageId: String, index, fromModal?): void {
    if (this.isNavigationEnabled) {
      const isFromPhotoExplorer = true;
      if (fromModal) {
        this.closePopup();
      }
      const data = {};
      this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, (param) => {
        this.searchService.buildURL(param, (url) => {
          data['filterParam'] = url;
          data['searchTerm'] = this.searchTerm || '';
          data['spatialFilterParams'] = JSON.stringify(this.spatialFilterParams) || '';
          data['start'] = index;
          if (pageId) {
            data['pageId'] = pageId;
          }
          data['isFromPhotoExplorer'] = isFromPhotoExplorer;
          this.router.navigate([this.homePageUrl + '/detail/' + docId, data], { relativeTo: this.activatedRoute });
          this.googleAnalyticsService.eventEmitter('View Document', 'Click', 'Document', docId.toString());
        });
      });
      this.citationContainer = false;
      if (this.photoExplorerTemplatemodalRef) {
        this.photoExplorerTemplatemodalRef.hide();
      }
    }
    const CurrentURL = window.location.href.split(window.location.host);
    if (this.searchTerm) {
      this.searchTerm = encodeURIComponent(this.searchTerm);
    }
    sessionStorage.setItem('photoExplorerPageURL', CurrentURL[1] + ';searchTerm=' + (this.searchTerm ? this.searchTerm : ''));
  }
  /* DESC: get spacial co-ordinates/filters from map
  * Input: map coordinates
  * */
  onSpatialSearchEmit(event: any): void {
    this.readmoreId.next(Math.random());
    this.spatialFilterParams = event.spatialFilterParams;
    this.getPhotoExplorerData(this.searchTerm, event.spatialFilterParams);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if ($(window).scrollTop() > 120) {
      this.hightScrollTop = 'true';
    } else {
      this.hightScrollTop = '';
    }
    if (!this.isMapsExplorerExapnd) {
      // In chrome and some browser scroll is given to body tag
      const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      const max = document.documentElement.scrollHeight;
      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if ((pos - 50) === (max - 50)) {
        // Do your action here
        this.loadMoreMaps();
        window.scrollBy(0, -10);
      }
    }
  }

  /*
  * DESC: Load next 100 maps if the scroll reach the bottom
  * */
  loadMoreMaps() {
    this.readmoreId.next(Math.random());
    if (this._albums.length === this.totalRecoards) {
      // this.modlService.showNotification('INFO', 'All the documents have been loaded', 'For the current search criteria');
      return true;
    }
    if (!this.loading) {
      this.loading = true;
      this.loadMoreLoader = true;
      // this.modlService.showLoader();
      this.explorerService.getPhotoExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
        if (res) {
          this.totalRecoards = res.totalRecoards;
          this._albums = this._albums.concat(res.imageUrls);
        }
        this.loading = false;
        this.loadMoreLoader = false;
        // this.modlService.hideLoader();
      }, (error) => {
        this.loading = false;
        this.loadMoreLoader = false;
        // this.modlService.hideLoader();
      }, this.spatialFilterParams, this._albums.length);
    }
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.unSubPageSettingsObservable) {
      this.unSubPageSettingsObservable.unsubscribe();
    }
    this.stop$.next();
    this.stop$.complete();
  }
  resultWrapperScrollHandler(event) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.loadMoreMaps();
      // this.componentRef.directiveRef.scrollToBottom(100);
      // document.getElementById('thumbnailImageWrapper2').scrollBy(0, -100);
    }
  }

  /*
  * Scroll To Top of the page
  */
  scrollTopPage() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    //  $('html,body').animate({ scrollTop: 0 }, 'slow');
  }

  /*
* navigate to map page
* */
  navigateToMap() {
    this.copyAdvancedFilterParam.searchTerm = this.copyAdvancedFilterParam.searchTerm ? this.copyAdvancedFilterParam.searchTerm : '';
    this.router.navigate([`${this.homePageUrl}/explorer/map`, this.copyAdvancedFilterParam]);
  }
  /*
* return random number
*/
  getRandomNumber() {
    return Math.random();
  }

  /*
  * zoom map to available co-ordinates and create marker on it
  * */
  zoomToMap(coordinatesArray: Array<any> = [], pageId): void {
    this.isMapsExplorerExapnd = true;
    setTimeout(() => {
      this._coordinatesToZoom = { coordinates: coordinatesArray, random: this.getRandomNumber() };
    }, 1000);
    this._albums.forEach(obj => {
      if (obj.pageId === pageId) {
        obj['highlightSelected'] = true;
      } else {
        obj['highlightSelected'] = false;
      }
    });
  }
  toggleNavigation(event) {
    this.tilesource = null;
    if (event.value === 'next') {
      if (this.activeSlideIndex < this._albums.length - 1) {
        this.activeSlideIndex = (this.activeSlideIndex + 1);
        this.previewImageObj = this._albums[this.activeSlideIndex];
        this.disableNavigations = { isDisableNext: false, isDisablePrevious: false, random: Math.random() };
        if (this.activeSlideIndex === this._albums.length - 1) {
          this.disableNavigations = { isDisableNext: true, isDisablePrevious: false, random: Math.random() };
        }
      } else {
        this.disableNavigations = { isDisableNext: true, isDisablePrevious: false, random: Math.random() };
      }
      if (this.previewImageObj['relatedImageId'] !== '') {
        this.hasRelatedImages = true;
        this.disableNavigations = { isDisableNext: true, isDisablePrevious: true, random: Math.random() };
        this.currentImageId = this.previewImageObj['pageId'];
      } else {
        this.hasRelatedImages = false;
      }
    } else if (event.value === 'previous') {
      if (this.activeSlideIndex > 0) {
        this.activeSlideIndex = (this.activeSlideIndex - 1);
        this.previewImageObj = this._albums[this.activeSlideIndex];
        this.disableNavigations = { isDisableNext: false, isDisablePrevious: false, random: Math.random() };
        if (this.activeSlideIndex === 0) {
          this.disableNavigations = { isDisableNext: false, isDisablePrevious: true, random: Math.random() };
        }
      } else {
        this.activeSlideIndex = 0;
        this.disableNavigations = { isDisableNext: false, isDisablePrevious: true, random: Math.random() };
      }
      if (this.previewImageObj['relatedImageId'] !== '') {
        this.hasRelatedImages = true;
        this.disableNavigations = { isDisableNext: true, isDisablePrevious: true, random: Math.random() };
        this.currentImageId = this.previewImageObj['pageId'];
      } else {
        this.hasRelatedImages = false;
      }
    }
    if (this.citationContainer) {
      this.citationContainer = false;
    }
  }
  getCitationDetailsFromDocumnentData(docId) {
    if (!this.citationContainer) {
      this.isCitationLoading = false;
    }
    this.documentCitation = [];
    this.citationContainer = !this.citationContainer;
    if (this.citationCache[docId]) {
      this.isCitationLoading = true;
      this.documentCitation = this.citationCache[docId];
      return true;
    }
    this.searchService.getDocumentDetail(docId, (response) => {
      if (response) {
        this.isCitationLoading = true;
        this.citationCache[docId] = response;
        this.documentCitation = response;
        this.getAccessToCitation();
      }
    });
  }
  gotoAuthorDetail(author) {
    const _searchTerm = '';
    if (this.pageContentData.advancedFilterParam.searchKeywords) {
      this.pageContentData.advancedFilterParam.searchKeywords = [{ fieldName: 'authors', fieldValue: author, operator: 'AND' }];
    } else {
      this.pageContentData.advancedFilterParam.searchKeywords = [{ fieldName: 'authors', fieldValue: author, operator: 'AND' }];
    }
    this.citationContainer = false;
    this.photoExplorerTemplatemodalRef.hide();
    this.searchService.backToSearchResult(_searchTerm, this.pageContentData.advancedFilterParam);
  }
  gotoCollectionDetails(collectionId) {
    this.citationContainer = false;
    this.photoExplorerTemplatemodalRef.hide();
    this.router.navigate([this.homePageUrl + '/collection-details'],
      { queryParams: { archive: this.documentCitation['shortName'], id: collectionId, scrollTo: this.documentCitation.wileyId } });
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!($(event.target).is('#documentCitation'))) {
      this.citationContainer = false;
    }
  }
  disclaimerToggle() {
    this.toggledisclaimerShow = !this.toggledisclaimerShow;
    if (this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    } else if (!this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    }
  }
  viewMoreOptionStatus(value) {
    this.toggledisclaimerShow = true;
  }

  toggleLimiters(event) {
    this.showLimiters = !this.showLimiters;
    event.stopPropagation();
  }
  @HostListener('document:click', ['$event']) onclick() {
    this.showLimiters = false;
  }
  /**
   * @description :fetch result`s count for limiter display
   * @param event :form data from limiter
   */
  fetchResultCount(form): void {
    const searchTerm = this.searchTerm ? this.searchTerm.replace(/\s\s+/g, '') : '';
    this.showLoadingTxt = true;
    this.explorerService.getPhotoExplorer(form.form, searchTerm, res => {
      this.showLoadingTxt = false;
      this.estimatedCount = res.totalRecoards;
    }, (error) => {
      this.showLoadingTxt = false;
      this.estimatedCount = 0;
    }, this.spatialFilterParams, null, true);
  }

  /**
   * @description: remove vertical and horizontal scrollbar on IE
   *
   */
  getAccessToCitation() {
    const parentHeight = document.querySelector('.citation-data-container.show-citationContainer').clientHeight;
    const childHeight = document.querySelector('.citation-container').clientHeight;
    if (parentHeight || childHeight) {
      if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
        if (parentHeight === childHeight || parentHeight > childHeight) {
          if (document.querySelector('.ps--active-y')) {
            document.querySelector('.ps--active-y').classList.remove('ps--active-y');
          }
          if (document.querySelector('.ps--active-x')) {
            document.querySelector('.ps--active-x').classList.remove('ps--active-x');
          }
        }
      }
    }
  }
  loadCoverImages(imageObj, imageId, position) {
    this.activePage = !this.activePage;
    const authToken = JSON.parse(localStorage.getItem('auth_token'));
    let imageObject;
    imageObject = imageObj;
    imageObject.pageId = imageId;
    // tslint:disable-next-line:max-line-length
    imageObject.image = `${environment.APIUrl}${APIURLS['fileUrlImage']}${imageObject.docId}/${imageId}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`;
    if (position === 'b') {
      $('.front-image').removeClass('btn-primary').addClass('photo-back-btn');
      $('.back-image').removeClass('photo-back-btn').addClass('btn-primary');
      $('.openseadragon-canvas').addClass('animate-back');
      this.disableNavigations = { isDisableNext: true, isDisablePrevious: true, random: Math.random() };
    } else if (position === 'f') {
      $('.front-image').removeClass('photo-back-btn').addClass('btn-primary');
      $('.back-image').removeClass('btn-primary').addClass('photo-back-btn');
      $('.openseadragon-canvas').removeClass('animate-back').addClass('animate-front');
    }

    setTimeout(() => {
      $('.openseadragon-canvas').removeClass('animate-back');
      $('.openseadragon-canvas').removeClass('animate-front');
    }, 3000);
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.loadCoverImages(this.previewImageObj, this.currentImageId, 'f');
    this.disableNavigations = { isDisableNext: false, isDisablePrevious: false, random: Math.random() };
  }
}
