
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from './../services/http.service';
import { PageSettings } from './../../common/models/pagesettings';
import { AdvancedFilterParams } from './../../common/models/AdvancedFilterParams';
import { ModalService } from './../services/modal.service';
import { environment, APIURLS } from '../../../environments/environment';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../services/sharedService';
import { LangChangeEvent } from '@ngx-translate/core';
const viewListKey = 'documentList';
const pageSettingsStorageKey: String = 'wiley-page-settings';
const searchStorageKey: String = 'wiley-search-settings';

@Injectable()
export class PageSettingsService implements OnDestroy {
	stop$: Subject<any> = new Subject();
	public static pageSettings: PageSettings;
	public static pageSettingsObservable: BehaviorSubject<PageSettings>;
	public static ViewListObservable: BehaviorSubject<any>;
	public static ViewContentObservable: BehaviorSubject<any>;
	public personalizationDataObservable = new Subject();
	translatedValues: any;
	personalizationData = this.personalizationDataObservable.asObservable();
	private i = 0;
	private paramsSub: any;
	private viewList: any;
	private viewListCount: any;
	documentTitle: Boolean = false;
	currentText: any;
	AddContentList = [];
	viewContentCount = 0;

	constructor(
		private httpService: HttpService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private modalService: ModalService,
		private commonService: CommonService,
		private translate: TranslateService,
		private sharedService: SharedService
	) {
		this.viewList = {};
		this.viewListCount = 0;
		PageSettingsService.pageSettings = new PageSettings();
		PageSettingsService.pageSettingsObservable = new BehaviorSubject(PageSettingsService.pageSettings);
		PageSettingsService.ViewListObservable = new BehaviorSubject({
			viewList: {},
			count: 0
		});
		PageSettingsService.ViewContentObservable = new BehaviorSubject({
			viewList: {},
			count: 0
		});
		setTimeout(() => {
			if (localStorage.documentList) {
				this.viewList = JSON.parse(localStorage.documentList);
			}
			Object.keys(this.viewList).forEach((contentType) => {
				this.viewListCount += Object.keys(this.viewList[contentType]).length;
			});
			this.viewContentCount = this.AddContentList.length;
			PageSettingsService.ViewListObservable.next({
				viewList: this.viewList,
				count: this.viewListCount
			});
			PageSettingsService.ViewContentObservable.next({
				viewList: this.AddContentList,
			    count: this.viewContentCount
			});
			
		}, 100);
		// this.translate.get('Common.Popups').subscribe((res: string) => {
		// 	this.translatedValues = res;
		// });
		this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
			this.translatedValues = event.translations['Common']['Popups'];
		});
	}

	initialize(observer): void {
		if (this.paramsSub) {
			this.paramsSub.unsubscribe();
			this.paramsSub = null;
		}
		this.paramsSub = this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
			PageSettingsService.pageSettings.currentLocation = params['col'] ? params['col'] : localStorage.getItem('currentLocation');
			PageSettingsService.pageSettings.currentArchive = params['archive'] ? params['archive'].toUpperCase() : '';
			this.personalize(observer);
		});
	}

	personalize(observer) {
		// this.httpService.get('../assets/config/locations-config.json', (persResponse) => {
		this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(persResponse => {
			if (persResponse) {
				Object.keys(persResponse.locations).forEach((key) => {
					if (key === PageSettingsService.pageSettings.currentArchive) {
						PageSettingsService.pageSettings.backgroundUrl = persResponse.locations[PageSettingsService.pageSettings.currentArchive].backgroundUrl;
						PageSettingsService.pageSettings.description = persResponse.locations[PageSettingsService.pageSettings.currentArchive].description;
						// this.translate.get(persResponse.locations[PageSettingsService.pageSettings.currentArchive].description).takeUntil(this.stop$).subscribe((res: string) => {
						//   PageSettingsService.pageSettings.description = res;
						// });
						PageSettingsService.pageSettings.archiveLogo = persResponse.locations[PageSettingsService.pageSettings.currentArchive].archiveLogo;
						this.translate.get(persResponse.locations[PageSettingsService.pageSettings.currentArchive].pageTitle).pipe(takeUntil(this.stop$)).subscribe((res: string) => {
							PageSettingsService.pageSettings.pageTitle = res;
						});
						// tslint:disable-next-line:max-line-length
						PageSettingsService.pageSettings.hasFolderImage = persResponse.locations[PageSettingsService.pageSettings.currentArchive].hasFolderImage;
						PageSettingsService.pageSettings.availableFields = persResponse.fields;
						PageSettingsService.pageSettings.availableOperators = persResponse.operators;
						PageSettingsService.pageSettings.showAdvancedFilter = false;
						PageSettingsService.pageSettings.searchResultCount = 0;
						const location = PageSettingsService.pageSettings.currentLocation || localStorage.getItem('currentLocation');
						if (location) {
							const personalizationV2  = JSON.parse(localStorage.getItem('personalizationData'));
							if (personalizationV2) {
								this.setPersonalizationData(personalizationV2);
								localStorage.setItem('personalizationData', JSON.stringify(personalizationV2));
								this.setPagesettingService(personalizationV2, persResponse, observer);								
							} else {
								this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] + location, (response) => {
									if (response) {
										this.setPersonalizationData(response);
										localStorage.setItem('personalizationData', JSON.stringify(response));
										this.setPagesettingService(response, persResponse, observer);
									}
								}, (error) => {
									observer.error(error);
							});
							}	
						}
					}
				});
			}
		}, (error) => {
			observer.error(error);
		});
	}
	setPagesettingService(response, persResponse, observer) {
		PageSettingsService.pageSettings.customerLogo = response.logo;
		PageSettingsService.pageSettings.returnUrl = response.returnUrl;
		PageSettingsService.pageSettings.wileyArchives = [];
		PageSettingsService.pageSettings.customerArchives = [];
		PageSettingsService.pageSettings.currentSelectedArchives = [];
		PageSettingsService.pageSettings.currentInstitutionName = response.instuteName;
		PageSettingsService.pageSettings.downloadContentArchives = [];
		const archiveNames = [];
		if (response.archives) {
			response.archives.forEach(element => {
				if (persResponse.locations[element.shortName]) {
					element['logo'] = persResponse.locations[element.shortName].archiveLogo;
					archiveNames.push(element.shortName);

					PageSettingsService.pageSettings.wileyArchives.push(element);
					if (PageSettingsService.pageSettings.currentArchive.toLowerCase() === 'wiley') {
						PageSettingsService.pageSettings.customerArchives.push(element);
					} else {
						PageSettingsService.pageSettings.customerArchives = null;
					}
				}
			});
		}
		if (response.downloadContentArchives) {
			response.downloadContentArchives.forEach(element => {
				if (persResponse.locations[element.shortName]) {
					element['logo'] = persResponse.locations[element.shortName].archiveLogo;
					archiveNames.push(element.shortName);

					PageSettingsService.pageSettings.wileyArchives.push(element);
					if (PageSettingsService.pageSettings.currentArchive.toLowerCase() === 'wiley') {
						PageSettingsService.pageSettings.downloadContentArchives.push(element);
					} else {
						PageSettingsService.pageSettings.downloadContentArchives = null;
					}
				}
			});
		}

		let wileySelectedArchives: any;
		/* TODO: We need to clear 'wileySelectedArchive' while we switch b/w location for default all archives as selected in home page
		*  While we implement location switch feature we need to clear current selected archive on that particluar
		*  location and allow to switch OR keep track of selected archive in each locations
		*
		*  sessionStorage.setItem('wileySelectedArchive', '');
		*/
		if (sessionStorage.getItem('wileySelectedArchive')) {
			wileySelectedArchives = JSON.parse(sessionStorage.getItem('wileySelectedArchive'));
		}
		if (Array.isArray(wileySelectedArchives)) {
			// tslint:disable-next-line:max-line-length
			PageSettingsService.pageSettings.currentSelectedArchives = PageSettingsService.pageSettings.currentSelectedArchives.concat(JSON.parse(localStorage.getItem('wileySelectedArchive')));
		} else {

			PageSettingsService.pageSettings.currentSelectedArchives = archiveNames;
			const selectedArchives = JSON.stringify(archiveNames);
			// sessionStorage.setItem('wileySelectedArchive', selectedArchives);
		}

		let wileyTitle = '';
		let wileyContent = '';
		let wileyName = '';
		let wileyShortName = '';

		this.translate.get('wiley.description.title').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
			wileyTitle = res;
		});

		this.translate.get('wiley.description.content').pipe(takeUntil(this.stop$)).subscribe((content: string) => {
			wileyContent = content;
		});

		this.translate.get('wiley.name').pipe(takeUntil(this.stop$)).subscribe((name: string) => {
			wileyName = name;
		});

		this.translate.get('wiley.shortName').pipe(takeUntil(this.stop$)).subscribe((shortName: string) => {
			wileyShortName = shortName;
		});
		setTimeout(() => {
			const wileyObject = {
				'backgroundUrl': 'assets/config/wiley/wiley_landing_page_banner.png',
				'description': {
					'title': wileyTitle,
					// tslint:disable-next-line:max-line-length
					'content': wileyContent
				},
				'pageTitle': 'Wiley Digital Archives',
				'logo': 'assets/config/wiley/wiley_logo.png',
				'name': wileyName ? wileyName : 'Cross Search all of the Wiley Digital Archives',
				'shortName': wileyShortName ? wileyShortName : 'wiley',
				returnUrl: ''
			};
			PageSettingsService.pageSettings.wileyArchives.unshift(wileyObject);
			const rgs1value = 'RGS1';
			const rgs2value = 'RGS2';
			const rcp1value = 'RCP1';
			const rcp2value = 'RCP2';
			PageSettingsService.pageSettings.wileyArchives = PageSettingsService.pageSettings.wileyArchives.filter(item => item.shortName !== rgs1value);
			PageSettingsService.pageSettings.wileyArchives = PageSettingsService.pageSettings.wileyArchives.filter(item => item.shortName !== rgs2value);
			PageSettingsService.pageSettings.wileyArchives = PageSettingsService.pageSettings.wileyArchives.filter(item => item.shortName !== rcp1value);
			PageSettingsService.pageSettings.wileyArchives = PageSettingsService.pageSettings.wileyArchives.filter(item => item.shortName !== rcp2value);
			const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
			if (getVal) {
				let isavailable = false;
				let isRCPAvailable = false;
				if (Array.isArray(getVal)) {
				  getVal.forEach(element => {
					if (element.shortName === 'RGS1' || element.shortName === 'RGS2') {
					  isavailable = true;
					}
					if (element.shortName === 'RCP1' || element.shortName === 'RCP2') {
					  isRCPAvailable = true;
					}
				  });
				}
				const rgsObject = {
					'logo': 'assets/config/rgs/rgs-logo-other.png',
					'name': 'The Royal Geographical Society (with IBG)',
					'shortName': 'RGS',
					'pageTitle': 'The Royal Geographical Society (with IBG)'
				  };
				const rcpObject = {
				'logo': 'assets/config/rcp/rcp_logo.png',
				'name': 'The Royal College of Physicians',
				'shortName': 'RCP',
				'pageTitle': 'The Royal College of Physicians'
				};
				if (isavailable) {
				PageSettingsService.pageSettings.wileyArchives.push(rgsObject);
				}
				if (isRCPAvailable) {
				PageSettingsService.pageSettings.wileyArchives.push(rcpObject);
				}
			} else {
				if (PageSettingsService.pageSettings.wileyArchives.length > 0) {
					const getArchives = JSON.parse(localStorage.getItem('personalizationData'));
					if (getArchives) {
						let isavailable = false;
						let isRCPAvailable = false;
						if (Array.isArray(getArchives.archives)) {
							getArchives.archives.forEach(element => {
							if (element.shortName === 'RGS1' || element.shortName === 'RGS2') {
							  isavailable = true;
							}
							if (element.shortName === 'RCP1' || element.shortName === 'RCP2') {
							  isRCPAvailable = true;
							}
						  });
						}
						const rgsObject = {
							'logo': 'assets/config/rgs/rgs-logo-other.png',
							'name': 'The Royal Geographical Society (with IBG)',
							'shortName': 'RGS',
							'pageTitle': 'The Royal Geographical Society (with IBG)'
						  };
						const rcpObject = {
						'logo': 'assets/config/rcp/rcp_logo.png',
						'name': 'The Royal College of Physicians',
						'shortName': 'RCP',
						'pageTitle': 'The Royal College of Physicians'
						};
						if (isavailable) {
						PageSettingsService.pageSettings.wileyArchives.push(rgsObject);
						}
						if (isRCPAvailable) {
						PageSettingsService.pageSettings.wileyArchives.push(rcpObject);
						}
					}
				}
			}
			this.removeDuplicate(PageSettingsService.pageSettings);
		}, 600);
		// setTimeout(() => {
		// 	if (PageSettingsService.pageSettings.wileyArchives.length > 5) {
		// 		// PageSettingsService.pageSettings.wileyArchives = PageSettingsService.pageSettings.wileyArchives.slice(1).slice(-5);
		// 	}
		// }, 500);

		// {
		// 	'backgroundUrl': 'assets/config/wiley/wiley_landing_page_banner.png',
		// 	'description': {
		// 		'title': 'WILEY DIGITAL ARCHIVES',
		// 		// tslint:disable-next-line:max-line-length
		// 		'content': 'Wiley Digital Archives is a continuous program of new databases
		// comprised of unique or rare historical primary sources, digitized from leading societies, libraries, and archives around the world,
		// and made accessible in ways that tie directly to research outcomes and educational goals.
		// All Archives are cross-searchable, and contain
		// tools for searching, browsing, analyzing and visualizing primary source content.'
		// 	},
		// 	'pageTitle': 'Wiley Digital Archives',
		// 	'logo': 'assets/config/wiley/wiley_logo.png',
		// 	'name': 'Cross Search all of the Wiley Digital Archives',
		// 	'shortName': 'wiley',
		// 	returnUrl: ''
		// }
		// PageSettingsService.pageSettings.wileyArchives.unshift(wileyObject);

		const accessType = localStorage.getItem('accessType');
		if (accessType !== 'guest') {
			this.populateDropDowns(observer);
		}
	}
	removeDuplicate(data) {
		const ids = data._wileyArchives.map(o => o.shortName)
		const filtered = data._wileyArchives.filter(({shortName}, index) => !ids.includes(shortName, index + 1))
		data._wileyArchives = filtered;
		return data;
	  }
	populateDropDowns(observer) {
		// const data = PageSettingsService.pageSettings.currentArchive.toUpperCase() === 'WILEY' ?
		// 	PageSettingsService.pageSettings.currentSelectedArchives : [PageSettingsService.pageSettings.currentArchive];
		let data;
		const copydata = [];
		const currentSelectedArchive = PageSettingsService.pageSettings.currentArchive;
		if (currentSelectedArchive !== 'WILEY' && currentSelectedArchive !== 'RGS' &&  currentSelectedArchive !== 'RCP') {
			copydata.push(currentSelectedArchive);
			data = copydata;
		} else {
			if (localStorage.getItem('archivesList')) {
				data = JSON.parse(localStorage.getItem('archivesList'));
			} else {
				const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
				if (personalizationData) {
					personalizationData.archives.map(archive => {
						copydata.push(archive.shortName);
					});
					data = copydata;
				}
			}
		}

		this.commonService.getPagesettingsDropdownLimiters(data, res => {
				this.parseDataForDropdowns(res, observer);
			}, (error) => {
				observer.error(error);
			});
	}


	parseDataForDropdowns (response, observer) {
		
		if (!response) {
			return false;
		}
		if (response.contentTypes) {
			// response.contentTypes.forEach((element, index) => {
				PageSettingsService.pageSettings.availableContentTypes = response.contentTypes.map(function (contentTypeElement, contentTypeIndex) {
					return {
						id: contentTypeElement.value,
						name: contentTypeElement.name
					};
				});
			// });
		}
		if (response.contentSubTypes) {
			// response.contentSubTypes.forEach((element, index) => {
				PageSettingsService.pageSettings.availableContentSubTypes = response.contentSubTypes.map(function
					(contentSubTypeElement, contentSubTypeIndex) {
					return {
						id: contentSubTypeElement.value,
						name: contentSubTypeElement.name
					};
				// });
			});
		}
		if (response.pubTitles) {
			// response.pubTitles.forEach((element, index) => {
				PageSettingsService.pageSettings.availablePublicationTitles = response.pubTitles.map(function (pubDateElement, pubDateIndex) {
					return {
						id: pubDateElement.value,
						name: pubDateElement.name
					};
				});
			// });
		}
		if (response.collections) {
			// response.collections.forEach((element, index) => {
				PageSettingsService.pageSettings.availableCollections = response.collections.map(function (collectionElement, collectionIndex) {
					return {
						id: collectionElement.value,
						name: collectionElement.name
					};
				});
			// });
		}
		if (response.sources) {
			// response.sources.forEach((element, index) => {
				PageSettingsService.pageSettings.availableSources = response.sources.map(function (sourcesElement, sourcesIndex) {
					return {
						id: sourcesElement.value,
						name: sourcesElement.name
					};
				// });
			});
		}
		if (response.language) {
			// response.language.forEach((element, index) => {
				PageSettingsService.pageSettings.availableLanguages = response.language.map(function (languageElement, languageIndex) {
					return {
						id: languageElement.value,
						name: languageElement.name
					};
				// });
			});
		}
		if (response.pubEndDate && response.pubStartDate) {
			PageSettingsService.pageSettings.pubDates = {
				startDate: response.pubStartDate,
				endDate: response.pubEndDate
			};
		}
		if (response.subject) {
			PageSettingsService.pageSettings.availableSubject = response.subject.map(function (subjectElement, subjectIndex) {
				return {
					id: subjectElement.value,
					name: subjectElement.name
				};
			});
		}
		if (response.illustrationTypes) {
			PageSettingsService.pageSettings.availableIllustrationTypes = response.illustrationTypes
				.map(function (illustrationTypesElement, illustrationTypesIndex) {
					illustrationTypesElement.name = illustrationTypesElement.name.replace('_', ' ');
					illustrationTypesElement.name = illustrationTypesElement.name.replace('-', ' - ');
					illustrationTypesElement.name = illustrationTypesElement.name.replace('nonEnglish', 'Non-English');
				return {
					id: illustrationTypesElement.value,
					name: illustrationTypesElement.name
				};
			});
		}
		if (observer) {
			observer.next(PageSettingsService.pageSettings);
		}
		PageSettingsService.pageSettingsObservable.next(PageSettingsService.pageSettings);
	}





	public refreshPageSettings() {
		return new Observable((observer) => {
			this.initialize(observer);
		});
	}

	public updateSetting(key: string, value: any) {
		if (Array.isArray(PageSettingsService.pageSettings[key])) {
			PageSettingsService.pageSettings[key].length = 0;
			if (Array.isArray(value)) {
				value.forEach((element) => {
					PageSettingsService.pageSettings[key].push(element);
				});
			}
		} else {
			PageSettingsService.pageSettings[key] = JSON.parse(JSON.stringify(value));
		}
		setTimeout(() => {
			PageSettingsService.pageSettingsObservable.next(PageSettingsService.pageSettings);
		});
	}

	public getPageSettings() {
		return PageSettingsService.pageSettings;
	}

	public updatePageSettings(obj: PageSettings) {
		PageSettingsService.pageSettings = obj;
		PageSettingsService.pageSettingsObservable.next(PageSettingsService.pageSettings);
	}

	public updateSearchSettings(obj: AdvancedFilterParams) {
		PageSettingsService.pageSettings.advancedFilterParam = new AdvancedFilterParams(JSON.parse(JSON.stringify(obj)));
		PageSettingsService.pageSettingsObservable.next(PageSettingsService.pageSettings);
	}

	addToViewList(document, group, index?) {
		if (!this.translatedValues) {
			this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
				this.translatedValues = res;
			});
		}
		this.viewList = JSON.parse(localStorage.documentList);
		this.currentText = '';
		if (this.viewList[group]) {
			if (document.title.length > 45) {
				this.currentText = document.title.substring(0, 45) + '...';
				this.documentTitle = true;
			} else {
				this.currentText = document.title;
			}
			const indexOfDocID = this.getJSONKeys(this.viewList[group]).indexOf(document.id);
			if (indexOfDocID > -1) {
				delete this.viewList[group][document.id];
				if (Object.keys(this.viewList[group]).length === 0) {
					delete this.viewList[group];
				}
				// tslint:disable-next-line:max-line-length
				this.modalService.showNotification('SUCCESS', `${this.translatedValues.removeList}`, `<span class="titleRemovedFromList">${this.currentText}</span><br/>${this.translatedValues.removeList}`, 3000);
				this.sharedService.setList('true');
			} else {
				this.viewList[group][document.id] = {
					docID: document.id,
					docTitle: document.title,
					citation: document.citation,
					thumbnails: document.imageThumbnails,
					archive: document.archive || '',
					index: index
				};
				// tslint:disable-next-line:max-line-length
				this.modalService.showNotification('SUCCESS', `${this.translatedValues.addtoList}`, `<span class="docTitle">${this.currentText}</span><br/>${this.translatedValues.useToolstoView}`, 3000);
				this.sharedService.setList('true');
			}
		} else {
			if (document.title.length > 45) {
				this.currentText = document.title.substring(0, 45) + '...';
				this.documentTitle = true;
			} else {
				this.currentText = document.title;
			}
			this.viewList[group] = {};
			this.viewList[group][document.id] = {
				docID: document.id,
				docTitle: document.title,
				citation: document.citation,
				thumbnails: document.imageThumbnails,
				archive: document.archive || '',
				index: index
			};
			if (document.title.length > 50) {
				this.documentTitle = true;
			}
			// tslint:disable-next-line:max-line-length
			this.modalService.showNotification('SUCCESS', `${this.translatedValues.addtoList}`, `<span class="docTitle">${this.currentText}</span><br/>${this.translatedValues.useToolstoClipImageView}`, 3000);
			this.sharedService.setList('true');
		}
		this.viewListCount = 0;
		Object.keys(this.viewList).forEach((contentType) => {
			this.viewListCount += Object.keys(this.viewList[contentType]).length;
		});
		this.viewList = this.viewList instanceof Array ? this.viewList = {} : this.viewList;
		localStorage.setItem(viewListKey, JSON.stringify(this.viewList));
		PageSettingsService.ViewListObservable.next({
			viewList: this.viewList,
			count: this.viewListCount
		});

	}

	removeFromViewList(document, contentType) {
		this.viewList = JSON.parse(localStorage.documentList);
		if (this.viewList[contentType]) {
			this.currentText = '';
			if (document.docTitle.length > 45) {
				this.currentText = document.docTitle.substring(0, 45) + '...';
				this.documentTitle = true;
			} else {
				this.currentText = document.docTitle;
			}
			if (this.viewList[contentType][document.docID]) {
				delete this.viewList[contentType][document.docID];
				this.viewListCount = 0;
				Object.keys(this.viewList).forEach((contentTypeKey) => {
					this.viewListCount += Object.keys(this.viewList[contentTypeKey]).length;
					if (Object.keys(this.viewList[contentTypeKey]).length === 0) {
						delete this.viewList[contentTypeKey];
					}
				});
				localStorage.setItem(viewListKey, JSON.stringify(this.viewList));
				this.modalService.showNotification('SUCCESS', this.translatedValues.removeList, this.currentText + this.translatedValues.removeList);
			}
			PageSettingsService.ViewListObservable.next({
				viewList: this.viewList,
				count: this.viewListCount
			});
		}
	}
	storeViewContenVal(docs) {
		this.AddContentList = docs;
	}
	addToViewContent(document, group?, index?) {
		const viewConts = JSON.parse(localStorage.getItem('contntList'));
		if(viewConts) {
		  if (viewConts.count === 10) {
			localStorage.setItem('haveCount', 'true');
			this.modalService.showNotification('WARNING', "WARNING", 'You can select up to 10 documents at a time.', 5000);
			  return;
		  }
		} else {
			this.AddContentList = [];
		}

		if (!this.translatedValues) {
			this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
				this.translatedValues = res;
			});
		}
		const data = {
			docId: document.id,
			title: document.title
		}
		this.AddContentList.push(data)
		this.viewContentCount = this.AddContentList.length;
		PageSettingsService.ViewContentObservable.next({
			viewList: this.AddContentList,
			count: this.viewContentCount
		});
		const currentText = document.title;
		this.modalService.showNotification('SUCCESS', `${this.translatedValues.addtoContent}`, `<span class="titleRemovedFromList">${currentText}</span>`, 3000);

		localStorage.setItem('contntList', JSON.stringify({viewList: this.AddContentList,count: this.viewContentCount}));
	}
	removeToViewContent(document, group) {
		if (!this.translatedValues) {
			this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
				this.translatedValues = res;
			});
		}
		let index = this.AddContentList.findIndex(res => res.docId == document.id);
		this.AddContentList.splice(index, 1)
		this.viewContentCount = this.AddContentList.length;
		PageSettingsService.ViewContentObservable.next({
			viewList: this.AddContentList,
			count: this.viewContentCount
		});
		const currentText = document.title;
		this.modalService.showNotification('SUCCESS', `${this.translatedValues.removeContent}`, `<span class="titleRemovedFromList">${currentText}</span>`, 3000);
		localStorage.setItem('contntList', JSON.stringify({viewList: this.AddContentList,count: this.viewContentCount}));

	}
	clearViewContent() {
		PageSettingsService.ViewContentObservable.next({
		viewList: {},
		count: 0
		});
		PageSettingsService.ViewContentObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
		})
	}
	getJSONKeys(obj) {
		if (typeof obj === 'object') {
			return Object.keys(obj);
		} else {
			return [];
		}
	}

	setPersonalizationData(personalizationData: any): any {
		this.personalizationDataObservable.next(personalizationData);
	}
	ngOnDestroy() {
		this.stop$.next();
		this.stop$.complete();
	}
}
