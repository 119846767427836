<!--  Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->
<div #imageEditorContainer [ngStyle]="{display:isEditorInitialized ? 'block':'none'}" class="set-full-width" style=" width: 100%;">
  <div #toolbarContainer class="row margin-0 image-editor-toolbar-container" style="width:100% !important">
    <div class="col-md-10 image-editor-toolbar">
      <ul class="padding-0 iomageviewer-menubar-nav">
      </ul>
    </div>
  </div>

  <div #viewerContainer class="col-md-12 margin-0 image-editor-main-container">
    <div class="search-content-toggle">
      <div class="row margin-0 padding-0">
      </div>
      <div #editorContainer class="col-md-12 col-sm-12 editorContainer" (mouseenter)="floatingToolbarMouseEnter()" (mouseleave)="floatingToolbarMouseLeave()"
        [ngClass]="{'fullWidthInIpad': fullWidthInIpad}" [ngStyle]="{'display': isInListViewMode ? 'none' : ''}" [class.fullScreenContainer]="isInFullScreenMode">
        <div class="floating-toolbar-menu" [class.showMenu]="showFloatingMenu || hoverFloatingMenu">
          <!-- hamburger menu -->
          <li class="image-viewer-flip-list" (click)="openImageviewerMenu();$event.stopPropagation();" *ngIf="isShowFloatIcons">
            <span title="{{ 'Detail.imageViewerMenu' | translate }}" class="fa fa-ellipsis-h imageviewer-float-image image-viewer-more-optn"></span>
            <ul (click)="$event.stopPropagation();" class="dropdown-menu dropdown-menu-right imageviewer-menubar-body" role="menu" [ngStyle]="{'display': isImageviewerMenuOpen ? 'block': 'none'}">
              <li *ngIf="isShowFloatIcons && !isSharedDocument" class="image-viewer-flip-list image-viewvew-list" (click)="selectImage()">
                <span class="crop-image-icon imageviewer-float-image" title="{{ 'Detail.crop' | translate }} ({{shortcutKeyTitle}} + Insert)"></span>
              </li>
              <li *ngIf="options.showExpand && showFittoHeight && isShowFloatIcons" class="image-viewer-flip-list image-viewvew-list" title="{{ 'Detail.fittoHeight' | translate }}"
                (click)="fitImageToWidth(false)">
                <span class="spriteico fittoHeight-icon imageviewer-float-image"></span>
              </li>
              <li *ngIf="options.showExpand && !showFittoHeight && isShowFloatIcons" class="image-viewer-flip-list image-viewvew-list"
                title="{{ 'Detail.fittoWidth' | translate }}" (click)="fitImageToWidth(true)">
                <span class="spriteico fittoWidth-icon imageviewer-float-image"></span>
              </li>
              <li *ngIf="options.showRotate " class="image-viewer-flip-list  image-viewvew-list" (click)="rotateImage(-1)">
                <span class="spriteico rotateleft-flipicon imageviewer-float-image" title="{{ 'Detail.rotateLeft' | translate }} ({{shortcutKeyTitle}} + Left Arrow)"></span>
              </li>
              <li *ngIf="options.showRotate" class="image-viewer-flip-list  image-viewvew-list" (click)="rotateImage(1)">
                <span class="spriteico rotateright-flipicon  imageviewer-float-image" title="{{ 'Detail.rotateRight' | translate }} ({{shortcutKeyTitle}} + Right Arrow)"></span>
              </li>
              <li class="reset-button image-viewvew-list" (click)="resetImage()">
                <span class="spriteico resetIcon imageviewer-float-image" title="{{ 'Detail.reset' | translate }}"></span>
              </li>
            </ul>
          </li>

          <li *ngIf="options.showFullScreen && !isInFullScreenMode && isShowFloatIcons" id="toggleFullScreenButton" title="{{ 'Detail.fullScreen' | translate }} ({{shortcutKeyTitle}} + Up Arrow)"
            class="image-viewer-flip-list" (click)="browserFullScreen(true)">
            <span class="spriteico fullScreen-icon imageviewer-float-image"></span>
          </li>
          <li *ngIf="isInFullScreenMode && isShowFloatIcons" id="toggleFullScreenButton" class="image-viewer-flip-list" title="{{ 'Detail.smallScreen' | translate }} ({{shortcutKeyTitle}} + Up Arrow)"
            (click)="browserFullScreen(true)">
            <span class="spriteico smallScreen-icon imageviewer-float-image"></span>
          </li>


          <li class="brightness-button image-viewer-flip-list" *ngIf="!isInListViewMode && isSessionGuest != 'guest' && isShowFloatIcons"
            (click)="openBrightnessMenu()">
            <span class="spriteico brightness-icon imageviewer-float-image " title="{{ 'Detail.brightnessandReset' | translate }}"></span>
            <ul (click)="$event.stopPropagation();" class="dropdown-menu dropdown-menu-left brightness-menubar-body" role="menu" [ngStyle]="{'display': isBrightnessMenuOpen ? 'block': 'none'}">
              <h4 class="brightness-menubar-title">{{ 'Detail.brightnessandReset' | translate }}</h4>
              <form #imageToolbarForm="ngForm" class="brightnessControl">
                <div class="brightness-control imgviewer-brightness-control">
                  <label>{{ 'Detail.Brightness' | translate }}</label>
                  <div class="btn-group img-viewer-btn-group">
                    <app-range-slider name="brightnessControl" (RangeChanged)="adjustBrightness($event)" [minValue]="showDragonViewer ? -255 : -1"
                      [maxValue]="showDragonViewer ? 255 : 1" [stepValue]="showDragonViewer ? 1 : 0.01" title="{{ 'Detail.Brightness' | translate }} Increase({{shortcutKeyTitle}} + '>')/ Decrease({{shortcutKeyTitle}} + '<') "
                      [(ngModel)]="currentBrightness"></app-range-slider>
                  </div>
                </div>
                <div class="imgviewer-contrast-control contrast-control">
                  <label class="imgviewer-label">{{ 'Detail.Contrast' | translate }}</label>
                  <div class="btn-group img-viewer-btn-group">
                    <app-range-slider name="contrastControl" (RangeChanged)="adjustContrast($event)" [minValue]="showDragonViewer ? -5 : -1"
                      [stepValue]="showDragonViewer ? 1 : 0.01" [value]="1" [maxValue]="showDragonViewer ? 5 : 1" title="{{ 'Detail.Contrast' | translate }}"
                      [(ngModel)]="currentContrast"></app-range-slider>
                  </div>
                </div>
                <div class="invert-control">
                  <label class="imgviewer-label" title=" {{ 'Detail.Negative' | translate }} ({{shortcutKeyTitle}} + N)">
                    <input id="invertChkbox" type="checkbox" name="isInvertedChkBox" [(ngModel)]="isInverted" (change)="negativeChanged()" /> {{ 'Detail.Negative' | translate }}
                  </label>
                </div>
              </form>
              <div class="float-right reset_btn">
                <button class="btn btn-xs btn-primary imgviewer-reset-btn reset-mr-2" (click)="resetToolbar()">{{ 'Detail.reset' | translate }}</button>
                <button class="btn btn-xs btn-primary imgviewer-reset-btn" (click)="closeBrightnessToolbar()">{{ 'Common.Close' | translate }}</button>
              </div>
            </ul>
          </li>

          <!-- <li *ngIf="isShowFloatIcons && !isSharedDocument" class="image-viewer-flip-list" (click)="selectImage()">
            <span class="crop-image-icon imageviewer-float-image" title="{{ 'Detail.crop' | translate }} ({{shortcutKeyTitle}} + Insert)"></span>
          </li>
          <li *ngIf="!isShowFloatIcons" class="image-viewer-flip-list" (click)="selectImage()" >
            <span class="crop-image-icon selected imageviewer-float-image" title="{{ 'Detail.crop' | translate }} ({{shortcutKeyTitle}} + Insert)"></span>
          </li> -->




          <li *ngIf="options.showDownload && isShowFloatIcons" class="download-button image-viewer-flip-list" (click)="downloadAsPDF()">
            <span class="spriteico downloadPDFIcon imageviewer-float-image" title="{{ 'Detail.downloadAsPDF' | translate }}  ({{controlShortcut}} + D)"></span>
            <ul class="dropdown-menu dropdown-menu-left brightness-menubar-body dropdown-menu-position" role="menu" [ngStyle]="{'display': isDownloadMenuOpen ? 'block': 'none'}"
              (click)="$event.stopPropagation();">
              <h4 class="brightness-menubar-title">{{ 'Detail.downloadAsPDF' | translate }}</h4>
              <div class="download-button-brightness-control brightness-control">
                <label class="radio-inline showall-container download_img" (click)="clearSelectPage(downloadImagesModel)">
                  <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel" [ngModelOptions]="{standalone:true}" [value]="'all-pages'"
                    (ngModelChange)="downloadImagesPDF($event)" checked>{{ 'Detail.allPages' | translate }}
                  <span class="showall-checkmark"></span>
                </label>
                <label class="radio-inline showall-container download_img" (click)="clearSelectPage(downloadImagesModel)">
                  <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel" [ngModelOptions]="{standalone:true}" [value]="'current-page'"
                    (ngModelChange)="downloadImagesPDF($event)">{{ 'Detail.currentPage' | translate }}
                  <span class="showall-checkmark"></span>
                </label>
                <label class="radio-inline showall-container download_img" (click)="clearSelectPages()">
                  <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel" [ngModelOptions]="{standalone:true}" [value]="'selected-page'"
                    (ngModelChange)="downloadImagesPDF($event)">{{ 'Detail.selectPages' | translate }}
                  <span class="showall-checkmark"></span>
                </label>
                <input *ngIf="downloadImagesModel == 'selected-page'" [(ngModel)]="pagesToDownload" (ngModelChange)="selectPageChanges()"
                  [ngModelOptions]="{standAlone:true}" class="downloadimage-txtbox" type="text" value="" placeholder="1,5-7,9,11-12">
                <div class="error text-danger errorMsg">{{errorMsg}}</div>
                <div class="text-center downloadbtn-div">
                  <button class="btn btn-primary btn-download" [disabled]="isDownloadingInProgress == true || isInvalidPageNumber == true"
                    (click)="downloadImageToPDF()">{{isDownloadingInProgress === true ? downloadingText : downloadText}} </button>
                </div>
              </div>
            </ul>
          </li>
          <li *ngIf="options.showPrint && isShowFloatIcons" class="print-button image-viewer-flip-list" (click)="printImageMenu()">
            <span class="spriteico print-icon imageviewer-float-image" title="{{ 'Detail.printImage' | translate}} ({{shortcutKeyTitle}} + O)"></span>
            <ul class="dropdown-menu dropdown-menu-left brightness-menubar-body dropdown-menu-position" role="menu" [ngStyle]="{'display': isPrintMenuOpen ? 'block': 'none'}"
              (click)="$event.stopPropagation();">
              <h4 class="brightness-menubar-title">{{ 'Detail.printImage' | translate }}</h4>
              <div class="image-viewer-brightness-control brightness-control">
                <label class="radio-inline showall-container download_img" (click)="clearSelectPage(printImageModel)">
                  <input type="radio" name="printImages" [ngModel]="printImageModel" [ngModelOptions]="{standalone:true}" [value]="'all-pages'"
                    (ngModelChange)="setPrintImageModel($event)">{{ 'Detail.allPages' | translate }}
                  <span class="showall-checkmark"></span>
                </label>
                <label class="radio-inline showall-container download_img" (click)="clearSelectPage(printImageModel)">
                  <input type="radio" name="printImages" [ngModel]="printImageModel" [ngModelOptions]="{standalone:true}" [value]="'current-page'"
                    (ngModelChange)="setPrintImageModel($event)">{{ 'Detail.currentPage' | translate }}
                  <span class="showall-checkmark"></span>
                </label>
                <label class="radio-inline showall-container download_img" (click)="clearSelectPages()">
                  <input type="radio" name="printImages" [ngModel]="printImageModel" [ngModelOptions]="{standalone:true}" [value]="'selected-page'"
                    (ngModelChange)="setPrintImageModel($event)">{{ 'Detail.selectPages' | translate }}
                  <span class="showall-checkmark"></span>
                </label>
                <input *ngIf="printImageModel == 'selected-page'" [(ngModel)]="pagesToPrint" [ngModelOptions]="{standAlone:true}" (ngModelChange)="selectPageChanges()"
                  class="downloadimage-txtbox" type="text" value="" placeholder="1,5-7,9,11-12">
                <div class="error text-danger errorMsg">{{errorMsg}}</div>
                <div class="text-center downloadbtn-div">
                  <button class="btn btn-primary btn-download" [disabled]="isPrintInProgress == true || isInvalidPageNumber == true" (click)="printImage()">
                    {{isPrintInProgress === true ? retrievingText : printText}} </button>
                </div>
              </div>
            </ul>
          </li>




          <li *ngIf="isShowFloatIcons" class="image-viewer-flip-list" id="image-viewer-zoom-in" (click)="zoomImage(1)">
            <span class="spriteico zoomFlipIcon imageviewer-float-image" title="{{ 'Detail.zoomIn' | translate }} ({{shortcutKeyTitle}} + '=')"></span>
          </li>
          <li *ngIf="options.showZoom && isShowFloatIcons" class="image-viewer-flip-list" id="image-viewer-zoom-out" (click)="zoomImage(-1)">
            <span class="spriteico zoomout-flipicon imageviewer-float-image" title="{{ 'Detail.zoomOut' | translate }} ({{shortcutKeyTitle}} + '_')"></span>
          </li>
          <li *ngIf="!isShowFloatIcons" class="image-viewer-flip-list" (click)="selectImage()">
            <span class="crop-image-icon selected imageviewer-float-image" title="{{ 'Detail.crop' | translate }} ({{shortcutKeyTitle}} + Insert)"></span>
          </li>

        </div>
        <span *ngIf="currentSelectedImageIndex > 0 && !isImageviewerMenuOpen" class="fa-3x fa fa-arrow-circle-left left-fa-arrow"
          (click)="loadPreviousImage()" title="{{ 'Detail.pageUp' | translate }}"></span>
        <dragon-image-viewer [itemTitle]="itemTitle" [currentDocumentID]='currentPagedocID' [image]="currentSelectedImage" [brightness]="currentBrightness"
          [contrast]="currentContrast" [invert]="isInverted" [restoreImage]="restoreOriginalImage" [fitToWidth]="fitToWidth"
          [angle]="currentAngle" [resized]="isPageResized" (afterPageLoaded)="afterPageLoaded($event)" (overlayClicked)="onOverlayClick($event)"
          (zoomResetEmit)="zoomReset($event)" [docIdInput]="defaultPage" (highlighthide)="highlighthide($event)" [ngClass]="{'toggleHighlightOn' : !toggleHighlighter, 'toggleHighlightOff' : toggleHighlighter, 'enableOverlay' : !enableOverlay , 'disableOverlay' : enableOverlay}"
          class="highlightOn" [listOfImages]="listOfImages" [documentImages]="documentTitleImages" [selection]="selection"
          [screenMode]="isInFullScreenMode" (selectionPerformed)="selectionPerformed($event)" [currentOverlay]="currentPageOverlay"
          [documentType]="currentDocumentType" [zoomIn]="_callZoomIn" [zoomOut]="_callZoomOut" [invokedFrom]="invokedFrom"
          [searchTermOverlay]="searchTermOverlay" [downloadUrlFor]="urlForDownload" [downloadUrl]="urlToDownload" [fuzzySearch]="fuzzySearch" [showOverlayValue]="showOverlayValue">
        </dragon-image-viewer>
        <span *ngIf="currentSelectedImageIndex + 1 !== listOfImages?.length && !isImageviewerMenuOpen" class="fa-3x fa fa-arrow-circle-right right-fa-arrow"
          (click)="loadNextImage()" title="{{ 'Detail.pageDown' | translate }}"></span>

        <div class="mobileEditorToolbar">
          <div class="mobileEditorDropdown" [ngStyle]="{'display': showEditorMenu ? 'block': 'none'}">
            <ul class="padding-0" [ngClass]="{'brightnessPopup': changeBrightness}">
              <li class="resetButtonLi">
                <span class="resetbutton" title="{{ 'Detail.reset' | translate }}" (click)="resetImage()">{{ 'Detail.reset' | translate }}</span>
              </li>
              <li [ngClass]="{'effect-active': selection}" *ngIf="!isInListViewMode  && !isInFullScreenMode">
                <span class="" [ngClass]="{'i-crop-image': !selection, 'i-crop-image-active': selection }" (click)="selectImage()" title="{{ 'Detail.crop' | translate }}"></span>
              </li>

              <li [popover]="imageToolbar" #imageToolbarPopup [placement]="'left'" popoverTitle="Toolbar" [outsideClick]="true" (click)="brightnessUpdate(editImage)">
                <span class="i-edit-image" title="{{ 'Detail.brightnessandReset' | translate }}" #editImage></span>
              </li>
              <li *ngIf="options.showZoom">
                <span class="i-zoom-in" id="mob-image-viewer-zoom-in" (click)="zoomImage(1)" title="{{ 'Detail.zoomIn' | translate }}"></span>
              </li>
              <li *ngIf="options.showZoom">
                <span class="i-zoom-out" id="mob-image-viewer-zoom-out" (click)="zoomImage(-1)" title="{{ 'Detail.zoomOut' | translate }}"></span>
              </li>
              <li *ngIf="options.showRotate">
                <span class="i-rotate-left" (click)="rotateImage(-1)" title="{{ 'Detail.rotateLeft' | translate }}"></span>
              </li>
              <li *ngIf="options.showRotate">
                <span class="i-rotate-right" (click)="rotateImage(1)" title="{{ 'Detail.rotateRight' | translate }}"></span>
              </li>
              <li *ngIf="options.showDownload" [popover]="downloadImage" [placement]="'left'" popoverTitle="Download as PDF" [outsideClick]="true"
                (click)="brightnessUpdate(downloadImg)">
                <span class="i-download" title="{{ 'Detail.downloadAsPDF' | translate }}" #downloadImg></span>
              </li>

              <li *ngIf="options.showDocument">
                <span class="i-document" title="{{ 'Detail.Brightness' | translate }}"></span>
              </li>
              <li *ngIf="options.showExpand" [ngClass]="{'effect-active':!fitToWidth}">
                <span class="i-fit-height" (click)="fitImageToWidth(false)" title="{{ 'Detail.fittoHeight' | translate }}"></span>
              </li>
              <li *ngIf="options.showExpand" [ngClass]="{'effect-active':fitToWidth}">
                <span class="i-fit-width" (click)="fitImageToWidth(true)" title="{{ 'Detail.fittoWidth' | translate }}"></span>
              </li>
              <li *ngIf="options.showFullScreen && !isInFullScreenMode" id="toggleFullScreenButton" (click)="browserFullScreen()">
                <span class="i-full-screen" title="{{ 'Detail.fullScreen' | translate }}"></span>
              </li>
              <li *ngIf="isInFullScreenMode" class="effect-active" [ngClass]="{'effect-active':isInFullScreenMode}" (click)="browserFullScreen()">
                <span class="small-screen" title="{{ 'Detail.smallScreen' | translate }}"></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="mobileFilmStrip">
          <div id="mobileFilmStripDropdown" class="mobileFilmStripDropdown" [ngStyle]="{'display': showFilmStripMenu ? 'block': 'none'}">
            <div #thumbsContainer class="thumbs-container">
            </div>
          </div>
        </div>

      </div>
      <div #listViewContainer class="col-md-12 list-view-container" [ngStyle]="{'display': isInListViewMode ? '' : 'none'}">
        <div class="list-view-content" *ngIf="addFilmStrip">
          <div class="col-lg-2 col-sm-3 list-view-item" *ngFor="let image of listOfImages; let i = index" [ngClass]="{'irrelevant-img': !_source[image.imageId]?.isRelevant}">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<iframe id="printf" name="printf" height="0px" width="0px"></iframe>
<ng-template #imageToolbar>
  <form #imageToolbarForm="ngForm" class="brightnessControl">
    <div class="brightness-control">
      <label>{{ 'Detail.Brightness' | translate }}</label>
      <div class="btn-group">
        <app-range-slider name="brightnessControl" (RangeChanged)="adjustBrightness($event)" [minValue]="showDragonViewer ? -255 : -1"
          [maxValue]="showDragonViewer ? 255 : 1" [stepValue]="showDragonViewer ? 1 : 0.01" title="{{ 'Detail.Brightness' | translate }}"
          [(ngModel)]="currentBrightness"></app-range-slider>
      </div>
    </div>
    <div class="contrast-control">
      <label>{{ 'Detail.Contrast' | translate }}</label>
      <div class="btn-group">
        <app-range-slider name="contrastControl" (RangeChanged)="adjustContrast($event)" [minValue]="showDragonViewer ? -5 : -1"
          [stepValue]="showDragonViewer ? 1 : 0.01" [value]="1" [maxValue]="showDragonViewer ? 5 : 1" title="{{ 'Detail.Contrast' | translate }}"
          [(ngModel)]="currentContrast"></app-range-slider>
      </div>
    </div>
    <div class="invert-control">
      <label>
        <input id="invertChkbox" type="checkbox" name="isInvertedChkBox" [(ngModel)]="isInverted" (change)="negativeChanged()" title=" {{ 'Detail.Negative' | translate }} ({{shortcutKeyTitle}} + N)"
        /> {{ 'Detail.Negative' | translate }}
      </label>
    </div>
  </form>
  <div class="float-right reset_btn">
    <button class="btn btn-xs btn-primary" (click)="resetToolbar()">{{ 'Detail.reset' | translate }}</button>
  </div>
</ng-template>
<ng-template #downloadImage>

  <div class="brightness-control">
    <label class="radio-inline showall-container download_img" (click)="clearSelectPage(downloadImagesModel)">
      <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel" [ngModelOptions]="{standalone:true}" [value]="'all-pages'"
        (ngModelChange)="downloadImagesPDF($event)" checked>{{ 'Detail.allPages' | translate }}
      <span class="showall-checkmark"></span>
    </label>
    <label class="radio-inline showall-container download_img" (click)="clearSelectPage(downloadImagesModel)">
      <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel" [ngModelOptions]="{standalone:true}" [value]="'current-page'"
        (ngModelChange)="downloadImagesPDF($event)">{{ 'Detail.currentPage' | translate }}
      <span class="showall-checkmark"></span>
    </label>
    <label class="radio-inline showall-container download_img" (click)="clearSelectPages()">
      <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel" [ngModelOptions]="{standalone:true}" [value]="'selected-page'"
        (ngModelChange)="downloadImagesPDF($event)">{{ 'Detail.selectPages' | translate }}
      <span class="showall-checkmark"></span>
    </label>
    <input *ngIf="downloadImagesModel == 'selected-page'" [(ngModel)]="pagesToDownload" (ngModelChange)="selectPageChanges()"
      [ngModelOptions]="{standAlone:true}" class="downloadimage-txtbox" type="text" value="" placeholder="1,5-7,9,11-12">
    <div class="error text-danger errorMsg">{{errorMsg}}</div>
    <div class="text-center downloadbtn-div">
      <button class="btn btn-primary btn-download" [disabled]="isDownloadingInProgress == true || isInvalidPageNumber == true"
        (click)="downloadImageToPDF()">{{isDownloadingInProgress === true ? downloadingText : downloadText}} </button>
    </div>
  </div>
</ng-template>
<ng-template #printImagePopOverTemplate>

  <div class="brightness-control">
    <label class="radio-inline showall-container download_img" (click)="clearSelectPage(printImageModel)">
      <input type="radio" name="printImages" [ngModel]="printImageModel" [ngModelOptions]="{standalone:true}" [value]="'all-pages'"
        (ngModelChange)="setPrintImageModel($event)">{{ 'Detail.allPages' | translate }}
      <span class="showall-checkmark"></span>
    </label>
    <label class="radio-inline showall-container download_img" (click)="clearSelectPage(printImageModel)">
      <input type="radio" name="printImages" [ngModel]="printImageModel" [ngModelOptions]="{standalone:true}" [value]="'current-page'"
        (ngModelChange)="setPrintImageModel($event)">{{ 'Detail.currentPage' | translate }}
      <span class="showall-checkmark"></span>
    </label>
    <label class="radio-inline showall-container download_img" (click)="clearSelectPages()">
      <input type="radio" name="printImages" [ngModel]="printImageModel" [ngModelOptions]="{standalone:true}" [value]="'selected-page'"
        (ngModelChange)="setPrintImageModel($event)">{{ 'Detail.selectPages' | translate }}
      <span class="showall-checkmark"></span>
    </label>
    <input *ngIf="printImageModel == 'selected-page'" [(ngModel)]="pagesToPrint" [ngModelOptions]="{standAlone:true}" (ngModelChange)="selectPageChanges()"
      class="downloadimage-txtbox" type="text" value="" placeholder="1,5-7,9,11-12">
    <div class="error text-danger errorMsg">{{errorMsg}}</div>
    <div class="text-center downloadbtn-div">
      <button class="btn btn-primary btn-download" [disabled]="isPrintInProgress == true || isInvalidPageNumber == true" (click)="printImage()">
        {{isPrintInProgress === true ? retrievingText : printText}} </button>
    </div>
  </div>
</ng-template>
<ng-template #helpPopOverTemplate>

</ng-template>

<ng-template #bookmarkHtmlPageTemplate>
  <div class="modal-header">
    <h4 *ngIf="!showClipBoardSuccessAlert" class="modal-title sa-icon sa-info pulseWarning">
    </h4>
    <h4 *ngIf="showClipBoardSuccessAlert" class="modal-title sa-icon sa-success pulseWarning">
      <span class="sa-line sa-tip animateSuccessTip"></span>
      <span class="sa-line sa-long animateSuccessLong"></span>
    </h4>
  </div>
  <div class="modal-body url_body">
    <div *ngIf="showClipBoardSuccessAlert" class="alert alert-success">{{ 'Detail.urlCopied' | translate }}</div>
    <div class="well">
      <span id="bookmarkPageURLonTemplate">{{bookmarkPageURL}}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeBookMarkModal()">
      {{ 'Detail.Close' | translate }}
    </button>
    <button type="button" class="btn btn-primary" aria-label="Close" (click)="copytoClipBoard(bookmarkPageURL)">
      {{ 'Detail.copyClipboard' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #citationPageTemplate>
  <div class="modal-header citationHeader">
    <h4 class="modal-title illustration-title pull-left">{{ 'Detail.copyCitation' | translate }}</h4>
    <button type="button" class="fa fa-close citation-closebtn" (click)="closecitationPageTemplate()">
      <span class="sr-only">close button</span>
    </button>
  </div>
  <div class="modal-body citationBody">
    <div class="citationInnerHtml">
      <ul>
        <li>
          <label class="citation_type_header">
            <span>{{ 'Detail.MLA' | translate }} </span>
            <span class="float-right" [ngClass]="{'diasbledBtn' : emptyCitation} ">
              <span *ngIf="!isInIpadMode">
                <i class="copyCitation fa fa-copy" tooltip="{{'Detail.copyClipboard' | translate}}" aria-hidden="true" (click)="copyCitation(citationRes?.MLA, 'ModelMLA')"></i>
                <i class="fa fa-download download-Citation" download tooltip="{{'Detail.clickToDownload' | translate}}" aria-hidden="true"
                  (click)="downloadCitation(citationRes?.MLA)"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i class="copyCitation fa fa-copy" (click)="copyCitation(citationRes?.MLA, 'ModelMLA')"></i>
                <i class="fa fa-download download-Citation" download (click)="downloadCitation(citationRes?.MLA)"></i>
              </span>
            </span>
          </label>
          <label id="citationMla" class="cslEntry" [ngClass]="{'mycopyCitation' : modelcitaionMLA}" #citationMla [innerHtml]="citationRes?.MLA"
            *ngIf="!emptyCitation"></label>
          <label *ngIf="emptyCitation"> N/A</label>
        </li>
        <li>
          <label class="citation_type_header">
            <span>{{ 'Detail.CHICAGO' | translate }} </span>
            <span class="float-right" [ngClass]="{'diasbledBtn' : emptyCitation}">
              <span *ngIf="!isInIpadMode">
                <i tooltip="Copy to Clipboard " class="copyCitation  fa fa-copy" aria-hidden="true" (click)="copyCitation(citationRes?.CHICAGO, 'ModelCHICAGO' )"></i>
                <i class="fa fa-download download-Citation" tooltip="Click to download" aria-hidden="true" (click)="downloadCitation(citationRes?.CHICAGO)"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i class="copyCitation  fa fa-copy" (click)="copyCitation(citationRes?.CHICAGO, 'ModelCHICAGO' )"></i>
                <i class="fa fa-download download-Citation" (click)="downloadCitation(citationRes?.CHICAGO)"></i>
              </span>
            </span>
          </label>
          <label id="citationChicago" #citationChicago [ngClass]="{'mycopyCitation' : modelcitaionCHICAGO}" class="cslEntry" [innerHtml]="citationRes?.CHICAGO"
            *ngIf="!emptyCitation"></label>
          <label *ngIf="emptyCitation"> N/A</label>
        </li>
        <li>
          <label class="citation_type_header">
            <span>{{ 'Detail.APA' | translate }} </span>
            <span class="float-right" [ngClass]="{'diasbledBtn' : emptyCitation}">
              <span *ngIf="!isInIpadMode">
                <i placement="top" class="copyCitation fa fa-copy" tooltip="Copy to Clipboard" aria-hidden="true" (click)="copyCitation(citationRes?.APA, 'ModelAPA')"></i>
                <i class="fa fa-download download-Citation" tooltip="Click to download" aria-hidden="true" (click)="downloadCitation(citationRes?.APA)"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i placement="top" class="copyCitation fa fa-copy" (click)="copyCitation(citationRes?.APA, 'ModelAPA')"></i>
                <i class="fa fa-download download-Citation" (click)="downloadCitation(citationRes?.APA)"></i>
              </span>
            </span>
          </label>
          <label id="citationApa" #citationApa class="cslEntry" [ngClass]="{'mycopyCitation' : modelcitaionAPA}" [innerHtml]="citationRes?.APA"
            *ngIf="!emptyCitation"></label>
          <label *ngIf="emptyCitation"> N/A</label>
        </li>
      </ul>
    </div>
    <div>
      <h4 class="modal-title illustration-title">{{ 'Detail.exportCitation' | translate }}</h4>
      <div>
        <ul class="exportUl" [ngClass]="{'diasbledBtn' : emptyCitation}">
          <li class="export" (click)="exportRIS(EndNote)">
            <a class="exportCitation" id="EndNote" #EndNote>
              <span class="btn-link">{{ 'Detail.exporttoEndNote' | translate }}</span>
            </a>
          </li>
          <li class="export" (click)="exportRIS(ProCite)">
            <a class="exportCitation" id="ProCite" #ProCite>
              <span>{{ 'Detail.exportProCite' | translate }}</span>
            </a>
          </li>
          <li class="export" (click)="exportRIS(ReferenceManager)">
            <a class="exportCitation" id="ReferenceManager" #ReferenceManager>
              <span>{{ 'Detail.exportReferenceManager' | translate }}</span>
            </a>
          </li>
          <li class="export" (click)="exportRIS(Zotero)">
            <a class="exportCitation" id="Zotero" #Zotero>
              <span>{{ 'Detail.exportZotero' | translate }}</span>
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <div class="float-left text_left">
      {{ 'Detail.exportNote' | translate }}
    </div>
  </div>

</ng-template>

<ng-template #shareHtmlPageTemplate>
  <div class="modal-popup-parentDiv">
    <div class="modal-header emailpopup-header">
      <h4 class="emailpopup-heading">{{ 'Detail.sendEmail' | translate }} </h4>
    </div>
    <div class="modal-body">
      <form class="form-horizontal sendMail" #sendMailForm="ngForm">
        <div class="form-group">
          <label class="control-label col-sm-2 emailGroup" for="email">{{ 'Detail.from' | translate }}
            <span class="glyphicon glyphicon-asterisk"></span>
          </label>
          <div class="col-sm-10">
            <input type="email" #contactemailfrom="ngModel" class="form-control" id="emailFrom" placeholder="{{'Detail.fromPlaceholder' | translate}}"
              name="contactemailfrom" [(ngModel)]="shareMailData.from" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              required>
            <div class="" [hidden]="contactemailfrom.valid || contactemailfrom.untouched">
              <div class="alert-danger text-left" *ngIf="contactemailfrom.errors && contactemailfrom.errors.required">
                <h4>{{ 'Detail.emailIsRequired' | translate }}</h4>
              </div>
              <div class="alert-danger text-left" *ngIf="contactemailfrom.errors && contactemailfrom.errors.pattern">
                <h4>{{ 'Detail.invalidEmail' | translate }}</h4>
              </div>
            </div>
          </div>

        </div>
        <div class="form-group">
          <label class="control-label col-sm-2 emailGroup" for="email">{{ 'Detail.recipient' | translate }}
            <span class="glyphicon glyphicon-asterisk"></span>
          </label>
          <div class="col-sm-10">
            <input type="email" #contactemailto="ngModel" class="form-control" id="emailTo" placeholder="{{'Detail.recipientPlaceholder' | translate}}"
              name="contactemailto" [(ngModel)]="shareMailData.to" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              required>
            <div class="" [hidden]="contactemailto.valid || contactemailto.untouched">
              <div class="alert-danger text-left" *ngIf="contactemailto.errors && contactemailto.errors.required">
                <h4>{{ 'Detail.emailIsRequired' | translate }}</h4>
              </div>
              <div class="alert-danger text-left" *ngIf="contactemailto.errors && contactemailto.errors.pattern">
                <h4>{{ 'Detail.invalidEmail' | translate }}</h4>
              </div>
            </div>
          </div>

        </div>
        <div class="form-group">
          <label class="control-label col-sm-2 emailGroup" for="subject">{{ 'Detail.subject' | translate }}
            <span class="glyphicon glyphicon-asterisk"></span>
          </label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="subject" placeholder="{{'Detail.subjectPlaceholder' | translate}}" name="subject"
              [(ngModel)]="shareMailData.topic" #subject="ngModel" required>
            <div class="error text-danger" [hidden]="subject.valid || subject.untouched ">
              <div class="alert-danger text-left" *ngIf="subject.errors && subject.errors.required">
                <h4>{{ 'Detail.requiredSubject' | translate }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-sm-2 emailGroup" for="message">{{ 'Detail.Message' | translate }}
            <span class="glyphicon glyphicon-asterisk"></span>
          </label>
          <div class="col-sm-10">
            <textarea type="text" wrap="hard" class="form-control message-textarea" id="message" rows="10" cols="50" placeholder="{{'Detail.messagePlaceholder' | translate}}"
              name="message" [(ngModel)]="shareMailData.message" #message="ngModel" required></textarea>
            <div class="error text-danger" [hidden]="message.untouched || shareMailData.message.trim() !== ''">
              <div class="alert-danger text-left" *ngIf="(message.errors && message.errors.required) || shareMailData.message.trim() === ''">
                <h4>{{ 'Detail.requiredMessage' | translate }}</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="emailSend submitBtn">
          <div class="col-sm-12">
            <button type="button" [disabled]="!sendMailForm.form.valid || shareMailData.message.trim() === ''" class="btn-primary btn"
              aria-label="Close" (click)="shareEmail()">{{ 'Detail.Send' | translate }}</button>

          </div>
          <!-- <tooltip-content #sendMailFormTooltip [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
            <div class="error text-danger " [hidden]="contactemailfrom.valid">
              <div *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
                {{ 'Detail.fromIsRequired' | translate }}
              </div>

            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
              <div *ngIf="contactemailto.untouched || contactemailto.invalid">
                {{ 'Detail.recipientIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
              <div *ngIf="subject.untouched || subject.invalid">
                {{ 'Detail.subjectIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
              <div *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
                {{ 'Detail.messageIsRequired' | translate }}
              </div>
            </div>
          </tooltip-content> -->
          
          <!-- <ng-template #sendMailFormTooltip [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
            <div class="error text-danger " [hidden]="contactemailfrom.valid">
              <div *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
                {{ 'Detail.fromIsRequired' | translate }}
              </div>

            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
              <div *ngIf="contactemailto.untouched || contactemailto.invalid">
                {{ 'Detail.recipientIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
              <div *ngIf="subject.untouched || subject.invalid">
                {{ 'Detail.subjectIsRequired' | translate }}
              </div>
            </div>
            <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
              <div *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
                {{ 'Detail.messageIsRequired' | translate }}
              </div>
            </div>
          </ng-template> -->
        </div>
        <div class="emailClose closeBtn">
          <button type="button" class="btn-default btn" (click)="closeShareModal()">Close</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>


<!-- <ng-template #downloadPDFTemplate>
  <div class="modal-popup-parentDiv download-popup-div">
    <div class="modal-header download-popup-header">
    </div>
    <div class="modal-body">
      {{ downloadingText }}
    </div>

  </div>
</ng-template> -->

<ng-template #downloadPrintTemplate>
  <div class="modal-popup-parentDiv download-popup-div">
    <div class="modal-header download-popup-header">
    </div>
    <div class="modal-body">
      {{ retrievingText }}
    </div>

  </div>
</ng-template>



<ng-template #downloadPageasPdfTemplate>
  <div class="modal-header  download-title">
    <h4 class="modal-title text-center">
      <!-- <span class="downloadPopupIcon"></span> -->
      <!-- <span class="spriteico downloadPopupIcon"></span> -->
      <img src="assets/images/icons/download@2x.png " alt="View History" class="center-block downloadPopupIcon" />
    </h4>
  </div>
  <div class="modal-body url_body download-body">
    <div [class.arabic-style]="isArabicLanguage" class="download-text">
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadPageURL}}" target="_blank">{{ 'Detail.downloadPDF' | translate }}</a>
    </div>
    <div class="modal-download-text download-text-cmt" [class.arabic-style]="isArabicLanguage">
      <span [class.arabic-style]="isArabicLanguage" class="modal-download-text-span"> {{ 'Detail.clicktoDownloadFileMessage' | translate }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary" (click)="closeDownloadPdfModal();$event.stopPropagation();">
      {{ 'Detail.Close' | translate }}
    </button>
  </div>
</ng-template>
<ng-template #printWarningTemplate>
  <div class="modal-header  download-title">
    <h4 class="modal-title text-center">
      <img src="assets/images/icons/download@2x.png " alt="View History" class="center-block downloadPopupIcon" />
    </h4>
  </div>
  <div class="modal-body url_body no-pad-top">
    <div class="modal-download-text download-text-cmt" [class.arabic-style]="isArabicLanguage">
      <span [class.arabic-style]="isArabicLanguage" class=""> {{ 'Detail.printWarningMessage' | translate }}</span>
    </div>
    <div [class.arabic-style]="isArabicLanguage" class="download-text">
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadPageURL}}" target="_blank">{{ 'Detail.downloadPDF' | translate }}</a>
    </div>
    <div class="modal-download-text download-text-cmt" [class.arabic-style]="isArabicLanguage">
      <span [class.arabic-style]="isArabicLanguage" class="modal-download-text-span"> {{ 'Detail.clicktoDownloadFileMessage' | translate }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary" (click)="printWarningModalRef.hide();$event.stopPropagation();">
      {{ 'Detail.Close' | translate }}
    </button>
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" (click)="printImageFromServer();">
      {{ 'Common.print' | translate }}
    </button>
  </div>
</ng-template>