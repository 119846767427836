import { Injectable, OnDestroy } from '@angular/core';
import { APIURLS, environment } from '../../environments/environment';
import { HttpService } from '../common/services/http.service';
import { PageSettingsService } from '../common/services/page-settings.service';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class SearchResultDetailService implements OnDestroy {
  coverFlowData: any = [];
  locationsConfigDetails: any;
  cache = {
    relatedSubjectsData: {},
    data: {},
    collectionData: {},
    illustrationData: {}
  };
  private pubDatePrefix: any = {
    ON: 'EQUAL',
    BETWEEN: 'BETWEEN',
    AFTER: 'GREATER_THAN',
    BEFORE: 'LESS_THAN'
  };
  private pubDateDataPrefix: any = {
    EQUAL: 'ON',
    BETWEEN: 'BETWEEN',
    GREATER_THAN: 'AFTER',
    LESS_THAN: 'BEFORE'
  };
  constructor(
    private httpService: HttpService,
    private httpclient: HttpClient
  ) {
    this.httpService.get(APIURLS.config, response => {
      this.locationsConfigDetails = response;
    }, (error) => { });
    // setInterval(res => {
    //   this.clearCachedData();
    // }, ( 5000 * 60));
  }

  getDownloadTableUrl(docId, tableId, callBack, errorCallback) {
    if (docId && tableId) {
      // tslint:disable-next-line:max-line-length
      this.httpclient.get<any>(`${environment.APIUrl}${APIURLS.downloadTableUrlV2}${docId}/${tableId}?WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`, { responseType: 'text' as 'json' }).subscribe(res => {
        callBack(res);
      }, err => {
        errorCallback(err);
      });
    }
  }

  getDownloadfulltextUrl(docId, language, callBack, errorCallback) {
    if (docId) {
      if (language) {
         // tslint:disable-next-line:max-line-length
         this.httpclient.get<any>(`${environment.APIUrl}${APIURLS.downloadFullTextV2}${docId}?destLanguage=${language}`, { responseType: 'text' as 'json' }).subscribe(res => {
          callBack(res);
        }, err => {
          errorCallback(err);
        });
      } else {
        // tslint:disable-next-line:max-line-length
        this.httpclient.get<any>(`${environment.APIUrl}${APIURLS.downloadFullTextV2}${docId}?WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`, { responseType: 'text' as 'json' }).subscribe(res => {
          callBack(res);
        }, err => {
          errorCallback(err);
        });
      }
    }
  }

  getTextUrl(docId, pageId, languageCode, callBack, errorCallback) {
    if (docId && pageId) {
      let lang: any;
      if (languageCode) {
        lang = `?destLanguage=${languageCode}`;
      }
      // tslint:disable-next-line:max-line-length
      this.httpclient.get(`${environment.APIUrl}${APIURLS.downloadTextV2}${docId}/${pageId}${lang ? lang : ''}${lang ? '&' : '?'}WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`, { responseType: 'text' as 'json' }).subscribe(res => {
        callBack(res);
      }, err => {
        errorCallback(err);
      });
    }
  }

  /*
  * @param: element
  * @param: To position
  * @param: Duration
  * Description: Scroll to particular position with animated scroll
  * Associate Fun: 1
  * */
  scrollTo(element, to, duration): void {
    // tslint:disable-next-line:prefer-const
    let start = element.scrollTop,
      // tslint:disable-next-line:prefer-const
      change = to - start,
      currentTime = 0,
      // tslint:disable-next-line:prefer-const
      increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
  /*
  * DESC: render the animation with given param
  * t = current time
  * b = start value
  * c = change in value
  * d = duration
  * * Associate Fun: 2
  */
  easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) { return c / 2 * t * t + b; }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }
  /*
  * @param: document id
  * Output: object
  * DESC: get Volume for document
  * */
  getVolumeDetails(docId, callBack, errorCallback) {
    if (docId) {
      if (this.cache.data[docId]) {
        return callBack(this.cache.data[docId]);
      }
      this.httpService.get(`${environment.APIUrl}${APIURLS.getVolumes}${docId}`, res => {
        this.cache.data[docId] = res;
        callBack(res);
      }, err => {
        errorCallback(err);
      });
    }
  }

  getCollectionLevelMetadata(documentId, collectionId, callBack, errorCallback) {
    if (collectionId) {
      if (this.cache.collectionData[collectionId]) {
        return callBack(this.cache.collectionData[collectionId]);
      }
      this.httpService.get(`${environment.APIUrl}${APIURLS['getCollectionNotes']}/${documentId}/${collectionId}`, res => {
        callBack(res);
        this.cache.collectionData[collectionId] = res;
      }, error => {
        errorCallback(null);
      });
    }
  }
 
  getRelatedSubjects(docID, successcallBack, errorCallback) {
    if (docID) {
      if (this.cache.relatedSubjectsData[docID]) {
        return successcallBack(this.cache.relatedSubjectsData[docID]);
      }
      this.httpService.get(`${environment.APIUrl}${APIURLS['getRelatedSubjects']}/${docID}`, response => {
        this.cache.relatedSubjectsData[docID] = response;
        successcallBack(response);
      }, error => {
        errorCallback(error);
      }, 'json');
    }
  }

  private buildFilterParams(advancedFilters, callBack?, contentType?) {
    if (advancedFilters.publishedDateTo) {
      advancedFilters.publishedDateTo = advancedFilters.publishedDateTo.endsWith('-') ?
        advancedFilters.publishedDateTo.substring(0, advancedFilters.publishedDateTo.length - 1) : advancedFilters.publishedDateTo;
    }
    if (advancedFilters.publishedDateFrom) {
      advancedFilters.publishedDateFrom = advancedFilters.publishedDateFrom.endsWith('-') ?
        advancedFilters.publishedDateFrom.substring(0, advancedFilters.publishedDateFrom.length - 1) : advancedFilters.publishedDateFrom;
    }
    const params = {
      searchParams: [],
      filterParams: [],
      searchTerm: '',
      groupOffset: 0
    };

    let foundFilter = false;
    const advancedParamKeys = Object.keys(advancedFilters);
    advancedParamKeys.forEach((key, index) => {
      if (key === 'searchKeywords') {
        if (advancedFilters.searchKeywords) {
          advancedFilters.searchKeywords.forEach(obj => {
            if (obj.fieldValue) {
              params.searchParams.push(obj);
              foundFilter = true;
            }
          });
        }
      } else if (key === 'publishedDateFrom') {
        if (advancedFilters[key]) {
          foundFilter = true;
          const field = {
            fieldName: 'pubStartDate',
            fieldValue: advancedFilters[key].replace(/\-$/g, ''),
            condition: this.pubDatePrefix[
              advancedFilters['publishedDatePrefix']
            ],
            includeNull: advancedFilters['publishedDateIncludesNull']
          };
          if (advancedFilters['publishedDateTo']) {
            field['fieldEndValue'] = advancedFilters['publishedDateTo'];
            field['operator'] = 'AND';
          }
          params.filterParams.push(field);
        }
      } else if (key === 'publishedDateTo') {
      } else if (key === 'publishedDatePrefix') {
        if (advancedFilters.publishedDatePrefix === 'All') {
          if (advancedFilters[key]) {
            foundFilter = true;
            const field = {
              fieldName: 'pubStartDate',
              fieldValue: 'All',
              includeNull: advancedFilters['publishedDateIncludesNull']
            };
            if (advancedFilters['publishedDateTo']) {
              field['fieldEndValue'] = advancedFilters['publishedDateTo'];
              field['operator'] = 'AND';
            }
            params.filterParams.push(field);
          }
        }
      } else if (key === 'publishedDateIncludesNull') {
      } else if (key === 'facetFields') {
        if (advancedFilters.facetFields) {
          if (advancedFilters.facetFields.length > 0) {
            params[key] = advancedFilters[key];
          }
        }
      } else if (key === 'contentSources') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'sourceInstitution',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (key === 'languages') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'docLang',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (key === 'contentCollection') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'collection',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (key === 'subject') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'subject',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (Array.isArray(advancedFilters[key])) {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: key,
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      }
    });
    if (advancedFilters['archive']) {
      if (advancedFilters['archive'].length === 0) {
        params.filterParams.push({
          fieldName: 'archive',
          fieldValues:
            PageSettingsService.pageSettings.currentArchive === 'WILEY'
              ? PageSettingsService.pageSettings.currentSelectedArchives
              : [PageSettingsService.pageSettings.currentArchive],
          operator: 'OR'
        });
      }
    }

    // if (foundFilter) {
    //   if (window.location.pathname.includes('display=Photographs')) {
    //     params['groupLimit'] = GROUPLIMIT + 15;
    //     this.photoGraphView = true;

    //   } else {
    //     params['groupLimit'] = GROUPLIMIT;
    //   }

    // }
    if (advancedFilters.sortFields) {
      if (Object.keys(advancedFilters.sortFields)) {
        if (Object.keys(advancedFilters.sortFields).length > 0) {
          if (
            advancedFilters.sortFields[
            Object.keys(advancedFilters.sortFields)[0]
            ] !== ''
          ) {
            params['sortFields'] = advancedFilters.sortFields;
          }
        }
      }
    }
    if (typeof callBack === 'function') {
      callBack(params, foundFilter);
    }
  }
  advancedSearch(searchTerm, advancedFilters, callBack?, errorCallBack?, offset?, contentType?) {
    this.buildFilterParams(advancedFilters, params => {
      params.groupLimit = 3;
      params.groupOffset = this.coverFlowData.length || 0;
      params.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
      });
      params.searchTerm = searchTerm;
      if (typeof params === 'string') {
        params = JSON.parse(params);
      } 
      for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
        if (params.filterParams[k].fieldName === "archive"){
          const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
          const includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
          if (!includeWPA) {
            const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
            if (havewpa) {
              if (typeof params.filterParams[k].fieldValues  === 'string') {
                params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
               }  
               let subscribedArchives: any = JSON.parse(sessionStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
                if(!havewpaInSubs){
                  params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
                }
            }
          }
          const includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
          if (!includeWPA1) {
            const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
            if (havewpa) {
              if (typeof params.filterParams[k].fieldValues  === 'string') {
                params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
               }  
               let subscribedArchives: any = JSON.parse(sessionStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
                if(!havewpaInSubs){
                  params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
                }
            }
          }
        }
      }
      this.httpService.post(environment.APIUrl + APIURLS['advancedSearch'], params, res => {
          let count = 0;
            const resultGroups = res.results.groupResults[0].groupEntry.groupEntryContent;
            const accessToken = JSON.parse(localStorage.getItem('auth_token'));
            if (resultGroups.length > 0) {
              if (Array.isArray(resultGroups)) {
                resultGroups.forEach((group, groupIndex) => {
                  if (Array.isArray(group.documents)) {
                    if (group.group === contentType) {
                      count = group.totalGroupElements;
                      group.documents.forEach((document, docIndex) => {
                        let imgId: String = null;
                        if (document.imageThumbnails[0].includes('_thumbnail')) {
                          if (this.locationsConfigDetails.locations[document.archive].hasFolderImage === 'true') {
                            imgId = document.imageThumbnails[1].toString().replace(/\.[^/.]+$/, '').replace('_thumbnail', '');
                          } else {
                            imgId = document.imageThumbnails[0].toString().replace(/\.[^/.]+$/, '').replace('_thumbnail', '');
                          }
                        } else {
                          if (this.locationsConfigDetails.locations[document.archive].hasFolderImage === 'true') {
                            imgId = document.imageThumbnails[1].split('/')[6].split('?')[0];
                          } else {
                            imgId = document.imageThumbnails[0].split('/')[6].split('?')[0];
                          }
                        }
                        this.coverFlowData.push({
                          docID: document.id,
                          docTitle: document.title,
                          citation: document.citation ? String(document.citation).replace(/<[^>]+>/gm, '') : '',
                          imageCount: document.imageThumbnails.length,
                          url: environment.APIUrl + 'file/thumb/' + document.id + '/' + imgId + '?access_token=' +
                            accessToken + '&type=small' + '&WDALoc=' +
                            localStorage.getItem('currentLocation') || environment.defaultLocation
                        });
                      });
                    }
                  }
                });
              }
            }
            callBack({
              count: count,
              data: count > 0 ? this.coverFlowData : null
            });
        },
        error => {
          if (typeof callBack === 'function') {
            callBack(error);
          }
        }
      );
    });
  }

  /*
  * @DESC: clear cached data with 5000*60 time intervel
  * */

  clearCachedData() {
    this.cache = {
      relatedSubjectsData: {},
      data: {},
      collectionData: {},
      illustrationData: {}
    };
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.cache = {
      relatedSubjectsData: {},
      data: {},
      collectionData: {},
      illustrationData: {}
    };
  }

}
