import { Component, OnInit, AfterViewInit, Input, OnChanges, EventEmitter, Output, OnDestroy, HostListener } from '@angular/core';
import { COMMONPATHS } from '../../../../environments/environment';
declare let OpenSeadragon: any;
declare let $: any;
@Component({
  selector: 'app-basic-image-viewer',
  templateUrl: './basic-image-viewer.component.html',
  styleUrls: ['./basic-image-viewer.component.css']
})
export class BasicImageViewerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  shortcutKeyTitle: any;
  controlShortcut: any;
  disablePreviousBtn: Boolean = false;
  disableNextBtn: Boolean = false;
  currentAngle = 0;
  private currentZoomLevel = 0.1;
  showFloatingMenu: Boolean = false;
  imageviewerframeLoader = false;
  private viewer: any = null;
  _imageURL: String;
  @Output() toggleNavigation: EventEmitter<any> = new EventEmitter();
  @Output() getViewMoreOptionStatus: EventEmitter<any> = new EventEmitter();
  @Input('previewImageURL')
  set imageURL(value) {
    if (value) {
      this.imageviewerframeLoader = true;
      this._imageURL = value;
    }
  }

  get imageURL() {
    return this._imageURL;
  }

  _showOriginalImage: Boolean;
  @Input('showOriginalImage')
  set showOriginalImage(value) {
    this._showOriginalImage = value;
  }

  _tileSource: any;
  @Input('tileSource')
  set tileSource(value) {
    if (value) {
      this._tileSource = value;
    }
  }
  get tileSource() {
    return this._tileSource;
  }

  @Input()
  set disableNavigationButtons(val) {
    if (val) {
      if (val.isDisableNext) {
        this.disableNextBtn = true;
      } else {
        this.disableNextBtn = false;
      }
      if (val.isDisablePrevious) {
        this.disablePreviousBtn = true;
      } else {
        this.disablePreviousBtn = false;
      }
    }
  }

  imageLoadingStatus: Boolean = true;
  @Input('imageObjects')
  set imageObjects(value) {
    console.log(value);
    
    // this._tileSource = value.imageObject.tileSource;
    this.imageviewerframeLoader = true;
    this._imageURL = value.imageObject.url;
    this.openImage();
  }
  constructor() { }
  ngOnInit() { }
  ngOnChanges() {
    this.openImage();
  }

  ngAfterViewInit() {
    this.viewer = OpenSeadragon({
      id: 'explorerOpenSeaDragon',
      clickToZoom: false,
      homeButton: 'reset',
      zoomPerClick: 1.1,
      zoomPerScroll: 1.1,
      tabIndex: '',
      crossOriginPolicy: 'Anonymous',
      preserveViewport: true,
      visibilityRatio: 1,
      defaultZoomLevel: 1,
      minZoomLevel: 0.2,
      maxZoomLevel: 100,
      preserveOverlays: false,
      showRotationControl: true,
      gestureSettingsTouch: {
        pinchRotate: true
      },
      // constrainDuringPan: true
    });

    this.openImage();
    this.viewer.addHandler('open-failed', (eventSource, source, data) => {
      this._imageURL = COMMONPATHS.imageNotAvailable;
      setTimeout(() => {
        this.imageviewerframeLoader = false;
      }, 1000);
      this.openImage(this._imageURL);
      this.imageLoadingStatus = false;
    });

    this.viewer.addHandler('open', (eventSource, source, data) => {
      this.imageLoadingStatus = true;
      this.resetImage();
      setTimeout(() => {
        this.imageviewerframeLoader = false;
      }, 1000);
    });
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKeyTitle = 'Shift + Alt';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKeyTitle = 'Shift + Option';
    }
  }

  imageViewerMouseEnter() {
    this.showFloatingMenu = true;
  }
  imageViewerMouseLeave() {
    this.showFloatingMenu = false;
  }

  openImage(imageURL?): void {
    if (this.viewer) {
      this.viewer.close();
      if (window.location.pathname.split('/')[1].toLocaleLowerCase() === 'env') {
        this.viewer.open({ type: 'image', url: this._imageURL });
      } else {
        if (this._tileSource) {
          this.viewer.open(this._tileSource);
        } else if (this._imageURL) {
          this.viewer.open({ type: 'image', url: this._imageURL });
        } else {
          this.viewer.open('./assets/images/noimage.jpg');
        }
      }
    }
  }

  panImageTo(x, y) {
    const bounds = this.viewer.viewport.getBounds(true);
    const currentRect = this.viewer.viewport.viewportToImageRectangle(bounds);
    const rect = this.viewer.viewport.imageToViewportRectangle(x, y, (currentRect.width - 5), (currentRect.height - 5));
    this.viewer.viewport.fitBoundsWithConstraints(rect);
  }
  zoomInImage(zoomIn) {
    this.currentZoomLevel = parseFloat(this.viewer.viewport.getZoom().toFixed(5));
    this.currentZoomLevel += zoomIn;
    if (this.currentZoomLevel <= 0.2) {
      this.currentZoomLevel = 0.2;
    }
    this.viewer.viewport.zoomTo(this.currentZoomLevel);
  }
  zoomOutImage(zoomOut) {
    this.currentZoomLevel = parseFloat(this.viewer.viewport.getZoom().toFixed(5));
    if (this.currentZoomLevel > 0.2) {
      this.currentZoomLevel -= zoomOut;
    }
    if (this.currentZoomLevel <= 0.2) {
      this.currentZoomLevel = 0.2;
    }
    this.viewer.viewport.zoomTo(this.currentZoomLevel);
  }
  rotateImage(angle: number) {
    this.currentAngle = (angle + this.currentAngle) % 4;
    this.viewer.viewport.setRotation(this.currentAngle * 90);
  }

  gotoNextImage() {
    this._tileSource = null;
    this.imageviewerframeLoader = true;
    this.toggleNavigation.emit({ value: 'next', random: Math.random() });
    this.getViewMoreOptionStatus.emit({ value: 'true', random: Math.random() });
  }

  gotoPreviousImage() {
    this._tileSource = null;
    this.imageviewerframeLoader = true;
    this.toggleNavigation.emit({ value: 'previous', random: Math.random() });
    this.getViewMoreOptionStatus.emit({ value: 'true', random: Math.random() });
  }
  resetImage() {
    if (this.viewer) {
      this.viewer.viewport.zoomTo(0.1);
      this.viewer.viewport.setRotation(0);
      this.panImageTo(0, 0);
      this.viewer.viewport.fitVertically(true);
    }
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  ngOnDestroy(): void {
    this.tileSource = null;
    this._imageURL = '';
    this._tileSource = null;
    this.viewer = null;
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.shiftKey && e.altKey) {
      switch (e.keyCode) {
        case 37:
          this.rotateImage(-1);
          break;
        case 39:
          this.rotateImage(1);
          break;
        case 187:
          this.zoomInImage(0.1);
          break;
        case 189:
          this.zoomOutImage(0.1);
          break;
      }
    }
  }
}
