/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate {
    currentLocation: any;
    currentArchive: any;
    isFromBookmark = true;
    constructor(
        private auth: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
       this.router.events.subscribe((events) => {
            if (events instanceof NavigationEnd) {
                this.activatedRoute.params.subscribe(params => {
                    if (window.location.pathname.includes('search') || window.location.pathname.includes('detail')) {
                        sessionStorage.setItem('lastSessionPath', window.location.pathname + window.location.search);
                    }
                    if (params['fromBookmark']) {
                        sessionStorage.setItem('lastSessionPath', window.location.pathname + window.location.search);
                    }
                });
            }
        });
        this.currentArchive = document.location.pathname.split('/')[1];
     }

    canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate([`${this.currentArchive || environment.defaultArchive}/auth`]);
            return false;
        } else { return true; }
    }
}
