
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, ChangeDetectorRef, HostListener,
  Output, EventEmitter, Input, OnDestroy, AfterContentInit, AfterViewInit, DoCheck
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
// import { IMultiSelectTexts, IMultiSelectSettings } from '../../../../../assets/plugin/angular-2-dropdown-multiselect';
import { PageSettingsService } from '../../../../common/services/page-settings.service';
import { AdvancedFilterParams } from './../../../../common/models/AdvancedFilterParams';
import {
  orderBy as _orderBy, map as _map, isEmpty as _isEmpty, get as _get, find as _find,
  matchesProperty as _matchesProperty } from 'lodash';
import { Subscription ,  Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UrlUtilityService } from './../../../../common/services/url-utility.service';
import { SharedService } from './../../../../common/services/sharedService';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from './../../../../common/services/modal.service';
import { CommonService } from '../../../services/common.service';
import { IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
declare let $: any;
@Component({
  selector: 'app-text-analysis-limiter',
  templateUrl: './text-analysis-limiter.component.html',
  styleUrls: ['./text-analysis-limiter.component.css']
})
export class TextAnalysisLimiterComponent implements AfterContentInit, OnDestroy, AfterViewInit, DoCheck {
  private stop$: Subject<void> = new Subject<void>();
  publishDateMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];
  accessableWordcloudArchives = [];
  currentActiveTabIndex: any;
  locationConfigArchives: any;
  archiveUnsub: Subscription;
  isArabicLanguage: any;
  illustrationTypesTooltip: string;
  _showFilter: any;
  translatedValues: any;
  activeTabIndex: any;
  checkDate: Boolean = false;
  @Input() isDisableLimiterFields: any;
  @Input() isFromPage: any;
  @Input()
  set showFilter(flag) {
    if (flag) {
      this._showFilter = flag;
    }
  }

  get showFilter() {
    return this._showFilter;
  }
  @Input() popupRef: any;
  isFromPhotoExplorer: any;
  @Input('isFromPhotoExplorer')
  set _isFromPhotoExplorer(flag) {
    if (flag.value) {
      this.isFromPhotoExplorer = true;
      if (sessionStorage.getItem('explorerLimiter')) {
        // this.populateDataToForm();
        this.onFilterOpen();
      }
    } else {
      this.isFromPhotoExplorer = false;
    }
  }

  isFromCollocation: any;
  @Input('isFromCollocation')
  set _isFromCollocation(flag) {
    if (flag) {
      this.isFromCollocation = true;
      if (sessionStorage.getItem('explorerLimiter')) {
        this.onFilterOpen();
      }
    } else {
      this.isFromCollocation = false;
    }
  }
  @Input('limitLabel') limitLabel: any;
  set _limitLabel(value) {
    this.limitLabel = value;
  }

  get _limitLabel() {
    return this.limitLabel;
  }
  @Input('limitdisabled') limitdisabled: any;
  set _limitdisabled(value) {
    this.limitdisabled = value;
  }

  get _limitdisabled() {
    return this.limitdisabled;
  }
  @Output() messageEvent = new EventEmitter<any>();
  @Output() advancedFilterParamOut: EventEmitter<any> = new EventEmitter();
  copyAdvancedFilterParam: AdvancedFilterParams;
  filterParams: any;
  archivesTooltip: string;
  showPubDate: boolean;
  _enableSubmit: boolean;
  estimatedCountLabel: any;
  estimateTimer: any;
  limiterFormChangeSubscription: Subscription;
  hasValuesUndated: boolean;
  contentSubTypesTooltip: string;
  contentTypeTooltip: string;
  contentSourcesTooltip: string;
  publicationTitleTooltip: string;
  collectionsTooltip: string;
  // publishDateMask: (string | RegExp)[];
  pageContent: any = {
    searchKeywords: '',
    contentType: [],
    docSubType: [],
    contentCollection: [],
    contentSources: [],
    languages: [],
    publishedDateFrom: '',
    publishedDateTo: '',
    publishedDatePrefix: '',
    publishedDateIncludesNull: '',
    archive: [],
    groupLimit: [],
    currentOffset: '',
    sortFields: [],
    facetFields: [],
    subject: [],
    illustrationType: []
  };
  // estimatedCount: any = 0;
  searchKeywords = new UntypedFormControl();
  private contentType = new UntypedFormControl();
  private illustrationType = new UntypedFormControl();
  private docSubType = new UntypedFormControl();
  private contentCollection = new UntypedFormControl();
  private contentSources = new UntypedFormControl();
  private languages = new UntypedFormControl();
  publishedDateFrom = new UntypedFormControl();
  publishedDateTo = new UntypedFormControl();
  publishedDatePrefix = new UntypedFormControl();
  publishedDateIncludesNull = new UntypedFormControl();
  private groupLimit = new UntypedFormControl();
  private currentOffset = new UntypedFormControl();
  private archive = new UntypedFormControl({ disabled: this.pageContent.customerArchives === null });
  private sortFields = new UntypedFormControl();
  private facetFields = new UntypedFormControl();
  private subject = new UntypedFormControl();
  private validDate: Boolean;
  limiterForm = new UntypedFormGroup({
    searchKeywords: this.searchKeywords,
    contentType: this.contentType,
    docSubType: this.docSubType,
    contentCollection: this.contentCollection,
    contentSources: this.contentSources,
    languages: this.languages,
    publishedDateFrom: this.publishedDateFrom,
    publishedDateTo: this.publishedDateTo,
    publishedDatePrefix: this.publishedDatePrefix,
    publishedDateIncludesNull: this.publishedDateIncludesNull,
    archive: this.archive,
    groupLimit: this.groupLimit,
    currentOffset: this.currentOffset,
    sortFields: this.sortFields,
    facetFields: this.facetFields,
    subject: this.subject,
    illustrationType: this.illustrationType
  });
  // Settings configuration
  contentTypeSettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 2,
    containerClasses: 'multiselect-dropdown-item',
    itemClasses: 'multiselect-dropdown-item-span'
  };

  contentTypeTexts: IMultiSelectTexts = {
    checked: 'Content Type selected',
    checkedPlural: 'Content Types selected',
    defaultTitle: 'Content Types'
  };
  collectionsSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    containerClasses: 'multiselect-dropdown-item',
    itemClasses: 'multiselect-dropdown-item-span'
  };

  collectionTexts: IMultiSelectTexts = {
    checked: 'Collection selected',
    checkedPlural: 'Collections selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Collections'
  };
  contentSubTypeSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    containerClasses: 'multiselect-dropdown-item',
    itemClasses: 'multiselect-dropdown-item-span'
  };

  contentSubTypeTexts: IMultiSelectTexts = {
    checked: 'Content Subtype selected',
    checkedPlural: 'Content Subtypes selected',
    defaultTitle: 'Content Subtypes',
    searchPlaceholder: 'Find'
  };
  languageSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 2,
    containerClasses: 'multiselect-dropdown-item',
    itemClasses: 'multiselect-dropdown-item-span'
  };

  languageTexts: IMultiSelectTexts = {
    checked: 'Language selected',
    checkedPlural: 'Languages selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Languages'
  };
  contentSourcesSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    containerClasses: 'multiselect-dropdown-item',
    itemClasses: 'multiselect-dropdown-item-span'
  };

  contentSourcesTexts: IMultiSelectTexts = {
    checked: 'Source selected',
    checkedPlural: 'Sources selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Content Sources'
  };
  archivesTexts: IMultiSelectTexts = {
    checked: 'Archive selected',
    checkedPlural: 'Archives selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Archives'
  };
  archivesSettings: IMultiSelectSettings = {
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    containerClasses: 'multiselect-dropdown-item',
    itemClasses: 'multiselect-dropdown-item-span'
  };

  /*
* Configure the ss dropdown texts For Illustration Types
* */
  illustrationTypeText: IMultiSelectTexts = {
    checked: 'Illustration selected',
    checkedPlural: 'Illustrations selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Illustration Types'
  };
  /*
  * Configure the ss dropdown search and btn style For Illustration Types
  * */
  illustrationTypeSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    containerClasses: 'multiselect-dropdown-item',
    itemClasses: 'multiselect-dropdown-item-span'
  };

  @Input('currentTabIndex')
  set _currentTabIndex(value) {
    this.currentActiveTabIndex = value;
  }

  @Output() private limiterChanged: EventEmitter<any> = new EventEmitter();
  @Input() estimatedCount: any;
  @Input() showLoadingTxt: boolean;
  @Output() private saveDone: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private elementReference: ElementRef,
    private notificationService: ModalService,
    private changeDetector: ChangeDetectorRef,
    private sharedService: SharedService,
    private translate: TranslateService,
    /*
   * Auto populate data from search limiter
   * */
    private activatedRoute: ActivatedRoute,
    private urlUtilityService: UrlUtilityService,
    private commonService: CommonService
  ) {

    /*
    * Auto populate data from search limiter
    * */
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this.currentActiveTabIndex = params['ActiveIndex'];
      this.filterParams = params['filter'];
      this.urlUtilityService.parseUrl(this.filterParams).pipe(takeUntil(this.stop$)).subscribe((data) => {
        this.copyAdvancedFilterParam = new AdvancedFilterParams();
        this.advancedFilterParamOut.emit(this.copyAdvancedFilterParam);
        delete this.copyAdvancedFilterParam.currentManuscriptsOffset;
        delete this.copyAdvancedFilterParam.currentMonographsOffset;
        delete this.copyAdvancedFilterParam.currentPhotographsOffset;
        delete this.copyAdvancedFilterParam.currentContentTypeOffset;
        delete this.copyAdvancedFilterParam['searchTerm'];
        if (this.copyAdvancedFilterParam.searchKeywords) {
          this.copyAdvancedFilterParam.searchKeywords.forEach((obj, index) => {
            if (!obj.fieldValue) {
              this.copyAdvancedFilterParam.searchKeywords = this.copyAdvancedFilterParam.searchKeywords.filter(function (element) {
                return element.fieldValue !== '';
              });
            }
          });
        }
        this.populateDataToForm();
      });
    });

    this.publishedDatePrefix.valueChanges.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.publishedDateFrom.setValue(null);
      this.publishedDateTo.setValue(null);
      this.checkDate = false;
    });
    this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(res => {
      this.locationConfigArchives = res['locations'];
    });

    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.commonService.getDropdownLimiters(JSON.parse(localStorage.getItem('archivesList')), data => {
      setTimeout(() => {
        this.pageContent = Object.assign(data);
        this.pageContent.availableContentSubTypes = _orderBy(this.pageContent.availableContentSubTypes, ['name'], ['asc']);
        this.pageContent.availableCollections = _orderBy(this.pageContent.availableCollections, ['name'], ['asc']);
        this.pageContent.availableLanguages = _orderBy(this.pageContent.availableLanguages, ['name'], ['asc']);
        this.pageContent.availableSources = _orderBy(this.pageContent.availableSources, ['name'], ['asc']);
        this.pageContent.availableFields = _orderBy(this.pageContent.availableFields, ['itemName'], ['asc']);
        this.pageContent.availableIllustrationTypes = _orderBy(this.pageContent.availableIllustrationTypes, ['name'], ['asc']);
        this.pageContent.availableArchives = _orderBy(JSON.parse(localStorage.getItem('limitorArchives')), ['name'], ['asc']);
        const archives = [];
        if (res.currentArchive === 'WILEY' || res.currentArchive === 'RGS' || res.currentArchive === 'RCP') {
          this.pageContent.availableArchives.forEach(obj => {
            obj.id = obj.shortName;
            if (obj.shortName) {
              archives.push(obj.shortName);
            }
          });
        } else {
            const obj = _find(res.wileyArchives, _matchesProperty('shortName', res.currentArchive));
            obj['id'] = obj.shortName;
            this.pageContent.availableArchives.push(obj);
            archives.push(res.currentArchive);
            this.pageContent.customerArchives = res.customerArchives;
        }
        if (!sessionStorage.getItem('explorerLimiter')) {
          this.getUpdatedDropdownData({ archive: archives });
        }
        this.copyAdvancedFilterParam.archive = archives;
        if (!sessionStorage.getItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter')) {
          this.archive.setValue(archives);
        }
      }, 100);
    }, err => { });
  }, err => { });
  }
  ngDoCheck() {
    this.changeDetector.detectChanges();
  }

  populateDataToForm() {
    /*
    * Auto populate data from search limiter
    * */
    const TFLimiter = sessionStorage.getItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter');
    if (TFLimiter) {
      const obj = new AdvancedFilterParams(JSON.parse(TFLimiter));
      if (!obj['searchKeywords']) {
        obj['searchKeywords'] = [];
      }
      if (!obj['groupLimit']) {
        obj['groupLimit'] = 0;
      }
      if (!obj['currentOffset']) {
        obj['currentOffset'] = 0;
      }
      if (!obj['facetFields']) {
        obj['facetFields'] = [];
      }
      if (!obj['subject']) {
        obj['subject'] = [];
      }
      delete obj['activeTabIndex'];
      delete obj['currentManuscriptsOffset'];
      delete obj['currentMonographsOffset'];
      delete obj['currentPhotographsOffset'];
      delete obj['currentContentTypeOffset'];
      delete obj['searchTerm'];
      if (obj) {
        this.limiterForm.setValue(obj);
        if (obj.archive) {
          this.archive.setValue(obj.archive);
        }
      }
    } else {
      if (!this.copyAdvancedFilterParam['searchKeywords']) {
        this.copyAdvancedFilterParam['searchKeywords'] = [];
      }
      this.limiterForm.setValue(this.copyAdvancedFilterParam);
      if (this.copyAdvancedFilterParam['publishedDateFrom'] === 'All') {
        this.publishedDatePrefix.setValue('All');
      }
      this.publishedDateFrom.setValue(this.copyAdvancedFilterParam['publishedDateFrom']);
      this.publishedDateTo.setValue(this.copyAdvancedFilterParam['publishedDateTo']);
    }
  }

  setArchive(customerArchives: any, callback): any {
    const wileyArchives = this.pageContent.wileyArchives;
    if (this.pageContent.currentArchive.toUpperCase() === 'WILEY') {
      wileyArchives.splice(0, 1);
    }
    wileyArchives.forEach(obj => {
      if (obj.shortName === this.pageContent.currentArchive) {
        customerArchives = [obj];
      }
    });
    callback(customerArchives);
  }
  ngAfterContentInit() {
    if (this.archiveUnsub) {
      this.archiveUnsub.unsubscribe();
    }
    this.limiterForm.get('archive').valueChanges.pipe(takeUntil(this.stop$)).subscribe(form => {
      this.getUpdatedDropdownData({ archive: form });
    });
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
    });
    this.sharedService.TextAnalysisTabIndexObser.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.activeTabIndex = res;
    });
    this.onFilterOpen();
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });

  }
  populateTooltip(advancedFilters) {
    if (this.pageContent) {
      this.collectionsTooltip = '';
      this.publicationTitleTooltip = '';
      this.contentSourcesTooltip = '';
      this.contentTypeTooltip = '';
      this.contentSubTypesTooltip = '';
      this.illustrationTypesTooltip = '';
      if (advancedFilters.illustrationType && this.pageContent.availableIllustrationTypes instanceof Array) {
        for (
          let index = 0;
          index < advancedFilters.illustrationType.length;
          index++
        ) {
          const element = advancedFilters.illustrationType[index];
          if (element) {
            this.illustrationTypesTooltip += element;
            if (index !== advancedFilters.illustrationType.length - 1) {
              this.illustrationTypesTooltip += ', ';
            }
            if (
              advancedFilters.illustrationType.length ===
              this.pageContent.availableIllustrationTypes.length &&
              advancedFilters.illustrationType.length > 20
            ) {
              this.illustrationTypesTooltip =
                'All Illustration Types Selected';
            } else if (advancedFilters.illustrationType.length > 5) {
              this.illustrationTypesTooltip =
                advancedFilters.illustrationType.length + ' Illustration Types Selected.';
            } else {
              if (this.illustrationTypesTooltip.length >= 100) {
                this.illustrationTypesTooltip = this.illustrationTypesTooltip.substr(0, 100);
                this.illustrationTypesTooltip += '...';
                break;
              }
            }
          }
        }
      }
      if (advancedFilters.contentCollection && this.pageContent.availableCollections instanceof Array) {
        for (
          let index = 0;
          index < advancedFilters.contentCollection.length;
          index++
        ) {
          const element = advancedFilters.contentCollection[index];
          if (element) {
            this.collectionsTooltip += element;
            if (index !== advancedFilters.contentCollection.length - 1) {
              this.collectionsTooltip += ', ';
            }
            if (
              advancedFilters.contentCollection.length ===
              this.pageContent.availableCollections.length &&
              advancedFilters.contentCollection.length > 20
            ) {
              this.collectionsTooltip =
                'All Content Collections Titles Selected';
            } else if (advancedFilters.contentCollection.length > 5) {
              this.collectionsTooltip =
                advancedFilters.contentCollection.length + ' Collections Selected.';
            } else {
              if (this.collectionsTooltip.length >= 100) {
                this.collectionsTooltip = this.collectionsTooltip.substr(0, 100);
                this.collectionsTooltip += '...';
                break;
              }
            }
          }
        }
      }

      if (advancedFilters.languages && this.pageContent.availablePublicationTitles instanceof Array) {
        for (
          let index = 0;
          index < advancedFilters.languages.length;
          index++
        ) {
          const element = advancedFilters.languages[index];
          if (element) {
            this.publicationTitleTooltip += element;
            if (index !== advancedFilters.languages.length - 1) {
              this.publicationTitleTooltip += ', ';
            }
            if (
              advancedFilters.languages.length ===
              this.pageContent.availablePublicationTitles.length &&
              advancedFilters.languages.length > 20
            ) {
              this.publicationTitleTooltip = 'All languages Selected';
            } else if (advancedFilters.languages.length > 5) {
              this.publicationTitleTooltip =
                advancedFilters.languages.length + ' languages Selected.';
            } else {
              if (this.publicationTitleTooltip.length >= 100) {
                this.publicationTitleTooltip = this.publicationTitleTooltip.substr(
                  0,
                  100
                );
                this.publicationTitleTooltip += '...';
                break;
              }
            }
          }
        }
      }

      if (advancedFilters.contentSources && this.pageContent.availableSources instanceof Array) {
        for (
          let index = 0;
          index < advancedFilters.contentSources.length;
          index++
        ) {
          const element = advancedFilters.contentSources[index];
          if (element) {
            this.contentSourcesTooltip += element;
            if (index !== advancedFilters.contentSources.length - 1) {
              this.contentSourcesTooltip += ', ';
            }
          }
        }
        if (this.pageContent.availableSources && advancedFilters.contentSources && advancedFilters.contentSources) {
          // tslint:disable-next-line:max-line-length
          if (advancedFilters.contentSources.length === this.pageContent.availableSources.length && advancedFilters.contentSources.length > 20) {
            this.contentSourcesTooltip = 'All Sources Selected';
          }
        } else if (advancedFilters.contentSources.length > 5) {
          this.contentSourcesTooltip =
            advancedFilters.contentSources.length + ' Sources Selected.';
        } else {
          if (this.contentSourcesTooltip.length >= 100) {
            this.contentSourcesTooltip = this.contentSourcesTooltip.substr(
              0,
              100
            );
            this.contentSourcesTooltip += '...';
          }
        }
      }

      if (advancedFilters.docSubType && this.pageContent.availableContentSubTypes instanceof Array) {
        for (
          let index = 0;
          index < advancedFilters.docSubType.length;
          index++
        ) {
          const element = advancedFilters.docSubType[index];
          if (element) {
            this.contentSubTypesTooltip += element;
            if (index !== advancedFilters.docSubType.length - 1) {
              this.contentSubTypesTooltip += ', ';
            }
            if (
              advancedFilters.docSubType.length ===
              this.pageContent.availableContentSubTypes.length &&
              advancedFilters.docSubType.length > 20
            ) {
              this.contentSubTypesTooltip = 'All Content Subtypes Selected';
            } else if (advancedFilters.docSubType.length > 5) {
              this.contentSubTypesTooltip =
                advancedFilters.docSubType.length +
                ' Content Subtypes Selected.';
            } else {
              if (this.contentSubTypesTooltip.length >= 100) {
                this.contentSubTypesTooltip = this.contentSubTypesTooltip.substr(
                  0,
                  100
                );
                this.contentSubTypesTooltip += '...';
                break;
              }
            }
          }
        }
      }

      if (advancedFilters.contentType) {
        for (
          let index = 0;
          index < advancedFilters.contentType.length;
          index++
        ) {
          const element = advancedFilters.contentType[index];
          if (element) {
            this.contentTypeTooltip += element;
            if (index !== advancedFilters.contentType.length - 1) {
              this.contentTypeTooltip += ', ';
            }
            if (this.contentTypeTooltip.length >= 100) {
              this.contentTypeTooltip = this.contentTypeTooltip.substr(0, 100);
              this.contentTypeTooltip += '...';
              break;
            }
          }
        }
      }
      if (advancedFilters.archive) {
        this.archivesTooltip = '';
        for (let i = 0; i < advancedFilters.archive.length; i++) {
          const element = advancedFilters.archive[i];
          if (element) {
            this.archivesTooltip += element;
            if (i !== advancedFilters.archive.length - 1) {
              this.archivesTooltip += ', ';
            }
            if (this.archivesTooltip.length >= 100) {
              this.archivesTooltip = this.archivesTooltip.substr(0, 100);
              this.archivesTooltip += '...';
              break;
            }
          }
        }
      }
    }
  }

  isFiltered(form): any {
    let hasValues = false;
    Object.keys(form).forEach(key => {
      if (form[key]) {
        if (key === 'searchKeywords') {
          form['searchKeywords'].forEach(element => {
            if (element.fieldValue !== '') {
              hasValues = true;
            }
          });
          // } else if (key === 'archive') {
        } else if (Array.isArray(form[key]) && form[key].length > 0) {
          hasValues = true;
        } else if (key === 'publishedDateFrom') {
          hasValues = true;
        } else if (key === 'publishedDatePrefix') {
          if (form.publishedDatePrefix === 'All') {
            hasValues = true;
          }
        } else if (key === 'publishedDateIncludesNull') {
          if (form.publishedDatePrefix === 'All') {
            hasValues = true;
            this.hasValuesUndated = true;
          }
        }
      }
    });
    if (this.limiterForm.value.contentCollection.length === 0) {
      this.collectionsTooltip = '';
    }
    if (this.limiterForm.value.contentSources.length === 0) {
      this.contentSourcesTooltip = '';
    }
    if (this.limiterForm.value.contentType.length === 0) {
      this.contentTypeTooltip = '';
    }
    if (this.limiterForm.value.docSubType.length === 0) {
      this.contentSubTypesTooltip = '';
    }
    if (this.limiterForm.value.languages.length === 0) {
      this.publicationTitleTooltip = '';
    }
    if (this.limiterForm.value.contentType.length === 0) {
      this.publicationTitleTooltip = '';
    }
    if (this.limiterForm.value.archive.length === 0) {
      this.archivesTooltip = '';
    }
    if (this.limiterForm.value.illustrationType.length === 0) {
      this.illustrationTypesTooltip = '';
    }
    return hasValues;
  }

  onFilterOpen() {
    if (this.limiterForm) {
      const TFLimiter: any = sessionStorage.getItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter');
      if (TFLimiter) {
        const newSettings = new AdvancedFilterParams(JSON.parse(TFLimiter));
        delete newSettings.currentManuscriptsOffset;
        delete newSettings.currentMonographsOffset;
        delete newSettings.currentPhotographsOffset;
        delete newSettings.currentContentTypeOffset;
        // let searchTerm;
        // const searchTermArray = [];
        // searchTerm = sessionStorage.getItem('termFrequency');
        // const searchsTerm = [] = searchTerm.split(',');
        // searchsTerm.forEach(element => {
        //   element = element.replace(/[^a-zA-Z ]/g, '');
        //   newSettings.searchKeywords.push({ fieldName: 'ALL', fieldValue: element, operator: 'AND' });
        //   searchTermArray.push(element);
        // });


        this.limiterForm.setValue(newSettings);
        if (newSettings['publishedDateFrom'] === 'All') {
          this.publishedDatePrefix.setValue('All');
        }
        this.publishedDateFrom.setValue(newSettings['publishedDateFrom']);
        this.publishedDateTo.setValue(newSettings['publishedDateTo']);
      }
      this.populateTooltip(this.limiterForm.value);
      this.limiterFormChangeSubscription = this.limiterForm.valueChanges.pipe(takeUntil(this.stop$)).subscribe(form => {
        this.changeDetector.markForCheck();
        if (this.estimateTimer) {
          clearTimeout(this.estimateTimer);
          this.estimateTimer = null;
        }
        this.estimateTimer = setTimeout(() => {
          if (this.isFiltered(form)) {
            if (form.publishedDatePrefix !== '') {
              if (form.publishedDatePrefix !== 'All') {
                if (form.publishedDatePrefix === 'BETWEEN') {
                  if (form.publishedDateFrom && form.publishedDateTo) {
                    this.checkDate = false;
                    const fullFromDate = form.publishedDateFrom.split('-');
                    const fullToDate = form.publishedDateTo.split('-');
                    if (fullFromDate[0] || fullToDate[0]) {
                      // tslint:disable-next-line:max-line-length
                      if (Math.round(fullFromDate[0]) === 0 || Math.round(fullToDate[0]) === 0 || Math.round(fullFromDate[0]) <= 99 || Math.round(fullToDate[0]) <= 99) {
                        this.checkDate = true;
                        return false;
                      } else if (fullFromDate[1] || fullToDate[1]) {
                        if (((fullFromDate[1] !== '' && Math.round(fullFromDate[1]) === 0) || Math.round(fullFromDate[1]) > 12) ||
                        ((fullToDate[1] !== '' && Math.round(fullToDate[1]) === 0) || Math.round(fullToDate[1]) > 12)) {
                          this.checkDate = true;
                          return false;
                        } else if (fullFromDate[2] || fullToDate[2]) {
                          if (((fullFromDate[2] !== '' && Math.round(fullFromDate[2]) === 0) || Math.round(fullFromDate[2]) > 31) ||
                          ((fullToDate[2] !== '' && Math.round(fullToDate[2]) === 0) || Math.round(fullToDate[2]) > 31)) {
                            this.checkDate = true;
                            return false;
                          }
                          if ((Math.round(fullFromDate[1]) === 4 ||
                              Math.round(fullFromDate[1]) === 6 ||
                              Math.round(fullFromDate[1]) === 9 ||
                              Math.round(fullFromDate[1]) === 11) &&
                              Math.round(fullFromDate[2]) > 30) {
                            this.checkDate = true;
                            return false;
                          }

                          if ((Math.round(fullToDate[1]) === 4 ||
                              Math.round(fullToDate[1]) === 6 ||
                              Math.round(fullToDate[1]) === 9 ||
                              Math.round(fullToDate[1]) === 11) &&
                              Math.round(fullToDate[2]) > 30) {
                            this.checkDate = true;
                            return false;
                          }

                          const checkFromLeapYear = this.leapyear(fullFromDate[0]);
                          if (checkFromLeapYear) {
                            if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 29)) {
                              this.checkDate = true;
                              return false;
                            }
                          } else {
                            if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 28)) {
                              this.checkDate = true;
                              return false;
                            }
                          }

                          const checkToLeapYear = this.leapyear(fullToDate[0]);
                          if (checkToLeapYear) {
                            if ((Math.round(fullToDate[1]) === 2 && Math.round(fullToDate[2]) > 29)) {
                              this.checkDate = true;
                              return false;
                            }
                          } else {
                            if ((Math.round(fullToDate[1]) === 2 && Math.round(fullToDate[2]) > 28)) {
                              this.checkDate = true;
                              return false;
                            }
                          }
                        }
                      }
                    }
                    let fromDate = new Date(form.publishedDateFrom);
                    let toDate = new Date(form.publishedDateTo);
                    const fromDateArray = form.publishedDateFrom.split('-');
                    const toDateArray = form.publishedDateTo.split('-');
                    let fDate = '';
                    let _toDate = '';
                    if (fromDateArray[0]) {
                      fDate = fromDateArray[0];
                      if (fromDateArray[1]) {
                        fDate = fromDateArray[0] + '-' +  fromDateArray[1];
                        if (fromDateArray[2]) {
                          fDate = fromDateArray[0] + '-' +  fromDateArray[1] + '-' +  fromDateArray[2];
                        }
                      }
                    }
                    if (toDateArray[0]) {
                      _toDate = toDateArray[0];
                      if (toDateArray[1]) {
                        _toDate = toDateArray[0] + '-' +  toDateArray[1];
                        if (toDateArray[2]) {
                          _toDate = toDateArray[0] + '-' +  toDateArray[1] + '-' +  toDateArray[2];
                        }
                      }
                    }
                    fromDate = new Date(fDate);
                    toDate = new Date(_toDate);
                    if ((fromDate > toDate) ||
                          fromDate.toString() === 'Invalid Date' ||
                          toDate.toString() === 'Invalid Date' ||
                          form.publishedDateFrom.length <= 2 ||
                          form.publishedDateTo.length <= 2
                        ) {
                      this.checkDate = true;
                      return false;
                    }
                  } else {
                    this.checkDate = true;
                    return false;
                  }
                } else {
                  if (form.publishedDateFrom) {
                    if (form.publishedDateFrom.length > 2) {
                      this.checkDate = false;
                      const fullFromDate = form.publishedDateFrom.split('-');
                      if (fullFromDate[0]) {
                        if (Math.round(fullFromDate[0]) === 0 || Math.round(fullFromDate[0]) <= 99) {
                          this.checkDate = true;
                          return false;
                        } else if (fullFromDate[1]) {
                          if (Math.round(fullFromDate[1]) === 0 || Math.round(fullFromDate[1]) > 12) {
                            this.checkDate = true;
                            return false;
                          } else if (fullFromDate[2]) {
                            if ((fullFromDate[2] !== '' && Math.round(fullFromDate[2]) === 0) || Math.round(fullFromDate[2]) > 31) {
                              this.checkDate = true;
                              return false;
                            }
                            if ((Math.round(fullFromDate[1]) === 4 ||
                                 Math.round(fullFromDate[1]) === 6 ||
                                 Math.round(fullFromDate[1]) === 9 ||
                                 Math.round(fullFromDate[1]) === 11) &&
                                 Math.round(fullFromDate[2]) > 30) {
                              this.checkDate = true;
                              return false;
                            }
                            const checkFromLeapYear = this.leapyear(fullFromDate[0]);
                            if (checkFromLeapYear) {
                              if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 29)) {
                                this.checkDate = true;
                                return false;
                              }
                            } else {
                              if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 28)) {
                                this.checkDate = true;
                                return false;
                              }
                            }
                          }
                        }
                      }
                    } else {
                      this.checkDate = true;
                      return false;
                    }
                  } else {
                    this.checkDate = true;
                    return false;
                  }
                }
              } else {
                this.checkDate = false;
              }
            } else {
              this.checkDate = false;
            }
            this.limiterChanged.emit({isChanged: true, form: form, random: Math.random()});
            this.populateTooltip(form);
          }
          clearTimeout(this.estimateTimer);
        }, 500);
      }
      );
    }
  }

  onFilterClose() {
    if (this.limiterFormChangeSubscription) {
      this.limiterFormChangeSubscription.unsubscribe();
    }
  }
  onContentTypeChange() { }

  togglePubDate() {
    this.showPubDate = !this.showPubDate;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.elementReference.nativeElement.contains(event.target)) {
      if (!$(event.target).is('#publication-date')) {
        this.showPubDate = false;
      }
    }
  }

  resetSearchFilter() {
    const advancedFilterParams = new AdvancedFilterParams();
    delete advancedFilterParams['currentManuscriptsOffset'];
    delete advancedFilterParams['currentMonographsOffset'];
    delete advancedFilterParams['currentPhotographsOffset'];
    delete advancedFilterParams['currentContentTypeOffset'];
    delete advancedFilterParams['searchTerm'];
    this.collectionsTooltip = '';
    this.publicationTitleTooltip = '';
    this.contentSourcesTooltip = '';
    this.contentTypeTooltip = '';
    this.contentSubTypesTooltip = '';
    this.illustrationTypesTooltip = '';
    this.checkDate = false;
    const archives = [];
    this.pageContent.availableArchives.forEach(obj => {
      if (obj.shortName) {
        archives.push(obj.shortName);
      }
    });
    try {
      advancedFilterParams.archive = archives;
      this.limiterForm.setValue(advancedFilterParams);
      sessionStorage.removeItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter');
      if (!sessionStorage.getItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter')) {
        this.archive.setValue(archives);
      }
    } catch (error) {
      this.limiterForm.setValue(advancedFilterParams);
      sessionStorage.removeItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter');
      if (!sessionStorage.getItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter')) {
        this.archive.setValue(archives);
      }
    }
    // if (this.isFromPhotoExplorer) {
    this.searchSubmit(true);
    this.limiterChanged.emit({ isChanged: true, form: this.limiterForm.value, random: Math.random() });
    // }
    this.messageEvent.emit(advancedFilterParams);
  }
  searchSubmit(isReset?) {
    const advancedFilter = this.limiterForm.getRawValue();

    // if (advancedFilter['publishedDatePrefix']) {
    //   if (advancedFilter['publishedDatePrefix'] !== '') {
    //     if (advancedFilter['publishedDatePrefix'] !== 'All') {
    //       if (advancedFilter['publishedDatePrefix'] === 'BETWEEN') {
    //         if (advancedFilter['publishedDateFrom'] && advancedFilter['publishedDateTo']) {
    //           const fullFromDate = advancedFilter.publishedDateFrom.split('-');
    //           const fullToDate = advancedFilter.publishedDateTo.split('-');
    //           this.checkDateBetweenValidation(fullFromDate, fullToDate);
    //           const fromDate = new Date(advancedFilter['publishedDateFrom']);
    //           const toDate = new Date(advancedFilter['publishedDateTo']);

    //           if ((fromDate > toDate) || advancedFilter['publishedDateFrom'].length < 2 || advancedFilter['publishedDateTo'].length < 2) {
    //             this.checkDate = true;
    //             return false;
    //           }
    //         } else {
    //           this.checkDate = true;
    //           return false;
    //         }
    //       } else {
    //         if (advancedFilter.publishedDateFrom) {
    //           this.checkDate = false;
    //           const fullFromDate = advancedFilter.publishedDateFrom.split('-');
    //           this.checkDateValidation(fullFromDate);
    //         } else {
    //           this.checkDate = true;
    //           return false;
    //         }
    //       }
    //     } else {
    //       this.checkDate = false;
    //     }
    //   } else {
    //     this.checkDate = false;
    //   }
    // }
    if (!advancedFilter['archive']) {
      advancedFilter['archive'] = [];
    }

    if (advancedFilter['archive'].length === 0) {
      this.notificationService.showNotification('WARNING', 'WARNING', this.translatedValues.pleaseSelectOneArchive);
      return false;
    }

    sessionStorage.setItem(this.isFromPhotoExplorer ? 'explorerLimiter' : 'TFLimiter', JSON.stringify(advancedFilter));
    // sessionStorage.setItem('explorerLimiter', JSON.stringify(advancedFilter));
    delete advancedFilter['currentManuscriptsOffset'];
    delete advancedFilter['currentMonographsOffset'];
    delete advancedFilter['currentPhotographsOffset'];
    delete advancedFilter['currentContentTypeOffset'];
    delete advancedFilter['currentOffset'];
    delete advancedFilter['facetFields'];
    delete advancedFilter['groupLimit'];
    delete advancedFilter['searchTerm'];

    if (!advancedFilter['contentCollection']) {
      advancedFilter['contentCollection'] = [];
    }
    if (!advancedFilter['contentSources']) {
      advancedFilter['contentSources'] = [];
    }
    if (!advancedFilter['contentType']) {
      advancedFilter['contentType'] = [];
    }
    if (!advancedFilter['languages']) {
      advancedFilter['languages'] = [];
    }
    if (!advancedFilter['docSubType']) {
      advancedFilter['docSubType'] = [];
    }
    if (!advancedFilter['publishedDateFrom']) {
      advancedFilter['publishedDateFrom'] = '';
    }
    if (!advancedFilter['publishedDateIncludesNull']) {
      advancedFilter['publishedDateIncludesNull'] = false;
    }
    if (!advancedFilter['publishedDatePrefix']) {
      advancedFilter['publishedDatePrefix'] = '';
    }
    if (!advancedFilter['publishedDateTo']) {
      advancedFilter['publishedDateTo'] = '';
    }
    if (!advancedFilter['sortFields']) {
      advancedFilter['sortFields'] = [];
    }
    if (!advancedFilter['subject']) {
      advancedFilter['subject'] = [];
    }
    if (!advancedFilter['illustrationType']) {
      advancedFilter['illustrationType'] = [];
    }
    if (advancedFilter['publishedDatePrefix']) {
      if (advancedFilter['publishedDatePrefix'] === 'All') {
        advancedFilter['publishedDateFrom'] = advancedFilter['publishedDatePrefix'];
      }
    }
    advancedFilter['activeTabIndex'] = this.activeTabIndex || 0;
    this.advancedFilterParamOut.emit(advancedFilter);
    this.saveDone.emit({val: advancedFilter});
    if (!isReset) {
      this.popupRef.hide();
    }
  }

  leapyear(year) {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  }

  ngOnDestroy() {
    this.onFilterClose();
    this.stop$.next();
    this.stop$.complete();
  }

  /*
  * DESC: get new limiter values based on arhives values
  * @input: archives array
  * @output: Limiter service response as an Object
  */
  getUpdatedDropdownData(archives: any): void {
    // this.notificationService.showLoader();
    if (archives) {
      this.commonService.getDropdownLimiters(archives.archive, res => {
        if (res) {
          // this.notificationService.hideLoader();
          this.pageContent.availableContentSubTypes = _orderBy(res.availableContentSubTypes, ['name'], ['asc']);
          this.pageContent.availableContentTypes = _orderBy(res.availableContentTypes, ['name'], ['asc']);
          this.pageContent.availableCollections = _orderBy(res.availableCollections, ['name'], ['asc']);
          this.pageContent.availableLanguages = _orderBy(res.availableLanguages, ['name'], ['asc']);
          this.pageContent.availableSources = _orderBy(res.availableSources, ['name'], ['asc']);
          this.pageContent.availablePublicationTitles = _orderBy(res.availablePublicationTitles, ['name'], ['asc']);
          this.pageContent.availableIllustrationTypes = _orderBy(res.availableIllustrationTypes, ['name'], ['asc']);
          this.pageContent.pubDates = res.pubDates;
          this.pageContent.availableSubject = res.availableSubject;
          // this.limiterForm.value.archive = archives.archive;
          this.updateFields(this.limiterForm.value);
        }
      });
    }
  }
  /*
  * DESC: update form data values based on arhives values
  * check values in original array if it`s not then remove from selected array
  * @input: form values
  * @output: updated from values
  */
  updateFields(formData) {
    if (formData.contentType) {
      const arr = this.pageContent.availableContentTypes.filter((item) => {
        return formData.contentType.indexOf(item.name) !== -1;
      });
      formData.contentType = _map(arr, 'id');
      this.contentType.setValue(formData.contentType);
    }
    if (formData.docSubType) {
      const arr = this.pageContent.availableContentSubTypes.filter((item) => {
        return formData.docSubType.indexOf(item.name) !== -1;
      });
      formData.docSubType = _map(arr, 'id');
      this.docSubType.setValue(formData.docSubType);
    }
    if (formData.contentSources) {
      const arr = this.pageContent.availableSources.filter((item) => {
        return formData.contentSources.indexOf(item.name) !== -1;
      });
      formData.contentSources = _map(arr, 'id');
      this.contentSources.setValue(formData.contentSources);
    }
    if (formData.contentCollection) {
      const arr = this.pageContent.availableCollections.filter((item) => {
        return formData.contentCollection.indexOf(item.name) !== -1;
      });
      formData.contentCollection = _map(arr, 'id');
      this.contentCollection.setValue(formData.contentCollection);
    }
    if (formData.languages) {
      const arr = this.pageContent.availableLanguages.filter((item) => {
        return formData.languages.indexOf(item.name) !== -1;
      });
      formData.languages = _map(arr, 'id');
      this.languages.setValue(formData.languages);
    }
    if (formData.illustrationType) {
      const arr = this.pageContent.availableIllustrationTypes.filter((item) => {
        return formData.illustrationType.indexOf(item.name) !== -1;
      });
      formData.illustrationType = _map(arr, 'id');
    }
    const newSettings = new AdvancedFilterParams(formData);
    delete newSettings.currentManuscriptsOffset;
    delete newSettings.currentMonographsOffset;
    delete newSettings.currentPhotographsOffset;
    delete newSettings.currentContentTypeOffset;
    delete newSettings['searchTerm'];
    this.populateTooltip(formData);
  }

  ngAfterViewInit() {
    if (this.isFromCollocation) {
      this.publishDateMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
    } else {
      this.publishDateMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ];
    }
  }

}
