
import { takeUntil } from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {
  Component, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild,
  AfterContentInit, OnDestroy, OnInit
} from '@angular/core';
import { SearchService } from './../common/services/search.service';
import { PageSettingsService } from './../common/services/page-settings.service';
import { ModalService } from '../common/services/modal.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../common/services/sharedService';
import { isEmpty as _isEmpty, orderBy as _orderBy } from 'lodash';
import { TranslateService, LangChangeEvent, DefaultLangChangeEvent } from '@ngx-translate/core';
import { Subscription, Subject } from 'rxjs';
import { environment, APIURLS } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

declare var $: any;
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wiley-searchresult-content-component',
  templateUrl: './wiley-searchresult-content-component.component.html',
  styleUrls: ['./wiley-searchresult-content-component.component.css'],
  preserveWhitespaces: false
})
export class WileySearchresultContentComponentComponent implements AfterContentInit, OnDestroy, OnInit {
  spellCheckSearchTerms: any = [];
  currentIndex = 1;
  isChangeOperator: String = '';
  matchedOperator: any = [];
  showRelevanceHelp: Boolean = false;
  isArabicLanguage: Boolean = false;
  performingAdvSearch: any = 'Performing Advanced Search';
  disableSimpleSearch: boolean;
  secondaryCopySearchTerm: string;
  copySearchTerm: any;
  searchWithAdvParams: any;
  unSubFacetObservable: Subscription;
  unSubPageOberver: Subscription;
  unSubResultobServer: Subscription;
  unSubActivatedRoute: Subscription;
  unSubTranlate: Subscription;
  unSubMapParam: Subscription;
  translatedValues: any;
  controlShortcut: any;
  shortcutKey: any;
  documentData: any;
  docCount: any;
  specialParams: any;
  scrollDisplay: any;
  @Output('enableScroller') enableScroller: EventEmitter<any>;
  // @Input('source') source: any;
  // @ViewChild('searchTermValue') selectTermValues: ElementRef;
  @ViewChild('searchTermValue', { static: false, read: ElementRef }) selectTermValues: ElementRef;

  pageContent: any;
  private facets: any;
  contentTypesFacets: any = [];
  secondarySearch: string;
  searchWithinPlaceHolder: string;
  newSearchPlaceHolder: string;
  hasResults: Boolean = false;
  currentSort: String;
  searchTerm: String;
  showHelp: Boolean = false;
  private searchedKeywords = [];
  private currentContentType: any;
  private mapView: any;
  isFirefox: Boolean;
  selectedContentType: any;
  dropdownValue: any;
  @Input() isContentTypePhoto: Boolean = false;
  @Input() isContentTypeMap: Boolean = false;
  @Input('contentTypeOrder') contentTypeOrder: any;
  @Input() fromDownloadContent: Boolean = false;
  @Input() removeWithSearch: Boolean = false;
  _contentTypeOrder = [];
  contentTypeList = [];
  searchWithinKeyWord: string;
  checkEstimatedCount: any;
  suggestedSearchTerms: any = [];
  suggestedTimeOut: any;
  typeaheadLoading: boolean;
  noSuggestedTermsFound: boolean;
  isDownArrowClicked: boolean;
  showAddQuotesOn: Boolean = false;
  ischangeToOperators: Boolean = false;
  stop$: Subject<any> = new Subject();
  constructor(
    private pageSettingService: PageSettingsService,
    private searchService: SearchService,
    private popupService: ModalService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    setTimeout(() => {
      this.searchTerm = PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm : '';
    }, 0);
    this.enableScroller = new EventEmitter();
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
      this.searchWithinPlaceHolder = this.translatedValues['searchWithInPlaceHolder'] || 'Search within these search results';
      this.newSearchPlaceHolder = this.translatedValues['whatAreYouSearchingFor'] || 'What are you searching for?';
      this.performingAdvSearch = this.translatedValues.performingAdvSearch || 'Performing Advanced Search';
    });


    this.unSubResultobServer = SearchService.resultObservable.pipe(takeUntil(this.stop$)).subscribe((result) => {
      if (result) {
        this.documentData = result;
      }
    });
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.pageContent = data;
      this.pageContent.availableFields = _orderBy(this.pageContent.availableFields, ['itemName'], ['asc']);
      // this.searchTerm = this.pageContent.searchTerm;
      if (Object.keys(data.advancedFilterParam.sortFields).length > 0) {
        this.currentSort = data.advancedFilterParam.sortFields[Object.keys(data.advancedFilterParam.sortFields)[0]];
      } else {
        this.currentSort = '';
      }
      if (this.pageContent) {
        if (window.innerWidth <= 1024) {
          this.pageContent.showLeftMenu = false;
        } else {
          this.pageContent.showLeftMenu = true;
        }
      }
    });


    // if (window.innerWidth > 1024) {
    this.pageContent.showLeftMenu =
      sessionStorage.getItem('LefttMenuStatus') === 'false' ? false : true;
    // }
    SearchService.resultObservable.pipe(takeUntil(this.stop$)).subscribe((result) => {
      this.hasResults = (Array.isArray(result) && result.length > 0) ? true : false;
    });
    SearchService.facetObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.facets = data;
      this.contentTypesFacets = [];
      if (data.length > 0) {
        for (const obj of data) {
          if (obj.facetField === 'contentType') {
            let currentParams;
            const contentTypesParam = obj.facetContent;

            this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
              currentParams = JSON.parse(JSON.stringify(params));
            });
            if (currentParams.display) {
              // contentTypesParam.forEach(element => {
              //   if (element.value === currentParams.display) {
              //     if (this.contentTypesFacets.length > 0) {
              //       this.contentTypesFacets.forEach(elements => {
              //         if (currentParams.display === elements.value) {
              //           elements.count = element.count;
              //         } else {
              //           this.contentTypesFacets.push(element);
              //         }
              //       });
              //     } else {
              // this.contentTypesFacets.push(element);
              this.reOrderContentTypesFacets(obj.facetContent);
              //     }
              //   }
              // });
            } else {
              let count = this.contentTypeList.length;
              if (this.contentTypeList.length > 0) {
                contentTypesParam.forEach(element => {
                  this.contentTypeList.forEach(ele => {
                    if (ele === element.value) {
                      element['order'] = this.contentTypeList.indexOf(ele) + 1;
                    }
                  });
                  if (element.value) {
                    if (element['order'] === undefined) {
                      count++;
                      element['order'] = count;
                    }
                  }
                  // this.contentTypesFacets = obj.facetContent;
                  this.reOrderContentTypesFacets(obj.facetContent);
                });
              } else {
                // contentTypesParam.forEach(element => {
                //   this.contentTypesFacets = obj.facetContent;
                // });
                this.reOrderContentTypesFacets(obj.facetContent);
              }
            }
            // setTimeout(() => {
            //   // const contentType = document.getElementsByClassName('c-type').item(4).setAttribute('style', 'border-right: none');
            // // document.getElementsByClassName("order"+ this.contentTypesFacets.length)[0].style.borderRight = "none";
            // const x = document.getElementById('myDiv');
            // if (this.contentTypesFacets.length === 1) {
            //   const floorElements =
            //   x.getElementsByClassName('child') as HTMLCollectionOf<HTMLElement>;
            //   floorElements[this.contentTypesFacets.length - 1].style.borderRight = 'none';
            // } else {
            //   const floorElementsLen = document.getElementsByClassName('child').length;
            //   for (let i = 0; i < floorElementsLen; i++) {
            //     const floorElement =
            //     document.getElementsByClassName('order' + this.contentTypesFacets.length) as HTMLCollectionOf<HTMLElement>;
            //     floorElement[0].style.borderRight = 'none';
            //   }
            //   // const floorElements = document.getElementsByClassName('child').length;
            //   // const floorElements =
            //   // document.getElementsByClassName('order' + this.contentTypesFacets.length) as HTMLCollectionOf<HTMLElement>;
            //   // floorElements[0].style.borderRight = 'none';
            // }
            // }, 0);
          }
        }
      }
    });
    this.mapView = sessionStorage.getItem('isInMapView');
    this.sharedService.mapParam.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (!_isEmpty(res)) {
        this.specialParams = res;
      }
    });
  }
  reOrderContentTypesFacets(facets) {
    const unOrderFacet = facets;
    this.contentTypesFacets = [];
    for (let i = 0; i < unOrderFacet.length; i++) {
      if ((unOrderFacet[i].value === 'Manuscripts' &&
        (!(this.contentTypeList.length > 0)) &&
        (this.isContentTypePhoto === false &&
          this.isContentTypeMap === false)) ||
        (this.isContentTypePhoto && unOrderFacet[i].value === 'Photographs') ||
        (this.isContentTypeMap && unOrderFacet[i].value === 'Maps') ||
        (this.contentTypeList.length > 0 && unOrderFacet[i].order === 1)) {
        this.contentTypesFacets.push({ value: unOrderFacet[i].value, count: unOrderFacet[i].count });
      }
    }
    for (let i = 0; i < unOrderFacet.length; i++) {
      if ((unOrderFacet[i].value === 'Monographs' &&
        (!(this.contentTypeList.length > 0)) &&
        (this.isContentTypePhoto === false &&
          this.isContentTypeMap === false)) ||
        (this.isContentTypePhoto && unOrderFacet[i].value === 'Manuscripts') ||
        (this.isContentTypeMap && unOrderFacet[i].value === 'Manuscripts') ||
        (this.contentTypeList.length > 0 && unOrderFacet[i].order === 2)) {
        this.contentTypesFacets.push({ value: unOrderFacet[i].value, count: unOrderFacet[i].count });
      }
    }

    for (let i = 0; i < unOrderFacet.length; i++) {
      if ((unOrderFacet[i].value === 'Photographs' &&
        (!(this.contentTypeList.length > 0)) &&
        (this.isContentTypePhoto === false && this.isContentTypeMap === false)) ||
        (this.isContentTypePhoto && unOrderFacet[i].value === 'Maps') ||
        (this.isContentTypeMap && unOrderFacet[i].value === 'Photographs' ||
          (this.contentTypeList.length > 0 && unOrderFacet[i].order === 3))) {
        this.contentTypesFacets.push({ value: unOrderFacet[i].value, count: unOrderFacet[i].count });
      }
    }

    for (let i = 0; i < unOrderFacet.length; i++) {
      if ((unOrderFacet[i].value === 'Maps' &&
        (!(this.contentTypeList.length > 0)) &&
        (this.isContentTypePhoto === false && this.isContentTypeMap === false)) ||
        (this.contentTypeList.length > 0 && unOrderFacet[i].order === 4)) {
        this.contentTypesFacets.push({ value: unOrderFacet[i].value, count: unOrderFacet[i].count });
      }
    }
  }
  ngOnInit() {
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    if (this.contentTypeOrder !== undefined) {
      this._contentTypeOrder = this.contentTypeOrder.split(',');
      this._contentTypeOrder.forEach(element => {
        if (element === 'mo') {
          this.contentTypeList.push('Monographs');
        } else if (element === 'ms') {
          this.contentTypeList.push('Manuscripts');
        } else if (element === 'ph') {
          this.contentTypeList.push('Photographs');
        } else if (element === 'ma') {
          this.contentTypeList.push('Maps');
        }
      });
    }
  }

  ngAfterContentInit() {
    this.sharedService.language.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.dropdownValue = res;
      if (this.dropdownValue.help === true) {
        this.showHelp = true;
      } else {
        this.showHelp = false;
      }

      if (this.dropdownValue.relevance === true) {
        this.showRelevanceHelp = true;
      } else {
        this.showRelevanceHelp = false;
      }
      // if (this.dropdownValue.revise === true) {
      //   this.pageContent.showAdvancedFilter = true;
      // } else {
      //   this.pageContent.showAdvancedFilter = false;
      // }
    });
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKey = 'Shift + Alt';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKey = 'Shift + Option';
    }
    this.translate.onDefaultLangChange.pipe(takeUntil(this.stop$)).subscribe((event: DefaultLangChangeEvent) => {
      this.searchWithinPlaceHolder = event.translations.Common.Popups.searchWithInPlaceHolder || 'Search within these search results';
      this.newSearchPlaceHolder = event.translations.Common.Popups.whatAreYouSearchingFor || 'What are you searching for?';
      this.performingAdvSearch = event.translations.Common.performingAdvSearch || 'Performing Advanced Search';
    });
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      if (event.translations.Common.Popups.searchWithInPlaceHolder) {
        this.searchWithinPlaceHolder = event.translations.Common.Popups.searchWithInPlaceHolder || 'Search within these search results';
      }
      if (event.translations.Common.Popups.whatAreYouSearchingFor) {
        this.newSearchPlaceHolder = event.translations.Common.Popups.whatAreYouSearchingFor || 'What are you searching for?';
      }
      if (event.translations.Common.performingAdvSearch) {
        this.performingAdvSearch = event.translations.Common.performingAdvSearch || 'Performing Advanced Search';
      }
    });
    // this.shortcutKey = localStorage.getItem('ShortcutKey');
    // this.controlShortcut = localStorage.getItem('ControlShortcutKey');
    $(document).on('keydown', (e) => {
      if (e.ctrlKey && e.altKey && e.keyCode === 74) {
        this.revise();
      }
    });
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.isFirefox = true;
    } else {
      this.isFirefox = false;
    }
  }

  searchSettingChange(value) {
    const searchKeywordLength: number = PageSettingsService.pageSettings.advancedFilterParam.searchKeywords.length;
    let counter = 1;
    let advancedSearchKeyword = '';
    PageSettingsService.pageSettings.advancedFilterParam.searchKeywords.forEach(element => {
      if (element.fieldName === 'ALL') {
        if (counter < searchKeywordLength) {
          advancedSearchKeyword += element.fieldValue + ' ';
        } else {
          advancedSearchKeyword += element.fieldValue;
        }
        counter++;
      }
    });
    if (value === 'true') {
      this.searchWithinKeyWord = advancedSearchKeyword ? advancedSearchKeyword : '';
    }
    this.searchTerm = PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm : '';
    this.pageSettingService.updateSetting('isSearchWithIn', value === 'true' ? true : false);
    // this.secondarySearch = '';
    // if (value === 'false') {
    //   // this.pageContent.searchTerm = '';
    //   this.pageSettingService.updateSetting('searchTerm', this.pageContent.searchTerm);
    //   this.pageSettingService.updateSearchSettings(new AdvancedFilterParams());
    // }
  }

  simpleSearch() {
    if (this.fromDownloadContent) {
      this.searchService.getdocument();
      return;
    }
    this.sharedService.updateFacetFlag(false);
    /*
    * start: If drop down is open then simple search must invoke advanced search
    */
    if (this.pageContent.showAdvancedFilter) {
      // this.searchWithAdvParams = this.pageContent.showAdvancedFilter;
      this.sharedService.invokeAdvSearchCall(this.pageContent.showAdvancedFilter);
      return;
    }
    /*
    * end
    */
    const searchKey = this.selectTermValues.nativeElement.value.trim();
    let searchkey = searchKey.trim();
    if (!this.pageContent.isSearchWithIn) {
      if (searchkey) {
        if (searchkey.includes('-')) {
          if (searchkey.includes('"')) {
            searchkey = searchkey;
          } else {
            // searchkey = '"' + searchkey + '"';
          }
        }
        this.searchService.goToSearchPage(searchkey);
      }
    } else {
      if (searchkey) {
        if (searchkey.includes('-')) {
          if (searchkey.includes('"')) {
            searchkey = searchkey;
          } else {
            // searchkey = '"' + searchkey + '"';
          }
        }
      }
      const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
      if (currentSearchSettings.searchKeywords[0]) {
        if (currentSearchSettings.searchKeywords[0].fieldValue === '') {
          currentSearchSettings.searchKeywords.push({
            fieldValue: searchkey.replace(/[^a-zA-Z0-9 ?*-]/g, ''),
            fieldName: 'ALL',
            operator: 'AND'
          });
        } else if (currentSearchSettings.searchKeywords.length < 8) {
          currentSearchSettings.searchKeywords.forEach(element => {
            this.searchedKeywords.push(element.fieldValue.replace(/[^a-zA-Z0-9 ?*-]/g, ''));
          });
          if (this.searchedKeywords.indexOf(searchkey) > -1) {
            // this.modalService.showAlertPopup('Warning', 'The keyword ' + '"' + searchKey + '"' + ' is already in filters'	, null);
            return false;
          } else {
            currentSearchSettings.searchKeywords.push({
              fieldValue: searchkey.replace(/[^a-zA-Z0-9 ?*-]/g, ''),
              fieldName: 'ALL',
              operator: 'AND'
            });
          }
        } else {
          return false;
        }
      } else {
        let noKeyIsEmpty = true;
        currentSearchSettings.searchKeywords = currentSearchSettings.searchKeywords.map((key) => {
          if (key.fieldValue === '' && noKeyIsEmpty) {
            key.fieldValue = searchkey.replace(/[^a-zA-Z0-9 ?*-]/g, '');
            noKeyIsEmpty = false;
          }
          return key;
        });
        if (noKeyIsEmpty) {
          currentSearchSettings.searchKeywords.push({
            fieldValue: searchkey.replace(/[^a-zA-Z0-9 ?*-]/g, ''),
            fieldName: 'ALL',
            operator: 'AND'
          });
        }
      }
      this.pageSettingService.updateSearchSettings(currentSearchSettings);
      this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
    }
    if (sessionStorage.getItem('isFromAnalysisHubPage')) {
      sessionStorage.removeItem('isFromAnalysisHubPage');
    }
  }

  showContentType(type) {
    sessionStorage.setItem('display', type.value);
    this.getDocumentCount(type);
    let specialParams = null;
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      currentParams['display'] = type.value;
      const checkparamValue = currentParams['display'];
      this.currentContentType = checkparamValue;
      this.enableScroller.emit(checkparamValue);
      this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
      // this.popupService.showLoader();
      this.pageSettingService.updateSetting('groupsToBeDisplayed', [type.value]);
      this.scrollDisplay = checkparamValue;
      this.selectedContentType = this.scrollDisplay;
      if (type.value === 'Photographs' && this.mapView.toString() === 'false') {
        if (this.specialParams) {
          if (this.specialParams.spatialFilterParams) {
            specialParams = this.specialParams.spatialFilterParams;
          }
        }
        this.searchService.viewMoreResultsScroll(type.value, this.docCount, type.count, specialParams);
      } else {
        if (this.specialParams) {
          if (this.specialParams.spatialFilterParams) {
            specialParams = this.specialParams.spatialFilterParams;
          }
        }
        this.searchService.viewMoreResultsScroll(type.value, this.docCount, type.count, specialParams);
      }
      setTimeout(() => {
        // this.popupService.hideLoader();
      }, 1000);
    });
  }

  onSortOrderChange(order) {
    if (this.fromDownloadContent) {
      this.searchService.getdocument(order);
      return;
    }
    let checkparamValue;
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      checkparamValue = currentParams['display'] ? currentParams['display'] : this.currentContentType;
    });

    if (order === 'relevance') {
      currentSearchSettings.sortFields = {};
    } else {
      if (order === 'asc') {
        currentSearchSettings.sortFields = {
          pubStartDate: order
        };
      } else {
        currentSearchSettings.sortFields = {
          pubEndDate: order
        };
      }
    }
    if (sessionStorage.getItem('display')) {
      checkparamValue = sessionStorage.getItem('display');
    }
    if (this.specialParams) {
      if (this.specialParams.spatialFilterParams) {
        if (this.specialParams.spatialFilterParams[0].shape === 'circle') {
          const spatialSearchParams = {
            latlng: {
              lat: this.specialParams.spatialFilterParams[0].location.lat,
              lng: this.specialParams.spatialFilterParams[0].location.lon
            },
            northEast: {
              lat: null,
              lng: null
            },
            radius: this.specialParams.spatialFilterParams[0].radius * 1852,
            southWest: {
              lat: null,
              lng: null
            },
            type: this.specialParams.spatialFilterParams[0].shape
          };
          this.searchService.spatialSearch(spatialSearchParams);
        } else if (this.specialParams.spatialFilterParams[0].shape === 'rectangle') {
          const spatialSearchParams = {
            latlng: null,
            northEast: {
              lat: this.specialParams.spatialFilterParams[0].topRight.lat,
              lng: this.specialParams.spatialFilterParams[0].topRight.lon
            },
            radius: null,
            southWest: {
              lat: this.specialParams.spatialFilterParams[0].lowerLeft.lat,
              lng: this.specialParams.spatialFilterParams[0].lowerLeft.lon
            },
            type: this.specialParams.spatialFilterParams[0].shape
          };
          this.searchService.spatialSearch(spatialSearchParams);
        }
      } else {
        this.pageSettingService.updateSearchSettings(currentSearchSettings);
        this.searchService.goToAdvancedSearchPage(this.pageContent.searchTerm, currentSearchSettings, checkparamValue, true);
      }
    } else {
      this.pageSettingService.updateSearchSettings(currentSearchSettings);
      this.searchService.goToAdvancedSearchPage(this.pageContent.searchTerm, currentSearchSettings, checkparamValue, true);
    }
    setTimeout(() => {
      this.sharedService.updateFacetFlag(false);
    }, 500);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // // const totalWidth = $('#search_textbox').width() + 78;
    // const totalWidth = $('.dropdown-box.text-right.parent.searchbox-revise').width() - 85;
    // // const fromLeft = $('span.input-group-addon.search-checkbox').width();
    // $('ul.suggested-terms-list').attr('style', 'width:' + totalWidth + 'px');
    // $('.suggested-loading-text').attr('style', 'width:' + totalWidth + 'px');
    // $('.pharse-div').attr('style', 'width:' + totalWidth + 'px');
    this.suggesterTermResponsive();
  }
  suggesterTermResponsive() {
    const totalWidth = $('.dropdown-box.text-right.parent.searchbox-revise').width() - 85;
    $('ul.suggested-terms-list').attr('style', 'width:' + totalWidth + 'px !important');
    $('.suggested-loading-text').attr('style', 'width:' + totalWidth + 'px !important');
    $('.pharse-div').attr('style', 'width:' + totalWidth + 'px !important');
  }
  toggleFilters() {
    this.pageContent.showLeftMenu = !this.pageContent.showLeftMenu;
    sessionStorage.setItem('LefttMenuStatus', this.pageContent.showLeftMenu.toString());
    this.sharedService.updateLeftMenuStatus(this.pageContent.showLeftMenu);
  }
  openHelp(event) {
    //  this.showHelp = !this.showHelp;
    this.pageContent.showAdvancedFilter = false;
    event.stopPropagation();
    if (!this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: true,
        language: false,
        shareDocument: false
      });
    } else if (this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false
      });
    }
  }
  revise() {
    this.pageContent.showAdvancedFilter = !this.pageContent.showAdvancedFilter;
    if (this.pageContent.showAdvancedFilter) {
      this.secondaryCopySearchTerm = this.secondarySearch;
      this.copySearchTerm = this.searchTerm;
      this.secondarySearch = '';
      this.searchTerm = '';
      $('#search_textbox').attr('title', this.performingAdvSearch);
    } else {
      if (this.pageContent.isSearchWithIn) {
        $('#search_textbox').attr('title', this.searchWithinPlaceHolder);
      } else {
        $('#search_textbox').attr('title', this.newSearchPlaceHolder);
      }
      this.searchTerm = this.copySearchTerm;
      this.secondarySearch = this.secondaryCopySearchTerm;
    }
    this.showHelp = false;
    if (this.pageContent.showAdvancedFilter) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: true
      });
    } else if (!this.pageContent.showAdvancedFilter) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false
      });
    }
    if (sessionStorage.getItem('isFromAnalysisHubPage')) {
      sessionStorage.removeItem('isFromAnalysisHubPage');
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    // this.showHelp = false;
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false
    });
    if ($(event.target).is('#search_textbox') || $(event.target).is('#searchin') || $(event.target).is('#newsearch') ||
      $(event.target).is('.search-checkbox')) {
      const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
      currentSelectedArchivesSetting.showAdvancedFilter = false;
      this.pageSettingService.updatePageSettings(currentSelectedArchivesSetting);
    }
    // if (!(this.elementReference.nativeElement.contains(event.target))) {
    //   const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    //   currentSelectedArchivesSetting.showAdvancedFilter = false;
    //   this.pageSettingService.updatePageSettings(currentSelectedArchivesSetting);
    // }
  }

  getDocumentCount(type: any): void {
    this.documentData.map(group => {
      if (group.group === type.value) {
        this.docCount = group.documents.length;
      }
    });
  }
  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 40) {
      if (this.suggestedSearchTerms) {
        if (this.suggestedSearchTerms.length > 0) {
          if (this.currentIndex < this.suggestedSearchTerms.length) {
            this.isDownArrowClicked = true;
            this.currentIndex++;
            document.getElementById('search_textbox').blur();
            document.getElementById('handleFocusOnSuggestedTerms').focus();
          }
        }
      }
    }
    if (event.keyCode === 38) {
      if (this.suggestedSearchTerms) {
        if (this.suggestedSearchTerms.length > 0) {
          if (this.currentIndex > 1) {
            this.isDownArrowClicked = true;
            this.currentIndex--;
            document.getElementById('search_textbox').blur();
            document.getElementById('handleFocusOnSuggestedTerms').focus();
          }
        }
      }
    }
    if (event.keyCode === 13) {
      if (this.suggestedSearchTerms) {
        if (this.isDownArrowClicked) {
          if (this.suggestedSearchTerms.length > 0) {
            const searchTerm = $('.active-suggested-term .sugg-term').text().trim();
            if (this.selectTermValues) {
              if (this.selectTermValues.nativeElement.value.includes('"')) {
                this.selectTermValues.nativeElement.value = '"' + searchTerm + '"';
              } else {
                this.selectTermValues.nativeElement.value = searchTerm;
              }
            }
            setTimeout(() => {
              document.getElementById('search_textbox').focus();
            }, 500);
          }
        }
      }
    }
    if (event.ctrlKey && event.keyCode === 13) {
      setTimeout(() => {
        if (this.selectTermValues) {
          // if (this.selectTermValues.nativeElement.value.includes('"')) {
          //   this.selectTermValues.nativeElement.value = '"' + this.selectTermValues.nativeElement.valu + '"';
          // } else {
          //   this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.valu;
          // }
          this.selectTermValues.nativeElement.value = '"' + this.selectTermValues.nativeElement.value + '"';
        }
      }, 500);
    }
    const currentSort = $('#relevance_sort option:selected').text();
    if (event.shiftKey && event.altKey && event.keyCode === 82) {
      if (currentSort === 'Relevance ') {
        this.onSortOrderChange('asc');
      }
      if (currentSort.includes('Oldest')) {
        this.onSortOrderChange('desc');
      }
      if (currentSort.includes('Latest')) {
        this.onSortOrderChange('relevance');
      }
    }
  }


  advFilterClosed(event): void {
    this.searchTerm = this.copySearchTerm;
    this.secondarySearch = this.secondaryCopySearchTerm;
  }
  disableSearch(event) {
    this.disableSimpleSearch = !event;
  }
  openRelevanceHelp(event) {
    //  this.showHelp = !this.showHelp;
    this.pageContent.showAdvancedFilter = false;
    event.stopPropagation();
    if (!this.showRelevanceHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        relevance: true
      });
    } else if (this.showRelevanceHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        relevance: false
      });
    }
  }
  estimatedCount(estimatedCounts) {
    this.checkEstimatedCount = estimatedCounts;
  }
  changeToOperators() {
    this.ischangeToOperators = !this.ischangeToOperators;
    const and = / and /gi;
    const or = / or /gi;
    const not = / not /gi;
    if (this.ischangeToOperators) {
      this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.value.replace(and, ' AND ');
      this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.value.replace(or, ' OR ');
      this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.value.replace(not, ' NOT ');
    } else if (!this.ischangeToOperators) {
      this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.value.replace(and, ' and ');
      this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.value.replace(or, ' or ');
      this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.value.replace(not, ' not ');
    }
    setTimeout(() => {
      $('.active-suggested-term').focus();
    }, 100);
  }
  stringtoArray(terms) {
    const checkOperators = ['AND', 'OR', 'NOT'];
    const newOperatorArray = [];
    this.matchedOperator = [];
    const splittedTermsArray = terms.trim().split(' ');
    splittedTermsArray.forEach(element => {
      if (checkOperators.indexOf(element) === -1) {
        newOperatorArray.push(element.toUpperCase());
      }
    });
    checkOperators.forEach(element => {
      if (newOperatorArray.indexOf(element) !== -1) {
        this.matchedOperator.push(element);
      }
    });
    return this.matchedOperator;
  }
  onSearchChange(term) {
    this.isDownArrowClicked = false;
    this.noSuggestedTermsFound = false;
    const checkOperators = ['AND', 'OR', 'NOT'];
    term = term.trim();
    const splittedTermsArray = this.stringtoArray(term);
    this.isChangeOperator = '';
    this.isChangeOperator = splittedTermsArray.join('","');

    const searchTerms = term.match(/[^\s"]+|"([^"])+"~[0-9]{1,5}|"([^"])+"/g);
    if (searchTerms) {
      searchTerms.forEach(key => {
        if (/^".*"$/.test(key)) {
          this.isChangeOperator = '';
        }
      });
    }

    this.currentIndex = 1;
    if (this.suggestedTimeOut) {
      clearTimeout(this.suggestedTimeOut);
    }
    const archive = PageSettingsService.pageSettings.currentArchive === 'WILEY' ?
      PageSettingsService.pageSettings.currentSelectedArchives : [PageSettingsService.pageSettings.currentArchive];
    this.suggestedTimeOut = setTimeout(() => {
      if (term.includes('"')) {
        term = term.replace(/"/g, ' ');
      }
      const searchTermwithSpaces = (/\s/.test(term));
      let stopWordsFromString;
      if (searchTermwithSpaces) {
        stopWordsFromString = term.split(' ');
      }
      if (stopWordsFromString) {
        const BooleanOperators = ['AND', 'OR', 'NOT'];
        stopWordsFromString.forEach((op, index) => {
          if ((BooleanOperators.indexOf(stopWordsFromString[index]) > -1 &&
            BooleanOperators.indexOf(stopWordsFromString[index + 1]) > -1) ||
            stopWordsFromString[0] === 'AND' || stopWordsFromString[0] === 'OR') {
            stopWordsFromString[index] = '';
          }
          if (stopWordsFromString[index] === '' && BooleanOperators.indexOf(stopWordsFromString[index + 1]) > -1) {
            stopWordsFromString[index + 1] = '';
          }
          if ((stopWordsFromString[stopWordsFromString.length - 1] === 'AND') ||
            (stopWordsFromString[stopWordsFromString.length - 1] === 'OR') ||
            ((stopWordsFromString[stopWordsFromString.length - 1] === 'NOT'))) {
            stopWordsFromString.pop();
          }
        });
        stopWordsFromString = stopWordsFromString.filter(elem => {
          return elem.trim() !== '';
        });
        term = stopWordsFromString.toString().replace(/,/g, ' ');
        this.callSuggestedTerms(term, archive);
      } else {
        this.callSuggestedTerms(term, archive);
      }
    }, 1000);
  }
  callSuggestedTerms(term, archive) {
    if (term.length > 2 && archive.length > 0) {
      const wileySelectedArchiveList = JSON.parse((localStorage.getItem('wileySelectedArchive')));
      if (PageSettingsService.pageSettings.currentArchive === 'RGS') {
        archive = wileySelectedArchiveList;
      }
      term = term.replace(/[^a-zA-Z0-9 ?*-]/g, '');
      this.suggestedSearchTerms = [];
      if (term !== '') {
        this.typeaheadLoading = true;
        // tslint:disable-next-line:max-line-length
        this.httpClient.post(environment.APIUrl + APIURLS['getSuggestedTerms'], { 'lookup': term, 'archive': archive }).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res) {
            if (res['suggesterResult']) {
              if (res['suggesterResult'].length > 0) {
                this.suggestedSearchTerms = res['suggesterResult'];
                this.showAddQuotesOn = false;
                this.typeaheadLoading = false;
                this.noSuggestedTermsFound = false;
              } else {
                // if (res['spellCheckResult'].length > 0 && res['suggesterResult'].length > 0) {
                this.suggestedSearchTerms = [];
                this.typeaheadLoading = false;
                this.noSuggestedTermsFound = true;
                // }
              }
            } else {
              this.suggestedSearchTerms = [];
              this.typeaheadLoading = false;
              this.noSuggestedTermsFound = true;
            }
            // if (res['spellCheckResult']) {
            //   const spellchkarray = [];
            //   if (res['spellCheckResult'].length > 0) {
            //     res['spellCheckResult'].forEach(obj => {
            //       spellchkarray.push(obj);
            //     });
            //     this.spellCheckSearchTerms = spellchkarray[0];
            //     this.noSuggestedTermsFound = false;
            //     this.typeaheadLoading = false;
            //   } else {
            //     this.spellCheckSearchTerms = [];
            //     this.noSuggestedTermsFound = true;
            //     this.typeaheadLoading = false;
            //   }
            //   if (res['suggesterResult']) {
            //     if (res['suggesterResult'].length > 0) {
            //       this.suggestedSearchTerms = res['suggesterResult'];
            //       this.showAddQuotesOn = false;
            //       this.typeaheadLoading = false;
            //       this.noSuggestedTermsFound = false;
            //     } else {
            //       if (res['spellCheckResult'].length > 0 && res['suggesterResult'].length > 0) {
            //         this.suggestedSearchTerms = [];
            //         this.typeaheadLoading = false;
            //         this.noSuggestedTermsFound = true;
            //       }
            //     }
            //   } else {
            //     this.suggestedSearchTerms = [];
            //     this.typeaheadLoading = false;
            //     this.noSuggestedTermsFound = true;
            //   }
            // }
          }
        });
      }
    } else {
      this.suggestedSearchTerms = [];
    }
  }
  suggestedTermsMouseEnter(term, index) {
    this.currentIndex = index;
  }
  suggestedTermsMouseLeave(term, index) {

  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  typeaheadOnSelect(term, index) {
    if (index) {
      this.currentIndex = index;
    }
    if (term) {
      this.selectTermValues.nativeElement.value = term;
    }
  }
  addQuotes(term, index) {
    this.currentIndex = index;
    this.selectTermValues.nativeElement.value = '"' + term + '"';
  }
  addPharse() {
    if (this.selectTermValues.nativeElement.value.includes('"')) {
    } else {
      this.selectTermValues.nativeElement.value = '"' + this.selectTermValues.nativeElement.value + '"';
    }
  }
  removePharse() {
    if (this.selectTermValues.nativeElement.value.includes('"')) {
      this.selectTermValues.nativeElement.value = this.selectTermValues.nativeElement.value.replace(/"/g, '');
    }
  }
  toggle(e) {
    if (e.target.checked === true) {
      this.showAddQuotesOn = true;
      this.addPharse();
    } else {
      this.showAddQuotesOn = false;
      this.removePharse();
    }
  }
  spellCheckSearchTerm(term) {
    this.selectTermValues.nativeElement.value = term;
  }
  disableRightClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
