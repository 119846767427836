
import {takeUntil} from 'rxjs/operators';
import {
  Component, OnInit, Input, TemplateRef, ViewChild, AfterViewInit,
  HostListener, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpService } from '../../common/services/http.service';
import { AdvancedFilterParams } from '../../common/models/AdvancedFilterParams';
import { ExplorerService } from '../services/explorer.service';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { SearchService } from '../../common/services/search.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../common/services/modal.service';
// import { PerfectScrollbarDirective, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { environment, APIURLS } from '../../../environments/environment';
import { Subject,  BehaviorSubject, Subscription } from 'rxjs';
import { SharedService } from '../../common/services/sharedService';
import { BasicImageViewerService } from '../../common/services/basic-image-viewer.service';
import { Location } from '@angular/common';
import { UrlUtilityService } from '../../common/services/url-utility.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from '../../common/services/google-analytics.service';
import { map as _map } from 'lodash';
declare let $: any;

@Component({
  selector: 'app-map-explorer',
  templateUrl: './map-explorer.component.html',
  styleUrls: ['./map-explorer.component.css'],
  providers: [BasicImageViewerService]
})
export class MapExplorerComponent implements OnInit, AfterViewInit, OnDestroy {
  insetMapPage: any;
  currentDocId: any;
  insetMapData: any;
  private insetMapTemplatemodalRef: BsModalRef;
  // @ViewChild('insetMapPageTemplate') private insetMapPageTemplate: TemplateRef<any>;
  @ViewChild('insetMapPageTemplate', { static: false }) private insetMapPageTemplate: TemplateRef<any>;
  private photoLimitTemplatemodalRef: BsModalRef;

  @ViewChild('filterTemplate', { static: false }) private filterTemplate: TemplateRef<any>;

  insetMapArray: any = [];
  private photoExplorerInsetTemplatemodalRef: BsModalRef;
  // @ViewChild('photoExplorerInsetTemplate') private photoExplorerInsetTemplate: TemplateRef<any>;
  @ViewChild('photoExplorerInsetTemplate', { static: false }) private photoExplorerInsetTemplate: TemplateRef<any>;

  toggleFullText: Boolean = false;
  yearLimit: any;
  limitRange: Boolean;
  initFilterParam: any = {};
  intialMaxYear: number;
  intialMinYear: number;
  showLimit: Boolean;
  advancedFilterParam: any = {};
  showMap: Boolean;
  yearFrom: any;
  yearTo: any;
  rangeArray: Number[] = [0, 0];
  maxYearValue: Number = 0;
  minYearValue: Number = 0;
  private stop$: Subject<void> = new Subject<void>();
  isIpad: Boolean;
  isMobileView: Boolean;
  message: Boolean;
  showLimiters: Boolean;
  expandCount: number;
  expandLevelTwo: Boolean;
  expandLevelOne: Boolean;
  showDocView: Boolean;
  _removeDropPin: any;
  citationCache: any = {};
  tileSourceObj: any;
  unSubPageSettingsObservable: Subscription;
  pageContentData: any;
  isCitationLoading: Boolean = false;
  documentCitation: any;
  citationContainer: Boolean = false;
  disableNavigations: any;
  previewImageObj: String;
  _coordinatesToZoom = { coordinates: [], random: this.getRandomNumber() };
  isNavigationEnabled: Boolean = true;
  loadMoreLoader: Boolean = false;
  isSearchBtnEnable: boolean;
  toggledisclaimerShow: Boolean = true;
  // @ViewChild('popupRef') popupReference: PopoverDirective;
  @ViewChild('popupRef', { static: false }) popupReference: PopoverDirective;

  pageContent: any;
  resetMapData = { value: true, random: Math.random() };
  /*
  * DESC: for popup caption view
  * */
  readmoreId: Subject<any> = new BehaviorSubject(Math.random());
  totalRecoards: any = 0;
  // @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  // @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  // @ViewChild('PerfectScrollbarComponent', { static: false}) componentRef?: PerfectScrollbarComponent;
  // @ViewChild('PerfectScrollbarDirective', { static: false}) directiveRef?: PerfectScrollbarDirective;

  hightScrollTop: string;
  spatialFilterParams: any;
  // scrollTarget: HTMLElement;
  defaultImage = '/assets/images/loading.gif';
  errorImage = '/assets/images/noimage.jpg';
  loading: boolean;
  searchTerm: string;
  homePageUrl: string;
  isFromPhotoExplorer = { value: true, random: Math.random() };
  advancedParams: any;
  isMapsExplorerExapnd: Boolean = false;
  accessToken: any;
  tilesource: any;
  private photoExplorerTemplatemodalRef: BsModalRef;
  // @ViewChild('photoExplorerTemplate') private photoExplorerTemplate: TemplateRef<any>;
  @ViewChild('photoExplorerTemplate', { static: false }) private photoExplorerTemplate: TemplateRef<any>;

  activeSlideIndex: number;
  currentSlide: any;
  _albums = [];
  copyAdvancedFilterParam: any = {};
  _filterInputs: any;
  limiterList: any;
  newFilterParams = [];
  showLoadingTxt: Boolean = false;
  @Input('limitLabel') limitLabel: any;
  isArabicLanguage: boolean;
  mapCoordinatesNotNull: Boolean = false;
  estimatedCount: number;
  globepageId: any;
  isMoreRecords: Boolean;
  limitArchivrDropdown: any;
  enableWiley: boolean;
  set _limitLabel(value) {
    this.limitLabel = value;
  }

  get _limitLabel() {
    return this.limitLabel;
  }
  @Input('filterInputs')
  set filterInputs(value) {
    this._filterInputs = value;
    this.limiterList = this._filterInputs.filterParams.filterParams;
  }
  isFromPage: any;
  translateValues: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  searchWithFullText: any;
  searchWithoutFullText: any;
  isFirstSearch: any = 0;
  constructor(
    private bsModalService: BsModalService,
    private httpService: HttpService,
    private explorerService: ExplorerService,
    private searchService: SearchService,
    private modlService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private location: Location,
    private urlUtilityService: UrlUtilityService,
    private translate: TranslateService,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService
    // private elem: ElementRef
  ) {
    this.loading = true;
    setTimeout(() => {
      this.homePageUrl = `/${((PageSettingsService.pageSettings.currentArchive + '').toLowerCase()) || environment.defaultArchive}`;
    });
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.pageContentData = data;
    });
    const advParam = JSON.parse(sessionStorage.getItem('explorerLimiter'));
    this.limitArchivrDropdown = JSON.parse(sessionStorage.getItem('explorerDropDownLimiter'));

    if (advParam) {
      this.copyAdvancedFilterParam = new AdvancedFilterParams(advParam);
    } else {
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
        const filterParams = params['filter'];
        this.urlUtilityService.parseUrl(filterParams).pipe(takeUntil(this.stop$)).subscribe((url) => {
          if (localStorage.getItem('archivesList')) {
            this.copyAdvancedFilterParam['archive'] = JSON.parse(localStorage.getItem('archivesList'));
          }
          this.copyAdvancedFilterParam = new AdvancedFilterParams(url);
        });
      });
    }
    if (window.innerWidth <= 1024) {
      this.isIpad = true;
    } else {
      this.isIpad = false;
    }
    if (window.innerWidth <= 766) {
      this.isMobileView = true;
      this.expandLevelOne = false;
    } else {
      this.isMobileView = false;
    }
    if (window.innerWidth <= 998) {
      this.isMoreRecords = false;
    } else {
      this.isMoreRecords = true;
    }
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      this.translateValues = event.translations['explorer'];
      this.searchWithFullText = this.translateValues.searchWithFullText;
      this.searchWithoutFullText = this.translateValues.searchWithoutFullText;
    });
    this.translate.get('photoExplorer').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translateValues = res;
      // tslint:disable-next-line:max-line-length
      this.modlService.showNotification('INFO', 'INFO', this.translateValues.drawShapeRectOrCircle || 'Use the <img src="assets/images/icons/map-rect.png" width="25" /> or the <img src="assets/images/icons/map-circle.png" width="25" /> to draw an area to limit the search', 6000);
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 1024) {
      this.isIpad = true;
    } else {
      this.isIpad = false;
    }
    if (window.innerWidth <= 766) {
      this.isMobileView = true;
      this.expandLevelOne = false;
    } else {
      this.isMobileView = false;
    }
    if (window.innerWidth <= 998) {
      this.isMoreRecords = false;
    } else {
      this.isMoreRecords = true;
    }
  }

  ngOnInit() {
    // this.scrollTarget = document.getElementById('thumbnailImageWrapper2');
    this.accessToken = this.httpService.getCurrentAccessToken();
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'] || '';
      }
      if (params['spatialFilterParams'] && params['spatialFilterParams'] !== 'undefined') {
        this.spatialFilterParams = JSON.parse(params['spatialFilterParams']);
        setTimeout(() => {
          this.isMapsExplorerExapnd = true;
        }, 2000);
      }
      if (params['mapCoordinatesNotNull'] || params['ignoreFullText']) {
        this.callMapsWithCoordinates();
      }
      if (params['mapCoordinatesNotNull']) {
        this.showMap = true;
      } else {
        this.showMap = false;
      }
    });
    this.showLimit = false;
    this.limitRange = false;
    this.expanToLevelOne();
    this.showLimiters = false;
    // this.showMap = false;
  }
  ngAfterViewInit(): void {
    this.isFromPage = 'maps';
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res['language'] === true) {
        this.popupReference.hide();
      }
      if (res['otherArchives'] === true) {
        this.popupReference.hide();
      }
      if (res['tools'] === true) {
        this.popupReference.hide();
      }
    });
    this.cdRef.detectChanges();
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
      let archives: any = [];
      this.pageContent = Object.assign(data);
      if (this.pageContent.currentArchive.toLowerCase() === 'wiley') {
        archives = JSON.parse(localStorage.getItem('archivesList'));
      } else {
        archives.push(this.pageContent.currentArchive);
      }
      if(this.pageContent.currentArchive == 'WILEY') {
        this.enableWiley = true;
        var filterArchives;
        let accessedArchives: any = JSON.parse(localStorage.getItem('personalizationData'));
        if(!this.limitArchivrDropdown) {
          filterArchives = accessedArchives.archives;
          for(let k=0;k<filterArchives.length;k++) {
            if (filterArchives[k].shortName == 'wiley') {
              filterArchives.splice(k,1)
            }
          }
          if (accessedArchives.bothRCPAvailable) {
            const rcpObject = {
              'shortName': 'RCP',
              'name': 'The Royal College of Physicians',
              'logo': 'assets/config/rcp/rcp_logo.png',
              'pageTitle': "The Royal College of Physicians",
            };
            filterArchives = filterArchives.filter(item => item.shortName !== 'RCP1');
            filterArchives = filterArchives.filter(item => item.shortName !== 'RCP2');
            filterArchives.push(rcpObject);
          }
          if (accessedArchives.bothRGSAvailable) {
            const rgsObject = {
              'shortName': 'RGS',
              'name': 'The Royal Geographical Society (with IBG)',
              'logo': 'assets/config/rgs/rgs-logo-other.png',
              'pageTitle': "The Royal Geographical Society (with IBG)",
            };
            filterArchives = filterArchives.filter(item => item.shortName !== 'RGS1');
            filterArchives = filterArchives.filter(item => item.shortName !== 'RGS2');
            filterArchives.push(rgsObject)
          }
        } else {
          filterArchives = this.limitArchivrDropdown;
        }
      } else {
        this.enableWiley = false;
        if (this.pageContent.currentArchive === 'RGS') {
          const rgsObject = {
            'shortName': 'RGS',
            'name': 'The Royal Geographical Society (with IBG)',
            'logo': 'assets/config/rgs/rgs-logo-other.png',
            'trialArchive': false
          };
          filterArchives = [rgsObject];
        } else if (this.pageContent.currentArchive === 'RCP') {
          const rcpObject = {
            'shortName': 'RCP',
            'name': 'The Royal College of Physicians',
            'logo': 'assets/config/rcp/rcp_logo.png',
            'trialArchive': false
          };
          filterArchives = [rcpObject];
        } else {
          for(let k=0;k<this.pageContent.wileyArchives.length;k++) {
            if(this.pageContent.wileyArchives[k].shortName === this.pageContent.currentArchive) {
              filterArchives = [this.pageContent.wileyArchives[k]];
            }
          }
        }
      }
      setTimeout(() => {
        this.limitArchivrDropdown = this.removeDuplicate(filterArchives);
      }, 100); 
      if (!sessionStorage.getItem('explorerLimiter')) {
        this.copyAdvancedFilterParam['archive'] = archives;
      }
      setTimeout(() => {
        this.getMinMaxYear(this.copyAdvancedFilterParam);
        this.getMapExplorerData(this.searchTerm, '', 'fromInit');
      }, 500);
    });
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    // setTimeout(() => {
    //   this.getMapExplorerData(this.searchTerm);
    // }, 2000);
    // this.elementRef.nativeElement.select('#thumbnailImageWrapper2')
    //   .addEventListener('scroll', this.onScroll.bind(this));
    this.expandMapExplorer();

    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
    this.cdRef.detectChanges();
    this._albums.forEach(obj => { obj['highlightSelected'] = false; });

    const mapsWithGeocoordiates = sessionStorage.getItem('isMapsWithGeoCoordiates');
    if (mapsWithGeocoordiates) {
      setTimeout(() => {
        this.callMapsWithCoordinates();
        this.zoomToMap(this._albums[0].coordinates, this._albums[0].pageId, this._albums[0].highlightSelected);
      }, 3000);
    }
  }
  receiveAdvanceParam($event) {
    this.showLimit = false;
    this.getMinMaxYear($event);
  }
  getMinMaxYear(advanceFilterParam) {
    this.explorerService.getRangeSliderMinMaxYear(advanceFilterParam, res => {
      if (res.statsResults) {
        this.intialMaxYear = +res.statsResults.max;
        this.intialMinYear = +res.statsResults.min;
        this.maxYearValue = +res.statsResults.max;
        this.minYearValue = +res.statsResults.min;
        this.yearFrom = +res.statsResults.min;
        this.yearTo = +res.statsResults.max;
        this.rangeArray = [this.minYearValue, this.maxYearValue];
        if (advanceFilterParam.publishedDatePrefix === 'all') {
          this.rangeArray = [this.minYearValue, this.maxYearValue];
        } else if (advanceFilterParam.publishedDatePrefix === 'ON') {
          this.showLimit = false;
          this.limitRange = true;
          const fromDate = new Date(advanceFilterParam.publishedDateFrom).getFullYear();
          this.yearLimit = fromDate;
          if (this.intialMaxYear) {
            if (this.intialMaxYear < fromDate) {
              this.maxYearValue = fromDate;
            } else {
              this.maxYearValue = this.intialMaxYear;
            }
          }
          if (this.intialMinYear) {
            if (this.intialMinYear > fromDate) {
              this.minYearValue = fromDate;
            } else {
              this.minYearValue = this.intialMinYear;
            }
          }
          this.rangeArray = [fromDate, fromDate];
        } else if (advanceFilterParam.publishedDatePrefix === 'BEFORE') {
          const bFromDate = new Date(advanceFilterParam.publishedDateFrom).getFullYear();
          this.yearLimit = bFromDate;
          if (this.intialMaxYear) {
            if (this.intialMaxYear < bFromDate) {
              this.maxYearValue = bFromDate;
            } else {
              this.maxYearValue = this.intialMaxYear;
            }
          }
          if (this.intialMinYear) {
            if (this.intialMinYear > bFromDate) {
              this.rangeArray = [bFromDate, bFromDate];
              this.minYearValue = bFromDate;
            } else {
              this.rangeArray = [this.intialMinYear, bFromDate];
              this.minYearValue = this.intialMinYear;
            }
          }
        } else if (advanceFilterParam.publishedDatePrefix === 'AFTER') {
          this.showLimit = false;
          this.limitRange = true;
          const fDate = new Date(advanceFilterParam.publishedDateFrom).getFullYear();
          this.yearLimit = fDate;
          if (this.intialMaxYear) {
            if (this.intialMaxYear < fDate) {
              this.rangeArray = [fDate, fDate];
              this.maxYearValue = fDate;
            } else {
              this.rangeArray = [fDate, this.intialMaxYear];
              this.maxYearValue = this.intialMaxYear;
            }
          }
          if (this.intialMinYear) {
            if (this.intialMinYear > fDate) {
              this.minYearValue = fDate;
            } else {
              this.minYearValue = this.intialMinYear;
            }
          }
        } else if (advanceFilterParam.publishedDatePrefix === 'BETWEEN') {
          this.showLimit = true;
          this.limitRange = false;
          const fromDate = new Date(advanceFilterParam.publishedDateFrom).getFullYear();
          const toDate = new Date(advanceFilterParam.publishedDateTo).getFullYear();
          this.yearFrom = fromDate;
          this.yearTo = toDate;
          if (this.intialMaxYear !== null || this.intialMaxYear !== undefined) {
            if (this.intialMaxYear < toDate) {
              this.maxYearValue = toDate;
            } else {
              this.maxYearValue = this.intialMaxYear;
            }
          }
          if (this.intialMinYear !== null || this.intialMinYear !== undefined) {
            if (this.intialMinYear > fromDate) {
              this.minYearValue = fromDate;
            } else {
              this.minYearValue = this.intialMinYear;
            }
          }
          this.rangeArray = [fromDate, toDate];
        }
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      // this.modlService.hideLoader();
    });
  }
  removeDuplicate(data) {
		const ids = data.map(o => o.shortName)
		const filtered = data.filter(({shortName}, index) => !ids.includes(shortName, index + 1))
		data = filtered;
		return data;
	}
  receiveAdvancedParam(event) {
    this.copyAdvancedFilterParam = event;
    this.getMinMaxYear(this.copyAdvancedFilterParam);
    const searchHistory = {
      searchTerm: this.searchTerm ? this.searchTerm : ''
    };
    this.searchService.buildFilterParams(this.copyAdvancedFilterParam, res => {
      this.searchService.buildURL(res, filterData => {
        searchHistory['filter'] = filterData;
        this.location.replaceState(this.router.createUrlTree([searchHistory], { relativeTo: this.activatedRoute }).toString());
      });
    });
    switch (this.copyAdvancedFilterParam.publishedDatePrefix) {
      case 'BETWEEN':
        this.updateSliderByPublishedDateBtw();
        break;
      case 'ON':
        this.showLimit = false;
        this.limitRange = true;
        const fromDate = new Date(this.copyAdvancedFilterParam.publishedDateFrom).getFullYear();
        this.yearLimit = fromDate;
        //  this.yearTo = fromDate;
        if (this.intialMaxYear) {
          if (this.intialMaxYear < fromDate) {
            this.maxYearValue = fromDate;
          } else {
            this.maxYearValue = this.intialMaxYear;
          }
        }
        if (this.intialMinYear) {
          if (this.intialMinYear > fromDate) {
            this.minYearValue = fromDate;
          } else {
            this.minYearValue = this.intialMinYear;
          }
        }

        this.rangeArray = [fromDate, fromDate];
        break;
      case 'AFTER':
        this.showLimit = false;
        this.limitRange = true;
        const fDate = new Date(this.copyAdvancedFilterParam.publishedDateFrom).getFullYear();
        this.yearLimit = fDate;
        if (this.intialMaxYear) {
          if (this.intialMaxYear < fDate) {
            this.rangeArray = [fDate, fDate];
            this.maxYearValue = fDate;
            //  this.yearTo = fDate;
          } else {
            this.rangeArray = [fDate, this.intialMaxYear];
            this.maxYearValue = this.intialMaxYear;
            // this.yearTo = this.intialMaxYear;
          }
        }
        if (this.intialMinYear) {
          if (this.intialMinYear > fDate) {
            this.minYearValue = fDate;
          } else {
            this.minYearValue = this.intialMinYear;
          }
        }
        break;
      case 'BEFORE':
        this.showLimit = false;
        this.limitRange = true;
        const bFromDate = new Date(this.copyAdvancedFilterParam.publishedDateFrom).getFullYear();
        this.yearLimit = bFromDate;
        if (this.intialMaxYear) {
          if (this.intialMaxYear < bFromDate) {
            this.maxYearValue = bFromDate;
          } else {
            this.maxYearValue = this.intialMaxYear;
          }
        }
        if (this.intialMinYear) {
          if (this.intialMinYear > bFromDate) {
            this.rangeArray = [bFromDate, bFromDate];
            this.minYearValue = bFromDate;
            // this.yearFrom = bFromDate;
          } else {
            this.rangeArray = [this.intialMinYear, bFromDate];
            //   this.yearFrom = this.intialMinYear;
            this.minYearValue = this.intialMinYear;
          }
        }
        break;
    }
    this.getMapExplorerData(this.searchTerm);
    if (this.isMapsExplorerExapnd) {
      // this.componentRef.directiveRef.scrollToY(0, 300);
    } else {
      this.scrollTopPage();
    }
  }
  receiveDelete($event) {
    this._albums.forEach(obj => { obj['highlightSelected'] = false; });
  }
  updateSliderByPublishedDateBtw() {
    this.showLimit = true;
    this.limitRange = false;
    const fromDate = new Date(this.copyAdvancedFilterParam.publishedDateFrom).getFullYear();
    const toDate = new Date(this.copyAdvancedFilterParam.publishedDateTo).getFullYear();
    this.yearFrom = fromDate;
    this.yearTo = toDate;
    if (this.intialMaxYear !== null || this.intialMaxYear !== undefined) {
      if (this.intialMaxYear < toDate) {
        this.maxYearValue = toDate;
      } else {
        this.maxYearValue = this.intialMaxYear;
      }
    }
    if (this.intialMinYear !== null || this.intialMinYear !== undefined) {
      if (this.intialMinYear > fromDate) {
        this.minYearValue = fromDate;
      } else {
        this.minYearValue = this.intialMinYear;
      }
    }
    this.rangeArray = [fromDate, toDate];
  }
  removeContentSources(source) {
    if (this.copyAdvancedFilterParam.contentSources.indexOf(source) > -1) {
      this.copyAdvancedFilterParam.contentSources.splice(this.copyAdvancedFilterParam.contentSources.indexOf(source), 1);
    }
    this.copyAdvancedFilterParam.contentSources = this.copyAdvancedFilterParam.contentSources;
    if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
      this.copyAdvancedFilterParam.publishedDateFrom = '';
    }
    sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    this.getMapExplorerData(this.searchTerm);
    // this.componentRef.directiveRef.scrollToY(0, 300);
  }
  removeContentCollection(collection) {
    if (this.copyAdvancedFilterParam.contentCollection.indexOf(collection) > -1) {
      this.copyAdvancedFilterParam.contentCollection.splice(this.copyAdvancedFilterParam.contentCollection.indexOf(collection), 1);
    }
    this.copyAdvancedFilterParam.contentCollection = this.copyAdvancedFilterParam.contentCollection;
    if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
      this.copyAdvancedFilterParam.publishedDateFrom = '';
    }
    sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    this.getMapExplorerData(this.searchTerm);
    // this.componentRef.directiveRef.scrollToY(0, 300);
  }
  removeArchive(archive) {
    // if (this.copyAdvancedFilterParam.archive.indexOf(archive) > -1) {
    //   this.copyAdvancedFilterParam.archive.splice(this.copyAdvancedFilterParam.archive.indexOf(archive), 1);
    // }
    // this.copyAdvancedFilterParam.archive = this.copyAdvancedFilterParam.archive;
    // if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
    //   this.copyAdvancedFilterParam.publishedDateFrom = '';
    // }
    // sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    // this.getMapExplorerData(this.searchTerm);
    if (this.limitArchivrDropdown.indexOf(archive) > -1) {
      this.limitArchivrDropdown.splice(this.limitArchivrDropdown.indexOf(archive), 1);
    }
    let availableArchives = [];
    let accessedArchives: any = JSON.parse(localStorage.getItem('personalizationData'));
    for(let k=0;k< this.limitArchivrDropdown.length; k++) {
      if(this.copyAdvancedFilterParam.archive.includes(this.limitArchivrDropdown[k].shortName)) {
        availableArchives.push(this.limitArchivrDropdown[k].shortName)
      } else if(this.limitArchivrDropdown[k].shortName === 'RCP') {
          if(accessedArchives.bothRCPAvailable) {
            availableArchives.push('RCP1','RCP2')
          }
      } else if(this.limitArchivrDropdown[k].shortName === 'RGS') {
        if(accessedArchives.bothRGSAvailable) {
          availableArchives.push('RGS1','RGS2')
        }
      }
    }
    this.copyAdvancedFilterParam.archive = availableArchives;
    if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
      this.copyAdvancedFilterParam.publishedDateFrom = '';
    }
    sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
    sessionStorage.setItem('explorerDropDownLimiter', JSON.stringify(this.limitArchivrDropdown));
    this.getMapExplorerData(this.searchTerm);

  }


  removeLimiterParams(params) {
    if (params === 'contentSources') {
      this.copyAdvancedFilterParam.contentSources = [];
      if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
        this.copyAdvancedFilterParam.publishedDateFrom = '';
      }
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getMapExplorerData(this.searchTerm);
    } else if (params === 'contentCollection') {
      this.copyAdvancedFilterParam.contentCollection = [];
      if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
        this.copyAdvancedFilterParam.publishedDateFrom = '';
      }
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getMapExplorerData(this.searchTerm);
    } else if (params === 'publishedDate') {
      this.copyAdvancedFilterParam.publishedDateTo = '';
      this.copyAdvancedFilterParam.publishedDateFrom = '';
      this.copyAdvancedFilterParam.publishedDatePrefix = '';
      this.copyAdvancedFilterParam.publishedDateIncludesNull = false;
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getMapExplorerData(this.searchTerm);
    } else if (params === 'archive') {
      this.copyAdvancedFilterParam.archive = [];
      if (this.copyAdvancedFilterParam.publishedDateFrom === 'All') {
        this.copyAdvancedFilterParam.publishedDateFrom = '';
      }
      sessionStorage.setItem('explorerLimiter', JSON.stringify(this.copyAdvancedFilterParam));
      this.getMapExplorerData(this.searchTerm);
    }

    // this.componentRef.directiveRef.scrollToY(0, 300);
  }
  openClipedImage(index, image) {
    this.currentDocId = image.docId;
    this.insetMapArray = [];
    if (image.hasInsetMaps) {
      this.previewImageObj = image;
      const authToken = JSON.parse(localStorage.getItem('auth_token'));
      this.httpClient.get<any>(environment.APIUrl + APIURLS.getInsetMaps + '?pageId=' + image.pageId).pipe(takeUntil(this.stop$)).subscribe(res => {
        if (res) {
          res.forEach(pageId => {
            this.insetMapArray.push({
              'image': `${environment.APIUrl}${APIURLS['fileUrlImage']}${image.docId}/${pageId}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation')
                || environment.defaultLocation}`, 'pageId': pageId, 'page': image.pageId
            });
          });
        }
      }, err => { });
      setTimeout(() => {
        this.photoExplorerInsetTemplatemodalRef = this.bsModalService.show(this.photoExplorerInsetTemplate,
          { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large photo-explorer-large photo-inset-popup' });
      }, 500);
    } else {
      // this.expanToLevelOne();
      if (index === 0) {
        this.disableNavigations = { isDisableNext: false, isDisablePrevious: true, random: Math.random() };
      }
      if (index === this._albums.length - 1) {
        this.disableNavigations = { isDisableNext: true, isDisablePrevious: false, random: Math.random() };
      }
      this.isNavigationEnabled = false;
      this.activeSlideIndex = index;
      this.openImage(this._albums[this.activeSlideIndex]);
      this.readmoreId.next(Math.random());
      this.photoExplorerTemplatemodalRef = this.bsModalService.show(this.photoExplorerTemplate,
        { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large photo-explorer-large' });
      setTimeout(() => {
        this.activeSlideIndex = index;
      }, 300);
      setTimeout(() => {
        this.isNavigationEnabled = true;
      }, 1000);
      this.toggledisclaimerShow = true;
    }

  }

  closePopup(): void {
    this.photoExplorerTemplatemodalRef.hide();
    if (this.citationContainer) {
      this.citationContainer = false;
    }
  }

  expandMapExplorer() {
    this.isMapsExplorerExapnd = !this.isMapsExplorerExapnd;
    if (!this.isMapsExplorerExapnd) {
      this._albums.forEach(obj => { obj['highlightSelected'] = false; });
      this.spatialFilterParams = '';
    }
  }

  getMapExplorerData(searchTerm, spatialFilterParams?, str?) {
    if(str) {
      const advParam = JSON.parse(sessionStorage.getItem('explorerLimiter'));
      if (advParam) {
        this.copyAdvancedFilterParam = new AdvancedFilterParams(advParam);
      }
    }
    if (searchTerm !== undefined) {
      searchTerm = searchTerm ? searchTerm.trim() : '';
      this.expandLevelOne = true;
      const currentParams: any = [];
      const explorerLimiter = sessionStorage.getItem('explorerLimiter');
      currentParams['searchTerm'] = searchTerm;
      if (explorerLimiter) {
        currentParams['filter'] = explorerLimiter;
      }
      const url = this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString();
      this.location.replaceState(url);
    }
    setTimeout(() => {
      this._coordinatesToZoom = { coordinates: [], random: this.getRandomNumber() };
    }, 1500);
    if (this.copyAdvancedFilterParam.archive.length === 0) {
      return true;
    }
    if (this.spatialFilterParams) {
      if (this.totalRecoards === 0) {
        this.totalRecoards = '';
      }
      spatialFilterParams = this.spatialFilterParams;
    }
    /*
   * DESC: for popup caption view
   * */
    this.readmoreId.next(Math.random());
    this.loading = true;
    // this.modlService.showLoader();
    if (searchTerm) {
      searchTerm = searchTerm.replace(/\s\s+/g, '');
    }
    this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
          this.isMoreRecords = false;
        } else {
          this.isMoreRecords = true;
        }
        this._albums = res.imageUrls;
        if (this.isMapsExplorerExapnd) {
          // this.componentRef.directiveRef.scrollTo(0, 0, 300);
        } else {
          this.scrollTopPage();
        }
        if (res.totalRecoards > 0) {
          if (this.isFirstSearch < 2 && !this.toggleFullText) {
            // tslint:disable-next-line:max-line-length
            this.modlService.showNotification('INFO', 'INFO', this.translateValues.fullTextMessage || 'You may broaden the search by including text within Maps by clicking on <i class="fa fa-list-alt" aria-hidden="true"></i>', 6000);
            this.isFirstSearch++;
          }
        }
      }
      this.loading = false;
      this._albums.forEach(obj => { obj['highlightSelected'] = false; });
      // this.modlService.hideLoader();
    }, (error) => {
      this.loading = false;
      // this.modlService.hideLoader();
    }, spatialFilterParams, this.mapCoordinatesNotNull, null, false, !this.toggleFullText);
    // this.componentRef.directiveRef.scrollToY(0, 300);
    // DESC: when ever the result set change, the map layer should refresh
    this._removeDropPin = { value: true, random: Math.random() };
  }
  onShown() {
    this.photoLimitTemplatemodalRef = this.bsModalService.show(this.filterTemplate,
      { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large photo-explorer-large photo-inset-popup' });
    this.showLimiters = false;
    this.isSearchBtnEnable = true;
    this.isFromPhotoExplorer = { value: true, random: Math.random() };
  }
  closeLimitPopup(fromVal?) {
    if (this.limitArchivrDropdown.length >0 && (fromVal && fromVal.val.archive.length >0)) {
      let latestArchives = [];
      // for(let k=0; k< fromVal.val.archive.length;k++) {
      //   for(let l=0; l< this.limitArchivrDropdown.length;l++) {
      //       if(fromVal.val.archive[k] === this.limitArchivrDropdown[l].shortName) {
      //         latestArchives.push(this.limitArchivrDropdown[l])
      //       }
      //   }
      // }
      const archiveObj = JSON.parse(localStorage.getItem('limitorArchives'));
      archiveObj.forEach((data)=>{
       const checkVal = fromVal.val.archive.includes(data.shortName);
       if(checkVal) {
        delete data['logo'];
        latestArchives.push(data)
       }
      })
      this.limitArchivrDropdown = latestArchives;
      sessionStorage.setItem('explorerDropDownLimiter', JSON.stringify(this.limitArchivrDropdown));
    }
    this.isSearchBtnEnable = false;
    this.photoLimitTemplatemodalRef.hide();
  }

  /* DESC: navigate to document details page
  * Input: document id
  * */
  gotoDocumentDetails(docId: String, pageId: String, index, fromModal?): void {
    if (this.isNavigationEnabled) {
      const isFromMapExplorer = true;
      if (fromModal) {
        this.closePopup();
      }
      const data = {};
      this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, (param) => {
        this.searchService.buildURL(param, (url) => {
          data['filterParam'] = url;
          data['searchTerm'] = this.searchTerm || '';
          data['spatialFilterParams'] = JSON.stringify(this.spatialFilterParams) || '';
          data['start'] = index;
          if (pageId) {
            data['pageId'] = pageId;
          }
          data['isFromMapExplorer'] = isFromMapExplorer;
          data['mapCoordinatesNotNull'] = this.mapCoordinatesNotNull;
          data['ignoreFullText'] = !this.toggleFullText;
          this.router.navigate([this.homePageUrl + '/detail/' + docId, data], { relativeTo: this.activatedRoute });
          this.googleAnalyticsService.eventEmitter('View Document', 'Click', 'Document', docId.toString());

        });
      });
      this.citationContainer = false;
      if (this.photoExplorerTemplatemodalRef) {
        this.photoExplorerTemplatemodalRef.hide();
      }
    }
    const CurrentURL = window.location.href.split(window.location.host);
    if (this.searchTerm) {
      this.searchTerm = encodeURIComponent(this.searchTerm);
    }
    sessionStorage.setItem('mapExplorerPageURL', CurrentURL[1] + ';searchTerm=' + (this.searchTerm ? this.searchTerm : ''));
  }
  /* DESC: get spacial co-ordinates/filters from map
  * Input: map coordinates
  * */
  onSpatialSearchEmit(event: any): void {
    /*
   * DESC: for popup caption view
   * */
    if (event.length === 0 && this.expandLevelOne) {
      this.showDocView = true;
      this.expandLevelOne = true;
      this.expandLevelTwo = false;
    } else if (event.spatialFilterParams !== undefined) {
      this.showDocView = true;
      this.expandLevelOne = true;
      this.expandLevelTwo = false;
      if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
        this.isMoreRecords = false;
      } else {
        this.isMoreRecords = true;
      }
    }
    this.readmoreId.next(Math.random());
    this.spatialFilterParams = event.spatialFilterParams;
    this.getMapExplorerData(this.searchTerm, event.spatialFilterParams);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if ($(window).scrollTop() > 120) {
      this.hightScrollTop = 'true';
    } else {
      this.hightScrollTop = '';
    }
    if (!this.isMapsExplorerExapnd) {
      // In chrome and some browser scroll is given to body tag
      const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      const max = document.documentElement.scrollHeight;
      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if ((pos - 50) === (max - 50)) {
        // Do your action here
        this.loadMoreMaps();
        window.scrollBy(0, -10);
      }
    }
  }

  /*
  * DESC: Load next 100 maps if the scroll reach the bottom
  * */
  loadMoreMaps() {
    /*
    * DESC: for popup caption view
    * */
    this.readmoreId.next(Math.random());
    if (this._albums.length === this.totalRecoards) {
      return true;
    }
    if (!this.loading) {
      this.loading = true;
      this.loadMoreLoader = true;
      // this.modlService.showLoader();
      this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
        if (res) {
          this.totalRecoards = res.totalRecoards;
          if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
            this.isMoreRecords = false;
          } else {
            this.isMoreRecords = true;
          }
          this._albums = this._albums.concat(res.imageUrls);
        }
        this.loading = false;
        this.loadMoreLoader = false;
        // this.modlService.hideLoader();
      }, (error) => {
        this.loading = false;
        this.loadMoreLoader = false;
        // this.modlService.hideLoader();
      }, this.spatialFilterParams, this.mapCoordinatesNotNull, this._albums.length, false, !this.toggleFullText);
    }
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.unSubPageSettingsObservable) {
      this.unSubPageSettingsObservable.unsubscribe();
    }
    this.stop$.next();
    this.stop$.complete();
  }
  resultWrapperScrollHandler(event) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.loadMoreMaps();
      // this.componentRef.directiveRef.scrollToBottom(100);
    }
  }

  /*
  * Scroll To Top of the page
  */
  scrollTopPage() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // $('html,body').animate({ scrollTop: 0 }, 'slow');
  }

  /*
  * Navigate to Photos page
  */
  gotoPhotoExplorer() {
    this._albums.forEach(obj => { obj['highlightSelected'] = false; });
    this.copyAdvancedFilterParam.searchTerm = this.copyAdvancedFilterParam.searchTerm ? this.copyAdvancedFilterParam.searchTerm : '';
    this.router.navigate([`${this.homePageUrl}/explorer/photo`, this.copyAdvancedFilterParam]);
  }
  /*
* return random number
*/
  getRandomNumber() {
    return Math.random();
  }

  /*
  * zoom map to available co-ordinates and create marker on it
  * */
  zoomToMap(coordinatesArray: Array<any> = [], pageId, highlightSelected): void {
    this.globepageId = '';
    this.expanToLevelOne();
    if (highlightSelected) {
      this._removeDropPin = { value: true, random: Math.random() };
      this._albums.forEach(obj => { obj['highlightSelected'] = false; });
      return;
    }
    this.isMapsExplorerExapnd = true;
    setTimeout(() => {
      // NWLon NWLat, SWLon SWLat, SELon SELat, SELon SELat, NELon, NELat
      const geoBoundaries: Array<any> = [];
      if (coordinatesArray) {
        coordinatesArray.forEach(polygonCoOrdinates => {
          let geoBoundary = polygonCoOrdinates.replace('POLYGON((', '').replace('))', '');
          geoBoundary = geoBoundary.split(',');
          const polygonBoundaries: Array<any> = [];
          geoBoundary.forEach(element => {
            const splittedCoOrdinates = element.trim().split(' ');
            polygonBoundaries.push([splittedCoOrdinates[1], splittedCoOrdinates[0]]);
          });
          geoBoundaries.push(polygonBoundaries);
        });
      }
      this._coordinatesToZoom = { coordinates: geoBoundaries, random: this.getRandomNumber() };
    }, 1000);
    this.globepageId = pageId;
    this._albums.forEach(obj => {
      if (obj.pageId === pageId) {
        obj['highlightSelected'] = true;
      } else {
        obj['highlightSelected'] = false;
      }
    });
    if (sessionStorage.getItem('isMapsWithGeoCoordiates')) {
      sessionStorage.removeItem('isMapsWithGeoCoordiates');
    }
  }

  toggleNavigation(event) {
    this.tilesource = null;
    if (event.value === 'next') {
      if (this.activeSlideIndex < this._albums.length - 1) {
        this.activeSlideIndex = (this.activeSlideIndex + 1);
        this.openImage(this._albums[this.activeSlideIndex]);
        this.disableNavigations = { isDisableNext: false, isDisablePrevious: false, random: Math.random() };
        if (this.activeSlideIndex === this._albums.length - 1) {
          this.disableNavigations = { isDisableNext: true, isDisablePrevious: false, random: Math.random() };
        }
      } else {
        this.disableNavigations = { isDisableNext: true, isDisablePrevious: false, random: Math.random() };
      }
    } else if (event.value === 'previous') {
      if (this.activeSlideIndex > 0) {
        this.activeSlideIndex = (this.activeSlideIndex - 1);
        this.openImage(this._albums[this.activeSlideIndex]);
        this.disableNavigations = { isDisableNext: false, isDisablePrevious: false, random: Math.random() };
        if (this.activeSlideIndex === 0) {
          this.disableNavigations = { isDisableNext: false, isDisablePrevious: true, random: Math.random() };
        }
      } else {
        this.activeSlideIndex = 0;
        this.disableNavigations = { isDisableNext: false, isDisablePrevious: true, random: Math.random() };
      }
    }
    if (this.citationContainer) {
      this.citationContainer = false;
    }
  }
  getCitationDetailsFromDocumnentData(docId) {

    if (!this.citationContainer) {
      this.isCitationLoading = false;
    }
    this.documentCitation = [];
    this.citationContainer = !this.citationContainer;
    if (this.citationCache[docId]) {
      this.isCitationLoading = true;
      this.documentCitation = this.citationCache[docId];
      return true;
    }
    this.searchService.getDocumentDetail(docId, (response) => {
      if (response) {
        this.isCitationLoading = true;
        this.citationCache[docId] = response;
        this.documentCitation = response;
        this.getAccessToCitation();
      }
    });
  }
  gotoAuthorDetail(author) {
    const _searchTerm = '';
    if (this.pageContentData.advancedFilterParam.searchKeywords) {
      this.pageContentData.advancedFilterParam.searchKeywords = [{ fieldName: 'authors', fieldValue: author, operator: 'AND' }];
    } else {
      this.pageContentData.advancedFilterParam.searchKeywords = [{ fieldName: 'authors', fieldValue: author, operator: 'AND' }];
    }
    this.citationContainer = false;
    this.photoExplorerTemplatemodalRef.hide();
    this.searchService.backToSearchResult(_searchTerm, this.pageContentData.advancedFilterParam);
  }
  gotoCollectionDetails(collectionId) {
    this.citationContainer = false;
    this.photoExplorerTemplatemodalRef.hide();
    this.router.navigate([this.homePageUrl + '/collection-details'],
      { queryParams: { archive: this.documentCitation['shortName'], id: collectionId, scrollTo: this.documentCitation.wileyId } });
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!($(event.target).is('#documentCitation'))) {
      this.citationContainer = false;
    }
  }

  openImage(image) {
    this.previewImageObj = image;
    const acToken = this.httpService.getCurrentAccessToken();
    const documentId = image.docId;
    const pageId = image.pageId;

    let location = localStorage.getItem('currentLocation') || environment.defaultLocation;
    if (location) {
      location = location.trim();
    }
    const tileSourceURL = environment.APIUrl + APIURLS['tileSourceServer'] + '/' + documentId + '/' +
      image.pageId + '.jpg?WDALoc=' + location + '&access_token=' + acToken + '&_';
    let mapImageWidth = null;
    let mapImageHeight = null;
    let tilesourceID = null;

    if (documentId) {
      this.tilesource = null;

      this.httpService.get(environment.APIUrl + APIURLS['tileSourceServer'] + '/' + documentId + '/'
        + pageId + '.jpg/info.json?WDALoc=' + location + '&access_token=' + acToken, (tilesourceResponse) => {

          if (tilesourceResponse) {
            mapImageWidth = tilesourceResponse['width'];
            mapImageHeight = tilesourceResponse['height'];
            tilesourceID = tilesourceResponse['@id'];
            this.tilesource = [{
              '@context': 'http://iiif.io/api/image/2/context.json',
              '@id': tileSourceURL + '?access_token=' + acToken,
              'height': mapImageHeight,
              'width': mapImageWidth,
              'profile': ['http://iiif.io/api/image/2/level2.json'],
              'protocol': 'http://iiif.io/api/image',
              'tiles': [{
                'scaleFactors': [0.25, 0.5, 1, 2, 4, 8, 16, 32],
                'width': 512
              }],
              'random': Math.random()
            }];
          } else {
            this.tilesource = null;
          }
        }, (error) => {
          this.tilesource = null;
        }, 'json', null, null, true, { 'key': 'accessToken', 'value': acToken });
    }
  }
  disclaimerToggle() {
    this.toggledisclaimerShow = !this.toggledisclaimerShow;
    if (this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    } else if (!this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    }
  }
  viewMoreOptionStatus(value) {
    this.toggledisclaimerShow = true;
  }

  showLess() {
    this.expandLevelTwo = false;
    this.showDocView = true;
    this.expandLevelOne = false;
    if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
      this.isMoreRecords = true;
    }
  }
  showMore() {
    this.showDocView = true;
    if (!this.expandLevelOne) {
      this.expandLevelOne = !this.expandLevelOne;
    } else {
      this.expandLevelTwo = true;
    }
    if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
      this.isMoreRecords = false;
    }
  }
  toggleLimiters(event) {
    this.showLimiters = !this.showLimiters;
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event']) onclick() {
    this.showLimiters = false;
  }
  expanToLevelOne() {
    this.showDocView = true;
    this.expandLevelOne = true;
    this.expandLevelTwo = false;
  }
  receiveMessage($event) {
    this.message = $event;
    if (this.message && this.expandLevelTwo) {
      this.showDocView = true;
      this.expandLevelOne = false;
      this.expandLevelTwo = false;
    }
  }
  sliderRangeChanged(event) {
    this.showLimit = true;
    this.limitRange = false;
    this.expanToLevelOne();
    this.yearFrom = event[0][0];
    this.yearTo = (event[0][1]);
    if (!this.copyAdvancedFilterParam.publishedDatePrefix) {
      this.copyAdvancedFilterParam['publishedDateIncludesNull'] = true;
    }
    this.copyAdvancedFilterParam['publishedDateFrom'] = this.yearFrom + '-';
    this.copyAdvancedFilterParam['publishedDateTo'] = this.yearTo + '-';
    this.copyAdvancedFilterParam['publishedDatePrefix'] = 'BETWEEN';
    this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
          this.isMoreRecords = false;
        } else {
          this.isMoreRecords = true;
        }
        this._albums = res.imageUrls;
        if (this.isMapsExplorerExapnd) {
          // this.componentRef.directiveRef.scrollTo(0, 0, 300);
        } else {
          this.scrollTopPage();
        }
      }
      this.loading = false;
      this.expandLevelOne = true;
    }, (error) => {
      this.loading = false;
      // this.modlService.hideLoader();
    }, this.spatialFilterParams, this.mapCoordinatesNotNull, null, false, !this.toggleFullText);
  }
  toggle(event) {
    this.showMap = !this.showMap;
    if (this.showMap) {
      this.mapCoordinatesNotNull = true;
    } else {
      this.mapCoordinatesNotNull = false;
    }
    this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
          this.isMoreRecords = false;
        } else {
          this.isMoreRecords = true;
        }
        this._albums = res.imageUrls;
        if (this.isMapsExplorerExapnd) {
          // this.componentRef.directiveRef.scrollTo(0, 0, 300);
        } else {
          this.scrollTopPage();
        }
      }
      this.loading = false;
      this.expandLevelOne = true;
      this._albums.forEach(obj => {
        if (obj.pageId === this.globepageId) {
          obj['highlightSelected'] = false;
        } else {
          obj['highlightSelected'] = false;
        }
      });
    }, (error) => {
      this.loading = false;
      // this.modlService.hideLoader();
    }, this.spatialFilterParams, this.mapCoordinatesNotNull, null, false, !this.toggleFullText);
    const currentParams = [];
    if (this.showMap) {
      currentParams['searchTerm'] = this.searchTerm ? this.searchTerm : '';
      currentParams['mapCoordinatesNotNull'] = this.mapCoordinatesNotNull;
      currentParams['ignoreFullText'] = !this.toggleFullText;
      const url = this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString();
      this.location.replaceState(url);
    } else {
      const url = this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString();
      this.location.replaceState(url);
    }
    // DESC: when ever the result set change, the map layer should refresh
    this._removeDropPin = { value: true, random: Math.random() };
  }
  /**
   * @description :fetch result`s count for limiter display
   * @param event :form data from limiter
   */
  fetchResultCount(form): void {
    const searchTerm = this.searchTerm ? this.searchTerm.replace(/\s\s+/g, '') : '';
    this.showLoadingTxt = true;
    this.explorerService.getMapExplorer(form.form, searchTerm, res => {
      this.showLoadingTxt = false;
      this.estimatedCount = res.totalRecoards;
    }, (error) => {
      this.showLoadingTxt = false;
      this.estimatedCount = 0;
    }, this.spatialFilterParams, this.mapCoordinatesNotNull, null, true, !this.toggleFullText);
  }

  /**
   * @description: remove vertical and horizontal scrollbar on IE
   *
   */
  getAccessToCitation() {
    const parentHeight = document.querySelector('.citation-data-container.show-citationContainer').clientHeight;
    const childHeight = document.querySelector('.citation-container').clientHeight;
    if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
      if (parentHeight === childHeight || parentHeight > childHeight) {
        if (document.querySelector('.ps--active-y')) {
          document.querySelector('.ps--active-y').classList.remove('ps--active-y');
        }
        if (document.querySelector('.ps--active-x')) {
          document.querySelector('.ps--active-x').classList.remove('ps--active-x');
        }
      }
    }
  }
  searchIncludedFulltext() {
    this.toggleFullText = !this.toggleFullText;
    this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
          this.isMoreRecords = false;
        } else {
          this.isMoreRecords = true;
        }
        this._albums = res.imageUrls;
        if (this.isMapsExplorerExapnd) {
          // this.componentRef.directiveRef.scrollTo(0, 0, 300);
        } else {
          this.scrollTopPage();
        }
      }
      this.loading = false;
      this._albums.forEach(obj => {
        if (obj.pageId === this.globepageId) {
          obj['highlightSelected'] = false;
        } else {
          obj['highlightSelected'] = false;
        }
      });
    }, (error) => {
      this.loading = false;
      // this.modlService.hideLoader();
    }, this.spatialFilterParams, this.mapCoordinatesNotNull, null, false, !this.toggleFullText);
    // this.componentRef.directiveRef.scrollToY(0, 300);
    // DESC: when ever the result set change, the map layer should refresh
    this._removeDropPin = { value: true, random: Math.random() };
  }
  callMapsWithCoordinates() {
    this.showMap = true;
    if (this.showMap) {
      this.mapCoordinatesNotNull = true;
    } else {
      this.mapCoordinatesNotNull = false;
    }
   // if (this.copyAdvancedFilterParam.archive) {
    //   this.copyAdvancedFilterParam.archive = JSON.parse(localStorage.getItem('archivesList'));
    // }
    this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        if (this.totalRecoards <= 2 || window.innerWidth <= 998) {
          this.isMoreRecords = false;
        } else {
          this.isMoreRecords = true;
        }
        this._albums = res.imageUrls;
        if (this.isMapsExplorerExapnd) {
          // this.componentRef.directiveRef.scrollTo(0, 0, 300);
        } else {
          this.scrollTopPage();
        }
      }
      this.loading = false;
      this.expandLevelOne = true;
      this._albums.forEach(obj => {
        if (obj.pageId === this.globepageId) {
          obj['highlightSelected'] = true;
        } else {
          obj['highlightSelected'] = false;
        }
      });
    }, (error) => {
      this.loading = false;
      // this.modlService.hideLoader();
    }, this.spatialFilterParams, this.mapCoordinatesNotNull, null, false, !this.toggleFullText);
  }
  closeInsetPopup() {
    this.photoExplorerInsetTemplatemodalRef.hide();
  }
  getInsetmapPage(page) {
    const authToken = JSON.parse(localStorage.getItem('auth_token'));
    this.httpClient.get<any>(environment.APIUrl + APIURLS.getInsetmapPage + '?pageId=' + page.pageId).pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.disableNavigations = { isDisableNext: true, isDisablePrevious: true, random: Math.random() };
        this.insetMapPage = {
          'image': `${environment.APIUrl}${APIURLS['fileUrlImage']}${this.currentDocId}/${page.pageId}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation')
            || environment.defaultLocation}`, 'pageId': page.page
        };

        this.insetMapData = res;
        this.insetMapTemplatemodalRef = this.bsModalService.show(this.insetMapPageTemplate,
          { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large photo-explorer-large' });
      }
    }, err => { });
  }
  closeInsetmapPopup() {
    this.insetMapTemplatemodalRef.hide();
  }
  gotoDocumentDetailsPage(docId, pageId) {
    if (this.isNavigationEnabled) {
      const isFromMapExplorer = true;
      const data = {};
      this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, (param) => {
        this.searchService.buildURL(param, (url) => {
          data['filterParam'] = url;
          data['searchTerm'] = this.searchTerm || '';
          data['spatialFilterParams'] = JSON.stringify(this.spatialFilterParams) || '';
          // data['start'] = index;
          if (pageId) {
            data['pageId'] = pageId;
          }
          data['isFromMapExplorer'] = isFromMapExplorer;
          data['mapCoordinatesNotNull'] = this.mapCoordinatesNotNull;
          data['ignoreFullText'] = !this.toggleFullText;
          this.router.navigate([this.homePageUrl + '/detail/' + docId, data], { relativeTo: this.activatedRoute });
        });
      });
      this.citationContainer = false;
      if (this.insetMapTemplatemodalRef) {
        this.insetMapTemplatemodalRef.hide();
      }
      if (this.photoExplorerInsetTemplatemodalRef) {
        this.photoExplorerInsetTemplatemodalRef.hide();
      }
    }
    const CurrentURL = window.location.href.split(window.location.host);
    if (this.searchTerm) {
      this.searchTerm = encodeURIComponent(this.searchTerm);
    }
    this.disableNavigations = { isDisableNext: true, isDisablePrevious: true, random: Math.random() };
    sessionStorage.setItem('mapExplorerPageURL', CurrentURL[1] + ';searchTerm=' + (this.searchTerm ? this.searchTerm : ''));
  }
}

