
import {takeUntil} from 'rxjs/operators';

/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from './../services/http.service';
import { Router } from '@angular/router';
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';
import { PageSettingsService } from './../services/page-settings.service';
import { ModalService } from '../services/modal.service';
import { ImageSource, Overlay } from '../../new-image-viewer-frame/ImageSource';
import { environment, APIURLS, stopWords } from '../../../environments/environment';
import { SharedService } from './sharedService';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { without as _without } from 'lodash';
const historyKey = 'HistoryList';

declare let $: any;

export const windowLocationSettings = { hostName: window.location.hostname, port: window.location.port };
const GROUPLIMIT = 5;
let loadingsearch = false;
@Injectable()
export class SearchService implements OnDestroy {
  public static archivefacetObservable: BehaviorSubject<any>;
  stop$: Subject<any> = new Subject();
  documentIDReference: any;
  public static facetObservable: BehaviorSubject<any>;
  public static resultObservable: BehaviorSubject<any>;
  public static hasResultsObservable: BehaviorSubject<any>;
  public static HistoryListObservable: BehaviorSubject<any>;
  public static spatialSearchFacetObservable: BehaviorSubject<any>;
  public static documentObservable: BehaviorSubject<any>;
  public static documentUrlObservable: BehaviorSubject<any>;
  getarrayofdocsID:any;
  downloadContentFilterParam: any;
  collocatesCache$: any = {};
  clusterCache$: any = {};
  cacheDocumentDetails: any = {};
  cache = {
    illustrationsData: {},
    fulltextData: {},
    tocData: {},
    documentByItemData: {},
    documentItembyType: {},
    documentDetails: {},
  };
  translatedValues: any;
  advancedSearchFilterParams: any;
  pageFlage: boolean;
  termId: any;
  config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: false
  };
  modalRef: any;
  private pubDatePrefix: any = {
    ON: 'EQUAL',
    BETWEEN: 'BETWEEN',
    AFTER: 'GREATER_THAN',
    BEFORE: 'LESS_THAN'
  };
  private pubDateDataPrefix: any = {
    EQUAL: 'ON',
    BETWEEN: 'BETWEEN',
    GREATER_THAN: 'AFTER',
    LESS_THAN: 'BEFORE'
  };
  private searchHistory: any;
  private searchHistoryCount: any;
  private URL: any;
  private historyObject: any = [];
  private previousHistoryObject;
  private photoGraphView;
  private isContenttype: boolean;
  private loading = false;
  loadMapPos: any;
  illustrationsOverlays: any = [];
  splittedSearchTerms: Array<String> = [];
  booleanCount: number = 0;
  previousValue: any;
  documentList:any = [];
  newFilterParams: any;
  includeWPA: boolean;
  includeWPA1: boolean;
  constructor(
    private httpService: HttpService,
    private router: Router,
    private popupService: ModalService,
    private pageSettingService: PageSettingsService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    this.searchHistory = {};
    this.searchHistoryCount = 0;
    SearchService.archivefacetObservable = new BehaviorSubject({});
    SearchService.facetObservable = new BehaviorSubject({});
    SearchService.resultObservable = new BehaviorSubject([]);
    SearchService.documentObservable = new BehaviorSubject([]);
    SearchService.documentUrlObservable = new BehaviorSubject([]);
    SearchService.hasResultsObservable = new BehaviorSubject({
      hasResult: true,
      count: 0
    });
    SearchService.spatialSearchFacetObservable = new BehaviorSubject({});
    SearchService.HistoryListObservable = new BehaviorSubject({
      url: {},
      searchHistory: {}
    });
    setTimeout(() => {
      if (sessionStorage.HistoryList) {
        this.searchHistory = JSON.parse(sessionStorage.HistoryList);
      }
      Object.keys(this.searchHistory).forEach(contentType => {
        this.searchHistoryCount += Object.keys(
          this.searchHistory[contentType]
        ).length;
      });
      SearchService.HistoryListObservable.next({
        url: this.URL,
        searchHistory: this.searchHistory
      });
    }, 100);
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
    });
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      this.translatedValues = event.translations['Common']['Popups'];
    });
    setInterval(res => {
      this.clearCachedData();
    }, (5000 * 60));

    /* Document ID reference service */
    this.httpService.get(environment.APIUrl + APIURLS['getDocumentIDReference'], response => {
      this.documentIDReference = response;
      localStorage.setItem('documentIDReference', JSON.stringify(this.documentIDReference))
    }, error => { }
    );
  }
  private viewHistoryCount: any;

  public buildFilterParams(advancedFilters, callBack?, contentType?) {
    if (advancedFilters) {
      if (advancedFilters.publishedDateTo) {
        advancedFilters.publishedDateTo = advancedFilters.publishedDateTo.endsWith('-') ?
          advancedFilters.publishedDateTo.substring(0, advancedFilters.publishedDateTo.length - 1) : advancedFilters.publishedDateTo;
      }
      if (advancedFilters.publishedDateFrom) {
        advancedFilters.publishedDateFrom = advancedFilters.publishedDateFrom.endsWith('-') ?
          advancedFilters.publishedDateFrom.substring(0, advancedFilters.publishedDateFrom.length - 1) : advancedFilters.publishedDateFrom;
      }
    }
    var searchTerm = [];
    const fromScroll = localStorage.getItem('fromScroll');
    if (fromScroll) {
      const filterVal = JSON.parse(localStorage.getItem('filter'));
      if(filterVal && filterVal.searchParams.length>0) {
        searchTerm = filterVal.searchParams;
      }
      localStorage.removeItem('fromScroll');
    }
    
    let params = {
      searchParams: searchTerm,
      filterParams: [],
      searchTerm: PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm : '',
      groupOffset: advancedFilters['currentOffset']
        ? advancedFilters['currentOffset']
        : 0
    };

    let foundFilter = false;
    const advancedParamKeys = Object.keys(advancedFilters);
    advancedParamKeys.forEach((key, index) => {
      if (key === 'searchKeywords') {
        if (advancedFilters.searchKeywords) {
          advancedFilters.searchKeywords.forEach(obj => {
            if (obj.fieldValue) {
              params.searchParams.push(obj);
              foundFilter = true;
            }
          });
          params.searchParams = this.getUniqueListBy(params.searchParams, 'fieldValue')
        }
      } else if (key === 'publishedDateFrom') {
        if (advancedFilters[key]) {
          foundFilter = true;
          const field = {
            fieldName: 'pubStartDate',
            fieldValue: advancedFilters[key].replace(/\-$/g, ''),
            condition: this.pubDatePrefix[
              advancedFilters['publishedDatePrefix']
            ],
            includeNull: advancedFilters['publishedDateIncludesNull']
          };
          if (advancedFilters['publishedDateTo']) {
            field['fieldEndValue'] = advancedFilters['publishedDateTo'];
            field['operator'] = 'AND';
          }
          params.filterParams.push(field);
        }
      } else if (key === 'publishedDateTo') {
      } else if (key === 'publishedDatePrefix') {
        if (advancedFilters.publishedDatePrefix === 'All') {
          if (advancedFilters[key]) {
            foundFilter = true;
            const field = {
              fieldName: 'pubStartDate',
              fieldValue: 'All',
              includeNull: advancedFilters['publishedDateIncludesNull']
            };
            if (advancedFilters['publishedDateTo']) {
              field['fieldEndValue'] = advancedFilters['publishedDateTo'];
              field['operator'] = 'AND';
            }
            params.filterParams.push(field);
          }
        }
      } else if (key === 'publishedDateIncludesNull') {
      } else if (key === 'facetFields') {
        if (advancedFilters.facetFields) {
          if (advancedFilters.facetFields.length > 0) {
            params[key] = advancedFilters[key];
          }
        }
      } else if (key === 'contentSources') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'sourceInstitution',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (key === 'languages') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'docLang',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (key === 'contentCollection') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'collection',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (key === 'illustrationType') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'illustrationType',
            fieldValues: advancedFilters[key].map(function (item) {
              if (item.includes('"')) {
                return item;
              } else {
                return '"' + item + '"';
              }
            }),
            operator: 'OR'
          });
        }
      } else if (key === 'subject') {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: 'subject',
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      } else if (Array.isArray(advancedFilters[key])) {
        if (
          Array.isArray(advancedFilters[key]) &&
          advancedFilters[key].length > 0
        ) {
          foundFilter = true;
          params.filterParams.push({
            fieldName: key,
            fieldValues: advancedFilters[key].map(function (item) {
              return item;
            }),
            operator: 'OR'
          });
        }
      }
    });
    if (advancedFilters['archive']) {
      if (advancedFilters['archive'].length === 0) {
        if (window.location.pathname.includes('downloadcontent')) {
          if (advancedFilters.filterParams && advancedFilters.filterParams.length>0) {
            params = advancedFilters;
          } else {
            params.filterParams.push({
              fieldName: 'archive',
              fieldValues:
                PageSettingsService.pageSettings.currentArchive === 'WILEY'
                  || PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP'
                  ? JSON.parse(sessionStorage.getItem('wileyDCSelectedArchive'))
                  : [PageSettingsService.pageSettings.currentArchive],
              operator: 'OR'
            });
          }
        } else {
          const checkadiyionalArchives = localStorage.getItem('fromAdditionalArchive');
          if (checkadiyionalArchives === 'true') {
            let unsubsVal = JSON.parse(localStorage.getItem('unsubscribedArchives_tab2'))
            unsubsVal = unsubsVal.filter(item => item !== 'WPA' && item !== 'WPA1')
            params.filterParams.push({
              fieldName: 'archive',
              fieldValues: unsubsVal,
              operator: 'OR'
            });
          } else {
            params.filterParams.push({
              fieldName: 'archive',
              fieldValues:
                PageSettingsService.pageSettings.currentArchive === 'WILEY'
                  || PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP'
                  ? JSON.parse(localStorage.getItem('wileySelectedArchive'))
                  : [PageSettingsService.pageSettings.currentArchive],
              operator: 'OR'
            });
          }
        }
      } else {
        if (window.location.pathname.includes('downloadcontent')) {
          if (advancedFilters.filterParams && advancedFilters.filterParams.length>0) {
            params = advancedFilters;
          } 
        }
      }
    }

    if (foundFilter) {
      if (window.location.pathname.includes('display=Photographs')) {
        params['groupLimit'] = GROUPLIMIT + 15;
        this.photoGraphView = true;
        $('#ListIcon').removeClass('active');
        $('#GridIcon').addClass('active');
        $('#CursorNotAllowed').addClass('cursornotallowed');
        $('#ListIcon').addClass('disabled');
        $('#GridIcon').addClass('active');
      } else {
        params['groupLimit'] = GROUPLIMIT;
      }

    }
    if (advancedFilters.sortFields) {
      if (Object.keys(advancedFilters.sortFields)) {
        if (Object.keys(advancedFilters.sortFields).length > 0) {
          if (
            advancedFilters.sortFields[
            Object.keys(advancedFilters.sortFields)[0]
            ] !== ''
          ) {
            params['sortFields'] = advancedFilters.sortFields;
          }
        }
      }
    }
    if (typeof callBack === 'function') {
      callBack(params, foundFilter);
    }
  }

  public estimateResultCount(advancedFilters, callBack, fromTATTools?, tatSearchTerm?) {
    if (window.location.pathname.includes('analysis-hub')) {
      fromTATTools = true;
      if (sessionStorage.getItem('sessionFilterInput')) {
        tatSearchTerm = sessionStorage.getItem('sessionFilterInput')
      }
    }
    this.buildFilterParams(advancedFilters, params => {
      if (advancedFilters) {
        if (advancedFilters.searchKeywords) {
          advancedFilters.searchKeywords.forEach(obj => {
            obj.fieldValue = obj.fieldValue.trim();
            if (obj.fieldName === 'manuscriptNoV2') {
              if (obj.fieldValue !== '') {
                this.previousValue = { 'manuscriptNoV2': obj.fieldValue };
                if (!obj.fieldValue.includes('"')) {
                  obj.fieldValue = '"' + obj.fieldValue + '"';
                }
              }
            }
          });
        }
      }
      // added searchTerm if searchTerm is available for  "No matched record, please return to previous result" fix - 9912
      if (fromTATTools) {
        params.searchTerm = tatSearchTerm ? tatSearchTerm : '';
        let splittedTerms: any = this.splitTerms(params.searchTerm);
        splittedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
        splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
        params.searchTerm = splittedTerms;
      } else {
        params.searchTerm = '';
      }
      params.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
      });
      let modifiedParam;
      if (typeof params === 'string') {
        modifiedParam = JSON.parse(JSON.stringify(params));
      } else {
        modifiedParam = params;
      }
      modifiedParam = this.updateSearchParam(modifiedParam); 
      localStorage.setItem('filter', JSON.stringify(modifiedParam))
      this.downloadContentFilterParam = modifiedParam;
      this.newFilterParams = modifiedParam.filterParams;
      if (this.newFilterParams.length > 0) {
        for(let k =0; k < this.newFilterParams.length; k++) {
          if(this.newFilterParams[k].fieldName === "archive") {
           const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
           const haveRGS = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RGS'); 
           if (haveRGS) {
            const haveRGS1 = getSelectedArchives.includes('RGS1');
            if(haveRGS1) {
              this.newFilterParams[k].fieldValues.push('RGS1');
            }
            const haveRGS2 = getSelectedArchives.includes('RGS2');
            if(haveRGS2) {
              this.newFilterParams[k].fieldValues.push('RGS2');
            }
            this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RGS',this.newFilterParams[k].fieldValues);
           }
           const haveRCP = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RCP'); 
           if (haveRCP) {
            const haveRCP1 = getSelectedArchives.includes('RCP1');
            if(haveRCP1) {
              this.newFilterParams[k].fieldValues.push('RCP1');
            }
            const haveRCP2 = getSelectedArchives.includes('RCP2');
            if(haveRCP2) {
              this.newFilterParams[k].fieldValues.push('RCP2');
            }
            this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RCP',this.newFilterParams[k].fieldValues);
           }
          }
        }
        const checkadiyionalArchives = localStorage.getItem('fromAdditionalArchive');
        if (checkadiyionalArchives === 'true') {
          let unsubsVal = JSON.parse(localStorage.getItem('unsubscribedArchives_tab2'))
            unsubsVal = unsubsVal.filter(item => item !== 'WPA' && item !== 'WPA1')
          for (let k=0;k< modifiedParam.filterParams.length;k++) {
            if(modifiedParam.filterParams[k].fieldName === "archive") {
              modifiedParam.filterParams[k].fieldValues = unsubsVal;
            }
          }
        } else {
          if(this.newFilterParams) {
            modifiedParam.filterParams = this.newFilterParams;
          }
        }
      }
      this.httpService.post(
        environment.APIUrl + APIURLS['estimateSearchCount'],
        modifiedParam,
        response => {
          if (response) {
            callBack(response.totalRecords);
            // enable this code for  "No matched record, please return to previous result" fix - 9912
            if (response.totalRecords === 0) {
              /* If the advanced filter open then we should not reset the result set until search button clicked */
              if (!PageSettingsService.pageSettings.showAdvancedFilter) {
                SearchService.resultObservable.next([]);
                SearchService.facetObservable.next([]);
              }
              SearchService.hasResultsObservable.next({
                hasResult: false,
                count: 0
              });
            }
          } else {
            SearchService.resultObservable.next([]);
            SearchService.facetObservable.next([]);
          }
        },
        error => {
          SearchService.resultObservable.next([]);
          SearchService.facetObservable.next([]);
        }
      );
    });
  } 
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }
  removeSelectedValFromArray(val, arr) {
    let value = val;
    arr = arr.filter(item => item !== value)
    return arr;
  }

  updateSearchParam(param) {
    if (param) {
      if (param.searchParams) {
        if (param.searchParams.length) {
          for (let i = 0; i < param.searchParams.length; i++) {
            if (param.searchParams[i].fieldValue.includes('"')) {
              param.searchParams[i].fieldValue = param.searchParams[i].fieldValue.trim();
            } else {
              if (param.searchParams[i].fieldName === 'ALL') {
                param.searchParams[i].fieldValue = param.searchParams[i].fieldValue.trim().replace(/[^a-zA-Z0-9 ?!*()-]/g, '');
              } else {
                param.searchParams[i].fieldValue = param.searchParams[i].fieldValue.trim();
              }
            }
            if (param.searchParams[i].fieldName === 'barcode') {
              param.searchParams[i].fieldName = 'manuscriptNoV2';
            }
          }
        }
      }
    }
    return param;
  }
  public estimateSimpleSearchCount(searchKey, callBack) {
    let splittedTerms: any = this.splitTerms(searchKey);
    // splittedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
    if (splittedTerms) {
      if (splittedTerms.indexOf('AND', 'OR', 'NOT', 'and', 'or', 'not') === -1) {
        const booleanOperator = _without(splittedTerms, 'AND', 'OR', 'NOT', 'and', 'or', 'not');
        if (booleanOperator.length > 8) {
          splittedTerms = splittedTerms.splice(0, splittedTerms.indexOf(booleanOperator[7]) + 1);
        }
      } else {
        splittedTerms = splittedTerms.splice(0, 8);
      }
      splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
    }
    if (PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP') {
      PageSettingsService.pageSettings.currentSelectedArchives = JSON.parse((localStorage.getItem('wileySelectedArchive')));
    }
    
    if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf('RCP') > -1) {
      PageSettingsService.pageSettings.currentSelectedArchives = 
      PageSettingsService.pageSettings.currentSelectedArchives.filter(item => item !== 'RCP');
      PageSettingsService.pageSettings.currentSelectedArchives.push('RCP1', 'RCP2');
    }
    if (PageSettingsService.pageSettings.currentSelectedArchives.indexOf('RGS') > -1) {
      PageSettingsService.pageSettings.currentSelectedArchives = 
      PageSettingsService.pageSettings.currentSelectedArchives.filter(item => item !== 'RGS');
      PageSettingsService.pageSettings.currentSelectedArchives.push('RGS1', 'RGS2');
    }
    this.httpService.post(
      environment.APIUrl + APIURLS['estimateSearchCount'],
      {

        searchTerm: splittedTerms,
        filterParams: [
          {
            fieldName: 'archive',
            fieldValues:
              PageSettingsService.pageSettings.currentArchive === 'WILEY'
                || PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP'
                ? PageSettingsService.pageSettings.currentSelectedArchives
                : [PageSettingsService.pageSettings.currentArchive],
            operator: 'OR'
          }
        ]
      },
      response => {
        if (response.totalRecords === 0 || response.totalRecords === '0') {
          // tslint:disable-next-line:max-line-length
          this.popupService.showNotification('WARNING', this.translatedValues.searchResults, this.translatedValues.searchDidNotReturnResult);
          if ($('#searchResultDiv')) {
            $('#searchResultDiv').hide();
          }
          if ($('app-browser-map')) {
            $('app-browser-map').hide();
          }
          callBack(response.totalRecords);
        } else {
          callBack(response.totalRecords);
        }
      },
      error => {
        SearchService.resultObservable.next([]);
        SearchService.facetObservable.next([]);
      }
    );
  }

  public viewMoreResults(group) {
    if (!this.loading) {
      this.loading = true;
      const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
      if (currentSearchSettings.currentOffset === 0) {
        currentSearchSettings.currentOffset += 5;
      } else if (currentSearchSettings.currentOffset === 5) {
        currentSearchSettings.currentOffset = 10;
      } else {
        currentSearchSettings.currentOffset += 10;
      }
      this.pageSettingService.updateSearchSettings(currentSearchSettings);
      this.buildFilterParams(currentSearchSettings, params => {
        if (currentSearchSettings.currentOffset === 5) {
          if (group === 'Photographs') {
            params['groupLimit'] = 20;
          } else {
            params['groupLimit'] = 5;
          }
        } else {
          params['groupLimit'] = 10;
        }
        // this.popupService.showLoader();
        params.filterParams.forEach(obj => {
          if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
            obj.condition = 'All';
            obj.fieldValue = '';
          }
        });
        params = this.updateSearchParam(params);
        if (typeof params === 'string') {
          params = JSON.parse(params);
        }
        for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
          if (params.filterParams[k].fieldName === "archive"){
            const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
            this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
            if (!this.includeWPA) {
              const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
              if (havewpa) {
                if (typeof params.filterParams[k].fieldValues  === 'string') {
                  params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
                 }
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
                 let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
                  if(!havewpaInSubs){
                    params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
                  }
              }
            }
            this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
            if (!this.includeWPA1) {
              const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
              if (havewpa) {
                if (typeof params.filterParams[k].fieldValues  === 'string') {
                  params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
                 }
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
                 let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
                  if(!havewpaInSubs){
                    params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
                  }
              }
            }
          }
        }
        this.httpService.post(
          environment.APIUrl + APIURLS['advancedSearch'],
          params,
          response => {
            // this.popupService.hideLoader();
            let currentResult = SearchService.resultObservable.getValue();
            const currentGroup = null;
            const newResult =
              response.results.groupResults[0].groupEntry.groupEntryContent;
            newResult.forEach(newElement => {
              if (newElement.group === group) {
                currentResult = currentResult.map(val => {
                  if (val.group === group) {
                    if (newElement.documents) {
                      newElement.documents.forEach(doc => {
                        val.documents.push(doc);
                      });
                    }
                  }
                  return val;
                });
              }
            });
            SearchService.resultObservable.next(currentResult);
            this.loading = false;
            // this.popupService.hideLoader();
          },
          error => {
            // this.popupService.hideLoader();
            this.loading = false;
          }
        );
      });
    }
  }

  public viewMoreResultsScroll(group, scrollCount, totalGroup, specialParams?: any, contentType?) {    
    let docFromOthersTab = false;
    let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
    let unsubscribedArchives: any = JSON.parse(localStorage.getItem('unsubscribedArchives_tab2'));
    this.sharedService.disablefacets.pipe(takeUntil(this.stop$)).subscribe(res => {
      docFromOthersTab = res;
    })    
    if (!this.loading) {
      this.loading = true;
      if (scrollCount <= totalGroup) {
        const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
       // get related data--advance search 
      //   if(currentSearchSettings.archive.length == 0 ){
      //   const data =  JSON.parse(localStorage.getItem('filter'));
      //   currentSearchSettings.archive =  data.filterParams[0].fieldValues;
      //  }
        this.pageSettingService.updateSearchSettings(currentSearchSettings);
        this.buildFilterParams(currentSearchSettings, params => {
         
          if (scrollCount === 5) {
            params['groupLimit'] = 5;
            params['groupOffset'] = scrollCount;
          } else {
            params['groupLimit'] = 10;
            params['groupOffset'] = scrollCount;
          }
          if (specialParams) {
            params['spatialFilterParams'] = specialParams;
          }
          if (group === 'Photographs') {
            params['groupLimit'] = 20;
          }

          // this.popupService.showLoader();
          params.filterParams.forEach(obj => {
            if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
              obj.condition = 'All';
              obj.fieldValue = '';
            }
          });
          if (docFromOthersTab) {
            params.filterParams.forEach(obj => {
              if (obj.fieldName === 'archive') {
                obj.fieldValues = unsubscribedArchives;
              }
            });
          }

          if (contentType) {
            const filterContentType = params.filterParams.filter(item => item.fieldName === "contentType");
            if (filterContentType.length >0) {
              params.filterParams.forEach(item=> {
                  if(item.fieldName === "contentType") {
                    item.fieldValues = [contentType];
                  } 
                })
            } else {
               params.filterParams.push({ fieldName: 'contentType', fieldValue: contentType, operator: 'OR' });

            }
          }
          let splittedTerms: any = this.splitTerms(params.searchTerm);
          splittedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
          splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
          params.searchTerm = splittedTerms;           
          params = this.updateSearchParam(params);
          if (typeof params === 'string') {
            params = JSON.parse(params);
          }
        for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
          if (params.filterParams[k].fieldName === "archive"){
            const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
            this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
            if (!this.includeWPA) {
              const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
              if (havewpa) {
                if (typeof params.filterParams[k].fieldValues  === 'string') {
                  params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
                 }  
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
                 let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
                  if(!havewpaInSubs){
                    params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
                  }  
              }
            }
            this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
            if (!this.includeWPA1) {
              const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
              if (havewpa) {
                if (typeof params.filterParams[k].fieldValues  === 'string') {
                  params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
                 }  
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
                 let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
                  if(!havewpaInSubs){
                    params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
                  }  
              }
            }
          }
        }
          this.httpService.post(environment.APIUrl + APIURLS['advancedSearch'], params, response => {
            if (response.status === 'SUCCESS') {
              let currentResult = SearchService.resultObservable.getValue();
              const currentGroup = null;
              const newResult = response.results.groupResults[0].groupEntry.groupEntryContent;
              newResult.forEach(newElement => {
                if (newElement.group === group) {
                  currentResult = currentResult.map(obj => {
                    if (obj.group === group) {
                      if (newElement.documents) {
                        newElement.documents.forEach(doc => {
                          obj.documents.push(doc);
                        });
                      }
                    }
                    return obj;
                  });
                }
              });
              SearchService.resultObservable.next(currentResult);
              this.loading = false;
              setTimeout(() => {
                // this.popupService.hideLoader();
              }, 500);
            } else {
              this.popupService.showNotification('ERROR', this.translatedValues.sorry || 'Sorry', this.translatedValues.failureOccured);
              this.loading = false;
            }
          }, error => {
            // this.popupService.hideLoader();
            this.loading = false;
          });
        });
      } else {
        this.loading = false;
      }
    }
  }
  public viewMoreFacets(facet) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentFacetOffset = 0;

    // this.popupService.showLoader();
    if (
      Array.isArray(currentSearchSettings.facetFields) &&
      currentSearchSettings.facetFields.length > 0 && (currentSearchSettings.facetFields.filter(e => e.facetField === facet).length > 0)
    ) {
      currentSearchSettings.facetFields.forEach(facetResults => {
        if (facetResults.facetField === facet) {
          facetResults.facetOffset = facetResults.facetOffset + facetResults.facetLimit;
          facetResults.facetLimit = 20;
        }
      });
    } else {
      currentSearchSettings.facetFields.push({
        facetField: facet,
        facetOffset: 5,
        facetLimit: 20
      });
    }
    this.pageSettingService.updateSearchSettings(currentSearchSettings);

    this.buildFilterParams(currentSearchSettings, params => {
      params.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
      });
      params = this.updateSearchParam(params);
      if (typeof params === 'string') {
        params = JSON.parse(params);
      }
      for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
        if (params.filterParams[k].fieldName === "archive"){
          const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
          this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
          if (!this.includeWPA) {
            const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
            if (havewpa) {
              if (typeof params.filterParams[k].fieldValues  === 'string') {
                params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
               }  
               let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
                if(!havewpaInSubs){
                  params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
                }
            }
          }
          this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
          if (!this.includeWPA1) {
            const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
            if (havewpa) {
              if (typeof params.filterParams[k].fieldValues  === 'string') {
                params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
               }  
               let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
                if(!havewpaInSubs){
                  params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
                }
            }
          }
        }
      }
      this.httpService.post(
        environment.APIUrl + APIURLS['advancedSearch'],
        params,
        response => {
          let currentFacet = SearchService.facetObservable.getValue();
          const currentGroup = null;
          const newFacets = response.results.facetResults;
          newFacets.forEach(newElement => {
            if (newElement.facetField === facet) {
              currentFacet = currentFacet.map(currentFacetelement => {
                if (currentFacetelement.facetField === facet) {
                  newElement.facetContent.forEach(newFacet => {
                    currentFacetelement.facetContent.push(newFacet);
                  });
                  currentFacetelement.hasMore = newElement.hasMore;
                }
                return currentFacetelement;
              });
            }
          });
          SearchService.facetObservable.next(currentFacet);
          // this.popupService.hideLoader();
        },
        error => { }
      );
    });
  }

  public search(searchKey, selectedArchives, callBack?, contentType?: any) {
    // localStorage.setItem('wileySelectedArchive', JSON.stringify(PageSettingsService.pageSettings.currentSelectedArchives));
    SearchService.hasResultsObservable.next({ hasResult: true, count: 0 });

    // this.popupService.showLoader();
    let splittedTerms: any = this.splitTerms(searchKey);
    if (splittedTerms) {
      this.booleanSearch(splittedTerms);
      splittedTerms = splittedTerms.filter((entry) => {
        return entry.trim() !== '';
      });
      if (splittedTerms.indexOf('AND', 'OR', 'NOT') > -1) {
        const booleanOperator = _without(splittedTerms, 'AND', 'OR', 'NOT');
        if (booleanOperator.length > 8) {
          splittedTerms = splittedTerms.splice(0, splittedTerms.indexOf(booleanOperator[7]) + 1);
        }
      } else {
        splittedTerms = splittedTerms.splice(0, 8);
      }
      splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
      searchKey = encodeURIComponent(splittedTerms);
    }

    let searchUrl = environment.APIUrl + APIURLS['search'];
    searchUrl += '?q=' + searchKey;
    if (Array.isArray(selectedArchives)) {
      searchUrl += '&fq=archive:';
      let data = '';
      selectedArchives.forEach((archive, index) => {
        data += archive.toLowerCase();
        if (index !== selectedArchives.length - 1) {
          data += ';';
        }
      });
      const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
      this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
      if (!this.includeWPA) {
       var checkWPA = data.split(';');
       for(let k = 0; k < checkWPA.length; k++) {
        if (checkWPA[k] === 'wpa') {
          checkWPA = checkWPA.filter(item => item !== 'wpa');
          const checkWPAstr = checkWPA.join(';');
          data = checkWPAstr.toString();
        }
       }
      }
      this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
      if (!this.includeWPA1) {
       var checkWPA = data.split(';');
       for(let k = 0; k < checkWPA.length; k++) {
        if (checkWPA[k] === 'wpa1') {
          checkWPA = checkWPA.filter(item => item !== 'wpa1');
          const checkWPAstr = checkWPA.join(';');
          data = checkWPAstr.toString();
        }
       }
      }
      searchUrl += data;
    }
    searchUrl += '&groupLimit=';
    const GROUPLIMITCOPY = GROUPLIMIT;
    searchUrl += GROUPLIMITCOPY;
    const checkadiyionalArchives = localStorage.getItem('fromAdditionalArchive');
     if (checkadiyionalArchives === 'true') {
      searchUrl += '&otherArchive=true';
     } else {
      searchUrl += '&otherArchive=false';
     }
    //  localStorage.removeItem('fromAdditionalArchive');
    this.httpService.get(searchUrl, response => {
      if (response) {
        const result = response.results.groupResults[0].groupEntry.groupEntryContent;
        if (Array.isArray(result) && result.length === 0) {
          SearchService.hasResultsObservable.next({
            hasResult: false,
            count: 0
          });
        } else if (Array.isArray(result) && result.length > 0) {
          SearchService.hasResultsObservable.next({
            hasResult: true,
            count: response.totalRecords
          });
        }
        if (typeof callBack === 'function') {
          callBack({
            facets: response.results.facetResults,
            groups:
              response.results.groupResults[0].groupEntry.groupEntryContent,
            count: response.totalRecords
          });
        }
        setTimeout(() => {
          // this.popupService.hideLoader();
          this.sharedService.setSearchLoading(false);
          SearchService.resultObservable.next(result);
          SearchService.facetObservable.next(response.results.facetResults);
        }, 1000);
      } else {
        // this.popupService.hideLoader();
        this.sharedService.setSearchLoading(false);
        SearchService.resultObservable.next([]);
        SearchService.facetObservable.next([]);
      }
    }, error => {
      if (typeof callBack === 'function') {
        callBack(error);
      }
      // this.popupService.hideLoader();
      this.sharedService.setSearchLoading(false);
      SearchService.hasResultsObservable.next({
        hasResult: false,
        count: 0
      });
      SearchService.resultObservable.next([]);
      SearchService.facetObservable.next([]);
    }
    );
    //for facet archives List - simple search
    let disableFacetFlag = false;
    this.sharedService.disablefacets.pipe(takeUntil(this.stop$)).subscribe(res => {
      disableFacetFlag = res;
    })
    let archivesList: any;
    let dataFacet = [];
    setTimeout(() => {
      if (disableFacetFlag) {
        archivesList = JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) ? 
        JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) : JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
        let data1 = JSON.parse(sessionStorage.getItem('unsubscribedArchives_tab2')) ? 
        JSON.parse(sessionStorage.getItem('unsubscribedArchives_tab2')) : JSON.parse(localStorage.getItem('unsubscribedArchives_tab2'));
        dataFacet = data1;
      } else {
        archivesList = JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) ? 
        JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) : JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
        if (Array.isArray(archivesList)) {
          archivesList.forEach((archive, index) => {
            dataFacet.push(archive.shortName);
          });
        }
      }
      const facetParams = {
        searchParams: [],
        filterParams: [{ 'fieldName': 'archive', 'fieldValues': dataFacet, 'operator': 'OR' }],
        // searchTerm: PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm : '',
        groupOffset: 0,
        groupLimit: GROUPLIMIT
      };
            let splitedTerms: any = this.splitTerms(PageSettingsService.pageSettings.searchTerm);
  
      if (splitedTerms) {
        splitedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
        splitedTerms = splittedTerms.toString().replace(/,/g, ' ');
        facetParams['searchTerm'] = splittedTerms;
      } else {
        facetParams['searchTerm'] = PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm : '';
      }
      for(let k=0; k< facetParams.filterParams.length; k++) {
          if (facetParams.filterParams[k].fieldName === "archive"){
            const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
            this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
            if (!this.includeWPA) {
              const havewpa = facetParams.filterParams[k] && facetParams.filterParams[k].fieldValues.includes("WPA");
              if (havewpa) {
                let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
                if(!havewpaInSubs){
                  facetParams.filterParams[k].fieldValues = facetParams.filterParams[k].fieldValues.filter(item => item !== 'WPA')
                }
              }
            }
            this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
            if (!this.includeWPA1) {
              const havewpa = facetParams.filterParams[k] && facetParams.filterParams[k].fieldValues.includes("WPA1");
              if (havewpa) {
                let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
                if(!havewpaInSubs){
                  facetParams.filterParams[k].fieldValues = facetParams.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
                }
              }
            }
          }
        }
      this.httpService.post(
        environment.APIUrl + APIURLS['getArchivesCountForFacet'],
        facetParams, (response: any) => {
          if (response) {
            SearchService.archivefacetObservable.next(response);
            sessionStorage.setItem('facetArchiveResponse', JSON.stringify(response));
          } else {
            SearchService.facetObservable.next([]);
          }
        },
        (error: any) => {
          SearchService.facetObservable.next([]);
      });
    }, 100);
  }

  public advancedSearch(advancedFilters, callBack?, offset?) {
    advancedFilters.archive = PageSettingsService.pageSettings.currentSelectedArchives;
    SearchService.hasResultsObservable.next({ hasResult: true, count: 0 });
    // this.popupService.showLoader();
    this.buildFilterParams(advancedFilters, params => {
      if (offset) {
        params.groupOffset = offset;
      }
      params.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
        if (obj.fieldName === 'illustrationType') {
          obj.fieldValues.forEach((value, keys) => {
            if (value === 'Table-Handwritten' || value === 'Table-Printed' || value === 'Table_nonEnglish') {
              obj.fieldValues[keys] = '"' + value + '"';
            }
          });
        }
        if (obj.fieldName === 'archive') {
          if (obj.fieldValues.indexOf('RGS') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RGS');
            obj.fieldValues.push('RGS1', 'RGS2');
          }
          if (obj.fieldValues.indexOf('RCP') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RCP');
            obj.fieldValues.push('RCP1', 'RCP2');
          }

        }
      });
      let splittedTerms: any = this.splitTerms(params.searchTerm);
      splittedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
      splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
      params.searchTerm = splittedTerms;
      params = this.updateSearchParam(params);
            let copyparamsforFacets = this.updateSearchParam(params); 
     if(this.downloadContentFilterParam === undefined || [] ){
        this.downloadContentFilterParam = localStorage.getItem('filter');
     }
     const val = JSON.parse(this.downloadContentFilterParam);
     if (val){
      val.searchTerm = params.searchTerm;
      this.downloadContentFilterParam = JSON.stringify(val);
     }
     const checkadiyionalArchives = localStorage.getItem('fromAdditionalArchive');
     var convertObj;
     if (checkadiyionalArchives === 'true') {
      if (typeof params === 'string') {
         convertObj = JSON.parse(params);
      } else {
         convertObj = params;
      }
      convertObj.otherArchive = true;
      params = JSON.stringify(convertObj);
     } else {
      if (typeof params === 'string') {
         convertObj = JSON.parse(params);
      } else {
         convertObj = params;
      }
      convertObj.otherArchive = false;
      params = JSON.stringify(convertObj)
     }
     if (typeof params === 'string') {
      params = JSON.parse(params);
     }
     for(let k=0; k<params.filterParams.length; k++) {
      if (params.filterParams[k].fieldName === "archive"){
        if (checkadiyionalArchives === 'true') {
          let getUnSelectedArchives = JSON.parse(localStorage.getItem('unsubscribedArchives_tab2'))
          getUnSelectedArchives = getUnSelectedArchives.filter(item => item !== 'WPA' && item !== 'WPA1')
          if (typeof getUnSelectedArchives === 'string') {
            getUnSelectedArchives = JSON.parse(getUnSelectedArchives);
          }
          params.filterParams[k].fieldValues = getUnSelectedArchives;
        } 
        const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
        this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
        if (!this.includeWPA) {
          const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
          if (havewpa) {
            if (typeof params.filterParams[k].fieldValues  === 'string') {
              params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
             }
             let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
            let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
            if(!havewpaInSubs){
              params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
            }
          }
        }
        this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
        if (!this.includeWPA1) {
          const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
          if (havewpa) {
            if (typeof params.filterParams[k].fieldValues  === 'string') {
              params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
             }
             let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
            let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
            if(!havewpaInSubs){
              params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
            }
          }
        }
      }
     }
    if (params.filterParams.length === 0) {
      params = this.downloadContentFilterParam;
    }
      this.httpService.post(
        environment.APIUrl + APIURLS['advancedSearch'],
        params,
      //  this.downloadContentFilterParam,
        response => { 
          // this.popupService.hideLoader();
          const result = SearchService.resultObservable.value;
          const newResult =
          response.results && response.results.groupResults[0].groupEntry.groupEntryContent;
          SearchService.resultObservable.next(newResult);
          SearchService.facetObservable.next(response.results.facetResults);
          if (Array.isArray(newResult) && newResult.length === 0) {
            SearchService.hasResultsObservable.next({
              hasResult: false,
              count: 0
            });
          } else if (Array.isArray(newResult) && newResult.length > 0) {
            SearchService.hasResultsObservable.next({
              hasResult: true,
              count: response.totalRecords
            });
          }
          if (typeof callBack === 'function') {
            const test = {
              facets: response.results.facetResults,
              groups:
                response.results.groupResults[0].groupEntry.groupEntryContent,
              count: response.totalRecords
            };
            callBack(test);
          }
          setTimeout(() => {
            this.sharedService.setSearchLoading(false);
            // this.popupService.hideLoader();
          }, 1000);
        },
        error => {
          if (typeof callBack === 'function') {
            callBack(error);
          }
          // this.popupService.hideLoader();
          this.sharedService.setSearchLoading(false);
          SearchService.resultObservable.next([]);
          SearchService.facetObservable.next([]);
        }
      );


      //for facet archives List - advanced search
      let disableFacet = false;

      this.sharedService.disablefacets.pipe(takeUntil(this.stop$)).subscribe(res => {
        disableFacet = res;
      })
      setTimeout(() => {
        let archivesList: any;
        let dataFacet = [];
        if (disableFacet) {
          archivesList = JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) ?
          JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) : JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
          let data1 = JSON.parse(sessionStorage.getItem('unsubscribedArchives_tab2')) ? 
          JSON.parse(sessionStorage.getItem('unsubscribedArchives_tab2')) : JSON.parse(localStorage.getItem('unsubscribedArchives_tab2'));
          dataFacet = data1;
        } else {
          archivesList = JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) ?
          JSON.parse(sessionStorage.getItem('subscribedArchivesForFacet')) : JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
          if (Array.isArray(archivesList)) {
            archivesList.forEach((archive, index) => {
              dataFacet.push(archive.shortName);
            });
          }
        }

        copyparamsforFacets.filterParams.map(res => {
          if (res.fieldName === 'archive') {
            res.fieldValues = dataFacet;
          }
        });
        for(let k=0; k<copyparamsforFacets.filterParams.length; k++) {
          if (copyparamsforFacets.filterParams[k].fieldName === "archive"){
            const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
            this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
            if (!this.includeWPA) {
              const havewpa = copyparamsforFacets.filterParams[copyparamsforFacets.filterParams[k] && k].fieldValues.includes("WPA");
              if (havewpa) {
                if (typeof copyparamsforFacets.filterParams[k].fieldValues  === 'string') {
                  copyparamsforFacets.filterParams[k].fieldValues = JSON.parse(copyparamsforFacets.filterParams[k].fieldValues);
                 }
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
                 let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
                  if(!havewpaInSubs){
                    copyparamsforFacets.filterParams[k].fieldValues = copyparamsforFacets.filterParams[k].fieldValues.filter(item => item !== 'WPA')
                  }
              }
            }
            this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
            if (!this.includeWPA1) {
              const havewpa = copyparamsforFacets.filterParams[copyparamsforFacets.filterParams[k] && k].fieldValues.includes("WPA1");
              if (havewpa) {
                if (typeof copyparamsforFacets.filterParams[k].fieldValues  === 'string') {
                  copyparamsforFacets.filterParams[k].fieldValues = JSON.parse(copyparamsforFacets.filterParams[k].fieldValues);
                 }
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
                 let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
                  if(!havewpaInSubs){
                    copyparamsforFacets.filterParams[k].fieldValues = copyparamsforFacets.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
                  }
              }
            }
          }
        }
        const checkadiyionalArchives = localStorage.getItem('fromAdditionalArchive');
        if (checkadiyionalArchives === 'true') {
          let unsubsVal = JSON.parse(localStorage.getItem('unsubscribedArchives_tab2'))
          unsubsVal = unsubsVal.filter(item => item !== 'WPA' && item !== 'WPA1')
          copyparamsforFacets.otherArchive = true;
          for (let k=0;k< copyparamsforFacets.filterParams.length;k++) {
            if(copyparamsforFacets.filterParams[k].fieldName === "archive") {
              copyparamsforFacets.filterParams[k].fieldValues = unsubsVal;
            }
          }
        } else {
          copyparamsforFacets.otherArchive = false;
        }
        this.httpService.post(
          environment.APIUrl + APIURLS['getArchivesCountForFacet'],
          copyparamsforFacets, (response: any) => {
            if (response) {
              SearchService.archivefacetObservable.next(response);
              sessionStorage.setItem('facetArchiveResponse', JSON.stringify(response));
            } else {
              SearchService.facetObservable.next([]);
            }
          },
          (error: any) => {
            SearchService.facetObservable.next([]);
          });
          // localStorage.removeItem('fromAdditionalArchive');
      }, 100);
    });
  }

  goToAdvancedSearchPage(searchTerm: string, params?: any, contentType?: any, fromSortby?: any, isFromRightClick?: any) {
    this.searchHistory = {
      searchTerm: searchTerm ? searchTerm : ''
    };
    this.URL = '/';
    this.URL += (PageSettingsService.pageSettings.currentArchive + ''
    ).toLowerCase();
    this.URL += '/search';
    this.buildFilterParams(params, urlData => {
      const filterparams = urlData.filterParams;
      this.buildURL(urlData, filterData => {
        if (filterData) {
          this.searchHistory['filter'] = filterData;
        }
        if (contentType && (fromSortby === true)) {
          this.searchHistory['display'] = contentType;
        }
        this.estimateResultCount(params, count => {
          const startDate = new Date(params.publishedDateFrom);
          const endDate = new Date(params.publishedDateTo);
          if (count > 0) {
            setTimeout(() => {
              let urlForHistory;
              if (isFromRightClick === 'true' && isFromRightClick !== undefined) {
                const urlFilter = encodeURIComponent(this.searchHistory['filter']);
                const urlsearchTerm = encodeURIComponent(this.searchHistory['searchTerm']);
                const host = window.location.host;
                const pathName = window.location.pathname;
                const protocol = window.location.protocol;
                urlForHistory = `${pathName}/search;searchTerm=${urlsearchTerm};filter=${urlFilter}`
              } else {
                 urlForHistory = window.location.pathname;
              }
              const objDate = Date.now();
              const historyAdvancedFilterParam =
                PageSettingsService.pageSettings.advancedFilterParam;
              if (
                JSON.parse(localStorage.getItem('HistoryList')).length > 0
              ) {
                this.previousHistoryObject = JSON.parse(
                  localStorage.getItem('HistoryList')
                );
                let historyItem = {
                  url: urlForHistory,
                  filterParams: historyAdvancedFilterParam,
                  date: objDate,
                  searchParams: searchTerm ? searchTerm : '',
                  filters: JSON.parse(localStorage.getItem('filter'))
                };
                const filterVal = JSON.parse(localStorage.getItem('filter'));
                if(filterVal) {
                  for(let k=0; k<filterVal.filterParams.length; k++) {
                    if(filterVal.filterParams[k].fieldName === "archive") {
                      historyItem.filterParams['archive'] = filterVal.filterParams[k].fieldValues;
                    }
                  }
                   historyItem = {
                    url: urlForHistory,
                    filterParams: historyAdvancedFilterParam,
                    date: objDate,
                    searchParams: searchTerm ? searchTerm : '',
                    filters: JSON.parse(localStorage.getItem('filter'))
                  };
                }
                this.previousHistoryObject.push(historyItem);
                localStorage.setItem(
                  'HistoryList',
                  JSON.stringify(this.previousHistoryObject)
                );
              } else {
                const historyItem = {
                  url: urlForHistory,
                  filterParams: historyAdvancedFilterParam,
                  date: objDate,
                  searchParams: searchTerm ? searchTerm : '',
                  filters: JSON.parse(localStorage.getItem('filter'))
                };
                this.historyObject.push(historyItem);
                localStorage.setItem(
                  'HistoryList',
                  JSON.stringify(this.historyObject)
                );
              }
            });
            if (isFromRightClick === 'true' && isFromRightClick !== undefined) {
              const urlFilter = encodeURIComponent(this.searchHistory['filter']);
              const urlsearchTerm = encodeURIComponent(this.searchHistory['searchTerm']);
              const host = window.location.host;
              const pathName = window.location.pathname;
              const protocol = window.location.protocol;
              const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
              currentSearchSettings['docSubType'] =[];
              currentSearchSettings['contentType'] =[];
              this.pageSettingService.updateSearchSettings(currentSearchSettings);
              window.open(`${protocol}//${host}${pathName}/search;searchTerm=${urlsearchTerm};filter=${urlFilter}`, '_blank');
            } else {
              this.router.navigate([this.URL, this.searchHistory]);
               
              // SearchService.documentUrlObservable.subscribe((data:any)=>{
              //   if(data){
              //     this.router.navigate(['/wiley/downloadcontenttable']);
              //   }                
              // }) 
            
            }


          } else if (startDate > endDate) {
            this.popupService.showNotification('WARNING', this.translatedValues.searchResults, this.translatedValues.endDateValidation);
            // this.popupService.hideLoader();
          } else {
            this.popupService.showNotification('WARNING', this.translatedValues.searchResults,
              this.translatedValues.searchDidNotReturnResult);
            if ($('#searchResultDiv')) {
              $('#searchResultDiv').hide();
            }
            if ($('app-browser-map')) {
              $('app-browser-map').hide();
            }
            // this.popupService.hideLoader();
          }
        });
      });
    });
  }

  goToTermFrequencyPage(searchTerm: string, params?: any, id?: any, flag?: boolean, isFromTATTools?: boolean) {
    this.searchHistory = {
      searchTerm: searchTerm ? searchTerm : ''
    };
    this.termId = id;
    this.pageFlage = flag;
    this.URL = '/';
    this.URL += (PageSettingsService.pageSettings.currentArchive + ''
    ).toLowerCase();
    // this.URL += '/location/';
    // this.URL += PageSettingsService.pageSettings.currentLocation;
    this.URL += '/analysis-tools';

    const searchUrl = `${(PageSettingsService.pageSettings.currentArchive + ''
    ).toLowerCase()}/search`;
    //   const searchUrl = `${(PageSettingsService.pageSettings.currentArchive + ''
    // ).toLowerCase()}/location/${PageSettingsService.pageSettings.currentLocation}/search`;
    this.buildFilterParams(params, urlData => {
      const filterparams = urlData.filterParams;
      if (!isFromTATTools) {
        sessionStorage.setItem('lastResultPageSearchHistory', window.location.pathname);
      }
      if (params['pubStartDate'] || params['pubEndDate']) {
        this.searchHistory['startDate'] = params['pubStartDate'];
        this.searchHistory['endDate'] = params['pubEndDate'];
      }

      this.buildURL(urlData, filterData => {
        // const objDate = Date.now();
        // this.previousHistoryObject = JSON.parse(localStorage.getItem('HistoryList'));
        // this.previousHistoryObject = this.previousHistoryObject ? this.previousHistoryObject : [];
        // if (this.previousHistoryObject.length > 0) {
        //   const historyItem = {
        //     url: searchUrl,
        //     searchParams: '',
        //     date: objDate,
        //     isForTATTools: true
        //   };
        //   this.previousHistoryObject.push(historyItem);
        //   localStorage.setItem('HistoryList', JSON.stringify(this.previousHistoryObject));
        // } else {
        //   const historyItem = {
        //     url: searchUrl,
        //     searchParams: '',
        //     date: objDate,
        //     isForTATTools: true
        //   };
        //   this.historyObject.push(historyItem);
        //   localStorage.setItem('HistoryList', JSON.stringify(this.historyObject));
        // }
        if (this.pageFlage === true) {
          if (filterData) {
            this.searchHistory['filter'] = filterData;
            this.searchHistory['ActiveIndex'] = this.termId;
            this.searchHistory['fromSearchResult'] = this.pageFlage;
          }
          this.router.navigate([this.URL, this.searchHistory]);
        } else {
          if (filterData) {
            this.searchHistory['filter'] = filterData;
            this.searchHistory['ActiveIndex'] = this.termId;
          }
          this.router.navigate([this.URL, this.searchHistory]);
        }
      });
    });
  }
  backToSearchResult(searchTerm: string, params?: any, fromCollocates?: any, fromWordCloud?: any) {
    this.searchHistory = {
      searchTerm: searchTerm ? searchTerm : ''
    };
    this.URL = '/';
    this.URL += (PageSettingsService.pageSettings.currentArchive + ''
    ).toLowerCase();
    // this.URL += '/location/';
    // this.URL += PageSettingsService.pageSettings.currentLocation;
    this.URL += '/search';
    this.buildFilterParams(params, urlData => {
      if (fromCollocates || fromWordCloud) {
        this.searchHistory['fromTATTools'] = true;
        this.searchHistory['isClearSearchTerm'] = true;
        this.searchHistory['selectedArchives'] = params.filterParams.map(res => {
          if (res.fieldName === 'archive') {
            return res.fieldValues;
          }
        });
      }
      const filterparams = urlData.filterParams;
      if (params['pubStartDate'] || params['pubEndDate']) {
        this.searchHistory['startDate'] = params['pubStartDate'];
        this.searchHistory['endDate'] = params['pubEndDate'];
      }

      this.buildURL(urlData, filterData => {
        if (filterData) { this.searchHistory['filter'] = filterData; }
        this.router.navigate([this.URL, this.searchHistory]);
      });
    });
    setTimeout(() => {
      if (fromCollocates || fromWordCloud) {
        let historyTitle;
        if (fromCollocates) {
          historyTitle = 'collocate';
        } else if (fromWordCloud) {
          historyTitle = 'wordcloud';
        }
        let previousHistoryObject = JSON.parse(
          localStorage.getItem('HistoryList')
        );
        const historyItem = {
          url: window.location.pathname,
          filterParams: PageSettingsService.pageSettings.advancedFilterParam,
          searchParams: searchTerm ? searchTerm : '',
          date: Date.now(),
          fromCollocates: historyTitle
        };
        previousHistoryObject = previousHistoryObject instanceof Array ? previousHistoryObject : [];
        previousHistoryObject.push(historyItem);
        localStorage.setItem(
          'HistoryList',
          JSON.stringify(previousHistoryObject)
        );
      } else {
        if (JSON.parse(localStorage.getItem('HistoryList')).length > 0) {
          this.previousHistoryObject = JSON.parse(
            localStorage.getItem('HistoryList')
          );
          const historyItem = {
            url: window.location.pathname,
            filterParams: PageSettingsService.pageSettings.advancedFilterParam,
            date: Date.now()
          };
          this.previousHistoryObject.push(historyItem);
          localStorage.setItem(
            'HistoryList',
            JSON.stringify(this.previousHistoryObject)
          );
        }
      }
    }, 2500);
  }




  goToSearchPage(searchTerm: string, isFromHomePage?: boolean) {     
    if (PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP') {
      PageSettingsService.pageSettings.currentSelectedArchives = JSON.parse((localStorage.getItem('wileySelectedArchive')));
    }

    // if (!loadingsearch) {
    // loadingsearch = true;
    const data = {
      searchTerm: searchTerm ? searchTerm : ''
    };
    if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      data['selectedArchives'] =
        PageSettingsService.pageSettings.currentSelectedArchives;
    } else {
      if (PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP') {
        data['selectedArchives'] =
          PageSettingsService.pageSettings.currentSelectedArchives;
      } else {
        data['selectedArchives'] = [
          PageSettingsService.pageSettings.currentArchive
        ];
      }
    }
    let URL = '/';
    URL += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
    URL += '/search';
    this.estimateSimpleSearchCount(searchTerm, count => {
      // searchTerm = searchTerm.replace(/[^a-zA-Z0-9- ]/g, '');
      if (count > 0) {
        data['selectedArchives'] = JSON.parse(localStorage.getItem('wileySelectedArchive'));
        this.router.navigate([URL, data], {});
        var createPath ='';
        for(let k=0;k<data['selectedArchives'].length; k++) {
          if(createPath) {
            createPath = createPath + ','+ data['selectedArchives'][k];
          } else {
            createPath = data['selectedArchives'][k];
          }
        }
        loadingsearch = false;
        const objDate = Date.now();
        const pathName = window.location.pathname;
        const urlForHistory = `${pathName}/search;searchTerm=;selectedArchives=${createPath}`
        if (JSON.parse(localStorage.getItem('HistoryList')).length > 0) {
          this.previousHistoryObject = JSON.parse(
            localStorage.getItem('HistoryList')
          );
          const historyItem = {
            url: urlForHistory,
            searchParams: searchTerm,
            date: objDate
          };
          this.previousHistoryObject.push(historyItem);
          localStorage.setItem(
            'HistoryList',
            JSON.stringify(this.previousHistoryObject)
          );
        } else {
          const historyItem = {
            url: urlForHistory,
            searchParams: searchTerm,
            date: objDate
          };
          this.historyObject.push(historyItem);
          localStorage.setItem(
            'HistoryList',
            JSON.stringify(this.historyObject)
          );
        }
      } else {
        $('.modal-footer button.btn').trigger('focus');
        if (!isFromHomePage) {
          this.router.navigate([URL, data], {});
          loadingsearch = false;
        }
      }
    });
    // }
  }
  goToSearchFromHistoryPage(searchTerm: string, url: string) {
    const data = {
      searchTerm: searchTerm ? searchTerm : ''
    };
    if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      // data['selectedArchives'] =
      //   PageSettingsService.pageSettings.currentSelectedArchives;
      data['selectedArchives'] = JSON.parse(localStorage.getItem('wileySelectedArchive'));
    } else {
      if (PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP') {
        // data['selectedArchives'] =
        //   PageSettingsService.pageSettings.currentSelectedArchives;
        data['selectedArchives'] = JSON.parse(localStorage.getItem('wileySelectedArchive'));
      } else {
        data['selectedArchives'] = [
          PageSettingsService.pageSettings.currentArchive
        ];
      }
    }
    // let URL = "/";
    // URL += (PageSettingsService.pageSettings.currentArchive + "").toLowerCase();
    // URL += "/location/";
    // URL += PageSettingsService.pageSettings.currentLocation;
    // URL += "/search";
    this.estimateSimpleSearchCount(searchTerm, count => {
      if (count > 0) {
        this.router.navigate([url, data], {});
        const objDate = Date.now();
        if (JSON.parse(localStorage.getItem('HistoryList')).length > 0) {
          this.previousHistoryObject = JSON.parse(
            localStorage.getItem('HistoryList')
          );
          const historyItem = {
            url: window.location.pathname,
            searchParams: searchTerm,
            date: objDate
          };
          this.previousHistoryObject.push(historyItem);
          localStorage.setItem(
            'HistoryList',
            JSON.stringify(this.previousHistoryObject)
          );
        } else {
          const historyItem = {
            url: window.location.pathname,
            searchParams: searchTerm,
            date: objDate
          };
          this.historyObject.push(historyItem);
          localStorage.setItem(
            'HistoryList',
            JSON.stringify(this.historyObject)
          );
        }
      } else {
        this.popupService.showNotification('WARNING', this.translatedValues.searchResults, this.translatedValues.searchDidNotReturnResult);
        if ($('#searchResultDiv')) {
          $('#searchResultDiv').hide();
        }
        if ($('app-browser-map')) {
          $('app-browser-map').hide();
        }
        // this.popupService.showAlertPopup(
        //   'Warning',
        //   this.translatedValues.searchDidNotReturnResult,
        //   null
        // );
        $('.modal-footer button.btn').trigger('focus');
      }
    });
  }
  deleteHistory() {
    localStorage.setItem('HistoryList', JSON.stringify([]));
    this.previousHistoryObject = '';
    this.historyObject = [];
  }
  buildURL(params, callBack) {
    let url = '';
    let contentTypes = '';
    let contentSubTypes = '';
    let urlValues = '';
    let collection = '';
    let language = '';
    let contentSources = '';
    let archive = '';
    let pubDateFrom = '';
    let pubDateTo = '';
    let pubDatePrefix = '';
    let pubDateIncludesNull = '';
    let subject = '';
    let illustrationType = '';

    if (params) {
      if (params.filterParams) {
        params.filterParams.forEach(obj => {
          if (Array.isArray(obj.fieldValues)) {
            urlValues = this.buildPipeSeparatedValues(obj.fieldValues);
            if (obj.fieldName === 'contentType') {
              contentTypes = 'contentType=' + urlValues;
            }
            if (obj.fieldName === 'docSubType') {
              contentSubTypes = 'docSubType=' + urlValues;
            }
            if (obj.fieldName === 'collection') {
              collection = 'contentCollection=' + urlValues;
            }
            if (obj.fieldName === 'docLang') {
              language = 'languages=' + urlValues;
            }
            if (obj.fieldName === 'sourceInstitution') {
              contentSources = 'contentSources=' + urlValues;
            }
            if (obj.fieldName === 'archive') {
              archive = 'archive=' + urlValues;
            }
            if (obj.fieldName === 'subject') {
              subject = 'subject=' + urlValues;
            }
            if (obj.fieldName === 'illustrationType') {
              illustrationType = 'illustrationType=' + urlValues;
            }
          } else {
            if (obj.fieldName === 'pubStartDate') {
              pubDateFrom =
                'publishedDateFrom=' +
                (obj.fieldValue ? obj.fieldValue : '');
              pubDateTo =
                'publishedDateTo=' +
                (obj.fieldEndValue ? obj.fieldEndValue : '');
              pubDatePrefix =
                'publishedDatePrefix=' +
                (obj.condition
                  ? this.pubDateDataPrefix[obj.condition]
                  : '');
              pubDateIncludesNull =
                'publishedDateIncludesNull=' +
                (obj.includeNull ? obj.includeNull : '');
            }
          }
        });
      }

      let searchParamValues = '';
      let index = 1;
      if (params.searchParams.length > 0) {
        searchParamValues = 'searchKeywords=';
        params.searchParams.forEach(obj => {
          if (index < params.searchParams.length) {
            searchParamValues += obj.fieldName + '#';
            searchParamValues += obj.fieldValue + '#';
            searchParamValues += obj.operator;
            searchParamValues += '|';
          } else {
            searchParamValues += obj.fieldName + '#';
            searchParamValues += obj.fieldValue + '#';
            searchParamValues += obj.operator;
          }
          index++;
        });
      }

      url += contentTypes ? contentTypes + '&&' : '';
      url += contentSubTypes ? '&&' + contentSubTypes : '';
      url += collection ? '&&' + collection : '';
      url += language ? '&&' + language : '';
      url += contentSources ? '&&' + contentSources : '';
      url += archive ? '&&' + archive : '';
      url += pubDateFrom ? '&&' + pubDateFrom : '';
      url += pubDateTo ? '&&' + pubDateTo : '';
      url += pubDatePrefix ? '&&' + pubDatePrefix : '';
      url += pubDateIncludesNull ? '&&' + pubDateIncludesNull : '';
      url += searchParamValues ? '&&' + searchParamValues : '';
      url += subject ? '&&' + subject : '';
      url += illustrationType ? '&&' + illustrationType : '';
      if (params.sortFields) {
        if (Object.keys(params.sortFields).length > 0) {
          url += '&&sortby=';
          Object.keys(params.sortFields).forEach(key => {
            url += key + '|' + params.sortFields[key];
          });
        }
      }
      callBack(url);
    }
  }

  buildPipeSeparatedValues(fieldValues) {
    let urlVals = '';
    let i = 1;
    if (fieldValues) {
      fieldValues.forEach(obj => {
        if (fieldValues.length > i) {
          urlVals += obj + '|';
        } else {
          urlVals += obj;
        }
        i++;
      });
      return urlVals;
    }
    return null;
  }

  getDocumentDetail(docID, callBack) {
    if (docID) {
      if (this.cacheDocumentDetails) {
        if (this.cacheDocumentDetails[docID]) {
          return callBack(this.cacheDocumentDetails[docID]);
        }
      }
      this.httpService.get(environment.APIUrl + APIURLS['getDocumentDetail'] + docID, response => {
        this.cacheDocumentDetails[docID] = response;
        callBack(response);
      },
        error => {
          callBack(null);
        }
      );
    }
  }

  getDocumentItembyType(docID, callBack) {
    if (docID) {
      if (this.cache.documentItembyType[docID]) {
        return callBack(this.cache.documentItembyType[docID]);
      }
      this.httpService.get(
        environment.APIUrl + APIURLS['getDocumentItembyType'] + docID,
        response => {
          this.cache.documentItembyType[docID] = response;
          callBack(response);
        },
        error => { }
      );
    }
  }

  getDocumentByItem(docID, callBack) {
    if (docID) {
      if (this.cache.documentByItemData[docID]) {
        return callBack(this.cache.documentByItemData[docID]);
      }
      this.httpService.get(
        environment.APIUrl + APIURLS['getDocumentByItem'] + docID,
        response => {
          this.cache.documentByItemData = response;
          callBack(response);
        },
        error => { }
      );
    }
  }

  getDocumentItembyTOC(docID, callBack) {
    if (docID) {
      if (this.cache.tocData[docID]) {
        return callBack(this.cache.tocData[docID]);
      }
      this.httpService.get(
        environment.APIUrl + APIURLS['getDocumentItembyTOC'] + docID,
        response => {
          this.cache.tocData[docID] = response;
          callBack(response);
        },
        error => { }
      );
    }
  }

  getImageURLs(docID, images: Array<any>, callBack) {
    const imageURLs = [];
    const urlObservable = new Observable(observer => {
      images.forEach((img, index) => {
        const overlays: Array<Overlay> = [];
        if (Array.isArray(img.stringOrSPDetails)) {
          img.stringOrSPDetails.forEach(obj => {
            overlays.push(
              new Overlay(
                obj.width,
                obj.height,
                obj.hpos,
                obj.vpos
              )
            );
          });
        }

        const pageNumber = (img.id.split('-')[3]) / 10;
        imageURLs.push(
          new ImageSource(
            img.id,
            environment.APIUrl + APIURLS['fileUrlThumb'] + docID + '/' + img.id,
            environment.APIUrl + APIURLS['fileUrlImage'] + docID + '/' + img.id,
            overlays,
            false,
            img.itemTitle,
            pageNumber
          )
        );
        if (index === images.length - 1) {
          observer.complete();
        }
      });
    });
    if (typeof callBack === 'function') {
      urlObservable.pipe(takeUntil(this.stop$)).subscribe(
        nextValue => { },
        error => {
          callBack(null);
        },
        () => {
          callBack(imageURLs);
        }
      );
    }
  }

  getRelevantPages(pageID, searchTerm, callBack, fuzzy: any = false, docId?: any) {
    if (pageID) {
      const params = {
        searchTerm: searchTerm,
        filterParams: [
          {
            fieldName: 'pageId',
            fieldValues: [pageID]
          }
        ],
        fuzzy: fuzzy
      };
      if (docId) {
        params.filterParams.push({
          fieldName: 'documentId',
          fieldValues: [docId]
        });
      }
      this.httpService.post(environment.APIUrl + APIURLS['getRelevantPages'] + '?docId=' + docId, params, response => {
        callBack(response);
      },
        (err) => {
          callBack(null);
        }, 'json', pageID);
    }
  }

  getDocumentFullText(docID, callBack) {
    if (docID) {
      if (this.cache.fulltextData[docID]) {
        return callBack(this.cache.fulltextData[docID]);
      }
      this.httpService.get(
        environment.APIUrl + APIURLS['getDocumentFullText'] + docID,
        response => {
          this.cache.fulltextData[docID] = response;
          callBack(response);
        },
        error => { }, '', false, docID);
    }
  }

  getIllustraion(docID, callBack, errorCallback?) {
    if (docID) {
      if (this.cache.illustrationsData[docID]) {
        return callBack(this.cache.illustrationsData[docID]);
      }
      this.httpService.get(environment.APIUrl + APIURLS['getIllustrations'] + docID, response => {
        const keyAry = Object.keys(response);
        keyAry.forEach(res => {
          response[res].forEach(obj => {
            if (obj.caption === 'Untitled') {
              obj.caption = 'Untitled Illustration';
            }
          });
        });
        this.cache.illustrationsData[docID] = response;
        callBack(this.cache.illustrationsData[docID]);
      }, err => {
        // errorCallback(err);
      });
    }
  }

  bookmarkPage(title) {
    const currentUrl = window.location.href;
    const requestParams = { url: currentUrl };
    this.httpService.post(
      environment.APIUrl,
      requestParams,
      response => { },
      error => { }
    );
  }
  downloadFullText(docID, callBack) {
    if (docID) {
      this.httpService.get(environment.APIUrl + APIURLS['downloadFullText'] + docID, response => {
        callBack(response);
      }, error => {
      }, 'text', false, docID);
    }
  }
  saveAsTextFile(docID, pageId, successcallBack, errorCallback) {
    if (docID && pageId) {
      this.httpService.get(environment.APIUrl + APIURLS['saveAsTextFile'] + docID + '/' + pageId + '?WDALoc=' +
        localStorage.getItem('currentLocation') || environment.defaultLocation, response => {
          successcallBack(response);
        }, error => {
          errorCallback(error);
        }, 'text');
    }
  }
  translateEntireDoc(docID, pageId, lang, successcallBack, errorCallback) {
    if (lang) {
      // this.popupService.showLoader();
      this.httpService.get(environment.APIUrl + APIURLS['translateEntireDoc'] + docID + '?destLanguage=' + lang, response => {
        successcallBack(response);
        // setTimeout(() => {
        //   this.popupService.hideLoader();
        // }, 0);
      }, error => {
        // tslint:disable-next-line: max-line-length
        this.popupService.showNotification('ERROR', this.translatedValues.Error || 'Error', this.translatedValues.notTranslateEntireDoc || 'Could not translate entire document.');
        // this.popupService.hideLoader();
      }, 'text');
    }
  }
  translateDoc(docID, pageId, lang, successcallBack, errorCallback) {
    if (docID && pageId && lang) {
      // this.popupService.showLoader();
      this.httpService.get(environment.APIUrl + APIURLS['fileTranslateText'] + '/' + docID + '/'
        + pageId + '?destLanguage=' + lang + '&WDALoc=' +
        localStorage.getItem('currentLocation') || environment.defaultLocation, response => {
          successcallBack(response);
          setTimeout(() => {
            //     this.popupService.hideLoader();
          }, 0);
        }, error => {
          // tslint:disable-next-line: max-line-length
          this.popupService.showNotification('ERROR', this.translatedValues.Error || 'Error', this.translatedValues.notTranslateThisPage || 'Could not translate this page.');
          // this.popupService.hideLoader();
        }, 'json');
    }
  }

  getIllustrationTableDownload(docID, tableId, access_token, successcallBack, errorCallback) {
    this.httpService.get(environment.APIUrl + 'file/table/' + docID + '/' + tableId + '?access_token=' +
      access_token, response => {
        successcallBack(response);
        // setTimeout(() => {
        //   this.popupService.hideLoader();
        // }, 0);
      }, (err) => {
        errorCallback(err);
      }, 'blob');
  }

  goToSearchPageFromTextAnalysis(searchTerm: string, year: any) {
    const data = {
      searchTerm: searchTerm ? searchTerm : '',
      publishedDateFrom: year
    };
    if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      data['selectedArchives'] =
        PageSettingsService.pageSettings.currentSelectedArchives;
    } else {
      data['selectedArchives'] = [
        PageSettingsService.pageSettings.currentArchive
      ];
    }
    let URL = '/';
    URL += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
    // URL += '/location/';
    // URL += PageSettingsService.pageSettings.currentLocation;
    URL += '/search';
    this.estimateSimpleSearchCount(searchTerm, count => {
      if (count > 0) {
        this.router.navigate([URL, data], {});
        const objDate = Date.now();
        if (JSON.parse(localStorage.getItem('HistoryList')).length > 0) {
          this.previousHistoryObject = JSON.parse(
            localStorage.getItem('HistoryList')
          );
          const historyItem = {
            url: URL,
            searchParams: searchTerm,
            date: objDate
          };
          this.previousHistoryObject.push(historyItem);
          localStorage.setItem(
            'HistoryList',
            JSON.stringify(this.previousHistoryObject)
          );
        } else {
          const historyItem = {
            url: URL,
            searchParams: searchTerm,
            date: objDate
          };
          this.historyObject.push(historyItem);
          localStorage.setItem(
            'HistoryList',
            JSON.stringify(this.historyObject)
          );
        }
      } else {
        this.popupService.showNotification('WARNING', this.translatedValues.searchResults, this.translatedValues.searchDidNotReturnResult);
        if ($('#searchResultDiv')) {
          $('#searchResultDiv').hide();
        }
        if ($('app-browser-map')) {
          $('app-browser-map').hide();
        }
        $('.modal-footer button.btn').trigger('focus');
      }
    });
  }
  goToAdvancedSearchPageFromTextAnalysis(searchTerm: string, params?: any, isFromSearchResults?: any) {
    this.searchHistory = {
      searchTerm: searchTerm ? searchTerm : ''
    };
    this.URL = '/';
    this.URL += (PageSettingsService.pageSettings.currentArchive + ''
    ).toLowerCase();
    // this.URL += '/location/';
    // this.URL += PageSettingsService.pageSettings.currentLocation;
    this.URL += '/search';
    this.buildFilterParams(params, urlData => {
      urlData['fromTATTools'] = true;
      if (isFromSearchResults) {
        urlData['fromSearchResult'] = true;
      }
      // const filterparams = urlData.filterParams;
      // filterparams[filterparams.length - 1].fieldValues = params.archive;
      this.buildURL(urlData, filterData => {
        if (filterData) {
          this.searchHistory['filter'] = filterData;
        }
        this.searchHistory['fromTATTools'] = true;
        this.estimateResultCount(params, count => {
          if (count > 0) {
            setTimeout(() => {
              const urlForHistory = window.location.pathname;
              const objDate = Date.now();
              const historyAdvancedFilterParam = PageSettingsService.pageSettings.advancedFilterParam;
              const historyItem = {
                url: urlForHistory,
                filterParams: historyAdvancedFilterParam,
                searchParams: searchTerm || '',
                date: objDate,
                fromTextAnalysis: true
              };
              const previousHistoryObject = JSON.parse(localStorage.getItem('HistoryList'));
              if (previousHistoryObject.length > 0) {
                previousHistoryObject.push(historyItem);
                localStorage.setItem('HistoryList', JSON.stringify(previousHistoryObject));
              } else {
                const historyObject = [historyItem];
                localStorage.setItem('HistoryList', JSON.stringify(historyObject));
              }
            });
            this.router.navigate([this.URL, this.searchHistory]);
          } else {
            this.popupService.showNotification('WARNING', this.translatedValues.searchResults,
              this.translatedValues.searchDidNotReturnResult);
            if ($('#searchResultDiv')) {
              $('#searchResultDiv').hide();
            }
            if ($('app-browser-map')) {
              $('app-browser-map').hide();
            }
            // this.popupService.hideLoader();
          }
        }, true, searchTerm);
      });
    });
  }


  getFoamClusters(params, callBack, errorCallback) {
    // this.popupService.showLoader();
    const serviceUrl = environment.APIUrl + APIURLS['getFoamClusters'];
    params.filterParams.forEach(obj => {
      if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
        obj.condition = 'All';
        obj.fieldValue = '';
      }
    });
    if (this.clusterCache$[JSON.stringify(params)]) {
      callBack(this.clusterCache$[JSON.stringify(params)]);
      return;
    }
    this.httpService.post(
      serviceUrl, params, response => {
        callBack(response);
        this.clusterCache$[JSON.stringify(params)] = response;
        // this.popupService.hideLoader();
      },
      error => {
        errorCallback(null);
        // this.popupService.hideLoader();
      },
      'json'
    );
  }


  getClusterDetails(params, callBack, errorCallback) { 
    
    // this.popupService.showLoader();
    params.filterParams.forEach(obj => {
      if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
        obj.condition = 'All';
        obj.fieldValue = '';
      }
    });
    params = this.updateSearchParam(params);
    if (typeof params === 'string') {
      params = JSON.parse(params);
    }
    for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
      if (params.filterParams[k].fieldName === "archive"){
        const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
        this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
        if (!this.includeWPA) {
          const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
          if (havewpa) {
            if (typeof params.filterParams[k].fieldValues  === 'string') {
              params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
             }
             let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
             let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
              if(!havewpaInSubs){
                params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
              }
          }
        }
        this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
        if (!this.includeWPA1) {
          const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
          if (havewpa) {
            if (typeof params.filterParams[k].fieldValues  === 'string') {
              params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
             }
             let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
             let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
              if(!havewpaInSubs){
                params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
              }
          }
        }
      }
    }
    this.httpService.post(environment.APIUrl + APIURLS['advancedSearch'], params, response => {
      if (response.status === 'SUCCESS') {
        const result = SearchService.resultObservable.value;
        const newResult = response.results.groupResults[0].groupEntry.groupEntryContent;
        SearchService.resultObservable.next(newResult);
        SearchService.facetObservable.next(response.results.facetResults);
        if (Array.isArray(newResult) && newResult.length === 0) {
          SearchService.hasResultsObservable.next({
            hasResult: false,
            count: 0
          });
        } else if (Array.isArray(newResult) && newResult.length > 0) {
          SearchService.hasResultsObservable.next({
            hasResult: true,
            count: response.totalRecords
          });
        }
        if (typeof callBack === 'function') {
          callBack({
            facets: response.results.facetResults,
            groups:
              response.results.groupResults[0].groupEntry.groupEntryContent,
            count: response.totalRecords
          });
        }
        setTimeout(() => {
          // this.popupService.hideLoader();
        }, 1000);
      } else if (response.status === 'FAILURE') {
        this.popupService.showNotification(response.results[0].type, '', response.results[0].message);
      }
    }, error => {
      errorCallback(null);
    }, 'json');
  }
  deleteDocHistory() {
    localStorage.removeItem('detailsHistory');
  }

  spatialSearch(spatialSearchParams) {
    this.sharedService.isResetScrollSpyDisable(null);
    let params: any = [];
    if (spatialSearchParams.type === 'circle') {
      params = {
        'spatialFilterParams': [{
          'fieldName': 'latlon',
          'shape': spatialSearchParams.type,
          'radius': (spatialSearchParams.radius / 1852),
          'location': {
            'lat': spatialSearchParams.latlng.lat,
            'lon': spatialSearchParams.latlng.lng
          }
        }
        ]
      };
    } else if (spatialSearchParams.type === 'rectangle') {
      params = {
        'spatialFilterParams': [{
          'fieldName': 'latlon',
          'shape': spatialSearchParams.type,
          'lowerLeft': {
            'lat': spatialSearchParams.southWest.lat,
            'lon': spatialSearchParams.southWest.lng,
          },
          'topRight': {                    // Rectangle top right latitude and longitude
            'lat': spatialSearchParams.northEast.lat,
            'lon': spatialSearchParams.northEast.lng
          }
        }
        ]
      };
    }

    this.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, param => {
      this.advancedSearchFilterParams = param;
    });
    const requestParams = Object.assign({}, this.advancedSearchFilterParams, params);
    // this.popupService.showLoader();
    SearchService.hasResultsObservable.next({ hasResult: true, count: 0 });
    this.sharedService.mapRequestParams(requestParams);
    requestParams.filterParams.forEach(obj => {
      if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
        obj.condition = 'All';
        obj.fieldValue = '';
      }
    });
    params = this.updateSearchParam(params);
    if (typeof params === 'string') {
      params = JSON.parse(params);
    }
    for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
      if (params.filterParams[k].fieldName === "archive"){
        const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
        this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
        if (!this.includeWPA) {
          const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
          if (havewpa) {
            if (typeof params.filterParams[k].fieldValues  === 'string') {
              params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
             }
             let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
             let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
              if(!havewpaInSubs){
                params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
              }
          }
        }
        this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
        if (!this.includeWPA1) {
          const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
          if (havewpa) {
            if (typeof params.filterParams[k].fieldValues  === 'string') {
              params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
             }
             let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
             let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
              if(!havewpaInSubs){
                params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
              }
          }
        }
      }
    }
    this.httpService.post(environment.APIUrl + APIURLS['advancedSearch'], requestParams, response => {
      if (response.status === 'SUCCESS') {
        const newResult = response.results.groupResults[0].groupEntry.groupEntryContent;
        SearchService.resultObservable.next(newResult);
        SearchService.facetObservable.next(response.results.facetResults);
        SearchService.hasResultsObservable.next({ hasResult: true, count: response.totalRecords });
        // this.popupService.hideLoader();
      } else {
        this.popupService.showNotification('ERROR', 'Sorry', this.translatedValues.failureOccured);
      }
    },
      error => {
        // this.popupService.hideLoader();
      }
      
    );
    let facetResult: any = null;
    delete requestParams['groupLimit'];
    delete requestParams['groupOffset'];
    this.httpService.post(environment.APIUrl + APIURLS['spatialSearch'], requestParams, response => {
      if (response.status === 'SUCCESS') {
        facetResult = response;
        SearchService.spatialSearchFacetObservable.next(response);
      } else {
        this.popupService.showNotification('ERROR', this.translatedValues.sorry || 'Sorry', this.translatedValues.failureOccured);
      }
    }, error => {
    }, 'json');

  }

  getMetaDatasFromKeyword(docID, searchTerm, callBack, fuzzy: Boolean = true) {
    const params = {
      searchTerm: searchTerm,
      filterParams: [
        {
          fieldName: 'id',
          fieldValues: [docID]
        }
      ],
      fuzzy: fuzzy
    };
    this.httpService.post(environment.APIUrl + APIURLS['getMetaDatasFromKeyword'] + '?docId=' + docID, params, response => {
      callBack(response);
    },
      error => {
        callBack(null);
      }, 'json', docID);
  }

  getDocumentImagesBydocId(docID, successcallBack, errorCallback) {
    this.httpService.get(environment.APIUrl + APIURLS['getDocumentImagesById'] + '/' + docID, response => {
      successcallBack(response);
    }, error => {
      errorCallback(error);
    }, 'json');
  }

  getPageFulltextFromKeyword(docID, searchTerm, callBack, fuzzy: Boolean = true) {
    const params = {
      searchTerm: searchTerm,
      filterParams: [
        {
          fieldName: 'documentId',
          fieldValues: [docID]
        }
      ],
      fuzzy: fuzzy
    };
    this.httpService.post(environment.APIUrl + APIURLS['getPageLevelFullText'] + '?docId=' + docID, params, response => {
      callBack(response);
    },
      error => {
        callBack(null);
      }, 'json', docID);
  }

  getIllustrationCoordinates(docId, pageId, calledFrom?: String) {
    this.httpService.get(environment.APIUrl + APIURLS['getIllustrationsbypage'] + docId + '/' + pageId, res => {
      if (res) {
        const illustrations = Object.keys(res).map(key => ({ type: key, value: res[key] }));
        this.illustrationsOverlays = [];
        illustrations.forEach(coordinateValues => {
          coordinateValues.value.forEach(overlayValue => {
            if (calledFrom === 'Filmstrip') {
              if (overlayValue.type === 'table') {
                this.illustrationsOverlays.push(new Overlay(overlayValue.width, overlayValue.height, overlayValue.hpos,
                  overlayValue.vpos, overlayValue.type, overlayValue.tableId));
              }
            } else {
              this.illustrationsOverlays.push(new Overlay(overlayValue.width, overlayValue.height, overlayValue.hpos,
                overlayValue.vpos, overlayValue.type, overlayValue.tableId));
            }
          });
        });
        this.sharedService.shareIllustrationOverlays(this.illustrationsOverlays);
        this.illustrationsOverlays = [];
      }
    }, (error) => {
    });
  }


  getIllustrationCoordinatesV2(docId, pageId, calledFrom: String, successCallBack, errorCallback) {
    this.httpService.get(environment.APIUrl + APIURLS['getIllustrationsbypage'] + docId + '/' + pageId, res => {
      if (res) {
        const illustrations = Object.keys(res).map(key => ({ type: key, value: res[key] }));
        this.illustrationsOverlays = [];
        illustrations.forEach(coordinateValues => {
          coordinateValues.value.forEach(overlayValue => {
            if (calledFrom === 'Filmstrip') {
              if (overlayValue.type === 'table') {
                this.illustrationsOverlays.push(new Overlay(overlayValue.width, overlayValue.height, overlayValue.hpos,
                  overlayValue.vpos, overlayValue.type, overlayValue.tableId));
              }
            } else {
              this.illustrationsOverlays.push(new Overlay(overlayValue.width, overlayValue.height, overlayValue.hpos,
                overlayValue.vpos, overlayValue.type, overlayValue.tableId));
            }
          });
        });
        successCallBack(this.illustrationsOverlays);
        this.illustrationsOverlays = [];
      }
    }, (error) => {
      errorCallback(error);
    });
  }

  getLocationsConfig(successCallback, errorCallback) {
    this.httpService.get(APIURLS.config, response => {
      successCallback(response);
    },
      err => {
        errorCallback(err);
      }
    );
  }

  getAnalysisDetailsForSimpleSearch(searchKey, callBack) {
    this.httpService.post(
      environment.APIUrl + APIURLS['getAnalysis'],
      {
        terms: searchKey,
        filterParams: [
          {
            fieldName: 'archive',
            fieldValues:
              PageSettingsService.pageSettings.currentArchive === 'WILEY'
                ? PageSettingsService.pageSettings.currentSelectedArchives
                : [PageSettingsService.pageSettings.currentArchive],
            operator: 'OR'
          }
        ]
      },
      response => {
        callBack(response);
      },
      error => { }
    );
  }
  clearCachedData() {
    this.cache = {
      illustrationsData: {},
      fulltextData: {},
      tocData: {},
      documentByItemData: {},
      documentItembyType: {},
      documentDetails: {},
    };
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    delete this.cache;
    delete this.translatedValues;
    delete this.advancedSearchFilterParams;
    delete this.pageFlage;
    delete this.termId;
    delete this.config;
    delete this.modalRef;
    delete this.loadMapPos;
    delete this.illustrationsOverlays;
    this.stop$.next();
    this.stop$.complete();
  }

  splitTerms(searchKey) {
    if (searchKey) {
      if (searchKey.includes('“')) {
        searchKey = searchKey.replace(/“/g, '"');
      }
      if (searchKey.includes('”')) {
        searchKey = searchKey.replace(/”/g, '"');
      }
    }
    this.splittedSearchTerms = [];
    const searchTerms: Array<String> = [];
    if (searchKey) {
      searchKey = searchKey.match(/[^\s"]+|"([^"])+"~[0-9]{1,5}|"([^"])+"/g);
      searchKey.forEach(key => {
        if (this.checkForDocumentIdPrefix(key.toUpperCase())) {
          if (this.splittedSearchTerms.indexOf(key) <= -1) {
            if (/^".*"$/.test(key)) {
              this.sanitizeAndPushTerms(key);
            } else {
              this.sanitizeAndPushTerms('"' + key.toUpperCase() + '"');
            }
          }
        } else {
          if ((/^".*"$/.test(key)) || (/^".*"~[0-9]{1,5}$/.test(key))) {
            this.sanitizeAndPushTerms(key);
          } else {
            const searchTermwithSpaces = (/\s/.test(key));
            if (searchTermwithSpaces) {
              const withHyphen = key.split(' ');
              withHyphen.forEach(seperatedHypen => {
                this.sanitizeAndPushTerms(seperatedHypen.replace(/[^a-zA-Z0-9 ?!*()-]/g, ''));
              });
            } else {
              this.sanitizeAndPushTerms(key.replace(/[^a-zA-Z0-9 ?!*()-]/g, ''));
            }
          }
        }
      });
      return this.splittedSearchTerms;
    }
  }

  sanitizeAndPushTerms(term) {
    if (term !== 'AND' && term !== 'OR' && term !== 'NOT') {
      if (term === 'and' || term === 'aNd' || term === 'And' || term === 'anD') {
        term = 'AND';
      } else if (term === 'or' || term === 'Or' || term === 'oR') {
        term = 'OR';
      } else if (term === 'not' || term === 'Not' || term === 'nOt' || term === 'noT') {
        term = 'NOT';
      } else {
        if (stopWords.indexOf(term.toLowerCase()) > -1) {
          term = '';
        }
      }
    }

    if (term !== '') {
      if (term.includes('"') || (term === 'AND' || term === 'OR' || term === 'NOT')) {
        if (this.splittedSearchTerms.indexOf(term.toLowerCase()) !== -1) {
        } else {
          this.splittedSearchTerms.push(term);
        }
      } else {
        if (this.splittedSearchTerms.indexOf(term.toLowerCase()) !== -1) {
        } else {
          this.splittedSearchTerms.push(term.toLowerCase());
        }
      }
    }
  }

  checkForDocumentIdPrefix(term): Boolean {
    let containsDocumentIdPrefix: Boolean = false;
    if (!this.documentIDReference) {
      this.documentIDReference = JSON.parse(localStorage.getItem('documentIDReference'));
    }
    if (this.documentIDReference) {
      this.documentIDReference.forEach(docIdPrefix => {
        if (term.includes(docIdPrefix)) {
          if (term.match(new RegExp('-', 'g')) || [].length === 2) {
            containsDocumentIdPrefix = true;
          }
        }
      });
    }
    if (containsDocumentIdPrefix) {
      return true;
    }
  }


  booleanSearch(splittedTerms) {
    splittedTerms.forEach((op, i) => {
      if ((splittedTerms[i] === 'AND') || (splittedTerms[i] === 'OR') || (splittedTerms[i] === 'NOT')) {
        this.booleanCount = this.booleanCount + 1;
        if (this.booleanCount > 1) {
          splittedTerms[i] = '';
          splittedTerms[i - 1] = '';
        }
        if ((splittedTerms[0] === 'AND') || (splittedTerms[0] === 'OR')) {
          splittedTerms[0] = '';
        }
      } else {
        this.booleanCount = 0;
      }
      const searchTermLength = splittedTerms.length;
      if ((splittedTerms[searchTermLength - 1] === 'AND') || (splittedTerms[searchTermLength - 1] === 'OR') ||
        ((splittedTerms[searchTermLength - 1] === 'NOT'))) {
        splittedTerms.pop();
      }
    });
  }

  // goToAdvancedSearchPageFromFacetArchive
  goToAdvancedSearchPageFromFacetArchive(searchTerm: string, params?: any) {
    var archiveURL;
    if(params.archive.length > 0) {
      var personalizedData = localStorage.getItem('personalizationData');
      if (typeof personalizedData === 'string') {
        personalizedData = JSON.parse(personalizedData);
      }
      if (params.archive[0] === 'RCP1') {
        if (personalizedData['bothRCPAvailable']) {
          archiveURL = 'RCP';
        } else {
          var getWileySelectedArchives = localStorage.getItem('wileySelectedArchive');
          const havercp1 = getWileySelectedArchives.includes('RCP1');
          if (havercp1) {
            archiveURL  = 'RCP1';
          } else {
            archiveURL  = 'RCP2';
          }
        }
      } else if (params.archive[0] === 'RGS1') {
        if (personalizedData['bothRGSAvailable']) {
          archiveURL = 'RGS';
        } else {
          var getWileySelectedArchives = localStorage.getItem('wileySelectedArchive');
          const havercp1 = getWileySelectedArchives.includes('RGS1');
          if (havercp1) {
            archiveURL  = 'RGS1';
          } else {
            archiveURL  = 'RGS2';
          }
        }
      } else {
        archiveURL = params.archive[0];
      }
    }
    this.searchHistory = {
      searchTerm: searchTerm ? searchTerm : ''
    };
    this.URL = '/';
    this.URL += (archiveURL).toLowerCase();
    this.URL += '/search';
    this.buildFilterParams(params, urlData => {
      const filterparams = urlData.filterParams;
      this.buildURL(urlData, filterData => {
        if (filterData) {
          this.searchHistory['filter'] = filterData;
        }
        this.estimateResultCount(params, count => {
          const startDate = new Date(params.publishedDateFrom);
          const endDate = new Date(params.publishedDateTo);
          if (count > 0) {
            setTimeout(() => {
              const urlForHistory = window.location.pathname;
              const objDate = Date.now();
              const historyAdvancedFilterParam =
                PageSettingsService.pageSettings.advancedFilterParam;
              if (
                JSON.parse(localStorage.getItem('HistoryList')).length > 0
              ) {
                this.previousHistoryObject = JSON.parse(
                  localStorage.getItem('HistoryList')
                );
                const historyItem = {
                  url: urlForHistory,
                  filterParams: historyAdvancedFilterParam,
                  date: objDate,
                  searchParams: searchTerm ? searchTerm : ''
                };
                this.previousHistoryObject.push(historyItem);
                localStorage.setItem(
                  'HistoryList',
                  JSON.stringify(this.previousHistoryObject)
                );
              } else {
                const historyItem = {
                  url: urlForHistory,
                  filterParams: historyAdvancedFilterParam,
                  date: objDate,
                  searchParams: searchTerm ? searchTerm : ''
                };
                this.historyObject.push(historyItem);
                localStorage.setItem(
                  'HistoryList',
                  JSON.stringify(this.historyObject)
                );
              }
            });
            this.router.navigate([this.URL, this.searchHistory]);
            // this.popupService.hideLoader();
          } else if (startDate > endDate) {
            this.popupService.showNotification('WARNING', this.translatedValues.searchResults, this.translatedValues.endDateValidation);
            // this.popupService.hideLoader();
          } else {
            this.popupService.showNotification('WARNING', this.translatedValues.searchResults,
              this.translatedValues.searchDidNotReturnResult);
            if ($('#searchResultDiv')) {
              $('#searchResultDiv').hide();
            }
            if ($('app-browser-map')) {
              $('app-browser-map').hide();
            }
            // this.popupService.hideLoader();
          }
        });
      });
    });
  }

  getdocument(order?){
    var parseItem;
    if(order) {
      if (typeof this.downloadContentFilterParam == 'string') {
        parseItem = JSON.parse(this.downloadContentFilterParam);
      } else {
        parseItem = this.downloadContentFilterParam;
      }
     if (order === 'relevance') {
       parseItem.sortFields = {};
     } else {
       parseItem.sortFields = {
           pubStartDate: order
         };
     }
     this.downloadContentFilterParam = JSON.stringify(parseItem); 
    }
   let params = this.updateSearchParam(this.downloadContentFilterParam); 
   if (typeof params === 'string') {
    params = JSON.parse(params);
  } 
   for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
    if (params.filterParams[k].fieldName === "archive"){
      const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
      this.includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
      if (!this.includeWPA) {
        const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
        if (havewpa) {
          if (typeof params.filterParams[k].fieldValues  === 'string') {
            params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
           }
           let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
           let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
            if(!havewpaInSubs){
              params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
            }
        }
      }
      this.includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
      if (!this.includeWPA1) {
        const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
        if (havewpa) {
          if (typeof params.filterParams[k].fieldValues  === 'string') {
            params.filterParams[k].fieldValues = JSON.parse(params.filterParams[k].fieldValues);
           }
           let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
           let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
            if(!havewpaInSubs){
              params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
            }
        }
      }
    }
  }
    this.httpService.post(
      environment.APIUrl + APIURLS['advancedSearch'],
      params,
      response => {
        
        response.results.groupResults[0].groupEntry.groupEntryContent.forEach((data:any)=>{
          data.documents.forEach((docs:any)=>{
            this.documentList.push({id:docs.id ,title:docs.title})
            SearchService.documentObservable.next(this.documentList)
            SearchService.documentUrlObservable.subscribe((data:any)=>{
              if(data){
                const val = JSON.parse(localStorage.getItem('filter'));
                  this.buildURL(val, filterData => {
                    if (filterData) {
                      this.searchHistory['filter'] = filterData;
                    }
                    this.router.navigate(['/wiley/downloadcontenttable', this.searchHistory]);
                  });
                // this.router.navigate(['/wiley/downloadcontenttable']);
              }               
            })   
          })
          
        })
        // this.popupService.hideLoader();
        const result = SearchService.resultObservable.value;
        const newResult =
          response.results.groupResults[0].groupEntry.groupEntryContent;
        SearchService.resultObservable.next(newResult);
        SearchService.facetObservable.next(response.results.facetResults);
        if (Array.isArray(newResult) && newResult.length === 0) {
          SearchService.hasResultsObservable.next({
            hasResult: false,
            count: 0
          });
        } else if (Array.isArray(newResult) && newResult.length > 0) {
          SearchService.hasResultsObservable.next({
            hasResult: true,
            count: response.totalRecords
          });
        }
      
        setTimeout(() => {
          this.sharedService.setSearchLoading(false);
          // this.popupService.hideLoader();
        }, 1000);
      },
      error => {      
        // this.popupService.hideLoader();
        this.sharedService.setSearchLoading(false);
        SearchService.resultObservable.next([]);
        SearchService.facetObservable.next([]);
      }
    );
  }

  // downloadeDocument(downloadIds){     
  //   this.httpService.post(
  //     environment.APIUrl + APIURLS['downloadDocument'],
  //     downloadIds,
  //     response => {
  //       if (response) {
  //         this.getarrayofdocsID = response;
  //        console.log(response);
  //     }},
  //     error => {}
  //   );
  // }

    /**
     * @description: get search results for given term and limiters from cluster.
     * @param params : Advanced params
     * @returns :post request of document.search
     */
    downloadeDocument(downloadIds): Observable<any> { 
      
      // return this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['downloadDocument']}`, downloadIds);
      return this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['downloadDocument']}`, downloadIds);
 
    }
}


