/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit } from '@angular/core';

import { HttpService } from '../../../common/services/http.service';
import { CommonService } from '../../../common/services/common.service';

declare let $: any;
@Component({
  selector: 'known-issues',
  templateUrl: './known-issues.component.html',
  styleUrls: ['./known-issues.component.css']
})
export class KnownIssuesComponent implements AfterViewInit {
  knownIssuesList: any;
  knownIssues: any;

  constructor(
    private httpService: HttpService,
    private commonService: CommonService
  ) {
    // this.httpService.get('../assets/config/locations-config.json', (response) => {
    this.commonService.getLocationConfig(sessionStorage.getItem('lang')).subscribe(response => {
      this.knownIssuesList = response.knownIssues;
      // this.knownIssuesList.forEach(el => {
      //   this.knownIssues = el;

      // });
    }, err => { });

  }

  ngAfterViewInit() {

  }
}
