<div class="row">
  <!-- <template #detailsDynamicContainer></template> -->



  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="tab-wrapper">
      <tabset #explorerTabs>
        <tab [customClass]="'docviewer-tabs'" heading="{{'explorer.photo' | translate}}">
        </tab>
        <tab [customClass]="'docviewer-tabs'" heading="{{'explorer.map' | translate}}" (selectTab)="navigateToMap()">
        </tab>
        <tab [customClass]="'docviewer-tabs tabtext'" [disabled]="true" heading="{{'explorer.explorerphotosmaps' | translate}}">
        </tab>
      </tabset>
    </div>
    <div class="page_wrap">
      <h4 class="photo_title">{{'explorer.photosPageTitle' | translate}}</h4>
      <form name="simpleSearchForm">
        <div class="input-group photo-view-limiter-btn" id="photo_explorer">
          <label class="sr-only" for="search_textbox">{{'Common.Placeholder.searchPhotos' | translate}}</label>
          <div class="term-searchResult" id="queries" *ngIf="copyAdvancedFilterParam?.archive?.length > 0 || (copyAdvancedFilterParam?.publishedDateIncludesNull === true  && copyAdvancedFilterParam?.publishedDateFrom|| copyAdvancedFilterParam?.publishedDatePrefix)||
              copyAdvancedFilterParam?.languages.length > 0 || copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0 ||
              copyAdvancedFilterParam?.subject.length > 0" (click)="$event.stopPropagation()">
            <ul class="result-tag" *ngIf="copyAdvancedFilterParam?.archive?.length > 0 || (copyAdvancedFilterParam?.publishedDateIncludesNull === true  && copyAdvancedFilterParam?.publishedDateFrom|| copyAdvancedFilterParam?.publishedDatePrefix)|| copyAdvancedFilterParam?.languages.length > 0 ||
            copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0 || copyAdvancedFilterParam?.subject.length > 0">
              <ul class="advSearch searchQry" *ngIf="copyAdvancedFilterParam?.archive?.length > 0 || copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDateIncludesNull || copyAdvancedFilterParam?.publishedDatePrefix|| copyAdvancedFilterParam?.contentType.length > 0 || copyAdvancedFilterParam?.docSubType.length > 0 ||
               copyAdvancedFilterParam?.publishedDateFrom  != ''|| copyAdvancedFilterParam?.languages.length > 0 || copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0  || copyAdvancedFilterParam?.subject.length > 0">
                <label (click)="toggleLimiters($event)" *ngIf="copyAdvancedFilterParam?.archive?.length > 0 || copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDateIncludesNull || copyAdvancedFilterParam?.publishedDatePrefix|| copyAdvancedFilterParam?.languages.length > 0 ||
                copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0 || copyAdvancedFilterParam?.subject.length > 0"
                  class="searchBy searchLimiterLabel">{{ 'SearchResult.ViewLimiters' | translate }}
                  <i class="fa fa-caret-down limior-down-arrow" *ngIf="!showLimiters"></i>
                  <i class="fa fa-caret-up limior-down-arrow" *ngIf="showLimiters"></i>
                </label>
                <ul class="dropdown-menu customDropdownCls" [style.display]="showLimiters ? 'block' : 'none'">
                  <li class="historyBatch archivesList padding-left-0" *ngIf="copyAdvancedFilterParam?.archive?.length > 0" [class.archive-singleLimit]="copyAdvancedFilterParam?.archive?.length < 2">
                    <label class="search-resultlbl">{{ 'SearchResult.archive' | translate }} </label>
                    <span *ngIf="enableWiley" class="badge" aria-hidden="true" [outsideClick]="true" [popover]="sourcepopOverTemplate" [placement]="'bottom'">
                      <ng-container *ngFor="let archiveObj of limitArchivrDropdown;let i = index">
                        <ng-container *ngIf="i== 0">
                          <div class="archives-select-qry pull-left" title="{{archiveObj?.name}}">{{archiveObj.name}}</div>
                          <div *ngIf="limitArchivrDropdown?.length > 1" class="archives-search-resultcount  search-resultcount pull-left">+{{(limitArchivrDropdown?.length-1)}}
                            <i class="fa fa-caret-down"></i>
                          </div>
                        </ng-container>
                      </ng-container>
                    </span>
                    <span *ngIf="!enableWiley" class="badge">
                      <ng-container *ngFor="let archiveObj of limitArchivrDropdown;let i = index">
                          <div class="archives-select-qry pull-left" title="{{archiveObj?.name}}">{{archiveObj.name}}</div>
                      </ng-container>
                    </span>
                    <!-- <label *ngIf="copyAdvancedFilterParam?.archive?.length < 2">
                      <ng-container *ngFor="let archive of copyAdvancedFilterParam?.archive">
                        <span *ngFor="let archiveObj of pageContent?.wileyArchives; let i = index" class="margin-left5">
                          <ng-container *ngIf="archive === archiveObj?.shortName;">
                            <div class="badge select-qry pull-left singlelimit-select-qry" style="padding: 6px" [attr.title]="archiveObj?.name">
                              <div class="searchqryAdv pull-left singlelimit-searchqryAdv">
                                {{archiveObj?.name}}
                              </div>
                            </div>
                          </ng-container>
                        </span>
                      </ng-container>
                    </label>
                    <span *ngIf="copyAdvancedFilterParam?.archive?.length > 1" class="badge" aria-hidden="true" [outsideClick]="true" [popover]="sourcepopOverTemplate"
                      [placement]="'bottom'">
                      <ng-container *ngFor="let archiveObj of pageContent?.wileyArchives;">
                        <ng-container *ngIf="archiveObj?.shortName === copyAdvancedFilterParam?.archive[0]">
                          <div class="archives-select-qry pull-left" title="{{archiveObj?.name}}">{{archiveObj?.name}}</div>
                          <div class="archives-search-resultcount  search-resultcount  pull-left">+{{(copyAdvancedFilterParam?.archive?.length-1)}}
                            <i class="fa fa-caret-down"></i>
                          </div>
                        </ng-container>
                      </ng-container>
                    </span> -->
                    <ng-template #sourcepopOverTemplate>
                      <!-- <ul class="popoverUl" *ngFor="let archive of copyAdvancedFilterParam?.archive; let i = index">
                        <ng-container *ngFor="let archiveObj of pageContent?.wileyArchives;">
                          <ng-container *ngIf="archiveObj?.shortName === archive;">
                            <li>
                              <span class="popover_inner_span" title="{{archiveObj?.name}}">{{archiveObj?.name}} </span>
                            </li>
                            <i class="fa fa-times pull-right" (click)="removeArchive(archive)" role="button" *ngIf="copyAdvancedFilterParam?.archive?.length > 1"></i>
                          </ng-container>
                        </ng-container>
                      </ul> -->
                      <ul class="popoverUl" *ngFor="let archive of limitArchivrDropdown; let i = index">
                            <li>
                              <span class="popover_inner_span" title="{{archive?.name}}">{{archive?.name}} </span>
                            </li>
                            <i class="fa fa-times pull-right" (click)="removeArchive(archive)" role="button" *ngIf="limitArchivrDropdown?.length > 1"></i>
                      </ul>
                    </ng-template>
                  </li>
                  <li class="historyBatch col-sm-3 padding-left-0" *ngIf="copyAdvancedFilterParam?.contentSources?.length > 0" [class.singleLimit]="copyAdvancedFilterParam?.contentSources?.length < 2">
                    <label class="search-resultlbl">{{ 'SearchResult.Sources' | translate }} </label>
                    <label *ngIf="copyAdvancedFilterParam?.contentSources?.length < 2">
                      <span *ngFor="let source of copyAdvancedFilterParam?.contentSources; let i = index" class="margin-left5">
                        <div class="badge select-qry pull-left singlelimit-select-qry" style="padding: 6px" [attr.title]="source">
                          <div class="searchqryAdv pull-left singlelimit-searchqryAdv">{{source}}</div>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="removeContentSources(source)" role="button"></i>
                        </div>
                      </span>
                    </label>
                    <span *ngIf="copyAdvancedFilterParam?.contentSources?.length > 1" class="badge" aria-hidden="true" [outsideClick]="true"
                      [popover]="sourcepopOverTemplate" [placement]="'bottom'">
                      <div class="select-qry pull-left" title="{{copyAdvancedFilterParam?.contentSources[0]}}">{{copyAdvancedFilterParam?.contentSources[0]}}</div>
                      <div class="search-resultcount pull-left">+{{(copyAdvancedFilterParam?.contentSources?.length-1)}}
                        <i class="fa fa-caret-down"></i>
                      </div>
                      <div class="close_content pull-right">
                        <i class="fa fa-times close-icon" (click)="removeLimiterParams('contentSources')"></i>
                      </div>
                    </span>
                    <ng-template #sourcepopOverTemplate>
                      <ul class="popoverUl" *ngFor="let contentSources of copyAdvancedFilterParam?.contentSources; let i = index">
                        <li>
                          <span class="popover_inner_span" title="{{contentSources}}">{{contentSources}} </span>
                        </li>
                        <i class="fa fa-times pull-right" (click)="removeContentSources(contentSources)" role="button"></i>
                      </ul>
                    </ng-template>
                  </li>
                  <li class="historyBatch col-sm-3" *ngIf="copyAdvancedFilterParam?.contentCollection?.length > 0" [class.singleLimit]="copyAdvancedFilterParam?.contentCollection?.length < 2">
                    <label class="search-resultlbl">{{ 'SearchResult.Collections' | translate }} </label>
                    <label *ngIf="copyAdvancedFilterParam?.contentCollection?.length < 2">
                      <span *ngFor="let collection of copyAdvancedFilterParam?.contentCollection; let i = index" class=" margin-left5" title="{{collection}}"
                        style="padding: 0 !important;">
                        <div class="badge select-qry pull-left singlelimit-select-qry" style="padding: 6px" [attr.title]="collection">
                          <div class="searchqryAdv pull-left singlelimit-searchqryAdv">{{collection}}</div>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="removeContentCollection(collection)" role="button"></i>
                        </div>
                      </span>
                    </label>
                    <span *ngIf="copyAdvancedFilterParam?.contentCollection?.length > 1" class="badge" aria-hidden="true" [outsideClick]="true"
                      [popover]="collectionsepopOverTemplate" [placement]="'bottom'">
                      <div class="select-qry pull-left" title="{{copyAdvancedFilterParam?.contentCollection[0]}}">{{copyAdvancedFilterParam?.contentCollection[0]}}</div>
                      <div class="search-resultcount pull-left">+{{(copyAdvancedFilterParam?.contentCollection?.length-1)}}
                        <i class="fa fa-caret-down"></i>
                      </div>
                      <div class="close_content pull-right">
                        <i class="fa fa-times close-icon" (click)="removeLimiterParams('contentCollection')"></i>
                      </div>
                    </span>
                    <ng-template #collectionsepopOverTemplate>
                      <ul class="popoverUl" *ngFor="let contentCollection of copyAdvancedFilterParam?.contentCollection; let i = index">
                        <li>
                          <span class="popover_inner_span" title="{{contentCollection}}">{{contentCollection}} </span>
                        </li>
                        <i class="fa fa-times pull-right" (click)="removeContentCollection(contentCollection)" role="button"></i>
                      </ul>
                    </ng-template>
                  </li>
                  <!-- <pre> {{ copyAdvancedFilterParam | json}}</pre> -->
                  <li class="historyBatch col-sm-3" *ngIf="copyAdvancedFilterParam?.publishedDateIncludesNull && copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDatePrefix"
                    [ngClass]="{'publishedDate_result' : (copyAdvancedFilterParam?.publishedDateTo || copyAdvancedFilterParam?.publishedDateIncludesNull) }">
                    <label *ngIf="copyAdvancedFilterParam?.publishedDateIncludesNull && copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDatePrefix"
                      class="search-resultlbl">{{ 'SearchResult.PublicationDate' | translate }} </label>
                    <span>
                      <label *ngIf="copyAdvancedFilterParam?.publishedDateIncludesNull && copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDatePrefix">
                        <span class="badge margin-left5 select-qry-pub-date" style="min-width: 100px;">
                          <p class="pubdate-p">
                            <span class="">
                              <span *ngIf="copyAdvancedFilterParam?.publishedDateFrom !== 'All'">{{copyAdvancedFilterParam?.publishedDatePrefix}}
                                <span *ngIf="copyAdvancedFilterParam?.publishedDatePrefix !== 'All'">:</span>
                              </span>
                              {{ copyAdvancedFilterParam?.publishedDateFrom}}
                              <span *ngIf="copyAdvancedFilterParam?.publishedDateTo">{{ 'Common.AND' | translate }}</span>

                              <span *ngIf="copyAdvancedFilterParam?.publishedDateTo.includes('-') && copyAdvancedFilterParam?.publishedDateTo.length > 5 && copyAdvancedFilterParam?.publishedDateTo.length > 8">
                                {{copyAdvancedFilterParam?.publishedDateTo}}
                              </span>
                              <span class="2" *ngIf="copyAdvancedFilterParam?.publishedDateTo.length === 5">
                                {{copyAdvancedFilterParam?.publishedDateTo.slice(0,4)}}
                              </span>
                              <span *ngIf="copyAdvancedFilterParam?.publishedDateTo.length < 5">
                                {{copyAdvancedFilterParam?.publishedDateTo}}
                              </span>
                              <span class="4" *ngIf="copyAdvancedFilterParam?.publishedDateTo.length === 8">
                                {{copyAdvancedFilterParam?.publishedDateTo.slice(0,7)}}
                              </span>
                            </span>
                            <span *ngIf="copyAdvancedFilterParam?.publishedDateIncludesNull">&nbsp;
                              <span class="pub_date_operator">{{ 'Common.AND' | translate }}</span>&nbsp;
                              <span class="pub_date">{{ 'Common.UNDATED' | translate }}</span>&nbsp;
                            </span>
                            <span class="fa fa-times no-float close-icon" (click)="removeLimiterParams('publishedDate')" style="position: relative;padding-left: 8px;"></span>
                          </p>
                        </span>
                      </label>
                    </span>
                  </li>
                </ul>
              </ul>
            </ul>
          </div>
          <div id="searchexplorerText">
            <input aria-label="Search Maps" name="docSearch" placeholder="{{'Common.Placeholder.searchPhotos' | translate}}" autofocus
              title="{{'Common.Placeholder.searchPhotos' | translate}}" type="text" id="search_textbox" class="form-control searchInput"
              #searchTermValue [(ngModel)]="searchTerm" autocomplete="off">
            <button (click)="getPhotoExplorerData(searchTerm);" [class.disabled]="isSearchBtnEnable" class="btn btn-primary simpleSearchBtn"
              id="simpleSearchBtn" type="submit" title="{{ 'Common.Search' | translate }}">
              <i class="fa fa-search"></i>
              <span class="sr-only">{{ 'Common.Search' | translate }}</span>
            </button>
          </div>
          <div class="input-group-btn widthAuto">
            <a class="btn btn-default pad-2" title="{{'explorer.mapTitle' | translate}}" (click)="expandMapExplorer()">
              <span class="fa fa-globe fa-2x primary-text-color map-marker"></span>
            </a>
            <button class="btn btn-primary btn-adv-search explorer-button" (click)="onShown()">{{'HomePage.limit'|
              translate}}</button>
            <!-- <a class="btn btn-primary btn-adv-search explorer-button" triggers="click:click" [popover]="filterTemplate" [placement]="'bottom'"
              [outsideClick]="true" #popupRef="bs-popover" (onShown)="onShown()" (onHidden)="onHidden()">{{'HomePage.limit'| translate}}
            </a> -->
          </div>
        </div>
        <ng-template #filterTemplate>
          <div class="row photoLimiter">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <span class="closeIcon"><i (click)="closeLimitPopup()" class="fa fa-window-close" aria-hidden="true"></i></span>
              <app-text-analysis-limiter [isFromPhotoExplorer]="isFromPhotoExplorer" [limitLabel]="'Common.limit' | translate" (advancedFilterParamOut)="receiveAdvancedParam($event)"
                [popupRef]="popupRef" (saveDone)="closeLimitPopup($event)" (limiterChanged)="fetchResultCount($event)" [estimatedCount]="estimatedCount" [showLoadingTxt]="showLoadingTxt"
                [isFromPage]="isFromPage"></app-text-analysis-limiter>
            </div>
          </div>
        </ng-template>
      </form>

      <div class="row m-t-15">

        <div class="page-info col-md-12 col-xs-12 col-sm-12">
          <span class="pull-left" [ngClass]="{'paddingB10':!isMapsExplorerExapnd}">
            <div *ngIf="(totalRecoards > 0)">
              <strong class="primary-text-color">{{totalRecoards}}</strong> {{'explorer.photoPages' | translate}}
            </div>
          </span>

          <ng-container *ngIf="!loading && _albums.length === 0">
            <span class="text-center primary-text-color">
              <strong>{{'explorer.noResults' | translate}}</strong>
            </span>
          </ng-container>
          <ng-container *ngIf="loading">
            <span class="text-center primary-text-color">
              <strong>{{'explorer.loading' | translate}}</strong>
            </span>
          </ng-container>
          <!-- <div *ngIf="isMapsExplorerExapnd" class="display-text-leaflet">
              <p class="display-text-contet"> {{'photoExplorer.drawShape' | translate}} </p>
            </div> -->
        </div>
        <div id="thumbnailImageWrapper2" (click)="resultWrapperScrollHandler($event)" class="photo_img explorer-sm"
        [ngClass]="{'col-md-12 col-sm-12 col-lg-12': !isMapsExplorerExapnd, 'col-md-6 col-sm-6 col-lg-6 col-xs-6
viewport-max-height':isMapsExplorerExapnd}" [style.max-height]="isMapsExplorerExapnd ? '66vh' : ''" [style.overflow]="isMapsExplorerExapnd ? 'auto' : 'none'">
         <!-- <perfect-scrollbar id="thumbnailImageWrapper2" (psScrollDown)="resultWrapperScrollHandler($event)" class="photo_img explorer-sm"
          [ngClass]="{'col-md-12 col-sm-12 col-lg-12': !isMapsExplorerExapnd, 'col-md-6 col-sm-6 col-lg-6 col-xs-6
viewport-max-height':isMapsExplorerExapnd}" [style.max-height]="isMapsExplorerExapnd ? '66vh' : ''"> -->
          <ng-container *ngFor="let image of _albums; let i = index">
            <div class="flt_lt" [ngClass]="{'col-md-4 col-sm-4 col-xs-12 col-lg-3': !isMapsExplorerExapnd, 'col-md-4 col-sm-6 col-xs-12':isMapsExplorerExapnd }">
              <div class="photo-exp-div">
                <img *ngIf="image?.thumb" (click)="gotoDocumentDetails(image.docId, image.pageId, i)" title="{{image?.title | explorerunicode}}" name='Document-image' class="accordian-img img-responsive center-block"
                  alt="Document Image" [defaultImage]="defaultImage" [lazyLoad]="image?.thumb" [errorImage]="errorImage">
                <i class="marker-icon fa fa-map-marker fa-2x" title="{{ 'photoExplorer.showOnMap' | translate }}" (click)="zoomToMap(image.coordinates, image.pageId);$event.stopPropagation();"
                  [ngStyle]="{'color': image['highlightSelected'] ? '#068853': ''}" *ngIf="image?.coordinates?.length > 0"></i>
                <i class="view-icn" title="{{ 'photoExplorer.previewPhoto' | translate }}" (click)="openClipedImage(i, image);$event.stopPropagation();"></i>
              </div>
            </div>
          </ng-container>
          <div *ngIf="totalRecoards !== _albums?.length" class="col-md-12 col-xs-12 col-sm-12 text-center clearFix mar-btm-20">
            <span *ngIf="loadMoreLoader" class="glyphicon glyphicon-repeat fast-right-spinner"></span>
            <div (click)="loadMoreMaps()" class="viewMoreContent" *ngIf="!loadMoreLoader">
              <div class="glyphicon glyphicon-menu-down loadMoreResultsArrow"></div>
              <div [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.ViewMore' | translate }}</div>
            </div>
          </div>
        <!-- </perfect-scrollbar> -->
      </div>
        <div *ngIf="isMapsExplorerExapnd" class="maps-block viewport-height explorerMap-sm" [ngClass]="{'col-xs-6 col-md-6 col-sm-6 col-lg-6':isMapsExplorerExapnd }">
          <div class="viewport-height">
            <app-map [resetMapData]="resetMapData" [valueField]="'count'" (spatialSearchEmits)="onSpatialSearchEmit($event)" [resultCount]="{value: totalRecoards}"
              [componentName]="'Photo'" [zoomToCoOrdinates]="_coordinatesToZoom"></app-map>
          </div>
        </div>
      </div>
    </div>
    <button [ngStyle]="{'display': !hightScrollTop ? 'none': 'block'}" class="btn btn-primary scrollTopPage" (click)="scrollTopPage()"
      title="{{'SearchResult.GoToTop' | translate}}">
      <span class="spriteico topPageIcon"></span>
      <span class="sr-only">{{'Common.scrollTopPage' | translate}}</span>
    </button>
  </div>
</div>




<ng-template #photoExplorerTemplate>
  <div class="modal-body photoexplorer-body">
    <div class="photoexplorer-popupbody">
      <button type="button" class="modal-close-button close pull-right photoexplorer-close-btn" aria-label="Close" (click)="closePopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="photoexp-titles-div">
          <a class="photoexplorer-title primary-text-color" title="{{previewImageObj?.title | explorerunicode}}"><span class="backToSearchResultLink" 
            (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')" appDecodeEntities>{{previewImageObj?.title}}</span></a>
        <div class="photoexplorer-document-title explorer-doc-parent" >
          <div class="explorer-doc-left">
            <span class="document-from-text"> {{'Detail.from' | translate}} </span>
            <a class="primary-text-color text-ellipsis" title="{{previewImageObj?.documentTitle | explorerunicode}}">
              <span class="backToSearchResultLink" (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')" appDecodeEntities>
                {{previewImageObj?.documentTitle }}</span></a>
          </div>
          <div class="explorer-doc-right">
            <button title="{{'Common.viewDocument' | translate}}" class="btn btn-xs btn-primary pull-right" (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')"><span class="spriteico documentview-icon"></span>
              {{'Common.viewDocument' | translate}}
            </button>
            <button class="btn btn-xs btn-primary pull-right citation-btn" id="documentCitation" title="{{ 'Detail.Citation' | translate }}"
              (click)="getCitationDetailsFromDocumnentData(previewImageObj?.docId, 'fromModal')">
              <span class="spriteico citation-icon" id="documentCitation"></span> {{ 'Detail.Citation' | translate }}
            </button>
          </div>
        </div>
        <div (mouseover)="getAccessToCitation()" (mouseenter)="getAccessToCitation()" class="citation-data-container" [class.show-citationContainer]="citationContainer" (click)="$event.stopPropagation();">
          <div class="citation-scrollbar">
          <!-- <perfect-scrollbar [config]="config" class="citation-scrollbar"> -->
                <div class="col-md-12 col-sm-12 padding-left-0 padding-top15 citation-container">
                  <p class="loading-text" *ngIf="!isCitationLoading"> {{ 'SearchResult.loadingPleaseWait' | translate}} </p>
                
                    <div class="col-md-7 col-sm-7 citation-section"  *ngIf="documentCitation?.title">
                        <div  *ngIf="documentCitation?.title" class="citation-content-div padding-0 margin-top20">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.documentTitle' | translate }}</label>
                            <h4 class="citation-content" appDecodeEntities>{{documentCitation?.title}}</h4>
                          </div>
                          <div *ngIf="(documentCitation?.authors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Authors' | translate }}</label>
                            <div class="author-containor">
                              <ng-container *ngFor="let author of documentCitation?.authors; let last = last;">
                                <a class="citation-content author-citation" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                                  <span class="author-comma" *ngIf="!last">, </span>
                                </a>
                              </ng-container>
                            </div>
                          </div>
                          <div *ngIf="(documentCitation?.itemAuthors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl"> name refere {{'Detail.NameReferences' | translate }}</label>
                            <div class="author-containor">
                              <ng-container *ngFor="let author of documentCitation?.itemAuthors; let last = last;">
                                <a class="citation-content author-citation" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                                  <span class="author-comma" *ngIf="!last">, </span>
                                </a>
                              </ng-container>
                            </div>
                          </div>
                          <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.pubDate !==null ">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'HomePage.publicationDate' | translate }}</label>
                            <h4 class="citation-content"> {{documentCitation?.pubDate}}</h4>
                          </div>
                          <div class="citation-content-div padding-0 margin-top20">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Language' | translate }}</label>
                            <h4 class="citation-content">{{documentCitation?.languages}}</h4>
                          </div>
                    </div>
                  
                  <div *ngIf="(documentCitation?.itemAuthors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                    <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{'Detail.NameReferences' | translate }}</label>
                    <div class="author-containor">
                      <ng-container *ngFor="let author of documentCitation?.itemAuthors; let last = last;">
                        <a class="citation-content author-citation" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                          <span class="author-comma" *ngIf="!last">, </span>
                        </a>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-md-5 col-sm-5 citation-section"  *ngIf="documentCitation?.title">
                    <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.collection !==null ">
                      <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Collection' | translate }} </label>
                      <a class="citation-content citation-link" (click)="gotoCollectionDetails(documentCitation?.collectionNumber)">{{documentCitation?.collection}}</a>
                    </div>
                    <div class="citation-content-div padding-0 margin-top20">
                      <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.physicalDescription' | translate }}</label>
                      <h4 class="citation-content">{{documentCitation?.docType}}</h4>
                    </div>
                    <div class="citation-content-div padding-0 margin-top20">
                      <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Source' | translate }}</label>
                      <h4 class="citation-content">{{documentCitation?.source}}</h4>
                    </div>
                    <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.findingAidNumber !==null ">
                      <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl"> {{ 'Detail.findingAidNumber' | translate }}</label>
                      <h4 class="citation-content">{{documentCitation?.findingAidNumber}}</h4>
                    </div>
                    <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.findingAidNumber !==null ">
                      <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.wileyDocumentNumber' | translate }}</label>
                      <a class="citation-content wiley-doc-link" (click)="gotoDocumentDetails(documentCitation?.wileyId, previewImageObj.pageId, activeSlideIndex)">{{documentCitation?.wileyId}}</a>
                    </div>
                    <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.manuscriptNo">
                      <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.referenceNo' | translate }}</label>
                      <h4 class="citation-content">{{documentCitation?.manuscriptNo}}</h4>
                    </div>
                  </div>
                </div>
          <!-- </perfect-scrollbar> -->
        </div> 
        </div>



      </div>
      <div id="explorerOpenSeaDragonCanvas">
        <!-- <app-basic-image-viewer [previewImageURL]="previewImageObj?.image" [tileSource]="tilesource" (toggleNavigation)="toggleNavigation($event)"></app-basic-image-viewer> -->
        <app-basic-image-viewer [previewImageURL]="previewImageObj?.image" [tileSource]="tileSourceObj" (toggleNavigation)="toggleNavigation($event)"
          [disableNavigationButtons]="disableNavigations" (getViewMoreOptionStatus)="viewMoreOptionStatus($event)"></app-basic-image-viewer>

        <div class="photo-cover-div" *ngIf="hasRelatedImages">
          <div class="btn-group photo-cover-buttons">
            <button type="button" class="btn btn-primary front-image" (click)="loadCoverImages(previewImageObj, currentImageId, 'f')">Front</button>
            <button type="button" class="btn btn-primary photo-back-btn back-image" (click)="loadCoverImages(previewImageObj, previewImageObj?.relatedImageId, 'b')">Back</button>
          </div>
        </div>
      </div>
      <div class="photoexplorer-captions toggledDisclaimer disclaimer-div" *ngIf="previewImageObj?.captions" [class.toggledDisclaimer]="toggledisclaimerShow">
        <span tooltip="{{'explorer.captions' | translate}} {{previewImageObj?.captions}}" [tooltipDisabled]="false" [tooltipAnimation]="true"
          tooltipPlacement="top">
          <b [class.arabic-style]="isArabicLanguage">{{'explorer.captions' | translate}}</b> {{previewImageObj?.captions}}</span>
        <div [class.arabic-style]="isArabicLanguage" class="disclaimer-toggle" (click)="disclaimerToggle()" *ngIf="previewImageObj?.captions.length > 170">
          <span *ngIf="toggledisclaimerShow">{{ 'HomePage.viewMore' | translate }} &nbsp;
            <i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-down"
              title="{{ 'HomePage.viewMore' | translate }}" aria-hidden="true"></i>
          </span>
          <span *ngIf="!toggledisclaimerShow">{{ 'HomePage.viewLess' | translate }} &nbsp;
            <i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-up"
              title="{{ 'HomePage.viewLess' | translate }}" aria-hidden="true"></i>
          </span>
        </div>
        <!-- <div style="width:98%">
            {{'explorer.captions' | translate}}
            <span #textContent id="read-more{{message | async}}">
              {{previewImageObj?.captions}}
            </span>
            <div class="read-more-link">
              <a [class.arabic-style]="isArabicLanguage" role="button" readMore [readMore-length]="200" [readMore-element]="textContent"
                class="">
                <span [class.arabic-style]="isArabicLanguage" class="less pull-right" title="{{ 'HomePage.viewLess' | translate }}">
                  <span>{{ 'HomePage.viewLess' | translate }}</span>
                  <i class="btn-up-arrow no-margin-right fa fa-angle-up vertical-align-middle" aria-hidden="true"></i>
                </span>
                <span [class.arabic-style]="isArabicLanguage" class="more pull-right" title="{{ 'HomePage.viewMore' | translate }}">
                  <span>{{ 'HomePage.viewMore' | translate }}</span>
                  <i class="btn-down-arrow no-margin-right fa fa-angle-down vertical-align-middle" aria-hidden="true"></i>
                </span>
              </a>
            </div>
        </div> -->
      </div>
      <!-- <carousel [(activeSlide)]="activeSlideIndex" [noWrap]="true" [interval]="false" (activeSlideChange)="slideChangeEvent($event)">

        <slide *ngFor="let image of _albums; let index = index">
          <div class="photoexp-titles-div">
            <p class="photoexplorer-title" title="{{image.title}}">{{image?.title}}</p>
            <p class="photoexplorer-document-title" title="{{image.documentTitle}}" *ngIf="image?.documentTitle">{{'Detail.from'
              |
              translate}}
              {{image?.documentTitle}}</p>
          </div>
          <div class="img-container photo-exp-image-div" [class.arabic-style]="isArabicLanguage">
            <img (click)="gotoDocumentDetails(image.docId, image.pageId, 'fromModal')" title="{{image?.title}}" name='Document-image'
              class="img-responsive center-block" alt="Document Image" [defaultImage]="defaultImage" [lazyLoad]="image?.image"
              [errorImage]="errorImage">  
              <app-basic-image-viewer [previewImageURL]="previewImageURL"></app-basic-image-viewer>
          </div>
          <div class="photoexplorer-captions" *ngIf="image?.captions?.length > 0">
            {{'explorer.captions' | translate}}

            <span #textContent id="read-more{{message | async}}">
              <span *ngFor="let cap of image?.captions; let last = last">{{cap}}<span *ngIf="!last">, &nbsp;</span></span>
            </span>
            <div class="read-more-link">
              <a [class.arabic-style]="isArabicLanguage" role="button" readMore [readMore-length]="350"
                [readMore-element]="textContent" class="btn btn-primary viewmore-btn">
                <span [class.arabic-style]="isArabicLanguage" class="less pull-right">{{ 'HomePage.viewLess' |
                  translate }}
                  <i class="btn-up-arrow no-margin-right fa fa-angle-up" aria-hidden="true"></i>
                </span>
                <span [class.arabic-style]="isArabicLanguage" class="more pull-right">{{ 'HomePage.viewMore' |
                  translate }}
                  <i class="btn-down-arrow no-margin-right fa fa-angle-down" aria-hidden="true"></i>
                </span>
              </a>
            </div>
          </div>
        </slide>
      </carousel>  -->
    </div>
  </div>
</ng-template>