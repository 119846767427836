import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayString'
})
export class DisplayStringPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(/[^a-zA-Z0-9,\-\ ]/g, '');
  }

}
