
import {takeUntil} from 'rxjs/operators';

/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {
	Component, forwardRef, Input, ViewChild, ElementRef, HostListener,
	Renderer2, TemplateRef, Output, EventEmitter, OnInit, AfterContentInit, OnDestroy, ChangeDetectorRef,
	SimpleChanges,
	OnChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchService } from './../common/services/search.service';
import { PageSettingsService } from './../common/services/page-settings.service';
import { ModalService } from './../common/services/modal.service';
import { SharedService } from '../common/services/sharedService';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map as _map } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment, APIURLS } from '../../environments/environment';

declare var $: any;
@Component({
	selector: 'multiselect-dropdown',
	templateUrl: './multiselect-dropdown.component.html',
	styleUrls: ['./multiselect-dropdown.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultiselectDropdownComponent),
			multi: true
		}, SearchService
	]
})
export class MultiselectDropdownComponent implements ControlValueAccessor, AfterContentInit, OnInit, OnChanges, OnDestroy {
	customerArchives = [];
	translateAdvancedSearch: any;
	isArabicLanguage: Boolean = false;
	changingArchivesConfirmation: string;
	selectArchive: string;
	@Output() isMutliDropdownOpen = new EventEmitter<boolean>();

	// @ViewChild('optionsList', { read: ElementRef }) private optionsList: ElementRef;
	// @ViewChild('selectAll', { read: ElementRef }) private selectAllBtn: ElementRef;
	@ViewChild('optionsList', { static: false, read: ElementRef }) optionsList: ElementRef;
	@ViewChild('selectAll', { static: false, read: ElementRef }) selectAllBtn: ElementRef;

	@Input('options') dropDownOptions: any = [];
	@Input('text-key') textKey: string = 'text';
	@Input('value-key') valueKey: string = 'value';
	@Input('option-html') optionHTML: TemplateRef<any>;
	@Input('placeholder') placeHolder: string = 'Select';
	@Input('page-content') pageContent: any;
	isListOpen: boolean;
	isAllSelected: Boolean = false;
	selectedOptions: any = [];
	optionContext: any;
	popupconfirmContent = '';
	config = {
		animated: true,
		keyboard: false,
		backdrop: true,
		ignoreBackdropClick: false
	};
	modalRef: any;
	dropDownValue: any;
	copyDropdownOptions: Object;
	@Input('advance-dropdown-status')
	set advanceDropdownStatus(flag: boolean) {
		this.isListOpen = false;
		if (this.optionsList) {
			this.renderer.removeClass(this.optionsList.nativeElement, 'show-options');
		}

	}

	get advanceDropdownStatus() {
		return this.isListOpen;
	}
	showArchives: Boolean = false;
	headerDropdownOpen: any;
	private stop$: Subject<void> = new Subject<void>();
	constructor(private renderer: Renderer2,
		private searchService: SearchService,
		private modalService: ModalService,
		private sharedService: SharedService,
		private translate: TranslateService,
		private httpClient: HttpClient,
		private cd: ChangeDetectorRef) {
		this.isListOpen = false;
		this.isAllSelected = true;
	}
// 	async getArchives() {
// 		this.dropDownCount = false;
// 		return new Promise(resolve => {
// 	let archivesArr: any;
// 	this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + localStorage.getItem('currentLocation')).takeUntil(this.stop$).subscribe((res) => {
// 		archivesArr = res;
// 		console.log(res)
// 		// archivesArr.forEach(element => {
// 		// 	if (element.shortName === 'RGS1') {
// 		// 		if (this.dropDownOptions.indexOf('RGS1') > -1) {
// 		// 			this.dropDownOptions.push(element);
// 		// 		}
// 		// 	}
// 		// 	if (element.shortName === 'RGS2') {
// 		// 		if (this.dropDownOptions.indexOf('RGS2') > -1) {
// 		// 			this.dropDownOptions.push(element);
// 		// 		}
// 		// 	}
// 		// 	if (element.shortName === 'RCP1') {
// 		// 		if (this.dropDownOptions.indexOf('RCP1') > -1) {
// 		// 			this.dropDownOptions.push(element);
// 		// 		}
// 		// 	}
// 		// 	if (element.shortName === 'RCP2') {
// 		// 		if (this.dropDownOptions.indexOf('RCP2') > -1) {
// 		// 			this.dropDownOptions.push(element);
// 		// 		}
// 		// 	}
// 		// });
// 		if (window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
// 			window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
// 			// this.dropDownOptions = res;
// 			this.copyDropdownOptions = res;
// 			// this.toggleSelectAll();
// 		} else {
// 			// this.toggleSelectAll();
// 		}
// 		const archiveArry = _map(this.dropDownOptions, 'shortName');
// 		localStorage.setItem('archivesList', archiveArry);
// 		localStorage.setItem('limitorArchives', JSON.stringify(this.dropDownOptions));
// 		this.customerArchives = archiveArry;
// 		this.isMutliDropdownOpen.emit(this.isListOpen);
// 		resolve('resolved');
// 	}, err => { });
// });
// }

	ngOnInit() {
		// setTimeout(() => {
		// 	this.selectedOptions = this.dropDownOptions;
		// }, 1000);
		const currentLanguage = sessionStorage.getItem('lang');
		if (currentLanguage) {
			if (currentLanguage.toLowerCase() === 'ar') {
				this.isArabicLanguage = true;
			}
		}
		this.sharedService.language.pipe(
			takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
		this.translate.get('AdvancedSearch').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
			this.translateAdvancedSearch = res;
		});

		this.translate.get('Common.Popups.selectArchive').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
			this.selectArchive = res;
		});
		this.translate.get('Common.Popups.changingArchivesConfirmation').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
			this.changingArchivesConfirmation = res;
		});
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.dropDownOptions) {
			if (changes.dropDownOptions.currentValue?.length !== changes.dropDownOptions.previousValue?.length) {
				this.dropDownOptions = changes.dropDownOptions.currentValue;
				let arry = [];
				changes.dropDownOptions.currentValue.map(function (item) {
					arry.push(item.shortName)
				})
				this.writeValue(arry);
			}
		}
	}

	showList() {
		if (this.optionsList) {
			if ($('.option-list').hasClass('show-options')) {
				this.renderer.removeClass(this.optionsList.nativeElement, 'show-options');
				this.isListOpen = false;
				// $('.option-list').hide();
			} else {
				this.renderer.addClass(this.optionsList.nativeElement, 'show-options');
				this.isListOpen = true;
			}
		}
		this.isMutliDropdownOpen.emit(this.isListOpen);
	}

	hideList() {
		if ($('.option-list').hasClass('show-options')) {
			this.renderer.addClass(this.optionsList.nativeElement, 'show-options');
			this.isListOpen = true;
		} else {
			this.renderer.removeClass(this.optionsList.nativeElement, 'show-options');
			this.isListOpen = false;
			// $('.option-list').hide();
		}
		this.isMutliDropdownOpen.emit(this.isListOpen);
	}
//isAllSelectedCopy: Boolean;
	toggleOptionsList() {
		this.isAllSelected =  this.isAllSelected;
		if (this.isListOpen) {
			if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
				if (this.selectedOptions.length > 0) {
					this.hideList();
				} else {
					// this.modalService.showAlertPopup('Warning', this.selectArchive,
					// 	() => {
					// 		this.showList();

					// 	});
					this.modalService.showNotification('WARNING', 'Warning', this.selectArchive || 'Please select at least one archive.');
					this.showList();
				}
			} else {
				this.hideList();
			}
		} else {
			this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, (response, foundFilter) => {
				if (foundFilter) {
					this.modalService.showConfirmPopup('Warning', this.changingArchivesConfirmation,
						() => {
							this.showList();
						}, () => {

						});
				} else {
					this.showList();
				}
			});
		}
		this.sharedService.changearchiveValue('true');

	}

	toggleSelectAll() {
		setTimeout(() => {
		this.selectedOptions = [];
		this.isAllSelected = !this.isAllSelected;
		this.cd.detectChanges();
		if (this.isAllSelected) {
			this.dropDownOptions.forEach((option, index) => {
				this.selectedOptions.push(option[this.valueKey]);
			});
		} else {
			this.selectedOptions = [];
		}
		this.propagateChange(this.selectedOptions);
		this.sharedService.setAdvSearchArchiveSelection(PageSettingsService.pageSettings.currentSelectedArchives);
		}, 500);
	}

	toggleSelectedOption(value) {
		// setTimeout(() => {
		const indexOfVal = this.selectedOptions.indexOf(value);
		if (indexOfVal === -1) {
			this.selectedOptions.push(value);
		} else {
			this.selectedOptions.splice(indexOfVal, 1);
		}
		if (this.dropDownOptions) {
			if (this.selectedOptions.length === this.dropDownOptions.length) {
				this.isAllSelected = true;
			} else {
				this.isAllSelected = false;
			}
		}
		// this.searchService.estimateResultCount();
		this.propagateChange(this.selectedOptions);
		// }, 500);
	}
	ngAfterContentInit() {

		if (PageSettingsService.pageSettings.currentArchive.toUpperCase() === 'WILEY') {
			const archivesList = JSON.parse(localStorage.getItem('personalizationData'));
			const selectedArchiveList = [];
			if (archivesList) {
				const archiveArry = _map(archivesList.archives, 'shortName');
				// this.customerArchives = archiveArry;
			}
		}

		this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
			this.dropDownValue = res;
			if (this.dropDownValue.archiveSelected === true) {
				this.showArchives = true;
			} else {
				this.showArchives = false;

			}

		});
	}
	onOptionClick(option) {
		this.toggleSelectedOption(option[this.valueKey]);
		this.sharedService.setAdvSearchArchiveSelection(PageSettingsService.pageSettings.currentSelectedArchives);
	}

	writeValue(value: any) {
		if (!value) {
			return;
		}
		// if (this.dropDownCount === true) {
		// 	await this.getArchives();
		// }
		// setTimeout(() => {
			// if (window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
			// window.location.pathname.split('/')[1].toUpperCase() !== 'RCP' && this.copyDropdownOptions !== undefined) {
			// 	this.dropDownOptions = this.copyDropdownOptions;
			// }
	// let archivesArr: any;
		const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
		if (getVal) {
			if (window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
				window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
				// this.dropDownOptions = res;
				this.copyDropdownOptions = getVal;
				// this.toggleSelectAll();
			}
			const archiveArry = _map(this.dropDownOptions, 'shortName');
			localStorage.setItem('archivesList', JSON.stringify(archiveArry));
			localStorage.setItem('limitorArchives', JSON.stringify(this.dropDownOptions));
			this.customerArchives = archiveArry;
			this.isMutliDropdownOpen.emit(this.isListOpen);
			if (value) {
				this.selectedOptions = [];
				this.customerArchives.forEach(option => {
					this.toggleSelectedOption(option);
				});
				if (this.dropDownOptions) {
					if (this.selectedOptions.length === this.dropDownOptions.length) {
						this.isAllSelected = true;
					} else {
						this.isAllSelected = true;
					}
				}
			}
		} else {
			this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + localStorage.getItem('currentLocation')).pipe(takeUntil(this.stop$)).subscribe((res) => {
				// archivesArr = res;
				if (window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
					window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
					// this.dropDownOptions = res;
					this.copyDropdownOptions = res;
					// this.toggleSelectAll();
				} else {
					// this.toggleSelectAll();
				}
				const archiveArry = _map(this.dropDownOptions, 'shortName');
				localStorage.setItem('archivesList', JSON.stringify(archiveArry));
				localStorage.setItem('limitorArchives', JSON.stringify(this.dropDownOptions));
				this.customerArchives = archiveArry;
				this.isMutliDropdownOpen.emit(this.isListOpen);
				if (value) {
					this.selectedOptions = [];
					this.customerArchives.forEach(option => {
						this.toggleSelectedOption(option);
					});
					if (this.dropDownOptions) {
						if (this.selectedOptions.length === this.dropDownOptions.length) {
							this.isAllSelected = true;
						} else {
							this.isAllSelected = true;
						}
					}
				}
			}, err => { });	
		}		
	}

	propagateChange = (_: any) => { };

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched() { }

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this.isListOpen) {
			if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
				if (this.selectedOptions.length > 0) {
					this.hideList();
				}
				//  else if (!$('.modal').hasClass('in')) {
				// 	this.modalService.showAlertPopup('Warning', this.selectArchive,
				// 		() => {
				// 			this.showList();
				// 		});
				// 	this.isListOpen = false;
				// }
				else {
					this.modalService.showNotification('WARNING', 'Warning', this.selectArchive || 'Please select at least one archive.');
					this.showList();
				}
			} else {
				this.hideList();
			}
		} else {
			// this.isListOpen = true;
		}
		if ($('.modal-backdrop')) {
			if ($('.modal-backdrop').hasClass('in')) {
				// event.preventDefault();
			} else {
				// this.showArchives = false;
				this.sharedService.dropdownToggleOption({
					tools: false,
					otherArchives: false,
					archiveSelected: false,
					advanceSearch: false,
					help: false,
					language: false,
					shareDocument: false
				});
			}
		} else {
			this.sharedService.dropdownToggleOption({
				tools: false,
				otherArchives: false,
				archiveSelected: false,
				advanceSearch: false,
				help: false,
				language: false,
				shareDocument: false
			});
		}
	}
	ngOnDestroy(): void {
		this.stop$.next();
		this.stop$.complete();
	}

}
