<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="tab-wrapper">
      <tabset #explorerTabs>
        <tab [customClass]="'docviewer-tabs'" heading="{{'explorer.photo' | translate}}" (selectTab)="gotoPhotoExplorer()">
        </tab>
        <tab [customClass]="'docviewer-tabs'" heading="{{'explorer.map' | translate}}" [active]="true">
        </tab>
        <tab [customClass]="'docviewer-tabs tabtext'" [disabled]="true" heading="{{'explorer.explorerphotosmaps' | translate}}">
        </tab>
      </tabset>
    </div>
    <div class="page_wrap">
      <h4 class="photo_title">{{'explorer.mapPageTitle' | translate}}</h4>
      <!-- <div class="col-sm-12"> -->

      <form name="simpleSearchForm">
          <div class="input-group map-explorerCls" id="photo_explorer">
            <label class="sr-only" for="search_textbox">{{'explorer.searchMaps' | translate}}</label>
            <div class="term-searchResult" id="queries" *ngIf="copyAdvancedFilterParam?.archive?.length > 0 || (copyAdvancedFilterParam?.publishedDateIncludesNull === true  && copyAdvancedFilterParam?.publishedDateFrom|| copyAdvancedFilterParam?.publishedDatePrefix)||
              copyAdvancedFilterParam?.languages.length > 0 || copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0 ||
              copyAdvancedFilterParam?.subject.length > 0" (click)="$event.stopPropagation()">
          <ul class="result-tag" *ngIf="copyAdvancedFilterParam?.archive?.length > 0 || (copyAdvancedFilterParam?.publishedDateIncludesNull === true  && copyAdvancedFilterParam?.publishedDateFrom|| copyAdvancedFilterParam?.publishedDatePrefix)|| copyAdvancedFilterParam?.languages.length > 0 ||
            copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0 || copyAdvancedFilterParam?.subject.length > 0">
            <ul class="padding-0 advSearch searchQry" [ngClass]="{'borderNone':isMobileView}" *ngIf="copyAdvancedFilterParam?.archive?.length > 0 || copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDateIncludesNull || copyAdvancedFilterParam?.publishedDatePrefix|| copyAdvancedFilterParam?.contentType.length > 0 || copyAdvancedFilterParam?.docSubType.length > 0 ||
               copyAdvancedFilterParam?.publishedDateFrom  != ''|| copyAdvancedFilterParam?.languages.length > 0 || copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0  || copyAdvancedFilterParam?.subject.length > 0">
              <label (click)="toggleLimiters($event)"  *ngIf="(copyAdvancedFilterParam?.archive?.length > 0 || copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDateIncludesNull || copyAdvancedFilterParam?.publishedDatePrefix|| copyAdvancedFilterParam?.languages.length > 0 ||
                copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0 || copyAdvancedFilterParam?.subject.length > 0)"
                class="searchBy searchLimiterLabel">{{ 'SearchResult.ViewLimiters' | translate }}
                   <i class="fa fa-caret-down limior-down-arrow" *ngIf="!showLimiters" ></i>
                   <i class="fa fa-caret-up limior-down-arrow" *ngIf="showLimiters"></i>
               </label>
               <i *ngIf="isMobileView && (copyAdvancedFilterParam?.archive?.length > 0 || copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDateIncludesNull || copyAdvancedFilterParam?.publishedDatePrefix|| copyAdvancedFilterParam?.languages.length > 0 ||
                  copyAdvancedFilterParam?.contentCollection.length > 0 || copyAdvancedFilterParam?.contentSources.length > 0 || copyAdvancedFilterParam?.subject.length > 0)" (click)="toggleLimiters($event)" class="fa fa-bars fa-lg"></i>
              <ul class="dropdown-menu customDropdownCls" [style.display]="showLimiters ? 'block' : 'none'">
                <li class="historyBatch archivesList padding-left-0" *ngIf="copyAdvancedFilterParam?.archive?.length > 0" [class.archive-singleLimit]="copyAdvancedFilterParam?.archive?.length < 2">
                  <label class="search-resultlbl">{{ 'SearchResult.archive' | translate }} </label>
                  <span *ngIf="enableWiley" class="badge" aria-hidden="true" [outsideClick]="true" [popover]="sourcepopOverTemplate" [placement]="'bottom'">
                    <ng-container *ngFor="let archiveObj of limitArchivrDropdown;let i = index">
                      <ng-container *ngIf="i== 0">
                        <div class="archives-select-qry pull-left" title="{{archiveObj?.name}}">{{archiveObj.name}}</div>
                        <div *ngIf="limitArchivrDropdown?.length > 1" class="archives-search-resultcount search-resultcount pull-left">+{{(limitArchivrDropdown?.length-1)}}
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </ng-container>
                    </ng-container>
                  </span>
                  <span *ngIf="!enableWiley" class="badge">
                    <ng-container *ngFor="let archiveObj of limitArchivrDropdown;let i = index">
                        <div class="archives-select-qry pull-left" title="{{archiveObj?.name}}">{{archiveObj.name}}</div>
                    </ng-container>
                  </span>
                  <!-- <label *ngIf="copyAdvancedFilterParam?.archive?.length < 2">
                    <ng-container *ngFor="let archive of copyAdvancedFilterParam?.archive">
                      <span *ngFor="let archiveObj of pageContent?.wileyArchives; let i = index" class="margin-left5">
                        <ng-container *ngIf="archive === archiveObj?.shortName">
                          <div class="badge select-qry pull-left singlelimit-select-qry" style="padding: 6px" [attr.title]="archiveObj?.name">
                            <div class="searchqryAdv pull-left singlelimit-searchqryAdv">
                              {{archiveObj?.name}}
                            </div>
                          </div>
                        </ng-container>
                      </span>
                    </ng-container>
                  </label>
                  <span *ngIf="copyAdvancedFilterParam?.archive?.length > 1" class="badge" aria-hidden="true"
                    [outsideClick]="true" [popover]="sourcepopOverTemplate" [placement]="'bottom'">
                    <ng-container *ngFor="let archiveObj of pageContent?.wileyArchives;">
                      <ng-container *ngIf="archiveObj?.shortName === copyAdvancedFilterParam?.archive[0]">
                        <div class="archives-select-qry pull-left" title="{{archiveObj?.name}}">{{archiveObj?.name}}</div>
                        <div class="archives-search-resultcount search-resultcount  pull-left">+{{(copyAdvancedFilterParam?.archive?.length-1)}}
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </ng-container>
                    </ng-container>
                  </span> -->
                  <ng-template #sourcepopOverTemplate>
                    <!-- <ul class="popoverUl" *ngFor="let archive of copyAdvancedFilterParam?.archive; let i = index">
                      <ng-container *ngFor="let archiveObj of pageContent?.wileyArchives;">
                        <ng-container *ngIf="archiveObj?.shortName === archive">
                          <li>
                            <span class="popover_inner_span" title="{{archiveObj?.name}}">{{archiveObj?.name}} </span>
                          </li>
                          <i class="fa fa-times pull-right" (click)="removeArchive(archive)" role="button" *ngIf="copyAdvancedFilterParam?.archive?.length > 1"></i>
                        </ng-container>
                      </ng-container>
                    </ul> -->
                    <ul class="popoverUl" *ngFor="let archive of limitArchivrDropdown; let i = index">
                      <li>
                        <span class="popover_inner_span" title="{{archive?.name}}">{{archive?.name}} </span>
                      </li>
                      <i class="fa fa-times pull-right" (click)="removeArchive(archive)" role="button" *ngIf="limitArchivrDropdown?.length > 1"></i>
                    </ul>
                  </ng-template>
                </li>
                <li class="historyBatch col-sm-3 padding-left-0" *ngIf="copyAdvancedFilterParam?.contentSources?.length > 0" [class.singleLimit]="copyAdvancedFilterParam?.contentSources?.length < 2">
                  <label class="search-resultlbl">{{ 'SearchResult.Sources' | translate }} </label>
                  <label *ngIf="copyAdvancedFilterParam?.contentSources?.length < 2">
                    <span *ngFor="let source of copyAdvancedFilterParam?.contentSources; let i = index" class="margin-left5">
                      <div class="badge select-qry pull-left singlelimit-select-qry" style="padding: 6px" [attr.title]="source">
                        <div class="searchqryAdv pull-left singlelimit-searchqryAdv">{{source}}</div>
                      </div>
                      <div class="close_content pull-right">
                        <i class="fa fa-times close-icon" (click)="removeContentSources(source)" role="button"></i>
                      </div>
                    </span>
                  </label>
                  <span *ngIf="copyAdvancedFilterParam?.contentSources?.length > 1" class="badge" aria-hidden="true"
                    [outsideClick]="true" [popover]="sourcepopOverTemplate" [placement]="'bottom'">
                    <div class="select-qry pull-left" title="{{copyAdvancedFilterParam?.contentSources[0]}}">{{copyAdvancedFilterParam?.contentSources[0]}}</div>
                    <div class="search-resultcount pull-left">+{{(copyAdvancedFilterParam?.contentSources?.length-1)}}
                      <i class="fa fa-caret-down"></i>
                    </div>
                    <div class="close_content pull-right">
                      <i class="fa fa-times close-icon" (click)="removeLimiterParams('contentSources')"></i>
                    </div>
                  </span>
                  <ng-template #sourcepopOverTemplate>
                    <ul class="popoverUl" *ngFor="let contentSources of copyAdvancedFilterParam?.contentSources; let i = index">
                      <li>
                        <span class="popover_inner_span" title="{{contentSources}}">{{contentSources}} </span>
                      </li>
                      <i class="fa fa-times pull-right" (click)="removeContentSources(contentSources)" role="button"></i>
                    </ul>
                  </ng-template>
                </li>
                <li class="historyBatch col-sm-3" *ngIf="copyAdvancedFilterParam?.contentCollection?.length > 0" [class.singleLimit]="copyAdvancedFilterParam?.contentCollection?.length < 2">
                  <label class="search-resultlbl">{{ 'SearchResult.Collections' | translate }} </label>
                  <label *ngIf="copyAdvancedFilterParam?.contentCollection?.length < 2">
                    <span *ngFor="let collection of copyAdvancedFilterParam?.contentCollection; let i = index" class=" margin-left5"
                      title="{{collection}}" style="padding: 0 !important;">
                      <div class="badge select-qry pull-left singlelimit-select-qry" style="padding: 6px" [attr.title]="collection">
                        <div class="searchqryAdv pull-left singlelimit-searchqryAdv">{{collection}}</div>
                      </div>
                      <div class="close_content pull-right">
                        <i class="fa fa-times close-icon" (click)="removeContentCollection(collection)" role="button"></i>
                      </div>
                    </span>
                  </label>
                  <span *ngIf="copyAdvancedFilterParam?.contentCollection?.length > 1" class="badge" aria-hidden="true"
                    [outsideClick]="true" [popover]="collectionsepopOverTemplate" [placement]="'bottom'">
                    <div class="select-qry pull-left" title="{{copyAdvancedFilterParam?.contentCollection[0]}}">{{copyAdvancedFilterParam?.contentCollection[0]}}</div>
                    <div class="search-resultcount pull-left">+{{(copyAdvancedFilterParam?.contentCollection?.length-1)}}
                      <i class="fa fa-caret-down"></i>
                    </div>
                    <div class="close_content pull-right">
                      <i class="fa fa-times close-icon" (click)="removeLimiterParams('contentCollection')"></i>
                    </div>
                  </span>
                  <ng-template #collectionsepopOverTemplate>
                    <ul class="popoverUl" *ngFor="let contentCollection of copyAdvancedFilterParam?.contentCollection; let i = index">
                      <li>
                        <span class="popover_inner_span" title="{{contentCollection}}">{{contentCollection}} </span>
                      </li>
                      <i class="fa fa-times pull-right" (click)="removeContentCollection(contentCollection)" role="button"></i>
                    </ul>
                  </ng-template>
                </li>
                <li class="historyBatch col-sm-3" *ngIf="copyAdvancedFilterParam?.publishedDateIncludesNull && copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDatePrefix"
                  [ngClass]="{'publishedDate_result' : (copyAdvancedFilterParam?.publishedDateTo || copyAdvancedFilterParam?.publishedDateIncludesNull) }">
                  <label *ngIf="copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDateIncludesNull || copyAdvancedFilterParam?.publishedDatePrefix"
                    class="search-resultlbl">{{ 'SearchResult.PublicationDate' | translate }} </label>
                  <span>
                    <label *ngIf="copyAdvancedFilterParam?.publishedDateFrom || copyAdvancedFilterParam?.publishedDateIncludesNull || copyAdvancedFilterParam?.publishedDatePrefix">
                      <span class="badge margin-left5 select-qry-pub-date" style="min-width: 100px;">
                        <p class="pubdate-p">
                          <span class="">
                            <span *ngIf="copyAdvancedFilterParam?.publishedDateFrom !== 'All'">{{copyAdvancedFilterParam?.publishedDatePrefix}}
                              <span *ngIf="copyAdvancedFilterParam?.publishedDatePrefix !== 'All'">:</span>
                            </span>
                            {{ copyAdvancedFilterParam?.publishedDateFrom}}
                            <span *ngIf="copyAdvancedFilterParam?.publishedDateTo">{{ 'Common.AND' | translate }}</span>

                            <span *ngIf="copyAdvancedFilterParam?.publishedDateTo.includes('-') && copyAdvancedFilterParam?.publishedDateTo.length > 5 && copyAdvancedFilterParam?.publishedDateTo.length > 8">
                              {{copyAdvancedFilterParam?.publishedDateTo}}
                            </span>
                            <span class="2" *ngIf="copyAdvancedFilterParam?.publishedDateTo.length === 5">
                              {{copyAdvancedFilterParam?.publishedDateTo.slice(0,4)}}
                            </span>
                            <span *ngIf="copyAdvancedFilterParam?.publishedDateTo.length < 5">
                              {{copyAdvancedFilterParam?.publishedDateTo}}
                            </span>
                            <span class="4" *ngIf="copyAdvancedFilterParam?.publishedDateTo.length === 8">
                              {{copyAdvancedFilterParam?.publishedDateTo.slice(0,7)}}
                            </span>
                          </span>
                          <span *ngIf="copyAdvancedFilterParam?.publishedDateIncludesNull">&nbsp;
                            <span class="pub_date_operator">{{ 'Common.AND' | translate }}</span>&nbsp;
                            <span class="pub_date">{{ 'Common.UNDATED' | translate }}</span> &nbsp;
                          </span>
                          <span class="fa fa-times no-float close-icon" (click)="removeLimiterParams('publishedDate')"
                            style="position: relative;padding-left: 8px;"></span>
                        </p>
                      </span>
                    </label>
                  </span>
                </li>
              </ul>
            </ul>
          </ul>
        </div>
            <input aria-label="Search Maps" name="docSearch" placeholder="{{'explorer.searchMaps' | translate}}"
            autofocus title="{{toggleFullText === true ? searchWithFullText || 'Broaden search by including text within Maps' : searchWithoutFullText || 'Search within metadata only' }}" type="text" id="search_textbox" class="form-control search_textboxCls"
              #searchTermValue [(ngModel)]="searchTerm" autocomplete="off">
            <div class="input-group-btn widthAuto">
                <button (click)="getMapExplorerData(searchTerm);" [class.disabled]="isSearchBtnEnable" class="btn btn-primary mapExplrElementHeight"
                id="simpleSearchBtn" type="submit" title="Search">
                <i class="fa fa-search"></i>
                <span class="sr-only">{{ 'Common.Search' | translate }}</span>
              </button>
              <button class="btn btn-primary btn-adv-search explorer-button" (click)="onShown()">{{'HomePage.limit'|
                translate}}</button>
              <!-- <a class="btn btn-default pad-2" title="{{'explorer.mapTitle' | translate}}" (click)="expandMapExplorer()">
  <span class="fa fa-globe fa-2x primary-text-color map-marker"></span>
              </a> -->

            </div>
          </div>
          <ng-template #filterTemplate>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <span class="closeIcon"><i (click)="closeLimitPopup()" class="fa fa-window-close" aria-hidden="true"></i></span>
                <app-text-analysis-limiter
                  [isFromPhotoExplorer]="isFromPhotoExplorer"
                  [limitLabel]="'Common.limit' | translate"
                  (advancedFilterParamOut)="receiveAdvancedParam($event)"
                  [popupRef]="popupRef"
                  (saveDone)="closeLimitPopup($event)"
                  (limiterChanged) = "fetchResultCount($event)"
                  [estimatedCount] = "estimatedCount"
                  [showLoadingTxt] = "showLoadingTxt"
                  (messageEvent)="receiveAdvanceParam($event)"
                  [isFromPage] = "isFromPage"
                ></app-text-analysis-limiter>
              </div>
            </div>
          </ng-template>
        </form>
      <!-- </div> -->
      <div class="col-sm-12 mapViewContianer">

        <div class="page-info col-md-12 col-xs-12 col-sm-12">
          <div *ngIf="isMapsExplorerExapnd" class="display-text-leaflet">
            <div class="display-text-contet">{{ 'photoExplorer.clickOnThe' | translate}} <i class="marker-icon-map geo-coordinate-icon-head"></i> {{ 'photoExplorer.locate' | translate}}
            <!-- <div class="btn-group">
                <label class="btn btn-primary"  ngDefaultControl   [(ngModel)]="checkModel.left"
                       btnCheckbox tabindex="0" role="button"><i class="marker-icon-map fa fa-map-marker fa-2x"></i></label>
                <label class="btn btn-primary" ngDefaultControl  [(ngModel)]="checkModel.middle"
                       btnCheckbox tabindex="0" role="button"><i class="marker-icon-map fa fa-map-marker fa-2x"></i></label>
              </div> -->
              <!-- <div class="btn-group filterDoc">
                  <button class="btn" ><i class="marker-icon-map fa fa-map-marker fa-2x"></i></button>
                  <button class="btn selected"><i class="marker-icon-map fa fa-map-marker fa-2x"></i></button>
              </div> -->

              <!-- <label class="wiley-admin-text check-container">
                <input type="checkbox" class="disable-label" [ngModel]="toggleFullText"
                  (change)="searchIncludedFulltext()" name="active">
                <span [class.arabic-style]="isArabicLanguage"
                  class="fuzzy-text">Search with Fulltext</span>
                <span class="checkmark"></span>
              </label> -->

             <div class="switch-mobile-view">
                <label class="switch">
                  <input type="checkbox" [checked]="showMap" (change)="toggle($event)">
                  <span class="slider round">
                    <i title="{{'explorer.showMapCoorDocTitle' | translate}}" *ngIf="showMap" class="marker-icon-active"></i>
                    <span title="{{'explorer.showAll' | translate}}" class="infoTxt" *ngIf="!showMap">
                      <!-- <i class="marker-icon-map fa fa-map-marker fa-2x mapOff" style="color: #4c4747 !important;"></i> -->
                      <i class="marker-icon-toggle" style="color: #4c4747 !important;"></i>
                    </span>
                  </span>
                </label>
                <div class="fulltext-section">
                  <label class="fulltext-switch">
                    <input type="checkbox" class="disable-label" [ngModel]="toggleFullText" (change)="searchIncludedFulltext()" name="active">
                    <span class="fulltext-slider fulltext-round" title="{{toggleFullText === true ? searchWithFullText || 'Broaden search by including text within Maps' : searchWithoutFullText || 'Search within metadata only' }}">
                      <span class="slider-info-on" [class.on-btn]="toggleFullText" *ngIf="toggleFullText"> <i class="fa fa-list-alt color-on" aria-hidden="true"></i></span>
                      <span class="slider-info-off" [class.on-btn]="!toggleFullText" *ngIf="!toggleFullText"> <i class="fa fa-list-alt color-off" aria-hidden="true"></i> </span>
                    </span>
                  </label>
                </div>
             </div>
            </div>

          </div>

        <div class="recordsPanel pull-left" [ngClass]="{'col-md-3':(showDocView && expandLevelOne && !expandLevelTwo), 'col-md-11': (showDocView && expandLevelOne && expandLevelTwo)}" >
           <div class="exploreMap padding0" [ngClass]="{'col-md-12':(showDocView && expandLevelOne)}">
            <div class="col-xs-8 col-md-8 text-left padding0"  *ngIf="(showDocView && expandLevelOne) ">
             <span class="total-rec-cls" *ngIf="(totalRecoards > 0) && showDocView; else noResultFound"><strong  class="primary-text-color">{{totalRecoards}}</strong> {{'explorer.mapPages' | translate}} - (Including Inset maps)</span>
              <ng-container *ngIf="!loading && _albums.length === 0 || !loading && totalRecoards === 0">
                <span class="text-center primary-text-color customCls">
                  <strong>{{'explorer.noResults' | translate}}</strong>
                </span>
              </ng-container>
              <ng-container *ngIf="loading && _albums.length === 0">
                <span class="text-center primary-text-color">
                  <strong>{{ 'explorer.loading' | translate}}</strong>
                </span>
              </ng-container>
            </div>
            <div class="btn-group-cls" *ngIf="totalRecoards > 0" [ngClass]="{'col-md-4 pull-right text-right':(showDocView && expandLevelOne),'col-md-12' :(showDocView && !expandLevelOne)}">
              <button class="btnLeft" title="{{'explorer.Collapse' | translate}}" (click)="showLess()" *ngIf=" (showDocView && expandLevelOne) || (showDocView && expandLevelOne && expandLevelTwo)"><i _ngcontent-c21="" aria-hidden="true" class="fa-2x fa fa-angle-left"></i></button>
              <button class="btnRight"  title="{{'explorer.Expand' | translate}}"   [ngClass]="{'forRightBtn':(showDocView && !expandLevelOne)}"  (click)="showMore()" *ngIf="(showDocView && !expandLevelOne) ||  (showDocView && expandLevelOne && !expandLevelTwo && isMoreRecords)"><i _ngcontent-c21="" aria-hidden="true" class="fa-2x fa fa-angle-right"></i>   </button>
              </div>
            </div>
            <div [style.display]="(expandLevelOne && showDocView) ? 'block' : 'none'" id="thumbnailImageWrapper2" (click)="resultWrapperScrollHandler($event)" class="photo_img"
            [ngClass]="{'col-md-12 col-sm-12 col-lg-12': !isMapsExplorerExapnd, 'col-xs-12 col-md-12 col-sm-12 col-lg-12 viewport-max-height':isMapsExplorerExapnd}" 
            [style.max-height]="isMapsExplorerExapnd ? '56vh' : ''">
        <!-- <perfect-scrollbar [style.display]="(expandLevelOne && showDocView) ? 'block' : 'none'" id="thumbnailImageWrapper2" (psScrollDown)="resultWrapperScrollHandler($event)" class="photo_img "
[ngClass]="{'col-md-12 col-sm-12 col-lg-12': !isMapsExplorerExapnd, 'col-xs-12 col-md-12 col-sm-12 col-lg-12
viewport-max-height':isMapsExplorerExapnd}"
          [style.max-height]="isMapsExplorerExapnd ? '56vh' : ''"> -->
          <!-- {{_albums | json}} -->
          <ng-container *ngFor="let image of _albums; let i = index">
            <div class="flt_lt" [ngClass]="{'col-md-12 col-sm-12 col-xs-12 col-lg-12': !isMapsExplorerExapnd, 'col-md-12 col-sm-12 col-xs-12':isMapsExplorerExapnd ,'col-md-4':(expandLevelOne && showDocView && expandLevelTwo)}">
              <div class="photo-exp-div">
                <img *ngIf="image?.thumb" (click)="gotoDocumentDetails(image.docId, image.pageId, i)" title="{{image?.title}}" name='Document-image' class="accordian-img img-responsive center-block"
                  alt="Document Image" [defaultImage]="defaultImage"  
                  [lazyLoad]="image?.thumb" [errorImage]="errorImage">
<i class="geo-coordinate-icon" title="{{ 'photoExplorer.showOnMap' | translate }}" (click)="zoomToMap(image.coordinates, image.pageId, image['highlightSelected']);$event.stopPropagation();"
                  [class.active-globe-icon]="image['highlightSelected']" *ngIf="image?.coordinates?.length > 0"></i>
                  
                  <img *ngIf="image?.processType === 'drone'" alt="Drone Icon" class="atr-list-img-icon" src="assets/images/icons/Drone1.png" tooltip="This large-format map was captured by a drone." placement="right">
                  
                <i *ngIf="image?.hasInsetMaps " class="insetmap-icon" title="Preview map and inset(s)" (click)="openClipedImage(i, image);$event.stopPropagation();"></i>
                <i *ngIf="!image?.hasInsetMaps " class="view-icn" title="{{ 'photoExplorer.previewMap' | translate }}" (click)="openClipedImage(i, image);$event.stopPropagation();"></i>
              </div>
            </div>
          </ng-container>
          <div *ngIf="totalRecoards !== _albums?.length && _albums?.length>0" class="col-md-12 col-xs-12 col-sm-12 text-center clearFix mar-btm-20">
            <span *ngIf="loadMoreLoader" class="glyphicon glyphicon-repeat fast-right-spinner"></span>
            <div (click)="loadMoreMaps()" class="viewMoreContent" *ngIf="!loadMoreLoader">
              <div class="glyphicon glyphicon-menu-down loadMoreResultsArrow"></div>
              <div [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.ViewMore' | translate }}</div>
            </div>
          </div>
        <!-- </perfect-scrollbar> -->
      </div>
      </div>
    </div>
        <div *ngIf="isMapsExplorerExapnd" class="maps-block viewport-height" [ngClass]="{'col-md-12 col-xs-12 col-sm-12':!showDocView}">
          <div class="viewport-height">
            <app-map [resetMapData]="resetMapData" [valueField]="'count'" (deleteEvent)="receiveDelete($event)" (spatialSearchEmits)="onSpatialSearchEmit($event)" (messageToMapExplorer)="receiveMessage($event)"
[resultCount]="{value: totalRecoards}" [componentName]="'Map'" [zoomToCoOrdinates]="_coordinatesToZoom"
[removeDropPin]="_removeDropPin"></app-map>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 slider-control term-frequency_slider no-print">
          <app-range-slider [fromMapView]="true" [value]="rangeArray" [minValue]="minYearValue" (RangeChanged)="sliderRangeChanged($event)"
          [maxValue]="maxYearValue" [stepValue]="1" [range]="true"></app-range-slider>
          <!-- <span *ngIf="yearFrom && yearTo">{{yearFrom}} - {{yearTo}}</span> -->
          <div class="sliderTxt" *ngIf="yearFrom && yearTo">
              <div>{{minYearValue}}</div>
              <div class="text-center" *ngIf="showLimit">{{'explorer.LimitFrom' | translate}}{{yearFrom}} {{'explorer.to' | translate}} {{yearTo}}</div>
              <div class="text-center" *ngIf="limitRange">
                  {{'explorer.Limit' | translate}} {{this.copyAdvancedFilterParam.publishedDatePrefix | titlecase }} {{yearLimit}}
              </div>
              <div class="text-right">{{maxYearValue}}</div>
            </div>
        </div>
    </div>
    <button [ngStyle]="{'display': !hightScrollTop ? 'none': 'block'}" class="btn btn-primary scrollTopPage" (click)="scrollTopPage()"
      title="{{'SearchResult.GoToTop' | translate}}">
      <span class="spriteico topPageIcon"></span>
      <span class="sr-only">{{'Common.scrollTopPage' | translate}}</span>
    </button>
  </div>
</div>


<ng-template #photoExplorerTemplate>
  <div class="modal-body photoexplorer-body">
    <div class="photoexplorer-popupbody">
      <button type="button" class="modal-close-button close pull-right photoexplorer-close-btn" aria-label="Close"
        (click)="closePopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="photoexp-titles-div">
        <a class="photoexplorer-title primary-text-color" title="{{previewImageObj?.title}}" appDecodeEntities><span class="backToSearchResultLink"
          (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')" appDecodeEntities>{{previewImageObj?.title}}</span></a>
        <div class="photoexplorer-document-title explorer-doc-parent" title="{{previewImageObj?.documentTitle || 'Document Title'}}">
          <div class="explorer-doc-left">
            <span class="document-from-text"> {{'Detail.from' | translate}} </span>
            <a class="primary-text-color text-ellipsis">
              <span class="backToSearchResultLink" (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')" appDecodeEntities>
                {{previewImageObj?.documentTitle}}</span></a>
          </div>
          <div class="explorer-doc-right">
            <button class="btn btn-xs btn-primary pull-right" (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')">{{'explorer.view' | translate}}
              <span class="spriteico documentview-icon"></span> {{'explorer.document' | translate}}
            </button>
            <button class="btn btn-xs btn-primary pull-right citation-btn" id="documentCitation" title="{{ 'Detail.Citation' | translate }}"
              (click)="getCitationDetailsFromDocumnentData(previewImageObj?.docId, 'fromModal')">
              <span class="spriteico citation-icon" id="documentCitation"></span>{{ 'Detail.Citation' | translate }}
            </button>
          </div>
          <div (mouseover)="getAccessToCitation()" (mouseenter)="getAccessToCitation()" class="citation-data-container" [class.show-citationContainer]="citationContainer" (click)="$event.stopPropagation();">
          <div class="citation-scrollbar">
            <!-- <perfect-scrollbar [config]="config" class="citation-scrollbar"> -->
              <div class="col-md-12 col-sm-12 padding-left-0 padding-top15 citation-container" >
                  <p class="loading-text" *ngIf="!isCitationLoading"> {{ 'SearchResult.loadingPleaseWait' | translate}} </p>
                  <div *ngIf="documentCitation?.title">
                  <div class="col-md-7 col-sm-7 citation-section">
                      <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.documentTitle' | translate }}</label>
                          <h4 class="citation-content" appDecodeEntities>{{documentCitation?.title}}</h4>
                        </div>
                        <div *ngIf="(documentCitation?.authors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Authors' | translate }}</label>
                          <div class="author-containor author-citation-div">
                            <ng-container *ngFor="let author of documentCitation?.authors; let last = last;">
                              <a class="citation-content author-citation author-citation-link" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                                <span class="author-comma" *ngIf="!last">, </span>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div *ngIf="(documentCitation?.itemAuthors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{'Detail.NameReferences' | translate }}</label>
                          <div class="author-containor">
                            <ng-container *ngFor="let author of documentCitation?.itemAuthors; let last = last;">
                              <a class="citation-content author-citation" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                                <span class="author-comma" *ngIf="!last">, </span>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.pubDate !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'HomePage.publicationDate' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.pubDate}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Language' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.languages}}</h4>
                        </div>
                  </div>
                  <div class="col-md-5 col-sm-5 citation-section">
                      <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.collection !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Collection' | translate }} </label>
                          <a class="citation-content citation-link" (click)="gotoCollectionDetails(documentCitation?.collectionNumber)">{{documentCitation?.collection}}</a>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Common.ContentType' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.docType}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Source' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.source}}</h4>
                        </div>
                        <!-- <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.findingAidNumber !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl"> {{ 'Detail.findingAidNumber' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.findingAidNumber}}</h4>
                        </div> -->
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.findingAidNumber !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.wileyDocumentNumber' | translate }}</label>
                          <a class="citation-content wiley-doc-link" (click)="gotoDocumentDetails(documentCitation?.wileyId, previewImageObj.pageId, activeSlideIndex)">{{documentCitation?.wileyId}}</a>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.manuscriptNo">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.referenceNo' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.manuscriptNo}}</h4>
                        </div>
                  </div>

                </div>
              </div>
            <!-- </perfect-scrollbar> -->
          </div>
          </div>
        </div>
      </div>
      <div id="explorerOpenSeaDragonCanvas">
          <app-basic-image-viewer [previewImageURL]="previewImageObj?.image" [tileSource]="tilesourceObj" (toggleNavigation)="toggleNavigation($event)"
          [disableNavigationButtons]="disableNavigations" (getViewMoreOptionStatus)="viewMoreOptionStatus($event)"></app-basic-image-viewer>
      </div>

      <div class="photoexplorer-captions toggledDisclaimer disclaimer-div" *ngIf="previewImageObj?.captions" [class.toggledDisclaimer]="toggledisclaimerShow">
        <!-- <b [class.arabic-style]="isArabicLanguage">{{'explorer.captions' | translate}}</b> {{previewImageObj?.captions}} -->
        <span tooltip="{{'explorer.captions' | translate}} {{previewImageObj?.captions}}" [tooltipDisabled]="false" [tooltipAnimation]="true" tooltipPlacement="top"><b [class.arabic-style]="isArabicLanguage">{{'explorer.captions' | translate}}</b> {{previewImageObj?.captions}}</span>
        <div [class.arabic-style]="isArabicLanguage" class="disclaimer-toggle" (click)="disclaimerToggle()" *ngIf="previewImageObj?.captions.length > 170">
          <span *ngIf="toggledisclaimerShow">{{ 'HomePage.viewMore' | translate }} &nbsp;<i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-down" title="{{ 'HomePage.viewMore' | translate }}"
            aria-hidden="true"></i></span>
          <span *ngIf="!toggledisclaimerShow">{{ 'HomePage.viewLess' | translate }} &nbsp;<i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-up" title="{{ 'HomePage.viewLess' | translate }}"
            aria-hidden="true"></i></span>
        </div>
      </div>
      <!-- <div class="photoexplorer-captions" *ngIf="previewImageObj?.captions?.length > 0">
        {{'explorer.captions' | translate}}
        <span #textContent id="read-more{{message | async}}">
          {{previewImageObj?.captions}}
        </span>
        <div class="read-more-link">
            <a [class.arabic-style]="isArabicLanguage" role="button" readMore [readMore-length]="350" [readMore-element]="textContent"
              class="">
              <span [class.arabic-style]="isArabicLanguage" class="less pull-right" title="{{ 'HomePage.viewLess' | translate }}">
                <span>{{ 'HomePage.viewLess' | translate }}</span>
                <i class="btn-up-arrow no-margin-right fa fa-angle-up vertical-align-middle" aria-hidden="true"></i>
              </span>
              <span [class.arabic-style]="isArabicLanguage" class="more pull-right" title="{{ 'HomePage.viewMore' | translate }}">
                <span>{{ 'HomePage.viewMore' | translate }}</span>
                <i class="btn-down-arrow no-margin-right fa fa-angle-down vertical-align-middle" aria-hidden="true"></i>
              </span>
            </a>
          </div>
      </div> -->
    </div>
  </div>
</ng-template>



<ng-template #photoExplorerInsetTemplate>
  <div class="modal-body photoexplorer-body">
    <div class="photoexplorer-popupbody">
      <button type="button" class="modal-close-button close pull-right photoexplorer-close-btn" aria-label="Close"
        (click)="closeInsetPopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="photoexp-titles-div">
        <a class="photoexplorer-title primary-text-color" title="{{previewImageObj?.title}}" appDecodeEntities><span class="backToSearchResultLink"
          (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')" appDecodeEntities>{{previewImageObj?.title}}</span></a>
        <div class="photoexplorer-document-title explorer-doc-parent" title="{{previewImageObj?.documentTitle || 'Document Title'}}">
          <div class="explorer-doc-left">
            <span class="document-from-text"> {{'Detail.from' | translate}} </span>
            <a class="primary-text-color text-ellipsis">
              <span class="backToSearchResultLink" (click)="gotoDocumentDetailsPage(currentDocId, previewImageObj.pageId)" appDecodeEntities>
                {{previewImageObj?.documentTitle}}</span></a>
          </div>
          <div class="explorer-doc-right">
            <button class="btn btn-xs btn-primary pull-right" (click)="gotoDocumentDetailsPage(currentDocId, previewImageObj.pageId)">{{'explorer.view' | translate}}
              <span class="spriteico documentview-icon"></span> {{'explorer.document' | translate}}
            </button>
            <button class="btn btn-xs btn-primary pull-right citation-btn" id="documentCitation" title="{{ 'Detail.Citation' | translate }}"
              (click)="getCitationDetailsFromDocumnentData(previewImageObj?.docId)">
              <span class="spriteico citation-icon" id="documentCitation"></span>{{ 'Detail.Citation' | translate }}
            </button>
          </div>
          

          <div (mouseover)="getAccessToCitation()" (mouseenter)="getAccessToCitation()" class="citation-data-container" [class.show-citationContainer]="citationContainer" (click)="$event.stopPropagation();">
            <div class="citation-scrollbar">
            <!-- <perfect-scrollbar [config]="config" class="citation-scrollbar"> -->
              <div class="col-md-12 col-sm-12 padding-left-0 padding-top15 citation-container" >
                  <p class="loading-text" *ngIf="!isCitationLoading"> {{ 'SearchResult.loadingPleaseWait' | translate}} </p>
                  <div *ngIf="documentCitation?.title">
                  <div class="col-md-7 col-sm-7 citation-section">
                      <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.documentTitle' | translate }}</label>
                          <h4 class="citation-content" appDecodeEntities>{{documentCitation?.title}}</h4>
                        </div>
                        <div *ngIf="(documentCitation?.authors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Authors' | translate }}</label>
                          <div class="author-containor author-citation-div">
                            <ng-container *ngFor="let author of documentCitation?.authors; let last = last;">
                              <a class="citation-content author-citation author-citation-link" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                                <span class="author-comma" *ngIf="!last">, </span>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div *ngIf="(documentCitation?.itemAuthors?.length > 0)" class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{'Detail.NameReferences' | translate }}</label>
                          <div class="author-containor">
                            <ng-container *ngFor="let author of documentCitation?.itemAuthors; let last = last;">
                              <a class="citation-content author-citation" title="{{author}}" (click)="gotoAuthorDetail(author)">{{author}}
                                <span class="author-comma" *ngIf="!last">, </span>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.pubDate !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'HomePage.publicationDate' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.pubDate}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Language' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.languages}}</h4>
                        </div>
                  </div>
                  <div class="col-md-5 col-sm-5 citation-section">
                      <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.collection !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Collection' | translate }} </label>
                          <a class="citation-content citation-link" (click)="gotoCollectionDetails(documentCitation?.collectionNumber)">{{documentCitation?.collection}}</a>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Common.ContentType' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.docType}}</h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.Source' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.source}}</h4>
                        </div>
                        <!-- <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.findingAidNumber !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl"> {{ 'Detail.findingAidNumber' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.findingAidNumber}}</h4>
                        </div> -->
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.findingAidNumber !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.wileyDocumentNumber' | translate }}</label>
                          <a class="citation-content wiley-doc-link" (click)="gotoDocumentDetails(documentCitation?.wileyId, previewImageObj.pageId, activeSlideIndex)">{{documentCitation?.wileyId}}</a>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentCitation?.manuscriptNo">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ 'Detail.referenceNo' | translate }}</label>
                          <h4 class="citation-content">{{documentCitation?.manuscriptNo}}</h4>
                        </div>
                  </div>

                </div>
              </div>
            <!-- </perfect-scrollbar> -->
          </div>
          </div>

        </div>
      </div>
      
      
      <div class="photo-inset-body col-md-12 inset-photos">
              <div class="inset-img"  *ngFor="let data of insetMapArray">
                <img class="insetimg center-block img-responsive" (click)="getInsetmapPage(data)" alt="Inset image" [attr.src]="data.image" />
              </div>
      </div>

      <div class="photoexplorer-captions toggledDisclaimer disclaimer-div" *ngIf="previewImageObj?.captions" [class.toggledDisclaimer]="toggledisclaimerShow">
        <!-- <b [class.arabic-style]="isArabicLanguage">{{'explorer.captions' | translate}}</b> {{previewImageObj?.captions}} -->
        <span tooltip="{{'explorer.captions' | translate}} {{previewImageObj?.captions}}" [tooltipDisabled]="false" [tooltipAnimation]="true" tooltipPlacement="top"><b [class.arabic-style]="isArabicLanguage">{{'explorer.captions' | translate}}</b> {{previewImageObj?.captions}}</span>
        <div [class.arabic-style]="isArabicLanguage" class="disclaimer-toggle" (click)="disclaimerToggle()" *ngIf="previewImageObj?.captions.length > 170">
          <span *ngIf="toggledisclaimerShow">{{ 'HomePage.viewMore' | translate }} &nbsp;<i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-down" title="{{ 'HomePage.viewMore' | translate }}"
            aria-hidden="true"></i></span>
          <span *ngIf="!toggledisclaimerShow">{{ 'HomePage.viewLess' | translate }} &nbsp;<i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-up" title="{{ 'HomePage.viewLess' | translate }}"
            aria-hidden="true"></i></span>
        </div>
      </div>
      
    </div>
  </div>
</ng-template>



<ng-template #insetMapPageTemplate>
  <div class="modal-body photoexplorer-body">
    <div class="photoexplorer-popupbody">
      <button type="button" class="modal-close-button close pull-right photoexplorer-close-btn" aria-label="Close"
        (click)="closeInsetmapPopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="photoexp-titles-div">
        <a class="photoexplorer-title primary-text-color" title="{{insetMapData?.illustrationTitles[0]}}" appDecodeEntities><span class="backToSearchResultLink"
          (click)="gotoDocumentDetails(previewImageObj.docId, previewImageObj.pageId, activeSlideIndex, 'fromModal')" appDecodeEntities>{{insetMapData?.illustrationTitles[0]}}</span></a>
        
        <div class="maps-explorer-doc-right">
            <button class="btn btn-xs btn-primary pull-right" (click)="gotoDocumentDetailsPage(currentDocId, insetMapPage?.pageId)">{{'explorer.view' | translate}}
              <span class="spriteico documentview-icon"></span> {{'explorer.document' | translate}}
            </button>
          </div>

      </div>
      <div id="explorerOpenSeaDragonCanvas">
          <app-basic-image-viewer [previewImageURL]="insetMapPage?.image" [tileSource]="tilesource" (toggleNavigation)="toggleNavigation($event)"
          [disableNavigationButtons]="disableNavigations" (getViewMoreOptionStatus)="viewMoreOptionStatus($event)"></app-basic-image-viewer>
      </div>

      
      
    </div>
    <div class="photoexplorer-captions toggledDisclaimer disclaimer-div">
    </div>
  </div>
</ng-template>