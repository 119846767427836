<div (mouseover)="imageViewerMouseEnter()" (mouseleave)="imageViewerMouseLeave()">
  <!-- <div class="image-split" >
    <div class="btn-group">
      <button type="button" class="btn btn-primary frontImage">Front Image</button>
      <button type="button" class="btn btn-primary">Back Image</button>
    </div>
  </div> -->
  <div class="floating-toolbar-menu" *ngIf="showFloatingMenu">
    <li class="reset-button image-viewvew-list" (click)="resetImage()">
      <span class="spriteico resetIcon imageviewer-float-image" title="{{ 'Detail.reset' | translate }}"></span>
    </li>
    <li class="image-viewer-flip-list  image-viewvew-list" title="{{ 'Detail.rotateLeft' | translate }} ({{shortcutKeyTitle}} + Left Arrow)" (click)="rotateImage(-1)">
      <span class="spriteico rotateleft-flipicon imageviewer-float-image"></span>
    </li>
    <li class="image-viewer-flip-list  image-viewvew-list" title="{{ 'Detail.rotateRight' | translate }} ({{shortcutKeyTitle}} + Right Arrow)" (click)="rotateImage(1)">
      <span class="spriteico rotateright-flipicon  imageviewer-float-image"></span>
    </li>
    <li class="image-viewer-flip-list" id="image-viewer-zoom-in" title="{{ 'Detail.zoomIn' | translate }} ({{shortcutKeyTitle}} + '=')" (click)="zoomInImage(0.1)">
      <span class="spriteico zoomFlipIcon imageviewer-float-image"></span>
    </li>
    <li class="image-viewer-flip-list" id="image-viewer-zoom-out" title="{{ 'Detail.zoomOut' | translate }} ({{shortcutKeyTitle}} + '_')" (click)="zoomOutImage(0.1)">
      <span class="spriteico zoomout-flipicon imageviewer-float-image"></span>
    </li>
  </div>
  <a class="right carousel-control carousel-control-next image-nav-btn" (click)="gotoNextImage()" title="{{'Detail.next'  | translate}}" *ngIf="showFloatingMenu && !disableNextBtn">
    <span class="icon-next carousel-control-next-icon control-border"></span>
    <span class="sr-only">{{'Detail.next'  | translate}}</span>
  </a>
  <a class="left carousel-control carousel-control-prev image-nav-btn" (click)="gotoPreviousImage()" title="{{'Detail.previous'  | translate}}" *ngIf="showFloatingMenu && !disablePreviousBtn">
    <span class="icon-prev carousel-control-prev-icon control-border"></span>
  </a>
  <div id="explorerOpenSeaDragon" style="width:100%; height:70vh; margin:0px auto; background-color: #272626">
    <div class="image-viewer-frame-loader" [ngStyle]="{display:imageviewerframeLoader ? 'block':'none'}">
      <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
    </div>
  </div>
</div>
