/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'replaceDate'
})
export class ReplaceDate extends DatePipe implements PipeTransform {
    transform(value: string | number | Date, timezone: string = null): any {
    // transform(value: string): string {
        if (value) {
            return super.transform(value, `${super.transform(new Date().toDateString(), timezone)}`);
            // return value.replace(/MMYYDD/g, `${super.transform(new Date().toDateString(), 'dd MMM. yyyy.')}`);
        } else {
            return value;
        }
    }
}
