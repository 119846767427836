
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, HostListener, ViewChild, Input, ElementRef, ChangeDetectorRef, TemplateRef, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { SearchService } from '../common/services/search.service';
import { Router, ActivatedRoute, NavigationEnd } from '../../../node_modules/@angular/router';
import { SharedService } from '../common/services/sharedService';
import { Subject, Subscription } from '../../../node_modules/rxjs';
import { PageSettingsService } from '../common/services/page-settings.service';
import { orderBy as _orderBy } from 'lodash';
import { UrlUtilityService } from '../common/services/url-utility.service';
import { AdvancedFilterParams } from '../common/models/AdvancedFilterParams';
import { ModalService } from '../common/services/modal.service';
import { TranslateService } from '../../../node_modules/@ngx-translate/core';
import { HttpService } from '../common/services/http.service';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
	selector: 'app-findpages-download-content',
	templateUrl: './findpages-download-content.component.html',
	styleUrls: ['./findpages-download-content.component.css']
})
export class FindpagesDownloadContentComponent implements OnInit, AfterViewInit, OnDestroy {
	private downloadPDFmodalRef: BsModalRef;
	// @ViewChild('downloadDocsTemplate') private downloadDocsTemplate: TemplateRef<any>;
	@ViewChild('downloadDocsTemplate', { static: false }) private downloadDocsTemplate: TemplateRef<any>;

	private stop$: Subject<any> = new Subject();
	private togglesearchicon: Array<boolean>;
	documentList: any;
	documentIdList: any = [];
	contentTypeOrder: any[];
	downloadDocs: any[];
	isArabicLanguage: Boolean = false;
	isPhotograph: Boolean = false;
	isMap: Boolean = false;
	enableSingleList: any;
	pageSettings;
	private personalizationObject: any;
	resultDataLoading: Boolean = false;
	hightScrollTop: any;
	lastScrollTop = 0;
	scrollDisplay: any;
	// @ViewChild('getScrollElement') scrollElement: ElementRef;
	// @ViewChild('downloadTableTemplate') private downloadTableTemplate: TemplateRef<any>;
	@ViewChild('getScrollElement', { static: false }) scrollElement: ElementRef;
	@ViewChild('downloadTableTemplate', { static: false }) private downloadTableTemplate: TemplateRef<any>;

	@Output('overlayClicked') private overlayClicked: EventEmitter<any>;
	public _isInMapView: Boolean = false;
	loadMoreLoader = false;
	specialParams: any;
	docIdForDocViewerObj: any;
	searchTotalCount: any;
	pageContentData: any;
	searchValue: any;
	fieldVal: boolean;
	searchKeyAry: any;
	contentTypesParam = [];
	searchItems: any;
	eventList: any =[];
	routeSub: Subscription;
	activerouteSub: Subscription;

	@Input('docIdForDocViewer')
	set docIdForDocViewer(val: any) {
		this.docIdForDocViewerObj = val;
	}
	isDocumentViewer = false;
	photographView: Boolean;
	showGridView: boolean;
	currentView: String;
	dropdownToggleValue: any;
	documentEnable: Boolean = false;
	groups: any;
	toggleInternalShareList: Boolean = false;
	count = 0;
	noResultInMap: Boolean = false;
	accessToken: any;
	resultLoader = false;
	contentTypeList = [];
	totalGroupCount: any;
	translatedValues: any;


	constructor(private searchService: SearchService,
		private sharedService: SharedService,
		private activatedRoute: ActivatedRoute,
		private urlUtilityService: UrlUtilityService,
		private router: Router,
		private elementReference: ElementRef,
		private httpService: HttpService,
		private modalService: ModalService,
		private popupService: ModalService,
		private location: Location,
		private bsModalService: BsModalService,
		private ref: ChangeDetectorRef,
		private pageSettingService: PageSettingsService,
		private translate: TranslateService
	) {
		const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
		const shortNames = [];
		// if (personalizationData.downloadContentArchives.length > 0) {
		// 	for (let k = 0; k < personalizationData.downloadContentArchives.length; k++) {
		// 		shortNames.push(personalizationData.downloadContentArchives[k].shortName);
		// 	}
		// 	localStorage.setItem('wileyDCSelectedArchive', JSON.stringify(shortNames));
		// 	sessionStorage.setItem('wileyDCSelectedArchive', JSON.stringify(shortNames));
		// }
		// SearchService.facetObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
		// 	console.log(data)
		// 	for (const obj of data) {
		// 		if (obj.facetField === 'contentType') {
		// 			this.contentTypesParam = obj.facetContent;
		// 		}
		// 	}
		// });
		this.togglesearchicon = [];
		let filterParams = null;
		let displayParams = null;
		this.accessToken = this.httpService.getCurrentAccessToken();
		PageSettingsService.pageSettingsObservable.subscribe((data) => {
			if (data.advancedFilterParam.searchKeywords) {
				data.advancedFilterParam.searchKeywords.forEach((obj, index) => {
					if (!obj.fieldValue) {
						data.advancedFilterParam.searchKeywords.splice(index, 1);
					}
				});
			}
			this.pageContentData = data;
			if (this.pageContentData.searchTerm) {
				if (this.pageContentData.searchTerm.includes('-') > -1) {
				}
			}
			if (this.pageContentData.advancedFilterParam.searchKeywords) {
				this.pageContentData.advancedFilterParam.searchKeywords.forEach((obj, index) => {
					if (!obj.fieldValue) {
						this.pageContentData.advancedFilterParam.searchKeywords.splice(index, 1);
					}
				});
			}

			const searchKey = this.pageContentData.advancedFilterParam.searchKeywords;
			searchKey.forEach(element => {
				this.searchValue = element;
				if (this.searchValue.fieldValue !== '') {
					this.fieldVal = true;
					if (this.searchKeyAry.indexOf(this.searchValue.fieldValue) > -1) {

					} else {
						this.searchKeyAry.push(this.searchValue.fieldValue);
					}
				} else {
					this.fieldVal = false;
				}
			});
		});
		PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
			this.pageSettings = data;
			this.pageSettings.availableFields = _orderBy(this.pageSettings.availableFields, ['itemName'], ['asc']);
		});

		this.sharedService.setSearchLoading(true);
		// this.router.events.subscribe(events => {
		this.routeSub = this.router.events.pipe(takeUntil(this.stop$)).subscribe((events) => {
			// if (events instanceof NavigationEnd) {
				this.activerouteSub =  this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
					if (params['filter']) {
						filterParams = params['filter'];
					}
					if (params['display']) {
						displayParams = params['display'];
						this.pageSettingService.updateSetting('groupsToBeDisplayed', [params['display']]);
					}
					if (params['order']) {
						this.contentTypeOrder = params['order'];
					}
					if (filterParams) {

						this.urlUtilityService.parseUrl(filterParams).pipe(takeUntil(this.stop$)).subscribe((data) => {
							this.pageSettings.currentSelectedArchives = data['archive'];
							filterParams = new AdvancedFilterParams(data);
							this.pageSettings.searchTerm = params['searchTerm'];
							this.pageSettingService.updateSearchSettings(filterParams);

						});
					} else {
						this.pageSettings.searchTerm = params['searchTerm'];
						this.pageSettings.currentSelectedArchives = params['selectedArchives'] ? params['selectedArchives'].split(',') : [];

					}
				});
			// }
		});

		// Document view 
		SearchService.resultObservable.pipe(takeUntil(this.stop$)).subscribe((result) => {
			this.resultLoader = false;
			this.loadMoreLoader = false;
			if (result) {
				let i = 0;
				let count = this.contentTypeList.length;
				this.groups = result.map(group => {
					this.totalGroupCount = group.totalGroupElements;

					if (this.contentTypeList.length > 0) {
						this.contentTypeList.forEach(ele => {
							if (ele === group.group) {
								group['order'] = this.contentTypeList.indexOf(ele) + 1;
							}
						});
					}
					if (group.group) {
						if (group['order'] === undefined) {
							count++;
							group['order'] = count;
						}
					}
					if (group.documents.length > 0) {
						group.documents = group.documents.map(document => {
							if (document.imageThumbnails) {
								document.imageThumbnails = document.imageThumbnails.map((thumb) => {
									if (!thumb.includes('?access_token')) {
										if (thumb.split('_').length > 1) {
											return environment.APIUrl + 'file/thumb/' + document.id +
												'/' + thumb.split('_')[0] + '?access_token=' + this.accessToken + '&WDALoc=' +
												localStorage.getItem('currentLocation') || environment.defaultLocation;
										} else {
											return thumb;
										}
									} else {
										return thumb;
									}
								});
							}
							if (document.folderImages) {
								document.folderImages = document.folderImages.map((thumb) => {
									if (!thumb.includes('?access_token')) {
										if (thumb.split('.').length > 1 || thumb) {
											return environment.APIUrl + 'file/thumb/' + document.id +
												'/' + thumb.split('.')[0] + '?access_token=' + this.accessToken + '&WDALoc=' +
												localStorage.getItem('currentLocation') || environment.defaultLocation;
										} else {
											return thumb;
										}

									} else {
										return thumb;
									}
								});
							}
							return document;
						});
					}
					i++;
					return group;
				});
				if (this.groups.length > 0) {
					for (let k = 0; k < this.groups.length; k++) {
						for (let b = 0; b < this.groups[k].documents.length; b++) {
							if (this.groups[k].documents[b].archive === "BAAS") {
								this.groups[k].documents[b].archivetitle = "British Association for the Advancement of Science (Collections on the History of Science: 1830 — 1970)"
							} else if (this.groups[k].documents[b].archive === "ENV") {
								this.groups[k].documents[b].archivetitle = "Environmental Science and History"
							} else if (this.groups[k].documents[b].archive === "NYAS") {
								this.groups[k].documents[b].archivetitle = "The New York Academy of Sciences"
							} else if (this.groups[k].documents[b].archive === "RCP" || this.groups[k].documents[b].archive === "RCP1" || this.groups[k].documents[b].archive === "RCP2") {
								this.groups[k].documents[b].archivetitle = "The Royal College of Physicians"
							} else if (this.groups[k].documents[b].archive === "RGS" || this.groups[k].documents[b].archive === "RGS1" || this.groups[k].documents[b].archive === "RGS2") {
								this.groups[k].documents[b].archivetitle = "The Royal Geographical Society (with IBG)"
							} else if (this.groups[k].documents[b].archive === "RAI-NEW" || this.groups[k].documents[b].archive === "RAI") {
								this.groups[k].documents[b].archivetitle = "The Royal Anthropological Institute of Great Britain and Ireland"
							} else if (this.groups[k].documents[b].archive === "RAITEST") {
								this.groups[k].documents[b].archivetitle = "The Royal Anthropological Institute of Great Britain and Ireland - Test"
							}
							else if (this.groups[k].documents[b].archive === "WPA") {
								this.groups[k].documents[b].archivetitle = "Wiley Publishing Archive"
							}
							else if (this.groups[k].documents[b].archive === "WPA1") {
								this.groups[k].documents[b].archivetitle = "Wiley Publishing Archive - Confidential"
							}
						}
					}
				}
				if (this.photographView) {
					this.groups.forEach(element => {
						if (element.group === 'Photographs') {
							// this.searchService.viewMoreResultsScroll(element.group, 20, element.totalGroupElements);
						}
					});
					this.loadMoreLoader = false;
				} else {
					this.loadMoreLoader = false;
				}
				if (this.groups.length === 0 && this._isInMapView) {
					this.noResultInMap = true;
					this.popupService.showNotification('WARNING', this.translatedValues.searchResults || 'Search Result',
						this.translatedValues.searchDidNotReturnResult || 'No Results Found');
					if ($('#searchResultDiv')) {
						$('#searchResultDiv').hide();
					}
					if ($('app-browser-map')) {
						$('app-browser-map').hide();
					}
				} else {
					this.loadMoreLoader = false;
				}
			} else {
				this.groups = null;
				this.loadMoreLoader = false;
			}
			if (this.scrollDisplay === null) {
				this.ref.detectChanges();
			}
			this.loadMoreLoader = false;
			this.resultDataLoading = false;
		});
		const filterVal = JSON.parse(localStorage.getItem('filter'));
		if (filterVal) {
			this.searchItems = [];
			this.searchItems = filterVal.searchParams;
		}
	}
	ngAfterViewInit() {
		setTimeout(() => {
			SearchService.facetObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
				if(data) {
					for (const obj of data) {
						if (obj.facetField === 'contentType') {
							this.contentTypesParam = obj.facetContent;
						}
					}
				}
			});
		}, 3000);
	}
	loadMoreResults(value?: string, inx?: any) {
		if ($(window).scrollTop() > 120) {
			this.hightScrollTop = 'true';
		} else {
			this.hightScrollTop = '';
		}
		const scrollTopBottomCheck = $(window).scrollTop();
		if ((scrollTopBottomCheck >= this.lastScrollTop) && !this._isInMapView) {
			if (this.scrollDisplay) {
				const scrollHeight = $(document).height();
				const scrollPosition = $(window).height() + $(window).scrollTop();
				const checkVal = scrollHeight - scrollPosition;
				// if (checkVal === 0) {
				//   setTimeout(() => {
				//     window.scrollTo(0, document.body.scrollHeight - 1000);
				//   }, 300);
				// }
				let group;
				let length;
				let gridviewscroll;
				let checkLength;
				let checktotalGroup;
				if (this.scrollElement) {
					group = this.scrollElement.nativeElement.attributes['data-scrollgroup'].value;
					sessionStorage.setItem('display', group);
					length = this.scrollElement.nativeElement.attributes['data-scrolldoclength'].value;
					const totalGroup = this.scrollElement.nativeElement.attributes['data-scrolltotalgroup'].value;
					gridviewscroll = this.scrollElement.nativeElement.attributes['data-gridviewscroll'].value;
					checkLength = parseInt(length, 10);
					checktotalGroup = parseInt(totalGroup, 10);
				}
				let scrollableHeight: number;
				if (this.scrollDisplay === 'Photographs' || gridviewscroll === 'true') {
					scrollableHeight = 700;
				} else {
					scrollableHeight = 450;
				}
				if (checkVal <= scrollableHeight || value === 'viewMoreFromTemplate') {
					if (checkLength < checktotalGroup) {
						this.loadMoreLoader = true;
						window.scrollBy(0, -100);
						localStorage.setItem('fromScroll', "true");
						if (this._isInMapView && this.specialParams) {
							this.searchService.viewMoreResultsScroll(group, inx ? parseInt(inx) : checkLength, checktotalGroup,
								this.specialParams.spatialFilterParams, group);
						} else {
							this.searchService.viewMoreResultsScroll(group, inx ? parseInt(inx) : checkLength, checktotalGroup, null, group);
						}
					}
				}
			}
		}
		this.lastScrollTop = scrollTopBottomCheck;
	}

	checkAccordionStatus(index, state) {
		this.togglesearchicon[index] = state;
	}


	@HostListener('window:scroll', ['$event'])
	onWindowScroll($event) {
		if (this.docIdForDocViewerObj) {
			if (!this.isDocumentViewer) {
				this.loadMoreResults();
			}
		} else {
			this.loadMoreResults();
		}
	}

	scrollTopPage() {
		$('html,body').animate({ scrollTop: 0 }, 'slow');
	}

	getDocIllustrations(docId, index) {
		// this.modalService.showLoader();
		// this.searchService.getIllustraion(docId, (response) => {
		//   const tableOfContent = response;
		//   const parentNodes = [];
		//   if (tableOfContent) {
		//     const contents = Object.keys(tableOfContent);
		//     contents.forEach((content, parentIndex) => {
		//       const childNodes = [];
		//       if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {
		//         tableOfContent[content].forEach((child, childIndex) => {
		//           const grandChildNodes = [];
		//           if (child.caption === null) {
		//             child.caption = 'Untitled Illustration';
		//           }
		//           childNodes.push({
		//             name: child.caption,
		//             children: grandChildNodes,
		//             tableId: child.tableId ? child.tableId : '',
		//             pageId: child.pageId ? child.pageId : '',
		//             docId: docId,
		//             start: index
		//           });
		//         });
		//       }
		//       parentNodes.push({
		//         name: content,
		//         children: childNodes
		//       });
		//     });
		//     this.illustrationNodes = parentNodes;
		//     this.illustrationsmodalRef = this.bsModalService.show(this.illustrationDataTemplate, {
		//       backdrop: true,
		//       ignoreBackdropClick: true, class: 'illustration-popup'
		//     });
		//     this.modalService.hideLoader();
		//   }
		// });
	}

	viewMoreResultsGroup(group, totalLength, docLength, contentType) {
		this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
			const currentParams = JSON.parse(JSON.stringify(params));
			currentParams['display'] = group;
			sessionStorage.setItem('display', group);
			if (currentParams['display'] === 'Photographs') {
				this.photographView = true;
				$('#ListIcon').removeClass('active');
				$('#ListIcon').addClass('disabled');
				$('#GridIcon').addClass('active');
				this.showGridView = true;
				this.currentView = 'grid';
			}
			const checkparamValue = currentParams['display'];
			const urlTree = this.router.parseUrl(this.router.url + ';display=' + checkparamValue);
			// urlTree.queryParams['display'] = checkparamValue;
			this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
			// this.router.navigateByUrl(urlTree);
			/*
			* this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
			* above commented method will update urltree but it`s applied fully(it`s like temp display)
			*/
			// this.popupService.showLoader();
			this.loadMoreLoader = true;
			this.pageSettingService.updateSetting('groupsToBeDisplayed', [group]);
			this.scrollDisplay = checkparamValue;
			// this.onWindowScroll(null);
			this.resultDataLoading = true;
			this.searchTotalCount = totalLength;
			localStorage.setItem('fromScroll', "true");
			if (this._isInMapView && this.specialParams) {
				this.searchService.viewMoreResultsScroll(group, docLength, totalLength, this.specialParams.spatialFilterParams, group);
			} else {
				this.searchService.viewMoreResultsScroll(group, docLength, totalLength, null, group);
			}
			setTimeout(() => {
				// this.popupService.hideLoader();
				this.loadMoreLoader = false;
			}, 1000);
			if (group === 'Photographs' || this.currentView === 'grid') {
				$('html,body').animate({ scrollTop: 200 }, 'slow');
			} else {
				$('html,body').animate({ scrollTop: 800 }, 'slow');
			}
		});

	}
	weburl: any;
	ngOnInit() {
		this.searchService.advancedSearch(JSON.parse(localStorage.getItem('filter')));
		const contentType = sessionStorage.getItem('selectedContentType');
		if (contentType) {
			if (contentType === 'Maps') {
				this.isMap = true;
			} else if (contentType === 'Photographs') {
				this.isPhotograph = true;
			}
		} else {
			this.sharedService.iscontentTypePhoto.pipe(takeUntil(this.stop$)).subscribe(message => this.isPhotograph = message);
			this.sharedService.iscontentTypeMap.pipe(takeUntil(this.stop$)).subscribe(message => this.isMap = message);
		}
		$('body').trigger('click');
		SearchService.documentObservable.subscribe((data) => {

			if (data) {
				this.documentList = data;
			}
		})

		SearchService.hasResultsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
			if (data) {
				this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
					if (Object.keys(params).length === 0) {
						this.searchTotalCount = data.count;
					}
				})
			}
		});
		if (window.location.host === 'app.wileydigitalarchives.com') {
			this.weburl = 'app.wileydigitalarchives.com'
		} else if (window.location.host === 'qa.wileydigitalarchives.com') {
			this.weburl = 'qa.wileydigitalarchives.com'
		} else if (window.location.host === 'dev.wileydigitalarchives.com') {
			this.weburl = 'dev.wileydigitalarchives.com'
		} else {
			this.weburl = 'qa.wileydigitalarchives.com'
		}
	}

	singleListModeEnabled(event) {
		this.enableSingleList = event;
	}

	downloadData(docs: any, event: any) {
		if (event.checked) { this.count = this.count + 1 } else { this.count = this.count - 1 }
		if (this.count > 10) {
			this.modalService.showNotification('WARNING', "WARNING", 'You can select up to 10 documents at a time.', 6000);
			event.checked = false;
			this.count = 10;
			return;
		}

		if (event.checked) {
			const data = {
				docId: docs.id,
				title: docs.title
			}
			this.documentIdList.push(data);

		} else {
			let index = this.documentIdList.findIndex(res => res.docId == docs.id);
			this.documentIdList.splice(index, 1)
		}
		this.eventList.push(event)
	}
	// openNewWindow(urls:string) {  
	// 	// window.open(urls, '_blank');
	// 	window.location.href = urls;
	//   }

	goDownloadeContent() {
		// this.modalService.showLoader();
		this.searchService.downloadeDocument(this.documentIdList).subscribe((document: any) => {
			this.downloadDocs = document;
			this.downloadPDFmodalRef = this.bsModalService.show(this.downloadDocsTemplate, { backdrop: true, ignoreBackdropClick: true });
			// this.modalService.hideLoader();
		});
	}
	backtoHome() {
		const currentArchive = (PageSettingsService.pageSettings.currentArchive + '')
		let homePageUrl = '/';
		homePageUrl += currentArchive.toLowerCase();
		this.router.navigate([homePageUrl + '/downloadcontent']);
	}
	DownloadALlModal(downloadDocs) {
		console.log(downloadDocs)
		if (downloadDocs) {
			for (let k = 0; k < downloadDocs.length; k++) {
				if (downloadDocs[k].signedUrl) {
					window.open(downloadDocs[k].signedUrl);
				}
			}
			this.downloadPDFmodalRef.hide();
		}
	}
	closeDownloadModal() {
		this.downloadPDFmodalRef.hide();
	}
	clickContent(contentVal) {
		this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
			const currentParams = JSON.parse(JSON.stringify(params));
			currentParams['display'] = contentVal.value;
			const checkparamValue = currentParams['display'];
			const urlTree = this.router.parseUrl(this.router.url + ';display=' + checkparamValue);
			this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
			// this.popupService.showLoader();
			// this.loadMoreLoader = true;
			this.pageSettingService.updateSetting('groupsToBeDisplayed', [contentVal.value]);
			this.scrollDisplay = checkparamValue;
			this.resultDataLoading = true;
			this.searchTotalCount = contentVal.count
			localStorage.setItem('fromScroll', "true");
			if (this._isInMapView && this.specialParams) {
				this.searchService.viewMoreResultsScroll(contentVal.value, 5, contentVal.count, this.specialParams.spatialFilterParams, contentVal.value);
			} else {
				this.searchService.viewMoreResultsScroll(contentVal.value, 5, contentVal.count, null, contentVal.value);
			}
			if (contentVal.value === 'Photographs' || this.currentView === 'grid') {
				$('html,body').animate({ scrollTop: 200 }, 'slow');
			} else {
				$('html,body').animate({ scrollTop: 800 }, 'slow');
			}
		})
	}
	clearSearch(val) {
		if (val && val.fieldValue) {
			const fieldVal = JSON.parse(localStorage.getItem('filter'));
			if (fieldVal) {
				if (fieldVal.searchParams.length > 0) {
					const newVal = [];
					for (let k = 0; k < fieldVal.searchParams.length; k++) {
						if (fieldVal.searchParams[k].fieldValue !== val.fieldValue) {
							newVal.push(fieldVal.searchParams[k])
						}
					}
					fieldVal.searchParams = newVal;
					this.searchItems = newVal;
					localStorage.setItem('filter', JSON.stringify(fieldVal))
					PageSettingsService.pageSettings.advancedFilterParam.searchKeywords = newVal;
					this.pageSettingService.updateSearchSettings(PageSettingsService.pageSettings.advancedFilterParam);

				}
			}
			this.searchService.advancedSearch(JSON.parse(localStorage.getItem('filter')));
		}
	}
	clearAllDC() {
		this.downloadDocs = [];
		this.documentIdList = [];
		this.count = 0;
		for(let k=0; k< this.eventList.length; k++) {
		this.eventList[k].checked = false;
	    }
		this.downloadPDFmodalRef.hide();
		this.eventList = [];
	}
	ngOnDestroy(): void {
		this.stop$.next();
		this.stop$.complete();
		if (this.routeSub) {
		this.routeSub.unsubscribe();
		}
		if (this.activerouteSub) {
			this.activerouteSub.unsubscribe();
			}
	}
}

