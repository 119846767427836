import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) { return []; }
        if (!searchText) { return items; }
        searchText = searchText.toLowerCase();
        return items.filter(obj => {
            return (obj['metadata']['COLLECTION TITLE'].toLowerCase().includes(searchText) || obj['id'].toLowerCase().includes(searchText));
        });
    }
}

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Pipe({
    name: 'filterColumn'
  })
  export class FilterColumnPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
      if (!items) { return []; }
      if (!searchText) {  return items; }
  searchText = searchText.toLowerCase();
  return items.filter( it => {
        it = JSON.stringify(it);
        it = it.toLowerCase();
        return it.includes(searchText);
      });
     }
  }

//   @Pipe({
//     name: 'encodeEntity'
//   })
//   export class EncodeEntityPipe implements PipeTransform {
//     transform(str: string): any {
//         const element = document.createElement('div');
//         if (str && typeof str === 'string') {
//             str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
//             str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
//             element.innerHTML = str;
//             str = element.textContent;
//             element.textContent = '';
//           }
//           return str;
//      }
//   }


  @Pipe({
    name: 'unicodeencodeEntity'
  })
  export class UnicodeEncodeEntityPipe implements PipeTransform {
    transform(str: string): any {
        const element = document.createElement('div');
        if (str && typeof str === 'string') {
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
          }
          return str;
     }
  }
  @Pipe({
    name: 'explorerunicode'
  })
  export class ExplorerUnicodeEncodeEntityPipe implements PipeTransform {
    transform(str: string): any {
        const element = document.createElement('div');
        if (str && typeof str === 'string') {
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
          }
          return str;
     }
  }
