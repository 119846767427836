
import {takeUntil} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { environment, APIURLS, DOCUMENT_ID_REFERENCE, stopWords } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';
import { SearchService } from '../../../common/services/search.service';
import { AdvancedFilterParams } from '../../../common/models/AdvancedFilterParams';

export class ImageData {
  pageId: String;
  docId: String;
  title: String;
  documentTitle: String;
  captions: Array<any>;
  thumb: String;
  image: String;
  startIndex: Number;

  constructor(
    pageId: String,
    itemTitle: String = 'Untitled',
    documentTitle: String = null,
    thumb: String,
    image: String,
    docId: String,
    captions: Array<any> = [],
    startIndex: Number
  ) {
    this.pageId = pageId;
    this.docId = docId;
    this.title = itemTitle;
    this.documentTitle = documentTitle;
    this.captions = captions;
    this.thumb = thumb;
    this.image = image;
    this.startIndex = startIndex;
  }
}
@Injectable()
export class ExplorerCoverflowService implements OnDestroy{
  private stop$: Subject<void> = new Subject<void>();
  searchResultCache$: any = {};
  coverFlowData: any = [];
  locationsConfigDetails: any;
  explorerPhotoCache$ = {};
  explorerMapCache$ = {};
  splittedSearchTerms: Array<String> = [];
  constructor(
    private httpClient: HttpClient,
    private searchService: SearchService,
  ) {
    this.httpClient.get(`${APIURLS['config']}`).pipe(takeUntil(this.stop$)).subscribe(res => {
      this.locationsConfigDetails = res;
    }, (error) => { });
  }
  /**
   * @desc: get more photos from service based on offset and rows
   * @param advSearchParams
   * @param searchTerm
   * @param callback
   * @param errorCallback
   * @param offset
   * @param spatialFilterParams
   * @param rows
   */
  getPhotoExplorer(advSearchParams, searchTerm, callback, errorCallback, offset: number = 0, spatialFilterParams, rows: number = 3) {
    const data = advSearchParams || {};
    let str = searchTerm || '';
    str = str.trim();
    if (str.includes('-')) {
      if (str.includes('"')) {
        searchTerm = str;
      } else {
        searchTerm = '"' + str + '"';
      }
    }
    data.searchTerm = searchTerm;
    this.searchService.buildFilterParams(new AdvancedFilterParams(advSearchParams), (param) => {
      param.filterParams.forEach((obj, index) => {
        if (obj.fieldName === 'illustrationType') {
          param.filterParams.splice(index, 1);
        }
      });
      param.searchTerm = searchTerm;
      param['start'] = offset;
      if (window.innerWidth <= 768) {
        param['rows'] = 1;
      } else {
        param['rows'] = rows;
      }
      // param['rows'] = rows;
      if (spatialFilterParams) {
        spatialFilterParams.forEach(obj => {
          obj.fieldName = 'latlon';
        });
        param['spatialFilterParams'] = spatialFilterParams;
      }
      /**
       * remove unwanted params for getting document
       */
      delete param['groupLimit'];
      delete param['groupOffset'];
      delete param['searchParams'];
      let undate = 0;
      param.filterParams.forEach((obj, index) => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          undate++;
          if (undate > 1) {
            param.filterParams.splice(index, 1);
          }
        }
      });
      param.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
      });
      param.filterParams.forEach(obj => {
        if (obj.fieldName === 'archive') {
          if (obj.fieldValues.indexOf('RGS') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RGS');
            obj.fieldValues.push('RGS1', 'RGS2');
          }
          if (obj.fieldValues.indexOf('RCP') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RCP');
            obj.fieldValues.push('RCP1', 'RCP2');
          }

        }
      });
      // if (this.explorerPhotoCache$[JSON.stringify(param)]) {
      //   callback(this.explorerPhotoCache$[JSON.stringify(param)]);
      //   return true;
      // }
      // let splittedTerms: any = this.splitTerms(param.searchTerm);
      // splittedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
      // splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
      // param.searchTerm = splittedTerms;
      this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['getPhotoExplorer']}`, param).pipe(takeUntil(this.stop$)).subscribe(res => {
        const authToken = JSON.parse(localStorage.getItem('auth_token'));
        if (res) {
          const imageUrls: any[] = [];
          if (res.results) {
            if (res.results.explorerResult) {
              res.results.explorerResult.forEach((doc, index) => {
                imageUrls.push(new ImageData(
                  doc.id,
                  doc.itemTitle,
                  doc.documentTitle,
                  // tslint:disable-next-line:max-line-length
                  `${environment.APIUrl}${APIURLS['fileUrlThumb']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                  // tslint:disable-next-line:max-line-length
                  `${environment.APIUrl}${APIURLS['fileUrlImage']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                  doc.documentId,
                  doc.captions,
                  index
                ));
              });
            }
          }
          const result = { imageUrls: imageUrls, totalRecoards: res.totalRecords };
          this.explorerPhotoCache$[JSON.stringify(param)] = result;
          callback(result);
        }
      }, err => {
        errorCallback([]);
      });
    });
  }
/**
 * @desc: get more data for map explorer based on offset and rows
 * @param advSearchParams
 * @param searchTerm
 * @param callback
 * @param errorCallback
 * @param offset
 * @param spatialFilterParams
 * @param rows default 3
 */
  getMapExplorer(advSearchParams, searchTerm, callback, errorCallback, offset: number = 0,
     spatialFilterParams, mapCoordinates, ignoreFullTexts, rows: number = 3) {
    const data = advSearchParams || {};
    let str = searchTerm || '';
    str = str.trim();
    if (str.includes('-')) {
      if (str.includes('"')) {
        searchTerm = str;
      } else {
        searchTerm = '"' + str + '"';
      }
    }
    data.searchTerm = searchTerm;
    this.searchService.buildFilterParams(new AdvancedFilterParams(advSearchParams), (param) => {
      param.filterParams.forEach((obj, index) => {
        if (obj.fieldName === 'illustrationType') {
          param.filterParams.splice(index, 1);
        }
      });
      param.searchTerm = searchTerm;
      param['start'] = offset;
      if (window.innerWidth <= 768) {
        param['rows'] = 1;
      } else {
        param['rows'] = rows;
      }
      if (spatialFilterParams) {
        spatialFilterParams.forEach(obj => {
          obj.fieldName = 'geoBoundaries';
        });
        param['spatialFilterParams'] = spatialFilterParams;
      }
      if (mapCoordinates) {
        param['mapCoordinatesNotNull'] = mapCoordinates;
      }
      if (ignoreFullTexts) {
        param['ignoreFullText'] = ignoreFullTexts;
      }
      /**
       * remove unwanted params for getting document
       */
      delete param['groupLimit'];
      delete param['groupOffset'];
      delete param['searchParams'];
      let undate = 0;
      param.filterParams.forEach((obj, index) => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          undate++;
          if (undate > 1) {
            param.filterParams.splice(index, 1);
          }
        }
      });
      param.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
      });
      param.filterParams.forEach(obj => {
        if (obj.fieldName === 'archive') {
          if (obj.fieldValues.indexOf('RGS') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RGS');
            obj.fieldValues.push('RGS1', 'RGS2');
          }
          if (obj.fieldValues.indexOf('RCP') > -1) {
            obj.fieldValues = obj.fieldValues.filter(item => item !== 'RCP');
            obj.fieldValues.push('RCP1', 'RCP2');
          }

        }
      });
      // if (this.explorerPhotoCache$[JSON.stringify(param)]) {
      //   callback(this.explorerPhotoCache$[JSON.stringify(param)]);
      //   return true;
      // }
      // let splittedTerms: any = this.splitTerms(param.searchTerm);
      // splittedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
      // splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
      // param.searchTerm = splittedTerms;
      this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['getMapExplorer']}`, param).pipe(takeUntil(this.stop$)).subscribe(res => {
        const authToken = JSON.parse(localStorage.getItem('auth_token'));
        if (res) {
          const imageUrls: any[] = [];
          if (res.results) {
            if (res.results.explorerResult) {
              res.results.explorerResult.forEach((doc, index) => {
                imageUrls.push(new ImageData(
                  doc.id,
                  doc.itemTitle,
                  doc.documentTitle,
                  // tslint:disable-next-line:max-line-length
                  `${environment.APIUrl}${APIURLS['fileUrlThumb']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                  // tslint:disable-next-line:max-line-length
                  `${environment.APIUrl}${APIURLS['fileUrlImage']}${doc.documentId}/${doc.id}?access_token=${authToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`,
                  doc.documentId,
                  doc.captions,
                  index
                ));
              });
            }
          }
          const result = { imageUrls: imageUrls, totalRecoards: res.totalRecords };
          this.explorerPhotoCache$[JSON.stringify(param)] = result;
          callback(result);
        }
      }, err => {
        errorCallback([]);
      });
    });
  }

/**
 * @desc: get documents from adv.search service based on offset and rows
 * @param searchTerm
 * @param advancedFilters
 * @param callBack
 * @param errorCallBack
 * @param offset
 * @param contentType
 * @param rows default is 3
 */
  advancedSearch(searchTerm, advancedFilters, callBack?, errorCallBack?, offset?, contentType?, rows: number = 3, spatialFilterParams?) {
    if (advancedFilters) {
      this.searchService.buildFilterParams(advancedFilters, params => {
        params.groupOffset = offset ? offset : 0;
        if (window.innerWidth <= 768) {
          params.groupLimit = 1;
        } else {
          params.groupLimit = rows;
        }
        params.filterParams.forEach(obj => {
          if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
            obj.condition = 'All';
            obj.fieldValue = '';
          }
        });
        params.searchTerm = searchTerm;
        if (spatialFilterParams) {
          params['spatialFilterParams'] = spatialFilterParams;
        }
        if (contentType) {
          params.filterParams.push({ fieldName: 'contentType', fieldValue: contentType, operator: 'OR' });
        }
        // if (this.searchResultCache$[JSON.stringify(params)]) {
        //   callBack(this.searchResultCache$[JSON.stringify(params)]);
        //   return;
        // }
        let splittedTerms: any = this.splitTerms(params.searchTerm);
        splittedTerms = splittedTerms instanceof Array ? splittedTerms.splice(0, 8) : [];
        splittedTerms = splittedTerms.toString().replace(/,/g, ' ');
        params.searchTerm = splittedTerms;
        for(let k=0; k<params.filterParams && params.filterParams.length; k++) {
          if (params.filterParams[k].fieldName === "archive"){
            const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
            const includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
            if (!includeWPA) {
              const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA");
              if (havewpa) { let subscribedArchives: any = JSON.parse(sessionStorage.getItem('wileySubscribedArchives_tab1'));
              let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
               if(!havewpaInSubs){
                params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA')
               }
              }
            }
            const includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
            if (!includeWPA1) {
              const havewpa = params.filterParams[params.filterParams[k] && k].fieldValues.includes("WPA1");
              if (havewpa) { let subscribedArchives: any = JSON.parse(sessionStorage.getItem('wileySubscribedArchives_tab1'));
              let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
               if(!havewpaInSubs){
                params.filterParams[k].fieldValues = params.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
               }
              }
            }
          }
        }
        this.httpClient.post<any>(`${environment.APIUrl}${APIURLS['advancedSearch']}`, params).pipe(takeUntil(this.stop$)).subscribe(res => {
          const imageUrls: any[] = [];
            let totalRecords = 0;
              totalRecords = res.totalRecords;
              const resultGroups = res.results.groupResults[0].groupEntry.groupEntryContent;
              const accessToken = JSON.parse(localStorage.getItem('auth_token'));
              if (resultGroups.length > 0) {
                if (Array.isArray(resultGroups)) {
                  resultGroups.forEach((group, groupIndex) => {
                    if (Array.isArray(group.documents)) {
                      if (group.group === contentType) {
                        // totalRecords = group.totalGroupElements;
                        group.documents.forEach((document, docIndex) => {
                          if (document.title) {
                            if (document.title.includes('&#x0027;')) {
                              document.title = document.title.replace(/&#x0027;/g, "'");
                            }
                            if (document.title.includes('&#x0026;')) {
                              document.title = document.title.replace(/&#x0026;/g, '&');
                            }
                            if (document.title.includes('&#x0022;')) {
                              document.title = document.title.replace(/&#x0022;/g, '"');
                            }
                          }
                          let imgId: String = null;
                          if (document.imageThumbnails[0].includes('_thumbnail')) {
                            if (this.locationsConfigDetails.locations[document.archive].hasFolderImage === 'true') {
                              imgId = document.imageThumbnails[1].toString().replace(/\.[^/.]+$/, '').replace('_thumbnail', '');
                            } else {
                              imgId = document.imageThumbnails[0].toString().replace(/\.[^/.]+$/, '').replace('_thumbnail', '');
                            }
                          } else {
                            if (this.locationsConfigDetails.locations[document.archive].hasFolderImage === 'true') {
                              imgId = document.imageThumbnails[1].split('/')[6].split('?')[0];
                            } else {
                              imgId = document.imageThumbnails[0].split('/')[6].split('?')[0];
                            }
                          }
                          imageUrls.push({
                            docId: document.id,
                            title: document.title,
                            citation: document.citation ? String(document.citation).replace(/<[^>]+>/gm, '') : '',
                            imageCount: document.imageThumbnails.length,
                            thumb: environment.APIUrl + APIURLS['fileUrlThumb'] + document.id + '/' + imgId + '?access_token=' +
                              accessToken + '&type=small' + '&WDALoc=' +
                              localStorage.getItem('currentLocation') || environment.defaultLocation,
                            source: document.sourceInstitution
                          });
                        });
                      }
                    }
                  });
                }
              }
          const result = {
            count: totalRecords,
            data: totalRecords > 0 ? imageUrls : null
          };
          this.searchResultCache$[JSON.stringify(params)] = result;
          callBack(result);
          },
          error => {
            if (typeof callBack === 'function') {
              callBack(error);
            }
          }
        );
      });
    }
  }

  splitTerms(searchKey) {
    this.splittedSearchTerms = [];
    const searchTerms: Array<String> = [];
    if (searchKey) {
      searchKey = searchKey.match(/[^\s"]+|"([^"]*)+"/g);
      searchKey.forEach(key => {
        if (this.checkForDocumentIdPrefix(key)) {
          if (this.splittedSearchTerms.indexOf(key) <= -1) {
            if (/^".*"$/.test(key)) {
              this.sanitizeAndPushTerms(key);
            } else {
              this.sanitizeAndPushTerms('"' + key + '"');
            }
          }
        } else {
          if (/^".*"$/.test(key)) {
            this.sanitizeAndPushTerms(key);
          } else {
            if (key.includes('-')) {
              const withHyphen = key.split('-');
              withHyphen.forEach(seperatedHypen => {
                this.sanitizeAndPushTerms(seperatedHypen.replace(/[^a-zA-Z0-9-?*! ]/g, ''));
              });
            } else {
              this.sanitizeAndPushTerms(key.replace(/[^a-zA-Z0-9-?*! ]/g, ''));
            }
          }
        }
      });
      return this.splittedSearchTerms;
    }
  }
  sanitizeAndPushTerms(term) {
    if (stopWords.indexOf(term.toLowerCase()) > -1) {
      term = '';
    }
    if (term !== '') {
        if (this.splittedSearchTerms.indexOf(term.toLowerCase()) !== -1) {
        } else {
        this.splittedSearchTerms.push(term);
        }
    }
  }

  checkForDocumentIdPrefix(term): Boolean {
    let containsDocumentIdPrefix: Boolean = false;
    DOCUMENT_ID_REFERENCE.forEach(docIdPrefix => {
      if (term.includes(docIdPrefix)) {
        if (term.match(new RegExp('-', 'g')) || [].length === 2) {
          containsDocumentIdPrefix = true;
        }
      }
    });
    if (containsDocumentIdPrefix) {
      return true;
    }
  }
  coverFlowClosed() {
    this.searchResultCache$ = {};
  }
  ngOnDestroy() {
    this.searchResultCache$ = {};
    this.stop$.next();
    this.stop$.complete();
  }
}

