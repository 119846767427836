<form [formGroup]="limiterForm" class="col-md-12 advance-search-menu">
  <div class="row">
    <div class="estimated-count-parent-div col-md-12">
      <span class="text-italic estimated-count-text"> {{ 'AdvancedSearch.estimatedCount' | translate }}</span>
      <span [class.arabic-style]="isArabicLanguage" *ngIf="(!estimatedCount && !showLoadingTxt || estimatedCount == 0 && !showLoadingTxt) || (limiterForm.get('languages').value.length==0 && limiterForm.get('contentSources').value.length==0 && limiterForm.get('docSubType').value.length
      ==0 && limiterForm.get('archive').value.length == 0 && limiterForm.get('contentCollection').value.length ==0 && limiterForm.get('publishedDatePrefix').value
      == '' && limiterForm.get('contentType').value.length ==0)"
        class="blink-wordlbl mb-0">
        <!-- {{ 'HomePage.noResults' | translate }} -->
        <span *ngIf="isFromPage === 'photos'">{{ 'HomePage.noPhotos' | translate }}</span>
        <span *ngIf="isFromPage === 'maps'">{{ 'HomePage.noMaps' | translate }}</span>
        <span *ngIf="isFromPage === 'analysisHub'">{{ 'HomePage.noResults' | translate }}</span>
      </span>
      <span [class.arabic-style]="isArabicLanguage" *ngIf="(estimatedCount > 0 && !showLoadingTxt) && !(limiterForm.get('languages').value.length==0 && limiterForm.get('contentSources').value.length==0 && limiterForm.get('docSubType').value.length
        ==0 && limiterForm.get('archive').value.length == 0 && limiterForm.get('contentCollection').value.length ==0 && limiterForm.get('publishedDatePrefix').value
        == '' && limiterForm.get('contentType').value.length ==0)" class="blink-wordlbl mb-0">
        {{ estimatedCount }} 
        <span *ngIf="isFromPage === 'photos'">{{ 'HomePage.photos' | translate }}</span>
        <span *ngIf="isFromPage === 'maps'">{{ 'HomePage.maps' | translate }}</span>
        <span *ngIf="isFromPage === 'analysisHub'">{{ 'HomePage.documents' | translate }}</span>
      </span>
      <span *ngIf="showLoadingTxt">
        {{'AdvancedSearch.Loading' | translate}}
      </span>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 advanced-searchdiv">
      <span [ngClass]="{'hidecontentTypeTooltip': !contentTypeTooltip}">
        <!-- <tooltip-content #contentTypeTooltipText>
          <label>{{ 'HomePage.contentTypes' | translate }}</label>
          <br />{{contentTypeTooltip}}</tooltip-content> -->
          <ng-template #contentTypeTooltipText>
            <label>{{ 'HomePage.contentTypes' | translate }}</label>
            <br />{{contentTypeTooltip}}
          </ng-template>
        <ss-multiselect-dropdown *ngIf="!isFromPhotoExplorer" name="contentType"
          [tooltip]="contentTypeTooltipText" [tooltipDisabled]="contentTypeTooltip ? false : true" placement="top"
          [options]="pageContent.availableContentTypes" [texts]="contentTypeTexts" [settings]="contentTypeSettings"
          formControlName="contentType" [class.highlight-limitors]="limiterForm.get('contentType').value.length > 0 ? true :
false" [disabled]="(isDisableLimiterFields?.isDisable && isFromCollocation)"></ss-multiselect-dropdown>
      </span>
      <div class="button-group pub-dateDiv">
        <!-- <button title="{{'textAnalysisPage.publicationDate' | translate}}" type="button"
          [tooltip]="publicationdateTooltip" tooltipPlacement="top"
          [tooltipDisabled]="limiterForm.get('publishedDatePrefix').value == '' ? true : false"
          [tooltipAnimation]="true" data-tooltip="tooltip"
          class="dropdown-search rightpadding-0 dropdown-element pub-date down-fa dropdown-toggle"
          data-toggle="dropdown" id="publication-date" (click)="togglePubDate()"
          [class.highlightpubs-limitors]="limiterForm.get('publishedDatePrefix').value == '' ? false : true"
          [disabled]="(isDisableLimiterFields?.isDisable && isFromCollocation)">
          {{limiterForm.get('publishedDatePrefix').value == 'All' ? 'All' : (limiterForm.get('publishedDateFrom').value
          || limiterForm.get('publishedDateTo').value)
          ? (limiterForm.get('publishedDateFrom').value == 'All' ? '' : limiterForm.get('publishedDatePrefix').value +
          '
          : ' ) + limiterForm.get('publishedDateFrom').value : 'Date'}} {{
          limiterForm.get('publishedDateTo').value
          ? ' & ' + limiterForm.get('publishedDateTo').value : '' }}
          {{(limiterForm.get('publishedDateIncludesNull').value
          && limiterForm.get('publishedDatePrefix').value ) ? 'And Undated' : '' }}
        </button>
        <tooltip-content #publicationdateTooltip>
          <label>{{ 'HomePage.publicationDate' | translate }}</label>
          <br />{{limiterForm.get('publishedDatePrefix').value == 'All' ? 'All' :(limiterForm.get('publishedDateFrom').value || limiterForm.get('publishedDateTo').value) ?
          (limiterForm.get('publishedDatePrefix').value
          === 'All' ? '':limiterForm.get('publishedDatePrefix').value + ' : ' ) +
          limiterForm.get('publishedDateFrom').value
          : 'Date'}} {{ limiterForm.get('publishedDateTo').value ? ' & ' +
          limiterForm.get('publishedDateTo').value
          : ''}} {{(limiterForm.get('publishedDateIncludesNull').value && limiterForm.get('publishedDatePrefix').value)
          ? '
          And Undated' : '' }}
          <br />
        </tooltip-content> -->
        <ul class="dropdown-menu publicate-date-ul" [ngStyle]="{'display': showPubDate ? 'block': 'none'}"
          (click)="$event.stopPropagation();">
          <li>
            <div class="date-pickeron" data-reference="publication-date">
              <a href="javascript:void(0);" title="{{'textAnalysisPage.All' | translate}}" class="small"
                data-value="All">
                <input value="All" id="select_all_pubdate" type="radio" [formControl]="publishedDatePrefix" />
                <label for="select_all_pubdate">{{ 'HomePage.ToolsDropdown.ALL' | translate }}</label>
              </a>
            </div>
          </li>
          <li>
            <div class="date-pickeron" data-reference="publication-date">
              <a href="javascript:void(0);" title="{{ 'HomePage.On' | translate }}" class="small" data-value="ON">
                <input value="ON" id="on" type="radio" [formControl]="publishedDatePrefix" />
                <label for="on">{{ 'HomePage.On' | translate }}</label>
              </a>
              <div *ngIf="limiterForm.get('publishedDatePrefix').value == 'ON'">
                <input [textMask]="{mask: publishDateMask, guide:false, modelClean:true}" class="date-picker"
                  placeholder="{{ (isFromCollocation ? 'Common.Placeholder.collocationDate' : 'Common.Placeholder.date') | translate}}"
                  type="text" [formControl]="publishedDateFrom" />
                <p class="date-error mb-0" *ngIf="checkDate  && (publishedDateFrom.dirty || publishedDateFrom.touched)">Enter valid Date</p>
              </div>
            </div>
          </li>
          <li>
            <div class="date-pickeron" data-reference="publication-date">
              <a href="javascript:void(0);" title="{{ 'HomePage.Before' | translate }}" class="small"
                data-value="Before">
                <input class="test-radio" value="BEFORE" id="before" type="radio" [formControl]="publishedDatePrefix" />
                <label for="before">{{ 'HomePage.Before' | translate }}</label>
              </a>
              <div *ngIf="limiterForm.get('publishedDatePrefix').value == 'BEFORE'">
                <input [textMask]="{mask: publishDateMask, guide:false}" class="date-picker"
                  placeholder="{{ (isFromCollocation ? 'Common.Placeholder.collocationDate' : 'Common.Placeholder.date') | translate}}"
                  type="text" [formControl]="publishedDateFrom" />
                <p class="date-error mb-0" *ngIf="checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)">Enter valid Date</p>
              </div>
            </div>
          </li>
          <li>
            <div class="date-pickeron" data-reference="publication-date">
              <a href="javascript:void(0);" title="{{ 'HomePage.After' | translate }}" class="small" data-value="After"
                tabIndex="1">
                <input value="AFTER" id="after" type="radio" [formControl]="publishedDatePrefix" />
                <label for="after">{{ 'HomePage.After' | translate }}</label>
              </a>
              <div *ngIf="limiterForm.get('publishedDatePrefix').value == 'AFTER'">
                <input [textMask]="{mask: publishDateMask, guide:false}" class="date-picker"
                  placeholder="{{ (isFromCollocation ? 'Common.Placeholder.collocationDate' : 'Common.Placeholder.date') | translate}}"
                  type="text" [formControl]="publishedDateFrom" />
                <p class="date-error mb-0" *ngIf="checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)">Enter valid Date</p>
              </div>
            </div>
          </li>
          <li>
            <div class="date-pickeron" data-reference="publication-date">
              <a href="javascript:void(0);" title="{{ 'HomePage.Between' | translate }}" class="small"
                data-value="Between" tabIndex="1">
                <input value="BETWEEN" id="Between" type="radio" [formControl]="publishedDatePrefix" />
                <label for="Between">{{ 'HomePage.Between' | translate }}</label>
              </a>
              <div *ngIf="limiterForm.get('publishedDatePrefix').value == 'BETWEEN'">
                <input class="date-picker" [textMask]="{mask: publishDateMask, guide:false}"
                  placeholder="{{ (isFromCollocation ? 'Common.Placeholder.collocationDate' : 'Common.Placeholder.date') | translate}}"
                  type="text" [formControl]="publishedDateFrom" />
                <input class="date-picker" [textMask]="{mask: publishDateMask, guide:false}"
                  placeholder="{{ (isFromCollocation ? 'Common.Placeholder.collocationDate' : 'Common.Placeholder.date') | translate}}"
                  type="text" [formControl]="publishedDateTo" />
                <p class="date-error mb-0" *ngIf="checkDate  && (publishedDateFrom.dirty || publishedDateFrom.touched || publishedDateTo.dirty || publishedDateTo.touched)">Enter valid Date</p>
              </div>
            </div>
          </li>
          <li>
            <div class="pubdate-checkboxdiv">
              <label class="pubdate-checkbox" title="{{ 'AdvancedSearch.includeUndatesDocuments' | translate }}">{{ 'AdvancedSearch.includeUndatesDocuments' | translate }}
                <input type="checkbox" [formControl]="publishedDateIncludesNull">
                <span class="checkmark"></span>
              </label>
            </div>
          </li>
        </ul>
      </div>
      <span [ngClass]="{'hideCollectionTooltip': !collectionsTooltip}">
        <!-- <tooltip-content #collectionsTooltipText>
          <label>{{ 'HomePage.Collections' | translate }}</label>
          <br />{{collectionsTooltip}}</tooltip-content> -->
          <ng-template #collectionsTooltipText>
            <label>{{ 'HomePage.Collections' | translate }}</label>
            <br />{{collectionsTooltip}}
          </ng-template>
        <ss-multiselect-dropdown name="contentCollection" class="contentCollection"
          [options]="pageContent.availableCollections" [tooltip]="collectionsTooltipText"
          placement="top" [class.highlight-limitors]="limiterForm.get('contentCollection').value.length >0 ? true : false"
          [texts]="collectionTexts" [settings]="collectionsSettings" formControlName="contentCollection"
          [disabled]="(isDisableLimiterFields?.isDisable && isFromCollocation)"></ss-multiselect-dropdown>
          
        </span>

      <!-- *ngIf="pageContent?.availableArchives?.length > 0"
       [ngClass]="{'hideArchivesTooltip': !archivesTooltip}"
      -->
      <span>
        <!-- <tooltip-content #archivesTooltipText>
          <label>{{ 'Titles.Archives' | translate }}</label>
          <br />{{archivesTooltip}}</tooltip-content> -->
          <ng-template #archivesTooltipText>
            <label>{{ 'Titles.Archives' | translate }}</label>
            <br />{{archivesTooltip}}
          </ng-template>
        <ss-multiselect-dropdown name="archive" class="contentCollection"
          [options]="pageContent.availableArchives" [tooltip]="archivesTooltipText" placement="top" [class.highlight-limitors]="limiterForm.get('archive').value.length>0
? true : false" [texts]="archivesTexts" [settings]="archivesSettings" formControlName="archive"
          [disabled]="this.pageContent.customerArchives === null"></ss-multiselect-dropdown>
          
        </span>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 advanced-searchdiv">
      <span [ngClass]="{'hidecontentSubTypesTooltip': !contentSubTypesTooltip}">
        <!-- <tooltip-content #contentSubTypeTooltipText>
          <label>{{ 'HomePage.contentSubtypes' | translate }}</label>
          <br />{{contentSubTypesTooltip}}</tooltip-content> -->
          <ng-template #contentSubTypeTooltipText>
            <label>{{ 'HomePage.contentSubtypes' | translate }}</label>
            <br />{{contentSubTypesTooltip}}
          </ng-template>
        <ss-multiselect-dropdown *ngIf="!isFromPhotoExplorer && !isFromCollocation" name="docSubType" class="docSubType"
           [options]="pageContent.availableContentSubTypes" [tooltip]="contentSubTypeTooltipText"
          placement="top" [texts]="contentSubTypeTexts" [settings]="contentSubTypeSettings"
          formControlName="docSubType" [class.highlight-limitors]="limiterForm.get('docSubType').value.length>0
? true : false"></ss-multiselect-dropdown>
      </span>
      <span [ngClass]="{'hidepublicationTitleTooltip': !publicationTitleTooltip}">
        <!-- <tooltip-content #publicationTitleTooltipText>
          <label>{{ 'HomePage.languages' | translate }}</label>
          <br />{{publicationTitleTooltip}}</tooltip-content> -->
          <ng-template #publicationTitleTooltipText>
            <label>{{ 'HomePage.languages' | translate }}</label>
          <br />{{publicationTitleTooltip}}
          </ng-template>
        <ss-multiselect-dropdown *ngIf="!isFromPhotoExplorer && !isFromCollocation" class="languages" name="languages"
          [options]="pageContent.availableLanguages"
          [tooltip]="publicationTitleTooltipText" placement="top" [texts]="languageTexts" [settings]="languageSettings" formControlName="languages"
          [class.highlight-limitors]="limiterForm.get('languages').value.length>0
? true : false"></ss-multiselect-dropdown>
      </span>
      <span [ngClass]="{'hidecontentSourcesTooltip': !contentSourcesTooltip}">
        <!-- <tooltip-content #contentSourcesTooltipText>
          <label>{{ 'HomePage.contentSources' | translate }}</label>
          <br />{{contentSourcesTooltip}}</tooltip-content> -->
          <ng-template #contentSourcesTooltipText>
            <label>{{ 'HomePage.contentSources' | translate }}</label>
            <br />{{contentSourcesTooltip}}
          </ng-template>
        <ss-multiselect-dropdown name="contentSources" class="contentSources"
           [options]="pageContent.availableSources" [tooltip]="contentSourcesTooltipText" placement="top" [texts]="contentSourcesTexts" [settings]="contentSourcesSettings"
          formControlName="contentSources" [class.highlight-limitors]="limiterForm.get('contentSources').value.length>0 ? true : false"
          [disabled]="(isDisableLimiterFields?.isDisable && isFromCollocation)"></ss-multiselect-dropdown>
          
        </span>
      <span [ngClass]="{'hideillustrationTypeTooltip': !illustrationTypesTooltip}">
        <!-- <tooltip-content #illustrationTypeTooltipText>
          <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.illustrationType' | translate }}</label>
          <br />{{illustrationTypesTooltip}}</tooltip-content> -->
          <ng-template #illustrationTypeTooltipText>
            <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.illustrationType' | translate }}</label>
            <br />{{illustrationTypesTooltip}}
          </ng-template>
        <ss-multiselect-dropdown *ngIf="false" class="illustrationType"
          [options]="pageContent.availableIllustrationTypes" [tooltip]="illustrationTypeTooltipText"
          placement="top" [texts]="{ checked: illustrationTypeText.checked, checkedPlural: illustrationTypeText.checkedPlural, defaultTitle:
              illustrationTypeText.defaultTitle, searchPlaceholder: illustrationTypeText.searchPlaceholder }"
          [settings]="illustrationTypeSettings" formControlName="illustrationType"
          [class.highlight-limitors]="limiterForm.get('illustrationType').value.length>0 ? true : false">
        </ss-multiselect-dropdown>
      </span>
    </div>
  </div>
  <div class="text-right margin-t-5 mobile-btn" [class.documentsClass]="_enableSubmit">
    <button type="button" title="{{ 'AdvancedSearch.Reset' | translate }}"
      class="btn btn-secondary btn-custom btn-reset"
      (click)="resetSearchFilter()">{{ 'AdvancedSearch.Reset' | translate }}</button>
    <button type="submit" title="{{ (limiterForm.get('languages').value.length==0 && limiterForm.get('contentSources').value.length==0 && limiterForm.get('docSubType').value.length
    ==0 && limiterForm.get('archive').value.length == 0 && limiterForm.get('contentCollection').value.length ==0 && limiterForm.get('publishedDatePrefix').value
    == '' && limiterForm.get('contentType').value.length ==0) ? limitdisabled : limitLabel }}"
      class="btn btn-custom btn-submit" (click)="searchSubmit()" [disabled]="checkDate || !estimatedCount || estimatedCount == 0 || (limiterForm.get('languages').value.length==0 && limiterForm.get('contentSources').value.length==0 && limiterForm.get('docSubType').value.length
==0 && limiterForm.get('archive').value.length == 0 && limiterForm.get('contentCollection').value.length ==0 && limiterForm.get('publishedDatePrefix').value
== '' && limiterForm.get('contentType').value.length ==0)"
      >{{limitLabel}}</button>
  </div>
</form>

<!-- [ngClass]="{'disable-btn': estimatedCount == 0, 'enable-btn':estimatedCount > 0}" -->
