
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { SearchService } from '../common/services/search.service';
import { PageSettingsService } from '../common/services/page-settings.service';
import { HttpService } from '../common/services/http.service';
import { ModalService } from '../common/services/modal.service';
import { SharedService } from '../common/services/sharedService';
import { CommonService } from '../common/services/common.service';
import { TranslateService, LangChangeEvent, DefaultLangChangeEvent } from '../../../node_modules/@ngx-translate/core';
import { Subject, Subscription } from '../../../node_modules/rxjs';
import { map as _map, orderBy as _orderBy, uniq as _uniq } from 'lodash';
import { environment, APIURLS, availableArchives } from '../../environments/environment';
import * as $ from '../../../node_modules/jquery';
import { AdvancedFilterParams } from '../common/models/AdvancedFilterParams';

export const values = {
  tat: 'TAT',
  browseMap: 'browse',
  explorer: {
    photo: 'photo',
    map: 'map'
  }
};
@Component({
  selector: 'app-find-pages',
  templateUrl: './find-pages.component.html',
  styleUrls: ['./find-pages.component.css']
})
export class FindPagesComponent implements OnInit {
  // @ViewChild('homepageSeach') private homepageSeach: TabsetComponent;
  // @ViewChild('warningsTemplate') private warningsTemplate: TemplateRef<any>;
  @ViewChild('homepageSeach', { static: false }) private homepageSeach: TabsetComponent;
	@ViewChild('warningsTemplate', { static: false }) private warningsTemplate: TemplateRef<any>;

  // downloadPageURL: any;
  // @ViewChild('downloadTableTemplate') private downloadTableTemplate: TemplateRef<any>;
  // @ViewChild('errorTableTemplate') private errorTableTemplate: TemplateRef<any>;
  public docID: string;
  public isIncludePage: boolean = false;
  downloade: string;
  downloadTablemodalRef: BsModalRef;
  downloadDocs: boolean = false;
  // downloadError: boolean = false;
  actiateBasicSearch = false;
  activateAdvSearch: boolean = true;
  isFiltertoggle = false;
  isDownArrowClicked: boolean;
  noSuggestedTermsFound: boolean;
  isChangeOperator: String = '';
  matchedOperator: any = [];
  currentIndex = 1;
  suggestedTimeOut: any;
  currentArchive: any;
  suggestedSearchTerms: Array<string> = [];
  typeaheadLoading: boolean;
  showAddQuotesOn: Boolean = false;
  stop$: Subject<any> = new Subject();
  pageContent: any;
  bothRCPAvailable: boolean;
  bothRGSAvailable: boolean;
  isMultiselectDropdownOpen: any;
  showHelp: Boolean = false;
  isadvancedSearchOpenCheck: any;
  copySearchTerm: any;
  showFilter: Boolean = true;
  disableSimpleSearch = false;
  showLoadingTxt: Boolean = false;
  estimatedCount: any = 0;
  isResetFilter = false;
  showPubDate: boolean;
  isBrowseByContentTypePhoto: Boolean = false;
  isBrowseByContentTypeMap: Boolean = false;
  private contentType: String;
  private searchKey: String;
  otherSearchArg: any;
  wileySubscribedArchives = [];
  rgsArchives = [];
  rcpArchives = [];
  unsubscribedArchives: string[];
  showJisclogo = false;
  isArabicLanguage: Boolean = false;
  unSubTranlate: Subscription;
  availableContentTypes: any = [];
  suggestedTermsListWidth: any;
  private warningssmodalRef: BsModalRef;
  selectArchive: any;
  selectedArchives: any;
  getFilterValue: any;
  warningObj: Array<any> = [];
  addSessionArchies = false;
  private homePageUrl: string;
  private photoMapsExplorerRef: BsModalRef;
  isShowWarningPopup: Boolean = false;
  newDownloadContent: Boolean = false;
  haveShortNameRCP1 = false;
  haveShortNameRGS1 = false;
  // documentList:any;

  constructor(
    private httpClient: HttpClient, private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private pageSettingService: PageSettingsService,
    private httpService: HttpService,
    private modalService: ModalService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private translate: TranslateService,
  ) {
    localStorage.removeItem('filter');
    
  //  const valfilterParams = new AdvancedFilterParams();
  //   this.pageSettingService.updateSearchSettings(valfilterParams);
    localStorage.removeItem('fromAdditionalArchive');
    const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
			const shortNames = [];
          	if (personalizationData.downloadContentArchives.length > 0) {
            for(let k=0; k< personalizationData.downloadContentArchives.length; k++) {
              shortNames.push(personalizationData.downloadContentArchives[k].shortName);
            }
			localStorage.setItem('wileyDCSelectedArchive', JSON.stringify(shortNames)); 
      sessionStorage.setItem('wileyDCSelectedArchive', JSON.stringify(shortNames)); 
		}
    // this.route.params.subscribe(
    //   (params) => {
    //     this.docID = params.docID;
    //     this.httpClient.get(environment.APIUrl + 'util/downloadxmlcontent?wileyId=' + this.docID + '&includePage=' + this.isIncludePage).subscribe((res) => {
    //       this.downloadPageURL = res['url']
    //     })
    //   });

    // const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
    // this.bothRCPAvailable = personalizationData.bothRCPAvailable;
    // this.bothRGSAvailable = personalizationData.bothRGSAvailable;

    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {  
      this.pageContent = data;
      if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
        // if(this.pageContent._downloadContentArchives.length === 0) {
        //   this.authPage = false;
        //   this.router.navigate(['/wiley' + '/unauthorized-page']);
        //   return;
        // }
        const rcpObject = {
          'shortName': 'RCP',
          'name': 'The Royal College of Physicians',
          'trialArchive': false,
          'logo': 'assets/config/rcp/rcp_logo.png',
        };
        const rgsObject = {
          'shortName': 'RGS',
          'name': 'The Royal Geographical Society (with IBG)',
          'trialArchive': false,
          'logo': 'assets/config/rgs/rgs-logo-other.png',
        };
        const getlocalVal = JSON.parse(localStorage.getItem('personalizationData'));
        if(getlocalVal.bothRCPForContentDownload && getlocalVal.bothRGSForContentDownload){
          for(let k=0; k< this.pageContent.downloadContentArchives.length;k++){
          if (this.pageContent.downloadContentArchives[k].shortName === 'RCP1') {
              this.pageContent.downloadContentArchives.push(rcpObject);
            } else if(this.pageContent.downloadContentArchives[k].shortName === 'RGS1') {
              this.pageContent.downloadContentArchives.push(rgsObject);
            }
          }
        } else {
          if (getlocalVal.bothRCPForContentDownload){
            for(let k=0; k< this.pageContent.downloadContentArchives.length;k++){
              if (this.pageContent.downloadContentArchives[k].shortName === 'RCP1') {
                  this.pageContent.downloadContentArchives.push(rcpObject);
              }
            }
          } else {
            for(let k=0; k< PageSettingsService.pageSettings.downloadContentArchives.length; k++) {
              if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RCP1") {
                const rcp1Object = {
                  'shortName': 'RCP',
                  'name': 'The Royal College of Physicians - Part I (1101 – 1862)',
                  'trialArchive': false,
                  'logo': 'assets/config/rcp/rcp_logo.png',
                };
                this.pageContent.downloadContentArchives.push(rcp1Object);
              } else if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RCP2") {
                const rcp2Object = {
                  'shortName': 'RCP',
                  'name': 'The Royal College of Physicians - Part II (1863 – 2000)',
                  'trialArchive': false,
                  'logo': 'assets/config/rcp/rcp_logo.png',
                };
                this.pageContent.downloadContentArchives.push(rcp2Object);
              }
            }
          }
          if (getlocalVal.bothRGSForContentDownload) {
            for(let k=0; k< this.pageContent.downloadContentArchives.length;k++){
             if(this.pageContent.downloadContentArchives[k].shortName === 'RGS1') {
                  this.pageContent.downloadContentArchives.push(rgsObject);
                }
              }
          } else {
            for(let k=0; k< PageSettingsService.pageSettings.downloadContentArchives.length; k++) {
              if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RGS1") {
                const rgs1Object = {
                  'shortName': 'RGS',
                  'name': 'The Royal Geographical Society (with IBG) - Part I (1485 -1899)',
                  'trialArchive': false,
                  'logo': 'assets/config/rgs/rgs-logo-other.png',
                };
                this.pageContent.downloadContentArchives.push(rgs1Object);
              } else if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RGS2") {
                const rgs2Object = {
                  'shortName': 'RGS',
                  'name': 'The Royal Geographical Society (with IBG) - Part II (1900 - 1983)',
                  'trialArchive': false,
                  'logo': 'assets/config/rgs/rgs-logo-other.png',
                };
                this.pageContent.downloadContentArchives.push(rgs2Object);
              }
            }
          }
        }
        // for(let k=0; k< PageSettingsService.pageSettings.downloadContentArchives.length; k++) {
        //   if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RCP1") {
        //     this.haveShortNameRCP1 = true;
        //     this.pageContent.downloadContentArchives.push(rcpObject);
        //   } else if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RCP2") {
        //     if(!this.haveShortNameRCP1) {
        //       this.pageContent.downloadContentArchives.push(rcpObject);
        //     }
        //   } else if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RGS1") {
        //     this.haveShortNameRGS1 = true;
        //     this.pageContent.downloadContentArchives.push(rgsObject);
        //   } else if(PageSettingsService.pageSettings.downloadContentArchives[k].shortName === "RGS2") {
        //     if (!this.haveShortNameRGS1) {
        //       this.pageContent.downloadContentArchives.push(rgsObject);
        //      }
        //   }
        // }
        }
        this.pageContent.downloadContentArchives = this.removeSelectedValFromArray('RCP1',this.pageContent.downloadContentArchives);
        this.pageContent.downloadContentArchives = this.removeSelectedValFromArray('RCP2',this.pageContent.downloadContentArchives);
        this.pageContent.downloadContentArchives = this.removeSelectedValFromArray('RGS1',this.pageContent.downloadContentArchives);
        this.pageContent.downloadContentArchives = this.removeSelectedValFromArray('RGS2',this.pageContent.downloadContentArchives);
        this.pageContent.availableFields = _orderBy(this.pageContent.availableFields, ['itemName'], ['asc']);
      // if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      //   if(this.pageContent._downloadContentArchives.length === 0) {
      //     this.authPage = false;
      //     this.router.navigate([this.homePageUrl + '/unauthorized-page']);
      //     return;
      //   } 
      //   if (this.bothRCPAvailable) {
      //     this.pageContent.downloadContentArchives = this.pageContent.downloadContentArchives.filter(item => item.shortName !== 'RCP1');
      //     this.pageContent.downloadContentArchives = this.pageContent.downloadContentArchives.filter(item => item.shortName !== 'RCP2');
      //   }
      //   if (this.bothRGSAvailable) {
      //     this.pageContent.downloadContentArchives = this.pageContent.downloadContentArchives.filter(item => item.shortName !== 'RGS1');
      //     this.pageContent.downloadContentArchives = this.pageContent.downloadContentArchives.filter(item => item.shortName !== 'RGS2');
      //   }
      //   const rcpObject = {
      //     'logo': 'assets/config/rcp/rcp_logo.png',
      //     'name': 'The Royal College of Physicians',
      //     'shortName': 'RCP',
      //     'trialArchive': false
      //   };
      //   const rgsObject = {
      //     'logo': 'assets/config/rgs/rgs-logo-other.png',
      //     'name': 'Royal Geographical Society (with IBG)',
      //     'shortName': 'RGS',
      //     'trialArchive': false
      //   };
      //   if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      //     let isRGSavailable = false;
      //     let isRCPAvailable = false;

      //     this.pageContent.downloadContentArchives.forEach(element => {
      //       if (element.shortName === 'RGS') {
      //         isRGSavailable = true;
      //       }
      //       if (element.shortName === 'RCP') {
      //         isRCPAvailable = true;
      //       }
      //     });

      //     if (this.bothRCPAvailable && !isRGSavailable && !isRCPAvailable) {
      //       this.pageContent.downloadContentArchives.push(rcpObject);
      //     }
      //     if (this.bothRGSAvailable && !isRGSavailable && !isRCPAvailable) {
      //       this.pageContent.downloadContentArchives.push(rgsObject);
      //     }
      //   }
      // }
      // this.pageContent.availableFields = _orderBy(this.pageContent.availableFields, ['itemName'], ['asc']);
    });
    
  }
  removeSelectedValFromArray(val, arr) {
    let value = val;
    arr = arr && arr.filter(item => item.shortName !== value)
    return arr;
  }
  dropdownValue: any;
  isWileyLibraryHelp: Boolean = false;
  controlShortcut: any;
  operationSystem: any;
  controlShift: any;
  performingAdvSearch: any = 'Performing Advanced Search';
  searchPlaceholder: any = 'What are you searching for?';
  currLanguage: any;
  titleName: string;
  message: string;
  unSubDropdownToggle: Subscription;
  authPage = false;
  helpDropDown: any;

  ngOnInit() {
    this.sharedService.updateFacetFlag(false);
    // if (sessionStorage.getItem('wileySubscribedArchives_tab1')) {
    //   sessionStorage.removeItem('wileySubscribedArchives_tab1');
    // }
    // if (sessionStorage.getItem('unsubscribedArchives_tab2')) {
    //   sessionStorage.removeItem('unsubscribedArchives_tab2');
    // }
    // if (localStorage.getItem('wileySubscribedArchives_tab1')) {
    //   localStorage.removeItem('wileySubscribedArchives_tab1');
    // }
    // if (localStorage.getItem('unsubscribedArchives_tab2')) {
    //   localStorage.removeItem('unsubscribedArchives_tab2');
    // }
    if (sessionStorage.getItem('SearchResultsPageURLFromSearch')) {
      sessionStorage.removeItem('SearchResultsPageURLFromSearch');
    }
    if (sessionStorage.getItem('currentSearchDocumentId')) {
      sessionStorage.removeItem('currentSearchDocumentId');
    }
    if (sessionStorage.getItem('currentOffsetIndex')) {
      sessionStorage.removeItem('currentOffsetIndex');
    }
    // let archivesArr: any;
    // const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
    // if (getVal) {
    //     archivesArr = getVal;
    //     archivesArr.forEach(element => {
    //       this.wileySubscribedArchives.push(element.shortName);
    //       if (element.shortName === 'RGS1') {
    //         this.rgsArchives.push(element);
    //       }
    //       if (element.shortName === 'RGS2') {
    //         this.rgsArchives.push(element);
    //       }
    //       if (element.shortName === 'RCP1') {
    //         this.rcpArchives.push(element);
    //       }
    //       if (element.shortName === 'RCP2') {
    //         this.rcpArchives.push(element);
    //       }
    //     });
    //     setTimeout(() => {
    //       this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
    //       sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //       sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
  
    //       localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //       localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    //     }, 1000);
    // } else {
    //   this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + localStorage.getItem('currentLocation')).pipe(takeUntil(this.stop$)).subscribe((res) => {
    //     archivesArr = res;
    //     archivesArr.forEach(element => {
    //       this.wileySubscribedArchives.push(element.shortName);
    //       if (element.shortName === 'RGS1') {
    //         this.rgsArchives.push(element);
    //       }
    //       if (element.shortName === 'RGS2') {
    //         this.rgsArchives.push(element);
    //       }
    //       if (element.shortName === 'RCP1') {
    //         this.rcpArchives.push(element);
    //       }
    //       if (element.shortName === 'RCP2') {
    //         this.rcpArchives.push(element);
    //       }
    //     });
    //     setTimeout(() => {
    //       this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
    //       sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //       sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
  
    //       localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //       localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    //     }, 1000);
    //   }, err => { });
    // }
   


    if (window.location.pathname.includes('baas')) {
      this.showJisclogo = true;
    } else {
      this.showJisclogo = false;
    }
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    // SearchService.documentObservable.subscribe((data)=>{
    //   console.log(data);
    //  if(data){
    //   this.documentList = data;
    //  }      
    // })
    this.showAdvDropdown();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      // to avoid the unwanted click in header part
      // this.modalService.hideLoader();
    }, 2500);
    setTimeout(() => {
      if (document.querySelector('.grecaptcha-badge')) {
        (document.querySelector('.grecaptcha-badge') as HTMLElement).style.visibility = 'hidden';
        (document.querySelector('.grecaptcha-badge') as HTMLElement).style.opacity = '0';
      }
    }, 3500);
    const browseMapVisitedCount = sessionStorage.getItem('browseMapVisitedCount');
    if (browseMapVisitedCount) {
      sessionStorage.removeItem('browseMapVisitedCount');
    }
    this.sharedService.language.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.dropdownValue = res;
      if (this.dropdownValue.help === true) {
        this.showHelp = true;
      } else {
        this.showHelp = false;
      }
      if (this.dropdownValue.wolhelp === true) {
        this.isWileyLibraryHelp = true;
      } else {
        this.isWileyLibraryHelp = false;
      }
    });
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.operationSystem = 'Shift + Alt';
      this.controlShift = 'Ctrl + Shift';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.operationSystem = 'Shift + Option';
      this.controlShift = 'Cmd + Shift';
    }
    this.unSubTranlate = this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: any) => {
      this.selectArchive = res.selectArchive;
      this.performingAdvSearch = res.performingAdvSearch || 'Performing Advanced Search';
    });
    this.translate.onDefaultLangChange.pipe(takeUntil(this.stop$)).subscribe((event: DefaultLangChangeEvent) => {
      this.performingAdvSearch = event.translations.Common.performingAdvSearch || 'Performing Advanced Search';
      this.searchPlaceholder = event.translations.HomePage.searchPlaceholder || 'What are you searching for?';
    });
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      this.currLanguage = event.lang;
      if (event.translations.Common.performingAdvSearch) {
        this.performingAdvSearch = event.translations.Common.performingAdvSearch || 'Performing Advanced Search';
      }
      if (event.translations.HomePage.searchPlaceholder) {
        this.searchPlaceholder = event.translations.HomePage.searchPlaceholder || 'What are you searching for?';
      }
    });
    this.translate.get('warnings.rai.titleName').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.titleName = res;
    });
    this.translate.get('warnings.rai.message').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.message = res;
    });
    this.currentArchive = PageSettingsService.pageSettings.currentArchive;
    setTimeout(() => {
      this.homePageUrl = `/${(this.currentArchive + '').toLowerCase()}`;
    });
    sessionStorage.removeItem('mapView');
    this.unSubDropdownToggle = this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.helpDropDown = res.help;
    });
    setTimeout(() => {
      // const response = JSON.parse(localStorage.getItem('personalizationData'));
      let response;
      const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
      if (getVal) {
          response = getVal;          
          if (getVal) {
            getVal.forEach(element => {
                this.wileySubscribedArchives.push(element.shortName);
                if (element.shortName === 'RGS1') {
                  this.rgsArchives.push(element);
                }
                if (element.shortName === 'RGS2') {
                  this.rgsArchives.push(element);
                }
                if (element.shortName === 'RCP1') {
                  this.rcpArchives.push(element);
                }
                if (element.shortName === 'RCP2') {
                  this.rcpArchives.push(element);
                }
              });
            setTimeout(() => {
              this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
              sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
              sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
      
              localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
              localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
            }, 1000);
            // if (sessionStorage.getItem('subscribedArchivesForFacet')) {
            //   sessionStorage.removeItem('subscribedArchivesForFacet')
            // }
            // if (localStorage.getItem('subscribedArchivesForFacet')) {
            //   localStorage.removeItem('subscribedArchivesForFacet')
            // }
            // sessionStorage.setItem('subscribedArchivesForFacet', JSON.stringify(getVal));
            // localStorage.setItem('subscribedArchivesForFacet', JSON.stringify(getVal));
            if (window.location.pathname.includes('shibboleth') && localStorage.getItem('auth_token')) {
              this.router.navigate(['wiley']);
            } else {
              if (this.currentArchive.toUpperCase() !== 'WILEY' &&
                window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
                window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
                const customerSubscribedArchives = _map(response, 'shortName');
                if (customerSubscribedArchives.indexOf(this.currentArchive.toUpperCase()) === -1) {
                  this.authPage = false;
                  this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                } else {
                  this.authPage = true;
                }
              } else {
                const customerSubscribedArchives1 = _map(response, 'shortName');
                let currentArchiveName;
                if (this.currentArchive.toUpperCase() === 'RGS') {
                  if (customerSubscribedArchives1.includes('RGS1')) {
                    currentArchiveName = 'RGS1';
                  } else {
                    currentArchiveName = 'RGS2';
                  }
                } else if (this.currentArchive.toUpperCase() === 'RCP') {
                  if (customerSubscribedArchives1.includes('RCP1')) {
                    currentArchiveName = 'RCP1';
                  } else {
                    currentArchiveName = 'RCP2';
                  }
                }
                setTimeout(() => {
                  if ((this.currentArchive.toUpperCase() === 'WILEY' ||
                    customerSubscribedArchives1.includes(currentArchiveName.toUpperCase()))) {
                    this.authPage = true;
                  } else {
                    this.authPage = false;
                    this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                  }
                }, 500);
              }
            }
          }
      } else {
        let locId = localStorage.getItem('currentLocation');
        this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + locId).pipe(
        takeUntil(this.stop$)).subscribe((res: any) => {
          response = res;          
          if (res) {
            res.forEach(element => {
              this.wileySubscribedArchives.push(element.shortName);
              if (element.shortName === 'RGS1') {
                this.rgsArchives.push(element);
              }
              if (element.shortName === 'RGS2') {
                this.rgsArchives.push(element);
              }
              if (element.shortName === 'RCP1') {
                this.rcpArchives.push(element);
              }
              if (element.shortName === 'RCP2') {
                this.rcpArchives.push(element);
              }
            });
          setTimeout(() => {
            this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
            sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
            sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    
            localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
            localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
          }, 1000);
            // if (sessionStorage.getItem('subscribedArchivesForFacet')) {
            //   sessionStorage.removeItem('subscribedArchivesForFacet')
            // }
            // if (localStorage.getItem('subscribedArchivesForFacet')) {
            //   localStorage.removeItem('subscribedArchivesForFacet')
            // }
            sessionStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
            localStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
            if (window.location.pathname.includes('shibboleth') && localStorage.getItem('auth_token')) {
              this.router.navigate(['wiley']);
            } else {
              if (this.currentArchive.toUpperCase() !== 'WILEY' &&
                window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
                window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
                const customerSubscribedArchives = _map(response, 'shortName');
                if (customerSubscribedArchives.indexOf(this.currentArchive.toUpperCase()) === -1) {
                  this.authPage = false;
                  this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                } else {
                  this.authPage = true;
                }
              } else {
                const customerSubscribedArchives1 = _map(response, 'shortName');
                let currentArchiveName;
                if (this.currentArchive.toUpperCase() === 'RGS') {
                  if (customerSubscribedArchives1.includes('RGS1')) {
                    currentArchiveName = 'RGS1';
                  } else {
                    currentArchiveName = 'RGS2';
                  }
                } else if (this.currentArchive.toUpperCase() === 'RCP') {
                  if (customerSubscribedArchives1.includes('RCP1')) {
                    currentArchiveName = 'RCP1';
                  } else {
                    currentArchiveName = 'RCP2';
                  }
                }
                setTimeout(() => {
                  if ((this.currentArchive.toUpperCase() === 'WILEY' ||
                    customerSubscribedArchives1.includes(currentArchiveName.toUpperCase()))) {
                    this.authPage = true;
                  } else {
                    this.authPage = false;
                    this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                  }
                }, 500);
              }
            }
          }
        });
      }     
      const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
      if (personalizationData) {
        const archiveArry = _map(personalizationData.archives, 'shortName');
        const archives =
          PageSettingsService.pageSettings.currentArchive === 'WILEY' ? archiveArry : [PageSettingsService.pageSettings.currentArchive];
        if (archives.length > 0) {
          this.commonService.getDropdownLimiters(archives, res => {
            if (res) {
              this.availableContentTypes = _orderBy(res.availableContentTypes, ['name'], ['asc']);
            }
          });
        }
      } else {
        const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
        if (personalizationV2) {
            localStorage.setItem('personalizationData', JSON.stringify(personalizationV2));
            const archiveArry = _map(personalizationData.archives, 'shortName');
            const archives =
              PageSettingsService.pageSettings.currentArchive === 'WILEY' ?
                archiveArry : [PageSettingsService.pageSettings.currentArchive];
            if (archives.length > 0) {
              this.commonService.getDropdownLimiters(archives, limiterRes => {
                if (limiterRes) {
                  this.availableContentTypes = _orderBy(limiterRes.availableContentTypes, ['name'], ['asc']);
                }
              });
            }
        } else {
          this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] + localStorage.getItem('currentLocation') ||
          PageSettingsService.pageSettings.currentLocation, (res) => {
            if (res) {
              localStorage.setItem('personalizationData', JSON.stringify(response));
              const archiveArry = _map(personalizationData.archives, 'shortName');
              const archives =
                PageSettingsService.pageSettings.currentArchive === 'WILEY' ?
                  archiveArry : [PageSettingsService.pageSettings.currentArchive];
              if (archives.length > 0) {
                this.commonService.getDropdownLimiters(archives, limiterRes => {
                  if (limiterRes) {
                    this.availableContentTypes = _orderBy(limiterRes.availableContentTypes, ['name'], ['asc']);
                  }
                });
              }
            }
        }, err => { });
        }
      }
    }, 2000);
    this.suggestedTermsListWidth = $('#searchid').width();
  }


  showAdvDropdown() {
    this.ADSE(true);
    this.isFiltertoggle = true;
    this.isMultiselectDropdownOpen = false;
  }

  ADSE(event: any) {
    this.isadvancedSearchOpenCheck = event;
    this.pageContent.showAdvancedFilter = true;
    if (this.pageContent.showAdvancedFilter) {
      if (this.pageContent.searchTerm) {
        this.copySearchTerm = this.pageContent.searchTerm;
        this.pageContent.searchTerm = '';
      }
    } else {
      this.pageContent.searchTerm = this.copySearchTerm;
    }
    this.showHelp = false;
    this.sharedService.changeadvValue('true');

  }

  openHelp(event) {
    this.isMultiselectDropdownOpen = event;
    this.isadvancedSearchOpenCheck = event;
    event.stopPropagation();
    /*
    * send updated valued to all dropdowns
    * and open help dropdown
    */
    if (!this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: true,
        language: false,
        shareDocument: false

      });
    } else if (this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false
      });
    }

  }

  onSearchChange(term) {
    
    this.isDownArrowClicked = false;
    this.noSuggestedTermsFound = false;
    const checkOperators = ['AND', 'OR', 'NOT'];
    term = term.trim();
    const splittedTermsArray = this.stringtoArray(term);
    this.isChangeOperator = '';
    this.isChangeOperator = splittedTermsArray.join('","');

    const searchTerms = term.match(/[^\s"]+|"([^"])+"~[0-9]{1,5}|"([^"])+"/g);
    if (searchTerms) {
      searchTerms.forEach(key => {
        if (/^".*"$/.test(key)) {
          this.isChangeOperator = '';
        }
      });
    }

    this.currentIndex = 1;
    if (this.suggestedTimeOut) {
      clearTimeout(this.suggestedTimeOut);
    }
    const archive = PageSettingsService.pageSettings.currentArchive === 'WILEY' ?
      PageSettingsService.pageSettings.currentSelectedArchives : [PageSettingsService.pageSettings.currentArchive];
    this.suggestedTimeOut = setTimeout(() => {
      if (term.includes('"')) {
        term = term.replace(/"/g, ' ');
      }
      const searchTermwithSpaces = (/\s/.test(term));
      let stopWordsFromString;
      if (searchTermwithSpaces) {
        stopWordsFromString = term.split(' ');
      }
      if (stopWordsFromString) {
        const BooleanOperators = ['AND', 'OR', 'NOT'];
        stopWordsFromString.forEach((op, index) => {
          if ((BooleanOperators.indexOf(stopWordsFromString[index]) > -1 &&
            BooleanOperators.indexOf(stopWordsFromString[index + 1]) > -1) ||
            stopWordsFromString[0] === 'AND' || stopWordsFromString[0] === 'OR') {
            stopWordsFromString[index] = '';
          }
          if (stopWordsFromString[index] === '' && BooleanOperators.indexOf(stopWordsFromString[index + 1]) > -1) {
            stopWordsFromString[index + 1] = '';
          }
          if ((stopWordsFromString[stopWordsFromString.length - 1] === 'AND') ||
            (stopWordsFromString[stopWordsFromString.length - 1] === 'OR') ||
            ((stopWordsFromString[stopWordsFromString.length - 1] === 'NOT'))) {
            stopWordsFromString.pop();
          }
        });
        stopWordsFromString = stopWordsFromString.filter(elem => {
          return elem.trim() !== '';
        });
        term = stopWordsFromString.toString().replace(/,/g, ' ');
        this.callSuggestedTerms(term, archive);
      } else {
        this.callSuggestedTerms(term, archive);
      }
    }, 1000);
  }

  callSuggestedTerms(term, archive) {
    const wileySelectedArchiveList = JSON.parse((localStorage.getItem('wileySelectedArchive')));
    if (this.currentArchive === 'RGS' || this.currentArchive === 'RCP') {
      archive = wileySelectedArchiveList;
    }
    if (term.length > 2 && archive.length > 0) {
      term = term.replace(/[^a-zA-Z0-9 ?*-]/g, '');
      this.suggestedSearchTerms = [];
      if (term !== '') {
        this.typeaheadLoading = true;
        // tslint:disable-next-line:max-line-length
        this.httpClient.post(environment.APIUrl + APIURLS['getSuggestedTerms'], { 'lookup': term, 'archive': archive }).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res) {
            if (res['suggesterResult']) {
              if (res['suggesterResult'].length > 0) {
                this.suggestedSearchTerms = res['suggesterResult'];
                this.showAddQuotesOn = false;
                this.typeaheadLoading = false;
                this.noSuggestedTermsFound = false;
              } else {
                // if (res['spellCheckResult'].length > 0 && res['suggesterResult'].length > 0) {
                this.suggestedSearchTerms = [];
                this.typeaheadLoading = false;
                this.noSuggestedTermsFound = true;
                // }
              }
            } else {
              this.suggestedSearchTerms = [];
              this.typeaheadLoading = false;
              this.noSuggestedTermsFound = true;
            }
          }
        });
      }
    } else {
      this.suggestedSearchTerms = [];
    }
  }

  stringtoArray(terms) {
    const checkOperators = ['AND', 'OR', 'NOT'];
    const newOperatorArray = [];
    this.matchedOperator = [];
    const splittedTermsArray = terms.trim().split(' ');
    splittedTermsArray.forEach(element => {
      if (checkOperators.indexOf(element) === -1) {
        newOperatorArray.push(element.toUpperCase());
      }
    });
    checkOperators.forEach(element => {
      if (newOperatorArray.indexOf(element) !== -1) {
        this.matchedOperator.push(element);
      }
    });
    return this.matchedOperator;
  }

  /**
   * Search Filter 
   */
  receiveAdvSelectMessage(event) {
    
    this.isadvancedSearchOpenCheck = event;
  }
  disableSearch(event) {
    this.disableSimpleSearch = !event;
  }
  isShowLoadingText(event: boolean) {
    this.showLoadingTxt = event;
  }
  receiveEstimatedCount(event: any) {
    this.estimatedCount = event;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.showPubDate = false;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      if (this.suggestedSearchTerms) {
        if (this.isDownArrowClicked) {
          if (this.suggestedSearchTerms.length > 0) {
            const searchTerm = $('.active-suggested-term .sugg-term').text().trim();
            if (this.pageContent.searchTerm.includes('"')) {
              this.pageContent.searchTerm = '"' + searchTerm + '"';
            } else {
              this.pageContent.searchTerm = searchTerm;
            }
            setTimeout(() => {
              document.getElementById('searchid').focus();
            }, 500);
          }
        }
      }
    }
    if (e.keyCode === 40) {
      if (this.suggestedSearchTerms) {
        if (this.suggestedSearchTerms.length > 0) {
          if (this.currentIndex < this.suggestedSearchTerms.length) {
            this.currentIndex++;
            this.isDownArrowClicked = true;
            document.getElementById('searchid').blur();
            document.getElementById('handleFocusOnSuggestedTerms').focus();
          }
        }
      }
    }
    if (e.keyCode === 38) {
      if (this.suggestedSearchTerms) {
        if (this.suggestedSearchTerms.length > 0) {
          if (this.currentIndex > 1) {
            this.isDownArrowClicked = true;
            this.currentIndex--;
            document.getElementById('searchid').blur();
            document.getElementById('handleFocusOnSuggestedTerms').focus();
          }
        }
      }
    }
    if (e.ctrlKey && e.keyCode === 13) {
      setTimeout(() => {
        if (this.pageContent.searchTerm) {
          this.pageContent.searchTerm = '"' + this.pageContent.searchTerm + '"';
        }
      }, 500);
    }
    if (e.shiftKey && e.altKey) {
      switch (e.keyCode) {
        case 77:
          this.verifyAndGotoSearch('', 'Manuscripts');
          break;
        case 79:
          const contentTypeArr = [];
          this.pageContent.availableContentTypes.forEach(contentType => {
            contentTypeArr.push(contentType.name);
          });
          if (contentTypeArr.includes('Monographs')) {
            this.verifyAndGotoSearch('', 'Monographs');
          }
          break;
        case 80:
          this.verifyAndGotoSearch('', 'Photographs');
          break;
        case 85:
          this.verifyAndGotoSearch('', 'Maps');
          break;
        case 74:
          this.verifyAndGotoSearch('', '', values.tat);
          break;
        case 71:
          this.verifyAndGotoSearch('', '', values.explorer.photo);
          break;
        case 89:
          this.verifyAndGotoSearch('', '', values.tat);
          break;
      }
    } else if (e.ctrlKey && e.altKey) {
      switch (e.keyCode) {
        case 77:
          this.verifyAndGotoSearch('', '', values.browseMap);
          break;
        case 74:
          this.homepageSeach.tabs[1].active = true;
          break;
        case 65:
          if ($('.option-list').hasClass('show-options')) {
            $('.option-list').hide();
            $('.option-list').removeClass('show-options');
            $('.archive-btnopen .dropdown-fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
          } else {
            $('.option-list').show();
            $('.option-list').addClass('show-options');
            $('.archive-btnopen .dropdown-fa').removeClass('fa-chevron-down').addClass('fa-chevron-up');
          }
          e.preventDefault();
          break;
      }
    } else if (e.ctrlKey && e.shiftKey) {
      switch (e.keyCode) {
        case 83:
          this.homepageSeach.tabs[0].active = true;
          break;
      }
    }
  } 

  getFilterFormValue(formValues) {
    
    this.isShowWarningPopup = formValues.status;
  }

  verifyAndGotoSearch(searchKey, contentType?: String, arg?: any) {
    this.searchService.getdocument();      
  }

  /*
   * DESC: Navigate to respective pages and on selection after verfing warning popup(s)
   */
  // gotoRespectivePages() {
  //   switch (true) {
  //     case (this.contentType ? true : false):

  //       this.setContentType(this.contentType);
  //       break;
  //     case (this.otherSearchArg === values.tat):
  //       // this.goToTermFrequency(0);
  //       this.gotoAnalysisHub();
  //       break;
  //     case (this.otherSearchArg === values.explorer.photo):
  //       this.gotoPhotoExplorer();
  //       break;
  //     case (this.otherSearchArg === values.explorer.map):
  //       this.gotoMapExplorer();
  //       break;
  //     case (this.otherSearchArg === values.browseMap):
  //       this.gotoSearchResultPage();
  //       break;
  //     default:
  //       this.goToSearchPage(this.searchKey); 
  //       break;
  //   }
  // }

  // gotoAnalysisHub(page: string = 'frequency') {
  //   sessionStorage.removeItem('termPopularityUrl');
  //   sessionStorage.removeItem('currentAvtiveIndex');
  //   sessionStorage.removeItem('termFrequency');
  //   sessionStorage.removeItem('termPopularity');
  //   sessionStorage.removeItem('termGroups');
  //   sessionStorage.removeItem('display');
  //   sessionStorage.removeItem('TFLimiter');
  //   sessionStorage.removeItem('explorerLimiter');
  //   sessionStorage.removeItem('TATLimiters');
  //   sessionStorage.removeItem('lastResultPageSearchHistory');
  //   sessionStorage.removeItem('TGLastTileIfo');
  //   sessionStorage.removeItem('selectedGroup');
  //   sessionStorage.removeItem('TGTabSelection');
  //   PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(pageSettings => {
  //     this.searchService.buildFilterParams(pageSettings.advancedFilterParam, (paramsBuilt) => {
  //       this.searchService.buildURL(paramsBuilt, (url) => {
  //         const data = {};
  //         data['preventLimiters'] = true;
  //         data['filterParam'] = url;
  //         data['invokedFrom'] = 'home-page';
  //         // data['searchTerm'] = pageSettings.searchTerm ? pageSettings.searchTerm : '';
  //         this.router.navigate([`${this.homePageUrl}/analysis-hub/${page}`, data]);
  //       });
  //     });
     
  //   });
  //   if (sessionStorage.getItem('termFrequency')) {
  //     sessionStorage.removeItem('termFrequency');
  //   }
  //   if (sessionStorage.getItem('wileySuggestedterms')) {
  //     sessionStorage.removeItem('wileySuggestedterms');
  //   }
  // }

  // setContentType(contentType, mapFlag?: any, isFromRightClick?: any) {
  //   sessionStorage.removeItem('termPopularityUrl');
  //   sessionStorage.removeItem('termFrequencyUrl');
  //   sessionStorage.removeItem('currentAvtiveIndex');
  //   sessionStorage.removeItem('termFrequency');
  //   sessionStorage.removeItem('termPopularity');
  //   sessionStorage.removeItem('termGroups');
  //   sessionStorage.removeItem('display');
  //   sessionStorage.removeItem('TFLimiter');
  //   sessionStorage.removeItem('TATLimiters');
  //   sessionStorage.removeItem('explorerLimiter');
  //   sessionStorage.setItem('isInMapView', 'false');
  //   sessionStorage.removeItem('lastResultPageSearchHistory');
  //   const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
  //   currentSearchSettings.docSubType = [];
  //   currentSearchSettings.contentType = [];
  //   if (contentType === 'Photographs') {
  //     currentSearchSettings.docSubType = ['Photograph'];
  //   } else if (contentType === 'Maps') {
  //     currentSearchSettings.docSubType = ['Map'];
  //   } else {
  //     currentSearchSettings.contentType = [contentType];
  //   }
  //   this.pageSettingService.updateSearchSettings(currentSearchSettings);
  //   const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
  //   const currentSelectedArchivesSet = _map(personalizationData.archives, 'shortName');
  //   /**
  //    * update page setting service dropdown values
  //    */
  //   setTimeout(() => {
  //     this.sharedService.setAdvSearchArchiveSelection(currentSelectedArchivesSet);
  //   }, 2000);
  //   this.commonService.getDropdownLimiters(currentSelectedArchivesSet, callback => { }, true);
  //   if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
  //     currentSearchSettings.archive = currentSelectedArchivesSet;
  //   } else if (PageSettingsService.pageSettings.currentArchive === 'RGS') {
  //     currentSearchSettings.archive = ['RGS1', 'RGS2'];
  //   } else if (PageSettingsService.pageSettings.currentArchive === 'RCP') {
  //     currentSearchSettings.archive = ['RCP1', 'RCP2'];
  //   } else {
  //     currentSearchSettings.archive = [PageSettingsService.pageSettings.currentArchive];
  //   }
  //   if (currentSelectedArchivesSet.length > 0) {
  //     this.searchService.
  //       goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm,
  //         currentSearchSettings, contentType, '', isFromRightClick);
  //   }

  // }


  // gotoPhotoExplorer() {
  //   sessionStorage.removeItem('TFLimiter');
  //   sessionStorage.removeItem('explorerLimiter');
  //   sessionStorage.removeItem('TATLimiters');
  //   this.router.navigate([`${this.homePageUrl}/explorer/photo`]);
  //   if (this.photoMapsExplorerRef) {
  //     this.photoMapsExplorerRef.hide();
  //   }
  // }
  // gotoMapExplorer() {
  //   sessionStorage.removeItem('TFLimiter');
  //   sessionStorage.removeItem('explorerLimiter');
  //   sessionStorage.removeItem('TATLimiters');
  //   this.router.navigate([`${this.homePageUrl}/explorer/map`]);
  //   if (this.photoMapsExplorerRef) {
  //     this.photoMapsExplorerRef.hide();
  //   }
  // }

  // goToSearchPage(searchKey) {
  //   sessionStorage.removeItem('termPopularityUrl');
  //   sessionStorage.removeItem('termFrequencyUrl');
  //   sessionStorage.removeItem('currentAvtiveIndex');
  //   sessionStorage.removeItem('termFrequency');
  //   sessionStorage.removeItem('termPopularity');
  //   sessionStorage.removeItem('termGroups');
  //   sessionStorage.removeItem('display');
  //   sessionStorage.removeItem('TFLimiter');
  //   sessionStorage.setItem('isInMapView', 'false');
  //   sessionStorage.removeItem('TATLimiters');
  //   sessionStorage.removeItem('explorerLimiter');
  //   sessionStorage.removeItem('lastResultPageSearchHistory');
  //   if (this.pageContent.showAdvancedFilter) {  
  //     const data = {
  //       url:'wisly/download'
  //     }
  //     SearchService.documentUrlObservable.next(data)    
  //     this.sharedService.invokeAdvSearchCall(this.pageContent.showAdvancedFilter);     
  //     return 
  //   }
   
  //   const searchvalue = searchKey.trim();
  //   this.sharedService.updateFacetFlag(false);
  //   this.searchService.goToSearchPage(searchvalue, true);
  // }

  // gotoSearchResultPage() {
  //   this.searchService.loadMapPos = [];
  //   let archives: any;
  //   if (PageSettingsService.pageSettings.currentArchive === 'WILEY'
  //     || PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP') {
  //     const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
  //     archives = _map(personalizationData.archives, 'shortName');
  //   } else {
  //     archives = [PageSettingsService.pageSettings.currentArchive];
  //   }
  //   const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
  //   currentSearchSettings.archive = archives;
  //   sessionStorage.setItem('isInMapView', 'true');
  //   this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  // }

  resetClicked(event) {
    this.isResetFilter = true;
    setTimeout(() => {
      this.isResetFilter = false;
    }, 1000);
    event.stopPropagation();

  }
   
  onSelectedArchivesChange(selectedArchives) {
    sessionStorage.setItem('wileyDCSelectedArchive', JSON.stringify(selectedArchives));
    localStorage.setItem('wileyDCSelectedArchive', JSON.stringify(selectedArchives));
    let getStoredArchives = JSON.parse(localStorage.getItem('wileyDCSelectedArchive'));
    let getSelectedArchives = JSON.parse(localStorage.getItem('personalizationData'));
    getSelectedArchives = getSelectedArchives.downloadContentArchives;
    let initialArry= [];
    for(let k=0;k<getSelectedArchives.length;k++) {
      initialArry.push(getSelectedArchives[k].shortName);
    }
    if (getStoredArchives.length > 0) {
      if(getStoredArchives.includes('RCP')) {
        const haveRCP1 = initialArry.includes('RCP1');
        if(haveRCP1) {
          getStoredArchives.push('RCP1');
        }
        const haveRCP2 = initialArry.includes('RCP2');
        if(haveRCP2) {
          getStoredArchives.push('RCP2');
        }
        getStoredArchives = this.removeValueFromArray('RCP',getStoredArchives);
      }
      if (getStoredArchives.includes('RGS')){
        const haveRGS1 = initialArry.includes('RGS1');
        if(haveRGS1) {
          getStoredArchives.push('RGS1');
        }
        const haveRGS2 = initialArry.includes('RGS2');
        if(haveRGS2) {
          getStoredArchives.push('RGS2');
        }
        getStoredArchives = this.removeValueFromArray('RGS',getStoredArchives);
      }
    } 
    sessionStorage.setItem('wileyDCSelectedArchive', JSON.stringify(getStoredArchives));
    localStorage.setItem('wileyDCSelectedArchive', JSON.stringify(getStoredArchives));
  }
  removeValueFromArray(val, arr) {
    let value = val;
    arr = arr.filter(item => item !== value)
    return arr;
  }
  // ngAfterViewInit() {
  //   //  this.getDocumentLoad();    
  // }


  // getDocumentLoad(document?) {
  //   this.route.params.subscribe(
  //     (params) => {
  //       if (document) this.docID = document; else this.docID = params.docID;
  //       // this.httpClient.get(environment.APIUrl + 'util/downloadxmlcontent?wileyId=' + this.docID + '&includePage=' + this.isIncludePage).subscribe((res) => {
  //       //   if (res) {
  //       //     this.downloadPageURL = res['url']
  //       //     this.downloadPageURL ? (this.downloadDocs = true, this.downloadError = false) : (this.downloadError = true, this.downloadDocs = false)
  //       //   }
  //       // })
  //     });
  //   // setTimeout(() => {
  //   //   if (this.downloadPageURL) {
  //   //     this.downloadDocs = true;
  //   //     this.downloadError = false;
  //   //     this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
  //   //   } else {
  //   //     this.downloadError = true;
  //   //     this.downloadDocs = false;
  //   //     this.downloadTablemodalRef = this.bsModalService.show(this.errorTableTemplate, { backdrop: true, ignoreBackdropClick: true });


  //   //   }
  //   // }, 1500);
  // }

  // handleSelected($event) {
  //   if ($event.target.checked) {
  //     this.isIncludePage = true;
  //     // this.httpClient.get(environment.APIUrl + 'util/downloadxmlcontent?wileyId=' + this.docID + '&includePage=' + this.isIncludePage).subscribe((res) => {
  //     //   this.downloadPageURL = res['url']
  //     // })
  //   } else {
  //     this.isIncludePage = false;
  //     // this.httpClient.get(environment.APIUrl + 'util/downloadxmlcontent?wileyId=' + this.docID + '&includePage=' + this.isIncludePage).subscribe((res) => {
  //     //   this.downloadPageURL = res['url']
  //     // })
  //   }
  // }
receiveMessage(event) {
    this.isMultiselectDropdownOpen = !this.isMultiselectDropdownOpen;
    // when ever archives changed limitor dropdown values also should change
    this.pageSettingService.populateDropDowns('');
    this.showHelp = false;
  }
  backtoHome() {
    this.router.navigate(['/wiley']);
  }

  // downloadSearch(document) {
  //   console.log(document);
  //   this.getDocumentLoad(document);
  // }

}

// =============================advance search=============================



