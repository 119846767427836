import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoExplorerComponent } from './photo-explorer/photo-explorer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { TextAnalysisLimiterModule } from '../common/modules/text-analysis-limiter/text-analysis-limiter.module';
import { ExplorerRoutingModule } from './explorer-routing.module';
import { MapExplorerComponent } from './map-explorer/map-explorer.component';
// import { Attributes } from '../../assets/Js/ng-lazyload-image/src/types';
import { interval } from 'rxjs';
// import { LazyLoadImageModule } from '../../assets/Js/ng-lazyload-image/src/lazyload-image.module';
import { LazyLoadImageModule, Attributes } from 'ng-lazyload-image';
import { ReadMoreModule } from '../common/modules/read-more/read-more.module';
import { BasicImageViewerComponent } from '../common/components/basic-image-viewer/basic-image-viewer.component';
import { MapModule } from '../common/modules/map/map.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ExplorerService } from './services/explorer.service';
import { RangeSliderModule } from '../common/modules/range-slider/range-slider.module';
import { UnicodeConverterModule } from '../unicode/unicode-converter/unicode-converter.module';
import {ExplorerUnicodeEncodeEntityPipe} from '../common/pipes/filter';
// This will trigger an event every second
export function getObservable(attributes: Attributes) {
  return interval(1000);
}
// This will trigger an callback if the image is not loaded
export function setErrorImage({ element, errorImagePath, useSrcset }) {
  // if (localStorage.getItem('accessType') !== 'guest') {
  //   location.reload();
  // }
  element.src = errorImagePath;
}
@NgModule({
    imports: [
        RangeSliderModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        PopoverModule,
        CarouselModule.forRoot(),
        ExplorerRoutingModule,
        MapModule,
        LazyLoadImageModule,
        TextAnalysisLimiterModule,
        ReadMoreModule,
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        UnicodeConverterModule
    ],
    declarations: [
        PhotoExplorerComponent,
        MapExplorerComponent,
        BasicImageViewerComponent,
        ExplorerUnicodeEncodeEntityPipe
    ],
    exports: [
        PhotoExplorerComponent,
        MapExplorerComponent,
        BasicImageViewerComponent
    ],
    providers: [ExplorerService]
})
export class ExplorerModule { }
