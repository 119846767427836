
import { takeUntil } from 'rxjs/operators';

/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {
  Component,
  Input,
  TemplateRef,
  ElementRef,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  AfterContentInit,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { PageSettingsService } from './../common/services/page-settings.service';
import { SearchService } from './../common/services/search.service';
import { HttpService } from './../common/services/http.service';
import { ModalService } from './../common/services/modal.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { UrlUtilityService } from './../common/services/url-utility.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { Subject } from 'rxjs';
import { IActionMapping, ITreeOptions } from '@circlon/angular-tree-component';
import { environment, APIURLS } from '../../environments/environment';
import { SharedService } from '../common/services/sharedService';
import { isEmpty as _isEmpty, without as _without, forEach } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../common/services/common.service';
import { UtilityService } from '../common/services/utility.service';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';


declare let $: any;
// declare let addthis: any;
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wiley-search-result',
  templateUrl: './wiley-search-result.component.html',
  styleUrls: ['./wiley-search-result.component.css'],
  preserveWhitespaces: false
})
export class WileySearchResultComponent implements AfterContentInit, OnDestroy {
  ENVArchiveFullName = 'Environmental Science and History';
  archivefullName = 'British Association for the Advancement of Science (Collections on the History of Science: 1830 - 1970)';
  showMoreBAASContentLink: Boolean = false;
  // moreRGSContentLink = 'https://www.wileydigitalarchives.com/downloads/RGS_Complete_Content%20List_3_Sept_2019.xlsx';
  // moreRGSContentLink = 'https://www.wileydigitalarchives.com/wp-content/uploads/2020/01/RGS-content-cumulative-21Nov2019.xlsx';
  moreRGSContentLink = '../assets/RGS_Complete_Content List_3_Sept_2019_update.xlsx';
  moreBAASContentLink = '../assets/BAAS_Complete_Content_List.xlsx';
  showMoreRGSContentLink: Boolean = false;
  showDocumentInfo: Boolean = false;
  showBAASDocumentInfo: Boolean = false;
  showENVDocumentInfo: Boolean = false;
  translateValues: any;
  dropdownToggleValue: any;
  docuCurrentValue: any;
  shiftContrlKey: string;
  emailNotDelivered: any;
  isRGSIandII: boolean;
  isRCPIandII: boolean;
  bothRCPAvailable: boolean;
  bothRGSAvailable: Boolean;
  showOtherTab: Boolean = false;
  mailSent: any;
  // private ngUnsubscribe: Subject<void> = new Subject<void>();
  enableShare: Boolean = false;
  isFromShare: Boolean = false;
  useFollowingLinktoAccessContent: String = '';
  private sharemodalRef: BsModalRef;
  // @ViewChild('shareHtmlTemplate') private shareHtmlTemplate: TemplateRef<any>;
  @ViewChild('shareHtmlTemplate', { static: false }) private shareHtmlTemplate: TemplateRef<any>;

  shareMailLoader: Boolean = false;
  shareMailData: ShareMail = {
    'topic': '',
    'message': '',
    'to': '',
    'from': ''
  };
  toggleInternalShareList: Boolean = false;
  shareUrl: string;
  isBookmarkPopupOpen: Boolean = false;
  isContentSubTypeEnable: Boolean = false;
  isArabicLanguage: Boolean = false;
  @Output('pagechanged') private pagechanged: EventEmitter<any> = new EventEmitter();
  resultDataLoading: Boolean = false;
  isIllustrationOpen = false;
  isDocumentViewer = false;
  historyObject: any[] = [];
  filterParams: any;
  viewHistoryUrl: any;
  currentHistory: any;
  resultLoader = false;
  advancedSearchTerms: any;
  recentSearchTerm: any;
  lastTerm: any;
  previousSearchTerm: any;
  lastSearchArray = [];
  translatedValues: any;
  controlShortcut: any;
  shortcutKeyTitle: any;
  // shortcutKeyTitle: any;
  searchTermDatas: any = [];
  searchTermwithSpaces: any;
  specialParams: any;
  isSearchTermContainsbibId = false;
  documentSearchTerm: any;
  showFolderImage: any;
  totalGroupCount: any;
  illustrationPageID: any;
  illustrationDocID: any;
  illustrationDocumentImage: string;
  lastScrollTop = 0;
  loadMoreLoader = false;
  hightScrollTop: any;
  mapView;
  showListView: any = Boolean;
  // private envDocmodalRef: BsModalRef;
  @ViewChild('getScrollElement', { static: false }) scrollElement: ElementRef;
  // @ViewChild('envDocsTemplate', { static: false }) private envDocsTemplate: TemplateRef<any>;

  scrollDisplay: any;
  // private warningObj: Array<any> = [];
  searchResultCount = null;
  groups: any;
  pageContent: any;
  private selectedImageResults: any[];
  bookmarkedResults: any[];
  viewList: any[];
  viewListCount: number;
  viewContent: any[];
  viewContentCount: number;
  hasResults: boolean;
  // private splitWord: any;
  currentView: String;
  fieldNames = {
    'ALL': 'Entire Document',
    'title': 'Document Title',
    'id': 'Document Number',
    'author': 'Authors',
    'authors': 'Authors',
    'bibID': 'Bib ID',
    'fullText': 'Full Text',
    'keyword': 'Keyword',
    'docLang': 'Language',
    'placeOfPub': 'Place of Publication',
    'subject': 'Subject',
    // 'pubTitle': 'Publication Title',
    'manuscriptNoV2': 'Reference Number',
    'barcode': 'Barcode',
    'docNotes': 'Location',
    'collectionCreators': 'Creator',
    'tableData': 'Table'
  };
  // private isIllustrationExist = false;
  // private documentData: any;
  // private paramObservable: Subscription;
  private isInListViewMode = false;
  private isPageResized = false;
  // private counterEvent = 0;
  private searchValue: any;
  fieldVal = false;
  showLeftMenu = true;
  public _isInMapView: Boolean = false;
  private illustrationsLoader: Boolean = false;
  photographView: Boolean;
  noResultInMap: Boolean = false;
  searchKeyAry = [];
  visitedDocList = [];
  isGridView: any;
  accessToken: any;
  isSliderPopover: Boolean = true;
  popoverforIpad: Boolean = false;
  showGridView: boolean;
  @Input() isContentTypePhoto: Boolean = false;
  @Input() isContentTypeMap: Boolean = false;
  @Input() contentTypeOrder: any;
  @Input('contentTypeOrderSearchResult') contentTypeOrderSearchResult: any;
  _contentTypeOrder = [];
  contentTypeList = [];
  homePageUrl: string;
  documentIDReference: Boolean = false;
  documentEnable: Boolean = false;
  addMargin: Boolean = false;
  booleanCount = 0;
  documentFromOtherArchives: Boolean = false;
  isOtherTabActive: Boolean = false;
  unsubscribedArchives: any;
  subscribedArchives: any;
  leftMenuStatus: Boolean;
  showDCIcon: boolean;
  paidArchiveDC: any =[];
  @Input()
  set isInMapView(flag: Boolean) {
    if (flag !== undefined) {
      this._isInMapView = !this._isInMapView;
      if (this._isInMapView) {
        // this.currentView = 'list';
        // $('#GridIcon').removeClass('active');
        // $('#ListIcon').removeClass('active');
        this.isGridView = sessionStorage.getItem('isInGridView');
        if (this.isGridView === 'true') {
          this.currentView = 'grid';
          setTimeout(() => {
            $('#ListIcon').removeClass('active');
            $('#GridIcon').addClass('active');
          }, 4000);
          this.showGridView = true;
        } else {
          this.currentView = 'list';
          setTimeout(() => {
            $('#GridIcon').removeClass('active');
            $('#ListIcon').addClass('active');
          }, 4000);
          this.showGridView = false;
        }
      }
    }
  }
  get isInMapView() {
    return this._isInMapView;
  }

  options1: ITreeOptions = {
    getChildren: () => new Promise((resolve, reject) => { })
  };

  options0: ITreeOptions = {
    displayField: 'title',
    nodeClass: (node) => `${node.data.title}Class`
  };
  defaultActionMapping: IActionMapping = { mouse: { contextMenu: (tree, node, $event) => { } } };
  manuscriptTableofContentOptions: ITreeOptions = { idField: 'uuid', animateExpand: true, animateSpeed: 30, animateAcceleration: 1.2 };
  // @Input('content-type-tooltip')
  // set contentTooltip(response) {
  //   this.contentTypeTooltip = response;
  // }
  // get contentTooltip() {
  //   return this.contentTypeTooltip;
  // }
  @Input('enable-single-list')
  set enableSingleList(response) {
    if (response) {
      this.activateViewOption(response);
    }
  }
  get enableSingleList() {
    return this.scrollDisplay;
  }

  // private contentTypeTooltip: any;
  private togglesearchicon: Array<boolean>;
  pageContentData: any;
  private bookMarkmodalRef: BsModalRef;
  // private bookmarkHtmlTemplate: TemplateRef<any>;
  bookmarkEntireDocURL: any;
  bookmarkURL: any;
  bookmarkPageURL: any;
  @Output() pageChanged: EventEmitter<any>;
  // @ViewChild('bookmarkHtmlTemplate') private bookmarkHtmlTemplate: TemplateRef<any>;
  // @ViewChild('bookmarkURLonTemplate', { read: ElementRef })
  // @ViewChild('bookmarkPageHtmlTemplate') private bookmarkPageHtmlTemplate: TemplateRef<any>;
  // @ViewChild('illustrationDataTemplate') private illustrationDataTemplate: TemplateRef<any>;

  @ViewChild('bookmarkHtmlTemplate', { static: false }) private bookmarkHtmlTemplate: TemplateRef<any>;
  @ViewChild('bookmarkURLonTemplate', { static: false }) private bookmarkURLonTemplate: ElementRef;
  @ViewChild('bookmarkPageHtmlTemplate', { static: false }) private bookmarkPageHtmlTemplate: TemplateRef<any>;
  @ViewChild('illustrationDataTemplate', { static: false }) private illustrationDataTemplate: TemplateRef<any>;

  // @ViewChild('warningsTemplate') private warningsTemplate: TemplateRef<any>;
  // private bookmarkURLonTemplate: ElementRef;
  private document: any;
  showClipBoardSuccessAlert = false;
  private toggleviewlist = false;
  private illustrationsmodalRef: BsModalRef;
  illustrationNodes = [];
  private _searchTerm: string;
   warningFlag = true;
  noImageUrl = 'assets/images/noimage.jpg';
  detailsRedirectParams: any;
  private fromResultsPage: boolean;
  private documentHistory;
  historyDocID: any;
  @Output('mapToggleSelect') mapToggleSelect: EventEmitter<any> = new EventEmitter();
  // @Output('pagechanged') private pageChanged: EventEmitter<any> = new EventEmitter();
  @Output('callDocViewer') callDocViewer: EventEmitter<any> = new EventEmitter();
  @Input('isDocViewerClosed')
  set isDocViewerClosed(val: boolean) {
    this.isDocumentViewer = val;
  }
  docIdForDocViewerObj: any;
  @Input('docIdForDocViewer')
  set docIdForDocViewer(val: any) {
    this.docIdForDocViewerObj = val;
  }
  stop$: Subject<any> = new Subject();
  browseMapVisitedCount: any = 0;
  constructor(
    private utilityService: UtilityService,
    @Inject(DOCUMENT) private dom: Document,
    private httpService: HttpService,
    public pageSettingService: PageSettingsService,
    private searchService: SearchService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlUtilityService: UrlUtilityService,
    private rd: Renderer2,
    private location: Location,
    private elementReference: ElementRef,
    private popupService: ModalService,
    private sharedService: SharedService,
    private ref: ChangeDetectorRef,
    private commonService: CommonService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    const getDownCOntentArray = JSON.parse(localStorage.getItem('personalizationData'));
    if (getDownCOntentArray.downloadContentArchives.length === 0) {
      this.showDCIcon = false;
    } else {
      this.showDCIcon = true;
      getDownCOntentArray.downloadContentArchives.filter((item)=> {
        if(item.trialArchive === false){
          this.paidArchiveDC.push(item.shortName)
        }
      })
    }
    const viewCont = JSON.parse(localStorage.getItem('contntList'));
    if (viewCont) {
      if (viewCont.count > 0) {
        this.pageSettingService.storeViewContenVal(viewCont.viewList)
      }
    }
    this.accessToken = this.httpService.getCurrentAccessToken();
    this.fromResultsPage = true;
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      const checkparamValue = currentParams['display'];
      if (checkparamValue === 'Photographs') {
        setTimeout(() => {
          this.activateViewOption(checkparamValue);
        }, 4000);
      }
      this.scrollDisplay = checkparamValue;
      this.mapView = sessionStorage.getItem('isInMapView');
    });
    this.warningFlag = sessionStorage.getItem('warningFlag') === 'true' ? true : false;
    this.viewList = [];
    this.viewListCount = 0;
    this.viewContent = [];
    this.viewContentCount = 0;
    this.togglesearchicon = [];
    this.bookmarkedResults = [];
    this.selectedImageResults = [];
    this.resultLoader = true;
    PageSettingsService.pageSettingsObservable.subscribe((data) => {
      if (data.advancedFilterParam.searchKeywords) {
        data.advancedFilterParam.searchKeywords.forEach((obj, index) => {
          if (!obj.fieldValue) {
            data.advancedFilterParam.searchKeywords.splice(index, 1);
          }
        });
      }
      this.pageContentData = data;
      if (this.pageContentData.searchTerm) {
        if (this.pageContentData.searchTerm.includes('-') > -1) {
        } else {
          this.isSearchTermContainsbibId = false;
        }
      }
      if (this.pageContentData.advancedFilterParam.searchKeywords) {
        this.pageContentData.advancedFilterParam.searchKeywords.forEach((obj, index) => {
          if (!obj.fieldValue) {
            this.pageContentData.advancedFilterParam.searchKeywords.splice(index, 1);
          }
        });
      }

      const searchKey = this.pageContentData.advancedFilterParam.searchKeywords;
      searchKey.forEach(element => {
        this.searchValue = element;
        if (this.searchValue.fieldValue !== '') {
          this.fieldVal = true;
          if (this.searchKeyAry.indexOf(this.searchValue.fieldValue) > -1) {

          } else {
            this.searchKeyAry.push(this.searchValue.fieldValue);
          }
        } else {
          this.fieldVal = false;
        }
      });
    });
    PageSettingsService.ViewListObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.viewList = data.viewList;
      this.viewListCount = data.count;
    });
    PageSettingsService.ViewContentObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
      this.viewContent = data.viewList;
      this.viewContentCount = data.count;
      if (this.groups && this.groups.length > 0) {
        this.UpdateGroupData()
      }
    });
    const checkadiyionalArchives = localStorage.getItem('fromAdditionalArchive');
    if (checkadiyionalArchives === 'true') {
      this.otherTabsetclick('other');
    } else {
      this.otherTabsetclick('selected');
    }
    this.sharedService.toolsList.pipe(takeUntil(this.stop$)).subscribe((changes) => {
      /*
      * require list from localstorage
      */
      setTimeout(() => {
        this.viewList = JSON.parse(localStorage.getItem('documentList'));
        const viewListArry = [];
        this.getJSONKeys(this.viewList).forEach((obj: any) => {
          this.getJSONKeys(this.viewList[obj]).forEach(obj1 => {
            viewListArry.push(obj1);
          });
        });
        this.viewListCount = viewListArry.length;
      }, 1000);
    });

    SearchService.resultObservable.pipe(takeUntil(this.stop$)).subscribe((result) => {
      this.resultLoader = false;
      this.loadMoreLoader = false;
      if (result) {
        let i = 0;
        let count = this.contentTypeList.length;
        this.groups = result.map(group => {
          this.totalGroupCount = group.totalGroupElements;
          if (this.contentTypeList.length > 0) {
            this.contentTypeList.forEach(ele => {
              if (ele === group.group) {
                group['order'] = this.contentTypeList.indexOf(ele) + 1;
              }
            });
          }
          if (group.group) {
            if (group['order'] === undefined) {
              count++;
              group['order'] = count;
            }
          }
          if (group.documents.length > 0) {
            group.documents = group.documents.map(document => {
              if (document.imageThumbnails) {
                document.imageThumbnails = document.imageThumbnails.map((thumb) => {
                  if (!thumb.includes('?access_token')) {
                    if (thumb.split('_').length > 1) {
                      return environment.APIUrl + 'file/thumb/' + document.id +
                        '/' + thumb.split('_')[0] + '?access_token=' + this.accessToken + '&WDALoc=' +
                        localStorage.getItem('currentLocation') || environment.defaultLocation;
                    } else {
                      return thumb;
                    }
                  } else {
                    return thumb;
                  }
                });
              }
              if (document.folderImages) {
                document.folderImages = document.folderImages.map((thumb) => {
                  if (!thumb.includes('?access_token')) {
                    if (thumb.split('.').length > 1 || thumb) {
                      return environment.APIUrl + 'file/thumb/' + document.id +
                        '/' + thumb.split('.')[0] + '?access_token=' + this.accessToken + '&WDALoc=' +
                        localStorage.getItem('currentLocation') || environment.defaultLocation;
                    } else {
                      return thumb;
                    }

                  } else {
                    return thumb;
                  }
                });
              }
              return document;
            });
          }
          i++;
          return group;
        });
        const viewCont = JSON.parse(localStorage.getItem('contntList'));
        if (viewCont) {
          this.viewContentCount = viewCont.count;
        } else {
          this.viewContentCount = 0;
        }
        if (this.groups && this.groups.length > 0) {
          this.UpdateGroupData()
        }
        if (this.photographView) {
          this.groups.forEach(element => {
            if (element.group === 'Photographs') {
              // this.searchService.viewMoreResultsScroll(element.group, 20, element.totalGroupElements);
            }
          });
          this.loadMoreLoader = false;
        } else {
          this.loadMoreLoader = false;
        }
        if (this.groups.length === 0 && this._isInMapView) {
          this.noResultInMap = true;
          this.popupService.showNotification('WARNING', this.translatedValues.searchResults || 'Search Result',
            this.translatedValues.searchDidNotReturnResult || 'No Results Found');
          if ($('#searchResultDiv')) {
            $('#searchResultDiv').hide();
          }
          if ($('app-browser-map')) {
            $('app-browser-map').hide();
          }
        } else {
          this.loadMoreLoader = false;
        }
      } else {
        this.groups = null;
        this.loadMoreLoader = false;
      }
      if (this.scrollDisplay === null) {
        this.ref.detectChanges();
      }
      this.loadMoreLoader = false;
      this.resultDataLoading = false;
    });

    SearchService.hasResultsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      if (data) {
        /* If the advanced filter open then we should not reset the result set until search button clicked */
        if (!PageSettingsService.pageSettings.showAdvancedFilter) {
          this.hasResults = data.hasResult;
          this.searchResultCount = data.count;
        }
      }
    });
    this.document = dom;
    setTimeout(() => {
      this.homePageUrl = '/';
      this.homePageUrl += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
    });
    this.translate.get('photoExplorer').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translateValues = res;
    });
  }
  UpdateGroupData() {
    for (let i = 0; i < this.groups.length; i++) {
      for (let k = 0; k < this.groups[i].documents.length; k++) {
        this.groups[i].documents[k].showDocs = true;
        const val = this.groups[i].documents[k].archive;
        if(this.paidArchiveDC.includes(val.toString())) {
          this.groups[i].documents[k].paidArchive = true; 
        } else {
          this.groups[i].documents[k].paidArchive = false; 
        }
      }
    }
    const viewConts = JSON.parse(localStorage.getItem('contntList'));
    if (viewConts) {
      for (let v = 0; v < viewConts.viewList.length; v++) {
        for (let i = 0; i < this.groups.length; i++) {
          for (let k = 0; k < this.groups[i].documents.length; k++) {
            if (viewConts.viewList[v].docId === this.groups[i].documents[k].id) {
              this.groups[i].documents[k].showDocs = false;
            }
          }
        }
      }
    }
  }
  ngOnInit() {

  }

  ngAfterContentInit() {
    this.sharedService.isleftMenuActive.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.leftMenuStatus = res;
    })

    setTimeout(() => {
      if (document.querySelector('.grecaptcha-badge')) {
        (document.querySelector('.grecaptcha-badge') as HTMLElement).style.visibility = 'hidden';
        (document.querySelector('.grecaptcha-badge') as HTMLElement).style.opacity = '0';
      }
      let otherArchivesFlag = localStorage.getItem('otherArchives');
      this.subscribedArchives = JSON.parse(sessionStorage.getItem('wileySubscribedArchives_tab1'));
      this.unsubscribedArchives = JSON.parse(sessionStorage.getItem('unsubscribedArchives_tab2'));
      if (this.unsubscribedArchives) {
        if (otherArchivesFlag !== 'true' && this.unsubscribedArchives.length > 0) {
          if(this.unsubscribedArchives.length === 1) {
            if (this.unsubscribedArchives[0] === 'WPA' || this.unsubscribedArchives[0] === 'WPA1') {
              this.showOtherTab = false;
            } else {
              this.showOtherTab = true;
            }
          } else if(this.unsubscribedArchives.length === 2) {
           if(this.unsubscribedArchives.includes('WPA') && this.unsubscribedArchives.includes('WPA1')){
            this.showOtherTab = false;
           } else {
            this.showOtherTab = true;
           }
          } else {
            this.showOtherTab = true;
          }
        } else {
          this.showOtherTab = false;
        }
      } else {
        this.showOtherTab = false;
      }
    }, 3500);
    // stick to document id from search results
    let currentOffsetIndex = sessionStorage.getItem('currentOffsetIndex');
    let docIdToScroll = sessionStorage.getItem('currentSearchDocumentId');
    if (currentOffsetIndex) {
      if (parseInt(currentOffsetIndex) > 4) {
        this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
          const currentParams = JSON.parse(JSON.stringify(params));
          const checkparamValue = currentParams['display'];
          this.showContentType({ value: checkparamValue });
          setTimeout(() => {
            $('html, body').animate({ scrollTop: 5 }, 2000);
            setTimeout(() => {
              this.loadMoreResults('viewMoreFromTemplate', currentOffsetIndex);
              if (docIdToScroll) {
                setTimeout(() => {
                  const id = $('#' + docIdToScroll).offset();
                  if (id) {
                    $('html, body').animate({ scrollTop: (id.top - 20) }, 2000);
                    sessionStorage.removeItem('currentOffsetIndex');
                  } else {
                    $('html, body').animate({ scrollTop: 500 }, 2000);
                    sessionStorage.removeItem('currentOffsetIndex');
                  }
                }, 1000);
              }
            }, 1000);
          }, 1500);
        });
      } else {
        if (docIdToScroll) {
          setTimeout(() => {
            const id = $('#' + docIdToScroll).offset();
            if (id) {
              $('html, body').animate({ scrollTop: (id.top - 20) }, 2000);
              sessionStorage.removeItem('currentOffsetIndex');
            }
          }, 3000);
        }
      }
    }
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.dropdownToggleValue = res;
      if (this.dropdownToggleValue.shareURLPopup === true) {
        this.documentEnable = true;
      } else {
        this.documentEnable = false;
        this.groups.forEach(obj => {
          obj.documents.forEach(element => {
            element.enableShare = false;
          });
        });
      }
      if (res.showSearchQuery === true) {
        this.toggleInternalShareList = true;
      } else {
        this.toggleInternalShareList = false;
      }
    });
    // this.sharedService.SharedropdownToggle.takeUntil(this.ngUnsubscribe).subscribe(res => {
    //   if (res.showSearchQuery === true) {
    //     this.toggleInternalShareList = true;
    //   } else {
    //     this.toggleInternalShareList = false;
    //   }
    //   });
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKeyTitle = 'Shift + Alt';
      this.shiftContrlKey = 'Ctrl + Shift';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKeyTitle = 'Shift + Option';
      this.shiftContrlKey = 'Cmd + Shift';
    }
    if (sessionStorage.getItem('isInGridView') === 'true') {
      this.showGridView = true;
      $('#ListIcon').removeClass('active');
      $('#GridIcon').addClass('active');
      this.currentView = 'grid';
      // this.currentView = JSON.parse(sessionStorage.getItem('isInGridView')) ? 'grid' : 'list';
    } else {
       this.currentView = 'list';
      this.showGridView = false;
      sessionStorage.setItem('isInGridView', 'false');
    }
    this.sharedService.searchLoader.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.resultLoader = res;
    });
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
      this.useFollowingLinktoAccessContent = res['useFollowingLinktoAccessContent'];
      this.mailSent = res['mailSent'];
      this.emailNotDelivered = res['emailNotDelivered'];
    });
    // this.shortcutKeyTitle = sessionStorage.getItem('ShortcutKey');
    this.sharedService.mapParam.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (!_isEmpty(res)) {
        this.specialParams = res;
      }
    });


    /*
    * RESET scrollspyDisaply if the map draw detected
    */
    this.sharedService.isResetScrollSpy.pipe(takeUntil(this.stop$)).subscribe(res => {

    });
    if (this.pageContentData.advancedFilterParam.contentType.length === 1) {
      if (this.pageContentData.advancedFilterParam.contentType.indexOf('Photographs') > -1) {
        this.currentView = 'grid';
        setTimeout(() => {
          $('#ListIcon').removeClass('active');
          $('#ListIcon').addClass('disabled');
          $('#CursorNotAllowed').addClass('cursornotallowed');
          $('#GridIcon').addClass('active');
        }, 4000);
      } else {
        this.currentView = 'list';
        setTimeout(() => {
          $('#ListIcon').removeClass('disabled');
          $('#ListIcon').addClass('active');
          $('#GridIcon').removeClass('active');
        }, 4000);
      }
    } else {
      this.isGridView = sessionStorage.getItem('isInGridView');
        if (this.isGridView === 'true') {
          this.currentView = 'grid';
          setTimeout(() => {
            $('#ListIcon').removeClass('active');
            $('#GridIcon').addClass('active');
          }, 4000);
          this.showGridView = true;
        } else {
          this.currentView = 'list';
          setTimeout(() => {
            $('#GridIcon').removeClass('active');
            $('#ListIcon').addClass('active');
          }, 4000);
          this.showGridView = false;
        }
      // this.currentView = 'list';
      // setTimeout(() => {
      //   $('#ListIcon').removeClass('disabled');
      //   $('#ListIcon').addClass('active');
      //   $('#GridIcon').removeClass('active');
      // }, 3000);
    }

    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      const checkparamValue = currentParams['display'];
      this.scrollDisplay = checkparamValue;
      if (this.scrollDisplay === 'Photographs') {
        this.photographView = true;
        $('#ListIcon').removeClass('active');
        $('#ListIcon').addClass('disabled');
        $('#GridIcon').addClass('active');
        this.currentView = 'grid';
      }
      if (sessionStorage.getItem('isInMapView') === 'true') {
        this._isInMapView = true;
      } else {
        this._isInMapView = false;
      }
    });
    if (this.mapView === 'true') {
      this._isInMapView = true;
      this.currentView = 'list';
    }
    const searchTerm = [];
    searchTerm.push(this.pageContentData.searchTerm);
    const data = {};
    data['searchTerm'] = searchTerm;
    data['isFromResultsPage'] = this.fromResultsPage;
    this.searchService.buildFilterParams(this.pageContentData.advancedFilterParam, (param) => {
      this.searchService.buildURL(param, (url) => {
        data['filterParam'] = url;
        if (this.illustrationPageID) {
          data['viewPage'] = this.illustrationPageID;
        }
        this.detailsRedirectParams = data;
      });
    });
    setTimeout(() => {
      if (localStorage.getItem('detailsHistory')) {
        this.documentHistory = JSON.parse(localStorage.getItem('detailsHistory'));
        this.documentHistory.forEach(doc => {
          this.historyDocID = doc.id;
          this.visitedDocList.push(this.historyDocID);
        });
      }
    });
    // this.httpService.get('../assets/config/locations-config.json', response => {
    this.commonService.getLocationConfig(localStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(res => {
      this.showFolderImage = res;
    },
      err => {
      }
    );
    this.isGridView = sessionStorage.getItem('isInGridView');
    if (this.isGridView === 'true') {
      $('#ListIcon').removeClass('active');
      $('#GridIcon').addClass('active');
      this.showGridView = true;
      this.currentView = 'grid';
    } else {
      $('#GridIcon').removeClass('active');
      $('#ListIcon').addClass('active');
      this.showGridView = false;
      this.currentView = 'list';
    }



    // $(document).on('keydown', (e) => {

    // });
    if (window.innerWidth <= 1024) {
      this.popoverforIpad = true;
    } else {
      this.popoverforIpad = false;
    }
    this.togglesearchicon = [];
    this.sharedService.isDocViewerShow.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.isDocumentViewer = false;
    });


    if (this.contentTypeOrderSearchResult !== undefined) {
      this._contentTypeOrder = this.contentTypeOrderSearchResult.split(',');
      this._contentTypeOrder.forEach(element => {
        if (element === 'mo') {
          this.contentTypeList.push('Monographs');
        } else if (element === 'ms') {
          this.contentTypeList.push('Manuscripts');
        } else if (element === 'ph') {
          this.contentTypeList.push('Photographs');
        } else if (element === 'ma') {
          this.contentTypeList.push('Maps');
        }
      });
    }

    /*
    @description: split search term with space
    @INPUT: this.pageContentData.searchTerm as search term
    @OUTPUT: splited search term (remove stop words)
    */
    const searchTermDatas = this.searchService.splitTerms(this.pageContentData.searchTerm);

    let isBooleanOperatersAvailabe = false;
    if (searchTermDatas) {
      searchTermDatas.forEach(element => {

        element = element.replace(/\(/g, '').replace(/\)/g, '');
        if (element !== '') {
          if (element.includes('"') && element.includes('-') || (element === 'AND' || element === 'OR' || element === 'NOT')) {
            if (this.searchTermDatas.indexOf(element.toLowerCase()) !== -1) {
            } else {
              this.searchTermDatas.push(element);
            }
            isBooleanOperatersAvailabe = true;
          } else {
            if (this.searchTermDatas.indexOf(element.toLowerCase()) !== -1) {
            } else {
              this.searchTermDatas.push(element.toLowerCase());
            }
          }
        }

      });
    }
    if (this.searchTermDatas) {
      this.searchTermDatas.forEach((op, i) => {
        if ((this.searchTermDatas[i] === 'AND') || (this.searchTermDatas[i] === 'OR') || (this.searchTermDatas[i] === 'NOT')) {
          this.booleanCount = this.booleanCount + 1;
          if (this.booleanCount > 1) {
            this.searchTermDatas[i] = '';
            this.searchTermDatas[i - 1] = '';
          }
          if ((this.searchTermDatas[0] === 'AND') || (this.searchTermDatas[0] === 'OR')) {
            this.searchTermDatas[0] = '';
          } else if (this.searchTermDatas[0] === 'NOT') {
            this.addMargin = true;
          }
        } else {
          this.booleanCount = 0;
        }
        const searchTermLength = this.searchTermDatas.length;
        if ((this.searchTermDatas[searchTermLength - 1] === 'AND') || (this.searchTermDatas[searchTermLength - 1] === 'OR')
          || ((this.searchTermDatas[searchTermLength - 1] === 'NOT'))) {
          this.searchTermDatas.pop();
        }
      });
      this.searchTermDatas = this.searchTermDatas.filter((entry) => {
        return entry.trim() !== '';
      });
      if (isBooleanOperatersAvailabe) {
        const booleanOperator = _without(this.searchTermDatas, 'AND', 'OR', 'NOT');
        if (booleanOperator.length > 8) {
          this.searchTermDatas = this.searchTermDatas.splice(0, this.searchTermDatas.indexOf(booleanOperator[7]) + 1);
        }
      } else {
        this.searchTermDatas = this.searchTermDatas.splice(0, 8);
      }
    }

    this.bookmarkClick();
    setTimeout(() => {
      const browseMapVisitedCount = sessionStorage.getItem('browseMapVisitedCount');
      if (browseMapVisitedCount) {
      } else {
        if (this._isInMapView) {
          if (this.translateValues) {
            // tslint:disable-next-line:max-line-length
            this.popupService.showNotification('INFO', 'INFO', this.translateValues.drawShapeRectOrCircle || 'Use the <img src="assets/images/icons/map-rect.png" width="25" /> or the <img src="assets/images/icons/map-circle.png" width="25" /> to draw an area to limit the search', 6000);
            sessionStorage.setItem('browseMapVisitedCount', 'true');
          }
        }
      }
    }, 1000);
    if (this.pageContentData.currentSelectedArchives.indexOf('RGS') > -1) {
      this.showDocumentInfo = true;
    } else {
      this.showDocumentInfo = false;
    }
    if (this.pageContentData.currentSelectedArchives.indexOf('BAAS') > -1) {
      this.showBAASDocumentInfo = true;
    } else {
      this.showBAASDocumentInfo = false;
    }
    if (this.pageContentData.currentSelectedArchives.indexOf('ENV') > -1) {
      this.showENVDocumentInfo = true;
    } else {
      this.showENVDocumentInfo = false;
    }
  }

  checkAccordionStatus(index, state) {
    this.togglesearchicon[index] = state;
  }

  callDocViewerFromOutput(event) {
    this.callDocumentViewer(event.docId, event.pageId, null, event.index);
  }
  callDocumentViewer(documentID, pageID?, invokedFrom?, index?, fromRightClick?: any, group?: any) {
    if (group === 'Photographs' && this.documentFromOtherArchives) {
    } else {

      const searchKeywordLength: number = PageSettingsService.pageSettings.advancedFilterParam.searchKeywords.length;
      let counter = 1;
      let advancedSearchKeyword = '';
      PageSettingsService.pageSettings.advancedFilterParam.searchKeywords.forEach(element => {
        if (element.fieldName === 'ALL') {
          if (counter < searchKeywordLength) {
            advancedSearchKeyword += element.fieldValue + ' ';
          } else {
            advancedSearchKeyword += element.fieldValue;
          }
          counter++;
        }
      });
      window.history.pushState(location.pathname, 'text', location.pathname);
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
        if (!params['searchTerm']) {
          $('#document_search').val('');
          $('#searchWithinBtn').trigger('click');
        }
      });
      const documentCurrentOffset = $('#' + documentID).offset();
      this.detailsRedirectParams.docID = documentID;
      this.detailsRedirectParams.start = index;
      let spatialFilterParams: any;
      if (this.specialParams) {
        spatialFilterParams = JSON.stringify(this.specialParams['spatialFilterParams']);
      }
      if (sessionStorage.getItem('display')) {
        // this.detailsRedirectParams.display = sessionStorage.getItem('display');
      }
      if (pageID) {
        if (this.isContentSubTypeEnable) {
          this.isContentSubTypeEnable = true;
        } else {
          this.isContentSubTypeEnable = false;
        }
      } else {
        this.isContentSubTypeEnable = false;
      }
      this.detailsRedirectParams.invokedFrom = invokedFrom ? invokedFrom.caller : { caller: 'SearchResultsPage', random: Math.random() };
      this.detailsRedirectParams.pageId = pageID;
      let url = this.detailsRedirectParams;
      let CurrentURL: any;
      CurrentURL = window.location.href.split(window.location.host);
      sessionStorage.setItem('SearchResultsPageURLFromSearch', CurrentURL[1]);
      sessionStorage.setItem('currentSearchDocumentId', documentID);
      sessionStorage.setItem('currentOffsetIndex', index);

      const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
      const currentLocation = localStorage.getItem('currentLocation') || environment.defaultLocation;
      url = this.router.createUrlTree(['/' + currentArchive + '/detail/' + documentID, this.detailsRedirectParams]);
      if (fromRightClick === 'true' && fromRightClick !== undefined) {
        if (sessionStorage.getItem('currentOffsetIndex')) {
          sessionStorage.removeItem('currentOffsetIndex');
        }
        const urlFilter = encodeURIComponent(this.detailsRedirectParams['filterParam']);
        const urlsearchTerm = encodeURIComponent(this.detailsRedirectParams['searchTerm']);
        const host = window.location.host;
        const pathName = PageSettingsService.pageSettings.currentArchive.toLocaleLowerCase();
        const protocol = window.location.protocol;
        window.open(`${protocol}//${host}/${pathName}/detail/${documentID};searchTerm=${urlsearchTerm};isFromResultsPage=true;filterParam=${urlFilter};start=0`, '_blank');

      }

      else {
        this.router.navigate(['../detail/' + documentID, this.detailsRedirectParams],
          { relativeTo: this.activatedRoute });
      }


    }

    // this.modalService.showLoader();
    // this.isDocumentViewer = true;
    // const randomVal = Math.random();
    // this.callDocViewer.emit({
    //   'docId': documentID,
    //   'random': randomVal,
    //   'isDocViewerShow': this.isDocumentViewer,
    //   'pageId': pageID,
    //   'currentOffset': documentCurrentOffset,
    //   'invokedFrom': invokedFrom ? invokedFrom : { caller: 'SearchResultsPage', random: Math.random() },
    //   'start': this.detailsRedirectParams.start,
    //   'spatialFilterParams': spatialFilterParams,
    //   'isFromResultsPage': true,
    //   'searchTerm': PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm : '',
    //   'advancedSearchTerm': advancedSearchKeyword,
    //   'isContentSubTypeEnable': this.isContentSubTypeEnable

    // });
    // // this.visitedDocList.push(documentID);
    // this.location.replaceState(url);
    // this.showMoreRGSContentLink = false;
  }

  generateArray(obj) {
    if (typeof obj === 'object') {
      return Object.keys(obj).map((key) => {
        return obj[key];
      });
    } else {
      return [];
    }
  }

  getJSONKeys(obj) {
    if (typeof obj === 'object') {
      return Object.keys(obj);
    } else {
      return [];
    }
  }


  setContentType(contentType) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentSelectedContentTypes = currentSearchSettings.contentType;
    if (currentSelectedContentTypes.indexOf(contentType) > -1) {
      currentSelectedContentTypes.splice(currentSelectedContentTypes.indexOf(contentType), 1);
    }
    currentSearchSettings.contentType = currentSelectedContentTypes;
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  setContentSubType(docSubType) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentSelectedContentSubtypes = currentSearchSettings.docSubType;
    if (currentSelectedContentSubtypes.indexOf(docSubType) > -1) {
      currentSelectedContentSubtypes.splice(currentSelectedContentSubtypes.indexOf(docSubType), 1);
    }
    currentSearchSettings.docSubType = currentSelectedContentSubtypes;
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  setLanguage(language) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentSelectedLanguage = currentSearchSettings.languages;
    if (currentSelectedLanguage.indexOf(language) > -1) {
      currentSelectedLanguage.splice(currentSelectedLanguage.indexOf(language), 1);
    }
    currentSearchSettings.languages = currentSelectedLanguage;
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  setSubject(subject) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentSelectedSubject = currentSearchSettings.subject;
    if (currentSelectedSubject.indexOf(subject) > -1) {
      currentSelectedSubject.splice(currentSelectedSubject.indexOf(subject), 1);
    }
    currentSearchSettings.subject = currentSelectedSubject;
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }


  setCollection(contentCollection) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentSelectedcollection = currentSearchSettings.contentCollection;
    if (currentSelectedcollection.indexOf(contentCollection) > -1) {
      currentSelectedcollection.splice(currentSelectedcollection.indexOf(contentCollection), 1);
    }
    currentSearchSettings.contentCollection = currentSelectedcollection;
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }

  setSource(contentSources) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentSelectedsource = currentSearchSettings.contentSources;
    if (currentSelectedsource.indexOf(contentSources) > -1) {
      currentSelectedsource.splice(currentSelectedsource.indexOf(contentSources), 1);
    }
    currentSearchSettings.contentSources = currentSelectedsource;
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  setIllustration(illustrationType) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    const currentSelectedsource = currentSearchSettings.illustrationType;
    if (currentSelectedsource.indexOf(illustrationType) > -1) {
      currentSelectedsource.splice(currentSelectedsource.indexOf(illustrationType), 1);
    }
    currentSearchSettings.illustrationType = currentSelectedsource;
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }

  imageClick(title) {
    if (this.selectedImageResults.indexOf(title) === -1) {
      this.selectedImageResults.push(title);
    } else {
      this.selectedImageResults.splice(this.selectedImageResults.indexOf(title), 1);
    }
  }

  /**
   * the function is to get qs and qb link of document for share popup
   * @param id which document id to get short url which is the qb url in share popup
   */
  bookmarkClick(id?: any) {
    //  if (!this.isBookmarkPopupOpen) {
    //  this.showClipBoardSuccessAlert = false;
    // this.modalService.showLoader();
    let urltoBookmark = '';
    this.bookmarkEntireDocURL = '';
    const currentUrl = window.location.href;
    if (id) {
      const currentWindowUrl = this.urlUtilityService.getCurrentUrl();
      let URL = '/';
      URL += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
      URL += '/detail';
      urltoBookmark = currentWindowUrl + URL + '/' + id + ';fromBookmark=true';
    } else {
      urltoBookmark = currentUrl + ';fromBookmark=true';
    }
    const requestParams = { 'url': urltoBookmark };
    this.httpService.post(environment.APIUrl + APIURLS['bookmarkShortUrl'], requestParams, (response) => {
      this.bookmarkEntireDocURL = response.url;
      // this.modalService.hideLoader();
      //      this.copytoClipBoard(this.bookmarkURL);
      //  this.bookMarkmodalRef = this.bsModalService.show(this.bookmarkHtmlTemplate, { backdrop: true, ignoreBackdropClick: true });
      //  this.isBookmarkPopupOpen = true;
    }, (error) => {
      //    this.isBookmarkPopupOpen = false;
      // this.modalService.hideLoader();
    }, 'json');
    // }
  }

  generateShortUrl(id?: any, index?) {
    //  this.modalService.showLoader();
    let urltoBookmark = '';
    this.bookmarkURL = '';
    const currentUrl = window.location.href;
    let currentParamsForBookmark;
    //   this.modalService.showLoader();
    if (id) {
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
        currentParamsForBookmark = JSON.parse(JSON.stringify(params));
      });
      index = index ? index : 0;
      currentParamsForBookmark = encodeURIComponent(currentParamsForBookmark['filter']);
      const currentWindowUrl = this.urlUtilityService.getCurrentUrl();
      let URL = '/';
      URL += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
      URL += '/detail';
      // tslint:disable-next-line:max-line-length
      urltoBookmark = currentWindowUrl + URL + '/' + id + ';fromBookmark=true;' + ';filterParam=' + currentParamsForBookmark + ';isFromResultsPage=true' + ';start=' + index;
    } else {
      urltoBookmark = currentUrl + ';fromBookmark=true';
    }
    const requestParams = { 'url': urltoBookmark };
    this.httpService.post(environment.APIUrl + APIURLS['bookmarkShortUrl'],
      requestParams, (response) => {
        this.bookmarkURL = response.url;
        // this.modalService.hideLoader();
      }, (error) => {
        // this.modalService.hideLoader();
      }, 'json');

    /*the below code is to get qs link for document*/
    const currentHost = this.urlUtilityService.getCurrentUrl();
    if (id) {
      this.shareUrl = '';
      // this.modalService.showLoader();
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
        const currentParams = JSON.parse(JSON.stringify(params));
        currentParams['searchTerm'] = '';
        delete currentParams['isFromResultsPage'];
        currentParams['location_id'] = localStorage.getItem('currentLocation') || environment.defaultLocation;
        const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
        const urlToShort = currentHost + this.router.createUrlTree([`/${currentArchive}/detail/${id}`]).toString();
        this.urlUtilityService.getShortUrl(urlToShort, (shortUrlResponse) => {
          this.shareUrl = shortUrlResponse.url;
          //    this.modalService.hideLoader();
        }, () => {
          //     this.modalService.hideLoader();
        });
      });
    }
    // this.modalService.hideLoader();
  }
  socialShare(doc, index, checkContentType) {
    $('.startWithRightSide').removeClass('startWithRightSide');
    $('.startWithLeftSide').removeClass('startWithLeftSide');
    $('.startWithMiddleSide').removeClass('startWithMiddleSide');
    $('.startWithRightSideTab').removeClass('startWithRightSideTab');
    $('.startWithLeftSideTab').removeClass('startWithLeftSideTab');
    $('.startWithLeftSideTab_photos').removeClass('startWithLeftSideTab_photos');
    $('.startWithRightSideTab_photos').removeClass('startWithRightSideTab_photos');
    $('.startWithMiddleSideTab_photos').removeClass('startWithMiddleSideTab_photos');
    const currentShareIcon = $('.' + checkContentType + 'ListViewIcon .socialShare' + index);
    const shareIconFromLeft = currentShareIcon.offset();
    if (window.outerWidth > 1600) {
      if (shareIconFromLeft.left > 1060) {
        currentShareIcon.addClass('startWithRightSide');
      }
      if (checkContentType === 'Photographs' && !this._isInMapView && !this.showGridView) {
        if (shareIconFromLeft.left < 750) {
          currentShareIcon.addClass('startWithLeftSide');
        }
      }
    } else if (window.outerWidth > 1360 && window.outerWidth < 1600) {
      if (shareIconFromLeft.left > 600) {
        currentShareIcon.addClass('startWithRightSide');
      }
      if (checkContentType === 'Photographs' && !this._isInMapView && !this.showGridView) {
        if (shareIconFromLeft.left < 700) {
          currentShareIcon.addClass('startWithLeftSide');
        }
      }
    } else if (window.outerWidth > 1020 && window.outerWidth < 1360) {
      if (!this._isInMapView && this.showGridView) {
        if (shareIconFromLeft.left > 600) {
          currentShareIcon.addClass('startWithRightSide');
        } else if (shareIconFromLeft.left < 500 && shareIconFromLeft.left > 350) {
          currentShareIcon.addClass('startWithMiddleSide');
        }
      }
      if (checkContentType === 'Photographs' && !this._isInMapView && !this.showGridView) {
        if (shareIconFromLeft.left < 490 && shareIconFromLeft.left > 350) {
          currentShareIcon.addClass('startWithMiddleSide');
          // currentShareIcon.addClass('startWithMiddleSideTab_photos');
        } else if (shareIconFromLeft.left > 490) {
          currentShareIcon.addClass('startWithRightSide');
          // currentShareIcon.addClass('startWithRightSideTab_photos');
        } else {
          currentShareIcon.addClass('startWithLeftSide');
          // currentShareIcon.addClass('startWithLeftSideTab_photos');
        }
      }
    } else if (window.outerWidth > 767 && window.outerWidth < 800) {
      if (!this._isInMapView && this.showGridView) {
        if (shareIconFromLeft.left > 300) {
          currentShareIcon.addClass('startWithRightSideTab');
        } else if (shareIconFromLeft.left < 300) {
          currentShareIcon.addClass('startWithLeftSideTab');
        }
      }
      if (checkContentType === 'Photographs' && !this._isInMapView && !this.showGridView) {
        if (shareIconFromLeft.left > 400) {
          currentShareIcon.addClass('startWithRightSideTab_photos');
        } else {
          currentShareIcon.addClass('startWithLeftSideTab_photos');
        }
      }
    } else if (window.outerWidth > 319 && window.outerWidth < 767) {
      if (!this._isInMapView && this.showGridView) {
        if (shareIconFromLeft.left > 100) {
          currentShareIcon.addClass('startWithRightSideTab');
        } else if (shareIconFromLeft.left < 100) {
          currentShareIcon.addClass('startWithLeftSideTab');
        }
      }
      if (checkContentType === 'Photographs' && !this._isInMapView && !this.showGridView) {
        if (shareIconFromLeft.left > 150) {
          currentShareIcon.addClass('startWithMiddleSideTab_photos');
        } else {
          currentShareIcon.addClass('startWithLeftSideTab_photos');
        }
      }
    } else if (window.outerWidth > 799 && window.outerWidth < 1020) {
      if (!this._isInMapView && this.showGridView) {
        if (shareIconFromLeft.left > 300) {
          currentShareIcon.addClass('startWithRightSideTab');
        } else if (shareIconFromLeft.left < 300) {
          currentShareIcon.addClass('startWithLeftSideTab');
        }
      }
      if (checkContentType === 'Photographs' && !this._isInMapView && !this.showGridView) {
        if (shareIconFromLeft.left > 400) {
          currentShareIcon.addClass('startWithRightSideTab_photos');
        } else {
          currentShareIcon.addClass('startWithLeftSideTab_photos');
        }
      }
    }
    const documentId = doc.id;
    if (this.docuCurrentValue === doc.id) {
      if (this.documentEnable) {
        this.documentEnable = false;
        this.sharedService.dropdownToggleOption({
          tools: false,
          otherArchives: false,
          archiveSelected: false,
          advanceSearch: false,
          help: false,
          language: false,
          shareDocument: false,
          shareURLPopup: false,
          showSearchQuery: false
        });
      } else {
        this.documentEnable = true;
        this.sharedService.dropdownToggleOption({
          tools: false,
          otherArchives: false,
          archiveSelected: false,
          advanceSearch: false,
          help: false,
          language: false,
          shareDocument: false,
          shareURLPopup: true,
          showSearchQuery: false
        });
      }
    } else {
      this.docuCurrentValue = doc.id;
      this.documentEnable = false;
      this.documentEnable = !this.documentEnable;
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        shareURLPopup: true,
        showSearchQuery: false
      });
    }
    doc.enableShare = this.documentEnable;
    this.generateShortUrl(doc.id, index);
    this.groups.forEach(obj => {
      obj.documents.forEach(element => {
        if (element.id === documentId) {
          // this.documentEnable = true;
        } else {
          // this.documentEnable = false;
          if (element.hasOwnProperty('enableShare')) {
            delete element['enableShare'];
          }
        }
      });
    });
    // event.stopPropagation();
  }

  hideSharePopup() {
    this.groups.forEach(obj => {
      obj.documents.forEach(element => {
        if (element.hasOwnProperty('enableShare')) {
          delete element['enableShare'];
        }
      });
    });
  }
  internalShareToggleList($event) {
    $event.stopPropagation();
    this.hideSharePopup();
    this.toggleInternalShareList = !this.toggleInternalShareList;
    this.sharedService.dropdownToggleOption({
      showSearchQuery: this.toggleInternalShareList
    });
    this.isFromShare = true;
  }
  shareMailClick(shareUrl) {
    if (!$('.modal').hasClass('in') && !this.shareMailLoader) {
      this.shareMailLoader = true;
      // this.modalService.showLoader();
      this.shareMailData = {
        'topic': '',
        'message': '',
        'to': ''
      };
      if (shareUrl) {
        this.shareMailData.message = this.useFollowingLinktoAccessContent + ' \n' + shareUrl;
        if (!$('.modal').hasClass('in')) {
          this.sharemodalRef = this.bsModalService.show(this.shareHtmlTemplate, { backdrop: true, ignoreBackdropClick: true });
          this.shareMailLoader = false;
        }
      }
      this.shareMailLoader = false;
      // this.modalService.hideLoader();
    }
  }
  closeShareModal() {
    // this.docTab = false;
    this.sharemodalRef.hide();
    this.shareMailLoader = false;
  }
  shareEmail() {
    this.utilityService.sendToMailClient(this.shareMailData.topic,
      this.shareMailData.message, this.shareMailData.to, this.shareMailData.from).then(res => {
        this.modalService.showNotification('SUCCESS', this.mailSent || 'Mail Sent', '');
        this.closeShareModal();
      }, err => {
        this.modalService.showNotification('WARNING', this.emailNotDelivered || 'Email has not been Delivered', '');
        this.closeShareModal();
      });
  }

  copytoClipBoard(bookmarkURL) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      if (bookmarkURL) {
        // $(bookmarkURL).addClass('welcome');
        const citationEle = bookmarkURL;
        const textToBeCopied = (citationEle).replace(/<(?:.|\n)*?>/gm, '');
        const $input = $('#bookmarkURLonTemplate');
        $input.val();
        const el = $input.get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        // el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;

        const successful = document.execCommand('copy');
        $input.blur();
        const msg = successful ? 'successful ' : 'un-successful ';
      }
    } else {
      const textToBeCopied = (bookmarkURL).replace(/<(?:.|\n)*?>/gm, '');
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      //  this.showClipBoardSuccessAlert = true;
      if (this.isFromShare) {
        this.modalService.showNotification('SUCCESS', this.translatedValues.copytoClipboard, 'TEXT copied to clipboard');
      }
    }



    //   if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    //     const $input = $('#bookmarkURLonTemplate');
    //     $input.val();
    //     const el = $input.get(0);
    //     const editable = el.contentEditable;
    //     const readOnly = el.readOnly;
    //     el.contentEditable = true;
    //     el.readOnly = false;
    //     const range = document.createRange();
    //     range.selectNodeContents(el);
    //     const sel = window.getSelection();
    //     sel.removeAllRanges();
    //     sel.addRange(range);
    //     el.setSelectionRange(0, 999999);
    //     el.contentEditable = editable;
    //     el.readOnly = readOnly;

    //     const successful = document.execCommand('copy');
    //     $input.blur();
    //     const msg = successful ? 'successful ' : 'un-successful ';
    //     const textToBeCopied = $('#bookmarkURLonTemplate').text();
    //     const event = (e: ClipboardEvent) => {
    //       e.clipboardData.setData('text/plain', textToBeCopied);
    //       e.preventDefault();
    //       document.removeEventListener('copy', event);
    //     };
    //     document.addEventListener('copy', event);
    //   } else {
    //     const textToBeCopied = $('#bookmarkURLonTemplate').text();
    //     const textarea = this.dom.createElement('textarea');
    //     textarea.style.height = '0px';
    //     textarea.style.left = '-100px';
    //     textarea.style.opacity = '0';
    //     textarea.style.position = 'fixed';
    //     textarea.style.top = '-100px';
    //     textarea.style.width = '0px';
    //     this.dom.body.appendChild(textarea);
    //     textarea.value = textToBeCopied;
    //     textarea.select();
    //     const copiedToclipBoard = document.execCommand('copy');
    //     if (copiedToclipBoard) {
    //       this.showClipBoardSuccessAlert = true;
    //     }
    // }
  }
  closeBookMarkModal() {
    this.bookMarkmodalRef.hide();
    this.isBookmarkPopupOpen = false;
  }

  setPublicationDate() {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.publishedDateFrom = '';
    currentSearchSettings.publishedDateTo = '';
    currentSearchSettings.publishedDatePrefix = '';
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  setKeywords(index) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.searchKeywords.splice(index, 1);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }

  getDocIllustrations(docId, index) {
    // this.modalService.showLoader();
    this.searchService.getIllustraion(docId, (response) => {
      const tableOfContent = response;
      const parentNodes = [];
      if (tableOfContent) {
        const contents = Object.keys(tableOfContent);
        contents.forEach((content, parentIndex) => {
          const childNodes = [];
          if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {
            tableOfContent[content].forEach((child, childIndex) => {
              const grandChildNodes = [];
              if (child.caption === null) {
                child.caption = 'Untitled Illustration';
              }
              childNodes.push({
                name: child.caption,
                children: grandChildNodes,
                tableId: child.tableId ? child.tableId : '',
                pageId: child.pageId ? child.pageId : '',
                docId: docId,
                start: index
              });
            });
          }
          parentNodes.push({
            name: content,
            children: childNodes
          });
        });
        this.illustrationNodes = parentNodes;
        this.illustrationsmodalRef = this.bsModalService.show(this.illustrationDataTemplate, {
          backdrop: true,
          ignoreBackdropClick: true, class: 'illustration-popup'
        });
        // this.modalService.hideLoader();
      }
    });
  }


  goToDocumentDetailPage(docID, pageID?) {
    this.googleAnalyticsService.eventEmitter('Details page', 'Click', docID, pageID);

    this.fromResultsPage = true;
    const currentUrl = window.location.href;
    sessionStorage.setItem('currentUrl', currentUrl);
    const searchTerm = [];
    searchTerm.push(this.pageContentData.searchTerm);
    this.pageContentData.advancedFilterParam.searchKeywords.forEach(element => {
      if (element.fieldValue) {

      }
    });
    const data = {};
    data['searchTerm'] = searchTerm;
    data['isFromResultsPage'] = true;
    this.searchService.buildFilterParams(this.pageContentData.advancedFilterParam, (param) => {
      this.searchService.buildURL(param, (url) => {
        data['pageId'] = pageID;
        data['filterParam'] = url;
        if (pageID) {
        }
      });
      this.router.navigate(['../detail/' + docID, data], { relativeTo: this.activatedRoute });
    });
  }


  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   this.toggleviewlist = true;
  //   this.togglehistorylist = false;
  //   this.togglepublist = false;
  //   this.togglesublist = false;
  //   this.togglesourcelist = false;
  //   this.togglelanglist = false;
  //   this.togglecolllist = false;
  // }
  resetSearchFilter() {
    sessionStorage.removeItem('termFrequencyUrl');
    sessionStorage.removeItem('termPopularityUrl');
    sessionStorage.removeItem('termFrequency');
    sessionStorage.removeItem('termPopularity');
    if (sessionStorage.getItem('wileySuggestedterms')) {
      sessionStorage.removeItem('wileySuggestedterms');
    }
    if (sessionStorage.getItem('sessionFilterInput')) {
      sessionStorage.removeItem('sessionFilterInput');
    }
    this.pageSettingService.updateSearchSettings(new AdvancedFilterParams());
    /* TODO change adv params to search param for map*/
    // this.sharedService.mapRequestParams(PageSettingsService.pageSettings.advancedFilterParam);
    this.searchService.goToAdvancedSearchPage('', PageSettingsService.pageSettings.advancedFilterParam);
    this.sharedService.updateFacetFlag(false);
  }

  closeIllustrationModal() {
    this.illustrationsmodalRef.hide();
  }
  illustraionTypes(params) {

  }

  afterIllustrationTreeInit(event) {
    setTimeout(() => {
      event.treeModel.expandAll();
    });
  }

  onNodeSelect($event) {
    if ($event.node.hasChildren === false) {
      if ($event.node.data['pageId']) {
        this.pageChanged.emit($event.node.data['pageId']);
      }
    }
  }
  onTableSelect($event) {
    if ($event.node.hasChildren === false) {
      const tableID = $event.node.data['tableId'];
      const startIndex = $event.node.data['start'];
      if ($event.node.data['pageId']) {
        this.illustrationDocID = $event.node.data.docId;
        this.illustrationPageID = $event.node.data['pageId'];
        this.isIllustrationOpen = true;
        this.isContentSubTypeEnable = false;
        // tslint:disable-next-line:max-line-length
        const nodeData = $event.node.data;
        this.callDocumentViewer(this.illustrationDocID, this.illustrationPageID, { caller: 'ILLUSTRATIONS', invokedTableId: nodeData.tableId, random: Math.random() }, startIndex);
        this.illustrationsmodalRef.hide();
        this.pagechanged.emit({
          pageId: nodeData['pageId'],
          pageData: {
            tableId: nodeData.tableId,
            width: nodeData.width ? nodeData.width : '',
            height: nodeData.height ? nodeData.height : '',
            hpos: nodeData.hpos ? nodeData.hpos : '',
            vpos: nodeData.vpos ? nodeData.vpos : '',
            highLight: true
          }
        });
        this.sharedService.filmstripImageList($event.node.data['pageId']);
      }
    }
  }

  showContentType(type) {
    sessionStorage.setItem('display', type.value);
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      currentParams['display'] = type.value;
      this.scrollDisplay = type.value;
      this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
      this.pageSettingService.updateSetting('groupsToBeDisplayed', [type.value]);
    });
  }

  onViewMoreClick(group) {
    // this.popupService.showLoader();
    this.showContentType({ value: group.group });
    this.searchService.viewMoreResults(group.group);
  }

  documentSubtypeList(docID, contentType, index) {
    this.searchService.getDocumentItembyType(docID, (response) => {
      const illustrations = Object.keys(response).map(key => ({ type: key, value: response[key] }));
      illustrations.forEach(contentSubType => {
        if (contentType === contentSubType['type']) {
          const pageID = contentSubType.value[0]['pages'][0];
          if (pageID) {
            this.isContentSubTypeEnable = true;
            this.callDocumentViewer(docID, pageID, '', index);
            this.urlUtilityService.setContentSubType(this.isContentSubTypeEnable);
          }
        }
      });
    });
  }

  toggleListView() {
    this.isInListViewMode = !this.isInListViewMode;
    this.isPageResized = !this.isPageResized;
    if (this.isInListViewMode) {
      // $('.i-list-view').parent().addClass('effect-active');
      // $('.i-single-view').parent().removeClass('effect-active');
    } else {
      // $('.i-list-view').parent().removeClass('effect-active');
      // $('.i-single-view').parent().addClass('effect-active');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isPageResized = !this.isPageResized;
    if (window.innerWidth <= 1024) {
      this.popoverforIpad = true;
    } else {
      this.popoverforIpad = false;
    }
  }
  getContentVal(document, group, j, str) {
    const viewConts = localStorage.getItem('haveCount');
    if (viewConts) {
      localStorage.removeItem('haveCount');
      return;
    }
    if (str === 'add') {
      document.showDocs = false;
    } else {
      document.showDocs = true;
    }
  }
  @HostListener('window:focus', ['$event'])
  onFocus(event) {
    setTimeout(() => {
      this.viewList = JSON.parse(localStorage.getItem('documentList'));
      const viewListArry = [];
      this.getJSONKeys(this.viewList).forEach((obj: any) => {
        this.getJSONKeys(this.viewList[obj]).forEach(obj1 => {
          viewListArry.push(obj1);
        });
      });
      this.viewListCount = viewListArry.length;
    }, 0);
  }

  gridViewClick(view: any) {
    this.sharedService.dropdownToggleOption({
      showSearchQuery: false
    });
    this.hideSharePopup();
    this.currentView = view;
    $('#ListIcon').removeClass('active');
    $('#GridIcon').addClass('active');
    sessionStorage.setItem('isInGridView', 'true');
    sessionStorage.setItem('checkGridview', 'true');
    $('.listViewSwitch').addClass('gridview-enable');
    if (view === 'grid') {
      this.showGridView = true;
    } else {
      this.showGridView = false;
    }
    this.showListView = false;
  }

  listViewClick(view: any) {
    this.sharedService.dropdownToggleOption({
      showSearchQuery: false
    });
    this.hideSharePopup();
    this.currentView = view;
    $('#GridIcon').removeClass('active');
    $('#ListIcon').addClass('active');
    sessionStorage.setItem('isInGridView', 'false');
    sessionStorage.setItem('checkGridview', 'false');
    $('.listViewSwitch').removeClass('gridview-enable');
    if (view === 'grid') {
      this.showGridView = true;
    } else {
      this.showGridView = false;
    }
    this.showListView = true;
    // list/grid view changes
    //  $('.listViewSwitch ').removeClass('gridview-enable');
  }
  mapViewClick(view?: any) {
    if (this._isInMapView) {
      this.groups.forEach(obj => {
        obj.documents.forEach(element => {
          element['highlightSelected'] = false;
        });
      });
    }
    this.sharedService.dropdownToggleOption({
      showSearchQuery: false
    });
    this.hideSharePopup();
    this.searchService.loadMapPos = [];
    this._isInMapView = !this._isInMapView;
    this.mapToggleSelect.emit(this._isInMapView);
    if (this.specialParams) {
      if (this.specialParams.spatialFilterParams) {
        delete this.specialParams.spatialFilterParams;
        if (PageSettingsService.pageSettings.searchTerm) {
          // tslint:disable-next-line:max-line-length
          this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, PageSettingsService.pageSettings.advancedFilterParam);
        } else {
          this.searchService.goToAdvancedSearchPage('', PageSettingsService.pageSettings.advancedFilterParam);
        }
      }
    }
    sessionStorage.setItem('isInMapView', this._isInMapView.toString());
    const browseMapVisitedCount = sessionStorage.getItem('browseMapVisitedCount');
    if (browseMapVisitedCount) {
    } else {
      if (this._isInMapView) {
        // tslint:disable-next-line:max-line-length
        this.popupService.showNotification('INFO', 'INFO', this.translateValues.drawShapeRectOrCircle || 'Use the <img src="assets/images/icons/map-rect.png" width="25" /> or the <img src="assets/images/icons/map-circle.png" width="25" /> to draw an area to limit the search', 6000);
        sessionStorage.setItem('browseMapVisitedCount', 'true');
      }
    }
    // let searchTerm = null;
    // this.activatedRoute.params.subscribe((params) => {
    //   searchTerm = params.searchTerm;
    //   const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
    //   this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings, this.isInMapView);
    //   // this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
    // });
  }
  setGridClasses(event?: any) {
    if (event === 'Photographs' || event === 'Photograph' || event === 'Photos') {
      if (this.currentView === 'grid' && this._isInMapView === true) {
        return 'gridviewDiv width-100';
      } else {
        return 'gridviewDiv';
      }
    } else {
      if (this.currentView === 'grid' && this._isInMapView === true) {
        return 'width-100';
      } else {
        return '';
      }
    }
  }
  getStyleListDisplay(event?: any) {
    // if ((this.scrollDisplay === 'Photographs')) {
    //   return 'disabled';
    // } else {
    if (event === 'Photographs' || event === 'Photograph' || event === 'Photos') {
      return '';
    } else {
      if (this.showGridView === false) {
        return 'active';
      } else {
        return '';
      }
    }
    // }
  }
  getStyleGridDisplay(event?: any) {
    if (event === 'Photographs' || event === 'Photograph' || event === 'Photos' || this.showGridView) {
      return 'active';
    } else {
      return '';
    }
  }
  // getdisplayOption(event?: any) {
  //   console.log(event)
  //   if (event === 'Photographs' || event === 'Photograph'
  //     || event === 'Photos' || this.showGridView) {
  //     return 'tooltipShow';
  //   } else {
  //     return 'tooltipHide';
  //   }
  // }

  getClassShow(event?: any) {
    if (event === 'Photographs' || event === 'Photograph' || event === 'Photos') {
      return 'showData';
    } else {
      return '';
    }
  }
  getClassHide(event?: any) {
    if (event === 'Photographs' || event === 'Photograph' || event === 'Photos') {
      return 'hideData';
    } else {
      return '';
    }
  }
  getgridviewList(event?: any) {
    if (event === 'Photographs' || event === 'Photograph' || event === 'Photos') {
      return 'gridViewClick';
    } else {
      return '';
    }
  }
  paddingLeft(event?: any) {
    if (event === 'Photographs' || event === 'Photograph' || event === 'Photos') {
      return 'padding-left-0 showData';
    } else {
      return '';
    }
  }
  imgsliderToggle($event) {
    this.hideSharePopup();
    $('.popover').hide();
    $('.lastPeekViewIcon').removeClass('lastPeekViewIcon');
    if (window.outerWidth > 768 && window.outerWidth < 1025) {
      if ($event.screenX > 1200) {
        $($event.target).parents('.gridview-enable').addClass('lastPeekViewIcon');
        $($event.target).parents('.listview-enabled .Photographsclass').addClass('lastPeekViewIcon');
      }
    }
    $event.stopPropagation();
  }
  clearContentType(contentType) {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.contentType = [];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  clearContentSubType() {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.docSubType = [];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  clearLanguage() {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.languages = [];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }


  clearCollection() {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.contentCollection = [];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }

  clearSource() {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.contentSources = [];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  clearIllustration() {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.illustrationType = [];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }
  clearSubject() {
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.subject = [];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);
  }

  limiterBadgeOnclick($event) {
    // $('.popover').toggle();
    // const elementBadgeSpan = $event.target;
    // $(elementBadgeSpan).toggleClass('popoverSelect', '');

  }


  contentType($event) {
    $('.popover').hide();
    // $('span.badge')
    // $($event.target).toggleClass('popoverSelect', '')
    $('.limiter-badge').map(function () {
      this.removeClass('popoverSelect');
    });
    this.toggleviewlist = !this.toggleviewlist;

  }

  toggleLeftMenu() {
    this.showLeftMenu = !this.showLeftMenu;
  }
  scrollResult(group, length, totalGroup) {
    if (length < totalGroup) {
      this.searchService.viewMoreResults(group);
      // $('.search-results').css('height', '120vh');
    } else { }
  }
  onScrollUp() {
  }

  scrollHandler(e) {
    this.loadMoreLoader = true;
    if (this.scrollDisplay && e === 'bottom') {
      const group = this.scrollElement.nativeElement.attributes['data-scrollgroup'].value;
      const length = this.scrollElement.nativeElement.attributes['data-scrolldoclength'].value;
      const totalGroup = this.scrollElement.nativeElement.attributes['data-scrolltotalgroup'].value;
      const checkLength = parseInt(length, 10);
      const checktotalGroup = parseInt(totalGroup, 10);
      window.scrollBy(0, -100);
      if (this._isInMapView && this.specialParams) {
        this.searchService.viewMoreResultsScroll(group, checkLength, checktotalGroup, this.specialParams.spatialFilterParams, group);
      } else {
        this.searchService.viewMoreResultsScroll(group, checkLength, checktotalGroup, null, group);
      }
      // this.searchService.viewMoreResultsScroll(group, checkLength, checktotalGroup);
      // setTimeout(() => {
      //   this.popupService.hideLoader();
      // }, 1000);
    }
  }

  loadMoreResults(value?: string, inx?: any) {
    if ($(window).scrollTop() > 120) {
      this.hightScrollTop = 'true';
    } else {
      this.hightScrollTop = '';
    }
    const scrollTopBottomCheck = $(window).scrollTop();
    if ((scrollTopBottomCheck >= this.lastScrollTop) && !this._isInMapView) {
      if (this.scrollDisplay) {
        const scrollHeight = $(document).height();
        const scrollPosition = $(window).height() + $(window).scrollTop();
        const checkVal = scrollHeight - scrollPosition;
        // if (checkVal === 0) {
        //   setTimeout(() => {
        //     window.scrollTo(0, document.body.scrollHeight - 1000);
        //   }, 300);
        // }
        let group;
        let length;
        let gridviewscroll;
        let checkLength;
        let checktotalGroup;
        if (this.scrollElement) {
          group = this.scrollElement.nativeElement.attributes['data-scrollgroup'].value;
          sessionStorage.setItem('display', group);
          length = this.scrollElement.nativeElement.attributes['data-scrolldoclength'].value;
          const totalGroup = this.scrollElement.nativeElement.attributes['data-scrolltotalgroup'].value;
          gridviewscroll = this.scrollElement.nativeElement.attributes['data-gridviewscroll'].value;
          checkLength = parseInt(length, 10);
          checktotalGroup = parseInt(totalGroup, 10);
        }
        let scrollableHeight: number;
        if (this.scrollDisplay === 'Photographs' || gridviewscroll === 'true') {
          scrollableHeight = 700;
        } else {
          scrollableHeight = 450;
        }
        if (checkVal <= scrollableHeight || value === 'viewMoreFromTemplate') {
          if (checkLength < checktotalGroup) {
            this.loadMoreLoader = true;
            window.scrollBy(0, -100);
            if (this._isInMapView && this.specialParams) {
              this.searchService.viewMoreResultsScroll(group, inx ? parseInt(inx) : checkLength, checktotalGroup,
                this.specialParams.spatialFilterParams, group);
            } else {
              this.searchService.viewMoreResultsScroll(group, inx ? parseInt(inx) : checkLength, checktotalGroup, null, group);
            }
          }
        }
      }
    }
    this.lastScrollTop = scrollTopBottomCheck;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    if (this.docIdForDocViewerObj) {
      if (!this.isDocumentViewer) {
        this.loadMoreResults();
      }
    } else {
      this.loadMoreResults();
    }
  }
  scrollTopPage() {
    $('html,body').animate({ scrollTop: 0 }, 'slow');
  }
  viewMoreResultsGroup(group, totalLength, docLength, contentType) {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      currentParams['display'] = group;
      sessionStorage.setItem('display', group);
      if (currentParams['display'] === 'Photographs') {
        this.photographView = true;
        $('#ListIcon').removeClass('active');
        $('#ListIcon').addClass('disabled');
        $('#GridIcon').addClass('active');
        this.showGridView = true;
        this.currentView = 'grid';
      }
      const checkparamValue = currentParams['display'];
      const urlTree = this.router.parseUrl(this.router.url + ';display=' + checkparamValue);
      // urlTree.queryParams['display'] = checkparamValue;
      this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
      // this.router.navigateByUrl(urlTree);
      /*
      * this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
      * above commented method will update urltree but it`s applied fully(it`s like temp display)
      */
      // this.popupService.showLoader();
      this.loadMoreLoader = true;
      this.pageSettingService.updateSetting('groupsToBeDisplayed', [group]);
      this.scrollDisplay = checkparamValue;
      // this.onWindowScroll(null);
      this.resultDataLoading = true;
      if (this._isInMapView && this.specialParams) {
        this.searchService.viewMoreResultsScroll(group, docLength, totalLength, this.specialParams.spatialFilterParams, group);
      } else {
        this.searchService.viewMoreResultsScroll(group, docLength, totalLength, null, group);
      }
      setTimeout(() => {
        // this.popupService.hideLoader();
        this.loadMoreLoader = false;
      }, 1000);
      if (group === 'Photographs' || this.currentView === 'grid') {
        $('html,body').animate({ scrollTop: 200 }, 'slow');
      } else {
        $('html,body').animate({ scrollTop: 800 }, 'slow');
      }
    });

  }
  errorHandler(event) {
    event.target.src = this.noImageUrl;
  }
  onTableBlur(event) {

  }
  mouseEnter(treeNode) {
    if (treeNode.hasChildren === false) {
      this.illustrationDocumentImage = '';
      if (treeNode.data.pageId) {
        this.illustrationDocID = treeNode.data.docId;
        this.illustrationPageID = treeNode.data.pageId;
        this.showLoader();
        setTimeout(() => {
          this.illustrationDocumentImage = environment.APIUrl + 'file/thumb/' + this.illustrationDocID + '/' +
            this.illustrationPageID + '?access_token=' + this.accessToken + '&WDALoc=' +
            localStorage.getItem('currentLocation') || environment.defaultLocation;
          setTimeout(() => {
            this.hideLoader();
          }, 200);
        }, 0);
        $('#illustrationImageDiv').addClass('show-illustrationimage');
      }
    }

  }
  mouseLeave(treeNode) {
    $('#illustrationImageDiv').removeClass('show-illustrationimage');
  }
  showLoader() {
    this.illustrationsLoader = true;
  }
  hideLoader() {
    this.illustrationsLoader = false;
  }
  zoomIntoMap(pos) {
    this.searchService.loadMapPos = pos;
    if (this._isInMapView) {
      this.sharedService.setZoomLocation(pos);
      // this._isInMapView = false;
      // this.mapToggleSelect.emit(this._isInMapView);
      // sessionStorage.setItem('isInMapView', this._isInMapView.toString());
      // setTimeout(() => {
      //   this._isInMapView = true;
      //   this.mapToggleSelect.emit(this._isInMapView);
      //   sessionStorage.setItem('isInMapView', this._isInMapView.toString());
      // }, 0);
    } else {
      this._isInMapView = true;
      // this.mapToggleSelect.emit(this._isInMapView);
      sessionStorage.setItem('isInMapView', this._isInMapView.toString());
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      $('html, body').animate({ scrollTop: 100 }, 'slow');
      setTimeout(() => {
        this.sharedService.setZoomLocation(pos);
      }, 4000);
    }
    $('.browsemap_icon').removeClass('defaultColor');
  }
  // buildAhref() {
  //   const currentWindowUrl = this.urlUtilityService.getCurrentUrl();
  //   let URL = '/';
  //   URL += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
  //   URL += '/location/';
  //   URL += PageSettingsService.pageSettings.currentLocation;
  //   URL += '/detail';
  //   return currentWindowUrl + URL + '/';
  // }

  onSpatialSearchEmitFromBrowserMap(event) {
    this.searchService.spatialSearch(event);
  }


  // this.activatedRoute.params.subscribe((params) => {
  //   let currentParams = JSON.parse(JSON.stringify(params));
  //   currentParams['display'] = type.value;
  //   const checkparamValue = currentParams['display'];
  //   this.enableScroller.emit(checkparamValue);
  //   this.location.replaceState(this.router.createUrlTree([currentParams], {relativeTo: this.activatedRoute}).toString());
  //   this.popupService.showLoader();
  //   this.pageSettingService.updateSetting('groupsToBeDisplayed', [type.value]);
  //   this.scrollDisplay = checkparamValue;
  //   setTimeout(() => {
  //     this.popupService.hideLoader();
  //   }, 1000);

  // });

  droppinHighlight(id, index) {
    this.groups[index].documents.forEach(obj => {
      if (obj.id === id) {
        obj['highlightSelected'] = true;
      } else {
        obj['highlightSelected'] = false;
      }
    });
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    $('html, body').animate({ scrollTop: 100 }, 'slow');
  }
  returntoSearch() {
    this.sharedService.setSearchLoading(false);
    this.sharedService.updateFacetFlag(false);
    this.previousSearchTerm = JSON.parse(localStorage.getItem('HistoryList')) || [];
    if (this.previousSearchTerm) {
      if (this.previousSearchTerm.length > 0) {
        this.runHistory(this.previousSearchTerm.pop());
      } else {
        this.searchService.goToSearchPage('');
      }
    } else {
      this.searchService.goToSearchPage('');
    }
  }

  runHistory(urlObj) {
    this.currentHistory = urlObj;
    this.viewHistoryUrl = this.currentHistory.url;
    this._searchTerm = this.currentHistory.searchParams;
    this.filterParams = this.currentHistory.filterParams ? this.currentHistory.filterParams : 0;
    if (this._searchTerm) {
      let url = this.viewHistoryUrl.split(';')[0];
      const checkUrlArry = url.split('/');
      if (checkUrlArry.length === 4) {
        url += '/search';
      }
      if (this._searchTerm && this.filterParams) {
        this.router.navigateByUrl(this.viewHistoryUrl);
      } else if (this._searchTerm && this.filterParams === 0) {
        this.searchService.goToSearchFromHistoryPage(this._searchTerm, url);
      }
    } else if ((this._searchTerm === '' || this._searchTerm === undefined) && !this.filterParams) {
      let url = this.viewHistoryUrl.split(';')[0];
      const checkUrlArry = url.split('/');
      if (checkUrlArry.length === 4) {
        url += '/search';
      }
      this.searchService.goToSearchFromHistoryPage(this._searchTerm, url);
    } else {
      const objDate = Date.now();
      const historyArry = JSON.parse(localStorage.getItem('HistoryList'));
      if (historyArry.length > 0) {
        urlObj.date = objDate;
        historyArry.push(urlObj);
        localStorage.setItem('HistoryList', JSON.stringify(historyArry));
      } else {
        urlObj.date = objDate;
        this.historyObject.push(urlObj);
        localStorage.setItem('HistoryList', JSON.stringify(this.historyObject));
      }
      this.router.navigateByUrl(this.viewHistoryUrl);
    }
  }

  searchResultsTrackByFn(index, item) {
    return item.id;
  }
  // gridViewResultClick() {
  //   this.sharedService.dropdownToggleOption({
  //     showSearchQuery: false
  //   });
  //   this.hideSharePopup();
  //   sessionStorage.setItem('isInGridView', 'true');
  //   sessionStorage.setItem('checkGridview', 'true');
  //   this.showGridView = true;
  // }
  @HostListener('document:keydown.escape', ['$event']) onEscapeKeydownHandler(event: KeyboardEvent) {
    this.isBookmarkPopupOpen = false;
  }
  activateViewOption(response) {
    this.scrollDisplay = response;
    if (response === 'Photographs') {
      $('#ListIcon').removeClass('active');
      $('#ListIcon').addClass('disabled');
      $('#GridIcon').addClass('active');
      sessionStorage.setItem('isInGridView', 'true');
      if (sessionStorage.getItem('checkGridview') === 'true') {
        sessionStorage.setItem('checkGridview', 'true');
      } else {
        sessionStorage.setItem('checkGridview', 'false');
      }
      this.showGridView = true;
    } else {
      $('#ListIcon').removeClass('disabled');
      if (sessionStorage.getItem('checkGridview') != null) {
        if (sessionStorage.getItem('checkGridview') === 'true') {
          sessionStorage.setItem('isInGridView', 'true');
          this.showGridView = true;
        } else {
          this.listViewClick('list');
        }
      } else {
        this.listViewClick('list');
      }
    }
  }
  gotoCollectionDetailsPage(collectionId, currentArchive, documentId) {
    // sessionStorage.setItem('currentOffsetIndex', index);
    let CurrentURL: any;
    CurrentURL = window.location.href.split(window.location.host);
    sessionStorage.setItem('SearchResultsPageURL', CurrentURL[1]);
    sessionStorage.setItem('currentSearchDocumentId', documentId);
    this.router.navigate([this.homePageUrl + '/collection-details'],
      { queryParams: { archive: currentArchive, id: collectionId, scrollTo: documentId, from: 'searchResultsPage' } });
  }
  setReferenceNumber(referenceNo) {
    const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
    if (referenceNo) {
      currentSearchSettings.searchKeywords = [{ fieldName: 'manuscriptNoV2', fieldValue: '"' + referenceNo + '"', operator: 'AND' }];
    }
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    const searchTerm = '';
    this.searchService.goToAdvancedSearchPage(searchTerm, currentSearchSettings);
  }

  // @HostListener('document:click', ['$event']) onclick() {
  //   this.sharedService.ShareDropdownToggleOption({
  //     showSearchQuery: false
  //   });
  //   if (!this.documentEnable) {
  //     this.hideSharePopup();
  //   }
  // }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.ctrlKey && e.shiftKey) {
      switch (e.keyCode) {
        case 89:
          this.internalShareToggleList(e);
          break;
      }
    }
    if (e.shiftKey && e.altKey && e.keyCode === 46) {
      this.resetSearchFilter();
    } else if (e.shiftKey && e.altKey && e.keyCode === 76) {
      if (this.currentView === 'list') {
        this.gridViewClick('grid');
      } else {
        this.listViewClick('list');
      }
    } else if (e.shiftKey && e.altKey && e.keyCode === 90) {
      this.mapViewClick();
    } else if (e.shiftKey && e.altKey && e.keyCode === 75) {
      // if (!this.isBookmarkPopupOpen && !this.isDocumentViewer) {
      this.bookmarkClick();
      // }
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.elementReference.nativeElement.contains(event.target)) {
      if ($(event.target.parentElement).is('.shareWidth')) {
        // return;
        this.documentEnable = true;
      } else if ($(event.target.parentElement.parentElement).is('.shareWidth')) {
        //  return;
        this.documentEnable = true;
      } else {
        this.documentEnable = false;
      }
      // this.sharedService.ShareDropdownToggleOption({
      //   showSearchQuery: false
      // });
      if (!this.documentEnable) {
        this.hideSharePopup();
      }
    }

    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false,
      shareURLPopup: false,
      showSearchQuery: false
    });
  }
  moreRgsContentOnMouseEnter() {
    this.showMoreRGSContentLink = true;
  }
  moreRgsContentOnMouseLeave() {
    this.showMoreRGSContentLink = false;
  }
  gotoRGSFullDocumentList() {
    this.showMoreRGSContentLink = false;
    window.open(this.moreRGSContentLink, '_blank');
  }

  closeRGSMoreContentWindow() {
    this.showMoreRGSContentLink = false;
  }
  showMoreRGSDocumentsOnClick() {
    this.showMoreRGSContentLink = true;
  }
  // clickHereENV() {
  //   this.envDocmodalRef = this.bsModalService.show(this.envDocsTemplate, { backdrop: true, ignoreBackdropClick: true });
  // }
  // closeEnvModal() {
  //   this.envDocmodalRef.hide();
  // }
  @HostListener('document:click', ['$event']) onclick() {
    // this.showMoreRGSContentLink = false;
  }
  gotoMapsExplorer(docID) {
    const CurrentURL = window.location.href;
    sessionStorage.setItem('isMapsWithGeoCoordiates', 'true');
    const data = {};
    data['searchTerm'] = docID;
    this.router.navigate(['../explorer/map', data], { relativeTo: this.activatedRoute });
  }
  moreBAASContentOnMouseEnter() {
    this.showMoreBAASContentLink = true;
  }
  moreBAASContentOnMouseLeave() {
    this.showMoreBAASContentLink = false;
  }
  showMoreBAASDocumentsOnClick() {
    this.showMoreBAASContentLink = true;
  }
  gotoBAASFullDocumentList() {
    this.showMoreBAASContentLink = false;
    window.open(this.moreBAASContentLink, '_blank');
  }
  onRightClick(doc, group, event) {
    event.stopPropagation();
    event.preventDefault();
    this.callDocumentViewer(doc.id, '', null, '', 'true', group);

  }
  disableRightClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  otherTabsetclick(option: string) {
    let filters = null;

    if (option === 'other') {
      this.documentFromOtherArchives = true;
      this.isOtherTabActive = true;
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
        if (params['filter']) {
          this.urlUtilityService.parseUrl(params['filter']).pipe(takeUntil(this.stop$)).subscribe((data) => {
            data['archive'] = this.unsubscribedArchives;
            filters = new AdvancedFilterParams(data);
          });
          localStorage.setItem('fromAdditionalArchive', 'true');
          this.searchService.advancedSearch(filters);
        } else {
          localStorage.setItem('fromAdditionalArchive', 'true');
          this.searchService.search(this.pageContentData.searchTerm ? this.pageContentData.searchTerm : '',
            this.unsubscribedArchives, null, this.pageSettingService.getPageSettings().groupsToBeDisplayed);
        }
      });
      this.sharedService.updateFacetFlag(true);
    } else if (option === 'selected') {
      this.documentFromOtherArchives = false;
      this.isOtherTabActive = false;
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
        if (params['filter']) {
          this.urlUtilityService.parseUrl(params['filter']).pipe(takeUntil(this.stop$)).subscribe((data) => {
            if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
              data['archive'] = this.pageContentData.currentSelectedArchives;
              filters = new AdvancedFilterParams(data);
            } else {
              data['archive'] = data['archive'];
              filters = new AdvancedFilterParams(data);
            }
          });
          localStorage.setItem('fromAdditionalArchive', 'false');
          this.searchService.advancedSearch(filters);
        } else {
          if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
            this.subscribedArchives = this.pageContentData.currentSelectedArchives;
          } else {
            this.subscribedArchives = this.pageContentData.currentSelectedArchives;
          }
          localStorage.setItem('fromAdditionalArchive', 'false');
          this.searchService.search(this.pageContentData.searchTerm ? this.pageContentData.searchTerm : '',
            this.subscribedArchives, null, this.pageSettingService.getPageSettings().groupsToBeDisplayed);
        }
      });
      this.sharedService.updateFacetFlag(false);
    }
  }


  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

interface ShareMail {
  topic?: String;
  message?: String;
  to?: String;
  from?: String;
}

