/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageSettingsService } from './../common/services/page-settings.service';
import { PageSettings } from './../common/models/pagesettings';
import { SearchService } from './../common/services/search.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../common/services/sharedService';
import { ModalService } from '../common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'search-result-details-wrapper',
  templateUrl: './search-result-details-wrapper.component.html',
  styleUrls: ['./search-result-details-wrapper.component.css']
})
export class SearchResultDetailsWrapperComponent implements OnInit, OnDestroy {
  unSubPageSetting: Subscription;
  pageLoadinFailed: string;
  pageSettings;
  activePage: any;
  searchWithInTerm;
  private paramObservable;
  illustrationData: any;
  itemTitle: any;
  loadedFrom: String = 'details';
  constructor(
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private sharedService: SharedService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {
    this.unSubPageSetting = PageSettingsService.pageSettingsObservable.subscribe(data => {
      this.pageSettings = data;
    });
    this.translate.get('Common.Popups.pageLoadinFailed').subscribe((res: string) => {
      this.pageLoadinFailed = res;
    });
    this.paramObservable = this.activatedRoute.params.subscribe(params => {
      this.searchService.getDocumentItembyType(params['docID'], response => {
        this.searchService.getIllustraion(params['docID'], res => {
          const tableOfContent = res;
          const parentNodes = [];
          if (tableOfContent) {
            const contents = Object.keys(tableOfContent);
            contents.forEach((content, parentIndex) => {
              const childNodes = [];
              if (
                Array.isArray(tableOfContent[content]) &&
                tableOfContent[content].length > 0
              ) {
                tableOfContent[content].forEach((child, childIndex) => {
                  const grandChildNodes = [];
                  if (child.caption === null) {
                    child.caption = 'Untitled';
                  }
                  childNodes.push({
                    name: child.caption,
                    children: grandChildNodes,
                    tableId: child.tableId ? child.tableId : '',
                    pageId: child.pageId ? child.pageId : '',
                    docId: params['docID'] ? params['docID'] : '',
                    width: child.width ? child.width : '',
                    height: child.height ? child.height : '',
                    hpos: child.hpos ? child.hpos : '',
                    vpos: child.vpos ? child.vpos : ''
                  });
                });
              }
              parentNodes.push({
                name: content,
                children: childNodes
              });
            });
            this.illustrationData = parentNodes;
          }
        });
      });
      if (params['docID']) {
        this.searchService.getDocumentDetail(params['docID'], (response) => {
          if (response) {
            this.sharedService.shareDocumentDataToSearchDetails(response);
          } else {
            this.modalService.showAlertPopup('Alert', this.pageLoadinFailed || 'This document is not available at this time.', null);
          }
        });
      }
    });
  }

  ngOnInit() {
  }

  activePageChanged(page) {
    this.activePage = page;
    this.sharedService.shareImageViewerHighlight(page);
  }

  onSearchTermChange(searchWithInTerm) {
    this.searchWithInTerm = searchWithInTerm;
  }
  afterIllustrationTreeInit(event) {
    setTimeout(() => {
      event.treeModel.expandAll();
    }, 2000);
  }
  itemsTitle(event) {
    this.itemTitle = event;
  }
  ngOnDestroy(): void {
    if (this.unSubPageSetting) {
      this.unSubPageSetting.unsubscribe();
    }
  }
}
